import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { api } from 'src/common/api'
import { IUploadFileMutationData } from 'src/default/interfaces'

const useUploadCmsFile = () =>
  useMutation({
    mutationFn: async (data: IUploadFileMutationData) => {
      const { file } = data

      const generateUrlResponse = await api.post(
        `/cms/file-upload/generate-presigned-url?contentType=${encodeURIComponent(
          file.type,
        )}`,
      )

      const { url } = generateUrlResponse.data

      if (url) {
        return axios.put(url, file, {
          transformRequest: (data, headers: any) => {
            //TODO ts
            delete headers.common['Authorization']

            headers['Content-Type'] = file.type

            return data
          },
        })
      }
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useUploadCmsFile
