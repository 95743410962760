import cx from 'classnames'

import { useRouter } from '../../../../../default/helpers/navigation'
import styles from './breadcrumbs-item.module.scss'

interface BreadcrumbsItemProps {
  isLast?: boolean
  label: string
  routeTo?: string
}

const BreadcrumbsItem = (props: BreadcrumbsItemProps) => {
  const router = useRouter()

  const { label, routeTo, isLast } = props

  return isLast ? (
    <span className={cx(styles.wrapper, styles.active)}>{label}</span>
  ) : (
    <span className={styles.wrapper}>
      {routeTo ? <a onClick={() => router.push(routeTo)}>{label}</a> : label}
      <span className={styles.separator}>/</span>
    </span>
  )
}

export default BreadcrumbsItem
