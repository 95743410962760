import { Button, Icons } from 'src/common/components'
import { useLogout } from 'src/common/hooks'

import styles from './crm-select-organization-empty.module.scss'

const CRMSelectOrganizationEmpty = () => {
  const logoutMutation = useLogout()

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icons.WarningAngular />
      </div>

      <div className={styles.title}>No organizations found!</div>

      <div className={styles.description}>
        Right now you are not added to any organization. Please contact your
        admin.
      </div>

      <Button className={styles.logout} size="small" onClick={handleLogout}>
        Log Out
      </Button>
    </div>
  )
}

export default CRMSelectOrganizationEmpty
