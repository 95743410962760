import logo from './assets/not-found.png'
import styles from './empty-persons.module.scss'

interface EmptyPersonsProps {
  term: 'account' | 'lead' | 'record' | 'submission' | 'sms campaign' | 'task' | 'Email Templates'
  ActionComponent?: React.ReactNode
}

const EmptyPersons = (props: EmptyPersonsProps) => {
  const { term, ActionComponent } = props

  return (
    <div className={styles.messageContainer}>
      <img src={logo} alt="ticket-icon" width={'30%'} />

      <p className={styles.titleMessage}>{`No ${term}s Found`}</p>

      <p className={styles.message}>
        {`We did not find any ${term}s at the moment `}
      </p>

      {ActionComponent ? (
        <div className={styles.actionWrapper}>{ActionComponent}</div>
      ) : null}
    </div>
  )
}

export default EmptyPersons
