import { useFormikContext } from 'formik'

import { IPersonMutationData } from 'src/common/interfaces'
import { PhoneNumber } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from '../main-information.module.scss'

const LeadMainInformation = () => {
  const {
    values: { email, phoneNumber },
  } = useFormikContext<IPersonMutationData>()

  return (
    <>
      {email ? (
        <div>
          <div className={styles.title}>Email:</div>
          <div>{email}</div>
        </div>
      ) : null}

      {phoneNumber ? (
        <>
          <div className={styles.divider} />

          <div>
            <div className={styles.title}>Mobile Number:</div>
            <PhoneNumber value={phoneNumber}>{phoneNumber}</PhoneNumber>
          </div>
        </>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LeadMainInformation,
  'is_enable_lead_main_information',
  true,
)
