import { Accordion } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benestream-person-eligibilities-medicaid.module.scss'

interface BenestreamPersonEligibilitiesMedicaidProps {
  parentFamilyOf3: string
  otherAdultIndividual: string
}

const BenestreamPersonEligibilitiesMedicaid = (
  props: BenestreamPersonEligibilitiesMedicaidProps,
) => {
  const { parentFamilyOf3, otherAdultIndividual } = props

  return (
    <Accordion
      className={styles.wrapper} //TODO move layout to common place like benestream accordion layout
      titleClassName={styles.accordionTitle}
      contentClassName={styles.accordionContent}
      title="Medicaid Rules"
    >
      <div className={styles.numberItem}>
        <div className={styles.numberItemLabel}>
          Parent
          <br /> (Family of 3):
        </div>
        <div>{`${parentFamilyOf3}%`}</div>
      </div>

      <div className={styles.numberItem}>
        <div className={styles.numberItemLabel}>
          Other adult
          <br /> (Individual):
        </div>
        <div>{`${otherAdultIndividual}%`}</div>
      </div>
    </Accordion>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonEligibilitiesMedicaid,
  'is_enable_benestream_person_eligibilities_medicaid',
  true,
)
