import { UseFormInput, useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { appLocalStorage } from 'src/common/libs'
import {
  getParamsObjectWithoutPrefix,
  getParamsStringWithPrefix,
} from 'src/default/helpers'
import { useRouter } from 'src/v2/commons/utils/navigation'

const PARAMS_PREFIX_KEY = 'f'

interface UsePersistenValuesFormProps<V extends {}> {
  localStoragePath: string
  formConfig: UseFormInput<V>
  isProgrammaticFormUpdateRef?: React.MutableRefObject<boolean>
  parseBooleans?: boolean
}

const usePersistentForm = <V extends {}>(
  params: UsePersistenValuesFormProps<V>,
) => {
  const {
    localStoragePath,
    formConfig: { initialValues, ...rest },
    parseBooleans = true,
    isProgrammaticFormUpdateRef,
  } = params

  const { pathname } = useLocation()

  const localStorageId = `params_form_${localStoragePath}`

  const router = useRouter()

  const form = useForm<V>({
    initialValues,
    ...rest,
  })

  useEffect(() => {
    const initialURLParams = getParamsObjectWithoutPrefix(
      PARAMS_PREFIX_KEY,
      window.location.search,
      {
        arrayFormat: 'bracket',
        parseBooleans,
      },
    )
    if (Object.keys(initialURLParams).length) {
      form.setValues({
        ...initialValues,
        ...initialURLParams,
      })
    } else {
      const currentLocalStorageData = appLocalStorage.getData(localStorageId)
      if (currentLocalStorageData) {
        if (JSON.stringify(initialValues) !== currentLocalStorageData) {
          form.setValues({
            ...initialValues,
            ...JSON.parse(currentLocalStorageData),
          })
        }
      }
    }
    if (isProgrammaticFormUpdateRef) {
      isProgrammaticFormUpdateRef.current = true
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(form.getValues()) !== JSON.stringify(initialValues)) {
      const newURLParamsString = getParamsStringWithPrefix(
        PARAMS_PREFIX_KEY,
        form.getValues(),
        {
          arrayFormat: 'bracket',
          skipEmptyString: true,
        },
      )
      router.push(`${pathname}?${newURLParamsString}`, false)
    } else {
      router.push(pathname, false)
    }

    appLocalStorage.setData(localStorageId, JSON.stringify(form.getValues()))
  }, [form.getValues()])

  return form
}

export default usePersistentForm
