import { Dayjs } from 'dayjs'

import dayjs from 'src/common/libs/dayjs'

export const formatDate = (
  date: string | Dayjs | Date,
  formatString: string,
) => {
  if (typeof date === 'string' || date instanceof Date) {
    return dayjs(date).format(formatString)
  } else {
    return date ? date.format(formatString) : '-'
  }
}

export const validateDate = (date?: string, format?: string) => {
  return dayjs(date, format).format(format) === date
}
