import { useEffect, useState } from 'react'

import { Input } from 'src/common/components'
import { ICurrentOptionComponent } from 'src/default/interfaces'

import styles from './basic-multi-select-suggestions-current-option.module.scss'

const BasicMultiSelectSuggestionsCurrentOption = (
  props: ICurrentOptionComponent,
) => {
  const { name, placeholder, value, onCustomEvent } = props

  const [localValue, setLocalValue] = useState<string>('')

  useEffect(() => {
    setLocalValue('')
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.currentTarget.value)

    if (onCustomEvent) {
      onCustomEvent('findValue', event)
    }
  }

  return (
    <div>
      <Input
        inputClassName={styles.input}
        name={`${name}_find`}
        placeholder={placeholder}
        value={localValue}
        withError={false}
        onChange={handleChange}
      />
    </div>
  )
}

export default BasicMultiSelectSuggestionsCurrentOption
