import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { QUERY_IDS } from 'src/default/config'

interface GetLeadParams {
  personId?: string
}

const useGetOpportunities = (params: GetLeadParams) => {
  const { personId } = params

  let url = `/appointments/person/${personId}`

  return useQuery({
    queryKey: [QUERY_IDS.GET_OPPORTUNITIES],
    queryFn: async () => await api.get(url),
  })
}

export default useGetOpportunities
