import { useAppSelector } from '@v2/store/hooks'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useGetInformationRelatedQuery } from '../models/merging-tool.api'
import { IColumnFields } from '../models/merging-tool.model'

interface IAssociatedInformation {
  data: { field: IColumnFields; total: string; [key: string]: any }[]
}

type RequestOrigin = 'search' | 'account' | 'lead'

export const useAssociatedInformationUseCase = (): IAssociatedInformation => {
  const [searchParams] = useSearchParams()
  const personsIds = useAppSelector(
    (state) =>
      state[searchParams.get('location') as RequestOrigin].filters
        .selectedItems,
  )

  const { data: informationRelated } = useGetInformationRelatedQuery({
    params: { personsIds: personsIds },
  })

  const tableColumns = useMemo(() => {
    if (informationRelated) {
      return informationRelated.map((line) => ({
        field: line.field,
        ...line.data,
      }))
    }
    return []
  }, [informationRelated])

  return { data: tableColumns }
}
