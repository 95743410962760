import Status from '../BasicStatus/BasicStatus.component'
import styles from './table-lead-status.module.scss'

interface TableLeadStatusProps {
  status?: string
  color?: string
}

const TableLeadStatus = (props: TableLeadStatusProps) => {
  const { status, color } = props

  if (!status) {
    return null
  }

  return (
    <Status labelClassName={styles.label} color={color}>
      {status}
    </Status>
  )
}

export default TableLeadStatus
