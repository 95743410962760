import dayjs from 'src/common/libs/dayjs'
import { STATUS_OPTIONS } from 'src/default/config'
import { IOpportunityListRecord } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './opportunity-item.module.scss'

interface IOpportunityItem {
  opportunity: IOpportunityListRecord
  onClick: (value: any) => void
}

const OpportunityItem = (props: IOpportunityItem) => {
  const { onClick } = props
  const {
    status,
    appointmentDate,
    benefitType,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  } = props.opportunity

  const statusSelected = STATUS_OPTIONS.find(
    (option) => option.value === status,
  )
  return (
    <div
      onClick={() => onClick(props.opportunity)}
      className={styles.resultContainer}
    >
      <div className={styles.name}>{benefitType?.replaceAll('_', ' ')}</div>
      <div className={styles.statusContainer}>
        <div className={styles.titles}>Status:</div>
        <div className={styles.infoDates}>{statusSelected?.label}</div>
      </div>
      <div className={styles.closeDateContainer}>
        <div className={styles.titles}>Date:</div>
        <div className={styles.infoDates}>
          {dayjs(appointmentDate).tz().format('MMM D, YYYY, h:mm A')}
        </div>
      </div>

      <div className={styles.createdAndUpdated}>
        <div className={styles.titles}>Created By:</div>
        <div className={styles.infoDates}>
          <div>
            {Boolean(createdBy?.trim()) ? createdBy : 'Not Information'}
          </div>
          <div>
            {createdAt
              ? new Date(createdAt)?.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              : 'Not Information'}
          </div>
        </div>
      </div>
      <div className={styles.createdAndUpdated}>
        <div className={styles.titles}>Updated By:</div>
        <div className={styles.infoDates}>
          <div>
            {Boolean(updatedBy?.trim()) ? updatedBy : 'Not Information'}
          </div>
          <div>
            {updatedAt
              ? new Date(updatedAt)?.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              : 'Not Information'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  OpportunityItem,
  'is_enable_opportunity_item',
  true,
)
