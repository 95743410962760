import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { BasicInput, FieldWrapper, Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import { useAppSelector } from '../../../../../v2/store/hooks'
import Calendar from './Calendar'
import styles from './input-date.module.scss'

interface InputDateProps extends IField {
  hideCalendar?: boolean
  futureDate?: boolean
  pastDate?: boolean
  maxYear?: number
}

const InputDate = (props: InputDateProps) => {
  const FORMAT = 'MM/DD/YYYY'

  const selectRef = useRef(null)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const {
    name,
    withError,
    className,
    hideCalendar,
    disabled,
    futureDate,
    pastDate,
    maxYear,
    value: strictValue,
  } = props
  const [field, meta, { setValue, setTouched }] = useField({ name })

  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [originalValue, setOriginalValue] = useState(field.value)

  const handleOnChange = (e: any) => {
    const { value: targetValue } = e.target
    const regExpValidateIfIsNumber = /^\d+$/

    if (!targetValue) {
      setValue('')
      return
    }

    if (targetValue < field.value) {
      setValue(targetValue)
      return
    }

    const targetValueWithoutFormat = targetValue.replaceAll('/', '')
    const MAX_LENGTH_OF_DATE = 10
    const LENGTH_OF_DAY = 2
    const LENGTH_OF_DAY_AND_MONTH = 4

    if (
      regExpValidateIfIsNumber.test(targetValueWithoutFormat) &&
      targetValue.length <= MAX_LENGTH_OF_DATE
    ) {
      let formattedValue = targetValueWithoutFormat
      if (formattedValue.length > LENGTH_OF_DAY) {
        formattedValue = formattedValue
          .slice(0, LENGTH_OF_DAY)
          .concat('/')
          .concat(formattedValue.slice(LENGTH_OF_DAY))
      }
      if (formattedValue.length > LENGTH_OF_DAY_AND_MONTH) {
        formattedValue = formattedValue
          .slice(0, LENGTH_OF_DAY_AND_MONTH + 1)
          .concat('/')
          .concat(formattedValue.slice(LENGTH_OF_DAY_AND_MONTH + 1))
      }
      setValue(formattedValue)
    }
  }

  const handleCalendarChange = (date: string) => {
    setValue(date)
  }

  const handleOnBlur = () => {
    setTouched(true)
  }

  const handleToggleCalendar = () => {
    if (disabled) {
      return
    }

    setShowCalendar(!showCalendar)
  }

  const isValidDate = (date: string) => {
    return dayjs(date).isValid()
  }

  useEffect(() => {
    if (field.value === null) {
      setValue('')
    }
    if (field.value?.includes('T') && !Boolean(strictValue)) {
      const formattedDate = dayjs.tz(field.value, 'UTC').format(FORMAT)
      setValue(formattedDate)
      setOriginalValue(formattedDate)
    }
  }, [field.value])

  useEffect(() => {
    if (strictValue) {
      setValue(strictValue)
    }
  }, [strictValue])

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setShowCalendar(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setShowCalendar(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  return (
    <FieldWrapper
      {...props}
      className={cx(
        styles.wrapper,
        styles[`${currentTheme}Slice`],
        showCalendar && styles.withCalendar,
        withError && styles.withError,
        className,
      )}
    >
      <div className={styles.inputWrapper}>
        <BasicInput
          {...props}
          name={`inner-${name}`}
          placeholder={FORMAT}
          value={
            originalValue !== field.value
              ? field.value
              : field.value
              ? dayjs(field.value).format(FORMAT)
              : ''
          }
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />

        {!hideCalendar ? (
          <div className={styles.button} onClick={handleToggleCalendar}>
            <Icons.Calendar />
          </div>
        ) : null}
      </div>

      <div ref={selectRef}>
        {showCalendar ? (
          <div className={styles.calendarWrapper}>
            <Calendar
              futureDate={futureDate}
              pastDate={pastDate}
              maxYear={maxYear}
              value={
                isValidDate(field.value)
                  ? dayjs(field.value).format(FORMAT)
                  : originalValue
                  ? dayjs(originalValue).format(FORMAT)
                  : dayjs().format(FORMAT)
              }
              onChange={handleCalendarChange}
              onClose={() => setShowCalendar(false)}
              format={FORMAT}
            />
          </div>
        ) : null}
      </div>
    </FieldWrapper>
  )
}

export default InputDate
