import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { rootApi } from '@v2/store/api/rootApi'

import { IOfficeVisit, TicketFiltersModel, TicketsModel } from './ticket.model'

export const ticketApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    tickets: builder.query<TicketsModel, { params: TicketFiltersModel }>({
      query: (queryArgs) => ({
        url: '/v2/office-visit',
        method: 'GET',
        params: queryArgs.params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...rest } = queryArgs.params
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: rest,
        })
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.params.offset === 0) {
          return newItems
        }
        return {
          ...newItems,
          records: [...(currentCache?.records || []), ...newItems.records],
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.params.offset &&
          currentArg?.params.offset !== previousArg?.params.offset
        )
      },
    }),
    setOfficeVisit: builder.mutation<null, IOfficeVisit>({
      query(data) {
        const body = { ...data }
        delete body.id
        return {
          url: `/office-visit/${data.id}`,
          method: 'PUT',
          data: body,
        }
      },
    }),
  }),
})

export const { useTicketsQuery, useSetOfficeVisitMutation } = ticketApi
