export const OPPORTUNITY_STATUS = [
  { value: 'INFO_GATHERING', label: 'Information gathering' },
  { value: 'QUICK_SCREEN_COMPLETE', label: 'Quick screen complete' },
  { value: 'POTENTIAL_ELIGIBILITY', label: 'Potential eligibility' },
  { value: 'PENDING_STATE_RESPONSE', label: 'Pending state response' },
  { value: 'PENDING_RENEWAL', label: 'Pending renewal' },
  { value: 'NOT_ELIGIBLE', label: 'Not eligible' },
  { value: 'REMOVED', label: 'Removed' },
  { value: 'OPT_OUT', label: 'Opportunity out' },
  { value: 'ENROLLED_ENROLLED_BY_BENESTREAM', label: 'Enrolled by benestream' },
  {
    value: 'ENROLLED_ALREADY_ON_AT_TIME_OF_QUICK_SCREEN',
    label: 'Enrolled already on at time of quick screen',
  },
  {
    value: 'ENROLLED_RENEWED_RENEWAL_CONFIRMED_BY_BENESTREAM',
    label: 'Enrolled renewed confirmed by benestream',
  },
  { value: 'ENROLLED_ENROLLED_BY_METROPLUS', label: 'Enrolled by metroplus' },
  {
    value: 'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
    label: 'Independent enrollment confirmed',
  },
  { value: 'RE_SCREENED', label: 'Re-Screened' },
  //TODO remove this one after the data team does the migration
  { value: 'SCREENED_WITH_HEALTHFIRST', label: 'Scheduled with HealthFirst' },
  { value: 'SCHEDULED_WITH_HEALTHFIRST', label: 'Scheduled with HealthFirst' },
  {
    value: 'RE_SCHEDULED_WITH_HEALTHFIRST',
    label: 'Re-scheduled with HealthFirst',
  },
]
