import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetFormsParams {
  organizationId: string
}

const useGetForms = (params: GetFormsParams) => {
  const { organizationId } = params

  return useQuery({
    queryKey: [`getFormsByOrganization`, organizationId],
    queryFn: async () =>
      await api.get(`/v1/forms/template/list/${organizationId}`),
  })
}

export default useGetForms
