import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { api } from 'src/common/api'
import { formatDate } from 'src/common/helpers'

interface IGetOfficeVisitsParams {
  status?: string
  assignedTo?: string
  reasonForVisit?: string
  fromDate: string
  limit: number
  isOpenTickets: boolean
  nextPageParam: number | null
}

const useGetOfficeVisits = (params: IGetOfficeVisitsParams) => {
  const {
    status,
    assignedTo,
    reasonForVisit,
    fromDate,
    isOpenTickets,
    nextPageParam,
    limit,
  } = params

  return useInfiniteQuery({
    queryKey: [
      `getOfficeVisitsByOrganizationId`,
      status,
      assignedTo,
      reasonForVisit,
      fromDate,
      isOpenTickets,
    ],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v2/office-visit`, {
        signal,
        params: {
          status,
          assignedTo,
          reasonForVisit,
          isOpenTickets,
          createdAt: Boolean(fromDate)
            ? dayjs(fromDate).utc().toDate()
            : fromDate,
          offset: pageParam,
          limit,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: () => nextPageParam,
  })
}

export default useGetOfficeVisits
