import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IConvertLeadToAccountData {
  enrollmentId: string
  manualApproval?: boolean
}

const useConvertLeadToAccount = () =>
  useMutation({
    mutationFn: async (data: IConvertLeadToAccountData) => {
      const { enrollmentId, manualApproval } = data
      let url = `/v2/enrollments/verify/${enrollmentId}`
      if (manualApproval) {
        url = `/v2/enrollments/verify/${enrollmentId}?manualApproval=${manualApproval}`
      }
      return api.post(url)
    },
  })

export default useConvertLeadToAccount
