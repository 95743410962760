import { useField } from 'formik'
import { useEffect } from 'react'

import { BasicInput, FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

const InputZipcode = (props: IField) => {
  const { name } = props

  const [field, , { setValue }] = useField({ name })

  useEffect(() => {
    if (field.value) {
      const zipcode = field.value.replace(/[^0-9.]/g, '')
      setValue(zipcode)
    }
  }, [])

  const handleChange = (
    event: React.SyntheticEvent<HTMLInputElement, InputEvent>,
  ) => {
    const { value: originalValue } = event.currentTarget
    let phoneValue = originalValue
    phoneValue = phoneValue.replace(/[^0-9.]/g, '')

    setValue(phoneValue)
  }

  return (
    <FieldWrapper {...props}>
      <BasicInput {...props} placeholder="12345" onChange={handleChange} />
    </FieldWrapper>
  )
}

export default InputZipcode
