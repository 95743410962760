import cx from 'classnames'

import { Icons } from 'src/common/components'
import checkboxStyles from 'src/common/components/form/Checkbox/checkbox.module.scss'
import { IOption } from 'src/common/interfaces'

import styles from './basic-select-groups-options.module.scss'

interface BasicSelectGroupsOptionsProps {
  options: IOption[]
  currentValue?: string
  onChange: (newValue: string) => void
}

const BasicSelectGroupsOptions = (props: BasicSelectGroupsOptionsProps) => {
  const { options, currentValue, onChange } = props

  return (
    <div className={styles.wrapper}>
      {options.map((option) => (
        <div
          key={option.value}
          className={styles.option}
          onClick={() => onChange(option.value)}
        >
          <div className={checkboxStyles.wrapper}>
            <span
              className={cx(
                checkboxStyles.inner,
                styles.checkboxInner,
                currentValue === option.value && checkboxStyles.checked,
              )}
            >
              <Icons.Check />
            </span>
          </div>
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  )
}

export default BasicSelectGroupsOptions
