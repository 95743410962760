import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

interface SearchParams {
  searchValue?: string
  organizationId?: string | null
  isCrossOrganization?: boolean
  isExtendAsBNST?: boolean
  limit?: number
}

const useSearchPerson = (params: SearchParams) => {
  const {
    searchValue = '',
    organizationId = undefined,
    isCrossOrganization,
    isExtendAsBNST,
    ...restParams
  } = params

  return useInfiniteQuery({
    queryKey: [
      `getSearchPerson`,
      searchValue,
      organizationId,
      isCrossOrganization,
      isExtendAsBNST,
    ],
    queryFn: async ({ signal, pageParam }) => {
      const params = {
        ...restParams,
        search: decodeURIComponent(searchValue.trim()),
        organizationId,
        isExtendAsBNST,
        offset: pageParam,
      }
      Object.assign(params, {
        getUserOrganizations: isCrossOrganization ? true : null,
      })
      return await api.get(`/v5/persons`, {
        signal,
        params,
      })
    },
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length,
    enabled:
      (Boolean(organizationId) || isCrossOrganization) && !!searchValue.length,
  })
}

export default useSearchPerson
