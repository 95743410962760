import cx from 'classnames'

import { IPersonNote } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import PersonNotesListItem from './PersonNotesListItem'
import styles from './person-notes-list.module.scss'

interface PersonNotesListProps {
  notes: IPersonNote[]
  refetch: () => Promise<any>
}

const PersonNotesList = (props: PersonNotesListProps) => {
  const { notes, refetch } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
      {notes.length ? (
        <>
          {notes.map((note: any) => (
            <PersonNotesListItem key={note.id} data={note} refetch={refetch} />
          ))}
        </>
      ) : (
        <div className={styles.noNotes}>No notes found</div>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonNotesList,
  'is_get_person_notes_enabled',
)
