import { useFormikContext } from 'formik'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import { BENESTREAM_PERSON_STATUS } from 'src/benestream/constants'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import {
  Input,
  InputDate,
  PersonHistoryTracker,
  Select,
} from 'src/common/components'

interface BenestreamPersonFormInformationProps {
  personType?: 'lead' | 'account'
}

const BenestreamPersonFormInformation = (
  props: BenestreamPersonFormInformationProps,
) => {
  const { personType } = props

  const { values } = useFormikContext<IBenestreamPerson>()

  const createdByValue = `${values?.createdBy?.firstName} ${values?.createdBy?.lastName}`

  const title =
    personType === 'lead' ? 'Lead Information' : 'Account Information'

  return (
    <>
      <BenestreamPersonFormBlock title={title}>
        <Input
          disabled
          name="createdBy"
          label="Created By"
          value={createdByValue}
          tabletWidth="50"
        />

        <InputDate
          disabled
          name="createdAt"
          label="Created At"
          hideCalendar
          tabletWidth="50"
        />

        {personType === 'lead' ? (
          <Select
            name="status"
            label="Status"
            tabletWidth="50"
            placeholder="Select status"
            options={BENESTREAM_PERSON_STATUS}
          />
        ) : null}

        <Input disabled name="source" label="Source" tabletWidth="50" />
      </BenestreamPersonFormBlock>

      <PersonHistoryTracker />
    </>
  )
}

export default BenestreamPersonFormInformation
