import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from '@v2/store/hooks'
import { WbfPolicies } from '@workers-benefit-fund/shared-data'

import { api } from 'src/common/api'

const useGetTagsBlackCarFund = () => {
  // TODO (marco): this should be moved to something generic. This is a patch. Check this thread to get more information: https://wbf-workspace.slack.com/archives/C066MEHM25B/p1731600654962289
  const { policies } = useAppSelector((state) => state.user.userProfile)
  let url = `/persons/tags/black-carfound`

  return useQuery({
    queryKey: [`getTagsBlackCarFund`, url],
    queryFn: async () => await api.get(url),
    enabled: policies.includes(WbfPolicies.is_get_black_car_fund_tags_enabled),
  })
}

export default useGetTagsBlackCarFund
