import { Icons } from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'

import styles from './new-email-editor-attachments.module.scss'

const NewEmailEditorAttachments = () => {
  const { setAttachments, filesAttachments, attachments, setFilesAttachments } =
    useEmailSenderAndManagerEmailTemplateContext()

  const handleRemove = (index: number) => {
    const newFilesAttachments = [...filesAttachments]
    const newAttachments = [...attachments]
    newAttachments.splice(index, 1)
    setAttachments(newAttachments)
    newFilesAttachments.splice(index, 1)
    setFilesAttachments(newFilesAttachments)
  }

  if (!filesAttachments.length) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Attachments</div>

      <div className={styles.inner}>
        {filesAttachments.map((attachment, index) => (
          <div className={styles.attachment} key={index}>
            <div className={styles.name}>{attachment.fileName}</div>

            <div className={styles.remove} onClick={() => handleRemove(index)}>
              <Icons.Close />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NewEmailEditorAttachments
