import { Icons } from '@workers-benefit-fund/wbf-ui-components'
import cx from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import {
  Button,
  Checkbox,
  Form,
  Input,
  InputDate,
  Modal,
  Portal,
  Select,
  SubmitButton,
} from 'src/common/components'
import { capitalize, getDocumentTypeFormatted } from 'src/common/helpers'
import { IModal } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { useNotifications } from 'src/common/notification'
import { useMutateDocument } from 'src/default/hooks'
import { EnumDocumentType, IDocument } from 'src/default/interfaces'
import { SingleAccountModalAttachments } from 'src/default/pages/Accounts/SingleAccountPage/components'

import PolicyCheckHOC from '../../../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../../../v2/store/hooks'
import DocumentModalResult from '../DocumentModalResult/DocumentModalResult'
import styles from './single-account-documents-modal.module.scss'

interface SingleAccountDocumentsModalProps extends IModal {
  initialData?: IDocument | null
  refetch: () => Promise<any>
  documentTypes?: EnumDocumentType[]
}

const SingleAccountDocumentsModal = (
  props: SingleAccountDocumentsModalProps,
) => {
  const { initialData, refetch, onClose, documentTypes } = props

  const { personId } = useParams<{ personId: string }>()
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [alertDetail, setAlertDetail] = useState({
    title: '',
    description: '',
  })

  const documentMutation = useMutateDocument(initialData ? 'update' : 'create')
  const notifications = useNotifications()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const isTLCRequired = userProfile.organization.profile?.isTLCRequired

  const formik = useFormik({
    initialValues: initialData
      ? {
          documentId: initialData.id,
          type: initialData.type,
          number: initialData.number,
          licenseExpiration: dayjs
            .tz(initialData.licenseExpiration, 'UTC')
            .isValid()
            ? dayjs
                .tz(initialData.licenseExpiration, 'UTC')
                .format('MM/DD/YYYY')
            : '',
          licenseUpdated: initialData.licenseUpdated,
          isVerified: initialData.isVerified,
          pictureURL: initialData.pictureURL,
          createdAt: initialData.createdAt,
          updatedAt: initialData.updatedAt,
        }
      : {
          type: '',
          number: '',
          licenseExpiration: '',
          isVerified: false,
        },
    onSubmit: async (values) => {
      await documentMutation
        .mutateAsync({
          ...values,
          licenseExpiration: values.licenseExpiration || null,
          personId: personId,
          createdAt: undefined,
          updatedAt: undefined,
        })
        .then((response) => {
          const documentData = response?.data
          const isTLCDocument = values.type === EnumDocumentType.TLC
          const hasNYCOpenData = documentData?.hasTLCDocumentNYCOpenData
          const hasNameInconsistency =
            documentData?.hasTLCDocumentNameInconsistency

          const closeModal =
            isTLCDocument && (!hasNYCOpenData || hasNameInconsistency)

          if (isTLCDocument) {
            const errorReason = !hasNYCOpenData
              ? 'TLC cannot be verified: Invalid TLC number.'
              : 'TLC cannot be verified: Name Mismatch.'

            showAlertMessage('Unable to verify TLC number', errorReason)
          }

          refetch()

          if (!closeModal) {
            onClose?.()
          }
        })
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Required'),
      number: yup.string().required('Required'),
      licenseExpiration: yup.string().date({
        format: 'MM/DD/YYYY',
      }),
    }),
  })

  const showAlertMessage = (title: string, description: string) => {
    setAlertDetail({ title, description })
    setShowAlertModal(true)
  }

  const handleOnCloseDocumentModal = () => {
    setShowAlertModal(false)
    onClose?.()
  }

  const getOptions = () => {
    return Object.values(EnumDocumentType)
      .map((type) => ({
        value: type,
        label: getDocumentTypeFormatted(type),
        disabled: documentTypes?.includes(type),
      }))
      .filter(
        (item) =>
          (item.value !== EnumDocumentType.TLC || isTLCRequired) &&
          item.value !== EnumDocumentType.SOCIAL_SECURITY_NUMBER &&
          item.value !== EnumDocumentType.PASSPORT,
      )
  }

  return (
    <Modal id="singleAccountDocumentModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>
          {initialData ? 'Document Information' : 'New Document'}
        </div>
        <div className={styles.portalWrapper}>
          <Portal.Target id="selectDisabledValue" media="desktop" />
        </div>
        <div className={styles.inner}>
          <div className={styles.block}>
            <Select
              required
              name="type"
              label="Type of Document"
              placeholder="Select an Option"
              options={getOptions()}
              onSelectDisabled={(value) => {
                notifications?.show(
                  'warning',
                  '⚠️ Only one document of the same type can be created',
                  {
                    id: `selectedDisabledValue-${value}`,
                    portalId: 'selectDisabledValue',
                  },
                )
              }}
            />
            <Input
              required
              name="number"
              label="Document Number"
              placeholder="Enter Document Number"
            />
          </div>

          <div className={styles.block}>
            <InputDate
              name="licenseExpiration"
              label="Document Expiration Date"
              futureDate
            />

            <Checkbox
              className={styles.isVerified}
              name="isVerified"
              label="Verified"
            />
          </div>

          {initialData ? (
            <div className={styles.block}>
              <InputDate
                disabled
                name="createdAt"
                label="Created Date"
                withError={false}
              />

              <InputDate
                disabled
                name="updatedAt"
                label="Last Modified Date"
                withError={false}
              />
            </div>
          ) : null}

          {showAlertModal && (
            <DocumentModalResult
              title={alertDetail.title}
              description={alertDetail.description}
              onClose={handleOnCloseDocumentModal}
            />
          )}

          <PolicyCheckHOC policyName="is_get_document_url_enabled">
            <SingleAccountModalAttachments
              name="pictureURL"
              attachmentType="document"
              multiple={true}
            />
          </PolicyCheckHOC>

          <PolicyCheckHOC
            policyName={
              initialData
                ? 'is_update_document_enabled'
                : 'is_create_document_enabled'
            }
          >
            <div className={styles.footer}>
              <Button
                className={styles.cancelButton}
                template="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <SubmitButton
                className={styles.submitButton}
                iconAfter="ArrowSelect"
                loading={documentMutation.isPending}
                disabled={!formik.values.number || !formik.values.type}
              >
                Save
              </SubmitButton>
            </div>
          </PolicyCheckHOC>
        </div>
      </Form>
    </Modal>
  )
}

export default SingleAccountDocumentsModal
