import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { BenestreamPersonFormAddressBlock } from 'src/benestream/components'
import { PRIVATE_ROUTES } from 'src/benestream/config'
import { useMutatePrimaryPerson } from 'src/benestream/hooks'
import { BasicBlock, Breadcrumbs, Form } from 'src/common/components'
import { yupPhoneValidator } from 'src/common/helpers'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import CreateLeadBottomButtons from './CreateLeadBottomButtons'
import CreateLeadCommunicationPreferences from './CreateLeadCommunicationPreferences/CreateLeadCommunicationPreferences'
import CreateLeadContactInformation from './CreateLeadContactInformation'
import { CreateLeadValues } from './createLeadPage.interface'

const CreateLeadPage = () => {
  const router = useRouter()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const primaryPersonMutation = useMutatePrimaryPerson('create')

  const currentOrganizationId = userProfile.organization.id || ''

  const [result, setResult] = useState<string | null>(null)
  const [leadId, setLeadId] = useState('')

  const handleCloseResult = () => {
    setResult(null)

    if (result === 'success') {
      router.push(`${PRIVATE_ROUTES.LEADS.path}/${leadId}`)
    }
  }

  const formik = useFormik<CreateLeadValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      preferredLanguage: '',
      birthDate: '',
      source: 'Manual Load',
      phoneNumber: '',
      abcIds: '',
      street: '',
      unitNumber: '',
    },
    validateOnBlur: true,
    onSubmit: async (values) => {
      const newValues = { ...values }
      newValues.street = `${newValues.street}   ${
        newValues.unitNumber || ''
      }`.trim()
      delete newValues.unitNumber
      await primaryPersonMutation
        .mutateAsync({
          ...newValues,
          abcIds: [values.abcIds],
          organizationId: currentOrganizationId,
        })
        .then((response: AxiosResponse) => {
          setResult('success')
          setLeadId(response.data.primaryPerson.id)
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required('First name is a required field'),
      lastName: yup.string().name().required('Last name is a required field'),
      email: yup.string().email().required('Email is a required field'),
      phoneNumber: yupPhoneValidator({
        requiredMessage: 'Phone number is a required field',
      }),
      birthDate: yup
        .string()
        .date({
          format: 'MM/DD/YYYY',
          specialRule: 'birthdate',
        })
        .required('Date of Birth is a required field'),
      preferredLanguage: yup
        .string()
        .required('Preferred language is a required field'),
      abcIds: yup.string().required('Company name is a required field'),
      state: yup.string().required('State is a required field'),
    }),
  })

  return (
    <>
      <Breadcrumbs items={[{ label: 'Create New Lead' }]} />

      <BasicTemplate
        title="Create New Lead"
        description="Benestream Client Employee"
      >
        <Form providerValue={formik}>
          <BasicBlock>
            <CreateLeadContactInformation formik={formik} />

            <BenestreamPersonFormAddressBlock showCopy={false} withPadding />

            <CreateLeadCommunicationPreferences />

            <CreateLeadBottomButtons />
          </BasicBlock>
        </Form>
        {result !== null ? (
          <ModalResult
            isSuccess={result === 'success'}
            description={
              result !== 'success'
                ? result
                : 'The lead has been created successfully.'
            }
            title={result === 'success' ? 'Lead Created!' : ''}
            onClose={handleCloseResult}
          />
        ) : null}
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadPage,
  'is_enable_benestream_create_lead_view',
  true,
)
