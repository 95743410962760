import { Box, Button, Card, Flex, Group, Select, Text, TextInput } from '@mantine/core'

import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'
import RichText from 'src/v2/commons/components/RichText/RichText'

import styles from './createEmailTemplate.module.scss'
import createEmailTemplatesContainer from '../../containers/createEmailTemplate.container'
import SuccessErrorModal from '@v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel', href: '/' },
  { title: 'Email Template' },
]

const OPTION_TYPE_EMAIL = [
  { value: 'PUBLIC', label: 'Public' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'TRANSACTIONAL', label: 'Transactional' },
]

const CreateEmailTemplateView = () => {
  const {
    onSubmit,
    emailTemplateForm,
    setEmailBody,
    showSuccessModal,
    setShowSucessModal,
    pathToViewEmailTempalte,
    handleViewEmailTemplates,
  } = createEmailTemplatesContainer()

  return (
    <BasicTemplate title="Create Email Template" breadCrumbItems={breadCrumbItems}>
      <Card shadow="lg" radius="md" style={{ padding: '26px' }}>
        <Box>
          <Text mt={20} className={styles.title}>
            Template Information
          </Text>
          <Flex gap="xl">
            <TextInput
              label="Subject"
              style={{ flex: .9 }}
              required
              {...emailTemplateForm.getInputProps('subject')}
            />
            <Select
              required
              style={{ flex: .3 }}
              label="Type"
              {...emailTemplateForm.getInputProps('type')}
              data={[...OPTION_TYPE_EMAIL]}
            />
          </Flex>
          <Flex gap="xl" mt="lg">
            <TextInput
              required
              label="Description"
              style={{ flex: 1 }}
              {...emailTemplateForm.getInputProps('description')}
            />
          </Flex>
          <Flex gap="xl" mt="lg">
            <Box flex={1}>
              <Text size='sm'>Message</Text>
              <RichText
                className={styles.wrapper}
                value={emailTemplateForm.getValues().body}
                onChange={setEmailBody}
                placeholder="Your Message..."
                showAttachments={false}
                showHtmlTextButton={true}
              />
            </Box>
          </Flex>
          <Group mt="xl" justify='space-between'>
            <Button variant="default" onClick={handleViewEmailTemplates}>
              Cancel
            </Button>
            <Button variant="filled" onClick={onSubmit}>
              Save
            </Button>
          </Group>
        </Box>
      </Card>
      <SuccessErrorModal
        status="success"
        title="Template saved successfully!"
        subTitle="A new email template has been saved successfully!"
        buttonText="View Template"
        opened={showSuccessModal}
        onClose={() => setShowSucessModal(false)}
        path={pathToViewEmailTempalte}
        closeButtonText={'Go To Template List'}
        closeButtonAction={handleViewEmailTemplates}
      />
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateEmailTemplateView,
  'is_get_roles_enabled',
  true,
)
