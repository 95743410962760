import { useField } from 'formik'

import { FieldWrapper, Input } from 'src/common/components'
import dayjs from 'src/common/libs/dayjs'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './deactivation-benefit-how-long.module.scss'

const DeactivationBenefitHowLong = () => {
  const [{ value }] = useField('service.startDate')

  const years = value ? dayjs().diff(dayjs(value), 'year') : 0
  const months = value ? dayjs().diff(dayjs(value), 'month') - years * 12 : 0

  return (
    <FieldWrapper
      className={styles.wrapper}
      label="How long have you driven for this service?"
      name="how-long"
    >
      <div className={styles.inner}>
        <div>
          <Input
            name="years"
            value={years}
            disabled
            withError={false}
            onChange={() => false}
          />

          <label>Years</label>
        </div>

        <div>
          <Input
            name="months"
            value={months}
            disabled
            withError={false}
            onChange={() => false}
          />

          <label>Months</label>
        </div>
      </div>
    </FieldWrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitHowLong,
  'is_enable_deactivation_benefit_how_long',
  true,
)
