import { Radio, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import {
  type MergedPersonModel,
  type RecordInformationFormValues,
  type RecordInformationTableData,
  type TransformReacordInformationValues,
} from '@v2/domains/merging-tool/models/merging-tool.model'
import { DataTable } from 'mantine-datatable'
import { useState } from 'react'

import SubmitMergeComponent from './SubmitMerge/SubmitMerge.component'
import styles from './data-table.module.scss'

interface RecordInformationProps {
  tableData: RecordInformationTableData[]
  recodrInformationForm: UseFormReturnType<RecordInformationFormValues | {}>
  setEntireColumn: (id: string) => void
  isChecked: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => boolean
  setCellValue: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => void
  onSubmit: (
    values: RecordInformationFormValues,
    primaryEntityId: string,
  ) => void
  isLoading: boolean
}

const renderCellValue = (
  value: TransformReacordInformationValues[keyof TransformReacordInformationValues],
) => {
  if (value === undefined) {
    return ''
  } else if (!(typeof value === 'boolean') && !value) {
    return 'None'
  } else if (Array.isArray(value)) {
    return value.join(', ') || 'None'
  } else if (typeof value === 'object') {
    return `${value?.person?.firstName}, ${value?.person?.lastName ?? ''}`
  } else {
    return value
  }
}
const RecordInformationView = ({
  tableData,
  recodrInformationForm,
  setEntireColumn,
  isChecked,
  setCellValue,
  onSubmit,
  isLoading,
}: RecordInformationProps) => {
  const [selectedColumn, setSelectedColumn] = useState('')

  const tableColumns = tableData.length
    ? Object.keys(tableData[0])
        .sort()
        .map((colHeader, idx) => ({
          accessor: colHeader,
          title: `Duplicate ${idx + 1}`,
        }))
        .filter(({ accessor }) => !['field'].includes(accessor))
    : []

  return (
    !!tableData.length && (
      <>
        <DataTable
          height={'75vh'}
          style={{ zIndex: 0 }}
          pinFirstColumn
          withColumnBorders
          withTableBorder
          rowClassName={({ field }) =>
            field === 'Use as Primary' ? styles['secondary-header'] : undefined
          }
          records={tableData}
          idAccessor="field"
          fz="16px"
          defaultColumnProps={{
            titleClassName: styles['column-title'],
            width: '200px',
          }}
          defaultColumnRender={(row, idx, accessor) => {
            let value = row[accessor]
            const isUsePrimary = row.field === 'Use as Primary'
            value = renderCellValue(value)

            return (
              <Radio
                label={value.toString()}
                variant={isUsePrimary ? 'filled' : 'outline'}
                color={isUsePrimary ? 'white' : ''}
                iconColor="blue"
                name={row.field as string}
                mod={{ primary: isUsePrimary }}
                classNames={{
                  body: styles['radio-btn-body'],
                  root: styles['radio-btn-root'],
                  icon: styles['radio-btn-icon'],
                  label: styles['radio-btn-label'],
                  labelWrapper: styles['radio-btn-warapper-label'],
                }}
                key={`${row.field}-${idx}-${accessor}`}
                checked={
                  isUsePrimary
                    ? accessor === selectedColumn
                    : isChecked(
                        row.field as keyof MergedPersonModel,
                        accessor as MergedPersonModel['id'],
                      )
                }
                onChange={
                  isUsePrimary
                    ? () => {
                        setSelectedColumn(accessor as MergedPersonModel['id'])
                        setEntireColumn(accessor as MergedPersonModel['id'])
                      }
                    : () =>
                        setCellValue(
                          row.field as keyof MergedPersonModel,
                          accessor as MergedPersonModel['id'],
                        )
                }
              />
            )
          }}
          columns={[
            {
              accessor: 'field',
              title: '',
              titleStyle: { backgroundColor: 'white' },
              cellsClassName: styles['column-cell-field'],
              render: ({ field }) =>
                field === 'Use as Primary' ? '' : (field as string),
            },
            {
              accessor: 'merged',
              title: 'Merged Record',
              titleClassName: styles['sticky-column'],
              cellsClassName: styles['sticky-cell'],
              render: ({ field }, idx) => {
                let value = (
                  recodrInformationForm.getValues() as RecordInformationFormValues
                )[field as keyof MergedPersonModel]?.value
                value = renderCellValue(value)
                return field !== 'Use as Primary' ? (
                  <Text
                    fz="14px"
                    className={styles['text-root']}
                    variant="unstyled"
                    key={`merged-${field}-${idx}`}
                    mod={{
                      override:
                        selectedColumn &&
                        !(
                          (
                            recodrInformationForm.getValues() as RecordInformationFormValues
                          )[field as keyof MergedPersonModel]?.source ===
                          selectedColumn
                        ),
                    }}
                  >
                    {value.toString()}
                  </Text>
                ) : (
                  ''
                )
              },
            },
            ...tableColumns,
          ]}
        />
        <SubmitMergeComponent
          onSubmit={onSubmit}
          primaryDuplicateId={selectedColumn}
          recodrInformationForm={recodrInformationForm}
          isLoading={isLoading}
        />
      </>
    )
  )
}

export default RecordInformationView
