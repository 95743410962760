import cx from 'classnames'
import { useField, useFormikContext } from 'formik'

import { FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import styles from './textarea.module.scss'

const Textarea = (props: IField) => {
  const { className, ...rest } = props
  const {
    name,
    label,
    inputClassName,
    value,
    type,
    placeholder,
    maxLength,
    disabled,
    onChange,
  } = rest

  const [field, meta] = useField(name)
  const { isSubmitting } = useFormikContext()

  const passedProps = {
    className: cx(
      meta.touched && meta.error && styles.error,
      !label && styles.withoutLabel,
      inputClassName,
    ),
    type,
    placeholder,
    maxLength,
    disabled: isSubmitting || disabled,
    ...field,
    onChange: onChange || field.onChange,
    value: typeof value !== 'undefined' ? value : field.value,
  }

  return (
    <FieldWrapper {...rest} className={cx(styles.wrapper, className)}>
      <textarea {...passedProps} />
    </FieldWrapper>
  )
}

export default Textarea
