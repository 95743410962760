import { useParams } from 'react-router-dom'

import { Table } from 'src/common/components'
import { IUserOrganization } from 'src/common/interfaces'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './users-table.module.scss'

interface UsersTableProps {
  users: IUserOrganization[]
}

const UsersTable = (props: UsersTableProps) => {
  const { users } = props
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <Table>
      {users.map((u) => (
        <Table.Row
          key={u.id}
          id={u.id}
          editable
          editLink={`/${organizationId}${PRIVATE_ROUTES.USERS.path}/:id`}
        >
          <Table.Column className={styles.name} title="Name" showMobile>
            {`${u.firstName} ${u.lastName}`}
          </Table.Column>

          <Table.Column title="Email" showMobile>
            {u.email}
          </Table.Column>

          <Table.Column title="Organizations ">
            {u.organizations?.map((o, i) => (
              <div key={i}>
                <p>
                  <strong> {o.name + ' - ' + o.code}</strong>
                  {o.memberships?.map((m) => (
                    <p>
                      {m.role} ({m.status})
                    </p>
                  ))}
                  <br></br>
                </p>
              </div>
            ))}
          </Table.Column>
        </Table.Row>
      ))}
    </Table>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UsersTable,
  'is_get_users_enabled',
)
