import { Button, Icons, Modal } from 'src/common/components'
import { BENESTREAM_ORG_ID } from 'src/common/config'
import { useChangeOrganization } from 'src/common/hooks'
import { IModal } from 'src/common/interfaces'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { setCurrentTheme } from '../../../../v2/domains/ui/models/ui.store'
import { useAppDispatch, useAppSelector } from '../../../../v2/store/hooks'
import styles from './org-approval-modal.module.scss'

interface OrgApprovalModalProps extends IModal {
  onSubmit: () => void
}

const OrgApprovalModal = (props: OrgApprovalModalProps) => {
  const dispatch = useAppDispatch()
  const { onClose, onSubmit } = props

  const changeOrganizationMutation = useChangeOrganization()

  const globalSearchChangeOrganization = useAppSelector(
    (state) => state.organization.globalSearchChangeOrganization,
  )

  const handleSubmit = () => {
    if (onClose) {
      onClose()
      changeOrganizationMutation
        .mutateAsync({
          organizationId: globalSearchChangeOrganization.newOrganizationId,
          membershipId: globalSearchChangeOrganization.newMembershipId,
        })
        .then(() => {
          dispatch(
            setCurrentTheme(
              BENESTREAM_ORG_ID ===
                globalSearchChangeOrganization.newOrganizationId
                ? CurrentTheme.BENESTREAM
                : CurrentTheme.DEFAULT_360,
            ),
          )
        })
        .catch((error) => {
          console.log('error', error)
        })
    }

    onSubmit()
  }

  return (
    <Modal id="changeOrgApproval" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.Refresh />
        </div>

        <div className={styles.title}>
          Record Belongs to Another Organization
        </div>

        <div className={styles.description}>
          To access and work with this record, you need to switch organizations.
          Do you want to switch organizations?
        </div>

        <div className={styles.buttons}>
          <Button size="small" template="secondary" onClick={onClose}>
            No, stay in current Organization
          </Button>

          <Button size="small" onClick={handleSubmit}>
            Yes, switch Organization
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  OrgApprovalModal,
  'is_enable_org_approval_view',
  true,
)
