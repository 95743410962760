export const getLeadStatusColor = (status: string) => {
  switch (status) {
    case 'invalid license':
    case 'missing info':
    case 'name mismatch':
    case 'expired':
    case 'duplicate':
      return 'red'
    case 'New':
    case 'Partial':
    case 'Signed Up':
    case 'Eligible Partially':
    case 'opt out':
    case 'license grace period deactivated':
    case 'license grace period':
      return 'orange'
    case 'Processing':
    case 'Eligible':
    case 'active':
      return 'green'
  }

  return 'grey'
}
