import cx from 'classnames'
import { useState } from 'react'

import { Modal, SelectOrganization } from 'src/common/components'
import { BENESTREAM_ORG_ID } from 'src/common/config'
import { useChangeOrganization } from 'src/common/hooks'
import { IModal } from 'src/common/interfaces'
import { cleanStringToBeUrlReadable } from 'src/default/helpers/clean-string'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'

import { useRouter } from '../../../../../default/helpers/navigation'
import { setCurrentTheme } from '../../../../../v2/domains/ui/models/ui.store'
import { useAppDispatch, useAppSelector } from '../../../../../v2/store/hooks'
import styles from './select-organization-wrapper-modal.module.scss'

interface SelectOrganizationWrapperModalProps extends IModal {}

const SelectOrganizationWrapperModal = (
  props: SelectOrganizationWrapperModalProps,
) => {
  const router = useRouter()
  const dispatch = useAppDispatch()

  const { onClose } = props
  const changeOrganizationMutation = useChangeOrganization()

  const organizationsByUser = useAppSelector(
    (state) => state.organization.organizationsByUser,
  )

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)
  const [errorState, setErrorState] = useState({ error: false, message: '' })

  const handleChangeOrganizationId = (
    organizationId: string,
    membershipId: string,
  ) => {
    const newOrganization = organizationsByUser.find(
      (organization) => organization.id === organizationId,
    )
    changeOrganizationMutation
      .mutateAsync({ organizationId, membershipId })
      .then((response) => {
        if (!response?.data?.success && response?.data?.statusCode === 403) {
          setErrorState({
            error: true,
            message:
              'You can access to this organization, please contact with your Administrator',
          })
          return
        }
        dispatch(
          setCurrentTheme(
            BENESTREAM_ORG_ID === organizationId
              ? CurrentTheme.BENESTREAM
              : CurrentTheme.DEFAULT_360,
          ),
        )
        onClose?.()
        router.push(
          `/${cleanStringToBeUrlReadable(newOrganization?.name)}/leads`,
          false,
        )
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  //TODO review this
  const handleCustomEvents = (eventId: string, event: boolean) => {
    if (eventId === 'toggleList') {
      setIsSelectOpen(event)
    }
  }

  return (
    <Modal id="selectOrganizationModal" onClose={onClose}>
      <div
        className={cx(styles.wrapper, isSelectOpen && styles.withOpenSelect)}
      >
        {errorState.error ? (
          <div>
            <h2>Error</h2>
            <p>{errorState.message}</p>
          </div>
        ) : (
          <>
            <div className={styles.title}>Please select your organization</div>

            <SelectOrganization
              optionsWrapperClassName={styles.optionsWrapper}
              onChange={handleChangeOrganizationId}
              onCustomEvent={handleCustomEvents}
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export default SelectOrganizationWrapperModal
