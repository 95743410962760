import * as yup from 'yup'

import { validatePhoneFormat } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'

declare module 'yup' {
  interface StringSchema {
    name(msg?: string): this
    date(props: {
      format: string
      message?: string
      specialRule?: string
    }): this
    phone(msg?: string): this
    driverLicense(msg?: string): this
    tlcNumber(msg?: string): this
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'name',
  function (message = 'must be a valid name') {
    return this.test('valid-name', message, function (value = '') {
      return value ? /^[a-zA-Z\s\-]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(yup.string, 'date', function (props) {
  const {
    format,
    message = 'must be a valid date',
    specialRule = 'none',
  } = props

  return this.test('valid-date', message, function (value = '') {
    if (!value) {
      return true
    }

    let isValid = true

    switch (specialRule) {
      case 'birthdate':
        isValid = dayjs(value, format, undefined, true).year() <= dayjs().year()
        break
    }

    return dayjs(value, format, undefined, true).isValid() && isValid
  })
})

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phone',
  function (message = 'must be a valid phone number') {
    return this.test('valid-phone', message, function (value = '') {
      return value
        ? //? /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
          validatePhoneFormat(value)
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'driverLicense',
  function (message = 'must be a valid driver license') {
    return this.test('valid-driverLicense', message, function (value = '') {
      return value ? /^[a-zA-Z0-9]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'tlcNumber',
  function (message = 'must be a valid TLC number') {
    return this.test('valid-tlcLicense', message, function (value = '') {
      return value ? /^[0-9]*$/.test(value) : true
    })
  },
)

export default yup
