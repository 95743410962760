import { EnumPersonLanguage } from 'src/common/interfaces'
import {
  DRIVERS_DEMAND_JUSTICE_ID,
  IDG_MACHINISTS_UNION,
  UNION360,
} from 'src/default/config'

export const getCookie = (name: string) => {
  if (typeof document !== 'undefined') {
    let cookieArray = document.cookie.split(';')
    let cookieName = `${name}=`

    for (let cookie of cookieArray) {
      cookie = cookie.trim()
      if (cookie.indexOf(cookieName) == 0) {
        return cookie.substring(cookieName.length, cookie.length)
      }
    }
  }

  return null
}

export const deleteCookie = (name: string) => {
  if (typeof document !== 'undefined') {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
}

export const validateIfShowRating = (currentOrganizationId: string) => {
  return (
    currentOrganizationId === DRIVERS_DEMAND_JUSTICE_ID ||
    currentOrganizationId === IDG_MACHINISTS_UNION ||
    currentOrganizationId === UNION360
  )
}

export const getPreferredLanguage = (preferredLanguage: string = '') => {
  return preferredLanguage
    ? EnumPersonLanguage[preferredLanguage as keyof typeof EnumPersonLanguage]
    : preferredLanguage
}

export const cleanStringToBeUrlRedeable = (value?: string) => {
  if (typeof value === 'undefined') {
    return
  }
  const charsToRemove = /[_;.,/]/g
  return value
    .replace(charsToRemove, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .toLowerCase()
}
