import { IEligibilityGuideline } from 'src/benestream/interfaces'
import { BasicBlock } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BenestreamPersonEligibilitiesItem from './BenestreamPersonEligibilitiesItem'
import BenestreamPersonEligibilitiesMedicaid from './BenestreamPersonEligibilitiesMedicaid'
import styles from './benestream-person-eligibilities.module.scss'

interface BenestreamPersonEligibilitiesProps {
  state?: string
  eligibilityGuideline: IEligibilityGuideline
}

const BenestreamPersonEligibilities = (
  props: BenestreamPersonEligibilitiesProps,
) => {
  const { state, eligibilityGuideline } = props

  const { id, stateAbbreviation, stateName, ...dataToShow } =
    eligibilityGuideline || {}
  const {
    householdSize,
    fpl,
    otherAdultIndividual,
    parentFamilyOf3,
    immigrationRules,
    povertyGuidelineForHousehold,
    premiums,
    waitPeriods,
    additionalNotes,
  } = dataToShow

  const showEmptyDescription = !Object.keys(dataToShow).length //TODO refactor it
  const povertyGuidelineForHouseholdUSD = new Intl.NumberFormat('en-US', {
    //TODO mb move it to helper
    style: 'currency',
    currency: 'USD',
  }).format(Number(povertyGuidelineForHousehold))

  return (
    <BasicBlock className={styles.wrapper} title="Eligibility Information">
      {!showEmptyDescription ? (
        <>
          <div>
            <div className={styles.numberItem}>
              <div className={styles.numberItemLabel}>Household Size:</div>
              <div>{householdSize}</div>
            </div>

            <div className={styles.numberItem}>
              <div className={styles.numberItemLabel}>% FPL:</div>
              <div>{`${fpl}%`}</div>
            </div>
          </div>

          <div className={styles.accordions}>
            <BenestreamPersonEligibilitiesItem
              title="Poverty Guidelines"
              value={`<p>The annual poverty level for a household size of ${householdSize} in ${state} is ${povertyGuidelineForHouseholdUSD}</p>`}
            />

            <BenestreamPersonEligibilitiesItem
              title="Immigration Rules"
              value={immigrationRules}
            />

            <BenestreamPersonEligibilitiesMedicaid
              parentFamilyOf3={parentFamilyOf3}
              otherAdultIndividual={otherAdultIndividual}
            />

            <BenestreamPersonEligibilitiesItem
              title="Premiums"
              value={premiums}
            />

            <BenestreamPersonEligibilitiesItem
              title="Wait Periods"
              value={waitPeriods}
            />

            {additionalNotes ? (
              <BenestreamPersonEligibilitiesItem
                title="Additional Notes"
                value={additionalNotes}
              />
            ) : null}
          </div>
        </>
      ) : (
        <div className={styles.emptyDescription}>
          <span>
            For quick screen opportunities, the State must be set on the Head of
            Household related contact.
          </span>
          <br />
          <br />
          <span>
            Please set a State on the record in order to get eligibility
            information.
          </span>
        </div>
      )}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonEligibilities,
  'is_get_benestream_eligibility_guidelines_enabled',
)
