import { IPerson } from 'src/common/interfaces'

import { EnumDocumentType } from '../interfaces'

export const getPersonDocuments = (documents: IPerson['documents']) => {
  const driverLicense =
    documents?.find((item) => item.type === EnumDocumentType.DRIVER_LICENSE)
      ?.number || ''
  const tlc =
    documents?.find((item) => item.type === EnumDocumentType.TLC)?.number || ''
  return {
    driverLicense,
    tlc,
  }
}
