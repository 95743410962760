import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IUseMutateOfficeVisit {
  id?: string
  reasonForVisit?: string
  assignedToId?: string
  status?: string
  note?: string | null
}

const useMutateOfficeVisit = () =>
  useMutation({
    mutationFn: async (data: IUseMutateOfficeVisit) => {
      const body = { ...data }
      delete body.id
      const url = `/office-visit/${data.id}`

      return api.put(url, body)
    },
  })

export default useMutateOfficeVisit
