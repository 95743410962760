import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import {
  BasicBlock,
  Form,
  SearchResultFilter,
  Table,
} from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import {
  concatQueryPages,
  formatDate,
  getEnrollmentByOrgId,
  getPhoneWithMask,
  removeUnderScoreAndTransformToLowerCase,
} from 'src/common/helpers'
import { useSearchPerson } from 'src/common/hooks'
import {
  InfiniteBasicTemplate,
  SearchResultTemplate,
} from 'src/common/templates'
import { IPersonSearchResultItem } from 'src/v2/domains/person/models/person.model'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import SearchResultEmptyPersons from './SearchResultEmptyPersons'
import styles from './search-result-page.module.scss'

const SearchResultPageWrapper = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const isAssignMultipleTasksEnabled = !!userProfile.policies.find(
    (policy) => policy === 'is_create_multiple_task_enabled',
  )
  const filterFormik = useFormik({
    initialValues: {
      globalMode: false,
    },
    onSubmit: () => {},
  })
  const { globalMode } = filterFormik.values

  return (
    <Form providerValue={filterFormik}>
      {!globalMode ? (
        <SearchResultPage />
      ) : (
        <SearchResultTemplate
          isCrossOrganization={globalMode}
          FilterComponent={<SearchResultFilter />}
          isAssignMultipleTasksEnabled={isAssignMultipleTasksEnabled}
        />
      )}
    </Form>
  )
}

const SearchResultPage = () => {
  const { searchValue = '' } = useParams<{ searchValue?: string }>()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    isLoading,
  } = useSearchPerson({
    searchValue,
    organizationId: currentOrganizationId,
    isExtendAsBNST: true,
    limit: PAGINATION_LIMIT,
  })

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const items = concatQueryPages<IPersonSearchResultItem>(pages)

  const title = `Search results for "${decodeURIComponent(searchValue)}"`

  return (
    <InfiniteBasicTemplate
      title={title}
      scrollPromise={handlePageScroll}
      isLoading={pages.length === 0 && isLoading}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: items?.length ?? 0,
      }}
    >
      <SearchResultFilter />

      <BasicBlock paddings="big" isLoading={isFetchingNextPage}>
        {items.length ? (
          <Table>
            {items.map((c: IPersonSearchResultItem) => (
              <Table.Row
                key={c.id}
                id={c.id}
                editable={true}
                meta={{
                  type: getEnrollmentByOrgId(
                    c.enrollments,
                    currentOrganizationId,
                  )?.isVerified
                    ? 'Account'
                    : 'Lead',
                }}
                editLink={({ type }) =>
                  `/${currentOrganizationId}${
                    PRIVATE_ROUTES[type === 'Lead' ? 'LEADS' : 'ACCOUNTS'].path
                  }/:id`
                }
              >
                <Table.Column title="Name" showMobile>
                  {`${c.firstName} ${c.lastName}`}
                </Table.Column>

                <Table.Column title="Type">
                  {getEnrollmentByOrgId(c.enrollments, currentOrganizationId)
                    ?.isVerified
                    ? 'Account'
                    : 'Lead'}
                </Table.Column>

                <Table.Column title="Email" showMobile>
                  {c.email}
                </Table.Column>

                <Table.Column title="Mobile" showMobile>
                  {getPhoneWithMask(c.phoneNumber)}
                </Table.Column>

                <Table.Column
                  className={styles.capitalize}
                  title="Status"
                  showMobile
                >
                  {removeUnderScoreAndTransformToLowerCase(
                    getEnrollmentByOrgId(c.enrollments, currentOrganizationId)
                      ?.status || '',
                  )}
                </Table.Column>

                <Table.Column title="Company" showMobile>
                  {
                    getEnrollmentByOrgId(c.enrollments, currentOrganizationId)
                      ?.associatedBenefitContract?.name
                  }
                </Table.Column>

                <Table.Column title="Worksite" showMobile>
                  {c.worksite}
                </Table.Column>

                <Table.Column title="Source" showMobile>
                  {c.source}
                </Table.Column>

                <Table.Column title="Created Date">
                  {formatDate(c.createdAt, 'MM/DD/YYYY')}
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        ) : isFetched ? (
          <SearchResultEmptyPersons />
        ) : null}
      </BasicBlock>
    </InfiniteBasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SearchResultPageWrapper,
  'is_get_benestream_persons_enabled',
)
