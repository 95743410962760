import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetCatalogAbcsByCurrentUser = () => {
  return useQuery({
    queryKey: [`useGetCatalogAbcsByCurrentUser`],
    queryFn: async () => await api.get(`abc/catalog/getAllByCurrentUser`),
  })
}

export default useGetCatalogAbcsByCurrentUser
