import { EnumDocumentType } from 'src/v2/domains/person/models/document.model'
import { PersonModel } from 'src/v2/domains/person/models/person.model'

export const getPersonDocuments = (documents: PersonModel['documents']) => {
  const driverLicense =
    documents?.find((item) => item.type === EnumDocumentType.DRIVER_LICENSE)
      ?.number || ''
  const tlc =
    documents?.find((item) => item.type === EnumDocumentType.TLC)?.number || ''
  return {
    driverLicense,
    tlc,
  }
}
