import {
  Box,
  Button,
  Card,
  Center,
  Loader,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { Icons } from 'src/common/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'
import { useRouter } from 'src/v2/commons/utils/navigation'

import RoleItem from '../components/RoleItem'
import { useCreateRoleContainer } from '../containers/useCreateRole.container'
import useRolesContainer from '../containers/useRoles.container'
import { RoleModel } from '../models/role.model'
import styles from './role.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Roles' },
]

const RolesView = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const { data: roles } = useRolesContainer()
  const { isLoading, onSubmit, roleCreateForm } = useCreateRoleContainer()
  const router = useRouter()

  if (roles) {
    return (
      <BasicTemplate
        header={
          <Button
            variant="filled"
            leftSection={<Icons.Plus />}
            onClick={() => open()}
          >
            Create New Role
          </Button>
        }
        title="Roles"
        breadCrumbItems={breadCrumbItems}
      >
        <Card shadow="lg" radius="lg" className={styles.defaultCard}>
          <Box c="var(--mantine-color-primary-5)">
            {roles.map((role: RoleModel) => (
              <RoleItem
                key={role.id}
                data={role}
                onSelect={(id: RoleModel['id']) => {
                  router.push(
                    `${PRIVATE_ROUTES.ROLES_ADMINISTRATION.path}/${id}`,
                  )
                }}
              />
            ))}
          </Box>
        </Card>
        <Modal title="Create New Role" opened={opened} onClose={close}>
          <Stack gap="lg">
            <TextInput
              key="name"
              label="Name"
              withAsterisk
              {...roleCreateForm.getInputProps('name')}
            />
            <Button
              loading={isLoading}
              onClick={() => onSubmit()}
              variant="filled"
            >
              Save
            </Button>
          </Stack>
        </Modal>
      </BasicTemplate>
    )
  } else {
    return (
      <Center className={styles.defaultCard}>
        <Loader size={30} />
      </Center>
    )
  }
}

export default withErrorBoundaryAndPolicyCheck(
  RolesView,
  'is_get_roles_enabled',
)
