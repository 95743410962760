import cx from 'classnames'
import { useField, useFormikContext } from 'formik'

import { Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './basic-input.module.scss'

const BasicInput = (props: React.PropsWithChildren<IField>) => {
  const {
    className,
    inputClassName,
    name,
    type,
    label,
    placeholder,
    size = 'normal',
    disabled,
    icon,
    maxLength,
    min,
    max,
    value,
    strictError,
    autoFocus,
    template = 'basic',
    onChange,
    children,
    autoComplete,
    onKeyDown,
    onBlur,
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [field, meta] = useField({ name })
  const { isSubmitting } = useFormikContext()

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  const passedProps = {
    className: cx(
      styles[`${currentTheme}Slice`],
      (strictError || (meta.touched && meta.error)) && styles.error,
      !label && styles.withoutLabel,
      IconComponent && styles.withIcon,
      styles[`template-${template}`],
      styles[`${inputClassName}`],
    ),
    type,
    placeholder,
    maxLength,
    autoFocus,
    disabled: isSubmitting || disabled,
    ...field,
    value:
      typeof value !== 'undefined'
        ? value
        : field.value === null
        ? ''
        : field.value,
    autoComplete,
    onKeyDown,
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const outerFunction = onChange || field.onChange

    if (type === 'number') {
      if (min && Number(value) < min) {
        return
      }

      if (max && Number(value) > max) {
        return
      }
    }

    outerFunction(event)
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const outerFunction = onChange || field.onChange
    onBlur?.(e) || passedProps.onBlur?.(e)

    e.target.value = e.target.value.trim()

    outerFunction(e)
  }

  return (
    <div
      className={cx(
        styles.inputWrapper,
        styles[`${currentTheme}Slice`],
        styles?.[size],
        className,
      )}
    >
      {IconComponent ? (
        <span className={styles.icon}>
          <IconComponent />
        </span>
      ) : null}

      {!children ? (
        <input
          {...passedProps}
          data-name={name}
          onChange={handleChange}
          onBlur={handleOnBlur}
          onWheel={
            props.disableOnWheel ? (e) => e?.currentTarget?.blur() : undefined
          }
        />
      ) : (
        <div
          className={cx(
            styles.input,
            disabled && styles.disabled,
            inputClassName,
          )}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default BasicInput
