import { Dayjs } from 'dayjs'
import { useEffect } from 'react'

import { Select } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IOption } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import styles from './month-select.module.scss'

interface MonthSelectProps {
  futureDate?: boolean
  pastDate?: boolean
  date: Dayjs
  yearsOptions: IOption[]
  onChange: (value: Dayjs) => void
}

const MonthSelect = (props: MonthSelectProps) => {
  const {
    date,
    //yearsOptions,
    onChange,
    futureDate = false,
    pastDate = true,
  } = props

  const options = (() => {
    const past = pastDate
    const future = futureDate

    const current = Number(dayjs().month())
    const result = []

    for (let i = 0; i < 12; i++) {
      if (date.year() === dayjs().year()) {
        if ((!past && i < current) || (!future && i > current)) {
          continue
        }
      }

      result.push({
        value: String(i),
        label: formatDate(dayjs().month(i), 'MMMM'),
      })
    }

    return result
  })()

  const handleChange = (monthValue: string) => {
    const newDate = date.clone().month(Number(monthValue))
    onChange(newDate)
  }

  useEffect(() => {
    const checkValidMonth = options.find(
      (option) => option.value === String(date.month()),
    )

    if (!checkValidMonth) {
      handleChange(options[options.length - 1].value)
    }
  }, [formatDate(date, 'YYYY-MM-DD')])

  // const handlePrev = () => {
  //   const newDate = date.clone().subtract(1, 'month')
  //   onChange(newDate)
  // }

  // const handleNext = () => {
  //   const newDate = date.clone().add(1, 'month')
  //   onChange(newDate)
  // }

  const currentValue = String(date.month())

  // const formatedYear = formatDate(date, 'YYYY')

  // const showNextButton =
  //   yearsOptions[0].value !== formatedYear ||
  //   (yearsOptions[0].value === formatedYear &&
  //     Number(currentValue) !== options.length - 1)
  // const showPrevButton =
  //   yearsOptions[yearsOptions.length - 1].value !== formatedYear ||
  //   (yearsOptions[yearsOptions.length - 1].value === formatedYear &&
  //     Number(currentValue) !== 0)

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.arrow}>
        {showPrevButton ? (
          <div onClick={handlePrev}>
            <Icons.ArrowSelect />
          </div>
        ) : null}
      </div> */}

      <Select
        inputClassName={styles.select}
        name="month"
        placeholder="Month"
        size="small"
        withError={false}
        options={options}
        value={currentValue}
        onChange={handleChange}
      />

      {/* <div className={styles.arrow}>
        {showNextButton ? (
          <div onClick={handleNext}>
            <Icons.ArrowSelect />
          </div>
        ) : null}
      </div> */}
    </div>
  )
}

export default MonthSelect
