import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { SmsCampaignCreate } from 'src/default/pages/Sms/sms-campaigns.interface'

const useMutationSmsCampaign = () =>
  useMutation({
    mutationFn: async (data: FormData) => {
      const url = '/sms-campaigns'

      return api.post(url, data, {
        transformRequest: (data, headers = {}) => {
          headers['Content-Type'] = 'multipart/form-data'

          return data
        },
      })
    },
  })

export default useMutationSmsCampaign
