import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { BasicBlock, Breadcrumbs } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import { useMutateForms } from 'src/default/hooks'
import { IFormTemplate } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import { FormTemplate } from '../components'

const CreateFormPage = () => {
  const router = useRouter()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const [result, setResult] = useState<string | null>(null)

  const formMutation = useMutateForms('create')

  const formik = useFormik<IFormTemplate>({
    initialValues: {
      notifyEmails: '',
      url: '',
      type: 'public',
      name: '',
      version: '1',
      userId: '',
      organizationId: currentOrganizationId ?? '',
    },
    onSubmit: async (values) => {
      const notifyEmails = values.notifyEmails.includes(',')
        ? values.notifyEmails.toString().split(',')
        : [values.notifyEmails]

      const newValues = { ...values, notifyEmails: notifyEmails }

      await formMutation
        .mutateAsync(newValues)
        .then(async ({ data }) => {
          setResult('success')
        })
        .catch((error) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      notifyEmails: yup.string().required('Emails for notify is required'),
      name: yup.string().required('Forms’s Name is required'),
      url: yup.string().required(),
      type: yup.string().required(),
      userId: yup.string().required('User is required'),
    }),
  })

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.FORM_TEMPLATES.path)
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Create New Form' }]} />

      <BasicTemplate title="Create New Form">
        <BasicBlock>
          <FormTemplate formik={formik} isCreating />
        </BasicBlock>
      </BasicTemplate>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={result !== 'success' ? result : undefined}
          onClose={handleCloseResult}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateFormPage,
  'is_crete_form_template_enabled',
)
