import { ICurrentOptionComponent } from 'src/default/interfaces'

import styles from './basic-current-option.module.scss'

const BasicCurrentOption = (props: ICurrentOptionComponent) => {
  const {
    value,
    placeholder,
    options = [],
    strictShowOption,
    showMetaForCurrentOption,
  } = props

  const currentOptionLabel = strictShowOption
    ? value
    : options?.find((o) => o.value === value)?.[
        showMetaForCurrentOption ? 'meta' : 'label'
      ]

  return (
    <>
      {currentOptionLabel || value ? (
        <div>{currentOptionLabel || value}</div>
      ) : (
        <div className={styles.placeholder}>{placeholder || ''}</div>
      )}
    </>
  )
}

export default BasicCurrentOption
