import { Link } from 'react-router-dom'

import { BasicInput, FieldWrapper, Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import { useRouter } from 'src/default/helpers/navigation'

import styles from './input-with-open-link.module.scss'

const InputWithOpenLink = (props: IField) => {
  const { url = '' } = props
  const router = useRouter()

  return (
    <FieldWrapper {...props}>
      <div className={styles.inputWithUrlWrapper}>
        <BasicInput className={styles.inputText} {...props} />
        <a
          className={styles.openLink}
          target="_blank"
          onClick={() => router.push(url)}
        >
          <Icons.NewLinkTab />
        </a>
      </div>
    </FieldWrapper>
  )
}

export default InputWithOpenLink
