import { IUpdateRoleResponse } from '../models/role.model'
import { useUpdateRoleUseCase } from '../useCases/useUpdateRole.usecase'

export const useUpdateRoleContainer = ({
  id,
  name,
}: {
  id: string
  name?: string
}): IUpdateRoleResponse => {
  const { roleUpdateForm, onSubmit, isLoading } = useUpdateRoleUseCase({
    id,
    name,
  })

  return { roleUpdateForm, onSubmit, isLoading }
}
