import DataLoaderWizard from 'src/common/components/wizard/DataLoaderWizard'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

const DataLoaderPage = () => {
  return <DataLoaderWizard />
}

export default withErrorBoundaryAndPolicyCheck(
  DataLoaderPage,
  'is_enable_data_loader_view',
  true,
)
