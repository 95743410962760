import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetCmsBenefitParams {
  benefitId?: string
}

const useGetCmsBenefitById = (params: GetCmsBenefitParams) => {
  const { benefitId } = params

  return useQuery({
    queryKey: [`getCmsBenefotById`, benefitId],
    queryFn: async () => await api.get(`/cms/${benefitId}`),
    enabled: !!benefitId,
  })
}

export default useGetCmsBenefitById
