import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'

import { useCreateRoleMutation } from '../models/role.api'
import { ICreateRoleResponse } from '../models/role.model'

export const useCreateRoleUseCase = (): ICreateRoleResponse => {
  const [createRole, { isLoading }] = useCreateRoleMutation()

  const roleCreateForm = useForm<{ name: string }>({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
    },
    validateInputOnBlur: true,
    validate: {
      name: isNotEmpty('Name is required!'),
    },
  })

  const onSubmit = () => {
    roleCreateForm.onSubmit(async (values) => {
      createRole(values.name)
        .unwrap()
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'Role succesfuly created',
          })
          window?.location.reload()
        })
        .catch(() => {
          notifications.show({
            title: 'Oops',
            message: 'There was an error',
            color: 'red',
          })
        })
    })()
  }

  return {
    roleCreateForm,
    onSubmit,
    isLoading,
  }
}
