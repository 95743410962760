import { FormikContextType } from 'formik'

import { Select, Table } from 'src/common/components'
import InputDateRange from 'src/default/components/Input/InputDateRange/InputDateRange'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import SubmissionsFilterByName from './SubmissionsFilterStatus'

interface SubmissionsFilterProps {
  providerValue: FormikContextType<any>
  total: number
  onSave?: () => void
}

const SubmissionsFilter = (props: SubmissionsFilterProps) => {
  return (
    <Table.Filter {...props} onSave={props.onSave}>
      <SubmissionsFilterByName />

      <Select
        name="isReviewed"
        placeholder="Reviewed"
        options={[
          {
            label: 'All',
            value: 'all',
          },
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]}
        withError={false}
        template="filter"
      />

      <Select
        name="isCompleted"
        placeholder="Completed"
        options={[
          {
            label: 'All',
            value: 'all',
          },
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]}
        withError={false}
        template="filter"
      />

      <InputDateRange
        name="createdDate"
        placeholder="From - To"
        withError={false}
        template="filter"
      />

      <Select
        name="orderBy"
        placeholder="Order By"
        options={[
          {
            value: 'createdAt DESC',
            label: 'Most Recent First',
          },
          {
            value: 'createdAt ASC',
            label: 'Oldest First',
          },
          {
            value: 'updatedAt DESC',
            label: 'Recently Updated First',
          },
          {
            value: 'updatedAt ASC',
            label: 'Least Recently Updated First',
          },
        ]}
        withError={false}
        //  value="createdAt DESC"
        template="filter"
      />
    </Table.Filter>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SubmissionsFilter,
  'is_enable_submissions_filter',
  true,
)
