import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { FieldWrapper } from 'src/common/components'
import { useGooglePlacesApi } from 'src/common/hooks'
import { IField } from 'src/common/interfaces'

import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './input-google-places.module.scss'

const GooglePlacesInput = (props: IField) => {
  const { name, placeholder, disabled, onChange, inputClassName = '' } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const googlePlacesApi = useGooglePlacesApi()

  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )

          if (!isClickInside) {
            setOptions([])
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setOptions([])
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const [field, meta, { setValue }] = useField({ name })

  const [placesIsLoading, setPlacesIsLoading] = useState<boolean>(false)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.currentTarget
    if (currentValue.length > 3 && !placesIsLoading) {
      setPlacesIsLoading(true)
      googlePlacesApi?.findPlace(event.currentTarget.value).then((results) => {
        if (results?.predictions) {
          const newOptions = results.predictions.map((p) => ({
            value: p.place_id,
            label: p.description,
          }))
          setOptions(newOptions)
          setPlacesIsLoading(false)
        }
      })
    }

    setValue(currentValue)
  }

  const handleSelect = (option: { value: string; label: string }) => {
    googlePlacesApi?.getPlaceById(option.value, (result) => {
      setValue(option.label)
      setOptions([])

      if (onChange) {
        onChange(result)
      }
    })
  }

  return (
    <FieldWrapper {...props}>
      <div
        className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}
        ref={selectRef}
      >
        <input
          className={cx(
            styles.basicInput,
            meta.error && meta.touched && styles.error,
            inputClassName ? styles[`${inputClassName}`] : null,
          )}
          data-name={name}
          placeholder={placeholder}
          {...field}
          value={field.value || ''}
          disabled={disabled}
          onChange={handleChange}
        />
        {options.length ? (
          <div className={styles.basicOptions}>
            {options?.map((o) => (
              <div key={o.value} onClick={() => handleSelect(o)}>
                {o.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </FieldWrapper>
  )
}

export default GooglePlacesInput
