import { BasicBlock } from 'src/common/components'
import { useDeleteCmsBenefit } from 'src/default/hooks'
import { ICmsBenefit } from 'src/default/interfaces'
import {
  BenefitsAdministrationImgName,
  BenefitsAdministrationToggleLink,
} from 'src/default/pages/Administration/BenefitsAdministration/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BenefitsAdministrationItemRemove from './BenefitsAdministrationItemRemove'
import styles from './benefits-administration-item.module.scss'

const getFirstHtmlString = (htmlString: string) => {
  const parser = new DOMParser()
  const htmlDocument = parser.parseFromString(htmlString, 'text/html')
  const firstElement = htmlDocument.body.firstElementChild

  if (firstElement === null) {
    return ''
  }

  const firstTagData = {
    tagName: firstElement.tagName,
    innerHTML: firstElement.innerHTML,
  }

  return `<${firstTagData.tagName}>${firstTagData.innerHTML}</${firstTagData.tagName}>`
}

interface BenefitsAdministrationItemProps extends ICmsBenefit {
  onDelete: () => void
}

const BenefitsAdministrationItem = (props: BenefitsAdministrationItemProps) => {
  const { id, name, content, icons, onDelete } = props

  const deleteMutation = useDeleteCmsBenefit()

  const handleRemove = async () => {
    await deleteMutation.mutateAsync(id)

    onDelete()
  }

  const firstHtmlString = getFirstHtmlString(content?.public?.en ?? '')

  return (
    <BasicBlock className={styles.wrapper}>
      <div className={styles.header}>
        <BenefitsAdministrationItemRemove name={name} onRemove={handleRemove} />
      </div>
      <div className={styles.inner}>
        <BenefitsAdministrationImgName img={icons.private} name={name} />

        <BenefitsAdministrationToggleLink benefitId={id} />

        {/* <div className={styles.title}>{title}</div> */}
        <div
          className={styles.preview}
          dangerouslySetInnerHTML={{ __html: firstHtmlString }}
        />
      </div>
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationItem,
  'is_enable_benefit_administration_item',
  true,
)
