import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

//TODO mb remove search property

interface GetPersonsParams {
  enabled?: boolean
  isVerified?: boolean
  organizationId: string | null
  limit?: number
  search?: string
  phoneNumber?: string
  orderBy?: string
}

const useGetPersons = (params: GetPersonsParams) => {
  const {
    enabled = true,
    isVerified,
    organizationId,
    limit,
    search,
    phoneNumber,
    orderBy,
  } = params
  return useInfiniteQuery({
    queryKey: [
      `getBenestreamPersons`,
      isVerified,
      organizationId,
      limit,
      search,
      phoneNumber,
    ],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/benestream/persons`, {
        signal,
        params: {
          organizationId,
          isVerified,
          limit,
          search,
          phoneNumber,
          offset: pageParam,
          orderBy,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length, //TODO ts,
    enabled,
  })
}

export default useGetPersons
