import { DatesRangeValue } from '@mantine/dates'
import { type UseFormReturnType } from '@mantine/form/lib/types'

import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import {
  PersonModel,
  type PersonsFiltersModel,
} from 'src/v2/domains/person/models/person.model'
import useAssignLeadUseCase from 'src/v2/domains/person/useCases/leads/useAssignLead.useCase'

import { AssignPersonModel } from '../../models/assignPerson.model'
import usePersonsUseCase from '../../useCases/usePersons.useCase'

interface IUseLeadsContainer {
  data: PersonsModel | undefined
  isLoading: boolean
  isSuccess: boolean
  isFetching: boolean
  personsFiltersForm: UseFormReturnType<PersonsFiltersModel>
  handleDateRangeChange: (value: DatesRangeValue) => void
  clearAllFilters: () => void
  handlePaginate: () => void
  organizationId: string
  handleAddRemoveLead: (
    lead: Pick<PersonModel, 'id'>['id'],
    addLead: boolean,
  ) => void
  handleSelectAllLeads: (payload: {
    isAllSelected: boolean
    allSelectedPersons: AssignPersonModel['filters']['selectedItems']
  }) => void
  selectedItems: Pick<PersonModel, 'id'>['id'][]
  isProgrammaticFormUpdateRef: React.MutableRefObject<boolean>
  handleClearStoreLead: () => void
  personsIds: string[]
  isAssignMultipleTasksEnabled: boolean
}

const useLeadsContainer = (): IUseLeadsContainer => {
  const {
    data,
    personsQueryStatus: { isLoading, isSuccess, isFetching },
    personsFiltersForm,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    organizationId,
    isProgrammaticFormUpdateRef,
    personsIds,
    isAssignMultipleTasksEnabled,
  } = usePersonsUseCase()

  const {
    handleAddRemoveLead,
    selectedItems,
    handleSelectAllLeads,
    handleClearStoreLead,
  } = useAssignLeadUseCase()

  return {
    isLoading,
    isSuccess,
    isFetching,
    data,
    personsFiltersForm,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    organizationId,
    isProgrammaticFormUpdateRef,
    handleAddRemoveLead,
    selectedItems,
    handleSelectAllLeads,
    handleClearStoreLead,
    personsIds,
    isAssignMultipleTasksEnabled,
  }
}

export default useLeadsContainer
