import { MantineProvider } from '@mantine/core'

import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'
import { useAppSelector } from 'src/v2/store/hooks'

import { benestreamTheme } from './themes/benestream'
import { defaultTheme } from './themes/default'

const ThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const currentTheme = useAppSelector((state) => state.ui.currentTheme)
  const theme =
    currentTheme === CurrentTheme.BENESTREAM ? benestreamTheme : defaultTheme

  return (
    <MantineProvider theme={theme} withGlobalClasses>
      {children}
    </MantineProvider>
  )
}

export default ThemeProvider
