import { IRoute } from 'src/common/interfaces'

//TODO move all routes to a common file

export const PRIVATE_ROUTES: { [key: string]: IRoute } = {
  LEADS: {
    path: '/leads',
    policy: 'is_get_persons_enabled',
  },
  ACCOUNTS: {
    path: '/accounts',
    policy: 'is_get_persons_enabled',
  },
  SETTINGS: {
    path: '/settings',
    policy: null,
  },
  DATA_LOADER: {
    path: '/upload-file',
    policy: 'is_persons_data_loader_enabled',
  },
  ADMINISTRATION: {
    path: '/administration',
    policy: 'is_enable_administration',
  },
}
