import { AxiosError } from 'axios'
import cx from 'classnames'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useMutateEligibility } from 'src/benestream/hooks/eligibilities'
import useGetElibilityHistory from 'src/benestream/hooks/eligibilities/useGetEligibilityHistory'
import { Button, Form, Input, Modal, SubmitButton } from 'src/common/components'
import {
  formatDate,
  removeUnderScoreAndTransformToLowerCase,
  splitCamelCase,
} from 'src/common/helpers'
import { basicApiErrorHandling } from 'src/default/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { Opportunity, OpportunityHistory } from '../opportunities.interface'
import SelectOpportunities from './SelectOpportunities'
import styles from './opportunities-modal.module.scss'

interface OpportunitiesModalProps {
  onCloseModal: () => void
  opportunity: Opportunity
  setResult: (value: string, isError: boolean) => void
}

const historyOriginalValue = (field: string, originalValue: string) => {
  if (
    field === 'expirationDate' ||
    field === 'markedAsEligibleAt' ||
    field === 'screeningCompletedAt'
  ) {
    return formatDate(originalValue, 'MM/DD/YYYY')
  }
  if (
    field === 'screeningCompletedBy' ||
    field === 'updatedBy' ||
    field === 'markedAsEligibleBy'
  ) {
    try {
      const data = JSON.parse(originalValue)
      return `${data.firstName} ${data.lastName}`
    } catch (e) {
      return originalValue
    }
  }

  if (field.toLowerCase().includes('status')) {
    return removeUnderScoreAndTransformToLowerCase(originalValue)
  }
  return originalValue
}

const historyNewValue = (field: string, newValue: string) => {
  if (
    field === 'expirationDate' ||
    field === 'markedAsEligibleAt' ||
    field === 'screeningCompletedAt'
  ) {
    return formatDate(newValue, 'MM/DD/YYYY')
  }

  if (
    field === 'screeningCompletedBy' ||
    field === 'updatedBy' ||
    field === 'markedAsEligibleBy'
  ) {
    try {
      const data = JSON.parse(newValue)
      return `${data.firstName} ${data.lastName}`
    } catch (e) {
      return newValue
    }
  }

  if (field.toLowerCase().includes('status')) {
    return removeUnderScoreAndTransformToLowerCase(newValue)
  }
  return newValue
}

const OpportunitiesModal = (props: OpportunitiesModalProps) => {
  const { onCloseModal, opportunity, setResult } = props

  const mutateEligibility = useMutateEligibility(
    opportunity ? 'update' : 'create',
  )

  const { data } = useGetElibilityHistory({ eligibilityId: opportunity.id })

  const opportunityHistory = data?.data?.records || []

  const formik = useFormik({
    initialValues: {
      id: opportunity.id,
      person: `${opportunity.enrollment.person.firstName} ${opportunity.enrollment.person.lastName}`,
      employer: opportunity.enrollment.associatedBenefitContract.name,
      benefitId: opportunity.benefit?.name ?? '',
      beneStreamStatus: opportunity.beneStreamStatus,
      expirationDate: opportunity.expirationDate,
      providerDataExchangeStatus: opportunity.providerDataExchangeStatus,
    },
    onSubmit: async (values) => {
      const payload: {
        id: string
        providerDataExchangeStatus: string
        expirationDate?: string
        beneStreamStatus: string
      } = {
        id: values.id,
        beneStreamStatus: values.beneStreamStatus,
        providerDataExchangeStatus: values.providerDataExchangeStatus,
      }

      if (values.expirationDate) {
        payload.expirationDate = values.expirationDate
      }
      await mutateEligibility
        .mutateAsync(payload)
        .then(() => {
          setResult('success', false)
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error), true)
        })
    },
    validationSchema: yup.object().shape({
      person: yup.string().required('Person is a required field'),
      employer: yup.string().required('Employer is a required field'),
      benefitId: yup.string().required('Benefit is a required field'),
      beneStreamStatus: yup.string().required('Status is a required field'),
      providerDataExchangeStatus: yup
        .string()
        .required('Benestream status is a required field'),
    }),
  })

  return (
    <Modal
      onClose={onCloseModal}
      className={styles.modalWrapper}
      id="opportunities"
      title="Opportunities"
    >
      <div className={styles.modalContent}>
        <div className={styles.title}>Opportunities</div>
        <div className={styles.sectionTitle}>Details</div>
        <Form providerValue={formik}>
          <div className={styles.formWrapper}>
            <Input
              className={styles.fieldWrapper}
              name="person"
              type="text"
              label="Person"
              tabletWidth="50"
              disabled
              withError={!!formik.errors['person']}
            />
            <Input
              className={styles.fieldWrapper}
              name="employer"
              type="text"
              label="Employer/Union"
              tabletWidth="50"
              disabled
              withError={!!formik.errors['employer']}
            />
            <Input
              className={styles.fieldWrapper}
              name="benefitId"
              type="text"
              label="Program/Benefit"
              tabletWidth="50"
              disabled
              withError={!!formik.errors['benefitId']}
            />

            <SelectOpportunities
              withError={!!formik.errors['beneStreamStatus']}
            />

            <Input
              className={styles.fieldWrapper}
              name="providerDataExchangeStatus"
              type="text"
              label="Benefit Provider Status"
              tabletWidth="50"
              disabled
              value={removeUnderScoreAndTransformToLowerCase(
                opportunity.providerDataExchangeStatus,
              )}
              withError={!!formik.errors['providerDataExchangeStatus']}
            />
          </div>

          <div className={styles.historyTitle}>History</div>
          <div className={styles.historyContainer}>
            {opportunityHistory.length ? (
              opportunityHistory
                .sort(
                  (a: OpportunityHistory, b: OpportunityHistory) =>
                    +new Date(b.dateOfChange) - +new Date(a.dateOfChange),
                )
                .map((history: OpportunityHistory) => (
                  <div key={history.id} className={styles.historyItem}>
                    <div className={styles.itemTitle}>{`${
                      history?.user?.firstName ?? 'Not Defined'
                    } ${history?.user?.lastName ?? 'Not Defined'}`}</div>
                    <div className={styles.itemBody}>
                      <div className={styles.fieldChange}>
                        {history.originalValue === null ||
                        history.originalValue === '' ? (
                          <>
                            Changed {splitCamelCase(history.objectField)} to{' '}
                            {''}
                            <strong>
                              {historyNewValue(
                                history.objectField,
                                history.newValue,
                              )}
                            </strong>
                          </>
                        ) : (
                          <>
                            Changed {splitCamelCase(history.objectField)} from{' '}
                            <strong>
                              {historyOriginalValue(
                                history.objectField,
                                history.originalValue,
                              )}
                            </strong>{' '}
                            to{' '}
                            <strong>
                              {historyNewValue(
                                history.objectField,
                                history.newValue,
                              )}
                            </strong>
                          </>
                        )}
                      </div>
                      <div className={styles.dateChange}>
                        {formatDate(history.dateOfChange, 'MM/DD/YYYY h:mm A')}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className={styles.noHistory}>No history data</div>
            )}
          </div>

          <PolicyCheckHOC
            policyName={
              opportunity
                ? 'is_enable_benestream_create_opportunity'
                : 'is_enable_benestream_update_opportunity'
            }
            disablePolicyCheck
          >
            <div className={styles.buttonWrapper}>
              <Button
                className={cx(styles.button, styles.cancelButton)}
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <SubmitButton className={styles.button} iconAfter="ArrowRight">
                Save
              </SubmitButton>
            </div>
          </PolicyCheckHOC>
        </Form>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  OpportunitiesModal,
  'is_enable_opportunities_modal',
  true,
)
