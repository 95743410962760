/* eslint-disable react-hooks/rules-of-hooks */
import fileDownload from 'js-file-download'
import { useEffect, useState } from 'react'

import { BasicBlock, EmptyPersons, Table } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages } from 'src/common/helpers'
import { useParamsFormik } from 'src/common/hooks'
import { InfiniteBasicTemplate } from 'src/common/templates'
import { useGetSubmissionsByOrganization } from 'src/default/hooks'
import { IFormSubmission } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import useGetSubmissionsByOrganizationFile from '../../../hooks/forms/useGetSubmissionsByOrganizationFile'
import SubmissionsFilter from './SubmissionsFilter/SubmissionsFilter'
import styles from './all-submissions.module.scss'

const SubmissionsPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const filterFormik = useParamsFormik({
    id: 'submissionsFormsFilter',
    initialValues: {
      orderBy: 'createdAt DESC',
    },
  })

  const mapFilterToGetSubmissionsDTO = (_filterFormik: any) => {
    return {
      organizationId: currentOrganizationId ?? '',
      limit: PAGINATION_LIMIT,
      formTemplateId: _filterFormik.values.formTemplateId,
      isReviewed:
        _filterFormik.values.isReviewed === 'all'
          ? undefined
          : _filterFormik.values.isReviewed,
      isCompleted:
        _filterFormik.values.isCompleted === 'all'
          ? undefined
          : _filterFormik.values.isCompleted,
      fromDate:
        _filterFormik.values.createdDate?.length > 0
          ? (_filterFormik.values.createdDate as string).split(':')[0]
          : undefined,
      toDate:
        _filterFormik.values.createdDate?.length > 0
          ? (_filterFormik.values.createdDate as string).split(':')[1]
          : undefined,
      orderBy: _filterFormik.values.orderBy,
    }
  }

  const {
    data: { pages } = { pages: [] },
    isFetching,
    isFetched,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    error,
  } = useGetSubmissionsByOrganization(
    mapFilterToGetSubmissionsDTO(filterFormik),
  )

  const { data: fileData, refetch: refetchFileData } =
    useGetSubmissionsByOrganizationFile(
      mapFilterToGetSubmissionsDTO(filterFormik),
    )

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages])

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const forms = concatQueryPages<IFormSubmission>(pages)

  return (
    <InfiniteBasicTemplate
      title="Form Submissions"
      isLoading={isLoading && !error}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: forms?.length ?? 0,
      }}
      scrollPromise={handlePageScroll}
    >
      <BasicBlock
        className={styles.wrapper}
        paddings="big"
        isLoading={isFetching || isFetchingNextPage}
        isLoadingTop={isFetching && !isFetchingNextPage}
      >
        {!error && (
          <div>
            <SubmissionsFilter
              providerValue={filterFormik}
              total={pages?.[0]?.data.totalRecords ?? 0}
              onSave={() => {
                refetchFileData().then((_data) => {
                  fileDownload(_data?.data?.data, 'report.csv', 'text/csv')
                })
              }}
            />
          </div>
        )}

        <div className={styles.tableContainer}>
          {forms.length ? (
            forms?.map((form: any) => (
              <Table.Collapsible form={form} key={form.id} refetch={refetch} />
            ))
          ) : isFetched ? (
            <EmptyPersons term="submission" />
          ) : null}
        </div>
      </BasicBlock>
    </InfiniteBasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SubmissionsPage,
  'is_enable_view_form_submissions',
)
