import { ComboboxItem } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

import {
  CreateUser,
  MembershipEmailDomainUser,
  UserMembership,
} from '../models/user.model'
import useUserUseCase from '../useCase/useUser.useCase'
import useUserEmailDomainsUseCase from '../useCase/useUserEmailDomains'
import useUserInformationUseCase from '../useCase/useUserInformation'
import useUserMembershipUseCase from '../useCase/useUserMembershipInformation.useCase'

interface UseAdministrationUserContainer {
  userInformationForm: UseFormReturnType<CreateUser>
  editForm: boolean
  setEditForm: (value: boolean) => void
  handleCancelForm: () => void
  onSubmit: () => void
  membership: UserMembership
  membershipRoleList: Array<{ value: string; label: string }>
  userMembershipModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  setMembershipRole: (value: ComboboxItem) => void
  actualRoleId: string
  membershipRole: ComboboxItem | null
  newStatus: string
  setNewStatus: (value: 'active' | 'inactive') => void
  handleMutation: () => void
  loadingUpdateMutation: boolean
  emailDomains: MembershipEmailDomainUser[]
  isLoading: boolean
  errorModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  handleDeleteUser: () => void
  isSuccessDeleteUser: boolean
  handleDeleteMembership: () => void
  handleGenerateApiKey: () => void
  isDeleteMembershipSuccess: boolean
  isGenerateApiKeySuccess: boolean
}

const useAdministrationUserContainer = (): UseAdministrationUserContainer => {
  const { handleDeleteUser, isSuccessDeleteUser } = useUserUseCase()
  const {
    userInformationForm,
    editForm,
    setEditForm,
    handleCancelForm,
    onSubmit,
    loading,
    errorModal,
  } = useUserInformationUseCase()

  const {
    membership,
    membershipRoleList,
    userMembershipModal,
    setMembershipRole,
    actualRoleId,
    membershipRole,
    newStatus,
    setNewStatus,
    handleMutation,
    loadingUpdateMutation,
    handleDeleteMembership,
    handleGenerateApiKey,
    isDeleteMembershipSuccess,
    isGenerateApiKeySuccess,
  } = useUserMembershipUseCase()

  const { emailDomains } = useUserEmailDomainsUseCase()

  return {
    userInformationForm,
    editForm,
    setEditForm,
    handleCancelForm,
    onSubmit,
    membership,
    handleDeleteMembership,
    handleGenerateApiKey,
    membershipRoleList,
    userMembershipModal,
    setMembershipRole,
    actualRoleId,
    membershipRole,
    newStatus,
    setNewStatus,
    handleMutation,
    emailDomains,
    isLoading: loading,
    loadingUpdateMutation,
    errorModal,
    handleDeleteUser,
    isSuccessDeleteUser,
    isDeleteMembershipSuccess,
    isGenerateApiKeySuccess,
  }
}

export default useAdministrationUserContainer
