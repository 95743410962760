import { useFormikContext } from 'formik'

import { Status } from 'src/common/components'
import { capitalize } from 'src/common/helpers'
import { IEnrollment } from 'src/default/interfaces'
import ConvertLead from 'src/default/pages/Accounts/SingleAccountPage/SingleAccountEnrollments/SingleAccountEnrollmentsModal/ConvertLead/ConvertLead'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benestream-person-form-header.module.scss'

interface BenestreamPersonFormHeaderProps {
  personType: 'lead' | 'account'
  enrollments?: IEnrollment[]
}

const BenestreamPersonFormHeader = (props: BenestreamPersonFormHeaderProps) => {
  const { personType, enrollments } = props

  const { values } = useFormikContext<any>() //TODO ts
  const { firstName, lastName, email, phoneNumber, companyName, id } = values

  const status = enrollments?.[0]?.status || ''

  const enrollmentIsNotVerified = enrollments?.find(
    (enrollment) => !enrollment.isVerified,
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.title}>{`${firstName} ${lastName}`}</div>

        <Status className={styles.status} size="big" color="grey">
          {capitalize(personType)}
        </Status>

        {enrollmentIsNotVerified ? (
          <div className={styles.convertLeadWrapper}>
            <ConvertLead
              personId={id}
              enrollmentId={enrollmentIsNotVerified.id}
            />
          </div>
        ) : null}
      </div>

      <div className={styles.inner}>
        <div className={styles.item}>
          <div className={styles.label}>Email:</div>
          <div className={styles.value}>{email || '-'}</div>
        </div>

        <div className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.label}>Mobile Number:</div>
          <div className={styles.value}>{phoneNumber || '-'}</div>
        </div>

        <div className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.label}>Company:</div>
          <div className={styles.value}>{companyName || '-'}</div>
        </div>

        {personType === 'lead' ? (
          <>
            <div className={styles.divider} />

            <div className={styles.item}>
              <div className={styles.label}>Status:</div>
              <div className={styles.value}>{capitalize(status) || '-'}</div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonFormHeader,
  'is_enable_benestream_person_form_header',
  true,
)
