import { dataLoaderObjects } from './dataLoader.constants'

interface ValidateCsvProps {
  actionType: string
  object: string
  setErrorMessage: (error: string) => void
  setValidatedData: (data: any[]) => void
}

export const validateCsv =
  (props: ValidateCsvProps) => (file: File, data: any[], headers: string[]) => {
    const { setErrorMessage, setValidatedData } = props

    if (!data.length && file.name) {
      return setErrorMessage('The file is empty. Please upload a new file.')
    }

    return setValidatedData(data)
  }
