import { IEmailTemplate } from '@v2/domains/email-template/models/emailTemplate.model'

import { FileAttachments } from 'src/default/interfaces/communication/email-communication'

const EMAIL_SENDER_AND_MANAGER_EMAIL_TEMPLATE_INITIAL_STATE = {
  fromEmailId: '',
  fromEmail: '',
  replyEmailId: '',
  replyEmail: '',
  emailDomainSignature: '',
  emailBody: '',
  subject: '',
  attachments: [] as File[],
  filesAttachments: [] as FileAttachments[],
  isNewEmail: true,
  isSuccessSendEmail: false,
  isViewEmail: false,
  isUploadingEmailBodyAttachments: false,
  emailTemplateSelected: undefined as IEmailTemplate | undefined,
  showManagerEmailTemplateModal: false,
  showNewEmailTemplateModal: false,
  showListEmailTemplateModal: true,
}

export default EMAIL_SENDER_AND_MANAGER_EMAIL_TEMPLATE_INITIAL_STATE
