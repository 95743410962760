import styles from './basic-multiselect-buttons-other-input.module.scss'

interface BasicMultiSelectButtonsOtherInputProps {
  value: string
  onChange: (value: string) => void
  onEscape: () => void
  onEnter: () => void
}

const BasicMultiSelectButtonsOtherInput = (
  props: BasicMultiSelectButtonsOtherInputProps,
) => {
  const { value, onChange, onEscape, onEnter } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (/^[a-zA-Z\s\-]*$/.test(newValue)) {
      onChange(newValue)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onEscape()
    }

    if (event.key === 'Enter') {
      onEnter()
    }
  }

  return (
    <input
      autoFocus
      className={styles.input}
      placeholder="Other"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

export default BasicMultiSelectButtonsOtherInput
