import { ComboboxItem } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'

import { useAppSelector } from 'src/v2/store/hooks'

import { useGetRolesQuery } from '../../role/models/role.api'
import {
  useDeleteUserMemberShipMutation,
  useUpdateUserMembershipMutation, useUseGenerateApiKeyMutation,
} from '../models/user.api'
import { UserMembership } from '../models/user.model'

interface UseUserInformationUseCase {
  membership: UserMembership
  membershipRoleList: Array<{ value: string; label: string }>
  userMembershipModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  actualRoleId: string
  setMembershipRole: (value: ComboboxItem) => void
  membershipRole: ComboboxItem | null
  newStatus: string
  setNewStatus: (value: 'active' | 'inactive') => void
  handleMutation: () => void
  loadingUpdateMutation: boolean
  handleDeleteMembership: () => void
  handleGenerateApiKey: () => void
  isDeleteMembershipSuccess: boolean
  isGenerateApiKeySuccess: boolean
}

const useUserMembershipUseCase = (): UseUserInformationUseCase => {
  const [opened, { open, close }] = useDisclosure(false)

  //TODO refactor this to move out of this domain
  //La refactorizacion en un formulario
  const { data: roles } = useGetRolesQuery()

  const { user, userProfile } = useAppSelector((state) => state.user)
  const [updateUserMembership, { isLoading }] =
    useUpdateUserMembershipMutation()

  const [deleteUserMemberShip, { isSuccess: isDeleteMembershipSuccess }] =
    useDeleteUserMemberShipMutation()

  const [generateApiKey, { isSuccess: isGenerateApiKeySuccess }] =
    useUseGenerateApiKeyMutation()

  const [membershipRole, setMembershipRole] = useState<ComboboxItem | null>(
    null,
  )
  const [newStatus, setNewStatus] = useState<'active' | 'inactive'>('active')

  const handleMutation = () => {
    updateUserMembership({
      id: user?.membership?.id ?? '',
      role: membershipRole?.label ?? '',
      status: newStatus ?? '',
    })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Success',
          message: 'Membership succesfuly changed',
        })
      })
      .catch(() => {
        notifications.show({
          title: 'Oops',
          message: 'There was an error',
          color: 'red',
        })
      })
      .finally(() => {
        close()
      })
  }
  const handleDeleteMembership = () => {
    deleteUserMemberShip(user.membership!.id)
      .unwrap()
      .catch(() => {
        notifications.show({
          title: 'Membership removal failed',
          message: 'An error has ocurred',
          color: 'red',
        })
      })
  }

  const handleGenerateApiKey = () => {
    generateApiKey(user.membership!.id)
      .unwrap()
      .catch(() => {
        notifications.show({
          title: 'API Key generation failed',
          message: 'An error has ocurred',
          color: 'red',
        })
      })
  }

  return {
    membership: {
      id: user.membership?.id ?? '',
      role: user.membership?.role ?? '',
      hasAPIKey: user.membership?.hasAPIKey ?? false,
      status: user.membership?.status ?? '',
    },
    membershipRoleList:
      roles?.records?.map((role) => ({ value: role.id, label: role.name })) ??
      [],
    setMembershipRole: setMembershipRole,
    userMembershipModal: {
      opened,
      open,
      close,
    },
    actualRoleId: userProfile.membership.membershipRole.id,
    membershipRole,
    newStatus,
    setNewStatus,
    handleMutation,
    loadingUpdateMutation: isLoading,
    handleDeleteMembership,
    handleGenerateApiKey,
    isDeleteMembershipSuccess,
    isGenerateApiKeySuccess,
  }
}

export default useUserMembershipUseCase
