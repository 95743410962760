import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetCmsBenefits = () => {
  return useQuery({
    queryKey: [`getCmsBenefits`],
    queryFn: async () => await api.get('/cms'),
  })
}

export default useGetCmsBenefits
