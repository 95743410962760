import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonTagsParams {
  personId?: string
  organizationId?: string | null
}

interface ITagsMutationData {
  records: any[]
}

const useUpdatePersonTags = (params: GetPersonTagsParams) =>
  useMutation({
    mutationFn: async (data: ITagsMutationData) =>
      api.put(
        `/persons/${params.personId}/${params.organizationId}/tags`,
        data,
      ),
  })

export default useUpdatePersonTags
