import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import { Button } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './leads-page-header.module.scss'

const LeadsPageHeader = () => {
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <div className={styles.wrapper}>
      <PolicyCheckHOC policyName="is_create_benestream_person_primary_enabled">
        <Button
          className={styles.button}
          icon="Plus"
          to={`/${organizationId}${PRIVATE_ROUTES.LEADS.path}/create`}
        >
          New Lead
        </Button>
      </PolicyCheckHOC>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LeadsPageHeader,
  'is_enable_benestream_leads_page_header',
  true,
)
