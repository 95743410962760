import { ComboboxItem, Select } from '@mantine/core'

export const RoleSelect = (props: {
  membershipRole: ComboboxItem | undefined
  membershipRoleList: ComboboxItem[] | undefined
  handleOnSelectChange: (value: string | null, option: ComboboxItem) => void
}) => {
  const { handleOnSelectChange, membershipRole, membershipRoleList } = props

  return (
    <Select
      value={membershipRole?.value}
      onChange={handleOnSelectChange}
      placeholder="Select Role"
      data={membershipRoleList}
      allowDeselect={false}
    />
  )
}
