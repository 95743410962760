import { InputSearch } from 'src/common/components'

import styles from './search-n-filter.module.scss'

const SearchNFilter = () => {
  return (
    <div className={styles.wrapper}>
      <InputSearch
        className={styles.input}
        name="search"
        placeholder="Search Communication"
        size="middle"
      />
    </div>
  )
}

export default SearchNFilter
