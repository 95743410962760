import { UseFormReturnType, useForm } from '@mantine/form'
import { PersonModel } from '@v2/domains/person/models/person.model'
import { useAppSelector } from '@v2/store/hooks'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useGetPersonQuery,
  useMergeDuplicatesMutation,
} from '../models/merging-tool.api'
import {
  type MergedPersonModel,
  type RecordInformationFormValues,
  type RecordInformationTableData,
  type TransformReacordInformationValues,
} from '../models/merging-tool.model'

interface IRecordInformation {
  tableData: RecordInformationTableData[]
  form: UseFormReturnType<RecordInformationFormValues | {}>
  setEntireColumn: (id: string) => void
  isChecked: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => boolean
  setCellValue: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => void
  onSubmit: (
    values: RecordInformationFormValues,
    primaryEntityId: string,
  ) => void
  mergeMutationStatus: {
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
  }
  mergeResponse: PersonModel | undefined
}

type RequestOrigin = 'search' | 'account' | 'lead'

const useRecordInformationUseCase = (): IRecordInformation => {
  const [searchParams] = useSearchParams()
  const duplicatesIds: string[] = useAppSelector(
    (state) =>
      state[searchParams.get('location') as RequestOrigin].filters
        .selectedItems,
  )

  const { data = [] } = useGetPersonQuery(duplicatesIds)
  const [
    mergeDuplicates,
    { isLoading, isError, isSuccess, data: mergeResponse },
  ] = useMergeDuplicatesMutation()

  const transformValues = (
    values: RecordInformationFormValues,
  ): TransformReacordInformationValues => {
    return Object.keys(values).reduce<TransformReacordInformationValues>(
      (returnValues, key) => {
        const field = values[key as keyof RecordInformationFormValues]
        if (field && key === 'address' && typeof field.value === 'string') {
          const [city, state, street, postalCode] = field.value.split(', ')
          returnValues['city'] = city
          returnValues['street'] = street
          returnValues['state'] = state
          returnValues['postalCode'] = postalCode
        } else if (
          field &&
          typeof field.value === 'object' &&
          !Array.isArray(field.value)
        ) {
          returnValues[key as keyof RecordInformationFormValues] =
            field.value?.person.id
        } else {
          returnValues[key as keyof RecordInformationFormValues] = field?.value
        }
        return returnValues
      },
      {} as TransformReacordInformationValues,
    )
  }

  const onSubmit = (
    values: RecordInformationFormValues,
    primaryEntityId: string,
  ) => {
    const mergePersonIds = data
      .map((person) => person.id)
      .filter((personId) => personId !== primaryEntityId)
    const updatePerson = transformValues(values)
    const body = {
      personId: primaryEntityId,
      mergePersonIds,
      updatePerson,
    }

    mergeDuplicates(body)
  }

  const form = useForm<RecordInformationFormValues>({
    mode: 'controlled',
  })

  const isChecked = (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => {
    const currentVal = form.getValues()[field]
    if (currentVal?.source === accessor) {
      return true
    }
    return false
  }

  const allNullRow = (data: RecordInformationTableData) => {
    return Object.values(data).every((field) => {
      return Array.isArray(field) ? !field.length : !field
    })
  }

  const transformDataToPivot = useMemo(() => {
    if (!!data.length) {
      const fields = Object.keys(data[0]) as Array<keyof MergedPersonModel>
      const chooseColumnRadioRow: RecordInformationTableData = {
        field: 'Use as Primary',
      }

      // preparing table rows
      const pivotTable = fields.map<RecordInformationTableData>((field) => ({
        field,
        ...data.reduce<RecordInformationTableData>((row, entry) => {
          row[entry.id] = entry[field]
          chooseColumnRadioRow[entry.id] = 'Use as Primary'
          return row
        }, {}),
      }))
      // filtering row with all values null or empty, removing id row to prevent paitning in table
      const pivotTableIdFiltered = pivotTable.filter((row) => {
        const { field, ...rest } = row
        return !allNullRow(rest) && row.field !== 'id'
      })

      return [chooseColumnRadioRow, ...pivotTableIdFiltered]
    }
    return []
  }, [data])

  const setEntireColumn = (entryId: MergedPersonModel['id']) => {
    const duplicateInstance = data.find(({ id }) => id === entryId)

    if (duplicateInstance) {
      const setEntireColumnValues = (
        Object.keys(duplicateInstance) as Array<keyof MergedPersonModel>
      )
        .filter((key) => key !== 'id')
        .reduce<RecordInformationFormValues>((obj, key) => {
          obj[key] = {
            source: duplicateInstance.id,
            value: duplicateInstance[key],
          }
          return obj
        }, {})

      form.setValues(setEntireColumnValues)
    }
  }

  const setCellValue = (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => {
    const fieldValue = data.find(({ id }) => id === accessor)
    if (fieldValue) {
      form.setFieldValue(field, {
        source: accessor,
        value: fieldValue[field],
      })
    }
  }
  return {
    tableData: transformDataToPivot,
    form,
    setEntireColumn,
    setCellValue,
    isChecked,
    onSubmit,
    mergeResponse,
    mergeMutationStatus: {
      isError,
      isLoading,
      isSuccess,
    },
  }
}

export default useRecordInformationUseCase
