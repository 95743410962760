import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useEmailTemplate = () => {
  return useQuery({
    queryKey: [`getEmailTemplate`],
    queryFn: async () => await api.get(`/email-template/by-user`),
  })
}

export default useEmailTemplate
