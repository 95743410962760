import { Box, Checkbox, ScrollArea, Table } from '@mantine/core'

import { PolicyDataTable, PolicyModel } from '../models/policy.model'
import { EditableDescription } from './EditableDescription.component'

export const PoliciesTable = (props: {
  policies: PolicyModel[]
  activePolicies: String[] | undefined
  onCheckBoxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    policyId: string,
  ) => void
  isRoleActive?: boolean
  updateRoleMutationStatus:
    | 'pending'
    | 'fulfilled'
    | 'rejected'
    | 'uninitialized'
}) => {
  const {
    policies,
    activePolicies,
    onCheckBoxChange,
    isRoleActive,
    updateRoleMutationStatus,
  } = props

  return (
    <Box style={{ height: '67vh', display: 'flex' }}>
      <ScrollArea mt={20} mb={40} style={{ width: '100%' }} scrollbars="y">
        <Table width={900} striped>
          <Table.Thead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              border: 'white',
              zIndex: 1,
            }}
          >
            <Table.Tr>
              <Table.Th />
              <Table.Th>Name</Table.Th>
              <Table.Th>Description</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {policies.map((policy: PolicyDataTable) => (
              <Table.Tr key={policy.id}>
                <Table.Td>
                  <Checkbox
                    aria-label="Select row"
                    checked={activePolicies?.includes(policy.id) || false}
                    onChange={(event) => onCheckBoxChange(event, policy.id)}
                    disabled={
                      (activePolicies === undefined && !isRoleActive) ||
                      updateRoleMutationStatus === 'pending'
                    }
                  />
                </Table.Td>
                <Table.Td>{policy.name}</Table.Td>
                <Table.Td>
                  <EditableDescription
                    description={policy.description}
                    policyId={policy.id}
                    name={policy.name}
                    disabled={updateRoleMutationStatus === 'pending'}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Box>
  )
}
