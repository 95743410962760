import { useEffect, useState } from 'react'

import { useGetPersons } from 'src/benestream/hooks'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { BasicBlock, EmptyPersons } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages } from 'src/common/helpers'
import { InfiniteBasicTemplate } from 'src/common/templates'

import { useAppSelector } from '../../../../v2/store/hooks'
import AccountsPageTable from './AccountsPageTable'
import styles from './accounts-page.module.scss'

const LeadsPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isRefetching,
    isFetchingNextPage,
    isFetched,
  } = useGetPersons({
    isVerified: true,
    organizationId: userProfile.organization.id || '',
    //status
    //company
    //createdDate
    orderBy: 'createdAt DESC',
    limit: PAGINATION_LIMIT,
  })

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages])

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const accounts = concatQueryPages<IBenestreamPerson>(pages)

  const title = 'Accounts'

  return (
    <InfiniteBasicTemplate
      title={title}
      scrollPromise={handlePageScroll}
      isLoading={isLoading}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: accounts?.length ?? 0,
      }}
    >
      <BasicBlock
        className={styles.wrapper}
        isLoading={pages.length === 0 || isFetchingNextPage}
        isLoadingTop={isRefetching && !isFetchingNextPage}
      >
        {accounts.length ? (
          <AccountsPageTable accounts={accounts} />
        ) : isFetched ? (
          <EmptyPersons term="account" />
        ) : null}
      </BasicBlock>
    </InfiniteBasicTemplate>
  )
}

export default LeadsPage
