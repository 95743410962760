import {
  Icons,
  Modal,
  Pagination,
} from '@workers-benefit-fund/wbf-ui-components'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useBlocker, useParams } from 'react-router-dom'
import * as yup from 'yup'

import {
  BasicBlock,
  Breadcrumbs,
  MetroPlus,
  Portal,
  Table,
} from 'src/common/components'
import { PersonNotesBlock } from 'src/common/components'
import DuplicatePersons from 'src/common/components/person/DuplicatePersons/DuplicatePersons'
import { PersonNoteModal } from 'src/common/components/person/PersonNotesBlock/components'
import { STATES } from 'src/common/config'
import { useDocumentTitle, useGetEligibilities } from 'src/common/hooks'
import { IPerson, IPersonMutationData } from 'src/common/interfaces'
import { ModalResult } from 'src/default/components'
import DriverForm from 'src/default/components/DriverForm'
import { BASIC_PERSON_INITIAL_DATA, PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import {
  useGetPerson,
  useGetPersonTags,
  useGetPersonTagsBlackCarFund,
  useMutatePerson,
  useMutatePersonTagBlackCarFund,
  useUpdatePersonTags,
} from 'src/default/hooks'
import useGetDuplicatedPersons from 'src/default/hooks/person/useGetDuplicatePersons'
import { IEnrollment } from 'src/default/interfaces'
import { SinglePersonTemplate } from 'src/default/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import {
  setShowTicketBlockInformation,
  setTicketId,
} from '../../../../v2/domains/ticket/models/ticket.store'
import { useAppDispatch, useAppSelector } from '../../../../v2/store/hooks'
import useGetOrganizationTags from '../../../hooks/person/useGetOrganizationTags'
import { getValidationSchemaByOrganizationId } from '../../Leads/SingleLeadPage/single-lead-page.util'
import SingleAccountDocuments from './SingleAccountDocuments'
import SingleAccountEnrollments from './SingleAccountEnrollments'
import SingleAccountFormSubmissions from './SingleAccountFormSubmissions/SingleAccountFormSubmissions'
import SingleAccountWashingtonState from './SingleAccountWashingtonState/SingleAccountWashingtonState'
import styles from './single-account-page.module.scss'

const SingleAccountPageWrapper = () => {
  const { personId } = useParams<{ personId: string }>()
  const router = useRouter()

  const [isReady, setIsReady] = useState<boolean>(false)

  const {
    data: { data: accountData } = { data: {} },
    isSuccess,
    isLoading,
    refetch,
  } = useGetPerson({
    personId,
  })

  const {
    data: { data: personTagsBlackCarFund } = { data: [] },
    isSuccess: isPersonTagsBlackCarFundSuccess,
    isError: isPersonTagsBlackCardFundError,
    isLoading: isPersonTagsBlackCarFundLoading,
  } = useGetPersonTagsBlackCarFund({ personId })

  useEffect(() => {
    if (accountData && !isLoading) {
      if (!accountData.isAccount) {
        router.push(`${PRIVATE_ROUTES.LEADS.path}/${personId}`)
      } else if (isPersonTagsBlackCarFundSuccess) {
        accountData.blackCarFund = personTagsBlackCarFund[0]?.id
        setIsReady(true)
      } else if (
        !isPersonTagsBlackCardFundError &&
        !isPersonTagsBlackCarFundLoading
      ) {
        setIsReady(true)
      }
    }
  }, [
    accountData,
    isLoading,
    isPersonTagsBlackCarFundSuccess,
    isPersonTagsBlackCardFundError,
    isPersonTagsBlackCarFundLoading,
  ])

  if (!isReady) {
    return null
  }

  return (
    <SingleAccountPage
      accountData={accountData}
      isSuccess={isSuccess}
      refetch={refetch}
    />
  )
}

interface SingleAccountPageProps {
  accountData: IPerson
  isSuccess: boolean
  refetch: () => Promise<any>
}

const SingleAccountPage = (props: SingleAccountPageProps) => {
  const dispatch = useAppDispatch()

  const LIMIT_BY_PAGE = 3
  const { accountData, isSuccess, refetch } = props

  const { personId, ticketId } = useParams<{
    personId: string
    ticketId?: string
  }>()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const [isReady, setIsReady] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)
  const [duplicatePerson, setDuplicatePerson] = useState<IPerson | null>(null)
  const [paginationOffset, setPaginationOffset] = useState(0)
  const [isLeavingTheRecordWithoutNote, setIsLeavingTheRecordWithoutNote] =
    useState<boolean>(true)

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      userProfile.policies.includes('is_notespromptmodal_enabled') &&
      isLeavingTheRecordWithoutNote &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isLeavingTheRecordWithoutNote) {
        event.preventDefault()
      }
      return
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isLeavingTheRecordWithoutNote])

  const { data: { data: duplicatedPersons } = { data: [] } } =
    useGetDuplicatedPersons({
      personId: accountData.id!,
      params: {
        organizationId: currentOrganizationId!,
        byEmail: 'true',
        byTLC: 'true',
        byDL: 'true',
      },
    })
  const personMutation = useMutatePerson('update')
  const tagsMutation = useUpdatePersonTags({
    personId,
    organizationId: currentOrganizationId,
  })
  const {
    data: { data: personTags } = { data: [] },
    isLoading: isTagsLoading,
    isSuccess: isTagsSuccess,
  } = useGetPersonTags({
    personId,
    organizationId: currentOrganizationId,
  })

  const {
    data: { data: organizationTags } = { data: [] },
    isLoading: isOrganizationTagsLoading,
    isSuccess: isOrganizationTagsSuccess,
  } = useGetOrganizationTags()

  const {
    data: { data: { records: eligibilities } } = { data: { records: [] } },
    isLoading: isEligibilitiesLoading,
    isSuccess: isEligibilitiesSuccess,
  } = useGetEligibilities({
    personId,
    organizationId: currentOrganizationId || undefined,
  })

  const mutationPersonTagBlackCarFund = useMutatePersonTagBlackCarFund()

  useDocumentTitle(
    `${accountData?.firstName ?? ''} ${accountData?.lastName ?? ''}`,
  )

  useEffect(() => {
    if (ticketId) {
      dispatch(setTicketId(ticketId))
      dispatch(setShowTicketBlockInformation(true))
    }
  }, [ticketId])

  useEffect(() => {
    setDuplicatePerson(null)
  }, [accountData])

  const formik = useFormik<IPersonMutationData>({
    initialValues: {
      id: personId,
      ...BASIC_PERSON_INITIAL_DATA,
      updatedAt: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      const newValues = { ...values }

      delete newValues.source
      delete newValues.updatedAt
      delete newValues.createdBy
      delete newValues.updatedBy
      delete newValues.updatedAt
      delete newValues.createdAt
      delete newValues.blackCarFund

      await personMutation
        .mutateAsync({ ...newValues })
        .then(() => {
          if (values.blackCarFund) {
            mutationPersonTagBlackCarFund.mutateAsync({
              personId: personId ?? '',
              tagId: values.blackCarFund ?? '',
            })
          }

          setResult('success')
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
        })

      setSubmitting(false)
    },
    validationSchema: yup
      .object()
      .shape(getValidationSchemaByOrganizationId(currentOrganizationId!)),
  })

  const handleFormReady = async () => {
    setIsReady(true)
  }

  const enrollments = (accountData?.enrollments ?? []).sort(
    (a: IEnrollment, b: IEnrollment) => a.createdAt.localeCompare(b.createdAt),
  )

  const documents = accountData?.documents ?? []

  //TODO refactor and fix NY/New York mutation during render the page
  const isNYState =
    formik.values.state === STATES.NEW_YORK.value ||
    formik.values.state === STATES.NEW_YORK.match[0]

  const getPersonEnrollment = (person: IPerson) => {
    return (
      person?.enrollments?.find(
        (item) =>
          item.associatedBenefitContract.organization.id ===
          currentOrganizationId,
      ) || { isVerified: false, startDate: null }
    )
  }

  const personName = (() => {
    const { firstName, lastName } = formik.values
    if (!firstName && !lastName) {
      return 'Account'
    }

    return `${firstName} ${lastName}`
  })()

  return (
    <>
      <Breadcrumbs items={[{ label: personName }]} />

      <SinglePersonTemplate
        isReady={isReady}
        isEligibilitiesLoading={isEligibilitiesLoading}
        isEligibilitiesSuccess={isEligibilitiesSuccess}
        eligibilities={eligibilities}
        personData={accountData}
      >
        <div className={styles.flexOne}>
          {duplicatedPersons?.totalRecords ? (
            <PolicyCheckHOC policyName="is_get_duplicate_persons_enabled">
              <BasicBlock paddings="normal" className="mb-6">
                <div className="flex flex-col gap-5">
                  <div className="flex items-center gap-3">
                    <Icons.Info />
                    <div className="text-primary font-bold text-base">
                      We found potential duplicates of this records.
                    </div>
                  </div>
                  <Table>
                    {duplicatedPersons?.records
                      ?.map((record: IPerson, index: number) => ({
                        ...record,
                        index: index + 1,
                      }))
                      ?.slice(
                        paginationOffset,
                        LIMIT_BY_PAGE + paginationOffset,
                      )
                      ?.map((l: IPerson, index: number) => (
                        <Table.Row
                          key={index}
                          id={l.id}
                          editable
                          onClick={() =>
                            setDuplicatePerson(
                              duplicatedPersons.records.find(
                                (item: IPerson) => item.id === l.id,
                              ),
                            )
                          }
                        >
                          <Table.Column title="No." showMobile width={50}>
                            {l.index}
                          </Table.Column>
                          <Table.Column title="Name" showMobile>
                            {`${l.firstName} ${l.lastName}`}
                          </Table.Column>
                          <Table.Column title="Mobile Phone" showMobile>
                            {l.phoneNumber}
                          </Table.Column>
                          <Table.Column title="Email" showMobile>
                            {l.email}
                          </Table.Column>
                          <Table.Column title="Type" showMobile>
                            {getPersonEnrollment(l)?.isVerified
                              ? 'Account'
                              : 'Lead'}
                          </Table.Column>
                          <Table.Column title="Created At" showMobile>
                            {getPersonEnrollment(l)?.isVerified
                              ? dayjs(getPersonEnrollment(l)?.startDate).format(
                                  'MM/DD/YYYY',
                                )
                              : dayjs(l.createdAt).format('MM/DD/YYYY')}
                          </Table.Column>
                        </Table.Row>
                      )) || null}
                  </Table>
                  <div className="w-full text-center">
                    {duplicatedPersons?.totalRecords > LIMIT_BY_PAGE ? (
                      <Pagination
                        limit={LIMIT_BY_PAGE}
                        total={duplicatedPersons?.totalRecords}
                        onChangePage={(page: number) =>
                          setPaginationOffset((page - 1) * LIMIT_BY_PAGE)
                        }
                        currentPage={paginationOffset / LIMIT_BY_PAGE + 1}
                      />
                    ) : null}
                  </div>
                </div>
              </BasicBlock>
            </PolicyCheckHOC>
          ) : null}
          <DriverForm
            eligibilities={eligibilities}
            enrollments={enrollments}
            customerType="account"
            data={accountData}
            formik={formik}
            isSuccess={isSuccess && isTagsSuccess && isOrganizationTagsSuccess}
            onReady={handleFormReady}
            onResult={setResult}
            initialTags={personTags?.records ?? []}
            organizationTags={organizationTags?.records ?? []}
            onTagsUpdate={async (values) => {
              await tagsMutation
                .mutateAsync({ records: values })
                .then(() => {
                  console.log('success tags')
                })
                .catch((error) => {
                  console.log('error tags')
                })
            }}
            isTagsLoading={isTagsLoading || isOrganizationTagsLoading}
            isTagsSuccess={isTagsSuccess && isOrganizationTagsSuccess}
            refetch={refetch}
            policies={userProfile.policies}
          />

          <SingleAccountDocuments
            data={documents}
            isSuccess={isSuccess}
            refetch={refetch}
          />

          <SingleAccountEnrollments
            data={enrollments}
            refetch={refetch}
            isSuccess={isSuccess}
          />

          <SingleAccountFormSubmissions />

          <SingleAccountWashingtonState eligibilities={eligibilities} />

          <PersonNotesBlock
            setIsLeavingTheRecordWithoutNote={setIsLeavingTheRecordWithoutNote}
          />
        </div>
      </SinglePersonTemplate>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={result === 'success' ? undefined : result}
          onClose={() => setResult(null)}
        />
      ) : null}

      {isNYState ? (
        <Portal.Wrapper id="metroPlus" isAdaptive={false}>
          <MetroPlus personType="account" />
        </Portal.Wrapper>
      ) : null}
      {duplicatePerson ? (
        <Modal isOpen onClose={() => setDuplicatePerson(null)} size="large">
          <DuplicatePersons
            duplicatePerson={duplicatePerson}
            currentPerson={accountData}
            persons={duplicatedPersons.records}
          />
        </Modal>
      ) : null}

      {blocker.state === 'blocked' ? (
        <Modal isOpen onClose={() => blocker.reset()}>
          <PersonNoteModal onAfterSave={() => blocker.proceed()} />
        </Modal>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountPageWrapper,
  'is_get_person_by_id_enabled',
)
