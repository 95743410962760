import { useFormik } from 'formik'
import { useState } from 'react'
import { validate as isValidUUID } from 'uuid'
import * as yup from 'yup'

import {
  Button,
  Form,
  Input,
  InputDate,
  InputPhone,
  Modal,
  Select,
  SelectState,
} from 'src/common/components'
import {
  WA_CONTACT_OPTIONS,
  WA_DETAILED_VOICEMAIL_OPTIONS,
  WA_GENDER_OPTIONS,
  WA_LANGUAGE_OPTIONS,
  WA_RACE_ETHNICITY_OPTIONS,
} from 'src/common/config'
import {
  chunkArray,
  getStateAbbreviationByName,
  getStateNameByAbbreviation,
} from 'src/common/helpers'
import { IModal } from 'src/common/interfaces'
import {
  WashingtonStateFieldConfigNames,
  WashingtonStateFieldsConfig,
  WashingtonStateRecord,
} from 'src/common/interfaces/washington-state'
import dayjs from 'src/common/libs/dayjs'
import { MultiSelectContracts } from 'src/default/components'
import { useUpdateWashingtonStateRecord } from 'src/default/hooks/washington-state'
import { IEligibility } from 'src/default/interfaces'
import { useAppSelector } from 'src/v2/store/hooks'

import WashingtonStateConfirmationModal from '../WashingtonStateConfirmationModal/WashingtonStateConfirmationModal'
import WashingtonStateHistoryTracker from '../WashingtonStateHistoryTracker/WashingtonStateHistoryTracker'
import styles from './washington-state-modal.module.scss'

const ITEMS_PER_ROW = 2

interface WashingtonStateModalProps extends IModal {
  washingtonStateRecord: WashingtonStateRecord
  eligibility: IEligibility
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  birthDate: yup.date().required('Birthdate is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
  addressLine1: yup.string().required('Address Line 1 is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  driversLicenseNumber: yup
    .string()
    .required("Driver's License Number is required"),
  preferredContactMethod: yup
    .string()
    .required('Preferred Contact Method is required'),
  language: yup.string().required('Language is required'),
  raceEthnicity: yup
    .array()
    .required('Race Ethnicity is required')
    .min(1, 'Race Ethnicity must have at least 1 items'),
  gender: yup.string().required('Gender is required'),
  detailedVoicemail: yup
    .string()
    .required('Detailed Voicemail Message is required'),
  appSubmissionDate: yup.date().required('Submission Date is required'),
})

const WashingtonStateModal = (props: WashingtonStateModalProps) => {
  const { washingtonStateRecord, eligibility, onClose } = props
  const policies = useAppSelector((state) => state.user.userProfile.policies)
  const isWashingtonStateUpdateEnabled = policies.includes(
    'is_wastate_update_enabled',
  )

  const washingtonStateMutation = useUpdateWashingtonStateRecord()
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)

  const formik = useFormik<WashingtonStateRecord>({
    initialValues: {
      ...washingtonStateRecord,
      SSN_ITIN: '',
      state: getStateNameByAbbreviation(washingtonStateRecord.state),
    },
    validationSchema,
    onSubmit: async (values) => {
      const cleanedValues = removeTemporaryFields(values)
      await washingtonStateMutation
        .mutateAsync({
          ...cleanedValues,
          SSN_ITIN: values.SSN_ITIN || washingtonStateRecord.SSN_ITIN,
          state: getStateAbbreviationByName(values.state),
        })
        .then(() => {
          setOpenConfirmationModal(false)
          onClose?.()
        })
    },
  })

  const removeTemporaryFields = (values: WashingtonStateRecord) => {
    return Object.fromEntries(
      Object.entries(values).filter(
        ([key]) => !key.includes('-current') && !key.includes('-changed'),
      ),
    )
  }

  const formatDate = (date: string | Date) => {
    return dayjs(date).format('MM/DD/YYYY')
  }

  const renderEligibilitySection = () => (
    <>
      <div className={styles.subtitle}>Eligibility</div>
      <div className={styles.inner}>
        <div className={styles.block}>
          <Input
            name="eligibility"
            label="Name"
            value={`${eligibility?.enrollment?.associatedBenefitContract?.name} - ${eligibility?.benefit?.name}`}
            disabled
          />
        </div>
        <div className={styles.block}>
          <Input
            name="providerExternalId"
            label="External ID"
            value={
              isValidUUID(eligibility?.providerExternalId)
                ? 'PENDING'
                : eligibility?.providerExternalId
            }
            disabled
          />
        </div>
        <div className={styles.block}>
          <Input
            name="status"
            label="Status"
            value={eligibility?.status}
            disabled
          />
          <Input
            name="updatedAt"
            label="Date"
            value={formatDate(eligibility?.updatedAt)}
            disabled
          />
        </div>
      </div>
    </>
  )

  const washingtonStateFieldsConfig: WashingtonStateFieldsConfig = {
    firstName: {
      component: Input,
      props: {
        name: 'firstName',
        label: 'First Name',
        required: true,
      },
    },
    lastName: {
      component: Input,
      props: {
        name: 'lastName',
        label: 'Last Name',
        required: true,
      },
    },
    middleInitial: {
      component: Input,
      props: {
        name: 'middleInitial',
        label: 'Middle Initial',
      },
    },
    birthDate: {
      component: InputDate,
      props: {
        name: 'birthDate',
        label: 'Birthdate',
        required: true,
      },
    },
    email: {
      component: Input,
      props: {
        name: 'email',
        label: 'Email',
        required: true,
      },
    },
    phoneNumber: {
      component: InputPhone,
      props: {
        name: 'phoneNumber',
        label: 'Phone Number',
        required: true,
      },
    },
    SSN_ITIN: {
      component: Input,
      props: {
        name: 'SSN_ITIN',
        label: 'SSN/ITIN',
        maxLength: 9,
        min: 9,
      },
    },
    SAWUsername: {
      component: Input,
      props: {
        name: 'SAWUsername',
        label: 'SAW Username',
      },
    },
    addressLine1: {
      component: Input,
      props: {
        name: 'addressLine1',
        label: 'Address Line 1',
        required: true,
      },
    },
    addressLine2: {
      component: Input,
      props: {
        name: 'addressLine2',
        label: 'Address Line 2',
      },
    },
    city: {
      component: Input,
      props: {
        name: 'city',
        label: 'City',
        required: true,
      },
    },
    state: {
      component: SelectState,
      props: {
        name: 'state',
        label: 'State',
        placeholder: 'Enter State',
        required: true,
      },
    },
    zipCode: {
      component: Input,
      props: {
        name: 'zipCode',
        label: 'Zip Code',
        maxLength: 5,
        min: 5,
        required: true,
      },
    },
    zipCodeExt: {
      component: Input,
      props: {
        name: 'zipCodeExt',
        label: 'Zip Code Ext',
        maxLength: 4,
        min: 4,
      },
    },
    driversLicenseNumber: {
      component: Input,
      props: {
        name: 'driversLicenseNumber',
        label: "Driver's License Number",
        required: true,
      },
    },
    preferredContactMethod: {
      component: Select,
      props: {
        name: 'preferredContactMethod',
        label: 'Preferred Contact Method',
        placeholder: 'Select Preferred Contact Method',
        options: WA_CONTACT_OPTIONS,
        required: true,
      },
    },
    language: {
      component: Select,
      props: {
        name: 'language',
        label: 'Language',
        placeholder: 'Select Language',
        options: WA_LANGUAGE_OPTIONS,
        required: true,
      },
    },
    otherLanguage: {
      component: Input,
      props: {
        name: 'otherLanguage',
        label: 'Other Language',
      },
    },
    raceEthnicity: {
      component: MultiSelectContracts,
      props: {
        name: 'raceEthnicity',
        label: 'Race Ethnicity',
        placeholder: 'Select Race Ethnicity',
        className: styles.select,
        withError: false,
        options: WA_RACE_ETHNICITY_OPTIONS,
        required: true,
      },
    },
    otherRaceEthnicity: {
      component: Input,
      props: {
        name: 'otherRaceEthnicity',
        label: 'Other Race Ethnicity',
      },
    },
    gender: {
      component: Select,
      props: {
        name: 'gender',
        label: 'Gender',
        placeholder: 'Select Gender',
        options: WA_GENDER_OPTIONS,
        required: true,
      },
    },
    detailedVoicemail: {
      component: Select,
      props: {
        name: 'detailedVoicemail',
        label: 'Detailed Voicemail Message',
        placeholder: 'Select Detailed Voicemail Message',
        options: WA_DETAILED_VOICEMAIL_OPTIONS,
        required: true,
      },
    },
    appSubmissionDate: {
      component: InputDate,
      props: {
        name: 'appSubmissionDate',
        label: 'Submission Date',
        required: true,
      },
    },
  }

  const fieldsChunked = chunkArray(
    Object.keys(
      washingtonStateFieldsConfig,
    ) as WashingtonStateFieldConfigNames[],
    ITEMS_PER_ROW,
  )

  const renderWashingtonStateFields = () => (
    <>
      <div className={styles.subtitle}>ESD/WA Fields</div>
      <div className={styles.inner}>
        {fieldsChunked.map((rowFields, rowIndex) => (
          <div
            key={rowIndex}
            className={
              rowIndex === fieldsChunked.length - 1
                ? styles.singleBlock
                : styles.block
            }
          >
            {rowFields.map((field) => {
              const Component = washingtonStateFieldsConfig[field]?.component
              const props = washingtonStateFieldsConfig[field]?.props
              return (
                <Component
                  key={field}
                  {...props}
                  disabled={!isWashingtonStateUpdateEnabled}
                />
              )
            })}
          </div>
        ))}
      </div>
    </>
  )

  const renderConfirmationModal = () => (
    <>
      {openConfirmationModal ? (
        <WashingtonStateConfirmationModal
          changedRecords={{
            ...formik.values,
            SSN_ITIN: formik.values.SSN_ITIN || washingtonStateRecord.SSN_ITIN,
            birthDate: formatDate(formik.values.birthDate),
            phoneNumber: formik.values.phoneNumber.replace('+1', ''),
            appSubmissionDate: formatDate(formik.values.appSubmissionDate),
          }}
          currentRecords={{
            ...washingtonStateRecord,
            SSN_ITIN: washingtonStateRecord.SSN_ITIN,
            birthDate: formatDate(washingtonStateRecord.birthDate),
            state: getStateNameByAbbreviation(washingtonStateRecord.state),
            appSubmissionDate: formatDate(
              washingtonStateRecord.appSubmissionDate,
            ),
          }}
          washingtonStateFieldsConfig={washingtonStateFieldsConfig}
          onClose={() => setOpenConfirmationModal(false)}
          onSubmit={formik.handleSubmit}
          isSubmitting={washingtonStateMutation.isPending}
        />
      ) : null}
    </>
  )

  return (
    <Modal id="washingtonStateModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>PFML Submission</div>
        {renderEligibilitySection()}
        {renderWashingtonStateFields()}
        {renderConfirmationModal()}
        <WashingtonStateHistoryTracker objectId={washingtonStateRecord.id} />
        <footer className={styles.footer}>
          <Button
            className={styles.cancelButton}
            template="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={styles.submitButton}
            iconAfter="ArrowSelect"
            loading={washingtonStateMutation.isPending}
            disabled={!isWashingtonStateUpdateEnabled}
            onClick={() => setOpenConfirmationModal(true)}
          >
            Update
          </Button>
        </footer>
      </Form>
    </Modal>
  )
}

export default WashingtonStateModal
