import { getEmailTemplates } from '../useCase/getEmailTemplates.usecase'

const getEmailTemplatesContainer = () => {
  const {
    isLoading,
    handleDateRangeChange,
    templates,
    handlePaginate,
    clearAllFilters,
    isSuccess,
    filterForm,
    totalRecords,
    handleEditEmailTemplate,
    handleDeleteEmailTemplate,
    openConfirmModalToDelete,
    setDisplayDeleteModal,
    displayDeleteModal,
    handleCreateEmailTemplate,
    usersOptions,
  } = getEmailTemplates()

  return {
    templates,
    isLoading,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    isSuccess,
    filterForm,
    totalRecords,
    handleEditEmailTemplate,
    handleDeleteEmailTemplate,
    openConfirmModalToDelete,
    setDisplayDeleteModal,
    displayDeleteModal,
    handleCreateEmailTemplate,
    usersOptions,
  }
}

export default getEmailTemplatesContainer
