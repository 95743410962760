import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonTagsParams {
  personId?: string
  organizationId?: string | null
}

export interface TagDto {
  id: string
  name: string
  isHided: true
  isNotEligible: true
  isProtected: true
  color: string
}

const useGetPersonTags = (params: GetPersonTagsParams) => {
  const { personId, organizationId } = params

  let url = `/persons/${personId}/${organizationId}/tags`

  return useQuery({
    queryKey: [`getPersonTags`, url],
    queryFn: async () => await api.get(url),
  })
}

export default useGetPersonTags
