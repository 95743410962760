import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { Breadcrumbs, Tabs } from 'src/common/components'
import { yupPhoneValidator } from 'src/common/helpers'
import { useRefetchById } from 'src/common/hooks'
import { IUserMutationData } from 'src/common/interfaces'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { QUERY_IDS } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import {
  useGetUser,
  useMutateUser,
  useUpdateUserHipaaStatus,
} from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import { UserEmailDomains, UserInformationTab } from './_components'
import styles from './single-user-page.module.scss'

const SingleUserPage = () => {
  const { userId } = useParams<{ userId: string }>()

  const session = useAppSelector((state) => state.auth)

  const [result, setResult] = useState<string | null>(null)

  const refetchById = useRefetchById()

  const { data: { data: userData } = { data: {} }, isLoading } = useGetUser({
    userId: userId || '',
  })

  const userMutation = useMutateUser('update', session.accessToken)
  const hipaaMutation = useUpdateUserHipaaStatus()

  const formik = useFormik<IUserMutationData>({
    initialValues: {
      id: userId,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    onSubmit: async (values) => {
      const newUserValues = {
        ...values,
        phone: values.phone ? values.phone.replaceAll('+', '') : undefined,
        password: values.password || undefined,
      }

      delete newUserValues.hipaaCertified

      await userMutation
        .mutateAsync(newUserValues)
        .then(async ({ data }) => {
          return hipaaMutation.mutateAsync({
            userId: userId || '',
            certified: Boolean(values.hipaaCertified),
          })
        })
        .then(() => {
          setResult('success')

          refetchById(QUERY_IDS.WHO_AM_I)
        })
        .catch((error) => {
          console.log('error', error)
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required(),
      lastName: yup.string().name().required(),
      //  associatedBenefitContract: yup.array().required().min(1),
      password: yup.string().min(8, 'At least 8 characters'),
      phone: yupPhoneValidator(),
      aircallUserId: yup.string(),
    }),
  })

  const { values } = formik

  useEffect(() => {
    if (Object.keys(userData).length) {
      const {
        id,
        firstName,
        lastName,
        email,
        role,
        phone,
        //  associatedBenefitContract,
        hipaaCertified,
        aircallUserId,
      } = userData //TODO better
      formik.setValues({
        ...values,
        id,
        firstName,
        lastName,
        email,
        // role,
        phone,
        hipaaCertified,
        aircallUserId,
        /*        associatedBenefitContract: associatedBenefitContract.map(
          (contract: any) => contract.id,
        ),*/
      })
    }
  }, [userData])

  const handleCloseResult = () => {
    setResult(null)
  }

  const userName = (() => {
    const { firstName, lastName } = formik.values
    if (!firstName && !lastName) {
      return 'User'
    }

    return `${firstName} ${lastName}`
  })()

  return (
    <>
      <Breadcrumbs items={[{ label: userName }]} />

      <BasicTemplate isLoading={isLoading}>
        <Tabs className={styles.tabs} show={true}>
          <Tabs.Item id="userForm" title="User Information">
            <UserInformationTab
              firstName={values.firstName ?? ''}
              lastName={values.lastName ?? ''}
              formik={formik}
            />
          </Tabs.Item>

          <Tabs.Item id="emailDomains" title="User Email Domains">
            <UserEmailDomains
              firstName={values.firstName ?? ''}
              lastName={values.lastName ?? ''}
            />
          </Tabs.Item>
        </Tabs>
      </BasicTemplate>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={result !== 'success' ? result : undefined}
          onClose={handleCloseResult}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleUserPage,
  'is_enable_single_user_view',
  true,
)
