import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { useRouter } from '@v2/commons/utils/navigation'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/default/config'

import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplateQuery,
  useUpdateEmailTemplateMutation,
} from '../models/emailTemplate.api'
import { IEmailTemplate } from '../models/emailTemplate.model'

export const getEmailTemplate = () => {
  const router = useRouter()
  const { emailTemplateId } = useParams()

  const initialEmailTemplate = {
    id: '',
    subject: '',
    type: '',
    description: '',
    body: '',
  }

  const [isEditEmailTemplate, setIsEditEmailTemplate] = useState<boolean>(false)
  const [isDeleteEmailTemplate, setIsDeleteEmailTemplate] =
    useState<boolean>(false)
  const { data, isLoading } = useGetEmailTemplateQuery(emailTemplateId ?? '')

  const [updateEmail] = useUpdateEmailTemplateMutation()

  const emailTemplateForm = useForm<IEmailTemplate>({
    mode: 'uncontrolled',
    initialValues: {
      id: data?.id ?? '',
      subject: data?.subject ?? '',
      type: data?.type ?? 'PUBLIC',
      description: data?.description ?? '',
      body: data?.body ?? '',
    },
    validateInputOnBlur: true,
    validate: {
      id: isNotEmpty(),
      subject: isNotEmpty(),
      type: isNotEmpty(),
      description: isNotEmpty(),
      body: isNotEmpty(),
    },
  })

  useEffect(() => {
    emailTemplateForm.setValues({
      id: data?.id ?? '',
      subject: data?.subject ?? '',
      type: data?.type ?? 'PUBLIC',
      description: data?.description ?? '',
      body: data?.body ?? '',
    })
  }, [isLoading])

  const setEmailBody = (value: string) => {
    emailTemplateForm.setFieldValue('body', value)
  }

  const onSubmit = () => {
    emailTemplateForm.onSubmit(async (values) => {
      updateEmail({ ...values })
        .unwrap()
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'Role succesfuly created',
          })
        })
        .catch(() => {
          notifications.show({
            title: 'Oops',
            message: 'There was an error',
            color: 'red',
          })
        })
    })()
  }

  const handleViewEmailTemplates = () => {
    router.push(`${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}`)
  }

  const [deleteEmailTemplateMutation] = useDeleteEmailTemplateMutation()

  const handleDeleteEmailTemplate = () => {
    deleteEmailTemplateMutation(emailTemplateForm.getValues().id)
      .unwrap()
      .then(() => {
        router.push(`${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}`)
        notifications.show({
          title: 'Success',
          message: 'Emailt Tempalte succesfuly deleted',
        })
      })
      .catch(() => {
        notifications.show({
          message: 'There was an error',
          title: 'Oops',
          color: 'red',
        })
      })
  }

  return {
    isLoading,
    initialEmailTemplate,
    isEditEmailTemplate,
    setIsEditEmailTemplate,
    isDeleteEmailTemplate,
    setIsDeleteEmailTemplate,
    emailTemplateForm,
    onSubmit,
    handleViewEmailTemplates,
    setEmailBody,
    handleDeleteEmailTemplate,
  }
}
