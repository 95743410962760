import { useFormik } from 'formik'

import { Form, Select } from 'src/common/components'
import { IOrganization } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'

interface SelectOrganizationProps {
  optionsWrapperClassName?: string
  onChange: (organizationId: string, membershipId: string) => void
  onCustomEvent?: (eventId: string, event: any) => void
  strictValue?: string
}

const SelectOrganization = (props: SelectOrganizationProps) => {
  const { optionsWrapperClassName, onChange, onCustomEvent } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const organizationsByUser = useAppSelector(
    (state) => state.organization.organizationsByUser,
  )

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  })

  const handleChange = (newValue: string) => {
    if (newValue) {
      const organization = organizationsByUser.find(
        (organization) => organization.id === newValue,
      )

      if (organization) {
        const organizationId = organization.id!
        const membershipId = organization.memberships?.at(0)?.id ?? ''
        onChange(organizationId, membershipId)
      }
    }
  }

  const options =
    organizationsByUser.map((organization: IOrganization) => ({
      value: organization.id || '',
      label: organization.name || 'undefined',
    })) ?? []

  return (
    <Form providerValue={formik}>
      <Select
        required
        optionsWrapperClassName={optionsWrapperClassName}
        name="organizationId"
        label="Organization"
        placeholder="Select Organization"
        options={options}
        value={currentOrganizationId}
        withError={false}
        onChange={handleChange}
        onCustomEvent={onCustomEvent}
      />
    </Form>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SelectOrganization,
  'is_get_auth_organizations_enabled',
  true,
)
