import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

export const useMutationSmsReport = () => {
  const mutation = useMutation({
    mutationFn: async (fields: { fromDate: String; toDate: String }) => {
      const { data } = await api.post(`/sms-reports/usage`, fields, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return data
    },
  })
  return mutation
}
