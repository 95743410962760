import cx from 'classnames'
import { useField } from 'formik'

import { IDMPNote } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../../deactivation-benefit-case.module.scss'
import DeactivationBenefitNote from './DeactivationBenefitNote'
import styles from './deactivation-benefit-notes.module.scss'

const DeactivationBenefitNotes = () => {
  const [{ value: notes }] = useField('notes')

  if (!notes?.length) {
    return null
  }

  return (
    <div className={cx(rootStyles.caseBlock, styles.wrapper)}>
      <div className={rootStyles.title}>IDG Staff Notes</div>

      <div>
        {notes.map((note: IDMPNote, i: number) => (
          <DeactivationBenefitNote key={i} {...note} />
        ))}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitNotes,
  'is_enable_deactivation_benefit_notes',
  true,
)
