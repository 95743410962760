import { useField } from 'formik'

import { Checkbox, FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import styles from './basic-multi-select-checkboxes.module.scss'

interface BasicMultiSelectCheckboxesProps extends IField {
  withAll?: boolean
}

const BasicMultiSelectCheckboxes = (
  props: React.PropsWithChildren<BasicMultiSelectCheckboxesProps>,
) => {
  const { withAll, children, ...rest } = props
  const { name, options } = rest

  const [{ value: fieldValue }, meta, { setValue }] = useField(name)

  const handleChange = (value: string) => {
    const currentValue = fieldValue || []

    if (!currentValue.includes(value)) {
      setValue([...currentValue, value])
    } else {
      setValue([...currentValue.filter((item: string) => item !== value)])
    }
  }

  const handleChangeAll = () => {
    const isFilled =
      options?.every((o) => fieldValue?.includes(o.value)) ?? null
    if (isFilled) {
      setValue([])
    } else {
      setValue(options?.map((o) => o.value))
    }
  }

  return (
    <FieldWrapper {...rest} name={name}>
      <div className={styles.wrapper}>
        {withAll ? (
          <Checkbox
            name={`checkbox-${name}-all`}
            label="All"
            value={options?.every((o) => fieldValue?.includes(o.value)) ?? null}
            onChange={() => handleChangeAll()}
          />
        ) : null}
        {options?.map((o, i) => (
          <Checkbox
            key={i}
            name={`checkbox-${name}-${o.value}`}
            label={o.label}
            value={fieldValue?.includes(o.value) ?? null}
            onChange={() => handleChange(o.value)}
          />
        ))}
      </div>
    </FieldWrapper>
  )
}

export default BasicMultiSelectCheckboxes
