import { Icons } from 'src/common/components'

import styles from './field-item.module.scss'

interface FieldItemProps {
  value: string
  onRemove?: (value: string) => void
}

const FieldItem = (props: FieldItemProps) => {
  const { value, onRemove = () => {} } = props

  return (
    <div className={styles.wrapper} onClick={() => onRemove(value)}>
      <div className={styles.inner}>{value}</div>

      <div className={styles.close}>
        <Icons.Close />
      </div>
    </div>
  )
}

export default FieldItem
