import { TextInput } from '@mantine/core'

export default {
  TextInput: TextInput.extend({
    styles: {
      input: {
        borderWidth: '2px',
        borderRadius: '8px',
      },
    },
  }),
}
