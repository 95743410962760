import { FormikContextType } from 'formik'
import { useEffect, useState } from 'react'

import { Form, Input, Select, SubmitButton } from 'src/common/components'
import { useGetOrganizationUsers } from 'src/default/hooks'
import { IFormTemplate } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './form-template.module.scss'

interface FormTemplateProps {
  formik: FormikContextType<IFormTemplate>
  isCreating?: boolean
}

const FormTemplate = (props: FormTemplateProps) => {
  const { formik } = props
  const [users, setUsers] = useState<any>([])

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const { data: organizationUsers, isSuccess } = useGetOrganizationUsers({
    organizationId: currentOrganizationId ?? '',
  })

  useEffect(() => {
    if (isSuccess && organizationUsers?.data) {
      const listUsers = organizationUsers?.data.map((users: any) => {
        return {
          value: users?.user?.id,
          label: `${users?.user?.firstName} ${users?.user?.lastName}`,
        }
      })
      setUsers(listUsers)
    }
  }, [isSuccess, organizationUsers])

  return (
    <>
      <Form providerValue={formik}>
        <div className={styles.wrapper}>
          <Input
            required
            name="notifyEmails"
            label="Emails for notify (Add emails separate by comma)"
            width="33"
          />

          <Input required name="url" label="URL" width="33" />

          <Select
            required
            name="type"
            label="Type"
            width="33"
            options={[
              { label: 'Public', value: 'public' },
              { label: 'Private', value: 'private' },
            ]}
          />

          <Input required name="name" label="Form’s Name" width="33" />

          <Select
            required
            name="userId"
            label="User"
            options={users}
            width="33"
          />
        </div>

        <div className={styles.buttonsWrapper}>
          <SubmitButton loading={formik.isSubmitting}>Save</SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  FormTemplate,
  'is_crete_form_template_enabled',
  true,
)
