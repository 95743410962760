import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetCatalogContractsParams {
  organizationId: string
}

const useGetABCsByOrganizationId = (params: GetCatalogContractsParams) => {
  const { organizationId } = params

  return useQuery({
    queryKey: [`useGetCatalogAbcsByOrganizationId`, organizationId],
    queryFn: async () =>
      await api.get(`v3/abc/?organizationId=${organizationId}`),
  })
}

export default useGetABCsByOrganizationId
