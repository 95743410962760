import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface DeletePersonHouseholder {
  personId: string
}

const useDeletePersonHouseholder = () =>
  useMutation({
    mutationFn: async (data: DeletePersonHouseholder) =>
      await api.delete(`/benestream/persons/income/${data.personId}`),
  })

export default useDeletePersonHouseholder
