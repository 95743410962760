import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { isPending } from '@reduxjs/toolkit'
import {
  type PersonModel,
  type PersonsFiltersModel,
} from '@v2/domains/person/models/person.model'
import { useAppSelector } from '@v2/store/hooks'

import {
  useAssignTaskMutation,
  useAssignTasksMutation,
} from '../models/task.api'
import { type ITaskParams, type ItaskModalForm } from '../models/task.model'
import { type IMutateTasksData } from '../models/task.model'

type OnSubmit = (
  selectedItems: Pick<PersonModel, 'id'>['id'][],
  tableFilters: ITaskParams,
) => void

const INITIAL_VALUES: ItaskModalForm = {
  type: '',
  subject: '',
  assignedToId: '',
  priority: '',
  personId: '',
  isPersonAssign: false,
  note: '',
  isNotify: false,
}

const useTaskUseCase = () => {
  const organizationId = useAppSelector(
    (state) => state.user.userProfile.organization.id,
  )
  const [assignTasks, taskMutationStatus] = useAssignTasksMutation()
  const [assignSingleTask, singleTaskMutationStatus] = useAssignTaskMutation()

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: INITIAL_VALUES,
    validateInputOnBlur: true,
    validate: {
      type: isNotEmpty('this field is required'),
      subject: isNotEmpty('this field is required'),
      assignedToId: isNotEmpty('this field is required'),
      note: isNotEmpty('this field is required'),
      activityDate: isNotEmpty('this field is required'),
    },
  })

  const handleAssignMultipleTasks = (
    filters: PersonsFiltersModel,
    personIds: string[],
    values: ItaskModalForm,
  ) => {
    const { personId, ...rest } = values
    const query = {
      params: filters,
      body: {
        organizationId,
        personIds,
        ...rest,
      },
    }
    return assignTasks(query)
  }

  const handleAssignSingleTask = (
    personsIds: string[],
    values: ItaskModalForm,
  ) => {
    const query = {
      organizationId,
      ...values,
      personId: personsIds[0],
    }
    return assignSingleTask(query)
  }

  const onSubmitForm: OnSubmit = async (personIds, filters) => {
    form.onSubmit(async (values) => {
      const assignTaskFunciton = () =>
        personIds.length > 1
          ? handleAssignMultipleTasks(filters, personIds, values)
          : handleAssignSingleTask(personIds, values)

      assignTaskFunciton()
        .unwrap()
        .then(() => {
          form.reset()
        })
        .catch((error) => {
          notifications.show({
            title: 'Something went wrong...',
            message: error.data.error,
            color: 'red',
          })
        })
    })()
  }
  return {
    getFormValues: form.getValues,
    getFormProps: form.getInputProps,
    assignFieldKey: form.key,
    onSubmitForm,
    taskMutationStatus: {
      isSuccess:
        taskMutationStatus.isSuccess || singleTaskMutationStatus.isSuccess,
      isLoading:
        taskMutationStatus.isLoading || singleTaskMutationStatus.isLoading,
    },
  }
}

export default useTaskUseCase
