import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { IEmailDomain } from 'src/v2/commons/interface/membershipEmailDomain'

const useMutateEmailDomain = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: IEmailDomain) => {
      const { id, ...restData } = data
      const isUpdateAction = action === 'update'

      const url = isUpdateAction ? `/email-domains/${id}` : '/email-domains'

      return api[isUpdateAction ? 'patch' : 'post'](url, restData)
    },
  })

export default useMutateEmailDomain
