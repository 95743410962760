import { ComboboxItem, Table } from '@mantine/core'
import { getPhoneWithMask } from '@v2/commons/utils/phone'
import { Status } from '@v2/domains/person/components/Tables/StatusColumn'
import React from 'react'

import { OpenTicketsProps, TicketModel } from '../models/ticket.model'
import styles from '../tickets.module.scss'
import { AssignTicket } from './AssignTicket.component'
import TicketCustomerLink from './TicketCustomerLink.component'

export const OpenTickets = (props: OpenTicketsProps) => {
  const {
    isLoading,
    tickets,
    usersOptions,
    openAssignModal,
    setOrganizer,
    setTicketToUpdate,
    isErrorAssignTicket,
  } = props

  const onChangeAssigneee = (assignee: ComboboxItem | null, id: string) => {
    setOrganizer({ label: assignee?.label ?? '', value: assignee?.value ?? '' })
    setTicketToUpdate(id)
    openAssignModal()
  }

  return (
    <>
      {tickets?.records ? (
        <div>
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Ticket Number</Table.Th>
                <Table.Th>Name</Table.Th>
                <Table.Th>Email</Table.Th>
                <Table.Th>Mobile</Table.Th>
                <Table.Th>Ticket Status</Table.Th>
                <Table.Th>Reason For Visit</Table.Th>
                <Table.Th>Assigned To</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tickets.records.map((t: TicketModel) => (
                <Table.Tr key={t.id} id={t.id} className={styles.row}>
                  <Table.Td>
                    <div className={styles.ticketNumberWrapper}>
                      <TicketCustomerLink className={styles.status} ticket={t}>
                        <Status
                          className={styles.ticketNumber}
                          color="orange"
                          hideMobileLabel
                        ></Status>
                        {`Queued #${t.numberVisit}`}
                      </TicketCustomerLink>
                    </div>
                  </Table.Td>
                  <Table.Td className={styles.name}>
                    {t.personId ? (
                      <TicketCustomerLink ticket={t}>
                        {t.name}
                      </TicketCustomerLink>
                    ) : (
                      t.name
                    )}
                  </Table.Td>
                  <Table.Td className={styles.secondary}>{t.email}</Table.Td>
                  <Table.Td className={styles.secondary}>
                    {getPhoneWithMask(t.phoneNumber)}
                  </Table.Td>
                  <Table.Td className={styles.secondary}>{t.status}</Table.Td>
                  <Table.Td className={styles.secondary}>
                    {t.reasonForVisit}
                  </Table.Td>
                  <Table.Td>
                    <AssignTicket
                      tickteId={t.id}
                      initialValue={t.assignUserId}
                      options={usersOptions}
                      onChangeAssignee={onChangeAssigneee}
                      isErrorAssignTicket={isErrorAssignTicket}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
      ) : (
        <>{!isLoading ? 'No Open Tickets Available' : null}</>
      )}
    </>
  )
}
