import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { BasicBlock, Button, EmptyPersons, Table } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import { capitalize, concatQueryPages } from 'src/common/helpers'
import { InfiniteBasicTemplate } from 'src/common/templates'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useGetSmsCampaigns } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import SmsCampaignsReportModal from './SmsCampaignsReportModal'
import styles from './sms-campaigns.module.scss'

const SmsCampaigns = () => {
  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isFetching,
  } = useGetSmsCampaigns({
    limit: PAGINATION_LIMIT,
  })
  const { organizationId } = useParams<{ organizationId: string }>()

  const [generateReportModalOpened, { open, close }] = useDisclosure(false)
  const [showModal, setShowModal] = useState(false)

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const items = concatQueryPages<any>(pages)

  return (
    <InfiniteBasicTemplate
      title="SMS Campaigns"
      scrollPromise={handlePageScroll}
      isLoading={pages.length === 0 && isFetching}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: items?.length ?? 0,
      }}
    >
      <PolicyCheckHOC policyName="is_send_sms_campaign_enabled">
        <div className={styles.buttonsWrapper}>
          <Button
            size="small"
            className={styles.button}
            to={`/${organizationId}${PRIVATE_ROUTES.SMS_CAMPAIGNS.path}/create`}
          >
            Create New SMS Campaign
          </Button>

          <Button
            size="small"
            className={styles.button}
            onClick={() => setShowModal(true)}
          >
            Create CSV Report
          </Button>
          {showModal && (
            <SmsCampaignsReportModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      </PolicyCheckHOC>
      <PolicyCheckHOC
        policyName="is_enable_get_sms_campaigns"
        disablePolicyCheck
      >
        <BasicBlock
          className={styles.wrapper}
          paddings="big"
          isLoadingTop={isFetching}
        >
          {items?.length ? (
            <Table>
              {items?.map((item: any) => (
                <Table.Row
                  key={item.id}
                  editable
                  id={item.id}
                  editLink={`/${organizationId}${PRIVATE_ROUTES.SMS_CAMPAIGNS.path}/:id`}
                >
                  <Table.Column title="Name" showMobile>
                    {item.name}
                  </Table.Column>
                  <Table.Column title="Sms Message" showMobile>
                    {item.smsMessage}
                  </Table.Column>
                  <Table.Column title="Targets" showMobile>
                    {item.targets}
                  </Table.Column>
                  <Table.Column title="Status" showMobile>
                    {capitalize(item.status)}
                  </Table.Column>
                  <Table.Column title="Created at" showMobile>
                    {dayjs(item.createdAt).format('MM/DD/YYYY : HH:mm')}
                  </Table.Column>
                </Table.Row>
              ))}
            </Table>
          ) : (
            <EmptyPersons term="sms campaign" />
          )}
        </BasicBlock>
      </PolicyCheckHOC>
    </InfiniteBasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SmsCampaigns,
  'is_enable_sms_campaigns_view',
  true,
)
