import { GetInputProps } from '@mantine/form/lib/types'

import { ForgotFormModel } from '../models/auth.model'
import useForgotFormUseCase from '../useCases/useForgotFormUseCase'

interface IUseForgotFormContainer {
  getFormProps: GetInputProps<ForgotFormModel>
  onSubmit: (callback: () => void) => void
  forgotMutationStatus: {
    isLoading: boolean
    isSuccess: boolean
  }
}

const useForgotFormContainer = (): IUseForgotFormContainer => {
  const { getFormProps, onSubmit, forgotMutationStatus } =
    useForgotFormUseCase()

  return {
    getFormProps,
    forgotMutationStatus,
    onSubmit,
  }
}

export default useForgotFormContainer
