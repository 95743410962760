import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { QUERY_IDS } from 'src/common/config'

interface GetEligibilitiesParams {
  personId?: string
  organizationId?: string
}

const useGetEligibilities = (params: GetEligibilitiesParams) => {
  const { personId, organizationId } = params

  let url = `/eligibilities/person/${personId}?organizationId=${organizationId}`

  return useQuery({
    queryKey: [QUERY_IDS.GET_ELIGIBILITIES],
    queryFn: async () => await api.get(url),
    enabled: !!organizationId,
    gcTime: 0,
    refetchOnMount: 'always',
  })
}

export default useGetEligibilities
