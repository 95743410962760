import cx from 'classnames'

import { Icons } from 'src/common/components/ui'

import { useAppSelector } from '../../../../../../v2/store/hooks'
import styles from './upload-file-error.module.scss'

interface UploadFileErrorProps {
  errorMessage: string
  removeCsvFile: () => void
}

const UploadFileError = (props: UploadFileErrorProps) => {
  const { errorMessage, removeCsvFile } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const errorTitle = errorMessage.includes('MB')
    ? 'Unable to Upload File!'
    : 'Unable to Read File!'

  return (
    <div
      className={cx(styles.fileErrorWrapper, styles[`${currentTheme}Slice`])}
    >
      <div className={styles.iconError}>
        <Icons.FileError />
      </div>
      <div className={styles.title}>{errorTitle}</div>
      <div className={styles.description}>
        {errorMessage || 'Please review the CVS file and try again.'}
      </div>
      <div className={styles.button} onClick={removeCsvFile}>
        Try again
      </div>
    </div>
  )
}

export default UploadFileError
