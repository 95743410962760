import { Select } from 'src/common/components'
import { reasonsForVisit } from 'src/default/helpers'

const PastTicketsTableFilterReason = () => {
  return (
    <Select
      name="reasonForVisit"
      placeholder="Reason For Visit"
      options={reasonsForVisit}
      withError={false}
      template="filter"
    />
  )
}

export default PastTicketsTableFilterReason
