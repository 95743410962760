import styles from './phone-number.module.scss'

interface PhoneNumberProps {
  value?: string
}

const PhoneNumber = (props: React.PropsWithChildren<PhoneNumberProps>) => {
  const { children } = props

  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
    </div>
  )
}

export default PhoneNumber
