import { UseFormReturnType } from '@mantine/form'

import { FileUploader } from '../../../../../common/components'
import { CreateSmsCampaignModel } from '../../models/smsCampaign.model'

const StepTwoCreateSmsCampaign = ({
  form,
}: {
  form: UseFormReturnType<CreateSmsCampaignModel>
}) => {
  const handleSetFile = (newFile: File) => {
    form.setFieldValue('targets', newFile)
  }

  return (
    <div>
      <FileUploader
        label="Targets"
        required
        description="Upload a CSV file with all targets"
        maxSizeMB={5}
        formats={['csv']}
        file={form.getValues().targets}
        setFile={handleSetFile}
        setUrlValue={() => null}
        errorMessage=""
        setErrorMessage={() => null}
        dragActiveDescription=""
      />
    </div>
  )
}

export default StepTwoCreateSmsCampaign
