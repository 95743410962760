import cx from 'classnames'
import { useParams } from 'react-router-dom'

import { BasicBlock, Button } from 'src/common/components'
import { useWindowSize } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { setNewCommunication } from '../../../../v2/domains/communication/models/communication.store'
import { useAppDispatch, useAppSelector } from '../../../../v2/store/hooks'
import CommunicationsList from './CommunicationsList'
import styles from './communications.module.scss'

interface CommunicationsProps {
  hideCreateCommunication?: boolean
}

const Communications = (props: CommunicationsProps) => {
  const dispatch = useAppDispatch()

  const newCommunication = useAppSelector(
    (state) => state.communication.newCommunication,
  )

  const { hideCreateCommunication } = props

  const { personId } = useParams<{ personId: string }>()

  const windowSize = useWindowSize()

  const handleNewCommunication = () => {
    dispatch(setNewCommunication(true))
  }

  const newButtonSize =
    windowSize.width > 420 && windowSize.width < 993 ? 'small' : 'micro'

  return (
    <BasicBlock
      className={styles.wrapper}
      title="Communications"
      header={
        !hideCreateCommunication ? (
          <PolicyCheckHOC policyName="is_send_email_to_person_enabled">
            <Button
              onClick={handleNewCommunication}
              size={newButtonSize}
              icon="Plus"
              className={cx(
                styles.btnNew,
                newButtonSize === 'small' && styles.big,
              )}
              disabled={newCommunication}
            >
              {`New ${newButtonSize === 'small' ? ' Communication' : ''} `}
            </Button>
          </PolicyCheckHOC>
        ) : null
      }
      paddings="small"
      isLoading={false}
      isShowWrapper={false}
    >
      <CommunicationsList personId={personId ?? ''} />
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Communications,
  'is_enable_view_communication_tab',
)
