import { useEffect, useRef, useState } from 'react'

import { Loader } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'

import styles from './scroll-load.module.scss'

interface ScrollLoadProps {
  onLoad?: (options: { pageParam: number }) => Promise<any>
  onNextPeriod?: () => Promise<any>
  maxHeight?: string
  isLoading?: boolean
}

const ScrollLoad = (props: React.PropsWithChildren<ScrollLoadProps>) => {
  const { onLoad, onNextPeriod, maxHeight, children, isLoading } = props

  const innerRef = useRef<HTMLDivElement>(null)

  const [page, setPage] = useState<number>(0)

  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null)

  const [needNext, setNeedNext] = useState<boolean>(false)

  useEffect(() => {
    if (innerRef.current) {
      setWrapperHeight(innerRef.current?.offsetHeight - 100)
    }
  }, [innerRef.current])

  useEffect(() => {
    if (needNext) {
      if (onLoad) {
        ;(async () => {
          const newPage = page + 1

          setPage(newPage)

          await onLoad({
            pageParam: PAGINATION_LIMIT * newPage,
          })

          setNeedNext(false)
        })()
      } else if (onNextPeriod) {
        onNextPeriod().then(() => {
          setNeedNext(false)
        })
      }
    }
  }, [needNext])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget
    const scroll = scrollHeight - scrollTop - clientHeight

    if (!needNext && scroll === 0) {
      setNeedNext(true)
    }
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        maxHeight: maxHeight
          ? `${maxHeight}`
          : wrapperHeight
          ? `${wrapperHeight}px`
          : 'none',
      }}
      onScroll={handleScroll}
    >
      <div ref={innerRef}>
        {children}

        <Loader isLoading={needNext || isLoading} />
      </div>
    </div>
  )
}

export default ScrollLoad
