import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  CurrentTheme,
  CurrentThemeType,
  LocalStorageLogo,
  LocalStorageUI,
  UiModel,
} from './ui.model'

const initialState: UiModel = {
  isGlobalLoading: false,
  currentTheme: CurrentTheme.DEFAULT_360,
  organizationLogo: '',
}

const uiStore = createSlice({
  name: 'ui',
  initialState: {
    ...initialState,
    currentTheme: localStorage.getItem(LocalStorageUI)
      ? JSON.parse(localStorage.getItem(LocalStorageUI) as string)
      : 'CurrentTheme.DEFAULT_360',
    organizationLogo: localStorage.getItem(LocalStorageLogo)
      ? JSON.parse(localStorage.getItem(LocalStorageLogo) as string)
      : '',
  } as UiModel,
  reducers: {
    setGlobalIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload
    },
    setCurrentTheme: (state, action: PayloadAction<CurrentThemeType>) => {
      state.currentTheme = action.payload
      localStorage.setItem(LocalStorageUI, JSON.stringify(action.payload))
    },
    setOrganizationLogo: (state, action: PayloadAction<string>) => {
      state.organizationLogo = action.payload
      localStorage.setItem(LocalStorageLogo, JSON.stringify(action.payload))
    },
    destroyUILocalStore: (state) => {
      state.currentTheme = CurrentTheme.DEFAULT_360
      localStorage.setItem(LocalStorageUI, '')
    },
  },
})

export const {
  setGlobalIsLoading,
  setCurrentTheme,
  setOrganizationLogo,
  destroyUILocalStore,
} = uiStore.actions

export default uiStore.reducer
