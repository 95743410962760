import useGetUsersNoPaginattionUseCase from 'src/v2/domains/user/useCase/useGetUsersNoPagination.useCase'

import useTaskUseCase from '../useCase/task.useCase'

const useTaskContainer = () => {
  const { getFormProps, onSubmitForm, assignFieldKey, taskMutationStatus } =
    useTaskUseCase()

  const { users } = useGetUsersNoPaginattionUseCase()

  return {
    getFormProps,
    users,
    onSubmitForm,
    assignFieldKey,
    taskMutationStatus,
  }
}

export default useTaskContainer
