import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from './baseQuery'

export const ENDPOINTS_TAGS = {
  ROOT: 'ROOT',
  USERS: 'USERS',
  ROLES_BY_ID: 'ROLES_BY_ID',
  ROLES: 'ROLES',
}

export const rootApi = createApi({
  reducerPath: 'rootServices',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: Object.values(ENDPOINTS_TAGS),
})
