import cx from 'classnames'
import { useField } from 'formik'

import { Input, InputDate } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './input-date-and-age.module.scss'

const InputDateAndAge = (props: IField) => {
  const { className, name, width, tabletWidth, format, maxYear, futureDate } =
    props

  const dateFormat = format || 'MM/DD/YYYY'

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [field, meta] = useField({ name })

  const ageValue = (() => {
    if (dayjs(field.value).isValid()) {
      const currentAge =
        dayjs().diff(dayjs(field.value).format(dateFormat), 'year') || 0
      if (currentAge > 0) {
        return dayjs().diff(dayjs(field.value).format(dateFormat), 'year')
      }
    }

    return ''
  })()

  return (
    <div
      className={cx(
        styles.wrapper,
        width && styles[`w${width}`],
        tabletWidth && styles[`tabletW${tabletWidth}`],
      )}
    >
      <div className={styles.inner} data-name={name}>
        <InputDate
          {...props}
          className={cx(styles.date, className)}
          width={undefined}
          withError={false}
          strictError={meta.touched && meta.error ? meta.error : undefined}
          format={dateFormat}
          maxYear={maxYear}
          futureDate={futureDate}
        />

        <div className={styles.age}>
          <Input
            name="age"
            label="Age"
            value={ageValue}
            disabled
            withError={false}
            onChange={() => false}
            required={false}
            labelClassName={props.labelClassName}
            inputClassName={props.inputClassName}
          />
        </div>
      </div>

      <div className={cx(styles.errorComment, styles[`${currentTheme}Slice`])}>
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </div>
  )
}

export default InputDateAndAge
