import { SetStateAction } from 'react'

import { User, UserOrganizationV2, typeOrderBy } from '../models/user.model'
import { type UsersResponseModel } from '../models/user.model'
import useGetUsersUseCase from '../useCase/useGetUsers'

interface UseAdministrationUsersContainer {
  users: UsersResponseModel | undefined
  handlePaginate: () => void
  isLoading: boolean
  isFetching: boolean
  handleEditUser: (userId: string) => void
  handleOrderBy: (field: string, orderBy: typeOrderBy) => void
  filterOrderBy: { field: string; orderBy: typeOrderBy }
  handleCreateUser: () => void
}

const useAdministrationUsersContainer = (): UseAdministrationUsersContainer => {
  const {
    users,
    usersQueryStatus: { isLoading, isFetching },
    handleEditUser,
    handlePaginate,
    handleOrderBy,
    filterOrderBy,
    handleCreateUser,
  } = useGetUsersUseCase()

  return {
    users,
    isFetching,
    isLoading,
    handleEditUser,
    handlePaginate,
    handleOrderBy,
    filterOrderBy,
    handleCreateUser,
  }
}

export default useAdministrationUsersContainer
