import { IField } from 'src/common/interfaces'
import { MultiSelect } from 'src/default/components'
import { IOptionComponent } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import MultiSelectContractsOption from './MultiSelectContractsOption'

interface MultiSelectContractsProps extends IField {
  OptionComponent?: (props: IOptionComponent) => React.ReactElement
}

const MultiSelectContracts = (props: MultiSelectContractsProps) => {
  const { options } = props

  const sortedOptions = options?.sort((a, b) => {
    if (a.value < b.value) {
      return -1
    }
    if (a.value > b.value) {
      return 1
    }
    return 0
  })

  return (
    <MultiSelect
      OptionComponent={MultiSelectContractsOption}
      {...props}
      options={sortedOptions}
    />
  )
}

MultiSelectContracts.Option = MultiSelectContractsOption

export default withErrorBoundaryAndPolicyCheck(
  MultiSelectContracts,
  'is_enable_multi_select_contracts',
  true,
)
