import { ComboboxItem, Select } from '@mantine/core'
import { useEffect, useState } from 'react'

import styles from '../tickets.module.scss'

interface AssignProps {
  tickteId: string
  isErrorAssignTicket: boolean
  initialValue?: string
  options: { value: string; label: string }[]
  onChangeAssignee: (newValue: ComboboxItem | null, ticketId: string) => void
}

export const AssignTicket = (props: AssignProps) => {
  const {
    tickteId,
    initialValue,
    options,
    onChangeAssignee,
    isErrorAssignTicket,
  } = props
  const [value, setValue] = useState<string | null | undefined>(initialValue)
  const handleChange = async (newValue: ComboboxItem | null) => {
    setValue(newValue?.value)
    onChangeAssignee(newValue, tickteId)
  }

  useEffect(() => {
    setValue(initialValue ?? null)
  }, [isErrorAssignTicket, initialValue])
  return (
    <form>
      <Select
        classNames={{
          input: styles.customTicketsSelect,
        }}
        placeholder="Select IDG Steward"
        name={tickteId}
        data={options}
        value={value}
        comboboxProps={{ position: 'bottom', offset: 0 }}
        onChange={(_value, option) => handleChange(option)}
      />
    </form>
  )
}
