export interface WashingtonStateOptions {
  label: string
  value: string
}

export const WA_GENDER_OPTIONS: WashingtonStateOptions[] = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Prefer not to say', value: 'NA' },
  { label: 'Non Binary', value: 'NB' },
]

export const WA_CONTACT_OPTIONS: WashingtonStateOptions[] = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Phone', value: 'PHONE' },
  { label: 'Mail', value: 'MAIL' },
]

export const WA_DETAILED_VOICEMAIL_OPTIONS: WashingtonStateOptions[] = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
]

export const WA_LANGUAGE_OPTIONS: WashingtonStateOptions[] = [
  {
    label: 'Amharic',
    value: 'AMHARIC',
  },
  {
    label: 'Arabic',
    value: 'ARABIC',
  },
  {
    label: 'Cambodian/Khmer',
    value: 'CAMBODIAN/KHMER',
  },
  {
    label: 'Chinese',
    value: 'CHINESE',
  },
  {
    label: 'English',
    value: 'ENGLISH',
  },
  {
    label: 'Farsi',
    value: 'FARSI',
  },
  {
    label: 'Japanese',
    value: 'JAPANESE',
  },
  {
    label: 'Korean',
    value: 'KOREAN',
  },
  {
    label: 'Laotian',
    value: 'LAOTIAN',
  },
  {
    label: 'Marshallese',
    value: 'MARSHALLESE',
  },
  {
    label: 'Oromo',
    value: 'OROMO',
  },
  {
    label: 'Punjabi',
    value: 'PUNJABI',
  },
  {
    label: 'Russian',
    value: 'RUSSIAN',
  },
  {
    label: 'Somali',
    value: 'SOMALI',
  },
  {
    label: 'Spanish',
    value: 'SPANISH',
  },
  {
    label: 'Tagalog',
    value: 'TAGALOG',
  },
  {
    label: 'Ukrainian',
    value: 'UKRAINIAN',
  },
  {
    label: 'Vietnamese',
    value: 'VIETNAMESE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

export const WA_RACE_ETHNICITY_OPTIONS: WashingtonStateOptions[] = [
  {
    label: 'American Indian or Alaska Native',
    value: 'AAOAA',
  },
  {
    label: 'Black or African American',
    value: 'BOAA',
  },
  {
    label: 'East Asian',
    value: 'EAEAA',
  },
  {
    label: 'Hispanic or Latino/Latina',
    value: 'HOL',
  },
  {
    label: 'Middle Eastern or North African',
    value: 'MEAA',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NHOPI',
  },
  {
    label: 'South Asian',
    value: 'SASAA',
  },
  {
    label: 'Southeast Asian',
    value: 'SEASEAA',
  },
  {
    label: 'White',
    value: 'WHT',
  },
  {
    label: 'Prefer not to say',
    value: 'PNTS',
  },
  {
    label: 'Ethnicity and/or race not listed',
    value: 'OTHER',
  },
]
