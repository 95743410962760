import { Select } from 'src/common/components'

const STATUS_OPTIONS = [
  { value: 'closed', label: 'Closed' },
  { value: 'resolved', label: 'Resolved' },
]

const PastTicketsTableFilterStatus = () => {
  return (
    <Select
      name="status"
      placeholder="Status"
      options={STATUS_OPTIONS}
      withError={false}
      template="filter"
    />
  )
}

export default PastTicketsTableFilterStatus
