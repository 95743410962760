import { useEffect, useState } from 'react'

import {
  PAGINATION_LIMIT,
  WA_CONTACT_OPTIONS,
  WA_DETAILED_VOICEMAIL_OPTIONS,
  WA_GENDER_OPTIONS,
  WA_LANGUAGE_OPTIONS,
  WA_RACE_ETHNICITY_OPTIONS,
  WashingtonStateOptions,
} from 'src/common/config'
import {
  concatQueryPages,
  getStateNameByAbbreviation,
  splitCamelCase,
} from 'src/common/helpers'
import {
  WashingtonStateFields,
  WashingtonStateHistory,
} from 'src/common/interfaces/washington-state'
import dayjs from 'src/common/libs/dayjs'
import useGetWashingtonStateHistory from 'src/default/hooks/washington-state/useGetWashingtonStateHistory'

import styles from './washington-state-history-tracker.module.scss'

interface WashingtonStateHistoryTrackerProps {
  objectId: string
}

const WashingtonStateHistoryTracker = ({
  objectId,
}: WashingtonStateHistoryTrackerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { data: { pages } = { pages: [] }, isFetched } =
    useGetWashingtonStateHistory({
      objectId: objectId || '',
      limit: PAGINATION_LIMIT,
    })

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages, isLoading])

  const historyWashingtonStateRecords =
    concatQueryPages<WashingtonStateHistory>(pages)

  const parseArrayValueWithOptions = (
    originalValue: string,
    options: WashingtonStateOptions[],
  ) => {
    try {
      const parsedValue = JSON.parse(originalValue)

      if (Array.isArray(parsedValue)) {
        return parsedValue
          .map(
            (value) =>
              options.find((option) => option.value === value)?.label || '',
          )
          .filter(Boolean)
          .join(', ')
      }
    } catch (error) {
      console.error('Failed to parse originalValue:', error)
    }

    return originalValue
  }

  const findOptionLabelByValue = (
    value: string,
    options: WashingtonStateOptions[],
  ) => {
    return options.find((option) => option.value === value)?.label || value
  }

  const formatHistoryFieldValue = (
    field: WashingtonStateFields,
    value: string,
  ) => {
    switch (field) {
      case 'raceEthnicity':
        return parseArrayValueWithOptions(value, WA_RACE_ETHNICITY_OPTIONS)
      case 'preferredContactMethod':
        return findOptionLabelByValue(value, WA_CONTACT_OPTIONS)
      case 'language':
        return findOptionLabelByValue(value, WA_LANGUAGE_OPTIONS)
      case 'gender':
        return findOptionLabelByValue(value, WA_GENDER_OPTIONS)
      case 'detailedVoicemail':
        return findOptionLabelByValue(value, WA_DETAILED_VOICEMAIL_OPTIONS)
      case 'birthDate':
        return dayjs.utc(value).format('MM/DD/YYYY')
      case 'state':
        return getStateNameByAbbreviation(value)
      default:
        return value
    }
  }

  const sortHistoryByDateDescending = (
    a: WashingtonStateHistory,
    b: WashingtonStateHistory,
  ) => {
    return (
      new Date(b.dateOfChange).getTime() - new Date(a.dateOfChange).getTime()
    )
  }

  const renderHistoryItem = (history: WashingtonStateHistory) => {
    if (
      history.originalValue === history.newValue ||
      history.objectField === 'createdAt' ||
      history.objectField === 'SSN_ITIN' ||
      history.objectField === 'id'
    ) {
      return null
    }

    return (
      <div key={history.id} className={styles.historyItem}>
        <div className={styles.itemTitle}>{`${
          history?.user?.firstName ?? 'Integration'
        } ${history?.user?.lastName ?? ''}`}</div>
        <div className={styles.itemBody}>
          <div className={styles.fieldChange}>{renderFieldChange(history)}</div>
          <div className={styles.dateChange}>
            {dayjs(history.dateOfChange).format('MM/DD/YYYY h:mm A')}
          </div>
        </div>
      </div>
    )
  }

  const renderFieldChange = (history: WashingtonStateHistory) => {
    const { objectField, originalValue, newValue } = history
    const formattedNewValue = formatHistoryFieldValue(objectField, newValue)

    if (originalValue === null || originalValue === '') {
      return (
        <>
          Changed {splitCamelCase(objectField)} to {''}
          <strong>{formattedNewValue}</strong>
        </>
      )
    }

    const formattedOriginalValue = formatHistoryFieldValue(
      objectField,
      originalValue,
    )

    return (
      <>
        Changed {splitCamelCase(objectField)} from{' '}
        <strong>{formattedOriginalValue}</strong> to{' '}
        <strong>{formattedNewValue}</strong>
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>History</div>
      <div className={styles.historyContainer}>
        {!isLoading && historyWashingtonStateRecords.length ? (
          historyWashingtonStateRecords
            .sort(sortHistoryByDateDescending)
            .map(renderHistoryItem)
        ) : isFetched ? (
          <div className={styles.noHistory}>No history found</div>
        ) : null}
      </div>
    </div>
  )
}

export default WashingtonStateHistoryTracker
