import { Button, Card, Group, Stepper } from '@mantine/core'

import withErrorBoundaryAndPolicyCheck from '../../../../commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import StepOneCreateSmsCampaign from '../../components/StepOneCreateSmsCampaign/StepOneCreateSmsCampaign'
import StepThreeCreateSmsCampaign from '../../components/StepThreeCreateSmsCampaign/StepThreeCreateSmsCampaign'
import StepTwoCreateSmsCampaign from '../../components/StepTwoCreateSmsCampaign/StepTwoCreateSmsCampaign'
import SummaryCreatedSmsCampaign from '../../components/SummaryCreatedSmsCampaign/SummaryCreatedSmsCampaign'
import useCreateSmsCampaignContainer from '../../containers/useCreateSmsCampaignContainer'
import styles from './create-sms-campaign-view.module.scss'

const CreateSmsCampaignView = () => {
  const {
    currentStep,
    changePrevStep,
    changeNextStep,
    form,
    handleCreateSmsCampaign,
    goToCampaignsPage,
    goToCampaignDetail,
    campaignCreated,
    checkIfStepHasError,
  } = useCreateSmsCampaignContainer()

  return (
    <div className={styles.wrapper}>
      <Card
        shadow="lg"
        radius="lg"
        className={styles.createSmsCampaignContainer}
      >
        <Stepper active={currentStep} onStepClick={() => null}>
          <Stepper.Step label="Setup">
            <StepOneCreateSmsCampaign form={form} />
          </Stepper.Step>
          <Stepper.Step label="Targets">
            <StepTwoCreateSmsCampaign form={form} />
          </Stepper.Step>
          <Stepper.Step label="Summary">
            <StepThreeCreateSmsCampaign form={form} />
          </Stepper.Step>
          <Stepper.Completed>
            <SummaryCreatedSmsCampaign campaignCreated={campaignCreated} />
          </Stepper.Completed>
        </Stepper>

        <Group justify="center" mt="xl">
          {currentStep > 0 && currentStep !== 3 ? (
            <Button variant="default" onClick={changePrevStep}>
              Back
            </Button>
          ) : null}
          {currentStep <= 2 ? (
            <Button
              onClick={() => {
                if (currentStep === 2) {
                  form.onSubmit((values) => {
                    handleCreateSmsCampaign(values)
                  })()
                }
                if (checkIfStepHasError(currentStep)) {
                  changeNextStep()
                }
              }}
            >
              {currentStep === 2 ? 'Create Campaign' : 'Next step'}
            </Button>
          ) : (
            <>
              <Button variant="default" onClick={goToCampaignsPage}>
                Go to campaigns
              </Button>
              <Button variant="" onClick={goToCampaignDetail}>
                Go to campaign detail
              </Button>
            </>
          )}
        </Group>
      </Card>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateSmsCampaignView,
  'is_create_sms_campaign_enable',
  true,
)
