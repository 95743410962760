import { useForm } from '@mantine/form'
import { type UseFormReturnType } from '@mantine/form/lib/types'
import { OrganizationModel } from '@v2/domains/organization/models/organization.model'
import { useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PAGINATION_LIMIT } from 'src/v2/commons/config'
import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import { type SearchFiltersModel } from 'src/v2/domains/person/models/person.model'
import { useSearchResultQuery } from 'src/v2/domains/person/models/search/search.api'
import { useAppSelector } from 'src/v2/store/hooks'

export interface ILeadsUseCase {
  searchForm: UseFormReturnType<SearchFiltersModel>
  handlePaginate: () => void
  searchQueryStatus: {
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
  }
  data: PersonsModel | undefined
  organizationId: string
  searchValue: string | undefined
  searchResultsIds: string[]
  userOrganizations: OrganizationModel['organizationsByUser']
  isAssignMultipleTasksEnabled: boolean
}

const useSearchUseCase = (): ILeadsUseCase => {
  const [page, setPage] = useState(0)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const userOrganizations = useAppSelector(
    (state) => state.organization.organizationsByUser,
  )
  const { searchValue } = useParams<{ searchValue: string }>()

  const orgId = useMemo(() => {
    setPage(0)
    return (userProfile.organization.id as string) || ''
  }, [userProfile.organization.id])

  const searchForm = useForm<SearchFiltersModel>({
    initialValues: {},
    name: 'searchForm',
    onValuesChange() {
      setPage(0)
    },
  })

  const isAssignMultipleTasksEnabled = !!userProfile.policies.find(
    (policy) => policy === 'is_create_multiple_task_enabled',
  )

  const { isLoading, isSuccess, data, isFetching } = useSearchResultQuery({
    params: {
      ...(searchForm.getValues()['getUserOrganizations']
        ? { ...searchForm.getValues() }
        : {}),
      search: searchValue,
      limit: PAGINATION_LIMIT,
      organizationId: orgId,
      offset: page * PAGINATION_LIMIT,
    },
  })

  const searchResultsIds = useMemo(() => {
    if (data) {
      return data.records.map((result) => result.id)
    }
    return []
  }, [data?.records])

  const handlePaginate = () => {
    if (isFetching) return
    return setPage((prev) => {
      if (
        data &&
        data.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  return {
    searchForm,
    data,
    searchResultsIds,
    searchValue,
    handlePaginate,
    searchQueryStatus: {
      isLoading: isLoading,
      isSuccess: isSuccess,
      isFetching: isFetching,
    },
    organizationId: userProfile.organization.id || '',
    userOrganizations,
    isAssignMultipleTasksEnabled,
  }
}

export default useSearchUseCase
