import { type PersonsFiltersModel } from 'src/v2/domains/person/models/person.model'

export interface ItaskModalForm {
  type: string
  subject: string
  assignedToId: string
  activityDate?: string
  priority: string
  isPersonAssign: boolean
  note: string
  isNotify: boolean
  personId: string
}
export interface ITaskBody extends Omit<ItaskModalForm, 'personId'> {
  personIds: string[]
  excludedPersonIds?: string[]
  isAllPersonSelected?: boolean
  organizationId: string
}

export type ITaskParams = PersonsFiltersModel

export interface IMutateTasksData {
  body: ITaskBody
  params: ITaskParams
}

export const TASK_TYPE_OPTIONS = [
  { label: 'Make a Call', value: 'makeCall' },
  { label: 'Other', value: 'other' },
]

export const TASK_PRIORITY_OPTIONS = [
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
]

export const TASK_SUBJECT_OPTIONS = [
  { value: 'callDriver', label: 'Call Driver' },
  { value: 'urgentCall', label: 'Urgent Call' },
  { value: 'sendEmailToDriver', label: 'Send Email to Driver' },
  { value: 'assistance', label: 'Assistance' },
  { value: 'leaveVoiceMail', label: 'Leave Voice Mail' },
]
