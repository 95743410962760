import cx from 'classnames'
import { Fragment, useState } from 'react'

import { Checkbox, Icons, Table } from 'src/common/components'
import { formatDate, getDocumentTypeFormatted } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'
import { useNycLookupLicense } from 'src/default/hooks'
import { EnumDocumentType, IDocument } from 'src/default/interfaces'

import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../../v2/store/hooks'
import SingleAccountDocumentsActions from './SingleAccountDocumentsActions'
import styles from './single-account-documents-table.module.scss'

interface SingleAccountDocumentsTableProps {
  data: IDocument[]
  refetch: () => Promise<any>
  onSelectDocument: (id: string) => void
}

const SingleAccountDocumentsTable = (
  props: SingleAccountDocumentsTableProps,
) => {
  const { data, refetch, onSelectDocument } = props
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [documentToDelete, setDocumentToDelete] = useState('')
  const [isShowLookupRow, setIsShowLookupRow] = useState(false)
  const isTLCRequired = userProfile?.organization?.profile?.isTLCRequired

  const { data: lookupData, isSuccess } = useNycLookupLicense({
    enabled: isShowLookupRow,
    findBy: 'license_number',
    findValue:
      data.find((item) => item.type === EnumDocumentType.TLC)?.number ?? '',
  })

  const driverData = lookupData?.data ?? null

  const handleSetSelectDocumentoToDelete = (e: any, id: string) => {
    e.stopPropagation()
    setDocumentToDelete(id)
  }

  const transformDate = (date: string) => {
    if (!date) {
      return dayjs()
    }
    const [simpleDate] = date.split('T')
    return dayjs(simpleDate)
  }

  const filterData = (document: IDocument) =>
    (document.type !== EnumDocumentType.TLC || isTLCRequired) &&
    document.type !== EnumDocumentType.SOCIAL_SECURITY_NUMBER &&
    document.type !== EnumDocumentType.PASSPORT

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <Table.Th title="No." width={40} />
            <Table.Th title="Document Type" />
            <Table.Th title="Number" />
            <Table.Th title="Verified" />
            <Table.Th title="Actions" />
          </tr>
        </thead>

        <tbody>
          {data.filter(filterData).map((d: IDocument, i: number) => (
            <Fragment key={d.id}>
              <tr
                className={styles.tableRowWrapper}
                onClick={() => onSelectDocument(d.id)}
              >
                <td className={styles.tableCellWrapper}>{i + 1}</td>
                <td className={styles.tableCellWrapper}>
                  <div className={styles.documentType}>
                    {getDocumentTypeFormatted(d.type)}
                    {d.type === EnumDocumentType.TLC ? (
                      <div
                        className={styles.documentTypeInner}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <div
                          className={styles.icon}
                          onClick={() => setIsShowLookupRow(!isShowLookupRow)}
                        >
                          <Icons.CheckCircle />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </td>
                <td className={styles.tableCellWrapper}>{d.number}</td>
                <td className={styles.tableCellWrapper}>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.checkboxColumn}
                      name={`${d.id}-isVerified`}
                      value={d.isVerified}
                    />
                  </div>
                </td>
                <PolicyCheckHOC policyName="is_delete_document_enabled">
                  <td
                    className={styles.tableCellWrapper}
                    onClick={(event) =>
                      handleSetSelectDocumentoToDelete(event, d.id)
                    }
                  >
                    <div className={styles.actions}>
                      <SingleAccountDocumentsActions.Delete
                        documentId={d.id}
                        name={d.type as string}
                        refetch={refetch}
                        isOpen={documentToDelete === d.id}
                      />
                    </div>
                  </td>
                </PolicyCheckHOC>
              </tr>

              {d.type === EnumDocumentType.TLC &&
              isSuccess &&
              isShowLookupRow ? (
                <tr key={`${i}-lookup`} className={styles.tableRowWrapper}>
                  {driverData ? (
                    <>
                      <td
                        className={cx(
                          styles.tableCellWrapper,
                          styles.lookupCell,
                        )}
                        colSpan={2}
                      >
                        <div>
                          <Icons.CheckCircle />
                          <span>NYC Lookup</span>
                        </div>

                        <div>{`${driverData.name} - ${driverData.licenseNumber}`}</div>
                      </td>
                      <td>
                        <div>
                          {driverData?.isActive ? (
                            <h3 className={styles.active}>ACTIVE</h3>
                          ) : (
                            <h3 className={styles.inactive}>INACTIVE</h3>
                          )}
                        </div>
                      </td>
                      <td
                        className={cx(
                          styles.tableCellWrapper,
                          styles.lookupCell,
                        )}
                        colSpan={2}
                      >
                        <div>{`Expiration Date: ${transformDate(
                          driverData.expirationDate,
                        ).format('MMM DD, YYYY')}`}</div>

                        <div>{`Last Update: ${transformDate(
                          driverData.lastDateUpdated,
                        ).format('MMM DD, YYYY')}`}</div>
                      </td>
                    </>
                  ) : (
                    <td
                      className={cx(styles.tableCellWrapper, styles.lookupCell)}
                      colSpan={5}
                    >
                      Driver is not found in NYC Open Data
                    </td>
                  )}
                </tr>
              ) : null}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SingleAccountDocumentsTable
