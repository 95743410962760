import { useState } from 'react'

import { BasicBlock, Button, Icons } from 'src/common/components'
import { useNotifications } from 'src/common/notification'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import RequirementsViewModal from '../RequirementsViewModal/RequirementsViewModal'
import styles from './abc-administration-item.module.scss'

interface ABCAdministrationItemProps {
  id: string
  name: string
  isDefault: boolean
  organization: any
  expiresIn: number
  benefits: any[]
  requirements: any
}

const ABCAdministrationItem = (props: ABCAdministrationItemProps) => {
  const { id, name, isDefault, expiresIn, benefits, requirements } = props
  const notifications = useNotifications()

  const [isShowDetails, setIsShowDetails] = useState<boolean>(false)

  const handleCopyAddress = (id: string) => {
    navigator.clipboard.writeText(id)
    notifications?.show('success', 'Copied to the clipboard', {
      id: 'IsCopyToTheClipboard',
    })
  }

  return (
    <BasicBlock className={styles.wrapper}>
      <div className={styles.header}>
        <p>ID: {id}</p>
        <div
          onClick={() => {
            handleCopyAddress(id)
          }}
          className={styles.copyAddress}
        >
          <Icons.Copy />
        </div>
      </div>

      <p className={styles.inner}>
        {name} <strong>{isDefault ? ' - [Default]' : ''}</strong>
      </p>

      <div className={styles.info}>
        <strong>Benefits:</strong>
        {benefits?.map((b) => (
          <> {b.name},</>
        ))}
      </div>
      <div className={styles.info}>
        <strong>Requirements:</strong>
        <PolicyCheckHOC
          policyName="is_enable_associated_benefit_contracts_detail"
          disablePolicyCheck
        >
          <Button
            disabled={false}
            size={'micro'}
            onClick={() => {
              setIsShowDetails(true)
            }}
          >
            Details
          </Button>
        </PolicyCheckHOC>
        {requirements?.map((b: any) => {
          return <> {b.event.params.message},</>
        })}
      </div>

      <div className={styles.info}>
        <strong>Other:</strong>
        expiresIn:
        {expiresIn} days
      </div>

      {isShowDetails ? (
        <RequirementsViewModal
          isNew={true}
          initialValues={requirements}
          onClose={() => {
            setIsShowDetails(false)
          }}
        />
      ) : null}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ABCAdministrationItem,
  'is_enable_associated_benefit_contract_administration-item',
  true,
)
