import { Table, TableLeadStatus } from 'src/common/components'
import { ITask } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './tasks-table.module.scss'

interface TasksTableProps {
  tasks: ITask[]
  setSingleTask: (item: ITask) => void
}

const TasksTable = (props: TasksTableProps) => {
  const { tasks, setSingleTask } = props

  const getType = (type: string) => {
    let newType = ''
    switch (type) {
      case 'makeCall':
        newType = 'Make a Call'
        break
      case 'other':
        newType = 'Other'
        break

      default:
        break
    }
    return newType
  }

  const getSubject = (subject: string) => {
    let newSubject = ''
    switch (subject) {
      case 'callDriver':
        newSubject = 'Call Driver'
        break
      case 'urgentCall':
        newSubject = 'Urgent Call'
        break
      case 'sendEmailToDriver':
        newSubject = 'Send Email To Driver'
        break
      case 'assistance':
        newSubject = 'Assistance'
        break
      case 'leaveVoiceMail':
        newSubject = 'Leave Voice Mail'
        break
      default:
        break
    }
    return newSubject
  }

  const getTaskPriorityColor = (priority: string) => {
    let color = ''
    switch (priority) {
      case 'high':
        color = 'red'
        break
      case 'medium':
        color = 'orange'
        break
      case 'low':
        color = 'green'
        break
      default:
        break
    }
    return color
  }

  return (
    <Table className={styles.wrapper}>
      {tasks.map((task: ITask) => (
        <Table.Row
          key={task.id}
          editable
          id={task.id}
          onClick={() => setSingleTask(task)}
        >
          <Table.Column className={styles.name} title="Name" showMobile>
            {`${task?.person?.firstName ?? ''} ${task?.person?.lastName ?? ''}`}
          </Table.Column>

          <Table.Column title="Type" showMobile>
            {getType(task?.type)}
          </Table.Column>

          <Table.Column title="Subject" showMobile>
            {getSubject(task?.subject)}
          </Table.Column>

          <Table.Column title="Assigned To">
            {`${task?.assignedTo?.firstName ?? ''} ${
              task?.assignedTo?.lastName.slice(0, 1) ?? ''
            }.`}
          </Table.Column>

          <Table.Column className={styles.capitalize} title="Status">
            {task?.status ?? ''}
          </Table.Column>

          <Table.Column title="Priority">
            <TableLeadStatus
              status={task?.priority}
              color={getTaskPriorityColor(task.priority)}
            />
          </Table.Column>

          <Table.Column title="Due Date">
            {dayjs.tz(task?.activityDate).isValid()
              ? dayjs.tz(task?.activityDate, 'UTC').format('MM/DD/YYYY')
              : ''}
          </Table.Column>
        </Table.Row>
      ))}
    </Table>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TasksTable,
  'is_get_tasks_enabled',
)
