export enum CommunicationTypeEnum {
  CALL_INBOUND = 'CALL_INBOUND',
  CALL_OUTBOUND = 'CALL_OUTBOUND',
  EMAIL_INBOUND = 'EMAIL_INBOUND',
  EMAIL_OUTBOUND = 'EMAIL_OUTBOUND',
  SMS_INBOUND = 'SMS_INBOUND',
  SMS_OUTBOUND = 'SMS_OUTBOUND',
  CHAT = 'CHAT',
  TICKET = 'TICKET',
}
