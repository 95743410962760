export const PUBLIC_ROUTES = {
  LOGIN: {
    path: '/login',
  },
  FORGOT: {
    path: '/forgot',
  },
  RESET: {
    path: '/reset-password',
  },
}

export const PRIVATE_ROUTES = {
  SEARCH: {
    path: '/search',
  },
}
