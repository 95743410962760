import axios from 'axios'

import { API_BASE_URL } from 'src/common/config'

import { version } from '../../../package.json'
import { resetAuthStore } from '../../v2/domains/auth/models/auth.store'
import { resetUserProfileStore } from '../../v2/domains/user/models/user.store'
import { checkRoute } from '../../v2/store/actions/common.actions'
import { store } from '../../v2/store/store'

const ignoreUrls = ['/v3/auth/login']

const api = axios.create({
  baseURL: API_BASE_URL,
})

api.interceptors.response.use(
  async (res) => {
    return res
  },
  async (error) => {
    if (error.response?.status === 401) {
      store.dispatch(resetAuthStore())
      store.dispatch(resetUserProfileStore())
      store.dispatch(checkRoute())
    }
    return Promise.reject(error)
  },
)

api.interceptors.request.use(async (config) => {
  const session = store.getState().auth
  if (ignoreUrls.includes(config.url ?? '')) {
    return config
  }

  if (config.headers) {
    config.headers.Authorization = `Bearer ${session?.accessToken}`
    config.headers.product = `W360`
    config.headers['app-platform'] = `web`
    config.headers['app-version'] = version
    config.headers['app-env'] = 'TBD'
  }
  return config
})

export default api
