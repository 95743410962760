import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { BasicBlock, Breadcrumbs } from 'src/common/components'
import { yupPhoneValidator } from 'src/common/helpers'
import { IUserMutationData } from 'src/common/interfaces'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import { useMutateUser, useUpdateUserHipaaStatus } from 'src/default/hooks'
import { UserForm } from 'src/default/pages/Users/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'

const CreateUserPage = () => {
  const router = useRouter()

  const session = useAppSelector((state) => state.auth)

  const [result, setResult] = useState<string | null>(null)

  const userMutation = useMutateUser('create', session.accessToken)
  const hipaaMutation = useUpdateUserHipaaStatus()

  const formik = useFormik<IUserMutationData>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      aircallUserId: '',
    },
    onSubmit: async (values) => {
      const newUserValues = {
        ...values,
        phone: values.phone ? values.phone.replaceAll('+', '') : undefined,
      }

      delete newUserValues.hipaaCertified

      await userMutation
        .mutateAsync(newUserValues)
        .then(async ({ data }) => {
          return hipaaMutation.mutateAsync({
            userId: data.id,
            certified: Boolean(values.hipaaCertified),
          })
        })
        .then(() => {
          setResult('success')
        })
        .catch((error) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required(),
      lastName: yup.string().name().required(),
      email: yup.string().email().required(),
      //  associatedBenefitContract: yup.array().required().min(1),
      password: yup.string().required().min(8, 'At least 8 characters'),
      phone: yupPhoneValidator(),
      aircallUserId: yup.string(),
    }),
  })

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.USERS.path)
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Create New User' }]} />

      <BasicTemplate title="Create New User">
        <BasicBlock>
          <PolicyCheckHOC policyName="is_create_user_enabled">
            <UserForm formik={formik} isCreating />
          </PolicyCheckHOC>
        </BasicBlock>
      </BasicTemplate>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={result !== 'success' ? result : undefined}
          onClose={handleCloseResult}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateUserPage,
  'is_enable_create_user_view',
  true,
)
