import { formatErrorMessage } from 'src/common/helpers'

export const basicApiErrorHandling = (error: any) => {
  console.log('ERROR:~~~~~~~~~~~~~~~', error)

  if (typeof error === 'string') {
    return formatErrorMessage(error)
  }

  const errorData = error?.response?.data

  if (errorData) {
    if (Array.isArray(errorData)) {
      return errorData
        .map((ed) => formatErrorMessage(`${ed.errorCode}: ${ed.message}`))
        .join(', ')
    } else {
      if (errorData.message) {
        if (errorData.error) {
          return formatErrorMessage(`${errorData.error}: ${errorData.message}`)
        }

        return formatErrorMessage(errorData.message)
      }
    }
  }

  if (error?.message) {
    return formatErrorMessage(error.message)
  }

  return 'Error'
}
