import cx from 'classnames'

import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './convert-lead-modal.module.scss'

interface ConvertLeadModalProps extends IModal {
  isLoading?: boolean
  onSubmit: () => void
}

const ConvertLeadModal = (props: ConvertLeadModalProps) => {
  const { isLoading, onSubmit, onClose } = props

  return (
    <Modal id="convertLead" template="small" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.Refresh />
        </div>

        <div className={styles.title}>Convert Lead</div>

        <div className={styles.subtitle}>
          Are you sure about to convert this lead?
        </div>

        <div className={cx(styles.buttons)}>
          <Button loading={isLoading} size="small" onClick={onSubmit}>
            Convert Lead
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ConvertLeadModal,
  'is_verify_enrollment_enabled',
)
