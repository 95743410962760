import logo from './assets/ticket-icon.png'
import styles from './empty-tickets.module.scss'

const EmptyTickets = () => {
  return (
    <div className={styles.messageContainer}>
      <img src={logo} alt="ticket-icon" />
      <p className={styles.message}>We can't find tickets at this moment</p>
    </div>
  )
}

export default EmptyTickets
