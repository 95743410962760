import type { FormikContextType } from 'formik'
import { useState } from 'react'

import { Button } from 'src/common/components'

import TableFilterButton from './TableFilterButton'
import TableFilterForm from './TableFilterForm'
import styles from './table-filter.module.scss'

interface TableFilterProps {
  providerValue: FormikContextType<any>
  total: number
  onSave?: () => void
  showSaveCSV?: boolean
}

const TableFilter = (props: React.PropsWithChildren<TableFilterProps>) => {
  const { providerValue, total, children, showSaveCSV = true } = props

  const [isOpen, setIsOpen] = useState<boolean>(
    JSON.stringify(providerValue.values) !==
      JSON.stringify(providerValue.initialValues),
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.filterTab}>
          <TableFilterButton
            isOpen={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
          />
          {showSaveCSV ? (
            <Button
              icon="UploadDocument"
              onClick={() => {
                props.onSave?.call(this)
              }}
              className={styles.button}
              size="small"
            >
              {`Save CSV`}
            </Button>
          ) : null}
        </div>
        {total ? (
          <div className={styles.total}>{`Total records: ${total}`}</div>
        ) : null}
      </div>

      {isOpen ? (
        <TableFilterForm providerValue={providerValue}>
          {children}
        </TableFilterForm>
      ) : null}
    </div>
  )
}

export default TableFilter
