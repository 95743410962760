import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IUserHipaaStatusMutationData {
  userId: string
  certified: boolean
}

const useUpdateUserHipaaStatus = () =>
  useMutation({
    mutationFn: async (data: IUserHipaaStatusMutationData) => {
      return api.post('/users/hipaa-certified', data)
    },
  })

export default useUpdateUserHipaaStatus
