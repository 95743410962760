import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetMembershipEmailDomain {
  membershipId: any
}

const useGetMembershipEmailDomains = (params: GetMembershipEmailDomain) => {
  return useQuery({
    queryKey: [`getMembershipEmailDomains`],
    queryFn: async () =>
      await api.get(`v2/membership-domain/membership/${params?.membershipId}`),
  })
}

export default useGetMembershipEmailDomains
