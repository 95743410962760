import type { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import {
  Button,
  Form,
  InputPassword,
  Loader,
  SubmitButton,
} from 'src/common/components'
import { useMagicLinkLogin } from 'src/common/hooks/auth'

//TODO move it to common slice
import useMutateUserPassword from '../../../../default/hooks/user/useMutateUserPassword'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './reset-page.module.scss'

const ResetPageWrapper = () => {
  const [sessionData, setSessionData] = useState<{
    access_token: string
    userId: string
  } | null>(null)

  const [searchParams] = useSearchParams()

  const magicLinkLoginMutation = useMagicLinkLogin()

  const magicLinkToken = searchParams.get('token')

  useEffect(() => {
    if (magicLinkToken) {
      ;(async () => {
        await magicLinkLoginMutation
          .mutateAsync({ magicLinkToken })
          .then((result: AxiosResponse) => {
            if (result.data.access_token && result.data.userId) {
              setSessionData({ ...result.data })
            }
          })
      })()
    }
  }, [magicLinkToken])

  return (
    <div className={styles.wrapper}>
      {!sessionData ? <Loader isLoading /> : <ResetPage {...sessionData} />}
    </div>
  )
}

interface ResetPageProps {
  access_token: string
  userId: string
}

const ResetPage = (props: ResetPageProps) => {
  const { access_token, userId } = props

  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)

  const [searchParams] = useSearchParams()
  const source = searchParams.get('source')

  const userMutation = useMutateUserPassword('update', access_token)

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      userMutation
        .mutateAsync({
          id: userId,
          password: values.password,
        })
        .then((res) => {
          if (res?.data) {
            setDisplaySuccessMessage(true)
          }
        })
        .catch((error) => {
          setFieldError('password', error.response?.data?.message ?? 'Error')
        })
    },

    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required('Required')
        .min(8, 'At least 8 characters'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords don't match")
        .required('Confirm Password is required'),
    }),
  })

  return (
    <>
      {displaySuccessMessage ? (
        <>
          <div className={styles.iconContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={styles.successIcon}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className={styles.successInfo}>
            <h2>Your password has been successfully changed!</h2>
            {source !== 'union-360-mobile' ? (
              <Button to="/login">Login</Button>
            ) : null}
          </div>
        </>
      ) : (
        <Form providerValue={formik}>
          <div className={styles.title}>Create new password</div>

          <div className={styles.description}>
            Please check your mail for instructions to reset your password
          </div>
          <InputPassword
            name="password"
            size="big"
            label="New Password"
            placeholder="Enter Password"
          />

          <InputPassword
            name="passwordConfirmation"
            size="big"
            label="Confirm New Password"
            placeholder="Enter Password"
          />

          <SubmitButton>Change</SubmitButton>
        </Form>
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ResetPageWrapper,
  'is_enable_reset_view',
  true,
)
