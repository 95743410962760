export const filterUndefinedValues = <T extends Record<string, any>>(
  obj: T
) => {
  const result: Partial<T> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      result[key as keyof T] = value as T[keyof T];
    }
  }
  return result as { [K in keyof T]: T[K] };
};
