import cx from 'classnames'
import { useParams } from 'react-router-dom'

import { Loader } from 'src/common/components'
import { useGetAttachment } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './attachment-page.module.scss'

const AttachmentPage = () => {
  const { attachmentId, attachmentType } = useParams()

  const result = useGetAttachment({ attachmentId, attachmentType })

  const { url, contentType } = result?.data ?? {}

  const isPdf = contentType === 'application/pdf'

  return (
    <div className={cx(styles.wrapper, isPdf && styles.pdf)}>
      {result.isLoading ? (
        <Loader isLoading fullScreen />
      ) : (
        <>
          {url ? (
            <>{isPdf ? <iframe src={url} /> : <img src={url} />}</>
          ) : (
            <div>Attachment not found</div>
          )}
        </>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AttachmentPage,
  'is_enable_attachment_view',
  true,
)
