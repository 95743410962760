import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TicketModel } from './ticket.model'

const initialState: Partial<TicketModel> = {
  id: '',
  showTicketBlockInformation: false,
}

const ticketStore = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setTicketId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setShowTicketBlockInformation: (state, action: PayloadAction<boolean>) => {
      state.showTicketBlockInformation = action.payload
    },
  },
})

export const { setTicketId, setShowTicketBlockInformation } =
  ticketStore.actions

export default ticketStore.reducer
