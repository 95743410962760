import { useState } from 'react'

import { useGetEligibilityGuidelines } from 'src/benestream/hooks'
import { IEligibilityGuideline } from 'src/benestream/interfaces'
import { BasicBlock, Button, Table } from 'src/common/components'
import { STATES } from 'src/common/config'

import EligibilityEditorModal from './EligibilityEditorModal'
import styles from './eligibility-editor.module.scss'

const EligibilityEditor = () => {
  const [showNewModal, setShowNewModal] = useState<boolean>(false)
  const [currentEligibilityId, setCurrentEligibilityId] = useState<
    string | null
  >(null)

  const eligibilitiesData = useGetEligibilityGuidelines()
  const eligibilities = eligibilitiesData?.data?.data ?? []

  const handleClose = () => {
    setShowNewModal(false)
    setCurrentEligibilityId(null)
  }

  const showNewButton = Object.values(STATES).some(
    (state) =>
      !eligibilities.find(
        (eligibility: IEligibilityGuideline) =>
          eligibility.stateAbbreviation === state.value,
      ),
  )

  const currentEligibility = eligibilities.find(
    (eligibility: IEligibilityGuideline) =>
      eligibility.id === currentEligibilityId,
  )

  const availableStates = Object.values(STATES)
    .filter(
      (state) =>
        !eligibilities.find(
          (eligibility: IEligibilityGuideline) =>
            eligibility.stateName === state.value,
        ),
    )
    .map((state) => state.value)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.title}>Eligibility Guidelines</h2>

          {showNewButton ? (
            <Button
              className={styles.newButton}
              icon="Plus"
              size="small"
              onClick={() => setShowNewModal(true)}
            >
              Add New Guideline
            </Button>
          ) : null}
        </div>

        <BasicBlock className={styles.inner}>
          <Table>
            {eligibilities
              .sort((a: IEligibilityGuideline, b: IEligibilityGuideline) =>
                a.stateName.localeCompare(b.stateName),
              )
              .map((eligibility: IEligibilityGuideline) => (
                <Table.Row
                  editable
                  key={eligibility.id}
                  onClick={() =>
                    setCurrentEligibilityId(eligibility?.id ?? null)
                  }
                >
                  <Table.Column title="State" showMobile>
                    {eligibility.stateName}
                  </Table.Column>

                  <Table.Column title="Other Adult Individual" showMobile>
                    {eligibility.otherAdultIndividual}
                  </Table.Column>

                  <Table.Column title="Parent Family Of 3" showMobile>
                    {eligibility.parentFamilyOf3}
                  </Table.Column>

                  <Table.Column title="Base Poverty Guideline" showMobile>
                    {eligibility.basePovertyGuideline}
                  </Table.Column>

                  <Table.Column
                    title="Poverty Guideline Per Additional Person"
                    showMobile
                  >
                    {eligibility.povertyGuidelinePerAdditionalPerson}
                  </Table.Column>
                </Table.Row>
              ))}
          </Table>
        </BasicBlock>
      </div>

      {showNewModal || currentEligibility ? (
        <EligibilityEditorModal
          isNew={showNewModal}
          initialValues={!showNewModal ? currentEligibility : undefined}
          availableStates={availableStates}
          refetch={eligibilitiesData.refetch}
          onClose={handleClose}
        />
      ) : null}
    </>
  )
}

export default EligibilityEditor
