import cx from 'classnames'
import { Dayjs } from 'dayjs'
import { Fragment } from 'react'

import styles from './date-select.module.scss'

interface DateSelectProps {
  futureDate?: boolean
  pastDate?: boolean
  value: Dayjs
  date: Dayjs
  onChange: (value: Dayjs) => void
}

const DateSelect = (props: DateSelectProps) => {
  const { value, date, onChange, futureDate = false, pastDate = true } = props

  const handleChange = (disabled: boolean, value: Dayjs) => {
    if (!disabled) {
      onChange(value)
    }
  }

  const dates = (() => {
    const past = pastDate
    const future = futureDate

    const firstDay = date.startOf('month').day()
    const lastDay = date.endOf('month').day()
    const prevMonth = date.subtract(1, 'month')
    const currentDate = new Date()

    const prependArray = Array.from(new Array(firstDay), (el, index) => {
      const day = prevMonth.daysInMonth() - firstDay + index + 1
      return {
        label: day,
        value: prevMonth.clone().set('date', day),
        disabled: true,
      }
    })

    const appendArray = Array.from(new Array(6 - lastDay), (el, index) => {
      const day = index + 1
      return {
        label: day,
        value: prevMonth.clone().set('date', day),
        disabled: true,
      }
    })

    const currentArray = Array.from(
      new Array(date.daysInMonth()),
      (el, index) => {
        const day = index + 1
        const diffValue = date.clone().set('date', day)
        return {
          label: day,
          value: diffValue,
          disabled:
            (!future && diffValue.diff(currentDate, 'day', true) > 0) ||
            (!past && diffValue.diff(currentDate, 'day') < 0),
        }
      },
    )

    return [...prependArray, ...currentArray, ...appendArray]
  })()

  return (
    <div className={styles.wrapper}>
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
        <div key={i} className={styles.day}>
          {day}
        </div>
      ))}

      {dates.map((d, i) => (
        <Fragment key={i}>
          <div
            className={cx(
              styles.date,
              d.disabled && styles.disabled,
              value && d.value.isSame(value, 'day') && styles.active,
            )}
            onClick={() => handleChange(d.disabled, d.value)}
          >
            {d.label}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default DateSelect
