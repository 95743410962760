import { IEnrollment } from '@v2/domains/person/models/enrollment.model'

export const getABCName = (enrollment: IEnrollment) => {
  const { associatedBenefitContract } = enrollment

  return associatedBenefitContract.name || ''
}

export const sortEnrollments = (enrollments: IEnrollment[]) => {
  return [...enrollments].sort((a, b) =>
    getABCName(a) > getABCName(b) ? 1 : -1,
  )
}

export const getOrganizationName = (enrollment: IEnrollment) => {
  const { associatedBenefitContract } = enrollment

  return associatedBenefitContract.organization.name || ''
}
