import { Select } from 'src/common/components'
import { STATES } from 'src/common/config'
import { IField } from 'src/common/interfaces'

const options = Object.values(STATES).map(({ value }) => ({
  label: value,
  value,
}))

const SelectState = (props: IField) => {
  return <Select {...props} options={options} />
}

export default SelectState
