import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { setOrganizationLogo } from 'src/v2/domains/ui/models/ui.store'

import { AuthModel } from '../../../v2/domains/auth/models/auth.model'
import { setAuthInfo } from '../../../v2/domains/auth/models/auth.store'
import { setUserProfile } from '../../../v2/domains/user/models/user.store'
import { useAppDispatch, useAppSelector } from '../../../v2/store/hooks'
import { AppDispatch } from '../../../v2/store/store'

interface IChangeOrganizationData {
  organizationId: string
  membershipId: string
}

const changeOrganization = async (
  data: IChangeOrganizationData,
  dispatch: AppDispatch,
  session: AuthModel,
) => {
  let newAccessToken = ''
  let organizationName = ''

  const refreshResponse = await api
    .post('/v3/auth/refresh', {
      refreshToken: session.refreshToken,
      organizationId: data.organizationId,
      membershipId: data.membershipId,
    })
    .then(async (result) => {
      if (result.data?.access_token) {
        newAccessToken = result.data.access_token
        dispatch(
          setAuthInfo({
            accessToken: result.data.access_token,
            refreshToken: result.data.refresh_token,
            userId: result.data.userId,
          }),
        )
      }
      return result
    })
    .catch((error) => {
      return { ...error?.response?.data }
    })

  const authProfileResponse = await api
    .get('/v3/auth/profile', {
      headers: { Authorization: `Bearer ${newAccessToken}` },
    })
    .then(async (result) => {
      dispatch(setOrganizationLogo(result.data.organization.circularLogoURL))
      organizationName = result.data.organization.name
      dispatch(setUserProfile(result.data))
      return result
    })
    .catch((error) => {
      return { ...error?.response?.data }
    })

  if (refreshResponse?.statusCode === 403) {
    return {
      data: {
        success: false,
        ...refreshResponse,
      },
    }
  }

  if (authProfileResponse?.statusCode === 401) {
    return {
      data: {
        success: false,
        ...authProfileResponse,
      },
    }
  }

  return {
    data: {
      success: true,
      organizationName,
    },
  }
}

const useChangeOrganization = () => {
  const dispatch = useAppDispatch()
  const session = useAppSelector((state) => state.auth)

  return useMutation({
    mutationFn: async (data: IChangeOrganizationData) =>
      changeOrganization(data, dispatch, session),
  })
}

export default useChangeOrganization
