import { useParams } from 'react-router-dom'

import { ITicket } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useRouter } from '../../../../../helpers/navigation'

interface TicketCustomerLinkProps {
  className?: string
  ticket: ITicket
}

const TicketCustomerLink = (
  props: React.PropsWithChildren<TicketCustomerLinkProps>,
) => {
  const router = useRouter()

  const { className, ticket, children } = props

  const id = ticket.personId || ''
  const ticketId = ticket.id

  const path = `${
    ticket.isVerifiedPerson ? 'accounts' : 'leads'
  }/${id}/${ticketId}/`

  return (
    <a onClick={() => router.push(path)} className={className}>
      {children}
    </a>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TicketCustomerLink,
  'is_enable_ticket_customer_link',
  true,
)
