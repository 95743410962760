import { Input } from '@mantine/core'

import { Icons } from 'src/common/components'

export const PoliciesFilter = (props: {
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const { onSearchChange } = props
  return (
    <Input
      style={{ width: '100%' }}
      placeholder="Search Policy..."
      leftSection={<Icons.Search />}
      onChange={onSearchChange}
    />
  )
}
