import { Anchor, Container, Flex, Stack, Text, Title } from '@mantine/core'

import { default as LetterIcon } from 'src/assets/letter.svg'

interface ForgotEmailSentViewProps {
  onTryAnother: () => void
}

const ForgotEmailSentView = (props: ForgotEmailSentViewProps) => {
  const { onTryAnother } = props

  return (
    <Stack align="center">
      <LetterIcon />

      <Title order={2} my={16}>
        Email has been sent!
      </Title>

      <Text ta="center">
        Please check your email for instructions to reset your password
      </Text>

      <Text ta="center">
        Did not receive the email? Check your spam folder or <br />
        <Anchor onClick={onTryAnother} fw={700}>
          try another email address
        </Anchor>
      </Text>
    </Stack>
  )
}

export default ForgotEmailSentView
