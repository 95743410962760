import cx from 'classnames'

import { CardButton } from '../../../ui'
import { ActionType } from '../dataLoaderWizard.interface'
import styles from './operation-step.module.scss'

interface OperationStep {
  actionType: ActionType
  changeActionType: (type: ActionType) => void
}

const OperationStep = (props: OperationStep) => {
  const { changeActionType, actionType } = props
  return (
    <div className={cx(styles.cardContent)}>
      <CardButton
        icon="Upload"
        label="Insert"
        onClick={(value) => changeActionType(value as ActionType)}
        isActive={actionType === 'insert'}
        id="insert"
      />
      <CardButton
        icon="UpdateClock"
        label="Update"
        onClick={(value) => changeActionType(value as ActionType)}
        isActive={actionType === 'update'}
        id="update"
      />
    </div>
  )
}

export default OperationStep
