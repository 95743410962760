import { LOCAL_STORAGE_LOGO, LOCAL_STORAGE_NAME } from 'src/default/config'

//TODO mb remove it

class LocalStorage {
  private readonly key: string

  constructor(keyword: string) {
    this.key = keyword
  }

  get state() {
    return localStorage.getItem(this.key) || '{}'
  }

  get token() {
    return this.getData('token')
  }

  set token(token: string) {
    this.setData('token', token)
  }

  get refreshToken() {
    return this.getData('refreshToken')
  }

  set refreshToken(refreshToken: string) {
    this.setData('refreshToken', refreshToken)
  }

  getData(key: string) {
    return this.state ? JSON.parse(this.state)?.[key] ?? undefined : undefined
  }

  setData(key: string, data: any) {
    localStorage.setItem(
      this.key,
      JSON.stringify({ ...JSON.parse(this.state), [key]: data }),
    )
  }

  clearData(key: string) {
    const state = JSON.parse(this.state)
    if (state.hasOwnProperty(key)) {
      delete state[key]
      localStorage.setItem(this.key, JSON.stringify(state))
    }
  }

  clear = () => localStorage.removeItem(this.key)
}

const appLocalStorage = new LocalStorage(LOCAL_STORAGE_NAME)
const appLocalLogo = new LocalStorage(LOCAL_STORAGE_LOGO)

export { appLocalStorage, appLocalLogo }
