import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutationPersonDataloader = () =>
  useMutation({
    mutationFn: async (data: {
      fileKey: string
      type: 'update' | 'create'
    }) => {
      const { fileKey, type } = data

      const response = await api.post(
        `/persons/dataloader/${type}-from-s3-csv`,
        {
          fileKey,
        },
      )

      return response.data
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useMutationPersonDataloader
