import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react'

import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import { type SearchFiltersModel } from 'src/v2/domains/person/models/person.model'
import { rootApi } from 'src/v2/store/api/rootApi'

export const searchApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    searchResult: builder.query<PersonsModel, { params: SearchFiltersModel }>({
      query: (queryArgs) => ({
        url: '/v7/persons',
        method: 'GET',
        params: queryArgs.params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...rest } = queryArgs.params

        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: rest,
        })
      },
      merge: (currentCache, newItems) => {
        return {
          ...newItems,
          records: [...(currentCache?.records || []), ...newItems.records],
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.params.offset &&
          currentArg?.params.offset !== previousArg?.params.offset
        )
      },
    }),
  }),
})

export const { useSearchResultQuery } = searchApi
