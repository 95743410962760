import cx from 'classnames'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { Fragment, useEffect, useState } from 'react'

import { formatDate } from 'src/common/helpers'

import styles from './date-select.module.scss'

dayjs.extend(isBetween)

interface DateSelectProps {
  futureDate?: boolean
  value: string
  date: Dayjs
  onChange: (value: string) => void
}

const DateSelect = (props: DateSelectProps) => {
  const { value, date, onChange, futureDate = false } = props

  const [startDate, setStartDate] = useState<Dayjs | null>()
  const [endDate, setEndDate] = useState<Dayjs | null>()
  const [calendarDates, setCalendarDates] = useState<any[]>([])

  const handleChange = (disabled: boolean, value: Dayjs, i: number) => {
    if (!disabled) {
      if (!startDate) {
        setStartDate(value)
        calendarDates[i].selected = true
        onChange(formatDate(value, 'MM/DD/YYYY'))
      } else if (!endDate) {
        if (value >= startDate) {
          setEndDate(value)
          calendarDates[i].selected = true
          onChange(
            `${formatDate(startDate, 'MM/DD/YYYY')} - ${formatDate(
              value,
              'MM/DD/YYYY',
            )}`,
          )
          addDatesInRange(i)
        } else {
          resetPreviousDateSelected()
          setStartDate(value)
          setEndDate(null)
          calendarDates[i].selected = true
          onChange(formatDate(value, 'MM/DD/YYYY'))
        }
      } else {
        resetStylesDates()
        setStartDate(value)
        setEndDate(null)
        calendarDates[i].selected = true
        onChange(formatDate(value, 'MM/DD/YYYY'))
      }
    }
  }

  const resetStylesDates = () => {
    const indexStartDate = calendarDates.indexOf(
      calendarDates.find((el) => el.selected),
    )
    if (indexStartDate !== -1) {
      calendarDates[indexStartDate].selected = false
    }
    const indexEndDate = calendarDates?.lastIndexOf(
      calendarDates.find((el) => el?.selected),
    )
    if (indexEndDate !== -1) {
      calendarDates[indexEndDate].selected = false
      for (let i = indexStartDate + 1; i <= indexEndDate - 1; i++) {
        calendarDates[i].inRange = false
      }
    }
  }

  const resetPreviousDateSelected = () => {
    const indexPreviousSelected = calendarDates.indexOf(
      calendarDates.find((el) => el.selected),
    )
    if (indexPreviousSelected !== -1) {
      calendarDates[indexPreviousSelected].selected = false
    }
  }

  const addDatesInRange = (indexEndDate: number) => {
    const indexStartDate = calendarDates.indexOf(
      calendarDates.find((el) => el.selected),
    )
    for (let i = indexStartDate + 1; i <= indexEndDate - 1; i++) {
      calendarDates[i].inRange = true
    }
  }

  useEffect(() => {
    setCalendarDates(dates())
  }, [date])

  const dates = () => {
    let initStartDate: dayjs.Dayjs | null = null
    let initEndDate: dayjs.Dayjs | null = null
    if (value.includes(' - ')) {
      const [startDate, endDate] = value.split(' - ')
      initStartDate = dayjs(startDate)
      initEndDate = dayjs(endDate)
    }
    const past = true
    const future = futureDate

    const firstDay = date.startOf('month').day()
    const lastDay = date.endOf('month').day()
    const prevMonth = date.subtract(1, 'month')
    const currentDate = new Date()

    const prependArray = Array.from(new Array(firstDay), (el, index) => {
      const day = prevMonth.daysInMonth() - firstDay + index + 1
      return {
        label: day,
        value: prevMonth.clone().set('date', day),
        disabled: true,
        selected: false,
        inRange: false,
      }
    })

    const appendArray = Array.from(new Array(6 - lastDay), (el, index) => {
      const day = index + 1
      return {
        label: day,
        value: prevMonth.clone().set('date', day),
        disabled: true,
        selected: false,
        inRange: false,
      }
    })

    const currentArray = Array.from(
      new Array(date.daysInMonth()),
      (el, index) => {
        const day = index + 1
        const diffValue = date.clone().set('date', day)
        return {
          label: day,
          value: diffValue,
          disabled:
            (!future && diffValue.diff(currentDate, 'day', true) > 0) ||
            (!past && diffValue.diff(currentDate, 'day') < 0),
          selected:
            diffValue.isSame(initStartDate, 'day') ||
            diffValue.isSame(initEndDate, 'day'),
          inRange: diffValue.isBetween(initStartDate, initEndDate, 'day'),
        }
      },
    )

    return [...prependArray, ...currentArray, ...appendArray]
  }

  return (
    <div className={styles.wrapper}>
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
        <div key={i} className={styles.day}>
          {day}
        </div>
      ))}

      {calendarDates?.map((day, i) => (
        <Fragment key={i}>
          <div
            className={cx(
              styles.date,
              day.disabled && styles.disabled,
              day.selected && styles.active,
              day.inRange && styles.inRange,
            )}
            onClick={() => handleChange(day.disabled, day.value, i)}
          >
            {day.label}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default DateSelect
