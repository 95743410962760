import { useState } from 'react'

const useCreateSmsCampaignUseCase = () => {
  const [active, setActive] = useState(0)
  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current))
  }
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current))

  return {
    currentStep: active,
    changeNextStep: nextStep,
    changePrevStep: prevStep,
    changeCurrentStep: setActive,
  }
}

export default useCreateSmsCampaignUseCase
