import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { BasicInput, FieldWrapper, Icons } from 'src/common/components'
import { isNumeric } from 'src/common/helpers'
import { IField } from 'src/common/interfaces'

import Calendar from './Calendar'
import styles from './input-date-range.module.scss'

const getDateWithMask = (str: string) => {
  return str
    .split('')
    .reduce((a, c, i, arr) => {
      if (i == 5 && arr[5] !== '/') a.push('/')
      a.push(c)
      if (i == 1 && arr[2] !== '/') a.push('/')
      return a
    }, [] as string[])
    .join('')
}

interface InputDateProps extends IField {
  hideCalendar?: boolean
  futureDate?: boolean
  pastDate?: boolean
}

const InputDateRange = (props: InputDateProps) => {
  const {
    className,
    inputClassName,
    name,
    value: strictValue,
    hideCalendar,
    futureDate = false,
    pastDate = true,
  } = props

  const [innerValue, setInnerValue] = useState<string>('')

  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setShowCalendar(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setShowCalendar(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const [field, meta, { setValue: setOuterValue, setTouched }] = useField({
    name,
  })

  useEffect(() => {
    if (strictValue) {
      setInnerValue(strictValue)
    } else {
      if (field.value) {
        if (field.value.includes(':')) {
          const [startDate, endDate] = field.value.split(':')
          const [startYear, startMonth, startDay] = startDate.split('-')
          const startDateValue = `${startMonth}/${startDay}/${startYear}`
          const [endYear, endMonth, endDay] = endDate.split('-')
          const endDateValue = `${endMonth}/${endDay}/${endYear}`
          setInnerValue(`${startDateValue} - ${endDateValue}`)
        } else {
          const [year, month, day] = field.value.split('-')
          setInnerValue(`${month}/${day}/${year}`)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (innerValue) {
      if (innerValue.includes(' - ')) {
        const [startDate, endDate] = innerValue.split(' - ')
        const [startMonth, startDay, startYear] = startDate.split('/')
        const startDateValue = `${startYear}-${startMonth}-${startDay}`
        const [endMonth, endDay, endYear] = endDate.split('/')
        const endDateValue = `${endYear}-${endMonth}-${endDay}`
        setOuterValue(`${startDateValue}:${endDateValue}`)
        return () => {}
      }
    }
  }, [innerValue])

  useEffect(() => {
    if (typeof field.value === 'undefined' && innerValue) {
      setInnerValue('')
    }
  }, [field.value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { defaultValue, value } = event.currentTarget

    const currentSymbol = (event.nativeEvent as InputEvent).data

    if (currentSymbol !== null && !isNumeric(currentSymbol)) {
      return false
    }

    if (
      value.length < defaultValue.length &&
      defaultValue[defaultValue.length - 1] === '/'
    ) {
      setInnerValue(value)
    } else {
      setInnerValue(getDateWithMask(value))
    }
  }

  const handleCalendarChange = (date: string) => {
    setInnerValue(date)
  }

  const handleToggleCalendar = () => {
    if (showCalendar) {
      setShowCalendar(false)
      return
    }

    if (innerValue === '') {
      setShowCalendar(true)
    } else {
      setShowCalendar(true)
    }
  }

  return (
    <FieldWrapper
      {...props}
      className={cx(
        styles.wrapper,
        showCalendar && styles.withCalendar,
        className,
      )}
    >
      <BasicInput
        {...props}
        inputClassName={cx(inputClassName, styles.input)}
        name={`inner-${name}`}
        placeholder="From - To"
        maxLength={10}
        value={innerValue}
        onChange={handleInputChange}
      />

      {!hideCalendar ? (
        <div className={styles.button} onClick={handleToggleCalendar}>
          <Icons.Calendar />
        </div>
      ) : null}

      <div ref={selectRef}>
        {showCalendar && (
          <div className={styles.calendarWrapper}>
            <Calendar
              futureDate={futureDate}
              pastDate={pastDate}
              value={innerValue}
              onChange={handleCalendarChange}
              onClose={() => setShowCalendar(false)}
            />
          </div>
        )}
      </div>
    </FieldWrapper>
  )
}

export default InputDateRange
