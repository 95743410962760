import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetEligibilityHistoryParams {
  eligibilityId: string
}

const useGetEligibilityHistory = (params: GetEligibilityHistoryParams) => {
  const { eligibilityId } = params

  return useQuery({
    queryKey: ['getEligibilityHistory', eligibilityId],
    queryFn: async () =>
      await api.get(`/v2/eligibilities/history/${eligibilityId}`),
  })
}

export default useGetEligibilityHistory
