import { Checkbox, Container } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import withErrorBoundaryAndPolicyCheck from '@v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { OrganizationModel } from '@v2/domains/organization/models/organization.model'

import { SearchFiltersModel } from '../../models/person.model'

const GlobalSearchCheckBox = ({
  organizationsByUser,
  form,
}: {
  organizationsByUser: OrganizationModel['organizationsByUser']
  form: UseFormReturnType<
    SearchFiltersModel,
    (values: SearchFiltersModel) => SearchFiltersModel
  >
}) => {
  return (
    <Container mb={32} ml={0}>
      {organizationsByUser.length! > 1 && (
        <Checkbox
          label="Search globally"
          radius="3px"
          {...form.getInputProps('getUserOrganizations')}
        />
      )}
    </Container>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  GlobalSearchCheckBox,
  'is_global_search_enabled',
)
