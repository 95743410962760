import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Breadcrumbs,
  Button,
  Checkbox,
  Form,
  Input,
} from 'src/common/components'
import { yup } from 'src/common/libs'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'
import { useGetEmailDomain, useMutateEmailDomain } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import styles from './single-email-domain-page.module.scss'

const SingleEmailDomainPage = () => {
  const mutationEmailDomain = useMutateEmailDomain('update')
  const [result, setResult] = useState<string | null>(null)
  const router = useRouter()

  const { emailDomainId = '' } = useParams<{ emailDomainId: string }>()

  const { data: getEmailDomian, isSuccess } = useGetEmailDomain({
    emailDomainId,
  })

  useEffect(() => {
    if (isSuccess) {
      formik.setValues({
        id: getEmailDomian.data?.id,
        domain: getEmailDomian.data?.domain,
        isWBFRegister: getEmailDomian.data?.isWBFRegister,
      })
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues: {
      id: '',
      domain: '',
      isWBFRegister: false,
    },
    onSubmit: async (values) => {
      mutationEmailDomain
        .mutateAsync(values)
        .then(() => {
          setResult('success')
        })
        .catch(() => {
          setResult('error')
        })
    },
    validationSchema: yup.object().shape({
      domain: yup.string().required('Organization is required'),
      isWBFRegister: yup.boolean().required('Is WBF Register is required'),
    }),
  })

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.EMAIL_DOMAINS.path)
    } else {
      setResult(null)
    }
  }

  const handleClose = () => {
    router.push(PRIVATE_ROUTES.EMAIL_DOMAINS.path)
  }

  return (
    <>
      <Breadcrumbs
        items={[{ label: formik.values.domain || 'Email Domain' }]}
      />

      <BasicTemplate>
        <BasicBlock title="Update Email Domain">
          <Form providerValue={formik}>
            <div className={styles.wrapperForm}>
              <Input
                width="50"
                name="domain"
                label="Email Address - Domain"
                required
              />

              <Checkbox
                width="50"
                name="isWBFRegister"
                label="Is WBF Register"
              />

              <PolicyCheckHOC policyName="is_update_email_domain_enabled">
                <div className={styles.buttonsWrapper}>
                  <Button
                    className={styles.cancelButton}
                    size="small"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    type="submit"
                    onClick={() => formik.submitForm()}
                  >
                    Save
                  </Button>
                </div>
              </PolicyCheckHOC>
            </div>
          </Form>

          {result !== null ? (
            <ModalResult
              isSuccess={result === 'success'}
              description={result !== 'success' ? result : undefined}
              onClose={handleCloseResult}
            />
          ) : null}
        </BasicBlock>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleEmailDomainPage,
  'is_get_email_domain_by_id_enabled',
)
