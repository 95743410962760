import cx from 'classnames'

import { useAppSelector } from '../../../../../v2/store/hooks'
import { DataLoaderState } from '../dataLoaderWizard.interface'
import styles from './review-step.module.scss'

interface ReviewStep {
  fileData: DataLoaderState['fileData']
}

const ReviewStep = (props: ReviewStep) => {
  const { fileData } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const getSampleDataColumns = (sampleData: string[]) => {
    if (sampleData.length > 3) {
      return sampleData.slice(0, 3)
    }
    return sampleData
  }

  return (
    <div
      className={cx(
        styles.cardContent,
        styles.noGap,
        styles[`${currentTheme}Slice`],
      )}
    >
      <div className={cx(styles.objectTable)}>
        <div
          className={cx(
            styles.box,
            styles.title,
            styles.noBorder,
            styles.defaultPointer,
          )}
        >
          Source Header
        </div>
        {getSampleDataColumns(Object.keys(fileData?.[0] || {})).map((item) => (
          <div
            key={item}
            className={cx(
              styles.box,
              styles.content,
              styles.noBorder,
              styles.defaultPointer,
            )}
          >
            {item}
          </div>
        ))}
      </div>
      <div className={cx(styles.objectTable)}>
        <div className={cx(styles.box, styles.title)}>Sample Data</div>

        {getSampleDataColumns(Object.values(fileData?.[0] || {})).map(
          (item) => (
            <div
              key={item}
              className={cx(styles.box, styles.content, styles.defaultPointer)}
            >
              {item}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default ReviewStep
