import { useState } from 'react'

import { Icons } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IPersonNote } from 'src/common/interfaces'

import { PersonNoteForm } from '../../components'
import styles from './person-notes-list-item.module.scss'

interface PersonNotesListItemProps {
  data: IPersonNote
  refetch: () => Promise<any>
}

const PersonNotesListItem = (
  props: React.PropsWithChildren<PersonNotesListItemProps>,
) => {
  const { data, refetch } = props
  const { user, message, createdAt } = data

  const [editMode, setEditMode] = useState<boolean>(false)

  const handleAfterSave = () => {
    setEditMode(false)
    refetch()
  }

  const isBuggyBinary = /[\x00-\x08\x0E-\x1F]/.test(message)

  return (
    <div className={styles.wrapper}>
      {!editMode ? (
        <div className={styles.inner}>
          <div>
            <div className={styles.header}>
              <div className={styles.headerName}>
                {user ? `${user.firstName} ${user.lastName}` : 'Unknown'}
              </div>

              <div>{formatDate(createdAt, 'MM/DD/YYYY HH:mm A')}</div>
            </div>

            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: !isBuggyBinary ? message : '<p>Unrecognized data</p>',
              }}
            />
          </div>

          <div className={styles.icon} onClick={() => setEditMode(true)}>
            <Icons.Edit />
          </div>
        </div>
      ) : (
        <div className={styles.formWrapper}>
          <PersonNoteForm
            data={data}
            onAfterSave={handleAfterSave}
            onCancel={() => setEditMode(false)}
          />
        </div>
      )}
    </div>
  )
}

export default PersonNotesListItem
