import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import DeleteConfirmationModal from 'src/v2/commons/components/DeleteConfirmationModal/DeleteConfirmationModal.component'
import SuccessErrorModal from 'src/v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'

interface DeleteModalWrapperProps {
  opened: boolean
  close: () => void
  handleDelete: () => void
  isSuccessDelete: boolean
  buttonText: string
  title: string
  subtitle: string
  body: string
  confirmationTitle: string
  confirmationButtonText: string
  successPath?: keyof typeof PRIVATE_ROUTES
}

const DeleteModalWrapper = ({
  opened,
  close,
  handleDelete,
  isSuccessDelete,
  buttonText,
  title,
  subtitle,
  body,
  confirmationTitle,
  confirmationButtonText,
  successPath,
}: DeleteModalWrapperProps) => {
  const [successdeleteModalOpened, { open: openSuccessDeleteModal }] =
    useDisclosure(false)

  useEffect(() => {
    if (isSuccessDelete) {
      openSuccessDeleteModal()
    }
  }, [isSuccessDelete])

  return (
    <>
      <DeleteConfirmationModal
        opened={opened}
        close={close}
        buttonText={buttonText}
        handleDelete={handleDelete}
        title={title}
        subtitle={subtitle}
        body={body}
      />
      {successdeleteModalOpened && (
        <SuccessErrorModal
          closeOnClickOutside={false}
          closeOnEscape={false}
          closeButtonProps={{
            display: 'none',
          }}
          status="success"
          title={confirmationTitle}
          buttonText={confirmationButtonText}
          path={PRIVATE_ROUTES[`${successPath}`].path}
          opened={successdeleteModalOpened}
          onClose={() => {}}
        />
      )}
    </>
  )
}

export default DeleteModalWrapper
