import { NYC_ORGANIZATION_ID } from 'src/v2/commons/config'
import {
  EnumEnrollmentInactiveStatus,
  EnumEnrollmentStatus,
  IEnrollment,
} from 'src/v2/domains/person/models/enrollment.model'
import { EnumPersonLanguage } from 'src/v2/domains/person/models/person.model'

export const legacyEnrollmentStatus = [
  EnumEnrollmentInactiveStatus.EXPIRED,
  EnumEnrollmentInactiveStatus.INACTIVE,
  EnumEnrollmentInactiveStatus.INVALID_CONTACT,
  EnumEnrollmentInactiveStatus.ELIGIBLE,
  EnumEnrollmentInactiveStatus.MISSING_INFO,
] as EnumEnrollmentStatus[]

export const getEnrollmentByOrgId = (
  enrollments: IEnrollment[],
  currentOrganizationId: string | null,
) => {
  return enrollments.find(
    (enrollment) =>
      enrollment.associatedBenefitContract?.organization?.id ===
      currentOrganizationId,
  )
}

export const mapEnrollmentStatus = (
  enrollment: EnumEnrollmentStatus,
  organizationId: string,
) => {
  const isNYCOrganization = organizationId === NYC_ORGANIZATION_ID

  if (!isNYCOrganization) {
    return enrollment
  }

  switch (enrollment) {
    case EnumEnrollmentInactiveStatus.DUPLICATE:
      return EnumEnrollmentInactiveStatus.EXISTING_PERSON
    case legacyEnrollmentStatus.includes(enrollment) ? enrollment : null:
      return EnumEnrollmentInactiveStatus.REVIEW_NEEDED
    default:
      return enrollment
  }
}

export const getPreferredLanguage = (preferredLanguage: string = '') => {
  return preferredLanguage
    ? EnumPersonLanguage[preferredLanguage as keyof typeof EnumPersonLanguage]
    : preferredLanguage
}
