import { appLocalStorage } from 'src/common/libs'

import { resetAuthStore } from '../../../v2/domains/auth/models/auth.store'
import { destroyUILocalStore } from '../../../v2/domains/ui/models/ui.store'
import { resetUserProfileStore } from '../../../v2/domains/user/models/user.store'
import { useAppDispatch } from '../../../v2/store/hooks'

const useLogout = () => {
  const dispatch = useAppDispatch()

  return {
    mutate: () => {
      dispatch(resetAuthStore())
      dispatch(resetUserProfileStore())
      dispatch(destroyUILocalStore())
      appLocalStorage.clear()
      dispatch({
        type: 'redirect',
        payload: { path: '/login', hardRedirect: true },
      })
    },
  }
}

export default useLogout
