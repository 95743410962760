import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetSmsByCampaignId = (
  smsCampaignId: string,
  limit: number,
  offset: number,
  status?: string,
) => {
  let url = `/sms-campaigns/${smsCampaignId}/sms?limit=${limit}&offset=${offset}`
  if (status) {
    url += `&status=${status}`
  }

  return useQuery({
    queryKey: ['getSmsByCampaignId', offset, status],
    queryFn: async () => await api.get(url),
    retry: 2,
  })
}

export default useGetSmsByCampaignId
