import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

interface GetPersonsParams {
  organizationId?: string
  associatedBenefitContractId?: string
  isVerified?: boolean
  isAssociatedBenefitContractVerified?: boolean
  documentNumber?: string
  email?: string
  phoneNumber?: string
  age?: number
  rating?: string
  search?: string
  source?: string
  companies?: string
  preferredLanguage?: string
  zipCode?: string
  assignedToUserId?: string
  isUnassigned?: boolean
  isEnrollmentStatusActive?: boolean
  tags?: string[]
  limit?: number
  offset?: number
  fromDate?: Date
  toDate?: Date
  orderBy?: string
  enabled?: boolean
}

const useGetPersons = (params: GetPersonsParams) => {
  const {
    enabled = true,
    organizationId,
    source,
    fromDate,
    toDate,
    limit,
    isVerified,
    email,
    orderBy,
    rating,
    preferredLanguage,
    zipCode,
    isUnassigned,
    companies,
  } = params

  return useInfiniteQuery({
    queryKey: [
      `getPersons`,
      organizationId,
      source,
      isVerified,
      rating,
      preferredLanguage,
      zipCode,
      isUnassigned,
      companies,
      fromDate,
      toDate,
    ],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v6/persons`, {
        signal,
        params: {
          organizationId,
          source,
          fromDate,
          toDate,
          limit,
          offset: pageParam,
          isVerified,
          email,
          orderBy,
          rating,
          preferredLanguage,
          zipCode,
          isUnassigned,
          companies,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      pages.filter((p: any) => p.data.records.length).length * PAGINATION_LIMIT,
    enabled,
  })
}

export default useGetPersons
