import { useState } from 'react'

import { BasicBlock, Table } from 'src/common/components'
import { PFML_ENROLLMENT_ABC_ID } from 'src/default/config'
import { useGetWashingtonStateRecord } from 'src/default/hooks/washington-state'
import { IEligibility } from 'src/default/interfaces'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import WashingtonStateModal from './WashingtonStateModal/WashingtonStateModal'
import styles from './single-account-wa.module.scss'

interface SingleAccountWashingtonStateProps {
  eligibilities?: IEligibility[]
}

const SingleAccountWashingtonState = (
  props: SingleAccountWashingtonStateProps,
) => {
  const [washingtonModalIsOpen, setWashingtonModalIsOpen] = useState(false)
  const eligibility = props.eligibilities?.find(
    (eligibility) =>
      eligibility.enrollment.associatedBenefitContract.id ===
      PFML_ENROLLMENT_ABC_ID,
  )

  const { data, isLoading } = useGetWashingtonStateRecord({
    id: eligibility?.providerExternalId ?? '',
  })

  const washingtonStateRecord = data?.data

  if (
    !washingtonStateRecord ||
    typeof washingtonStateRecord === 'undefined' ||
    typeof eligibility === 'undefined'
  ) {
    return null
  }

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        paddings="small"
        isLoading={isLoading}
        title="PFML Submission"
      >
        <Table className={styles.table}>
          <Table.Row
            key={washingtonStateRecord.id}
            className={styles.row}
            onClick={() => setWashingtonModalIsOpen(true)}
          >
            <Table.Column title="First Name">
              {washingtonStateRecord?.firstName}
            </Table.Column>
            <Table.Column title="Last Name">
              {washingtonStateRecord?.lastName}
            </Table.Column>
            <Table.Column title="Driver's License Number">
              {washingtonStateRecord?.driversLicenseNumber}
            </Table.Column>
            <Table.Column title="Email">
              {washingtonStateRecord?.email}
            </Table.Column>
          </Table.Row>
          <></>
        </Table>
      </BasicBlock>
      {washingtonModalIsOpen ? (
        <WashingtonStateModal
          washingtonStateRecord={washingtonStateRecord}
          eligibility={eligibility}
          onClose={() => setWashingtonModalIsOpen(false)}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountWashingtonState,
  'is_wastate_view_enabled',
)
