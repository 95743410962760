import cx from 'classnames'
import { useField } from 'formik'
import { useState } from 'react'

import { Select } from 'src/common/components'
import { IField, IOption } from 'src/common/interfaces'

import BasicMultiSelectSuggestionsCurrentOption from './BasicMultiSelectSuggestionsCurrentOption'
import FieldItem from './FieldItem'
import styles from './basic-multi-select-suggestions.module.scss'

const BasicMultiSelectSuggestions = (props: IField) => {
  const {
    width,
    tabletWidth,
    name,
    placeholder = 'Find...',
    options,
    ...rest
  } = props

  const [suggestions, setSuggestions] = useState<IOption[]>([])

  const [{ value: fieldValue }, meta, { setValue }] = useField(name)

  const handleCustomEvent = (
    eventId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (eventId === 'findValue') {
      const { value } = event.currentTarget

      const newSuggestions = (
        (value
          ? options?.filter((option: IOption) =>
              option.label.toLowerCase().includes(value.toLocaleLowerCase()),
            ) ?? []
          : []) as IOption[]
      ).filter((option: IOption) => !(fieldValue || []).includes(option.value))

      setSuggestions(newSuggestions)
    }
  }

  const handleChange = (newValue: string) => {
    setValue([...fieldValue, newValue])
    setSuggestions([])
  }

  const handleRemove = (value: string) => {
    setValue([...fieldValue.filter((item: string) => item !== value)])
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        tabletWidth && styles[`tabletW${tabletWidth}`],
        width && styles[`w${width}`],
      )}
    >
      <Select
        {...rest}
        name={name}
        className={styles.inner}
        inputClassName={styles.select}
        CurrentOptionComponent={BasicMultiSelectSuggestionsCurrentOption}
        placeholder={placeholder}
        options={suggestions}
        strictOpen={suggestions.length > 0}
        withArrow={false}
        onCustomEvent={handleCustomEvent}
        onChange={handleChange}
      />

      <div className={styles.selected}>
        {fieldValue?.map((value: string, i: number) => (
          <FieldItem key={i} value={value} onRemove={handleRemove} />
        ))}
      </div>
    </div>
  )
}

export default BasicMultiSelectSuggestions
