import cx from 'classnames'
import { useField } from 'formik'

import { FieldWrapper, Icons, Input } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './deactivation-benefit-stars.module.scss'

const DeactivationBenefitStars = () => {
  const [{ value }] = useField('service.stars')

  const activeStars = value || 0

  return (
    <FieldWrapper name="service.stars" label="How many stars do you have?">
      <div className={styles.wrapper}>
        <Input name="service.stars" disabled withError={false} />

        <div className={styles.starsWrapper}>
          {Array.from(Array(5).keys()).map((key) => (
            <div
              key={key}
              className={cx(
                styles.starItem,
                activeStars >= key + 1 && styles.filled,
              )}
            >
              <Icons.Star />
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitStars,
  'is_enable_deactivation_benefit_stars',
  true,
)
