import { CommunicationTypeEnum, ICommunication } from 'src/default/interfaces'

import styles from './action-item-description.module.scss'

function subjectFormatted(subject: string) {
  return subject.replace(/^_*(.)|_+(.)/g, (_, c1, c2, offset) => {
    if (c1) return c1.toUpperCase()
    return offset > 0 ? ' ' + c2.toLowerCase() : c2.toLowerCase()
  })
}

const ActionItemDescription = (props: ICommunication) => {
  const { user, person, subject, status } = props

  const description = (() => {
    if ('type' in props) {
      const { type } = props

      switch (type) {
        case CommunicationTypeEnum.EMAIL_OUTBOUND:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>{subject}</div>
              <div>
                <span>{`${user?.firstName || ''} ${
                  user?.lastName || ''
                }`}</span>{' '}
                sent an email to{' '}
                <span>{`${person?.firstName || ''} ${
                  person?.lastName || ''
                }`}</span>
              </div>
            </div>
          )
        case CommunicationTypeEnum.EMAIL_INBOUND:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>{subject}</div>
              <div>
                <span>{`${person?.firstName || ''} ${
                  person?.lastName || ''
                }`}</span>{' '}
                mail to{' '}
                <span>{`${user?.firstName || ''} ${
                  user?.lastName || ''
                }`}</span>
              </div>
            </div>
          )
        case CommunicationTypeEnum.CALL_OUTBOUND:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>{subject}</div>
              <div>
                <span>{`${user?.firstName || ''} ${
                  user?.lastName || ''
                }`}</span>{' '}
                logged a call with{' '}
                <span>{`${person?.firstName || ''} ${
                  person?.lastName || ''
                }`}</span>
              </div>
            </div>
          )
        case CommunicationTypeEnum.CALL_INBOUND:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>{subjectFormatted(subject)}</div>
              {status !== 'missed' ? (
                <div>
                  <span>{`${person?.firstName || ''} ${
                    person?.lastName || ''
                  }`}</span>{' '}
                  call to{' '}
                  <span>{`${user?.firstName || ''} ${
                    user?.lastName || ''
                  }`}</span>
                </div>
              ) : (
                <>
                  Missed call from
                  <span>
                    {` ${person?.firstName || ''} ${person?.lastName || ''}`}
                  </span>
                </>
              )}
            </div>
          )
        case CommunicationTypeEnum.CHAT:
        case CommunicationTypeEnum.SMS_OUTBOUND:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>{subject}</div>
              <div>
                <span>{`${user?.firstName || ''} ${
                  user?.lastName || ''
                }`}</span>{' '}
                sent an SMS to{' '}
                <span>{`${person?.firstName || ''} ${
                  person?.lastName || ''
                }`}</span>
              </div>
            </div>
          )
        case CommunicationTypeEnum.SMS_INBOUND:
          return <div></div>
        case CommunicationTypeEnum.TICKET:
          return (
            <div className={styles.textBody}>
              <div className={styles.title}>Ticket</div>
              <div>
                {status || ''} Ticket for <span>{subject}</span>
              </div>
            </div>
          )
      }
    }

    return (
      <div>
        <span>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span> had a
        task with{' '}
        <span>{`${person?.firstName || ''} ${person?.lastName || ''}`}</span>
      </div>
    )
  })()

  return <div className={styles.wrapper}>{description}</div>
}

export default ActionItemDescription
