import cx from 'classnames'

import { useAppSelector } from '../../../../v2/store/hooks'
import TableCollapsible from './TableCollapsible/TableCollapsible'
import { TableCoreRow } from './TableCore'
import TableFilter from './TableFilter'
import { TablePublicColumn, TablePublicRow, TableTh } from './TablePublic'
import { ITableColumn } from './table.interfaces'
import styles from './table.module.scss'

interface TableProps {
  className?: string
}

const Table = (props: React.PropsWithChildren<TableProps>) => {
  const { className, children } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  //TODO mb refactor defaultRow logic
  const defaultRow = (
    children
      ? Array.isArray(children)
        ? children[0]
        : children
      : [{ props: { children: [], editable: false } }]
  ) as { props: any } //TODO type

  const columns = defaultRow
    ? (defaultRow.props?.children ?? [])
        .filter((c: any) => c !== null) //TODO ts
        .map((c: { props: ITableColumn }, i: number) => ({
          title: c.props.title,
          sortable: c.props.sortable,
          showMobile: c.props.showMobile,
          width: c.props.width,
          mobileWidth: c.props.mobileWidth,
          noOverflow: c.props.noOverflow,
        }))
    : []

  return (
    <div
      className={cx(
        styles.tableWrapper,
        styles[`${currentTheme}Slice`],
        className,
      )}
    >
      {columns.length ? (
        <table>
          <thead>
            <tr>
              {columns.map((c: ITableColumn, i: number) => (
                <TableTh key={i} {...c} />
              ))}
            </tr>
          </thead>

          <tbody>
            {children && Array.isArray(children)
              ? children.map((row: any, i: number) => (
                  <TableCoreRow
                    key={i}
                    index={i}
                    {...row.props}
                    editLinkTemplate={defaultRow?.props.editLink}
                  >
                    {row.props.children}
                  </TableCoreRow>
                ))
              : null}
          </tbody>
        </table>
      ) : null}
    </div>
  )
}

Table.Th = TableTh
Table.Row = TablePublicRow
Table.Column = TablePublicColumn
Table.Filter = TableFilter
Table.Collapsible = TableCollapsible

export default Table
