import cx from 'classnames'
import { useField } from 'formik'
import { useState } from 'react'

import { AttachmentLink, Button, Icons } from 'src/common/components'
import { useUploadAttachment } from 'src/common/hooks'
import { FileUpload } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './single-account-modal-attachments.module.scss'

interface SingleAccountModalAttachmentsEmptyProps {
  disabled: boolean
  errorMessage?: string
}

const SingleAccountModalAttachmentsEmpty = (
  props: SingleAccountModalAttachmentsEmptyProps,
) => {
  const { disabled, errorMessage } = props

  return (
    <div className={styles.empty}>
      <div className={styles.icon}>
        {errorMessage ? <Icons.CloudUploadError /> : <Icons.CloudUploadGhost />}
      </div>

      <div className={cx(styles.description, errorMessage && styles.error)}>
        <div>{errorMessage ? 'Unable to upload file' : 'Select a file'}</div>

        <div className={styles.formats}>
          {errorMessage || 'JPG, PNG or PDF, file size must not exceed 5MB'}
        </div>
      </div>

      <Button
        className={styles.button}
        size="small"
        template="secondary"
        pseudo
        disabled={disabled}
      >
        SELECT FILE
      </Button>
    </div>
  )
}

interface SingleAccountModalAttachmentsProps {
  name: string
  isEditMode?: boolean
  onChange?: any
  multiple?: boolean
  attachmentType: 'enrollment' | 'document'
}

const SingleAccountModalAttachments = (
  props: SingleAccountModalAttachmentsProps,
) => {
  const { name, isEditMode = true, multiple = false, attachmentType } = props

  const uploadFileMutation = useUploadAttachment({ attachmentType })

  const [{ value }, , { setValue }] = useField(name)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  const handleDelete = (attachment: string) => {
    props.onChange?.call(this)
    if (multiple) {
      setValue(value?.filter((item: string) => item !== attachment))
    } else {
      setValue('')
    }
  }

  const uploadedItems = !multiple ? (value ? [value] : []) : value

  const showUploader = multiple ? true : !uploadedItems?.length

  return (
    <div className={styles.wrapper}>
      {showUploader ? (
        <FileUpload
          innerClassName={styles.inner}
          uploadFileMutation={uploadFileMutation}
          name={name}
          EmptyComponent={() => (
            <SingleAccountModalAttachmentsEmpty
              disabled={!isEditMode}
              errorMessage={uploadErrorMessage}
            />
          )}
          onUploadError={setUploadErrorMessage}
          ResultComponent={null}
          multiple={multiple}
          disabled={!isEditMode}
          label={`Attachment${multiple ? 's' : ''}`}
          formats={['.png', '.jpg', '.jpeg', '.pdf']}
          withError={false}
          onChange={props.onChange}
          maxSizeInMB={5}
        />
      ) : null}

      {uploadedItems?.length ? (
        <>
          <div className={styles.uploadedLabel}>Uploaded</div>

          <div className={styles.uploaded}>
            {uploadedItems.map((attachment: string, i: number) => (
              <div key={i} className={styles.uploadedItem}>
                <div className={styles.icon}>
                  <Icons.FileImage />
                </div>
                <div className={styles.name}>
                  <AttachmentLink
                    s3Url={attachment}
                    attachmentType={attachmentType}
                  />
                </div>

                {isEditMode ? (
                  <div
                    className={styles.delete}
                    onClick={() => handleDelete(attachment)}
                  >
                    <Icons.Trash />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountModalAttachments,
  'is_enable_single_account_attachment_detail',
  true,
)
