import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

import { useWindowSize } from 'src/common/hooks'

interface PortalTargetProps {
  className?: string
  id: string
  media?: 'desktop' | 'mobile'
}

const PortalTarget = (props: PortalTargetProps) => {
  const { className, id, media = 'desktop' } = props

  return <div className={className} id={`${media}_${id}`} />
}

interface PortalWrapperProps {
  id: string
  width?: number
  isAdaptive?: boolean
}

const PortalWrapper = (props: React.PropsWithChildren<PortalWrapperProps>) => {
  const { id, width = 768, isAdaptive = true, children } = props

  const { pathname } = useLocation()

  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null)

  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.width) {
      if (typeof document !== 'undefined') {
        const newPortalTarget = document.getElementById(
          isAdaptive
            ? `${windowSize.width >= width ? 'desktop' : 'mobile'}_${id}`
            : `desktop_${id}`,
        )
        setPortalTarget(newPortalTarget)
      }
    }
  }, [id, windowSize.width, pathname])

  if (!portalTarget) {
    return null
  }

  return createPortal(children, portalTarget)
}

export default {
  Target: PortalTarget,
  Wrapper: PortalWrapper,
}
