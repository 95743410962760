export const NYC_ORGANIZATION_ID = '6966036a-12d4-4a57-a2ad-c119d797edd1'
export const DRIVERS_DEMAND_JUSTICE_ID = 'bdbed64f-66e3-438f-881c-0b8b45b33b70'
export const IDG_MACHINISTS_UNION = 'dabbb0c3-16db-44d5-b9c8-6b553377faba'
export const DRIVERS_UNION_ID = '1f0636ef-588f-4dbd-ab55-114750af7dc2'
export const UNION360 = '6b007544-3832-4a1f-a47a-e07535e3b2ec'
export const FUTURE_BANK = '9649f86c-175a-413c-a7e1-4e249196354b'
export const ALL_ORGANIZATION_NAMES = [
  'wbf',
  'bcf-new-york',
  'idg-illinois',
  'drivers-union',
  'demonstration-org',
  'drivers-demand-justice',
  'union360',
  'idg-connecticut',
  'idg-florida',
  'idg-state-is-not-listed',
  'idg-machinists-union',
  'idg-new-jersey',
  'benestream',
  'idg-new-york',
]
