import { ApiResponseSerializer } from 'src/v2/commons/interface/ApiResponseSerializer'
import { rootApi } from 'src/v2/store/api/rootApi'

import { PolicyModel, UpdatePolicyDescriptionRequest } from './policy.model'

export const policyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPolicies: builder.query<ApiResponseSerializer<PolicyModel>, void>({
      query: () => ({
        url: '/v1/authorization/policies',
        method: 'GET',
      }),
      providesTags: ['Policies'],
    }),
    updatePolicyDescription: builder.mutation<
      void,
      UpdatePolicyDescriptionRequest
    >({
      query: (data) => ({
        url: '/v1/authorization/policy',
        method: 'PATCH',
        data: {
          id: data.id,
          name: data.name,
          description: data.description,
        },
      }),
      invalidatesTags: ['Policies'],
    }),
  }),
})

export const { useGetPoliciesQuery, useUpdatePolicyDescriptionMutation } =
  policyApi
