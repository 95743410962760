import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

import { Button, SubmitButton } from 'src/common/components'
import { useWindowSize } from 'src/common/hooks'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './bottom-buttons.module.scss'

interface BottomButtonsProps {
  currentStepIndex: number
  stepsCount: number
  loading: boolean
  onChangeStep: (stepIndex: number) => void
}

const BottomButtons = (props: BottomButtonsProps) => {
  const { currentStepIndex, stepsCount, loading, onChangeStep } = props
  const { organizationId } = useParams<{ organizationId: string }>()

  const { errors }: any = useFormikContext()

  const windowSize = useWindowSize()

  const buttonSize = windowSize.width > 768 ? 'normal' : 'small'

  const enabledContinueButton =
    currentStepIndex === 0
      ? !Boolean(
          Object.keys(errors).some((key) => {
            return (
              Boolean(errors[key]) &&
              !['city', 'state', 'street', 'postalCode'].includes(key)
            )
          }),
        )
      : !Boolean(
          Object.keys(errors).some((key) => {
            return Boolean(errors[key])
          }),
        )

  return (
    <div className={styles.wrapper}>
      {!currentStepIndex ? (
        <Button
          size={buttonSize}
          template="secondary"
          to={`/${organizationId}${PRIVATE_ROUTES.LEADS.path}`}
        >
          Cancel
        </Button>
      ) : (
        <Button
          className={styles.backButton}
          size={buttonSize}
          icon="ArrowSelect"
          onClick={() => onChangeStep(currentStepIndex - 1)}
        >
          Back
        </Button>
      )}

      {currentStepIndex + 1 !== stepsCount ? (
        <Button
          key="continue"
          className={styles.continueButton}
          size={buttonSize}
          iconAfter="ArrowSelect"
          disabled={!enabledContinueButton}
          onClick={() => onChangeStep(currentStepIndex + 1)}
        >
          Continue
        </Button>
      ) : (
        <SubmitButton
          disabled={!enabledContinueButton}
          key="submit"
          className={styles.continueButton}
          size={buttonSize}
          iconAfter="ArrowSelect"
          loading={loading}
        >
          Save
        </SubmitButton>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BottomButtons,
  'is_enable_create_leads_bottom_buttons',
  true,
)
