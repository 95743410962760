import { notifications } from '@mantine/notifications'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetRoleQuery,
  useUpdateRolePolicyMutation,
} from '../models/role.api'
import { IRoleResponse, RoleModel } from '../models/role.model'

export const useRoleUseCase = (): IRoleResponse => {
  const { roleId } = useParams()
  const initialRole: RoleModel = {
    id: '',
    name: '',
    createdAt: new Date(),
    deletedAt: new Date(),
    updatedAt: new Date(),
    policies: [],
  }

  const {
    data: dataRole,
    isLoading,
    refetch: refetchRole,
  } = useGetRoleQuery(roleId!)

  const [activePolicies, setActivePolicies] = useState<string[]>()
  const [updateRolePolicy] = useUpdateRolePolicyMutation()

  const handleOnCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    policyId: string,
  ) => {
    const action = event.target.checked ? 'attach' : 'detach'

    updateRolePolicy({
      action,
      roleId: dataRole ? dataRole.id : '',
      policyId: policyId,
    })
      .then(() => {
        setActivePolicies((prevActivePolicies: string[] | undefined) =>
          event.target.checked
            ? prevActivePolicies?.filter((id: string) => id !== policyId)
            : [...prevActivePolicies!, policyId],
        )
        refetchRole()
        notifications.show({
          title: 'Success',
          message: 'Role Policy succesfuly changed',
        })
      })
      .catch((error: Error) => {
        notifications.show({
          title: 'Oops',
          message: 'There was an error',
          color: 'red',
        })
      })
  }

  if (isLoading) {
    return {
      data: initialRole,
      isLoading,
      activePolicies,
      handleOnCheckBoxChange,
      refetch: refetchRole,
    }
  }

  if (!dataRole)
    return {
      data: initialRole,
      isLoading: false,
      activePolicies,
      handleOnCheckBoxChange,
      refetch: refetchRole,
    }

  return {
    data: dataRole,
    isLoading: false,
    activePolicies,
    handleOnCheckBoxChange,
    refetch: refetchRole,
  }
}
