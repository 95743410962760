import { useField } from 'formik'

import { Button, EmptyPersons } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

const SearchResultEmptyPersons = () => {
  const [, , { setValue: setGlobalModeValue }] = useField('globalMode')

  return (
    <EmptyPersons
      term="record"
      ActionComponent={
        <Button onClick={() => setGlobalModeValue(true)}>
          Try to search in all organizations
        </Button>
      }
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SearchResultEmptyPersons,
  'is_enable_benestream_search_result_empty_persons',
  true,
)
