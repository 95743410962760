import { Grid, TextInput, Textarea, rem } from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { Form, UseFormReturnType } from '@mantine/form'
import { IconCalendar } from '@tabler/icons-react'

import { CreateSmsCampaignModel } from '../../models/smsCampaign.model'

const StepOneCreateSmsCampaign = ({
  form,
}: {
  form: UseFormReturnType<CreateSmsCampaignModel>
}) => {
  return (
    <Form form={form}>
      <Grid gutter="md">
        <Grid.Col>
          <TextInput
            name="title"
            label="Title"
            withAsterisk
            description="This title is only visible for us, not for the final target"
            placeholder="Enter the title of the campaign"
            {...form.getInputProps('title')}
          />
        </Grid.Col>
        <Grid.Col>
          <Textarea
            name="smsMessage"
            withAsterisk
            label="SMS Message"
            description="Take into account the size of the message and the links are not shorter by default, please use an open source tool for this."
            placeholder="Enter the message of the campaign"
            {...form.getInputProps('smsMessage')}
          />
        </Grid.Col>
        <Grid.Col>
          <DateTimePicker
            name="sendAt"
            leftSection={
              <IconCalendar
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            }
            leftSectionPointerEvents="none"
            withAsterisk
            label="Send At"
            valueFormat="MM-DD-YYYY hh:mm"
            placeholder="MM-DD-YYYY hh:mm"
            {...form.getInputProps('sendAt')}
          />
        </Grid.Col>
      </Grid>
    </Form>
  )
}

export default StepOneCreateSmsCampaign
