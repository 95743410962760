import { Box, Button, Card, Flex, Group, Text, TextInput } from '@mantine/core'

import { useUpdateRoleContainer } from '../containers/useUpdateRole.container'
import styles from '../views/role.module.scss'

export const RoleForm = (props: {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
}) => {
  const { id, name, createdAt, updatedAt } = props
  const { onSubmit, roleUpdateForm } = useUpdateRoleContainer({ id, name })

  return (
    <Box>
      <Text mt={20} className={styles.title}>
        Role Information
      </Text>
      <Flex mx="xl" gap="xl">
        <TextInput
          label="Name"
          style={{ flex: 1 }}
          required
          {...roleUpdateForm.getInputProps('name')}
        />
      </Flex>
      <Flex mx="xl" gap="xl" mt="lg">
        <TextInput
          value={new Date(createdAt).toDateString()}
          readOnly
          label="Created Date"
          style={{ flex: 1 }}
        />
        <TextInput
          value={new Date(updatedAt).toDateString()}
          readOnly
          label="Last Modified Date"
          style={{ flex: 1 }}
        />
      </Flex>
      <Flex mx="xl" gap="xl" justify="flex-end" direction="row" wrap="wrap">
        <Group justify="center" mt="xl">
          <Button variant="filled" onClick={() => onSubmit()}>
            Save
          </Button>
        </Group>
      </Flex>
    </Box>
  )
}
