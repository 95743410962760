import { ActionIcon, Box, Table, Text, Tooltip } from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import DeleteConfirmationModal from '@v2/commons/components/DeleteConfirmationModal/DeleteConfirmationModal.component'
import { formatDate } from '@v2/commons/utils/time'

import { IEmailTemplates } from '../../models/emailTemplate.model'
import styles from './emailTemplate.module.scss'

interface EmailTemplateListProps {
  templates: IEmailTemplates[]
  isLoading: boolean
  onEdit: (emailTemplateId: string) => void
  deleteEmailTemplate: () => void
  openDeleteModal: (emailTemplateId: string) => void
  displayDeleteModal: boolean
  setDisplayDeleteModal: (value: boolean) => void
}

export const EmailTemplateList = ({
  templates,
  isLoading,
  onEdit,
  deleteEmailTemplate,
  openDeleteModal,
  displayDeleteModal,
  setDisplayDeleteModal,
}: EmailTemplateListProps) => {
  if (isLoading) {
    return <Text>Loading...</Text>
  }

  return (
    <Box>
      <Table
        striped
        highlightOnHover
        classNames={{
          table: styles.table,
          thead: styles['table-head'],
          th: styles['table-th'],
          td: styles['table-td'],
        }}
        withTableBorder={true}
        withColumnBorders={true}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Subject</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Create By</Table.Th>
            <Table.Th>Created At</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {templates.map((template) => (
            <Table.Tr key={template.id}>
              <Table.Td>{template.subject}</Table.Td>
              <Table.Td>
                <Tooltip label={template.description} maw={300} multiline>
                  <Text className={styles['description-cell']}>
                    {template.description}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>
                <Tooltip label={template.createdBy}>
                  <Text className={styles['created-by-cell']}>
                    {template.createdBy}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>
                {formatDate(template.createdAt, 'MM/DD/YYYY')}
              </Table.Td>
              <Table.Td>{template.type}</Table.Td>
              <Table.Td>
                <ActionIcon
                  disabled={template.type === 'PRIVATE'}
                  variant="subtle"
                  color="blue"
                  onClick={() => onEdit(template.id)}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  variant="subtle"
                  color="red"
                  onClick={() => openDeleteModal(template.id)}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <DeleteConfirmationModal
        subtitle="Are you sure you want to delete this template?"
        close={() => setDisplayDeleteModal(false)}
        title="Confirm Template Removal"
        body="This action cannot be undone."
        opened={displayDeleteModal}
        buttonText="Delete Tempalte"
        handleDelete={deleteEmailTemplate}
      />
    </Box>
  )
}
