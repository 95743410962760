import { Select } from 'src/common/components'
import { IOption } from 'src/common/interfaces'

interface PastTicketsTableFilterAssignedToProps {
  usersOptions: IOption[]
}

const PastTicketsTableFilterAssignedTo = (
  props: PastTicketsTableFilterAssignedToProps,
) => {
  const { usersOptions } = props

  return (
    <Select
      name="assignedTo"
      placeholder="Assigned To"
      options={usersOptions}
      withError={false}
      template="filter"
    />
  )
}

export default PastTicketsTableFilterAssignedTo
