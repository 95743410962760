import cx from 'classnames'

import { Button, Icons } from 'src/common/components'

import styles from './benestream-person-household-person-income-delete-message.module.scss'

interface Props {
  setDeleteHouseholdMemberId: (value: string) => void
  handleDeleteHouseholdMember: () => void
  isLoading: boolean
}

const BenestreamPersonHouseholdIncomeDeleteMessage = (props: Props) => {
  const { setDeleteHouseholdMemberId, handleDeleteHouseholdMember, isLoading } =
    props
  return (
    <div className={styles.deleteWrapper}>
      <div className={styles.iconTrashWrapper}>
        <Icons.Trash className={styles.trashIcon} />
      </div>
      <div className={styles.deleteTitle}>Delete Source of Income?</div>
      <div className={styles.deleteDescription}>
        Are you sure you want to permanently delete the Source of Income? This
        action cannot be undone.
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className={cx(styles.button, styles.cancelButton)}
          onClick={() => setDeleteHouseholdMemberId('')}
        >
          Cancel
        </Button>
        <Button
          className={cx(styles.button, styles.deleteButton)}
          onClick={handleDeleteHouseholdMember}
          loading={isLoading}
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  )
}

export default BenestreamPersonHouseholdIncomeDeleteMessage
