export const STATES = {
  ALABAMA: { value: 'Alabama', match: ['AL'] },
  ALASKA: { value: 'Alaska', match: ['AK'] },
  ARIZONA: { value: 'Arizona', match: ['AZ'] },
  ARKANSAS: { value: 'Arkansas', match: ['AR'] },
  CALIFORNIA: { value: 'California', match: ['CA'] },
  COLORADO: { value: 'Colorado', match: ['CO'] },
  CONNECTICUT: { value: 'Connecticut', match: ['CT'] },
  DELAWARE: { value: 'Delaware', match: ['DE'] },
  DISTRICT_OF_COLUMBIA: { value: 'District Of Columbia', match: ['DC'] },
  FLORIDA: { value: 'Florida', match: ['FL'] },
  GEORGIA: { value: 'Georgia', match: ['GA'] },
  HAWAII: { value: 'Hawaii', match: ['HI'] },
  IDAHO: { value: 'Idaho', match: ['ID'] },
  ILLINOIS: { value: 'Illinois', match: ['IL'] },
  INDIANA: { value: 'Indiana', match: ['IN'] },
  IOWA: { value: 'Iowa', match: ['IA'] },
  KANSAS: { value: 'Kansas', match: ['KS'] },
  KENTUCKY: { value: 'Kentucky', match: ['KY'] },
  LOUISIANA: { value: 'Louisiana', match: ['LA'] },
  MAINE: { value: 'Maine', match: ['ME'] },
  MARYLAND: { value: 'Maryland', match: ['MD'] },
  MASSACHUSETTS: { value: 'Massachusetts', match: ['MA'] },
  MICHIGAN: { value: 'Michigan', match: ['MI'] },
  MINNESOTA: { value: 'Minnesota', match: ['MN'] },
  MISSISSIPPI: { value: 'Mississippi', match: ['MS'] },
  MISSOURI: { value: 'Missouri', match: ['MO'] },
  MONTANA: { value: 'Montana', match: ['MT'] },
  NEBRASKA: { value: 'Nebraska', match: ['NE'] },
  NEVADA: { value: 'Nevada', match: ['NV'] },
  NEW_HAMPSHIRE: { value: 'New Hampshire', match: ['NH'] },
  NEW_JERSEY: { value: 'New Jersey', match: ['NJ'] },
  NEW_MEXICO: { value: 'New Mexico', match: ['NM'] },
  NEW_YORK: { value: 'New York', match: ['NY'] },
  NORTH_CAROLINA: { value: 'North Carolina', match: ['NC'] },
  NORTH_DAKOTA: { value: 'North Dakota', match: ['ND'] },
  OHIO: { value: 'Ohio', match: ['OH'] },
  OKLAHOMA: { value: 'Oklahoma', match: ['OK'] },
  OREGON: { value: 'Oregon', match: ['OR'] },
  PENNSYLVANIA: { value: 'Pennsylvania', match: ['PA'] },
  RHODE_ISLAND: { value: 'Rhode Island', match: ['RI'] },
  SOUTH_CAROLINA: { value: 'South Carolina', match: ['SC'] },
  SOUTH_DAKOTA: { value: 'South Dakota', match: ['SD'] },
  TENNESSEE: { value: 'Tennessee', match: ['TN'] },
  TEXAS: { value: 'Texas', match: ['TX'] },
  UTAH: { value: 'Utah', match: ['UT'] },
  VERMONT: { value: 'Vermont', match: ['VT'] },
  VIRGINIA: { value: 'Virginia', match: ['VA'] },
  WASHINGTON: { value: 'Washington', match: ['WA'] },
  WEST_VIRGINIA: { value: 'West Virginia', match: ['WV'] },
  WISCONSIN: { value: 'Wisconsin', match: ['WI'] },
  WYOMING: { value: 'Wyoming', match: ['WY'] },
}
