import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { Icons } from 'src/common/components'

import { TagDto } from '../../../hooks/person/useGetPersonTags'
import styles from './multi-select-tags.module.scss'

interface MultiSelectTagsProps {
  initialTags: TagDto[]
  recentlyTags: TagDto[]
  onChange: (value: any) => void
  isLoading?: boolean
}

const MultiSelectTags = (props: MultiSelectTagsProps) => {
  let nameInput: any
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [typedTag, setTypedTag] = useState<string>('')
  const [resultTags, setResultTags] = useState<(TagDto | any)[]>(
    props.initialTags,
  )

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      let handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onChange(resultTags)
          setIsEditing(false)
        }
      }
      if (typeof document !== 'undefined') {
        document.addEventListener('mousedown', handleClickOutside)
      }
      return () => {
        if (typeof document !== 'undefined') {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }
    }, [ref, resultTags])
  }

  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef)

  return !isEditing || props.isLoading ? (
    <div
      className={styles.wrapper}
      onClick={() => {
        setIsEditing(true)
        nameInput?.focus()
      }}
    >
      {resultTags.length === 0 ? (
        <div className={styles.tag}>
          <p>Add tag</p>
        </div>
      ) : (
        resultTags
          .filter((tag) => !tag.isHided)
          .map((tag) => (
            <div
              className={styles.tag}
              style={tag.color ? { backgroundColor: tag.color } : {}}
            >
              <p>{tag.name}</p>
            </div>
          ))
      )}
      {props.isLoading ? <p>...</p> : null}
    </div>
  ) : (
    <div ref={wrapperRef} className={cx(styles.component)}>
      <div
        className={cx(styles.wrapper, styles.editor)}
        onClick={() => {
          setIsEditing(true)
          nameInput?.focus()
        }}
      >
        {resultTags
          .filter((tag) => !tag.isHided)
          .map((tag) => (
            <div
              className={styles.tag}
              style={tag.color ? { backgroundColor: tag.color } : {}}
              onClick={() => {
                if (!tag.isProtected)
                  setResultTags([
                    ...resultTags.filter((rt) => rt.name != tag.name),
                  ])
              }}
            >
              <p>{tag.name}</p>
              {!tag.isProtected ? <Icons.Close width="12px" /> : null}
            </div>
          ))}
        <input
          type="text"
          ref={(input) => {
            nameInput = input
          }}
          autoFocus
          value={typedTag}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setResultTags([...resultTags, { id: null, name: typedTag }])
              setTypedTag('')
              nameInput?.focus()
            }
          }}
          onChange={(v) => {
            setTypedTag(v.target.value)
          }}
        />
        <div className={cx(styles.close)}>
          <Icons.Close
            onClick={() => {
              setResultTags(resultTags.filter((tag) => tag.isProtected))
              setTypedTag('')
              nameInput?.focus()
            }}
            width="12px"
          />
        </div>
      </div>
      <div className={cx(styles.options)}>
        {props.recentlyTags
          .filter((item) => !item.isNotEligible)
          .map((tag) => {
            if (
              !resultTags.find(
                (rt) => rt.name.toLowerCase() === tag.name.toLowerCase(),
              ) &&
              (typedTag === '' ||
                tag.name.toLowerCase().includes(typedTag.toLowerCase()))
            ) {
              return (
                <p
                  onClick={() => {
                    setResultTags([...resultTags, tag])
                    setTypedTag('')
                    nameInput?.focus()
                  }}
                >
                  {tag.name}
                </p>
              )
            }
          })}
        {!props.recentlyTags.find((rt) => rt.name === typedTag) &&
        typedTag != '' ? (
          <p
            onClick={() => {
              setResultTags([...resultTags, { id: null, name: typedTag }])
              setTypedTag('')
              nameInput?.focus()
            }}
          >
            {typedTag} (New tag)
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default MultiSelectTags
