import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages, splitCamelCase } from 'src/common/helpers'
import { IHistory } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { useGetHistoryPerson } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './person-history-tracker.module.scss'

const PersonHistoryTracker = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const { personId } = useParams<{ personId: string }>()
  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isFetched,
  } = useGetHistoryPerson({ personId: personId || '', limit: PAGINATION_LIMIT })

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages, isLoading])

  //TODO remove it?
  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const historyPersonRecords = concatQueryPages<IHistory>(pages)

  const historyPersonOriginalValue = (
    fields: string,
    originalValue: string,
  ) => {
    if (fields === 'birthDate') {
      return dayjs.utc(originalValue).format('MM/DD/YYYY')
    }
    return originalValue
  }

  const historyPersonNewValue = (field: string, newValue: string) => {
    if (field === 'birthDate') {
      return dayjs.utc(newValue).format('MM/DD/YYYY')
    }
    return newValue
  }

  return (
    <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
      <div className={styles.title}>History</div>

      <div className={styles.historyContainer}>
        {!isLoading && historyPersonRecords.length
          ? historyPersonRecords.map((history: IHistory) => (
              <div key={history.id} className={styles.historyItem}>
                <div className={styles.itemTitle}>{`${
                  history?.user?.firstName ?? 'Not Defined'
                } ${history?.user?.lastName ?? 'Not Defined'}`}<span className={styles.actionText}>{` ${history.actionSource?'By '+history.actionSource : ''} `}</span></div>
                <div className={styles.itemBody}>
                  <div className={styles.fieldChange}>
                    {history.originalValue === null ||
                    history.originalValue === '' ? (
                      <>
                        Changed {splitCamelCase(history.objectField)} to {''}
                        <strong>{history.newValue}</strong>
                      </>
                    ) : (
                      <>
                        Changed {splitCamelCase(history.objectField)} from{' '}
                        <strong>
                          {historyPersonOriginalValue(
                            history.objectField,
                            history.originalValue,
                          )}
                        </strong>{' '}
                        to{' '}
                        <strong>
                          {historyPersonNewValue(
                            history.objectField,
                            history.newValue,
                          )}
                        </strong>
                      </>
                    )}
                  </div>
                  <div className={styles.dateChange}>
                    {dayjs(history.dateOfChange).format('MM/DD/YYYY h:mm A')}
                  </div>
                </div>
              </div>
            ))
          : isFetched && (
              <div className={styles.noHistory}>No history found</div>
            )}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonHistoryTracker,
  'is_get_persons_history_by_id_enabled',
)
