import { Button } from '@mantine/core'
import { WbfPolicies } from '@workers-benefit-fund/shared-data'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Icons } from 'src/common/components/ui'
import { PRIVATE_ROUTES } from 'src/default/config'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { DRIVERS_UNION_ID } from 'src/v2/commons/config'

const ButtonCreateNewLead = ({
  currentOrganizationId,
}: {
  currentOrganizationId: string
}) => {
  const { organizationId } = useParams<{ organizationId: string }>()
  const isDriversUnionOrg = currentOrganizationId === DRIVERS_UNION_ID

  const getpath = () => {
    return isDriversUnionOrg
      ? 'https://www.driversunionwa.org/sign_up'
      : `/${organizationId}${PRIVATE_ROUTES.LEADS.path}/create`
  }

  const getTarget = () => {
    return isDriversUnionOrg ? '_blank' : undefined
  }

  return (
    <Button
      type="button"
      justify="space-between"
      leftSection={<Icons.Plus />}
      component={Link}
      to={getpath()}
      target={getTarget()}
    >
      Create New Lead
    </Button>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ButtonCreateNewLead,
  WbfPolicies.is_create_person_enabled,
)
