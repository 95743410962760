import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetMetroplusRepresentative = () => {
  return useQuery({
    queryKey: ['getMetroplusRepresentative'],
    queryFn: async () => await api.get('/metro-plus/get-metroplus-reps'),
  })
}

export default useGetMetroplusRepresentative
