import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { Button, Form, Icons, Input, Modal } from 'src/common/components'
import usePermanentDeletePerson from 'src/common/hooks/person/usePermanentDeletePerson'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './delete-person-modal.module.scss'

interface IDeletePersonModalProps {
  onClose: () => void
  onSuccessDelete: () => void
  personEmail: string
  personId: string
}

const DeletePersonModal = (props: IDeletePersonModalProps) => {
  const { personEmail, onClose, onSuccessDelete } = props
  const deletePersonMutation = usePermanentDeletePerson()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .oneOf(
          [personEmail],
          "Please verify the email entered matches the record's email exactly",
        )
        .required('Main Email is required'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      deletePersonMutation.mutate(
        { personId: props.personId, email: values.email },
        {
          onSuccess: () => {
            onClose()
            onSuccessDelete()
          },
          onError: (e) => {
            console.error(e)
          },
        },
      )
    },
  })

  return (
    <Modal
      id="deletePersonModal"
      template="medium"
      onClose={onClose}
      className={styles.deletePersonModalWrapper}
    >
      <header className={styles.modalHeader}>
        <div className={styles.iconContainer}>
          <Icons.Warning />
        </div>
        <h2>Confirm Permanent Removal</h2>
      </header>
      <section className={styles.modalBody}>
        <p>
          <strong>
            Are you sure you want to permanently delete this record?
          </strong>{' '}
          <span>
            To confirm and proceed, please enter the record's main email address
            in the field below. This final step ensures you're deleting the
            intended record.
          </span>
        </p>
        <Form providerValue={formik}>
          <Input
            name="email"
            label="Main Email"
            placeholder="Enter Main Email"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            strictError={
              typeof formik.errors.email === 'string' ? formik.errors.email : ''
            }
          />
          <footer className={styles.modalFooter}>
            <Button
              type="button"
              template="secondary"
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="small"
              disabled={!(formik.isValid && formik.dirty)}
              onClick={() => formik.submitForm()}
            >
              Delete
            </Button>
          </footer>
        </Form>
      </section>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeletePersonModal,
  'is_person_permanent_delete_enabled',
)
