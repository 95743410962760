import cx from 'classnames'

import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { formatDate } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'
import { CommunicationTypeEnum, ICommunication } from 'src/default/interfaces'

import { setNewCommunication } from '../../../../../../v2/domains/communication/models/communication.store'
import {
  setShowTicketBlockInformation,
  setTicketId,
} from '../../../../../../v2/domains/ticket/models/ticket.store'
import { useAppDispatch } from '../../../../../../v2/store/hooks'
import ActionItemDescription from './ActionItemDescription'
import ActionItemIcon from './ActionItemIcon'
import styles from './action-item.module.scss'

const ActionItem = (props: ICommunication) => {
  const dispatch = useAppDispatch()

  const {
    setFromEmail,
    setReplyEmail,
    setSubject,
    setEmailBody,
    setIsNewEmail,
    setIsSuccessSendEmail,
    setIsViewEmail,
    setFilesAttachments,
  } = useEmailSenderAndManagerEmailTemplateContext()
  const {
    createdAt,
    type,
    subject,
    from: fromEmail,
    content,
    replyTo,
    attachments,
  } = props

  const handleClick = () => {
    switch (type) {
      case CommunicationTypeEnum.EMAIL_OUTBOUND:
        setFromEmail(fromEmail)
        setReplyEmail(replyTo)
        setSubject(subject)
        setEmailBody(content)
        setFilesAttachments(attachments)
        setIsNewEmail(false)
        setIsViewEmail(true)
        setIsSuccessSendEmail(false)
        dispatch(setNewCommunication(true))
        dispatch(setShowTicketBlockInformation(false))
        break
      case CommunicationTypeEnum.TICKET:
        dispatch(setShowTicketBlockInformation(true))
        dispatch(setNewCommunication(false))
        dispatch(setTicketId(props.id))
        break
      default:
        break
    }
  }

  const itemClassPointer = () => {
    if (type === 'EMAIL_OUTBOUND' || type === 'TICKET') {
      return styles.pointer
    }
    return ''
  }

  return (
    <div
      className={cx(styles.actionItem, itemClassPointer())}
      onClick={handleClick}
    >
      <ActionItemIcon {...props} />

      <div className={styles.sumary}>
        <ActionItemDescription {...props} />
        <div className={styles.date}>
          {dayjs(createdAt).tz().format('MMM DD, YYYY, hh:mm A [ET]')}
        </div>
      </div>
    </div>
  )
}

export default ActionItem
