import cx from 'classnames'

import { useAppSelector } from '../../../../v2/store/hooks'
import { Button } from '../../form'
import BasicBlock from '../BasicBlock/BasicBlock'
import styles from './wizard.module.scss'

interface ButtonProps {
  label: string
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  noArrow?: boolean
}

export interface StepProps {
  title: string
  content: React.ReactElement
  footer: {
    backButton: ButtonProps
    nextButton: ButtonProps
  }
}

interface WizardProps {
  steps: StepProps[]
  currentStep: number
  changeCurrentStep: (step: number) => void
  hideFooter?: boolean
}

const Wizard = (props: WizardProps) => {
  const { steps, currentStep, changeCurrentStep, hideFooter } = props

  const { footer, content } = steps[currentStep - 1]

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const handleOnClickButton = (step: number) => {
    if (step > currentStep && footer.nextButton.onClick) {
      footer.nextButton.onClick()
    } else if (step < currentStep && footer.backButton.onClick) {
      footer.backButton.onClick()
    }

    if (step >= 1 && step <= steps.length) {
      changeCurrentStep(step)
    }
  }

  return (
    <BasicBlock>
      <div className={cx(styles.wizard, styles[`${currentTheme}Slice`])}>
        <div className={cx(styles.stepWrapper)}>
          {steps.map((step, index) => (
            <div key={index} className={cx(styles.stepCircleContainer)}>
              <div
                className={cx(
                  styles.stepCircle,
                  currentStep >= index + 1 && styles.current,
                )}
              >
                {index + 1}
              </div>
              <div
                className={cx(
                  styles.stepTitle,
                  currentStep >= index + 1 && styles.current,
                )}
              >
                {step.title}
              </div>
            </div>
          ))}
        </div>
        <div>{content}</div>
        {!hideFooter ? (
          <div className={cx(styles.buttonWrapper)}>
            <Button
              className={cx(styles.button, styles.backButton)}
              disabled={footer.backButton.disabled}
              icon={
                currentStep > 1 && !footer.backButton.noArrow ? 'ArrowLeft' : ''
              }
              onClick={() => handleOnClickButton(currentStep - 1)}
              loading={footer.backButton.loading}
            >
              {footer.backButton.label}
            </Button>
            <Button
              className={cx(styles.button)}
              iconAfter={
                currentStep < steps.length && !footer.backButton.noArrow
                  ? 'ArrowRight'
                  : ''
              }
              disabled={footer.nextButton.disabled}
              onClick={() => handleOnClickButton(currentStep + 1)}
              loading={footer.nextButton.loading}
            >
              {footer.nextButton.label}
            </Button>
          </div>
        ) : null}
      </div>
    </BasicBlock>
  )
}

export default Wizard
