import { useFormikContext } from 'formik'

import { Input, PersonHistoryTracker } from 'src/common/components'
import { formatDate } from 'src/common/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './lead-information.module.scss'

interface BenefitUtilizationProps {
  customerType: 'lead' | 'account'
}

const LeadInformation = (props: BenefitUtilizationProps) => {
  const { customerType } = props

  const {
    values: { createdBy, updatedBy, createdAt, updatedAt },
  } = useFormikContext() as any

  const createdAtLabel =
    customerType === 'account' ? 'Lead Created Date' : 'Created Date'
  const createdByLabel =
    customerType === 'account' ? 'Lead Created By' : 'Created By'

  const updatedDateLabel =
    customerType === 'account' ? 'Account Updated Date' : 'Updated Date'

  const updatedByLabel =
    customerType === 'account' ? 'Account Updated By' : 'Updated By'

  return (
    <div>
      <div className={styles.wrapperInformation}>
        <Input name="source" label="Lead Source" tabletWidth="50" disabled />

        <Input
          name="createdAt"
          label={createdAtLabel}
          tabletWidth="50"
          disabled
          value={formatDate(createdAt, 'MM/DD/YYYY')}
        />

        <Input
          className={styles.fullName}
          name="createdByFirstName"
          label={createdByLabel}
          tabletWidth="50"
          disabled
          value={`${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`}
        />

        <Input
          name="updatedAt"
          label={updatedDateLabel}
          tabletWidth="50"
          disabled
          value={formatDate(updatedAt, 'MM/DD/YYYY')}
        />

        <Input
          className={styles.fullName}
          name="updatedByFirstName"
          label={updatedByLabel}
          tabletWidth="50"
          disabled
          value={`${updatedBy?.firstName ?? ''} ${updatedBy?.lastName ?? ''}`}
        />
      </div>

      <PersonHistoryTracker />
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LeadInformation,
  'is_enable_lead_information',
  true,
)
