import { IEnrollment } from '@v2/domains/person/models/enrollment.model'
import {
  getOrganizationName,
  sortEnrollments,
} from '@v2/domains/person/utils/index'

import styles from './abcs-person-table.module.scss'

interface PersonOrganizationColumnProps {
  enrollments: IEnrollment[]
}

const PersonOrganizationColumn = (props: PersonOrganizationColumnProps) => {
  const { enrollments } = props
  const sortedEnrollment = sortEnrollments(enrollments)
  const uniqueOrganization = [
    ...new Set(
      sortedEnrollment.map((enrollment) => getOrganizationName(enrollment)),
    ),
  ]

  return (
    <>
      {uniqueOrganization.map((enrollmentName, idx) => (
        <div key={enrollmentName + idx} className={styles.abcItem}>
          {enrollmentName}
        </div>
      ))}
    </>
  )
}

export default PersonOrganizationColumn
