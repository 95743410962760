import { useClickOutside } from '@mantine/hooks'
import { useAppSelector } from '@v2/store/hooks'
import { useRef } from 'react'

import { useUpdatePolicyDescriptionUseCase } from '../useCases/updatePolicyDescription.usecase'

interface UpdatePolicyDescriptionProps {
  id: string
  description: string
  name: string
}

export const useUpdatePolicyDescriptionContainer = ({
  id,
  description,
  name,
}: UpdatePolicyDescriptionProps) => {
  const {
    policyUpdateForm,
    onSubmit,
    isLoading,
    onCancel,
    isEditing,
    setIsEditing,
  } = useUpdatePolicyDescriptionUseCase({
    id,
    description,
    name,
  })

  const { policies } = useAppSelector((state) => state.user.userProfile)

  const isEnableToEditDescription = policies.some(
    (policy) => policy === 'is_enable_edit_policies_description',
  )

  const inputRef = useRef<HTMLInputElement>(null)
  const clickOutsideRef = useClickOutside(() => {
    if (isEditing) {
      onCancel()
    }
  })

  const handleDoubleClick = () => {
    if (!isLoading) {
      setIsEditing(true)
    }
  }

  return {
    inputRef,
    clickOutsideRef,
    handleDoubleClick,
    policyUpdateForm,
    onSubmit,
    isLoading,
    onCancel,
    isEditing,
    setIsEditing,
    isEnableToEditDescription,
  }
}
