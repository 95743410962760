import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetDMPCasesParams {
  email?: string
}

const useGetDMPCases = (params: GetDMPCasesParams) => {
  const { email } = params

  const url = `/dmp${email ? '/email/' + email : ''}`

  return useQuery({
    queryKey: [`getGetDMPCases`, email],
    queryFn: async () => await api.get(url),
  })
}

export default useGetDMPCases
