import { Checkbox, Input } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './mutate-benefits-administration-buttons-data.module.scss'

interface MutateBenefitsAdministrationButtonsDataProps {
  language: string
}

const MutateBenefitsAdministrationButtonsData = (
  props: MutateBenefitsAdministrationButtonsDataProps,
) => {
  const { language } = props

  return (
    <div className={styles.wrapper}>
      {[0, 1].map((index) => (
        <div key={`${index}-${language}`} className={styles.buttonData}>
          <Input
            required={language !== 'en'}
            name={`buttons.${language}[${index}].name`}
            label="Button Name"
            placeholder="Enter Button Name"
          />

          <Input
            required={language !== 'en'}
            name={`buttons.${language}[${index}].link`}
            label="Hyperlink"
            placeholder="Enter Hyperlink"
          />

          <Checkbox
            innerClassName={styles.checkbox}
            name={`buttons.${language}[${index}].useNewTab`}
            label="Open in New Page"
            topLabel
          />
        </div>
      ))}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationButtonsData,
  'is_enable_mutate_benefits_administration_buttons_data',
  true,
)
