import { UseFormReturnType } from '@mantine/form'
import { OrganizationModel } from '@v2/domains/organization/models/organization.model'

import {
  type PersonModel,
  SearchFiltersModel,
} from 'src/v2/domains/person/models/person.model'
import useAssignSearchUseCase from 'src/v2/domains/person/useCases/search/useAssignSearch.useCase'
import useSearchUseCase from 'src/v2/domains/person/useCases/search/useSearch.useCase'

import {
  AssignPersonModel,
  PersonsModel,
} from '../../models/assignPerson.model'

interface IUseSearchContainer {
  data: PersonsModel | undefined
  searchForm: UseFormReturnType<SearchFiltersModel>
  searchQueryStatus: {
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
  }
  handlePaginate: () => void
  organizationId: string
  handleAddRemoveSearch: (search: PersonModel['id'], addSearch: boolean) => void
  selectedItems: PersonModel['id'][]
  handleSelectAllSearch: (payload: {
    isAllSelected: boolean
    allSearchResults: AssignPersonModel['filters']['selectedItems']
  }) => void
  handleClearStoreSearch: () => void
  searchValue: string | undefined
  searchResultsIds: string[]
  userOrganizations: OrganizationModel['organizationsByUser']
  isAssignMultipleTasksEnabled: boolean
}

const useSearchContainer = (): IUseSearchContainer => {
  const {
    handleAddRemoveSearch,
    selectedItems,
    handleSelectAllSearch,
    handleClearStoreSearch,
  } = useAssignSearchUseCase()

  const {
    data,
    searchResultsIds,
    handlePaginate,
    searchForm,
    organizationId,
    searchQueryStatus,
    searchValue,
    userOrganizations,
    isAssignMultipleTasksEnabled,
  } = useSearchUseCase()

  return {
    searchQueryStatus,
    organizationId,
    data,
    searchResultsIds,
    handlePaginate,
    searchForm,
    handleAddRemoveSearch,
    selectedItems,
    handleSelectAllSearch,
    handleClearStoreSearch,
    searchValue,
    userOrganizations,
    isAssignMultipleTasksEnabled,
  }
}

export default useSearchContainer
