import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import RichText from 'src/v2/commons/components/RichText/RichText'

import NewEmailEditorAttachments from '../NewEmailEditorAttachments'
import styles from './email-editor.module.scss'

const EmailEditor = () => {
  const {
    emailBody,
    setEmailBody,
    attachments,
    setAttachments,
    filesAttachments,
    emailDomainSignature,
    setFilesAttachments,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const handleAttachmentUpload = (
    files: { fileName: string; fileUrl: string; file: File }[],
  ) => {
    setFilesAttachments([
      ...files.map(({ fileName, fileUrl }) => ({ fileName, fileUrl })),
      ...filesAttachments,
    ])

    setAttachments([...files.map(({ file }) => file), ...attachments])
  }

  return (
    <>
      <RichText
        value={emailBody}
        onChange={setEmailBody}
        onAttachmentUpload={handleAttachmentUpload}
        className={styles.wrapper}
        showAttachments={true}
        attachmentType="emails"
        imageType="emails-images"
      />

      <RichText
        value={emailDomainSignature}
        onChange={() => {}}
        readOnly={true}
        showToolbar={false}
        className={styles.wrapperSignature}
      />

      <NewEmailEditorAttachments />
    </>
  )
}

export default EmailEditor
