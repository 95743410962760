import DataLoaderWizard from 'src/common/components/wizard/DataLoaderWizard'
import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

const DataLoaderPage = () => {
  return (
    <BasicTemplate title="Upload File">
      <DataLoaderWizard />
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DataLoaderPage,
  'is_enable_data_loader_view',
  true,
)
