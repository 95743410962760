import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateForms = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: any) => {
      const { id, ...restData } = data

      const url = '/v1/forms/template'

      return api[action === 'update' ? 'patch' : 'post'](
        url,
        action === 'update' ? data : restData,
      )
    },
  })

export default useMutateForms
