import { Input } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../../deactivation-benefit-case.module.scss'
import DeactivationBenefitHowLong from './DeactivationBenefitHowLong'
import DeactivationBenefitStars from './DeactivationBenefitStars'
import styles from './deactivation-benefit-driver-information.module.scss'

const DeactivationBenefitDriverInformation = () => {
  return (
    <div className={rootStyles.caseBlock}>
      <div className={rootStyles.title}>Driver Information</div>

      <div className={rootStyles.inner}>
        <Input disabled name="key" label="Case ID" />

        <Input
          disabled
          name="customer.profile.driveLocation.state"
          label="Driving area"
        />

        <Input
          disabled
          inputClassName={styles.serviceName}
          name="service.serviceName"
          label="Service"
        />

        <DeactivationBenefitHowLong />

        <DeactivationBenefitStars />

        <Input
          disabled
          name="service.trips"
          label="Number of completed trips"
        />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitDriverInformation,
  'is_enable_deactivation_benefit_driver_information',
  true,
)
