import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface SearchABCsParams {
  name: string
  organizationId: string
}

const useSearchABCs = (params: SearchABCsParams) => {
  const { organizationId, name } = params

  return useQuery({
    queryKey: ['abcs', name],
    queryFn: async () =>
      await api.get(`/abc/abcs/${name}?organizationIds=${organizationId}`),
  })
}

export default useSearchABCs
