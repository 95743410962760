import { UseFormReturnType } from '@mantine/form'

import { CreateUser } from '../models/user.model'
import useCreateUserUseCase from '../useCase/useCreateUser'

useCreateUserUseCase

interface UseCreateUserContainer {
  userCreateForm: UseFormReturnType<CreateUser>
  handleCancelForm: () => void
  onSubmit: () => void
  errorModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  loading: boolean
}

const useCreateUserContainer = (): UseCreateUserContainer => {
  const { userCreateForm, handleCancelForm, onSubmit, errorModal, loading } =
    useCreateUserUseCase()

  return {
    userCreateForm,
    handleCancelForm,
    onSubmit,
    errorModal,
    loading,
  }
}

export default useCreateUserContainer
