import cx from 'classnames'

import { Icons } from 'src/common/components'

import styles from './benestream-person-form-block.module.scss'

interface BenestreamPersonFormBlockProps {
  className?: string
  title: string
}

const BenestreamPersonFormBlock = (
  props: React.PropsWithChildren<BenestreamPersonFormBlockProps>,
) => {
  const { className, title, children } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.title}>
        <div className={styles.icon}>
          <Icons.ArrowSelect />
        </div>

        <span>{title}</span>
      </div>

      <div className={styles.inner}>{children}</div>
    </div>
  )
}

export default BenestreamPersonFormBlock
