import { GetInputProps } from '@mantine/form/lib/types'

import { LoginFormModel } from '../models/auth.model'
import useLoginFormUseCase from '../useCases/useLoginFormUseCase'

interface IUseLoginFormContainer {
  getFormProps: GetInputProps<LoginFormModel>
  onSubmit: () => void
  loginMutationStatus: {
    isLoading: boolean
    isSuccess: boolean
  }
}

const useLoginFormContainer = (): IUseLoginFormContainer => {
  const { getFormProps, onSubmit, loginMutationStatus } = useLoginFormUseCase()

  return {
    getFormProps,
    loginMutationStatus,
    onSubmit,
  }
}

export default useLoginFormContainer
