import {
  addSelectedItemLead,
  removeSelectedItemLead,
  resetLeadStoreLead,
  setIsAllSelectedLeads,
} from 'src/v2/domains/person/models/leads/assignLead.store'
import { useAppDispatch, useAppSelector } from 'src/v2/store/hooks'

import { AssignPersonModel } from '../../models/assignPerson.model'
import { type PersonModel } from '../../models/person.model'

const UseAssignLeadUseCase = () => {
  const dispatch = useAppDispatch()
  const { selectedItems } = useAppSelector((state) => state.lead.filters)

  const handleAddRemoveLead = (
    lead: Pick<PersonModel, 'id'>['id'],
    addLead: boolean,
  ) => {
    if (addLead) {
      dispatch(addSelectedItemLead(lead))
    } else {
      dispatch(removeSelectedItemLead(lead))
    }
  }

  const handleSelectAllLeads = ({
    isAllSelected,
    allSelectedPersons,
  }: {
    isAllSelected: boolean
    allSelectedPersons: AssignPersonModel['filters']['selectedItems']
  }) => {
    const payload = { isAllSelected, allSelectedPersons }
    dispatch(setIsAllSelectedLeads(payload))
  }

  const handleClearStoreLead = () => {
    dispatch(resetLeadStoreLead())
  }

  return {
    selectedItems,
    handleAddRemoveLead,
    handleSelectAllLeads,
    handleClearStoreLead,
  }
}

export default UseAssignLeadUseCase
