import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, EmailBadge, Icons } from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { useRefetchById } from 'src/common/hooks'
import { ModalResult } from 'src/default/components/Modal'
import { QUERY_IDS } from 'src/default/config'
import {
  useGetMembershipEmailDomains,
  useSendEmailMutation,
} from 'src/default/hooks'
import { MembershipEmailDomainUser } from 'src/v2/commons/interface/membershipEmailDomain'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { setNewCommunication } from '../../../../../v2/domains/communication/models/communication.store'
import { useAppDispatch, useAppSelector } from '../../../../../v2/store/hooks'
import EmailEditor from './EmailEditor'
import styles from './create-new-email.module.scss'

const CreateNewEmail = () => {
  const dispatch = useAppDispatch()
  const refetchById = useRefetchById()
  const { personId = '' } = useParams<{ personId: string }>()

  const {
    fromEmailId,
    replyEmailId,
    attachments,
    setAttachments,
    filesAttachments,
    setFilesAttachments,
    emailBody,
    setEmailBody,
    setFromEmailId,
    setFromEmail,
    setReplyEmailId,
    setReplyEmail,
    subject,
    setSubject,
    emailDomainSignature,
    setEmailDomainSignature,
    setIsNewEmail,
    setIsSuccessSendEmail,
    setIsViewEmail,
    isUploadingEmailBodyAttachments,
    setShowManagerEmailTemplateModal,
    setShowListEmailTemplateModal,
    setShowNewEmailTemplateModal,
    setEmailTemplateSelected,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false)
  const [titleModal, setTitleModal] = useState<string>('')
  const [descriptionModal, setDescriptionModal] = useState<string>('')
  const [showButtonsModal, setShowButtonsModal] = useState<boolean>(false)

  const sendEmail = useSendEmailMutation()

  const { data: membershipEmailDomains } = useGetMembershipEmailDomains({
    membershipId: userProfile.membership.id ?? null,
  })

  const handleManageTemplateModal = () => {
    setShowManagerEmailTemplateModal(true)
    setShowListEmailTemplateModal(true)
    setShowNewEmailTemplateModal(false)
  }

  const getErrorRequiredFields = (
    subject: string,
    emailBody: string,
    fromEmail: string,
    replyEmail: string,
  ) => {
    let error = []
    if (subject === '') {
      error.push('The subject of the email is required')
    }
    if (emailBody === '') {
      error.push('The body of the email is required')
    }
    if (fromEmail === '') {
      error.push('Please add at least one recipient before sending the email')
    }
    if (replyEmail === '') {
      error.push('Please add at least one email address to get replies')
    }
    return error.join(', \n')
  }

  const handleSendEmail = () => {
    if (
      subject === '' ||
      emailBody === '' ||
      fromEmailId === '' ||
      replyEmailId === ''
    ) {
      setIsSuccessModal(false)
      setShowButtonsModal(false)
      setTitleModal('Attention!')
      setShowModal(true)
      setDescriptionModal(
        getErrorRequiredFields(subject, emailBody, fromEmailId, replyEmailId),
      )
      return
    }

    sendEmail
      .mutateAsync({
        personId,
        fromEmailId,
        replyToEmailId: replyEmailId,
        subject,
        body: `${emailBody} <br /> ${emailDomainSignature}`,
        attachments,
        attachmentsFilesName: filesAttachments,
      })
      .then((res) => {
        if (res.data) {
          setIsNewEmail(false)
          setIsSuccessSendEmail(true)
          setIsViewEmail(false)
          refetchById(QUERY_IDS.GET_COMMUNICATIONS)
        }
      })
      .catch((error) => {
        setIsSuccessModal(false)
        setShowButtonsModal(true)
        setDescriptionModal(error?.response?.data?.message)
      })
  }

  const handleCancelEmail = () => {
    if (!isUploadingEmailBodyAttachments) {
      setDescriptionModal('Are you sure you want to delete this email?')
      setTitleModal('Delete Email')
      setShowButtonsModal(true)
      setIsSuccessModal(false)
      setShowModal(true)
    }
  }

  const membershipEmailDomainsFrom = (
    membershipEmailDomains: MembershipEmailDomainUser[],
  ) => {
    return membershipEmailDomains?.filter(
      (emailDomain: MembershipEmailDomainUser) =>
        (emailDomain.type === 'BOTH' &&
          emailDomain.emailDomain.isWBFRegister) ||
        (emailDomain.type === 'SEND' && emailDomain.emailDomain.isWBFRegister),
    )
  }

  const membershipEmailDomainsReply = (
    membershipEmailDomains: MembershipEmailDomainUser[],
  ) => {
    return membershipEmailDomains?.filter(
      (emailDomain: MembershipEmailDomainUser) =>
        emailDomain.type === 'BOTH' || emailDomain.type === 'REPLY',
    )
  }

  const handleClickModalButtons = (value: boolean) => {
    if (value) {
      dispatch(setNewCommunication(false))
      setEmailBody('')
      setEmailDomainSignature('')
      setSubject('')
      setFromEmailId('')
      setReplyEmailId('')
      setAttachments([])
      setFilesAttachments([])
    } else {
      setShowModal(false)
    }
  }

  const handleSaveAsTemplate = () => {
    setEmailTemplateSelected({
      id: '',
      subject: '',
      isGlobal: false,
      description: '',
      body: emailBody,
    })
    setShowManagerEmailTemplateModal(true)
    setShowListEmailTemplateModal(false)
    setShowNewEmailTemplateModal(true)
  }

  const handleFromEmail = (value: string) => {
    setFromEmailId(value)
    setFromEmail(
      membershipEmailDomains?.data.find(
        (email: MembershipEmailDomainUser) => email.id === value,
      )?.emailDomain?.domain,
    )
  }

  const handleReplayEmail = (value: string) => {
    setReplyEmailId(value)
    setReplyEmail(
      membershipEmailDomains?.data.find(
        (email: MembershipEmailDomainUser) => email.id === value,
      )?.emailDomain?.domain,
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerInformation}>
        <div className={styles.title}>New Email</div>
        <Button
          onClick={handleManageTemplateModal}
          icon="MoreOptions"
          className={styles.buttonTemplate}
          size="small"
        >
          Manage Template
        </Button>
      </div>
      <div className={styles.fromLabel}>
        <label className={styles.labelInformation}>
          From: <span>*</span>
        </label>
        <EmailBadge
          setEmailDomainSignature={setEmailDomainSignature}
          setFromOrReplyEmailId={handleFromEmail}
          membershipEmailDomains={membershipEmailDomainsFrom(
            membershipEmailDomains?.data,
          )}
        />
      </div>
      <div className={styles.replyLabel}>
        <label className={styles.labelInformation}>
          Reply To: <span>*</span>
        </label>
        <EmailBadge
          setFromOrReplyEmailId={handleReplayEmail}
          membershipEmailDomains={membershipEmailDomainsReply(
            membershipEmailDomains?.data,
          )}
        />
      </div>
      <div className={styles.subjectLabel}>
        <label className={styles.labelInformation}>
          Subject: <span>*</span>
        </label>
        <input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          type="text"
          className={styles.spanInformation}
          placeholder="Enter Email Subject"
        />
      </div>

      <EmailEditor />

      <div className={styles.footerButtonsContainer}>
        <div
          style={{
            marginTop: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icons.Trash onClick={handleCancelEmail} />
        </div>
        <div className={styles.containerButtons}>
          <PolicyCheckHOC policyName="is_create_email_template_enabled">
            <Button
              disabled={isUploadingEmailBodyAttachments}
              onClick={handleSaveAsTemplate}
              className={styles.alternativeButton}
              size="small"
            >
              Save As Template
            </Button>
          </PolicyCheckHOC>
          <PolicyCheckHOC policyName="is_send_email_to_person_enabled">
            <Button
              disabled={isUploadingEmailBodyAttachments}
              onClick={handleSendEmail}
              size="small"
              loading={sendEmail.isPending}
            >
              Send
            </Button>
          </PolicyCheckHOC>
        </div>
      </div>
      {showModal && (
        <ModalResult
          isSuccess={isSuccessModal}
          title={titleModal || ''}
          description={descriptionModal || ''}
          onClose={() => setShowModal(!showModal)}
          handleOnClickButton={handleClickModalButtons}
          showButtons={showButtonsModal}
          textSuccessButton="Delete"
        />
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateNewEmail,
  'is_send_email_to_person_enabled',
)
