import { useState } from 'react'

import { Button } from 'src/common/components'
//TODO something with the ManagerAppointmentModal file path
import { ManagerAppointmentModal } from 'src/default/templates/SinglePersonTemplate/Opportunities/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './schedule-appointment.module.scss'

interface IScheduleAppointment {
  className?: string
}

const ScheduleAppointment = (
  props: React.PropsWithChildren<IScheduleAppointment>,
) => {
  const { className } = props
  const [showModal, setShowModal] = useState<boolean>(false)

  const clickClose = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  return (
    <div className={className}>
      <Button
        className={styles.saveButton}
        size="small"
        icon="Calendar"
        onClick={openModal}
      >
        Schedule Appointment
      </Button>

      {showModal && (
        <ManagerAppointmentModal opportunity={null} clickClose={clickClose} />
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ScheduleAppointment,
  'is_create_appointment_enabled',
)
