import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import SuccessErrorModal from 'src/v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'

import ActionConfirmationModal from '../ActionConfirmationModal/ActionConfirmationModal.component'

interface ActionModalWrapperProps {
  isOpen: boolean
  onClose: () => void
  handleConfirm: () => void
  isSuccess: boolean
  buttonText: string
  title: string
  subtitle: string
  confirmationTitle?: string
  confirmationSubTitle?: string
  confirmationButtonText?: string
  successPath?: string
  isLoading: boolean
}

const ActionConfirmationModalWithFeedback = ({
  isOpen,
  onClose,
  handleConfirm,
  isSuccess,
  buttonText,
  title,
  subtitle,
  confirmationTitle = 'Changes Saved!',
  confirmationSubTitle = 'Record updated successfully',
  confirmationButtonText,
  successPath,
  isLoading,
}: ActionModalWrapperProps) => {
  const [
    successModalOpened,
    { open: openSuccessModal, close: closeSuccessModal },
  ] = useDisclosure(false)

  useEffect(() => {
    if (isSuccess) {
      openSuccessModal()
    }
  }, [isSuccess])

  return (
    <>
      <ActionConfirmationModal
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        buttonText={buttonText}
        handleConfirm={handleConfirm}
        title={title}
        subtitle={subtitle}
      />
      <SuccessErrorModal
        status="success"
        title={confirmationTitle}
        subTitle={confirmationSubTitle}
        buttonText={confirmationButtonText}
        path={successPath && PRIVATE_ROUTES[`${successPath}`].path}
        opened={successModalOpened}
        onClose={closeSuccessModal}
      />
    </>
  )
}

export default ActionConfirmationModalWithFeedback
