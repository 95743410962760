export const BENESTREAM_PERSON_STATUS = [
  {
    label: 'New',
    value: 'INITIAL',
  },
  {
    label: 'Opt Out',
    value: 'OPT_OUT',
  },
  {
    label: 'Removed',
    value: 'EXPIRED',
  },
]
