import cx from 'classnames'
import { useEffect } from 'react'

import { Icons } from 'src/common/components'

import styles from './modal.module.scss'

interface ModalProps {
  id: string
  title?: string
  titleAlign?: string
  subtitle?: string
  description?: string
  template?: string
  onClose?: () => void
  className?: string
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const {
    id,
    template = 'normal',
    children,
    onClose = () => false,
    className,
  } = props

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent | TouchEvent) => {
      if (typeof document !== 'undefined') {
        const specifiedElement = document.getElementById(id)
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = specifiedElement.contains(event.target)
          if (!isClickInside) {
            onClose()
          }
        }
      }
    }

    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('mousedown', clickEventHandler, false)
    window.addEventListener('touchstart', clickEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false)
      window.removeEventListener('touchstart', clickEventHandler, false)
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [id, onClose])

  return (
    <div className={cx(styles.wrapper, className)}>
      <div id={id} className={cx(styles.inner, styles[template])}>
        <div className={styles.close} onClick={onClose}>
          <Icons.Close />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
