import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IUserMembershipDomain {
  membershipId?: string
}

const useGetMembershipDomain = (params: IUserMembershipDomain) => {
  const { membershipId } = params

  return useQuery({
    queryKey: [`getMembershipDomains`, membershipId],
    queryFn: async () =>
      await api.get(`/v2/membership-domain/membership/${membershipId}`),
  })
}

export default useGetMembershipDomain
