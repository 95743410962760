import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutatePerson = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: Partial<{ id: string }>) => {
      const { id, ...rest } = data

      const url = action === 'update' ? `/v3/persons/${data.id}` : '/v5/persons'

      return api[action === 'update' ? 'patch' : 'post'](url, rest)
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useMutatePerson
