import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetEligibilityGuidelines = () => {
  return useQuery({
    queryKey: ['useGetEligibilityGuidelines'],
    queryFn: async () => await api.get(`/benestream-eligibility-guidelines`),
  })
}

export default useGetEligibilityGuidelines
