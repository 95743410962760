import { useAppSelector } from '@v2/store/hooks'

import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'

export const AdministrationContainer = () => {
  const router = useRouter()

  const organization = useAppSelector(
    (state) => state.user.userProfile,
  ).organization

  const policies = useAppSelector((state) => state.user.userProfile.policies)

  const handleUsers = () => {
    router.push(PRIVATE_ROUTES.USERS.path)
  }

  const handleRoles = () => {
    router.push(PRIVATE_ROUTES.ROLES_ADMINISTRATION.path)
  }

  const handlePolicies = () => {
    router.push(PRIVATE_ROUTES.POLICIES_ADMINISTRATION.path)
  }

  const handleABCs = () => {
    router.push(PRIVATE_ROUTES.ABCS_ADMINISTRATION.path)
  }

  const handleEmailTemplate = () => {
    router.push(PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path)
  }

  const isEnableEmailTemplates = policies.some(
    (policie) => policie === 'is_email_template_admin_feature_enabled',
  )

  return {
    handleRoles,
    handleUsers,
    handlePolicies,
    handleABCs,
    handleEmailTemplate,
    isEnableEmailTemplates,
    organization,
  }
}
