import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const runProcessSchema = createSlice({
  name: 'run-process',
  initialState: {},
  reducers: {
    resetRunProcess: (state) => {
      console.log(state)
    },
  },
})

export const { resetRunProcess } = runProcessSchema.actions

export default runProcessSchema.reducer
