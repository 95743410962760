import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Icons } from 'src/common/components'
import {
  GoogleMap,
  Input,
  InputGooglePlaces,
  SelectState,
} from 'src/common/components'
import { matchCustomerState } from 'src/common/helpers'
import { IPersonMutationData } from 'src/common/interfaces'
import { useNotifications } from 'src/common/notification'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './address-form-part.module.scss'

interface AddressFormPartProps {
  disabled?: boolean
  required?: boolean
}

const AddressFormPart = (props: AddressFormPartProps) => {
  const { disabled = false, required = false } = props

  const notifications = useNotifications()

  const { values, setValues } = useFormikContext<IPersonMutationData>()

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined,
  )

  useEffect(() => {
    const matchedValue = matchCustomerState(values.state)

    if (values.state !== matchedValue) {
      setValues({ ...values, state: matchedValue })
    }
  }, [])

  const handleCopyAddress = () => {
    const fullAddress = `${values.street} ${values.city} ${values.state} ${values.postalCode}`
    navigator.clipboard.writeText(fullAddress)

    notifications?.show('success', 'Address is copied to the clipboard', {
      id: 'addressIsCopyToTheClipboard',
      portalId: 'driverFormHeaderNotification',
    })
  }

  const handleGooglePlace = (result: google.maps.places.PlaceResult | null) => {
    setLocation(result?.geometry?.location ?? undefined)

    if (result) {
      const newValues = {
        street: '',
      } as {
        city?: string
        state?: string
        street?: string
        postalCode?: string
      }

      result.address_components?.reverse().forEach((a) => {
        if (a.types.includes('street_number')) {
          newValues.street = `${a.long_name} ${newValues.street}`
        }

        if (a.types.includes('route')) {
          newValues.street = a.long_name
        }

        if (a.types.includes('sublocality')) {
          if (!newValues.city) {
            newValues.city = a.long_name
          }
        }

        if (a.types.includes('locality')) {
          newValues.city = a.long_name
        }

        if (a.types.includes('administrative_area_level_1')) {
          newValues.state = a.long_name
        }

        if (a.types.includes('postal_code')) {
          newValues.postalCode = a.long_name
        }
      })

      setValues({
        ...values,
        ...newValues,
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.addressContainer}>
          <div onClick={handleCopyAddress} className={styles.copyAddress}>
            <Icons.Copy />
            Copy full address{' '}
          </div>
          <InputGooglePlaces
            name="street"
            label="Address"
            placeholder="Enter Street Address"
            disabled={disabled}
            onChange={handleGooglePlace}
          />
        </div>

        <Input
          name="city"
          label="City"
          placeholder="Enter City Name"
          disabled={disabled}
        />

        <SelectState
          name="state"
          label="State"
          placeholder="Enter State"
          disabled={disabled}
        />

        <Input
          name="postalCode"
          label="Postal Code"
          placeholder="Enter Postal Code"
          disabled={disabled}
        />
      </div>

      <GoogleMap
        className={styles.map}
        id="addressMap"
        location={location}
        defaultPlace={
          values.street ? `${values.city || ''} ${values.street}` : undefined
        }
        onLocationChange={setLocation}
      />
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AddressFormPart,
  'is_enable_create_lead_address_from_step',
  true,
)
