import { IEmailTemplate } from '@v2/domains/email-template/models/emailTemplate.model'
import cx from 'classnames'
import { useEffect, useState } from 'react'

import {
  Button,
  Icons,
  InputSearch,
  Modal,
  Select,
  Table,
} from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { useDebounce } from 'src/common/hooks'
import { ModalResult } from 'src/default/components'
import { UseDeleteEmailTemplate, useGetEmailTemplate } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './list-email-template-modal.module.scss'

const option = [
  { label: 'All Templates', value: 'all' },
  { label: 'Public Templates', value: 'public' },
  { label: 'Private Templates', value: 'private' },
]

const ListEmailTemplateModal = () => {
  const { data: emailTemplates, refetch } = useGetEmailTemplate()

  const {
    setShowManagerEmailTemplateModal,
    setShowNewEmailTemplateModal,
    setEmailTemplateSelected,
    setShowListEmailTemplateModal,
    setSubject,
    setEmailBody,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const [selectItem, setSelectedItem] = useState<IEmailTemplate>({
    body: '',
    description: '',
    id: '',
    subject: '',
    type: 'PRIVATE',
  })
  const [displayEmailTemplateList, setDisplayEmailTemplateList] = useState<
    IEmailTemplate[] | undefined
  >(undefined)
  const [typeEmailTemplate, setTypeEmailTemplate] = useState<string>('all')
  const [showModal, setShowModal] = useState<boolean>(false)
  const deleteMutation = UseDeleteEmailTemplate()

  useEffect(() => {
    if (emailTemplates?.data?.length) {
      setDisplayEmailTemplateList(emailTemplates?.data)
    }
  }, [emailTemplates])

  useEffect(() => {
    if (typeEmailTemplate === 'all') {
      setDisplayEmailTemplateList(emailTemplates?.data)
    }

    if (typeEmailTemplate === 'public') {
      const result = emailTemplates?.data?.filter(
        (template: IEmailTemplate) => template.type === 'PUBLIC',
      )
      setDisplayEmailTemplateList(result)
    }

    if (typeEmailTemplate === 'private') {
      const result = emailTemplates?.data?.filter(
        (template: IEmailTemplate) => template.type === 'PRIVATE',
      )
      setDisplayEmailTemplateList(result)
    }
  }, [typeEmailTemplate, emailTemplates, setDisplayEmailTemplateList])

  const handleUpdate = (item: IEmailTemplate) => {
    if (item.type === 'PRIVATE') {
      setShowListEmailTemplateModal(false)
      setShowNewEmailTemplateModal(true)
      setEmailTemplateSelected(item)
    }
  }

  const handleDeleteModal = (item: IEmailTemplate) => {
    if (item.type === 'PRIVATE' && item.id) {
      setSelectedItem(item)
      setShowModal(true)
    }
  }

  const handleDeleteModalClose = () => {
    setSelectedItem({
      body: '',
      description: '',
      id: '',
      subject: '',
      type: 'PRIVATE',
    })
    setShowModal(false)
  }

  const handleDelete = async () => {
    selectItem?.id && (await deleteMutation.mutateAsync(selectItem?.id))
    refetch()
    setShowModal(false)
  }

  const handleInsert = (strictItem?: IEmailTemplate) => {
    const { body, subject } = strictItem || selectItem

    const newEmailBody = `${body}`
    setSubject(subject)
    setEmailBody(newEmailBody)
    setShowManagerEmailTemplateModal(false)
  }

  const handleTypeEmailTemplate = (event: string) => {
    setTypeEmailTemplate(event)
  }

  const searchIntoTemplates = (value: string) => {
    if (value === '') {
      if (typeEmailTemplate === 'all') {
        setDisplayEmailTemplateList(emailTemplates?.data)
      }

      if (typeEmailTemplate === 'public') {
        const result = emailTemplates?.data?.filter(
          (template: IEmailTemplate) => template.type === 'PUBLIC',
        )
        setDisplayEmailTemplateList(result)
      }

      if (typeEmailTemplate === 'private') {
        const result = emailTemplates?.data?.filter(
          (template: IEmailTemplate) => template.type === 'PRIVATE',
        )
        setDisplayEmailTemplateList(result)
      }
    } else {
      const result = displayEmailTemplateList?.filter(
        (template: IEmailTemplate) =>
          template.subject.toLowerCase().includes(value) ||
          template.description.toLowerCase().includes(value),
      )
      setDisplayEmailTemplateList(result)
    }
  }

  const debounceSearch = useDebounce(searchIntoTemplates, 600)

  const handleSearch = (value: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(value.target.value)
  }

  const handleNewTemplate = () => {
    setShowListEmailTemplateModal(false)
    setShowNewEmailTemplateModal(true)
  }

  return (
    <Modal
      id="manage-template-modal"
      onClose={() => setShowManagerEmailTemplateModal(false)}
    >
      <div className={styles.modalContainer}>
        <div className={styles.title}>Manage Templates</div>
        <div className={styles.containerInputs}>
          <Select
            name="template"
            placeholder="Select template"
            options={option}
            onChangeMultipleValues={handleTypeEmailTemplate}
            onChange={handleTypeEmailTemplate}
            value={typeEmailTemplate}
            hideErrorMessage
          />
          <InputSearch
            placeholder="Search"
            size="middle"
            name="search-template"
            onChange={handleSearch}
          />
          <PolicyCheckHOC policyName="is_create_email_template_enabled">
            <Button onClick={handleNewTemplate} icon="Plus" size="small">
              New Template
            </Button>
          </PolicyCheckHOC>
        </div>
        <Table className={styles.tableContainer}>
          {displayEmailTemplateList?.map((item: IEmailTemplate) => (
            <Table.Row
              className={cx(
                selectItem?.id === item.id && styles.active,
                styles.row,
              )}
              key={item.id}
            >
              <Table.Column title="Name" showMobile>
                {item.subject}
              </Table.Column>

              <Table.Column title="Description" showMobile>
                {item.description}
              </Table.Column>

              <Table.Column title="Folder">
                {item.type === 'PUBLIC' ? 'Public Folder' : 'Private Folder'}
              </Table.Column>

              <Table.Column>
                <div className={styles.actionColumn}>
                  <PolicyCheckHOC policyName="is_update_email_template_enabled">
                    <Icons.Edit
                      className={cx(
                        item.type === 'PUBLIC'
                          ? styles.notClick
                          : styles.EditIcon,
                      )}
                      width={40}
                      onClick={() => handleUpdate(item)}
                    />
                  </PolicyCheckHOC>
                  <PolicyCheckHOC policyName="is_remove_email_template_enabled">
                    <Icons.Trash
                      width={30}
                      height={18}
                      className={cx(
                        item.type === 'PUBLIC'
                          ? styles.notClick
                          : styles.redIcon,
                      )}
                      onClick={() => handleDeleteModal(item)}
                    />
                  </PolicyCheckHOC>
                  <Button onClick={() => handleInsert(item)} size="small">
                    Insert
                  </Button>
                </div>
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </div>
      {showModal && (
        <ModalResult
          isSuccess={false}
          title="Delete Template"
          description="Are you sure you want to delete this template?"
          onClose={handleDeleteModalClose}
          handleOnClickButton={handleDelete}
          showButtons={true}
          textSuccessButton="Delete"
        />
      )}
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ListEmailTemplateModal,
  'is_get_email_templates_enabled',
)
