import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonNotesParams {
  personId?: string
  orderBy?:
    | 'createdAt DESC'
    | 'createdAt ASC'
    | 'updatedAt DESC'
    | 'updatedAt ASC'
}

const useGetPersonNotes = (params: GetPersonNotesParams) => {
  const { personId, orderBy } = params

  return useQuery({
    queryKey: [`getNote`, personId, orderBy],
    queryFn: async () =>
      await api.get(`/v3/person/note/${personId}`, {
        params: {
          orderBy,
        },
      }),
  })
}

export default useGetPersonNotes
