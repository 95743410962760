export const LOCAL_STORAGE_NAME = '____WORKER_360____'

export const LOCAL_STORAGE_LOGO = '__WORKER_360__LOGO__'

export const LEAD_SOURCE = 'Driver360-AdminPanel'

export const NOTE_TITLE = 'Lead note'

export const DEFAULT_DOCUMENT_TITLE = 'Driver 360 Portal'

export const PHONE_NUMBER_FOR_APPOINTMENTS = '833-814-8590'

export const PFML_ENROLLMENT_ABC_ID = '4853a62a-898e-4eb8-b723-1e40c37e66b2'
