import cx from 'classnames'
import { useState } from 'react'

import {
  ROUTES_MENU_ITEMS_BENESTREAM,
  ROUTES_SETTINGS_MENU_ITEMS,
} from 'src/benestream/config'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import RoutesMenu from './Menu'
import UserInformation from './UserInformation/UserInformation'
import logoSmallImg from './assets/benestream-small.png'
import logoImg from './assets/benestream.png'
import styles from './sidebar.module.scss'

const Sidebar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  const logo = isSidebarCollapsed ? logoSmallImg : logoImg

  return (
    <div
      className={cx(
        styles.wrapper,
        isSidebarCollapsed && styles.collapsedWrapper,
      )}
    >
      <div className={cx(styles.inner, isSidebarCollapsed && styles.collapsed)}>
        <div onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
          {isSidebarCollapsed ? (
            <img className={styles.logo} src={logo} />
          ) : (
            <img className={styles.logo} src={logo} />
          )}
        </div>

        <RoutesMenu
          menuItems={ROUTES_MENU_ITEMS_BENESTREAM}
          isSidebarCollapsed={isSidebarCollapsed}
        />

        <RoutesMenu
          menuItems={ROUTES_SETTINGS_MENU_ITEMS}
          borderBottom={false}
          showLogoutButton={true}
          isSidebarCollapsed={isSidebarCollapsed}
        />

        <UserInformation isSidebarCollapsed={isSidebarCollapsed} />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Sidebar,
  'is_enable_benestream_sidebar',
  true,
)
