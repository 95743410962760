import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Notifications, {
  INotification,
  NotificationOptions,
} from '../Notifications'

declare global {
  //TODO Better
  interface Window {
    showNotification: any
    clearNotification: any
  }
}

interface INotificationContext {
  state: { [key: string]: INotification }
  show: (
    type: string,
    content: string | React.ReactNode,
    options?: NotificationOptions,
  ) => void
  clear: (id: string) => void
  clearAll: () => void
}

export const NotificationContext = createContext<
  INotificationContext | undefined
>(undefined)

export const useNotifications = () => {
  const context = useContext(NotificationContext)

  return context
}

const reducer = (state: { [key: string]: INotification }, action: any) => {
  switch (action.type) {
    case 'addNotification':
      return { [action.newNotification.id]: action.newNotification, ...state }
    case 'removeNotification':
      return (() => {
        const newNotifications = { ...state }
        delete newNotifications[action.id]
        return newNotifications
      })()
    case 'clearAll':
      return {}
    default:
      return state
  }
}

export const NotificationProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const [state, dispatch] = useReducer(reducer, {})

  const show = (
    type: string,
    content: string | React.ReactNode,
    options?: NotificationOptions,
  ) => {
    const id = options?.id || uuidv4()
    dispatch({
      type: 'addNotification',
      newNotification: {
        id,
        type,
        content,
        options,
      },
    })
  }

  useEffect(() => {
    window.showNotification = show //TODO better
    window.clearNotification = clear
  }, [])

  const clear = (id: string) => {
    const newNotifications = { ...state }
    delete newNotifications[id]

    dispatch({ type: 'removeNotification', id })
  }

  const clearAll = () => dispatch({ type: 'clearAll' })

  return (
    <NotificationContext.Provider
      value={{
        state,
        show,
        clear,
        clearAll,
      }}
    >
      {children}
      <Notifications />
    </NotificationContext.Provider>
  )
}
