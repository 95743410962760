import { useState } from 'react'

//import type { Quill } from 'quill'
import QuillEditor from 'src/common/components/form/QuillEditor'
import { BASIC_QUILL_EDITOR_CONFIG } from 'src/common/config'
import { useUploadCmsFile } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import MutateBenefitsAdministrationItemQuillWidthModal from './MutateBenefitsAdministrationItemQuillWidthModal'

interface MutateBenefitsAdministrationItemQuillProps {
  type: 'private' | 'public'
  language: string
}

const BENEFIT_QUILL_CONFIG = {
  ...BASIC_QUILL_EDITOR_CONFIG,
  toolbar: {
    ...BASIC_QUILL_EDITOR_CONFIG.toolbar,
    container: [...BASIC_QUILL_EDITOR_CONFIG.toolbar.container, ['image']],
  },
}

const MutateBenefitsAdministrationItemQuill = (
  props: MutateBenefitsAdministrationItemQuillProps,
) => {
  const { type, language } = props

  const [modalInitialData, setModalInitialData] = useState<{
    width: number
    height: number
  } | null>(null)
  const [modalCallback, setModalCallback] = useState<
    ((dimensions: { width: number; height: number }) => Promise<any>) | null
  >(null)

  const uploadFileMutation = useUploadCmsFile()

  const handleCloseModal = () => {
    setModalInitialData(null)
    setModalCallback(null)
  }

  const handlers = {
    image: ($editor: any) => {
      //TODO ts
      if (typeof document !== 'undefined') {
        const $filePseudoInput = document.createElement('input')
        $filePseudoInput.type = 'file'
        $filePseudoInput.accept = 'image/*'
        $filePseudoInput.multiple = false

        $filePseudoInput.onchange = async (event) => {
          const { files } = event.target as HTMLInputElement
          const file = files?.[0]
          if (file) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (event) => {
              const image = new Image()
              image.src = event.target?.result as string
              image.onload = async () => {
                const { width, height } = image
                setModalInitialData({ width, height })

                setModalCallback(
                  () =>
                    async ({
                      width,
                      height,
                    }: {
                      width: number
                      height: number
                    }) => {
                      await uploadFileMutation
                        .mutateAsync({ file })
                        .then((result) => {
                          //TODO better way to get the image url from the response
                          const imageUrl =
                            result?.request.responseURL.split('?')[0]

                          const range = $editor.getSelection(true)
                          $editor.insertEmbed(range.index, 'image', imageUrl)
                          $editor.formatText(
                            range.index,
                            range.index + 1,
                            'width',
                            `${width}px`,
                          )
                          $editor.formatText(
                            range.index,
                            range.index + 1,
                            'height',
                            `${height}px`,
                          )

                          handleCloseModal()
                        })
                    },
                )
              }
            }
          }
        }

        $filePseudoInput.click()
      }
    },
  }

  return (
    <>
      <QuillEditor
        key={`content.${type}.${language}`}
        required
        modules={BENEFIT_QUILL_CONFIG}
        handlers={handlers}
        name={`content.${type}.${language}`}
        label="Benefit Description"
        placeholder="Enter benefit description"
      />

      {modalInitialData && modalCallback ? (
        <MutateBenefitsAdministrationItemQuillWidthModal
          initialValues={modalInitialData}
          onSubmit={modalCallback}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationItemQuill,
  'is_enable_mutate_benefits_administration_item_quill',
  true,
)
