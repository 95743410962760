import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IGetMetroplusRepresentativeParams {
  personId?: string
}

const useGetMetroplusRepresentative = (
  params: IGetMetroplusRepresentativeParams,
) => {
  const { personId } = params

  return useQuery({
    queryKey: ['getMetroplusRepresentative', personId],
    queryFn: async () =>
      await api.get(`/metro-plus/get-metroplus-send-to/${personId}`),
  })
}

export default useGetMetroplusRepresentative
