import { useFormik } from 'formik'
import { useState } from 'react'

import {
  BasicBlock,
  Breadcrumbs,
  Button,
  Checkbox,
  Form,
  Input,
} from 'src/common/components'
import { yup } from 'src/common/libs'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'
import { useMutateEmailDomain } from 'src/default/hooks'
import { IEmailDomain } from 'src/v2/commons/interface/membershipEmailDomain'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './create-email-domain-page.module.scss'

const CreateEmailDomainPage = () => {
  const mutationEmailDomain = useMutateEmailDomain('create')
  const [result, setResult] = useState<string | null>(null)
  const router = useRouter()

  const formik = useFormik({
    initialValues: {
      domain: '',
      isWBFRegister: false,
    },
    onSubmit: async (values: IEmailDomain) => {
      mutationEmailDomain
        .mutateAsync(values)
        .then(() => {
          setResult('success')
        })
        .catch(() => {
          setResult('error')
        })
    },
    validationSchema: yup.object().shape({
      domain: yup.string().required('Organization is required'),
      isWBFRegister: yup.boolean().required('Is WBF Register is required'),
    }),
  })

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.EMAIL_DOMAINS.path)
    } else {
      setResult(null)
    }
  }

  const handleClose = () => {
    router.push(PRIVATE_ROUTES.EMAIL_DOMAINS.path)
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Create Email Domain' }]} />

      <BasicTemplate>
        <BasicBlock title="Create Email Domain">
          <Form providerValue={formik}>
            <div className={styles.wrapperForm}>
              <Input
                width="50"
                name="domain"
                label="Email Address - Domain"
                required
              />
              <Checkbox
                width="50"
                name="isWBFRegister"
                label="Is WBF Register"
              />

              <div className={styles.buttonsWrapper}>
                <Button
                  className={styles.cancelButton}
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  type="submit"
                  onClick={() => formik.submitForm()}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>

          {result !== null ? (
            <ModalResult
              isSuccess={result === 'success'}
              description={result !== 'success' ? result : undefined}
              onClose={handleCloseResult}
            />
          ) : null}
        </BasicBlock>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateEmailDomainPage,
  'is_create_email_domain_enabled',
)
