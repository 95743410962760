export const createCsv = (
  headers: string[],
  data: Array<{ id: string; message: string }>,
  fileName?: string,
) => {
  // Convert the data array into a CSV string
  const csvString = [
    headers, // Specify your headers here
    ...data.map((item) => [item.id, item.message]), // Map your data fields accordingly
  ]
    .map((row) => row.join(','))
    .join('\n')

  const blob = new Blob([csvString], { type: 'text/csv' })

  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName || 'download.csv'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
