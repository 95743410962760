import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import { Icons, Portal } from 'src/common/components'

import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './tabs.module.scss'

interface TabProps {
  id: string
  title: string
}

const Tab = (props: React.PropsWithChildren<TabProps>) => {
  const { children } = props

  return <>{children}</>
}

interface TabsProps {
  className?: string
  show?: boolean
  hideNavigation?: boolean
  value?: string
  onChange?: (id: string | null) => void
  onClick?: (id: string) => void | boolean
}

const Tabs = (props: React.PropsWithChildren<TabsProps>) => {
  const {
    className,
    show,
    hideNavigation,
    value,
    onClick,
    onChange,
    children,
  } = props
  const items = ((Array.isArray(children) ? children : [children]) || [])
    .flat()
    .filter((item) => item)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [currentTabId, setCurrentTabId] = useState<string | null>(
    items.length ? items[0].props.id : null,
  )

  useEffect(() => {
    if (typeof value === 'undefined') {
      if (onChange) {
        onChange(currentTabId)
      }
    }
  }, [currentTabId])

  const handleTabClick = (tabId: string) => {
    if (onClick) {
      const result = onClick(tabId)

      if (result === false) {
        return false
      }
    }

    if (typeof value === 'undefined') {
      setCurrentTabId(tabId)
    }
  }

  const currentId = typeof value === 'undefined' ? currentTabId : value

  const currentItem = items.find((item) => item.props.id === currentId)

  return (
    <div
      className={cx(styles.wrapper, styles[`${currentTheme}Slice`], className)}
    >
      <div className={cx(styles.header, hideNavigation && styles.hidden)}>
        {items.map((item) => (
          <React.Fragment key={item.props.id}>
            {!hideNavigation ? (
              <div
                className={cx(
                  item.props.id === currentItem.props.id && show
                    ? styles.active
                    : '',
                )}
                onClick={() => handleTabClick(item.props.id)}
              >
                {item.props.title}

                <div className={styles.arrowWrapper}>
                  <Icons.ArrowSelect />
                </div>
              </div>
            ) : null}

            <Portal.Target
              className={cx(
                styles.currentItem,
                hideNavigation && styles.hidden,
              )}
              id={`currentTabContent_${item.props.id}`}
              media="mobile"
            />
          </React.Fragment>
        ))}
      </div>

      <Portal.Target
        className={cx(styles.currentItem, hideNavigation && styles.hidden)}
        id={`currentTabContent_${currentId}`}
        media="desktop"
      />

      {currentItem && show ? (
        <Portal.Wrapper id={`currentTabContent_${currentId}`} width={992}>
          {currentItem.props.children}
        </Portal.Wrapper>
      ) : null}
    </div>
  )
}

Tabs.Item = Tab

export default Tabs
