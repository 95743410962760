import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonParams {
  personId?: string
}

const useGetPerson = (params: GetPersonParams) => {
  const { personId } = params

  return useQuery({
    queryKey: ['getBenestreamPerson', personId],
    queryFn: async () => {
      return await api.get(`v2/benestream/persons/${personId}/`)
    },
  })
}

export default useGetPerson
