import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import CreateNewEmail from './CreateNewEmail/CreateNewEmail'
import SuccessSendEmail from './SuccessSendEmail/SuccessSendEmail'
import ViewEmail from './ViewEmail/ViewEmail'
import styles from './email-sender.module.scss'

const EmailSender = () => {
  const { isNewEmail, isSuccessSendEmail, isViewEmail } =
    useEmailSenderAndManagerEmailTemplateContext()

  return (
    <div className={styles.wrapper}>
      {isNewEmail && <CreateNewEmail />}
      {isSuccessSendEmail && <SuccessSendEmail />}
      {isViewEmail && <ViewEmail />}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EmailSender,
  'is_enable_email_sender',
  true,
)
