import { createTheme } from '@mantine/core'

import componentsOverrides from '../overrides'

export const benestreamTheme = createTheme({
  colors: {
    primary: [
      '#87A099',
      '#7E968F',
      '#768D85',
      '#0F4033',
      '#657971',
      '#5F8077',
      '#52705D',
      '#456254',
      '#37544A',
      '#0D352B',
    ],
    secondary: [
      '#F4B9A6',
      '#F2B09B',
      '#F0A790',
      '#EE9E85',
      '#EC957A',
      '#ED8A6A',
      '#EB805E',
      '#E9724C',
      '#D56647',
      '#C25F3F',
    ],
    red: [
      '#FFF5F5',
      '#FFE3E3',
      '#FFC9C9',
      '#FFA8A8',
      '#FF8787',
      '#FF6B6B',
      '#FA5252',
      '#F03E3E',
      '#E53E3E',
      '#C92A2A',
    ],
    gray: [
      '#FCFDFF',
      '#F4F4F4',
      '#E6E8EC',
      '#A3AAB8',
      '#C3CBDD',
      '#8A909E',
      '#BBC2D3',
      '#A3AAB8',
      '#444955',
      '#21252C',
      '#EAECF0',
    ],
    neutral: [
      '#FCFCFF',
      '#EDF0F5',
      '#DFE3EB',
      '#D1D7E1',
      '#C3CBDD',
      '#AEB8C9',
      '#9AA5B5',
      '#8A909E',
      '#666666',
      '#444955',
    ],
  },
  primaryColor: 'primary',
  primaryShade: { light: 3, dark: 7 },
  white: '#FFFFFF',
  black: '#000000',
  fontFamily: '"SFProText", sans-serif',
  headings: {
    fontFamily: '"SFProText", sans-serif',
  },

  components: {
    ...componentsOverrides,
  },
})
