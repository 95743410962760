import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'

import { useUpdateRoleMutation } from '../models/role.api'
import { IUpdateRoleResponse } from '../models/role.model'

export const useUpdateRoleUseCase = ({
  id,
  name,
}: {
  id: string
  name?: string
}): IUpdateRoleResponse => {
  const [updateRole, { isLoading }] = useUpdateRoleMutation()

  const roleUpdateForm = useForm<{ name: string }>({
    mode: 'uncontrolled',
    initialValues: {
      name: name ?? 'no',
    },
    validateInputOnBlur: true,
    validate: {
      name: isNotEmpty('Name is required!'),
    },
  })

  const onSubmit = () => {
    roleUpdateForm.onSubmit(async (values) => {
      updateRole({ name: values.name, id })
        .unwrap()
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'Role succesfuly created',
          })
        })
        .catch(() => {
          notifications.show({
            title: 'Oops',
            message: 'There was an error',
            color: 'red',
          })
        })
    })()
  }

  return {
    roleUpdateForm,
    onSubmit,
    isLoading,
  }
}
