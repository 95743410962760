import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

export enum MembershipEmailDomainType {
  SEND = 'SEND',
  REPLY = 'REPLY',
  BOTH = 'BOTH',
}

interface IMembershipEmailDomain {
  membershipId: string
  emailDomainId: string
  name: string
  signatureTemplate: string
  type: MembershipEmailDomainType
}

const useMutateMembershipEmailDomain = () =>
  useMutation({
    mutationFn: async (data: IMembershipEmailDomain) =>
      api.post(`/membership-domain`, data),
  })

export default useMutateMembershipEmailDomain
