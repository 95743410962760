import { IEligibility } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from '../main-information.module.scss'

//TODO realize can be it dynamic and if not - move to config as constans
const ID_METLIFE_BENEFIT = 'e24da618-bd74-4e7b-9e58-5236a840ee87'

interface AccountMainInformationProps {
  eligibilities: IEligibility[]
}

const AccountMainInformation = (props: AccountMainInformationProps) => {
  const { eligibilities } = props

  const metlifeID = (() => {
    const metlifeEligible = eligibilities?.find(
      (eligibility) => eligibility?.benefit?.id === ID_METLIFE_BENEFIT,
    )

    if (metlifeEligible) {
      return (!metlifeEligible.providerExternalId || metlifeEligible.providerExternalId?.includes('mock'))
        ? 'Pending'
        : metlifeEligible.providerExternalId
    }

    return null
  })()

  return (
    <>
      {metlifeID !== null ? (
        <>
          <div>
            <div className={styles.title}>Metlife ID:</div>
            <div>{metlifeID}</div>
          </div>

          <div className={styles.divider} />
        </>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AccountMainInformation,
  'is_enable_account_main_information',
  true,
)
