import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { BasicBlock, Button } from 'src/common/components'
import { useGetPersonNotes, useWindowSize } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import PersonNotesList from './PersonNotesList'
import { PersonNoteForm } from './components'
import styles from './person-notes-block.module.scss'

interface PersonNoteBlock {
  setIsLeavingTheRecordWithoutNote: (value: boolean) => void
}

const PersonNotesBlock = (props: PersonNoteBlock) => {
  const { setIsLeavingTheRecordWithoutNote } = props
  const { personId } = useParams<{ personId: string }>()

  const [showNewNote, setShowNewNote] = useState<boolean>(false)

  const windowSize = useWindowSize()

  const {
    data: { data: notes } = { data: {} },
    isSuccess,
    isLoading,
    refetch,
  } = useGetPersonNotes({
    personId,
    orderBy: 'createdAt DESC',
  })

  const handleCreateNote = () => {
    setShowNewNote(true)
  }

  const handleAfterSave = () => {
    setIsLeavingTheRecordWithoutNote(false)
    setShowNewNote(false)
    refetch()
  }

  const newButtonSize = windowSize.width > 420 ? 'small' : 'micro'

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        isLoading={isLoading}
        title="Notes"
        titleClassName={styles.title}
        headerClassName={styles.header}
        header={
          !showNewNote ? (
            <PolicyCheckHOC policyName="is_add_person_note_enabled">
              <Button
                className={styles.newButton}
                icon="Plus"
                onClick={handleCreateNote}
                size={newButtonSize}
              >
                {`New ${newButtonSize === 'small' ? ' Note' : ''} `}
              </Button>
            </PolicyCheckHOC>
          ) : null
        }
      >
        {isSuccess && !showNewNote ? (
          <PersonNotesList notes={notes.records} refetch={refetch} />
        ) : null}

        {showNewNote ? (
          <PersonNoteForm
            onAfterSave={handleAfterSave}
            onCancel={() => setShowNewNote(false)}
          />
        ) : null}
      </BasicBlock>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonNotesBlock,
  'is_enable_person_notes_view',
  true,
)
