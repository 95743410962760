import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Checkbox,
  Form,
  Icons,
  Select,
  SubmitButton,
} from 'src/common/components'
import {
  useGetMetroPlusSendToRecords,
  useGetMetroplusRepresentative,
  useMutateSendMetroplus,
} from 'src/common/hooks'
import { yup } from 'src/common/libs'
//TODO: move to common
import { ModalResult } from 'src/default/components'
import { basicApiErrorHandling } from 'src/default/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import MetroPlusHistoryRecords, {
  IMetroPlusHistoryRecord,
} from './MetroPlusHistoryRecords/MetroPlusHistoryRecords'
import MetroPlusSuccess from './MetroPlusSuccess'
import { MetroplusRepresentative } from './metroplus.interface'
import styles from './metroplus.module.scss'

interface MetroPlusProps {
  personType: 'lead' | 'account'
}

const MetroPlus = (props: MetroPlusProps) => {
  const { personType } = props

  const { personId } = useParams<{ personId: string }>()

  const { data: metroplusRepresentatives, refetch } =
    useGetMetroplusRepresentative()

  const { data: { data: historyRecords } = {} } = useGetMetroPlusSendToRecords({
    personId,
  })

  const sendMetroplus = useMutateSendMetroplus()

  const [metroplusId, setMetroplusId] = useState('')
  const [resultError, setResultError] = useState<string | null>(null)

  const handleCloseResult = () => {
    setResultError(null)
  }

  const formik = useFormik({
    initialValues: {
      representative: '',
    },
    onSubmit: async (values) => {
      sendMetroplus
        .mutateAsync({
          personId: personId ?? '',
          email: values.representative.toLowerCase(),
        })
        .then((response) => {
          setMetroplusId(response.data.metroPlusLeadId)
        })
        .catch((response) => {
          setResultError(basicApiErrorHandling(response))
        })
    },
    validationSchema: yup.object().shape({
      representative: yup.string().email(),
      confirm: yup.bool().required(),
    }),
  })

  return (
    <div>
      <BasicBlock
        className={styles.wrapper}
        title="Send to Metroplus"
        paddings="small"
        isLoading={false}
        isShowWrapper={false}
      >
        {metroplusId ? (
          <MetroPlusSuccess
            metroplusid={metroplusId}
            personType={personType}
            email={formik.values.representative}
          />
        ) : (
          <>
            <div className={styles.description}>
              Please confirm that you would like to send this record to
              MetroPlus!
            </div>

            <Form providerValue={formik} className={styles.formWrapper}>
              <Select
                className={styles.select}
                name="representative"
                label="Representatives"
                options={
                  Array.isArray(metroplusRepresentatives?.data)
                    ? metroplusRepresentatives?.data?.map(
                        (item: MetroplusRepresentative) => ({
                          label: item.eMail.toLowerCase(),
                          value: item.eMail,
                        }),
                      )
                    : []
                }
                placeholder="Select Representative"
                tabletWidth="50"
              />

              <Checkbox
                classNameWrapper={styles.checkbox}
                name="confirm"
                label="Confirm"
                tabletWidth="50"
                required
              />

              <PolicyCheckHOC policyName="is_send_person_to_metroplus_enabled">
                <SubmitButton
                  className={styles.sendButton}
                  loading={sendMetroplus.isPending}
                  size="small"
                >
                  Send
                </SubmitButton>
              </PolicyCheckHOC>
            </Form>

            <MetroPlusHistoryRecords
              historyRecords={
                (historyRecords as IMetroPlusHistoryRecord[]) || []
              }
            />
          </>
        )}
        {resultError !== null ? (
          <ModalResult
            isSuccess={false}
            description={resultError}
            onClose={handleCloseResult}
          />
        ) : null}
      </BasicBlock>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MetroPlus,
  'is_get_metroplus_reps_enabled',
)
