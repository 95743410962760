import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RoleModel } from './role.model'

interface IRoleSchema {
  selectedRoleInformation: RoleModel
  allRoles: RoleModel[]
}

const initialState: IRoleSchema = {
  selectedRoleInformation: {
    createdAt: new Date(),
    deletedAt: new Date(),
    updatedAt: new Date(),
    id: '',
    name: '',
    policies: [
      {
        createdAt: new Date(),
        updatedAt: new Date(),
        id: '',
        isDeprecated: false,
        name: '',
        description: '',
      },
    ],
  },
  allRoles: [],
}

const roleStore = createSlice({
  name: 'role',
  initialState: initialState,
  reducers: {
    setAllRoles: (state, action: PayloadAction<RoleModel[]>) => {
      state.allRoles = [...action.payload]
    },
    resetAllRoles: (state) => {
      state.allRoles = []
    },
    setRole: (state, action: PayloadAction<RoleModel>) => {
      state.selectedRoleInformation = { ...action.payload }
    },
    resetRoleInformation: (state) => {
      state.selectedRoleInformation = {
        ...initialState.selectedRoleInformation,
      }
    },
  },
})

export const { setRole, resetRoleInformation, setAllRoles, resetAllRoles } =
  roleStore.actions

export default roleStore.reducer
