import React from 'react'

import { Button, Icons, Modal } from 'src/common/components'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useRouter } from '../../../helpers/navigation'
import styles from './success-delete-person-modal.module.scss'

interface ISuccessDeletePersonProps {
  onClose: () => void
}

const SuccessDeletePersonModal = (props: ISuccessDeletePersonProps) => {
  const router = useRouter()

  const { onClose } = props

  const handleOnClose = () => {
    router.push(PRIVATE_ROUTES.LEADS.path)
    onClose()
  }

  return (
    <Modal
      id="successDeletePersonModal"
      template="big"
      className={styles.successDeleteModalWrapper}
      onClose={handleOnClose}
    >
      <header className={styles.modalHeader}>
        <div className={styles.iconContainer}>
          <Icons.Check />
        </div>
      </header>
      <section className={styles.modalBody}>
        <p>
          <strong>Record Deleted Successfully!</strong>
        </p>
      </section>
      <footer className={styles.modalFooter}>
        <Button onClick={handleOnClose}>Return to Homepage</Button>
      </footer>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SuccessDeletePersonModal,
  'is_enable_success_delete_person_modal',
  true,
)
