import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Icons, Modal } from 'src/common/components'
import { PFML_ENROLLMENT_ABC_ID } from 'src/default/config'
import { useMutatePersonEnrollment } from 'src/default/hooks'
import {
  EnumEnrollmentActiveStatus,
  EnumEnrollmentInactiveStatus,
  IEnrollment,
} from 'src/default/interfaces'

import styles from './single-account-enrollment-disable-action.module.scss'

interface ISingleAccountEnrollmentDisableAction {
  enrollment?: IEnrollment
  refetchEnrollments: () => Promise<any>
}

const SingleAccountEnrollmentDisableAction = (
  props: ISingleAccountEnrollmentDisableAction,
) => {
  const { enrollment, refetchEnrollments } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const personEnrollmentMutation = useMutatePersonEnrollment('update')
  const { personId } = useParams<{ personId: string }>()

  const isPFMLEnrollment =
    enrollment?.associatedBenefitContract.id === PFML_ENROLLMENT_ABC_ID

  const isActiveEnrollment = Object.values(EnumEnrollmentActiveStatus).includes(
    enrollment?.status as EnumEnrollmentActiveStatus,
  )

  const handleDisable = async () => {
    await personEnrollmentMutation.mutateAsync(
      {
        status: EnumEnrollmentInactiveStatus.INACTIVE,
        isActive: false,
        isVerified: enrollment?.isVerified,
        reasonsForDeactivation: enrollment?.statusReason,
        startDate: enrollment?.startDate || undefined,
        expirationDate: enrollment?.expirationDate || undefined,
        associatedBenefitContractId: enrollment?.associatedBenefitContract.id,
        attachments: enrollment?.attachments,
        id: enrollment?.id,
        personId,
      },
      {
        onSuccess: () => {
          refetchEnrollments()
          setIsModalOpen(false)
        },
      },
    )
  }

  return (
    <>
      <button
        className={styles.wrapperButtonIcon}
        onClick={() => setIsModalOpen(true)}
        disabled={!isPFMLEnrollment || !isActiveEnrollment}
      >
        <Icons.FlagCircle />
      </button>

      {isModalOpen ? (
        <Modal
          id="disable-enrollment"
          template="small"
          onClose={() => setIsModalOpen(false)}
        >
          <div className={styles.wrapperModal}>
            <div className={styles.icon}>
              <Icons.FlagCircle />
            </div>

            <div className={styles.title}>Disable Enrollment</div>

            <div className={styles.description}>
              Are you sure you want to disable this enrollment? This action
              cannot be undone.
            </div>

            <div className={styles.footer}>
              This will remove this record from the CSV file to ESD
            </div>

            <div className={styles.buttons}>
              <Button
                disabled={personEnrollmentMutation.isPending}
                size="small"
                template="secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>

              <Button
                size="small"
                loading={personEnrollmentMutation.isPending}
                onClick={handleDisable}
              >
                Disable
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default SingleAccountEnrollmentDisableAction
