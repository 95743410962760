import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateDocument = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (
      data: any, //TODO ts
    ) =>
      api[action === 'update' ? 'patch' : 'post']('/v2/person/documents', data),
  })

export default useMutateDocument
