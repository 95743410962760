import cx from 'classnames'

import { Icons } from 'src/common/components'

import { useAppSelector } from '../../../../v2/store/hooks'
import BasicBlock from '../BasicBlock/BasicBlock'
import styles from './card-button.module.scss'

interface CardButtonProps {
  id: string
  icon: string
  label: string
  onClick: (id: string) => void
  isActive: boolean
}

const CardButton = (props: CardButtonProps) => {
  const { icon, label, onClick, isActive, id } = props

  const IconComponent =
    icon && (Object.entries(Icons)?.find(([key]) => key === icon)?.[1] ?? null)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div onClick={() => onClick(id)}>
      <BasicBlock
        className={cx(
          styles.cardButton,
          isActive && styles.active,
          styles[`${currentTheme}Slice`],
        )}
      >
        {IconComponent ? (
          <span className={cx(styles.icon)}>
            <IconComponent />
          </span>
        ) : null}
        <div className={cx(styles.label)}>{label}</div>
      </BasicBlock>
    </div>
  )
}

export default CardButton
