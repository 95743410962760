import { AxiosError } from 'axios'
import cx from 'classnames'

import {
  IPersonIncome,
  IncomePeriod,
  IncomeSource,
} from 'src/benestream/interfaces'
import { Button, Icons, Table } from 'src/common/components'
import { useDeletePersonHouseholder } from 'src/common/hooks'

import { BenestreamPersonFormHouseholdIncomeValue } from '../components'
import styles from './benestream-person-form-household-income-table.module.scss'

const getIncomeSourceLabel = (incomeSource: IncomeSource) => {
  return (
    Object.entries(IncomeSource).find(
      ([, value]) => value === incomeSource,
    )?.[0] ?? ''
  )
}

const getIncomePeriodLabel = (incomePeriod: IncomePeriod) => {
  return (
    Object.entries(IncomePeriod).find(
      ([, value]) => value === incomePeriod,
    )?.[0] ?? ''
  )
}

interface BenestreamPersonFormHouseholdIncomeTableProps {
  data: IPersonIncome[]
  personName: string
  totalIncome: number
  onNew: () => void
  setDeleteHouseholdMember: (id: string) => void
  selectHouseholdMember: (householdMember: IPersonIncome) => void
}

const BenestreamPersonFormHouseholdIncomeTable = (
  props: BenestreamPersonFormHouseholdIncomeTableProps,
) => {
  const {
    data,
    personName,
    totalIncome,
    onNew,
    setDeleteHouseholdMember,
    selectHouseholdMember,
  } = props

  const showDeleteMessage = (id: string) => {
    setDeleteHouseholdMember(id)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button className={styles.newButton} icon="Plus" onClick={onNew}>
          New Source of Income
        </Button>
      </div>

      <div className={styles.inner}>
        <Table>
          {data.map((income: IPersonIncome) => (
            <Table.Row key={income.id}>
              <Table.Column title="Household Member">{personName}</Table.Column>

              <Table.Column title="Income Source">
                {getIncomeSourceLabel(income.incomeSource)}
              </Table.Column>

              <Table.Column title="Amount">{`$${Number(income.amount).toFixed(
                2,
              )}`}</Table.Column>

              <Table.Column title="Period">
                {getIncomePeriodLabel(income.period)}
              </Table.Column>

              <Table.Column title="">
                <div className={styles.iconsWrapper}>
                  <Icons.Edit
                    className={styles.icon}
                    onClick={() => selectHouseholdMember(income)}
                  />
                  <Icons.Trash
                    className={cx(styles.icon, styles.iconTrash)}
                    onClick={() => showDeleteMessage(income.id)}
                  />
                </div>
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </div>

      <BenestreamPersonFormHouseholdIncomeValue
        className={styles.incomeTotal}
        value={totalIncome}
      />
    </div>
  )
}

export default BenestreamPersonFormHouseholdIncomeTable
