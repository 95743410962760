import cx from 'classnames'
import React from 'react'

import { Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import styles from './document-modal-result.module.scss'

interface DocumentModalResultProps extends IModal {
  title?: string
  description?: string | React.ReactNode
}

const DocumentModalResult = (props: DocumentModalResultProps) => {
  const { title, description, ...rest } = props

  return (
    <Modal id="modalResultConvertLead" {...rest}>
      <div className={styles.wrapper}>
        <div className={cx(styles.icon, styles.error)}>
          <Icons.Warning />
        </div>

        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </Modal>
  )
}

export default DocumentModalResult
