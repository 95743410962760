import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetEmailDomains = () => {
  return useQuery({
    queryKey: ['getEmailDomains'],
    queryFn: async () => await api.get(`/email-domains`),
  })
}

export default useGetEmailDomains
