import { isDevEnv } from 'src/common/helpers'
import { EnumRouteCondition, IUser, IUserProfile } from 'src/common/interfaces'
import {
  DRIVERS_UNION_ID,
  NYC_ORGANIZATION_ID,
  UNION360,
} from 'src/default/config'

import { useAppSelector } from '../../../v2/store/hooks'

//TODO: verified where we use this function and remove from the other places
export const checkAreRouteConditionsNegative = (
  conditions: EnumRouteCondition[],
  currentUser: IUserProfile | null,
  currentOrganizationId: string | null,
) => {
  return (
    conditions.findIndex((condition) => {
      if (currentUser) {
        switch (condition) {
          case EnumRouteCondition.IDG_NY_ONLY:
            return currentOrganizationId !== NYC_ORGANIZATION_ID

          case EnumRouteCondition.DU_ONLY:
            return currentOrganizationId !== DRIVERS_UNION_ID

          case EnumRouteCondition.WBF_DOMAIN_ONLY:
            return !currentUser.user.email.includes('@workersbenefitfund.com')

          case EnumRouteCondition.DEV_ONLY:
            return !isDevEnv()

          case EnumRouteCondition.UNION360_ONLY:
            return currentOrganizationId !== UNION360

          default:
            return false
        }
      } else {
        return true
      }
    }) !== -1
  )
}

export const checkRouteHavePolicy = (routePolicy: string | null) => {
  const { policies } = useAppSelector((state) => state.user.userProfile)
  if (routePolicy === null) {
    return true
  }
  if (policies.includes(routePolicy)) {
    return true
  }
  return false
}
