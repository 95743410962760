import {
  Anchor,
  Button,
  Container,
  Flex,
  Image,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { Navigate } from 'react-router-dom'

import logoImg from 'src/assets/WBF_Logo_Color.png'
import { PUBLIC_ROUTES } from 'src/common/config'
import { useRouter } from 'src/v2/commons/utils/navigation'
import { useAppSelector } from 'src/v2/store/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import useLoginFormContainer from '../containers/useLoginFormContainer'

const LoginView = () => {
  const { getFormProps, onSubmit, loginMutationStatus } =
    useLoginFormContainer()
  const logo = useAppSelector((state) => state.ui.organizationLogo) ?? logoImg
  const router = useRouter()

  const handleRedirectRoute = (path: string) => {
    router.push(path, false)
  }

  if (loginMutationStatus.isSuccess) {
    return <Navigate to="/select-organization" replace />
  }

  return (
    <Container>
      <Stack align="center" mb={34}>
        <Image src={logo} alt="Logo" w={120} mb={14} />
        <Title order={1}>Welcome back!</Title>
        <Text ta="center">
          Enter your registered email and password to login into your account
        </Text>
      </Stack>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <TextInput
          id="email"
          label="Email"
          placeholder="Enter Email Address"
          size="lg"
          mb={32}
          {...getFormProps('email')}
        />
        <PasswordInput
          id="password"
          label="Password"
          placeholder="Enter Password"
          size="lg"
          {...getFormProps('password')}
        />
        <Flex justify="flex-end" mb={29}>
          <Anchor
            onClick={() => handleRedirectRoute(PUBLIC_ROUTES.FORGOT.path)}
            c="gray"
          >
            <Text size="sm" fw={500}>
              Forgot your password?
            </Text>
          </Anchor>
        </Flex>
        <Button
          type="submit"
          size="lg"
          loading={loginMutationStatus.isLoading}
          fullWidth
        >
          Login
        </Button>
      </form>
    </Container>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LoginView,
  'is_enable_login_view',
  true,
)
