import { Input } from 'src/common/components'
import { IField } from 'src/common/interfaces'

const InputTLCNumber = (props: IField) => {
  return (
    <Input
      {...props}
      label="TLC Number"
      placeholder="_ _ _ _ _ _ _"
      maxLength={7}
    />
  )
}

export default InputTLCNumber
