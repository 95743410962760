import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutationGenerateEmail = () =>
  useMutation({
    mutationFn: async () => {
      const response = await api.get('/persons/generate-email')
      return response.data
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useMutationGenerateEmail
