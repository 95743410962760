import * as Yup from 'yup'

const buttonSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  link: Yup.string().required('Required'),
  useNewTab: Yup.boolean().required('Required'),
})

const buttonsArraySchema = Yup.array()
  .of(buttonSchema)
  .min(2, 'At least two buttons are required')
  .max(2, 'Only two buttons are allowed')
  .required('Required')

const getButtonsValidationSchema = (actualLangs: string[]) =>
  Yup.object().shape(
    actualLangs.reduce((acc, language) => {
      acc[language] = buttonsArraySchema
      return acc
    }, {} as { [key: string]: any }),
  )

const getContentValidationSchema = (actualLangs: string[]) =>
  Yup.object().shape(
    actualLangs.reduce((acc, language) => {
      acc[language] = Yup.string().required('Required')
      return acc
    }, {} as { [key: string]: any }),
  )

const getValidationSchema = Yup.lazy((values) => {
  const actualLangs = Object.keys(values.metaData.name)
  const actualLangsWithoutEn = actualLangs.filter((lang) => lang !== 'en')

  return Yup.object({
    content: Yup.object({
      public: getContentValidationSchema(actualLangs),
      private: getContentValidationSchema(actualLangs),
    }),
    metaData: Yup.object({
      name: getContentValidationSchema(actualLangs),
    }),
    buttons: getButtonsValidationSchema(actualLangsWithoutEn),
    icons: Yup.object({
      public: Yup.string().required('Required'),
    }),
  })
})

export default getValidationSchema
