import { useFormik, useFormikContext } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { IBenestreamPerson, IPersonHousehold } from 'src/benestream/interfaces'
import {
  Button,
  Form,
  Icons,
  Input,
  Loader,
  SubmitButton,
} from 'src/common/components'
import {
  useGetPersonHousehold,
  useMutatePersonHousehold,
} from 'src/common/hooks'
import { IPerson } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import BenestreamPersonFormHouseholdIncomeInformation from '../BenestreamPersonFormHouseholdIncomeInformation/BenestreamPersonFormHouseholdIncomeInformation'
import BenestreamPersonFormBenefits from './BenestreamPersonFormBenefits'
import BenestreamPersonFormHouseholdInformation from './BenestreamPersonFormHouseholdInformation'
//TODO move to benestream
import BenestreamPersonFormHouseholdTable from './BenestreamPersonFormHouseholdTable'
import styles from './benestream-person-form-household.module.scss'

interface BenestreamPersonFormHouseholdFormProps {
  isNew?: boolean
  initialValues?: IPersonHousehold
  onSuccess: () => void
  refetchHouseHold: () => void
  setResult?: () => void
  refetchBenestreamPerson: () => void
}

const BenestreamPersonFormHouseholdForm = (
  props: BenestreamPersonFormHouseholdFormProps,
) => {
  const {
    isNew,
    initialValues = {
      birthDate: '',
      CHIPRenewalDate: '',
      MARenewalDate: '',
      nyepRenewalDate: '',
    },
    onSuccess,
    refetchHouseHold,
    refetchBenestreamPerson,
  } = props

  const { personId: primaryPersonId } = useParams<{ personId: string }>()

  const householdMutation = useMutatePersonHousehold(
    isNew ? 'create' : 'update',
  )

  const formik = useFormik({
    initialValues: !isNew
      ? {
          ...initialValues,
          birthDate: dayjs
            .tz(initialValues.birthDate, 'UTC')
            .format('MM/DD/YYYY'),
          CHIPRenewalDate: dayjs
            .tz(initialValues?.CHIPRenewalDate, 'UTC')
            .isValid()
            ? dayjs
                .tz(initialValues?.CHIPRenewalDate, 'UTC')
                .format('MM/DD/YYYY')
            : '',
          MARenewalDate: dayjs.tz(initialValues?.MARenewalDate, 'UTC').isValid()
            ? dayjs.tz(initialValues?.MARenewalDate, 'UTC').format('MM/DD/YYYY')
            : '',
          nyepRenewalDate: dayjs
            .tz(initialValues?.nyepRenewalDate, 'UTC')
            .isValid()
            ? dayjs
                .tz(initialValues?.nyepRenewalDate, 'UTC')
                .format('MM/DD/YYYY')
            : '',
        }
      : {
          firstName: '',
          lastName: '',
          birthDate: '',
          relationToApplicant: '',
          type: 'dependent',
          primaryPersonId,
          CHIPRenewalDate: '',
          MARenewalDate: '',
          nyepRenewalDate: '',
        },
    onSubmit: async (values) => {
      const payload = isNew
        ? values
        : (() => {
            const {
              updatedAt,
              deletedAt,
              householdMonthlyIncome,
              monthlyIncome,
              agreeToPrivacyWaiver,
              primaryPersonId,
              primaryPerson,
              ...rest
            } = values as any //TODO ts

            return {
              ...rest,
              CHIPRenewalDate: rest.CHIPRenewalDate || undefined,
              MARenewalDate: rest.MARenewalDate || undefined,
              nyepRenewalDate: rest.nyepRenewalDate || undefined,
            }
          })()

      if (!payload.CHIPRenewalDate) {
        delete payload.CHIPRenewalDate
      }

      if (!payload.MARenewalDate) {
        delete payload.MARenewalDate
      }

      if (!payload.nyepRenewalDate) {
        delete payload.nyepRenewalDate
      }
      delete payload.sfdcId

      await householdMutation
        .mutateAsync({
          ...payload,
        })
        .then(() => {
          onSuccess()
          refetchBenestreamPerson()
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
      birthDate: yup
        .string()
        .date({
          format: 'MM/DD/YYYY',
        })
        .required('Required'),
      relationToApplicant: yup.string().required('Required'),
    }),
  })

  return (
    <Form providerValue={formik}>
      <BenestreamPersonFormHouseholdInformation
        isNew={isNew}
        onSuccess={onSuccess}
        refetchHouseHold={refetchHouseHold}
        refetchBeneStreamPerson={refetchBenestreamPerson}
      />

      <BenestreamPersonFormBenefits />

      <div className={styles.bottomButtons}>
        <SubmitButton
          className={styles.saveButton}
          iconAfter="ArrowSelect"
          loading={formik.isSubmitting}
        >
          Save
        </SubmitButton>
      </div>
    </Form>
  )
}

interface BenestreamPersonFormHouseholdProps {
  refetchEligibilityGuideline?: () => void
  refetchPerson: () => void
  onResult: (error: string) => void
  savePersonInformation: () => void
  personIsLoading?: boolean
}

const BenestreamPersonFormHousehold = (
  props: BenestreamPersonFormHouseholdProps,
) => {
  const {
    refetchEligibilityGuideline,
    refetchPerson,
    onResult,
    savePersonInformation,
    personIsLoading,
  } = props

  const { personId: primaryPersonId } = useParams<{ personId: string }>()

  const [newHousehold, setNewHousehold] = useState(false)
  const [selectedHouseholdId, setSelectedHouseholdId] = useState<string | null>(
    null,
  )
  const [isHouseholdSizeChange, setIsHouseholdSizeChange] = useState(false)

  const { values, setFieldValue } = useFormikContext<IBenestreamPerson>()

  const personHousehold = useGetPersonHousehold({ primaryPersonId })
  const data = personHousehold.data?.data?.records ?? []

  const handleNewHousehold = () => {
    setNewHousehold(true)
    setSelectedHouseholdId(null)
  }

  const handleSelectHouseHold = (householdId: string) => {
    setNewHousehold(false)
    setSelectedHouseholdId(householdId)
  }

  const handleRefetch = () => {
    personHousehold.refetch()

    refetchPerson()

    if (refetchEligibilityGuideline) {
      refetchEligibilityGuideline()
    }
  }

  const handleNewSuccess = () => {
    setNewHousehold(false)
    handleRefetch()
  }

  const handleSuccess = () => {
    personHousehold.refetch()
  }

  const handleSaveHouseholdSize = () => {
    savePersonInformation()
    setTimeout(() => {
      setIsHouseholdSizeChange(false)
    }, 500)
  }

  const handleOnChangeHouseholdSize = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue('householdSize', event.target.value)
    setIsHouseholdSizeChange(true)
  }

  const selectedHousehold = data.find(
    (item: IPersonHousehold) => item.id === selectedHouseholdId,
  )

  return (
    <>
      <div>
        <div className={styles.title}>
          <div className={styles.icon}>
            <Icons.ArrowSelect />
          </div>
          Household Income Information
        </div>
        <BenestreamPersonFormHouseholdIncomeInformation
          householdIncome={values.householdIncome || 0}
        />
      </div>
      <div>
        <div className={styles.title}>
          <div className={styles.icon}>
            <Icons.ArrowSelect />
          </div>
          Household Members Information
        </div>
        <div className={styles.header}>
          <div className={styles.householdSizeWrapper}>
            <Input
              required
              name="householdSize"
              label="Household Size"
              placeholder="Enter the household size"
              tabletWidth="50"
              type="number"
              onChange={(e) => handleOnChangeHouseholdSize(e)}
              disableOnWheel
            />
            {isHouseholdSizeChange ? (
              <Button
                className={styles.householdSizeButton}
                onClick={handleSaveHouseholdSize}
                disabled={values.householdSize <= 0 || personIsLoading}
              >
                Save
              </Button>
            ) : (
              <div className={styles.householdSizeButton} />
            )}
          </div>
          <Button
            className={styles.addNewButton}
            icon="Plus"
            onClick={handleNewHousehold}
          >
            New Household Member
          </Button>
        </div>
      </div>

      {!personHousehold.isFetching ? (
        <div className={styles.inner}>
          <BenestreamPersonFormHouseholdTable
            data={data}
            selectedHouseholdId={selectedHouseholdId}
            onSelect={handleSelectHouseHold}
            onResult={onResult}
            refetch={handleRefetch}
          />

          {newHousehold ? (
            <>
              <div className={styles.newHouseholdTitle}>
                New Household Member
              </div>

              <BenestreamPersonFormHouseholdForm
                isNew
                onSuccess={handleNewSuccess}
                refetchHouseHold={personHousehold.refetch}
                refetchBenestreamPerson={handleRefetch}
              />
            </>
          ) : null}

          {selectedHousehold ? (
            <BenestreamPersonFormHouseholdForm
              key={`form${selectedHouseholdId}`}
              initialValues={{ ...selectedHousehold }}
              onSuccess={handleSuccess}
              refetchHouseHold={personHousehold.refetch}
              refetchBenestreamPerson={handleRefetch}
            />
          ) : null}

          {!newHousehold && !selectedHousehold ? (
            <div className={styles.empty} />
          ) : null}
        </div>
      ) : (
        <Loader isLoading />
      )}
    </>
  )
}

export default BenestreamPersonFormHousehold
