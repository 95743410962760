import { useForm } from '@mantine/form'
import { FormErrors, GetInputProps } from '@mantine/form/lib/types'
import { useState } from 'react'

import { useUploadAttachment } from '../../../../common/hooks'
import {
  useRunProcessMutation,
  useSaveFileMutation,
} from '../models/RunProcess.api'
import { IRunProcessItem } from '../models/RunProcess.model'

const useRunProcessUseCase = (
  initialItem: IRunProcessItem,
): {
  getFormProps: GetInputProps<any>
  onSubmit: () => void
  form: any
  status: string
  formErrors: FormErrors
  isLoading: boolean
} => {
  const [runProcess, { isLoading, isSuccess }] = useRunProcessMutation()
  const uploadFileMutation = useUploadAttachment({
    attachmentType: 'person-file',
  })
  const [saveFileMutation] = useSaveFileMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm<any>({
    mode: 'uncontrolled',
    initialValues: {},
    validateInputOnBlur: false,
    validate: initialItem?.parameters?.reduce((acc, parameter) => {
      if (parameter.validate) {
        acc[parameter.name] = parameter.validate
      }
      return acc
    }, {} as Record<string, any>),
  })

  const onSubmit = () => {
    if (isSubmitting) return

    form.onSubmit(async (values) => {
      setIsSubmitting(true)
      try {
        if (values?.file) {
          const s3File = await uploadFileMutation.mutateAsync({
            file: values?.file,
          })

          const fileURL = s3File?.request.responseURL.split('?')[0]

          if (fileURL && values?.saveFile && values?.fileSource) {
            await saveFileMutation({
              source: values.fileSource,
              url: fileURL,
              title: values?.file?.name,
              type: values?.file?.type,
            })
          }

          if (fileURL) {
            values.file = undefined
            values.fileSource = undefined
            values.saveFile = undefined
            values.fileKey = fileURL
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsSubmitting(false)
      }

      runProcess({ item: initialItem, values })
        .unwrap()
        .catch((error) => {
          if (error?.data?.error) {
            form.setErrors({ message: error?.data?.error })
          }
        })
    })()
  }

  return {
    getFormProps: form.getInputProps,
    form,
    onSubmit: onSubmit,
    status: isSuccess ? 'success' : 'other',
    formErrors: form.errors,
    isLoading: isLoading || isSubmitting,
  }
}

export default useRunProcessUseCase
