import DeactivationBenefitCaseInformation from './DeactivationBenefitCaseInformation'
import DeactivationBenefitCaseURL from './DeactivationBenefitCaseURL'
import DeactivationBenefitDriverInformation from './DeactivationBenefitDriverInformation'
import DeactivationBenefitHistory from './DeactivationBenefitHistory'
import DeactivationBenefitNotes from './DeactivationBenefitNotes'

const DeactivationBenefitCaseBlocks = [
  DeactivationBenefitDriverInformation,
  DeactivationBenefitCaseURL,
  DeactivationBenefitCaseInformation,
  DeactivationBenefitNotes,
  DeactivationBenefitHistory,
]

export default DeactivationBenefitCaseBlocks
