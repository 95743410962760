import { Button, Flex, Tooltip } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useNavigate } from 'react-router-dom'

import { Icons } from 'src/common/components/ui'

import { RecordInformationFormValues } from '../../models/merging-tool.model'
import styles from './submit-merge.module.scss'

interface SubmitMergeProps {
  recodrInformationForm: UseFormReturnType<RecordInformationFormValues | {}>
  onSubmit: (
    values: RecordInformationFormValues,
    primaryEntityId: string,
  ) => void
  isLoading: boolean
  primaryDuplicateId: string
}

const SubmitMergeComponent = ({
  recodrInformationForm,
  onSubmit,
  isLoading,
  primaryDuplicateId,
}: SubmitMergeProps) => {
  const navigate = useNavigate()

  return (
    <form
      onSubmit={recodrInformationForm.onSubmit((values) =>
        onSubmit(values, primaryDuplicateId),
      )}
    >
      <Flex justify="space-between" mt="30px">
        <Button
          variant="default"
          className={styles['button-cancel']}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Tooltip
          label="please choose primary duplicate"
          events={{
            hover: !primaryDuplicateId && true,
            focus: false,
            touch: false,
          }}
        >
          <Button
            classNames={{ root: styles['button-save'] }}
            rightSection={<Icons.ArrowRight />}
            type="submit"
            data-disabled={!primaryDuplicateId || isLoading}
            disabled={!primaryDuplicateId || isLoading}
          >
            Merge
          </Button>
        </Tooltip>
      </Flex>
    </form>
  )
}

export default SubmitMergeComponent
