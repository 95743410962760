import { useState } from 'react'

import { Icons } from 'src/common/components'
import { IDocument } from 'src/default/interfaces'

import { SingleAccountDocumentsModal } from '../../../components'
import styles from './single-account-documents-edit-action.module.scss'

interface SingleAccountDocumentsEditActionProps {
  data: IDocument
  refetch: () => Promise<any>
}

const SingleAccountDocumentsEditAction = (
  props: SingleAccountDocumentsEditActionProps,
) => {
  const { data, refetch } = props

  const [documentModalIsOpen, setDocumentModalIsOpen] = useState(false)

  return (
    <>
      <div
        className={styles.wrapper}
        onClick={() => setDocumentModalIsOpen(true)}
      >
        <Icons.Eye />
      </div>

      {documentModalIsOpen ? (
        <SingleAccountDocumentsModal
          initialData={data}
          refetch={refetch}
          onClose={() => setDocumentModalIsOpen(false)}
        />
      ) : null}
    </>
  )
}

export default SingleAccountDocumentsEditAction
