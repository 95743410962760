import { BenestreamPersonFormBlock } from 'src/benestream/components'
import {
  Input,
  InputDateAndAge,
  InputPhone,
  Select,
  SelectGender,
  SelectLanguage,
} from 'src/common/components'
import { MultiSelectCarCompanies, OtherEmail } from 'src/default/components'
import { UNION360 } from 'src/default/config'
import { useGetTagsBlackCarFund } from 'src/default/hooks'
import { HEALTHCARE_RADIO_OPTIONS } from 'src/default/pages/Leads/CreateLeadPage/CreateLeadForm/Steps/MainStep/CreateLeadHealthcare/CreateLeadHealthcareRadios/CreateLeadHealthcareRadios'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../../v2/commons/HOC/policyCheckHOC'
import { checkPolicyUtil } from '../../../../../../../v2/commons/utils/permissions/checkPolicyUtil'
import { useAppSelector } from '../../../../../../../v2/store/hooks'
import styles from './contact-information.module.scss'

interface IContactInformation {
  showUpdatePerson: boolean
  policies: string[]
}

const ContactInformation = (props: IContactInformation) => {
  const { showUpdatePerson, policies } = props
  const { data: { data: blackCarFundList } = { data: [] } } =
    useGetTagsBlackCarFund()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  return (
    <BenestreamPersonFormBlock
      className={styles.wrapper}
      title="Contact Information"
    >
      <Input
        inputClassName={!showUpdatePerson ? 'active' : ''}
        disabled={showUpdatePerson}
        required
        name="firstName"
        label="First Name"
        tabletWidth="50"
      />

      <Input
        inputClassName={!showUpdatePerson ? 'active' : ''}
        disabled={showUpdatePerson}
        required
        name="lastName"
        label="Last Name"
        tabletWidth="50"
      />

      <SelectGender
        inputClassName={!showUpdatePerson ? 'active' : ''}
        disabled={showUpdatePerson}
        name="gender"
        label="Gender"
        placeholder="Select Gender"
        tabletWidth="50"
      />

      <InputDateAndAge
        inputClassName={!showUpdatePerson ? 'active' : ''}
        disabled={showUpdatePerson}
        name="birthDate"
        label="Birthdate"
        tabletWidth="50"
      />

      <PolicyCheckHOC
        policyName="is_enable_email_edit"
        defaultComponent={
          <Input
            disabled
            required
            name="email"
            label="Main Email"
            tabletWidth="50"
          />
        }
      >
        <Input
          inputClassName={!showUpdatePerson ? 'active' : ''}
          disabled={showUpdatePerson}
          required
          name="email"
          label="Main Email"
          tabletWidth="50"
        />
      </PolicyCheckHOC>

      <PolicyCheckHOC policyName="is_enable_other_email">
        <OtherEmail
          inputClassName={!showUpdatePerson ? 'active' : ''}
          disabled={
            showUpdatePerson ||
            !checkPolicyUtil(policies, 'is_enable_email_edit')
          }
          name="otherEmail"
          label="Secondary Emails"
        />
      </PolicyCheckHOC>

      <InputPhone
        inputClassName={!showUpdatePerson ? 'active' : 'null'}
        disabled={showUpdatePerson}
        required
        name="phoneNumber"
        label="Main Mobile Phone"
        tabletWidth="50"
      />

      <InputPhone
        inputClassName={!showUpdatePerson ? 'active' : 'null'}
        disabled={showUpdatePerson}
        name="otherPhoneNumber"
        label="Secondary Mobile Phone"
        tabletWidth="50"
      />

      <SelectLanguage
        inputClassName={!showUpdatePerson ? 'active' : 'null'}
        disabled={showUpdatePerson}
        name="preferredLanguage"
        label="Preferred Language"
        placeholder="Select Preferred Language"
        tabletWidth="50"
      />

      <MultiSelectCarCompanies
        disabled={showUpdatePerson}
        name="companies"
        label="Which Company(s) do you work for?"
        tabletWidth="50"
      />

      <PolicyCheckHOC policyName="is_get_black_car_fund_tags_enabled">
        <Select
          inputClassName={!showUpdatePerson ? 'active' : 'null'}
          disabled={showUpdatePerson}
          options={blackCarFundList.map((blackCarFund: any) => ({
            label: blackCarFund.name,
            value: blackCarFund.id,
          }))}
          name="blackCarFund"
          label="Black Car Member Bases"
          tabletWidth="50"
        />
      </PolicyCheckHOC>

      <PolicyCheckHOC policyName="is_rating_enabled">
        <Select
          inputClassName={!showUpdatePerson ? 'active' : 'null'}
          disabled={showUpdatePerson}
          options={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ]}
          name="rating"
          label="Person Rating"
          tabletWidth="50"
        />
      </PolicyCheckHOC>

      {UNION360 !== currentOrganizationId && (
        <PolicyCheckHOC policyName="is_enable_view_health_screening">
          <>
            <div className={styles.title}>Healthcare Screening</div>
            <Select
              inputClassName={!showUpdatePerson ? 'active' : 'null'}
              disabled={showUpdatePerson}
              options={HEALTHCARE_RADIO_OPTIONS}
              name="haveHealthInsurance"
              label="Do you have Health Insurance?"
              tabletWidth="50"
            />

            <Select
              inputClassName={!showUpdatePerson ? 'active' : 'null'}
              disabled={showUpdatePerson}
              options={HEALTHCARE_RADIO_OPTIONS}
              name="coverageThroughTheState"
              label="Is your coverage through the state?"
              tabletWidth="50"
            />
            <Select
              inputClassName={!showUpdatePerson ? 'active' : 'null'}
              disabled={showUpdatePerson}
              options={HEALTHCARE_RADIO_OPTIONS}
              name="pay20PerMonth"
              label="Do you pay over 20 dollars a month?"
              tabletWidth="50"
            />
          </>
        </PolicyCheckHOC>
      )}
    </BenestreamPersonFormBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ContactInformation,
  'is_enable_contact_information',
  true,
)
