import { useState } from 'react'

import ForgotEmailSentView from '../views/ForgotEmailSentView'
import { ForgotView } from '../views/ForgotView'

const ForgotViewWrapper = () => {
  const [displaySendMessage, setDisplaySendMessage] = useState(false)

  return (
    <>
      {!displaySendMessage ? (
        <ForgotView onSent={() => setDisplaySendMessage(true)} />
      ) : (
        <ForgotEmailSentView
          onTryAnother={() => setDisplaySendMessage(false)}
        />
      )}
    </>
  )
}

export default ForgotViewWrapper
