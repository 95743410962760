import { Dispatch } from '@reduxjs/toolkit'

import { navigationState } from 'src/default/helpers/navigation'

import { PRIVATE_ROUTES as privateRoutesBenestream } from '../../../benestream/config'
import {
  PUBLIC_ROUTES,
  PRIVATE_ROUTES as privateRoutesCommon,
} from '../../../common/config'
import {
  ALL_ORGANIZATION_NAMES,
  PRIVATE_ROUTES as privateRoutesDefault,
} from '../../../default/config'
import { cleanStringToBeUrlReadable } from '../../../default/helpers/clean-string'
import { AuthModel } from '../../domains/auth/models/auth.model'
import { UserProfile } from '../../domains/user/models/user.model'
import { RedirectAction } from '../interfaces/action.interface'
import { RootState } from '../store'

const redirectMiddleware =
  (state: RootState) =>
  (next: Dispatch<RedirectAction>) =>
  (action: RedirectAction) => {
    const userProfile = state.getState().user.userProfile
    const session = state.getState().auth

    if (action.type.toUpperCase().includes('REDIRECT')) {
      if (action.payload.hardRedirect) {
        window.location.href = action.payload.path
        return
      }
      navigationState.navigate?.(action.payload.path)
      return
    }

    if (action.type.toUpperCase().includes('CHECK_ROUTE')) {
      const redirectPath = handleRequest(
        window.location.href,
        session,
        userProfile,
      )
      if (redirectPath) {
        navigationState.navigate?.(redirectPath)
        return
      }
    }

    return next(action)
  }

const handleRequest = (
  url: string,
  session: AuthModel,
  userProfile: UserProfile,
): string => {
  const privateRoutes = {
    ...privateRoutesBenestream,
    ...privateRoutesCommon,
    ...privateRoutesDefault,
  }

  const parsedUrl = new URL(url)
  const paths = parsedUrl.pathname.split('/').filter(Boolean)
  const currentOrganizationId = paths[0]

  const publicRoutesPath = Object.values(PUBLIC_ROUTES).map((item) => item.path)
  const privateRoutesPath = Object.values(privateRoutes).map(
    (item) => item.path,
  )

  if (
    !session.accessToken &&
    !publicRoutesPath.some((path) => url.includes(path))
  ) {
    return '/login'
  }

  if (
    session.accessToken &&
    userProfile.organization.id === '' &&
    !url.includes('select-organization')
  ) {
    return '/select-organization'
  }

  if (
    session.accessToken &&
    userProfile.organization.id &&
    (publicRoutesPath.some((path) => url.includes(path)) ||
      url.includes('select-organization'))
  ) {
    return `/${cleanStringToBeUrlReadable(userProfile.organization.name)}/leads`
  }

  if (
    session.accessToken &&
    userProfile.organization.id &&
    privateRoutesPath.some((path) => url.includes(path))
  ) {
    if (!ALL_ORGANIZATION_NAMES.includes(currentOrganizationId)) {
      const pathname = paths.slice(1).join('/')
      return `/${cleanStringToBeUrlReadable(
        userProfile.organization.name,
      )}/${pathname}`
    }
  }

  if (parsedUrl.pathname === '/') {
    return session.accessToken
      ? `/${cleanStringToBeUrlReadable(userProfile.organization.name)}/leads`
      : '/login'
  }

  return ''
}

export default redirectMiddleware
