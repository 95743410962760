import { Select } from '@mantine/core'
import { GetInputPropsReturnType } from '@mantine/form/lib/types'

import { Icons } from 'src/common/components'

import classes from './filter-select.module.scss'

interface SelectFilterProps extends GetInputPropsReturnType {
  placeholder: string
  options: { value: string; label: string }[]
}

const SelectFilter = ({
  options,
  placeholder,
  ...props
}: SelectFilterProps) => {
  return (
    <Select
      rightSection={<Icons.ArrowSelect />}
      data={options}
      placeholder={placeholder}
      comboboxProps={{
        withinPortal: false,
        position: 'bottom',
        middlewares: { flip: false, shift: false },
        offset: 0,
        transitionProps: { transition: 'scale-y', duration: 300 },
      }}
      withScrollArea={false}
      {...props}
      classNames={{
        input: classes.input,
        section: classes.section,
        dropdown: classes.dropdown,
      }}
    />
  )
}

export default SelectFilter
