import { BenestreamPersonFormBlock } from 'src/benestream/components'
import { Input, InputDate, Select } from 'src/common/components'

const BenestreamPersonFormEmployer = () => {
  return (
    <BenestreamPersonFormBlock title="Employer Information">
      <Input
        disabled
        name="companyName"
        label="Company Name"
        tabletWidth="50"
      />

      <Input
        name="worksite"
        label="Worksite"
        placeholder="Enter Worksite"
        tabletWidth="50"
      />

      <Input
        name="managerName"
        label="Manager Name"
        placeholder="Enter Manager Name"
        tabletWidth="50"
      />

      <Select
        name="isNewHire"
        label="New Hire"
        placeholder="Select an option"
        options={[
          { value: '1', label: 'Yes' },
          { value: '0', label: 'No' },
        ]}
        tabletWidth="50"
      />

      <InputDate
        name="dateOfHire"
        label="Date of Hire"
        placeholder="Select Date of Hire"
        tabletWidth="50"
      />

      <Select
        name="isFullTime"
        label="Full Time"
        placeholder="Select an option"
        options={[
          { value: '1', label: 'Yes' },
          { value: '0', label: 'No' },
        ]}
        tabletWidth="50"
      />

      <Input
        name="shift"
        label="Shift"
        placeholder="Enter Shift"
        tabletWidth="50"
      />

      <Select
        name="onCompanyPlan"
        label="On Company Plan?"
        placeholder="Select an option"
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]}
        tabletWidth="50"
      />

      <Input
        name="medicalTier"
        label="Medical Tier"
        placeholder="Enter Medical Tier"
        tabletWidth="50"
      />

      <Input
        name="employeeId"
        label="Employee ID"
        placeholder="Enter Employee ID"
        tabletWidth="50"
      />

      <Input
        name="severancePayment"
        label="Severance Payment"
        placeholder="Enter Severance Payment"
        tabletWidth="50"
      />

      <InputDate
        name="layoffDate"
        label="Layoff Date"
        placeholder="Select Layoff Date"
        tabletWidth="50"
      />
    </BenestreamPersonFormBlock>
  )
}

export default BenestreamPersonFormEmployer
