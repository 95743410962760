import { useDisclosure } from '@mantine/hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  EmptyPersons,
  ErrorBlock,
  Table,
  TableLeadStatus,
} from 'src/common/components'
import Checkbox from 'src/common/components/ui/Checkbox/Checkbox'
import { PAGINATION_LIMIT } from 'src/common/config'
import {
  concatQueryPages,
  getOrganizationAndPersonTypeToNavigate,
  getPhoneWithMask,
  mapEnrollmentStatus,
  removeUnderScoreAndTransformToLowerCase,
  sortEnrollments,
} from 'src/common/helpers'
import { useSearchPerson } from 'src/common/hooks'
import { BasicTemplate, InfiniteBasicTemplate } from 'src/common/templates'
import { PRIVATE_ROUTES } from 'src/default/config'
//TODO move it to common because it is used like common
import { getLeadStatusColor, getPersonDocuments } from 'src/default/helpers'
import SuccessErrorModal from 'src/v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'
import useSearchContainer from 'src/v2/domains/person/containers/search/useSearch.container'
import {
  IPersonSearchResultItem,
  PersonModel,
} from 'src/v2/domains/person/models/person.model'
import AssignTaskModal from 'src/v2/domains/task/views/AssignTaskModal.view'
import FloatingTasksCard from 'src/v2/domains/task/views/FloatingTaskCard.view'

import { useRouter } from '../../../default/helpers/navigation'
import {
  setGlobalSearchChangeOrganization,
  setShowApproveToChangeOrganization,
} from '../../../v2/domains/organization/models/organization.store'
import { useAppDispatch, useAppSelector } from '../../../v2/store/hooks'
import styles from './search-result-template.module.scss'

interface SearchResultTemplateProps {
  isCrossOrganization?: boolean
  FilterComponent?: React.ReactNode
  isAssignMultipleTasksEnabled: boolean
}

const SearchResultTemplate = (props: SearchResultTemplateProps) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  //TODO move it to common because it is used like common

  const { isCrossOrganization, FilterComponent } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)
  const {
    selectedItems,
    handleAddRemoveSearch,
    handleSelectAllSearch,
    handleClearStoreSearch,
  } = useSearchContainer()

  const [
    assignTaskModalOpen,
    { open: openAssignTaskModal, close: closeAssignTaskModal },
  ] = useDisclosure(false)

  const [
    successTaskModalOpen,
    { open: openSuccessAssignTaskModal, close: closeSuccessAssignTaskModal },
  ] = useDisclosure(false)

  const currentOrganizationId = userProfile.organization.id
  const openFloatingMenu = selectedItems.length > 0
  const { searchValue } = useParams<{ searchValue: string }>()
  const { organizationsByUser } = useAppSelector((state) => state.organization)

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isFetching,
    isFetched,
  } = useSearchPerson({
    searchValue,
    organizationId: !isCrossOrganization ? currentOrganizationId : undefined,
    isCrossOrganization,
    limit: PAGINATION_LIMIT,
  })

  const handlePageScroll = async () => {
    await fetchNextPage()
  }
  const pageItems = concatQueryPages<IPersonSearchResultItem>(pages)

  const pageItemsIds = useMemo(
    () => pageItems.map((item) => item.id),
    [pageItems],
  )

  const isAllItemsSelected = useMemo(
    () =>
      pageItemsIds.every((selectedItem) =>
        selectedItems.includes(selectedItem),
      ),
    [pageItemsIds],
  )

  const title = `Search results for "${decodeURIComponent(searchValue ?? '')}"`

  if (!currentOrganizationId) {
    return (
      <BasicTemplate title={title}>
        <ErrorBlock.UserInformation />
      </BasicTemplate>
    )
  }

  const handleSelectItem = (item: IPersonSearchResultItem) => {
    const { anotherOrgId, path } = getOrganizationAndPersonTypeToNavigate(
      item,
      currentOrganizationId,
    )

    const newMembershipId = organizationsByUser
      .find((organization) => organization.id === anotherOrgId)
      ?.memberships?.at(0)?.id

    if (anotherOrgId && newMembershipId) {
      dispatch(
        setGlobalSearchChangeOrganization({
          newOrganizationId: anotherOrgId,
          newMembershipId,
          newPath: path,
        }),
      )
      dispatch(setShowApproveToChangeOrganization(true))
    } else {
      router.push(path)
    }
  }

  const handleSelectAll = (event: React.MouseEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    handleSelectAllSearch({
      isAllSelected: checked,
      allSearchResults: pageItemsIds,
    })
  }
  const isSearchSelected = (searchId: string) => {
    return !!selectedItems.find((selectedId) => selectedId === searchId)
  }

  const handleSelectSearch = (
    event: React.MouseEvent<HTMLInputElement>,
    lead: PersonModel['id'],
  ) => {
    const { checked } = event.currentTarget
    handleAddRemoveSearch(lead, checked)
  }

  const hasPolicies = [
    'is_create_task_enabled',
    'is_merge_persons_enabled',
    'is_create_multiple_task_enabled',
  ].some((policy) => userProfile.policies.includes(policy))

  return (
    <InfiniteBasicTemplate
      title={title}
      scrollPromise={handlePageScroll}
      isLoading={pages.length === 0 && isFetching}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: pageItems?.length ?? 0,
      }}
    >
      {FilterComponent ? FilterComponent : null}
      {openFloatingMenu && (
        <FloatingTasksCard
          location="search"
          selectedItems={selectedItems}
          openAssignTaskModal={() => {
            if (selectedItems.length > 0) {
              openAssignTaskModal()
            }
          }}
          isMergingToolActive={true}
          onDeselect={handleClearStoreSearch}
          isAssignMultipleTasksEnabled={props.isAssignMultipleTasksEnabled}
        />
      )}
      {assignTaskModalOpen && (
        <AssignTaskModal
          isOpen={assignTaskModalOpen}
          close={closeAssignTaskModal}
          selectedItems={selectedItems}
          pageFilters={{}}
          clearStore={handleClearStoreSearch}
          openSuccessAssignTaskModal={openSuccessAssignTaskModal}
        />
      )}
      {successTaskModalOpen && (
        <SuccessErrorModal
          status="success"
          title="Tasks created successfully"
          subTitle="Tasks were created and assigned correctly."
          buttonText="Review Tasks"
          opened={successTaskModalOpen}
          onClose={closeSuccessAssignTaskModal}
          path={PRIVATE_ROUTES.TASKS.path}
        />
      )}
      <BasicBlock paddings="big" isLoading={isFetching}>
        {pageItems.length ? (
          <Table>
            {pageItems.map((c: IPersonSearchResultItem) => (
              <Table.Row key={c.id} id={c.id} editable>
                {hasPolicies ? (
                  <Table.Column
                    title={
                      <Checkbox
                        onClick={handleSelectAll}
                        checked={isAllItemsSelected}
                      />
                    }
                  >
                    <Checkbox
                      onClick={(e) => handleSelectSearch(e, c.id)}
                      checked={isSearchSelected(c.id)}
                    />
                  </Table.Column>
                ) : null}
                <Table.Column
                  title="Name"
                  showMobile
                  onClick={() => handleSelectItem(c)}
                >
                  {`${c.firstName} ${c.lastName}`}
                </Table.Column>

                <Table.Column
                  title="ABC"
                  showMobile
                  onClick={() => handleSelectItem(c)}
                >
                  {sortEnrollments(c.enrollments)?.map((item) => (
                    <div className={styles.listItem} key={item.id}>
                      {item.associatedBenefitContract?.name || ''}
                    </div>
                  ))}
                </Table.Column>

                {isCrossOrganization ? (
                  <Table.Column
                    title="Organizations"
                    showMobile
                    onClick={() => handleSelectItem(c)}
                  >
                    {sortEnrollments(c.enrollments)
                      ?.map(
                        (item) =>
                          item.associatedBenefitContract?.organization?.name,
                      )
                      ?.filter(
                        (item, index, arr) =>
                          item && arr.indexOf(item) === index,
                      )
                      ?.map((itemName) => (
                        <div className={styles.listItem} key={itemName}>
                          {itemName}
                        </div>
                      ))}
                  </Table.Column>
                ) : null}

                <Table.Column
                  title="Status"
                  onClick={() => handleSelectItem(c)}
                >
                  {sortEnrollments(c.enrollments)?.map(
                    (
                      e: IPersonSearchResultItem['enrollments'][0],
                      i: number,
                    ) => (
                      <TableLeadStatus
                        key={i}
                        status={removeUnderScoreAndTransformToLowerCase(
                          mapEnrollmentStatus(
                            e.status,
                            e.associatedBenefitContract.organization.id,
                          ),
                        )}
                        color={getLeadStatusColor(
                          removeUnderScoreAndTransformToLowerCase(
                            mapEnrollmentStatus(
                              e.status,
                              e.associatedBenefitContract.organization.id,
                            ),
                          ),
                        )}
                      />
                    ),
                  )}
                </Table.Column>

                <Table.Column title="Type" onClick={() => handleSelectItem(c)}>
                  {sortEnrollments(c.enrollments)?.map((item) => (
                    <div className={styles.typeRow} key={item.id}>
                      <div className={styles.circle} />
                      {item.isVerified ? 'Account' : 'Lead'}
                    </div>
                  ))}
                </Table.Column>

                <Table.Column
                  title="Driver's License Number"
                  showMobile
                  onClick={() => handleSelectItem(c)}
                >
                  {getPersonDocuments(c.documents).driverLicense}
                </Table.Column>

                <Table.Column
                  title="Email"
                  showMobile
                  onClick={() => handleSelectItem(c)}
                >
                  {c.email}
                </Table.Column>

                <Table.Column
                  title="Mobile Phone"
                  showMobile
                  onClick={() => handleSelectItem(c)}
                >
                  {getPhoneWithMask(c.phoneNumber)}
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        ) : isFetched ? (
          <EmptyPersons term="record" />
        ) : null}
      </BasicBlock>
    </InfiniteBasicTemplate>
  )
}

export default SearchResultTemplate
