import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetDuplicatePersonsParams {
  personId: string
  params: {
    organizationId: string
    byEmail?: string
    byPhoneNumber?: string
    byTLC?: string
    byDL?: string
    limit?: string
    offset?: string
  }
}

const useGetDuplicatedPersons = (params: GetDuplicatePersonsParams) => {
  const { personId } = params

  const searchParams = new URLSearchParams(params.params)

  let url = `/persons/${personId}/duplicates?${searchParams}`

  return useQuery({
    queryKey: [`getDuplicatePersons`, url],
    queryFn: async () => await api.get(url),
  })
}

export default useGetDuplicatedPersons
