import { Button, Flex, Modal, Stack, Text } from '@mantine/core'

import { Icons } from 'src/common/components'

import styles from './delete-confirmation-modal.module.scss'

interface DeleteConfirmationModalProps {
  opened: boolean
  close: () => void
  title: string
  subtitle?: string
  body: string
  handleDelete: () => void
  buttonText: string
}

const DeleteConfirmationModal = ({
  opened,
  close,
  title,
  subtitle,
  body,
  handleDelete,
  buttonText,
}: DeleteConfirmationModalProps) => {
  const onDelete = () => {
    handleDelete()
    close()
  }

  return (
    <Modal
      opened={opened}
      trapFocus={false}
      onClose={close}
      centered
      classNames={{
        root: styles.root,
        content: styles['modal-content'],
        header: styles['modal-header'],
        title: styles['modal-title'],
        close: styles['modal-close'],
        body: styles['modal-body'],
      }}
      title={
        <Flex className={styles.icon}>
          <Icons.WarningRed />
        </Flex>
      }
    >
      <Stack justify="center" gap="35px" align="center">
        <Text
          fz="22px"
          lh="26px"
          fw="700"
          ta="center"
          c="var(--mantine-color-red-10)"
        >
          {title}
        </Text>
        <Text fz="18px" lh="28px" fw="400">
          <strong>{subtitle}</strong> {body}
        </Text>
        <Flex
          align="center"
          gap={{ base: '20px', sm: 'none' }}
          justify="space-between"
          w="100%"
          direction={{ base: 'column', sm: 'row' }}
        >
          <Button className={styles.button} onClick={close} variant="default">
            Cancel
          </Button>
          <Button
            classNames={{
              root: styles.button,
              inner: styles.inner,
            }}
            onClick={onDelete}
            variant="filled"
          >
            {buttonText}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

export default DeleteConfirmationModal
