import React, { useEffect, useRef, useState } from 'react'

import { Icons } from 'src/common/components'

import styles from './action-menu.module.scss'

interface IMenuItem {
  label: React.ReactNode
  icon?: React.ReactNode
  onClick?: () => void
  color?: string
  enable: boolean
}

interface IActionMenuProps {
  items: IMenuItem[]
}

const ActionMenu = (props: IActionMenuProps) => {
  const { items } = props
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.actionMenu} ref={menuRef}>
      <button className={styles.menuTrigger} onClick={toggleMenu}>
        <Icons.DotsMenu />
      </button>
      {isOpen && (
        <div className={styles.menuContent}>
          {items.map(
            (item, index) =>
              item.enable && (
                <button
                  key={index}
                  className={styles.menuItem}
                  style={{ color: item.color }}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick()
                      setIsOpen(false)
                    }
                  }}
                >
                  {item.icon} {item.label}
                </button>
              ),
          )}
        </div>
      )}
    </div>
  )
}

export default ActionMenu
