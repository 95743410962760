import React, { Component, ComponentType, ErrorInfo } from 'react'

import PolicyCheckHOC from './policyCheckHOC'

interface ErrorBoundaryAndPolicyCheckHOCState {
  hasError: boolean
}

const logErrorToMyService = (error: Error, componentStack: string) => {
  // TODO(marco): implement logging service
  console.error('Logging error:', error, componentStack)
}

function withErrorBoundaryAndPolicyCheck<P>(
  WrappedComponent: ComponentType<P>,
  policyName: string | string[],
  disablePolicyCheck?: boolean,
) {
  return class ErrorBoundaryAndPolicyCheck extends Component<
    P,
    ErrorBoundaryAndPolicyCheckHOCState
  > {
    constructor(props: P) {
      super(props)
      this.state = { hasError: false }
    }

    static getDerivedStateFromError(
      error: Error,
    ): ErrorBoundaryAndPolicyCheckHOCState {
      return { hasError: true }
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
      logErrorToMyService(error, info.componentStack!)
    }

    render() {
      if (this.state.hasError) {
        return (
          <div>
            Something goes wrong. Try refresh the page or contact with the
            support team
          </div>
        )
      }

      return (
        <PolicyCheckHOC
          disablePolicyCheck={disablePolicyCheck}
          policyName={policyName}
        >
          <WrappedComponent {...this.props} />
        </PolicyCheckHOC>
      )
    }
  }
}

export default withErrorBoundaryAndPolicyCheck
