import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface MutatePersonHouseholdData {
  personId?: string
  chatMessageId?: string
  message: string
}

const useMutatePersonHousehold = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: MutatePersonHouseholdData) => {
      return api[action === 'update' ? 'patch' : 'post'](
        '/benestream/persons/dependent',
        { ...data },
      )
    },
  })

export default useMutatePersonHousehold
