import cx from 'classnames'

import { Icons } from 'src/common/components'

import styles from './metroplus-success.module.scss'

interface MetroplusProps {
  email?: string
  metroplusid: string
  personType: 'lead' | 'account'
}

const MetroPlusSuccess = (props: MetroplusProps) => {
  const { email, metroplusid, personType } = props

  return (
    <div className={styles.metroPlusSuccess}>
      <div className={styles.emailIcon}>
        <Icons.EmailSent width={30} height={25} />
      </div>
      <div className={styles.title}>Sent Successfully!</div>
      <div className={styles.description}>
        The {personType === 'account' ? 'account' : 'lead'} was sent to
      </div>
      {email ? (
        <div className={styles.description}>{email.toLowerCase()}</div>
      ) : null}
      <div className={cx(styles.description, styles.metroplusid)}>
        Metroplus ID: {metroplusid}
      </div>
    </div>
  )
}

export default MetroPlusSuccess
