import { useFormik } from 'formik'
import { Fragment, useEffect, useState } from 'react'

import { Form, Loader, ScrollLoad } from 'src/common/components'
import dayjs from 'src/common/libs/dayjs'
import { useGetCommunicationsPerson } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import ActionItem from '../components/ActionItem/ActionItem'
import SearchNFilter from './SearchNFilter'
import styles from './communications-list.module.scss'

interface ICommunicationsListProps {
  personId: string
}

const CommunicationsList = (props: ICommunicationsListProps) => {
  const { personId } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  const [lastActivityDate, setLastActivityDate] = useState<Date | null>(null)

  const [isEndOfList, setIsEndOfList] = useState<boolean>(false)

  const [isFirstData, setIsFirstData] = useState<boolean>(false)

  const [isEmptyData, setIsEmptyData] = useState<boolean>(true)

  const [isNewPeriod, setIsNewPeriod] = useState<boolean>(false)

  const [isNoRecursiveJumps, setIsNoRecursiveJumps] = useState<boolean>(true)

  const searchFormik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => {},
  })

  const {
    data: { pages } = { pages: [] },
    isLoadingError,
    isLoading,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useGetCommunicationsPerson({
    fromDate: fromDate,
    toDate: toDate,
    personId,
    organizationId: currentOrganizationId ?? '',
    search: searchFormik.values.search || undefined,
  })

  useEffect(() => {
    const lastMonthPage = pages?.[pages.length - 1]?.data

    if (!!lastMonthPage) {
      if (lastMonthPage.lastActivityDate !== lastActivityDate) {
        setLastActivityDate(lastMonthPage.lastActivityDate)
      }

      if (!isFirstData) {
        setIsFirstData(true)
      }

      if (!isEndOfList && lastMonthPage.recordsLeft === 0) {
        setIsEndOfList(true)
      }

      if (isEmptyData && (lastMonthPage.records?.length || 0) > 0) {
        setIsEmptyData(false)
      }

      let itemsCount = 0

      pages.forEach((i) => {
        itemsCount += i.data.records.length
      })

      if (
        !isEndOfList &&
        isFirstData &&
        !isLoadingError &&
        itemsCount < 8 &&
        !isLoading &&
        !isNewPeriod &&
        !!lastActivityDate &&
        !!lastMonthPage.lastActivityDate
      ) {
        onNextPeriod().then()
      }
    }
  }, [
    isEndOfList,
    isEmptyData,
    isFirstData,
    isLoadingError,
    pages,
    isNewPeriod,
    isNoRecursiveJumps,
    lastActivityDate,
  ])

  const onNextPeriod = () => {
    return new Promise((resolve) => {
      if (!isEndOfList && !isLoading && !!lastActivityDate) {
        setToDate(dayjs(lastActivityDate).endOf('month').toDate())
        setFromDate(dayjs(lastActivityDate).add(-1).startOf('month').toDate())
        setIsNewPeriod(true)
      }
      resolve(true)
    })
  }

  useEffect(() => {
    // TODO mb move this inside onNextPeriod func
    if (isNewPeriod) {
      fetchNextPage().then(() => {
        setIsNewPeriod(false)
      })
    }
  }, [isNewPeriod])

  useEffect(() => {
    resetState()
  }, [searchFormik.values.search])

  const resetState = () => {
    setFromDate(dayjs().add(-1, 'month').toDate())
    setToDate(dayjs().add(1, 'day').toDate())
    setLastActivityDate(dayjs().add(1, 'day').toDate())
    setIsEndOfList(false)
    setIsFirstData(false)
    setIsEmptyData(true)
    setIsNewPeriod(false)
    setIsNoRecursiveJumps(true)
  }

  const pagesFiltered = pages
    .flatMap((item) => item.data.records)
    .reduce((acc, current) => {
      if (!acc.find((item: any) => item.id === current.id)) {
        return [...acc, current]
      }
      return acc
    }, [])

  return (
    <div>
      <Form className={styles.filterBlock} providerValue={searchFormik}>
        <SearchNFilter />
      </Form>

      <div className={styles.wrapper}>
        {isLoading}

        {!isFirstData && isLoading ? (
          <Loader isLoading={isLoading} isShowWrapper={false} />
        ) : null}

        {isFirstData && !isLoading && isEndOfList && isEmptyData ? (
          <div className={styles.empty}>
            No past activity.
            <br />
            Past meetings and tasks marked
            <br />
            as done show up here.
          </div>
        ) : null}

        {isRefetching ? (
          <Loader isLoading={isRefetching} isShowWrapper={false} />
        ) : (
          <ScrollLoad
            onNextPeriod={onNextPeriod}
            maxHeight={'70vh'}
            isLoading={
              isFirstData && (isLoading || isFetching || isFetchingNextPage)
            }
          >
            {isFirstData && !isEmptyData
              ? pagesFiltered.map((item: any, i: number) => (
                  <Fragment key={i}>
                    <div className={styles.inner}>
                      <ActionItem key={item.id} {...item} />
                    </div>
                  </Fragment>
                ))
              : null}
            {isEndOfList && !isEmptyData ? (
              <div className={styles.empty}>
                No more past activities to load.
              </div>
            ) : null}
          </ScrollLoad>
        )}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CommunicationsList,
  'is_get_communications_history_enabled',
)
