import cx from 'classnames'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import {
  GoogleMap,
  Icons,
  Input,
  InputGooglePlaces,
  InputZipcode,
  SelectState,
} from 'src/common/components'
import { matchCustomerState } from 'src/common/helpers'
import { useNotifications } from 'src/common/notification'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './address-information.module.scss'

interface IAddressInformation {
  showCopy?: boolean
  withPadding?: boolean
  showUpdatePerson: boolean
}

const AddressInformation = (props: IAddressInformation) => {
  const { showCopy = true, withPadding = false, showUpdatePerson } = props

  const notifications = useNotifications()

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined,
  )

  useEffect(() => {
    const matchedValue = matchCustomerState(values.state)

    if (values.state !== matchedValue) {
      setValues({ ...values, state: null })
    }
  }, [])

  const handleCopyAddress = () => {
    const fullAddress = `${values.street} ${values.city} ${values.state} ${values.postalCode}`
    navigator.clipboard.writeText(fullAddress)

    notifications?.show('success', 'Address is copied to the clipboard', {
      id: 'addressIsCopyToTheClipboard',
    })
  }

  const { values, setValues } = useFormikContext<any>() //TODO ts

  const handleGooglePlace = (result: google.maps.places.PlaceResult | null) => {
    setLocation(result?.geometry?.location ?? undefined)

    if (result) {
      const newValues = {
        street: '',
      } as {
        city?: string
        state?: string
        street?: string
        postalCode?: string
      }

      result.address_components?.reverse().forEach((a) => {
        if (a.types.includes('street_number')) {
          newValues.street = `${a.long_name} ${newValues.street}`
        }

        if (a.types.includes('route')) {
          newValues.street = a.long_name
        }

        if (a.types.includes('sublocality')) {
          if (!newValues.city) {
            newValues.city = a.long_name
          }
        }

        if (a.types.includes('locality')) {
          newValues.city = a.long_name
        }

        if (a.types.includes('administrative_area_level_1')) {
          newValues.state = a.long_name
        }

        if (a.types.includes('postal_code')) {
          newValues.postalCode = a.long_name
        }
      })

      setValues({
        ...values,
        ...newValues,
      })
    }
  }

  return (
    <BenestreamPersonFormBlock
      className={styles.wrapper}
      title="Address Information"
    >
      <div
        className={cx(styles.formContainer, withPadding && styles.withPadding)}
      >
        <div className={styles.unitWrapper}>
          {showCopy ? (
            <div onClick={handleCopyAddress} className={styles.copyAddress}>
              <Icons.Copy />
              Copy full address{' '}
            </div>
          ) : null}
          <InputGooglePlaces
            inputClassName={!showUpdatePerson ? 'active' : ''}
            disabled={showUpdatePerson}
            name="street"
            label="Address"
            placeholder="Enter Street Address"
            onChange={handleGooglePlace}
          />
        </div>

        <Input
          inputClassName={!showUpdatePerson ? 'active' : ''}
          disabled={showUpdatePerson}
          name="city"
          label="City"
          placeholder="Enter City Name"
          tabletWidth="50"
        />

        <SelectState
          inputClassName={!showUpdatePerson ? 'active' : ''}
          disabled={showUpdatePerson}
          name="state"
          label="State"
          placeholder="Enter State"
          tabletWidth="50"
        />

        <InputZipcode
          inputClassName={!showUpdatePerson ? 'active' : ''}
          disabled={showUpdatePerson}
          tabletWidth="50"
          name="postalCode"
          label="Postal Code"
        />

        {values.street ? (
          <GoogleMap
            className={styles.map}
            id="addressMap"
            location={location}
            defaultPlace={
              values.street
                ? `${values.city || ''} ${values.street}`
                : undefined
            }
            onLocationChange={setLocation}
          />
        ) : null}
      </div>
    </BenestreamPersonFormBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AddressInformation,
  'is_enable_address_information',
  true,
)
