import { DatePickerInput, DateValue } from '@mantine/dates'
import { GetInputPropsReturnType } from '@mantine/form/lib/types'

import { Icons } from 'src/common/components/ui'

import classes from './filter-datepicker.module.scss'

interface DatePickerProps extends GetInputPropsReturnType {
  placeholder?: string
  valueFormat?: string
  defaultDate?: DateValue
}
const DatePickerFilter = ({
  placeholder = 'MM/DD/YYYY',
  valueFormat = 'MM/DD/YYYY',
  defaultDate,
  ...props
}: DatePickerProps) => {
  return (
    <DatePickerInput
      {...props}
      valueFormat={valueFormat}
      placeholder={placeholder}
      defaultValue={defaultDate}
      rightSection={<Icons.Calendar className={classes['calender-icon']} />}
      highlightToday={true}
      maxDate={new Date()}
      popoverProps={{
        offset: 0,
        width: 'target',
        withinPortal: false,
        transitionProps: { transition: 'scale-y', duration: 300 },
        middlewares: {
          flip: false,
          shift: false,
          inline: true,
        },
      }}
      classNames={{
        input: classes.input,
        section: classes.section,
        calendarHeader: classes.calendarHeader,
        levelsGroup: classes.levelsGroup,
        month: classes.month,
      }}
    />
  )
}
export default DatePickerFilter
