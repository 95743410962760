import { GetInputPropsReturnType } from '@mantine/form/lib/types'

import { EnumPersonLanguage } from 'src/common/interfaces'

import SelectFilter from '../FilterSelect/FilterSelect.component'

const LanguageOptions = Object.entries(EnumPersonLanguage).map(
  ([value, label]) => ({
    value,
    label,
  }),
)
interface SelectFilterProps extends GetInputPropsReturnType {
  placeholder: string
}

const SelectLanguageFilter = (props: SelectFilterProps) => {
  return <SelectFilter options={LanguageOptions} {...props} />
}

export default SelectLanguageFilter
