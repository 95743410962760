import cx from 'classnames'

import { Input } from 'src/common/components'

import styles from './benestream-person-form-household-income-value.module.scss'

interface BenestreamPersonFormHouseholdIncomeValueProps {
  className?: string
  value: number
}

const BenestreamPersonFormHouseholdIncomeValue = (
  props: BenestreamPersonFormHouseholdIncomeValueProps,
) => {
  const { className, value } = props

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.label}>Monthly Income</div>

      <Input
        disabled
        className={styles.monthlyIncomeInput}
        name="monthlyIncome"
        value={`$${value.toFixed(2)}`}
      />
    </div>
  )
}

export default BenestreamPersonFormHouseholdIncomeValue
