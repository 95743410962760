import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateProcessFormSubmission = () =>
  useMutation({
    mutationFn: async (data: any) => {
      const url =
        `/v1/forms/process/` +
        data.id +
        `?processingStrategy=` +
        data.processingStrategy
      return api['post'](url)
    },
  })

export default useMutateProcessFormSubmission
