import { Accordion } from 'src/common/components/ui'
import dayjs from 'src/common/libs/dayjs'

import styles from './metro-plus-history-records.module.scss'

export interface IMetroPlusHistoryRecord {
  id: string
  metroPlusId: string
  sentBy: string
  sentTo: string
  createdAt: string
}

export interface IMetroPlusHistoryRecords {
  historyRecords: IMetroPlusHistoryRecord[]
}

const MetroPlusHistoryRecords = (props: IMetroPlusHistoryRecords) => {
  const { historyRecords } = props
  return historyRecords.length > 0 ? (
    <Accordion title="History" titleClassName={styles.accTitle}>
      {historyRecords.map((record: IMetroPlusHistoryRecord) => (
        <div className={styles.wrapperMetroPlusHistory} key={record.id}>
          <div className={styles.title}>Metroplus Id:</div>
          <div>{record.metroPlusId}</div>
          <div className={styles.title}>Sent by:</div>
          <div>{record.sentBy}</div>
          <div className={styles.title}>Sent to:</div>
          <div>{record.sentTo}</div>
          <div className={styles.title}>Sent Date:</div>
          <div className="flex-center gap-1">
            <div>
              {new Date(record.createdAt)?.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
            </div>
            <div>{dayjs(record.createdAt).tz().format('HH:mm')}</div>
          </div>
        </div>
      ))}
    </Accordion>
  ) : null
}

export default MetroPlusHistoryRecords
