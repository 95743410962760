import { Icons } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IDMPNote } from 'src/default/interfaces'

import styles from './deactivation-benefit-note.module.scss'

const DeactivationBenefitNote = (props: IDMPNote) => {
  const { createdAt, owner, visible, text } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <div className={styles.name}>
            {owner?.profile
              ? `${owner.profile?.firstName} ${owner.profile?.lastName}`
              : 'Unknown'}
          </div>
          <div className={styles.date}>
            {formatDate(createdAt, 'MM/DD/YYYY')}
          </div>
        </div>

        {visible ? (
          <div className={styles.visible}>
            <Icons.Eye />
            <span>Visible to Driver</span>
          </div>
        ) : null}
      </div>

      <div className={styles.border} />

      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default DeactivationBenefitNote
