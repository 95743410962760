import cx from 'classnames'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { useGetDependentPersonIncomes } from 'src/benestream/hooks'
import {
  IPersonHousehold,
  IPersonIncome,
  IncomePeriod,
  IncomeSource,
} from 'src/benestream/interfaces'
import { Button, Icons, Modal } from 'src/common/components'
import { useDeletePersonHouseholder } from 'src/common/hooks'
import { IModal } from 'src/common/interfaces'

import BenestreamPersonFormHouseholdIncomeForm from './BenestreamPersonFormHouseholdIncomeForm'
import BenestreamPersonFormHouseholdIncomeTable from './BenestreamPersonFormHouseholdIncomeTable'
import BenestreamPersonHouseholdIncomeDeleteMessage from './BenestreamPersonHouseholdIncomeDeleteMessage'
import BenestreamPersonHouseholdIncomeNotification from './BenestreamPersonHouseholdIncomeNotification'
import styles from './benestream-person-form-household-income-modal.module.scss'

interface BenestreamPersonFormHouseholdIncomeModalProps extends IModal {
  totalIncome: number
  incomes: IPersonIncome[]
  onSuccess: () => void
  refetch: () => void
  refetchHouseHold: () => void
}

const BenestreamPersonFormHouseholdIncomeModal = (
  props: BenestreamPersonFormHouseholdIncomeModalProps,
) => {
  const {
    totalIncome,
    incomes,
    onClose = () => {},
    onSuccess,
    refetch,
    refetchHouseHold,
  } = props

  const [notification, setNotification] = useState({
    title: '',
    description: '',
    isSuccess: false,
  })
  const [deleteHouseholdMemberId, setDeleteHouseholdMemberId] = useState('')
  const [householdSelected, setHouseholdSelected] = useState<IPersonIncome>({
    id: '',
    incomeSource: IncomeSource.Other,
    period: IncomePeriod.Annually,
    amount: '',
    monthlyIncome: 0,
  })
  const [showNewForm, setShowNewForm] = useState(false)

  const deleteHouseholdMember = useDeletePersonHouseholder()

  const handleDeleteHouseholdMember = () => {
    deleteHouseholdMember
      .mutateAsync({ personId: deleteHouseholdMemberId })
      .then(() => {
        setDeleteHouseholdMemberId('')
        refetch()
        refetchHouseHold()
      })
  }

  const handleOnSuccess = () => {
    setHouseholdSelected({
      amount: '',
      id: '',
      incomeSource: IncomeSource['Child Support'],
      monthlyIncome: 0,
      period: IncomePeriod.Annually,
    })
    setNotification({
      title: 'Income Source Updated!',
      description: 'The income source has been updated successfully!',
      isSuccess: true,
    })
  }

  const handleOnError = () => {
    setNotification({
      title: 'Unable to Update Income Source!',
      description: 'Please check all the required information and try again.',
      isSuccess: true,
    })
  }

  const handleOnCloseModal = () => {
    setNotification({
      description: '',
      isSuccess: false,
      title: '',
    })
    refetch()
    refetchHouseHold()
    onClose()
  }

  const handleOnCloseNotification = () => {
    setNotification({
      description: '',
      isSuccess: false,
      title: '',
    })
    refetch()
    refetchHouseHold()
  }

  const handleOnSuccessIncome = () => {
    onSuccess()
    refetch()
    refetchHouseHold()
    setShowNewForm(false)
  }

  const { values: parentFormikValues } = useFormikContext<IPersonHousehold>()
  const personName = `${parentFormikValues.firstName} ${parentFormikValues.lastName}`

  const modalTitle = showNewForm
    ? 'New Household Member Income'
    : 'Household Member Income'

  const getModalContent = () => {
    if (!!deleteHouseholdMemberId) {
      return (
        <BenestreamPersonHouseholdIncomeDeleteMessage
          handleDeleteHouseholdMember={handleDeleteHouseholdMember}
          isLoading={deleteHouseholdMember.isPending}
          setDeleteHouseholdMemberId={setDeleteHouseholdMemberId}
        />
      )
    }
    if (notification.title) {
      return (
        <BenestreamPersonHouseholdIncomeNotification
          {...notification}
          closeModal={handleOnCloseModal}
          closeNotification={handleOnCloseNotification}
        />
      )
    }
    if (householdSelected.id) {
      return (
        <BenestreamPersonFormHouseholdIncomeForm
          benestreamPersonId={parentFormikValues.id}
          personName={personName}
          onClose={onClose}
          onSuccess={handleOnSuccess}
          onError={handleOnError}
          houseHoldInformation={{
            incomeSource: householdSelected.incomeSource,
            period: householdSelected.period,
            amount: +householdSelected.amount,
            benestreamPersonIncomeId: householdSelected.id,
          }}
        />
      )
    }
    if (showNewForm) {
      return (
        <BenestreamPersonFormHouseholdIncomeForm
          benestreamPersonId={parentFormikValues.id}
          personName={personName}
          onClose={onClose}
          onSuccess={handleOnSuccessIncome}
          onError={onClose}
        />
      )
    }
    return (
      <BenestreamPersonFormHouseholdIncomeTable
        data={incomes}
        personName={personName}
        totalIncome={totalIncome}
        onNew={() => setShowNewForm(true)}
        setDeleteHouseholdMember={setDeleteHouseholdMemberId}
        selectHouseholdMember={setHouseholdSelected}
      />
    )
  }

  useEffect(() => {
    if (!incomes.length) {
      setShowNewForm(true)
    } else {
      setShowNewForm(false)
    }
  }, [incomes])

  return (
    <Modal id="houseHoldIncomeModal" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {deleteHouseholdMemberId || notification.title ? '' : modalTitle}
        </div>
        {getModalContent()}
      </div>
    </Modal>
  )
}

interface BenestreamPersonFormHouseholdIncomeModalWrapperProps extends IModal {
  totalIncome: number
  onSuccess: () => void
  refetchHouseHold: () => void
  refetchBeneStreamPerson: () => void
}

const BenestreamPersonFormHouseholdIncomeModalWrapper = (
  props: BenestreamPersonFormHouseholdIncomeModalWrapperProps,
) => {
  const { refetchBeneStreamPerson } = props
  const { values: parentFormikValues } = useFormikContext<IPersonHousehold>()

  const { data, refetch, isLoading } = useGetDependentPersonIncomes({
    dependentPersonId: parentFormikValues.id,
  })
  const incomes = data?.data?.records ?? []

  const handleAfterSuccessResponse = () => {
    refetchBeneStreamPerson()
    refetch()
  }

  if (isLoading) {
    return null
  }

  return (
    <BenestreamPersonFormHouseholdIncomeModal
      {...props}
      incomes={incomes}
      refetch={handleAfterSuccessResponse}
    />
  )
}

export default BenestreamPersonFormHouseholdIncomeModalWrapper
