import cx from 'classnames'
import { useField } from 'formik'

import { Input, InputDate, Textarea } from 'src/common/components'
import { formatDate } from 'src/common/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../../deactivation-benefit-case.module.scss'
import DeactivationBenefitFiles from './DeactivationBenefitFiles'
import styles from './deactivation-benefit-case-information.module.scss'

const DeactivationBenefitCaseInformation = () => {
  const [{ value: eventDate }] = useField('eventDate')

  return (
    <div className={cx(rootStyles.caseBlock, styles.wrapper)}>
      <div className={rootStyles.title}>Case Information</div>
      <div className={rootStyles.inner}>
        <Input
          disabled
          name="caseType.label"
          label="Case type"
          placeholder="Not specified"
        />

        <InputDate
          disabled
          hideCalendar
          name="eventDate"
          label="When did it happen?"
          value={formatDate(eventDate, 'MM/DD/YYYY')}
        />

        <Input disabled name="reason.label" label="Main reason" />

        <Input disabled name="punishment.label" label="Punishment" />

        <Textarea
          className={styles.textarea}
          disabled
          name="description"
          label="Driver’s Original Statement"
        />

        <DeactivationBenefitFiles />

        <Textarea
          className={styles.textarea}
          disabled
          name="summary"
          label="Case Summary for Ride Share Company"
        />

        <Textarea
          className={styles.textarea}
          disabled
          name="finalDecision"
          label="Decision"
          withError={false}
        />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitCaseInformation,
  'is_enable_deactivation_benefit_case_information',
  true,
)
