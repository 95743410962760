import { DatesRangeValue } from '@mantine/dates'
import { notifications } from '@mantine/notifications'
import { PAGINATION_LIMIT } from '@v2/commons/config'
import usePersistentForm from '@v2/commons/hooks/forms/usePersistentValuesForm'
import { filterUndefinedValues } from '@v2/commons/utils/filterUndefinedValues'
import useGetUsers from '@v2/domains/user/useCase/useGetUsers'
import dayjs from 'dayjs'
import { useState } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'

import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery,
} from '../models/emailTemplate.api'
import { IEmailTemplateFilters } from '../models/emailTemplate.model'

export const getEmailTemplates = () => {
  const router = useRouter()
  const [page, setPage] = useState(0)
  const [selectedEmailTemplatedIdForDelete, setSelectedTemplatedIdForDelete] =
    useState<string>('')
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false)

  const filterForm = usePersistentForm<IEmailTemplateFilters>({
    localStoragePath: 'email-template',
    formConfig: {
      initialValues: {},
      onValuesChange() {
        setPage(0)
      },
    },
    parseBooleans: false,
  })

  const initialFetchValues = {
    limit: PAGINATION_LIMIT,
  }

  const handleDateRangeChange = (dateValues: DatesRangeValue) => {
    const isRangeSelected = dateValues.every((item) => !!item)
    if (isRangeSelected) {
      const [startDate, endDate] = dateValues.map((dateValue) =>
        dayjs(dateValue).format('YYYY-MM-DD'),
      )
      filterForm.setFieldValue('fromDate', startDate)
      filterForm.setFieldValue('toDate', endDate)
    }
  }

  const clearAllFilters = () => {
    filterForm.reset()
  }

  const filters = filterUndefinedValues(filterForm.getValues())

  const { data, isLoading, isFetching, isSuccess, refetch } =
    useGetEmailTemplatesQuery({
      params: {
        ...initialFetchValues,
        ...filters,
        offset: page * PAGINATION_LIMIT,
      },
    })

  const [deleteEmailTemplateMutation] = useDeleteEmailTemplateMutation()

  const handlePaginate = () => {
    if (isFetching) return
    return setPage((prev) => {
      if (
        data &&
        data.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  const handleEditEmailTemplate = (emailTemplateId: string) => {
    router.push(
      `${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}/${emailTemplateId}`,
    )
  }

  const handleCreateEmailTemplate = () => {
    router.push(`${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}/create`)
  }

  const openConfirmModalToDelete = (emailTemplateId: string) => {
    setSelectedTemplatedIdForDelete(emailTemplateId)
    setDisplayDeleteModal(true)
  }

  const handleDeleteEmailTemplate = () => {
    deleteEmailTemplateMutation(selectedEmailTemplatedIdForDelete)
      .unwrap()
      .then(() => {
        setPage(0)
        setDisplayDeleteModal(false)
        notifications.show({
          title: 'Success',
          message: 'Email Template successfully deleted',
          color: 'green',
        })
      })
      .catch(() => {
        notifications.show({
          message: 'There was an error deleting the template',
          title: 'Error',
          color: 'red',
        })
      })
  }

  const usersOptions = (useGetUsers().users?.records ?? []).map((user) => ({
    value: user.id,
    label: user.name,
  }))

  return {
    templates: data?.records || [],
    totalRecords: data?.totalRecords ?? 0,
    isLoading,
    handleDateRangeChange,
    clearAllFilters,
    handlePaginate,
    isSuccess,
    filterForm,
    handleEditEmailTemplate,
    handleDeleteEmailTemplate,
    openConfirmModalToDelete,
    setDisplayDeleteModal,
    displayDeleteModal,
    handleCreateEmailTemplate,
    usersOptions,
  }
}
