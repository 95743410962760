import { Navigate } from 'react-router-dom'

import { cleanStringToBeUrlReadable } from 'src/default/helpers/clean-string'
import { useAppSelector } from 'src/v2/store/hooks'

const NotFoundPage = () => {
  const userId = useAppSelector((state) => state.user.userProfile.user.id)
  const organizationName = useAppSelector(
    (state) => state.user.userProfile.organization.name,
  )

  if (userId) {
    return (
      <Navigate to={`/${cleanStringToBeUrlReadable(organizationName)}/leads`} />
    )
  }

  return (
    <div>
      <h1>This page could not be found.</h1>
    </div>
  )
}

export default NotFoundPage
