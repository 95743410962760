import cx from 'classnames'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Icons } from 'src/common/components'
import { Portal } from 'src/common/components'
import { useWindowSize } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import LogoutButton from './LogoutButton'
import Menu from './Menu'
import logoImg from './assets/logo.png'
import styles from './sidebar.module.scss'

interface SidebarProps {
  showMobile: boolean
  onClose: () => void
}

const Sidebar = (props: SidebarProps) => {
  const { showMobile, onClose } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const { pathname } = useLocation()

  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.width) {
      if (windowSize.width > 992 && showMobile) {
        onClose()
      }
    }
  }, [windowSize.width])

  useEffect(() => {
    onClose()
  }, [pathname])

  const logoSrc = userProfile.organization.logoURL ?? logoImg

  return (
    <div className={cx(styles.wrapper, showMobile && styles.showMobile)}>
      <div className={styles.inner}>
        <div className={styles.close} onClick={onClose}>
          <Icons.Close />
        </div>

        <img className={styles.logo} src={logoSrc} />

        <PolicyCheckHOC policyName="is_nyc_lookup_enabled">
          <Portal.Target
            className={styles.lookupButton}
            id="NYCLookupButton"
            media="mobile"
          />
        </PolicyCheckHOC>

        <Menu />

        <LogoutButton />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Sidebar,
  'is_enable_sidebar',
  true,
)
