import cx from 'classnames'

import {
  SuccessCircle,
  Warning,
} from '../../../../../common/components/ui/Icons'
import { createCsv } from '../../../../commons/utils/csv/createCsv'
import { CampaignSummaryDTO } from '../../models/smsCampaign.model'
import styles from './summary-created-sms-campaign.module.scss'

interface SummaryCreatedSmsCampaignsProps {
  campaignCreated: CampaignSummaryDTO
}

const SummaryCreatedSmsCampaign = (props: SummaryCreatedSmsCampaignsProps) => {
  const { campaignCreated } = props
  return (
    <div className={styles.wrapper}>
      {campaignCreated.targets.errors.length ? (
        <>
          <Warning width={80} height={80} className={styles.icon} />
          <div className={cx(styles.titleWrapper, styles.title)}>
            <div>
              The campaign{' '}
              <span className={styles.titleAccent}>{campaignCreated.name}</span>{' '}
              has been created with some errors.
            </div>
            <div className={styles.title}>
              Click{' '}
              <div
                className={styles.linkText}
                onClick={() =>
                  createCsv(
                    ['Person Id', 'Error'],
                    campaignCreated.targets.errors.map((item) => ({
                      id: item,
                      message: 'Phone number blocked or invalid',
                    })),
                    'reportErrors.csv',
                  )
                }
              >
                here
              </div>{' '}
              to download the CSV with the report of all targets that were not
              sent messages.
            </div>
          </div>
        </>
      ) : (
        <>
          <SuccessCircle />
          <div className={styles.title}>
            The campaign{' '}
            <span className={styles.titleAccent}>{campaignCreated.name}</span>{' '}
            has been successfully created without errors.
          </div>
        </>
      )}
    </div>
  )
}

export default SummaryCreatedSmsCampaign
