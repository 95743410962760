import BenestreamPersonFormBlock from 'src/benestream/components/person/BenestreamPersonFormBlock/BenestreamPersonFormBlock'
import { Select } from 'src/common/components'

import styles from './benestream-person-form-preferred-communications.module.scss'

const BenestreamPersonFormPreferredCommunications = () => {
  return (
    <BenestreamPersonFormBlock title="Communication Preferences">
      <div className={styles.formContainer}>
        <Select
          name="preferredMethodOfContact"
          placeholder="Select preferred method of contact"
          label="Preferred Method of Contact"
          withError={false}
          options={[
            { label: 'Call', value: 'CALL' },
            { label: 'Email', value: 'EMAIL' },
            { label: 'SMS', value: 'SMS' },
          ]}
          tabletWidth="50"
        />
        <Select
          name="smsOptIn"
          placeholder="Select sms opt-in"
          label="SMS Opt-in"
          withError={false}
          options={[
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ]}
          tabletWidth="50"
        />
        <Select
          name="emailOptIn"
          placeholder="Select email opt-in"
          label="Email Opt-in"
          withError={false}
          options={[
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ]}
          tabletWidth="50"
        />
        <Select
          name="preferredTimeOfContact"
          placeholder="Select preferred time of contact"
          label="Preferred Time of Contact"
          withError={false}
          options={[
            { label: 'Morning', value: 'MORNING' },
            { label: 'Afternoon', value: 'AFTERNOON' },
            { label: 'Night', value: 'NIGHT' },
            { label: 'Anytime', value: 'ANYTIME' },
          ]}
          tabletWidth="50"
        />
      </div>
    </BenestreamPersonFormBlock>
  )
}

export default BenestreamPersonFormPreferredCommunications
