import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { QUERY_IDS } from 'src/default/config'

interface GetCommunicationsParams {
  personId: string
  fromDate: Date | null
  toDate: Date | null
  organizationId?: string
  search?: string
}

const useGetCommunicationsPerson = (params: GetCommunicationsParams) => {
  const { search, personId, fromDate, toDate, organizationId } = params

  return useInfiniteQuery({
    queryKey: [QUERY_IDS.GET_COMMUNICATIONS, search],
    queryFn: async ({ signal, pageParam = {} }) => {
      return await api.get(`v4/communications/person/${personId}`, {
        signal,
        params: {
          fromDate,
          toDate,
          organizationId,
          search,
          ...pageParam,
        },
      })
    },
    gcTime: 0,
    refetchOnMount: 'always',
    initialPageParam: { fromDate, toDate },
    getNextPageParam: () => ({ fromDate, toDate }), //TODO refactor it
    enabled: !!fromDate && !!toDate,
  })
}

export default useGetCommunicationsPerson
