import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import useGetABCsByOrganizationId from '../../../../hooks/abc/useGetABCsByOrganizationId'
import ABCAdministrationItem from './ABCsAdministrationPageItem/ABCAdministrationItem'
import ABCsAdministrationTemplate from './ABCsAdministrationTemplate/ABCsAdministrationTemplate'

const ABCsAdministrationPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const { data: catalogContracts } = useGetABCsByOrganizationId({
    organizationId: currentOrganizationId ?? '',
  })

  return (
    <ABCsAdministrationTemplate>
      {(catalogContracts?.data?.records ?? []).map((abc: any) => (
        <ABCAdministrationItem {...abc} />
      ))}
    </ABCsAdministrationTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ABCsAdministrationPage,
  'is_abcs_administration_view',
  true,
)
