import { useMutation } from '@tanstack/react-query'

import { IEligibilityGuideline } from 'src/benestream/interfaces'
import { api } from 'src/common/api'

const useMutateEligibilityGuideline = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: Partial<IEligibilityGuideline>) => {
      const { id: eligibilityGuidelineId, ...payload } = data

      const url =
        action === 'update'
          ? `/benestream-eligibility-guidelines/${eligibilityGuidelineId}`
          : '/benestream-eligibility-guidelines'

      return api[action === 'update' ? 'patch' : 'post'](url, payload)
    },
  })

export default useMutateEligibilityGuideline
