import { ApiResponseSerializer } from 'src/v2/commons/interface/ApiResponseSerializer'
import { rootApi } from 'src/v2/store/api/rootApi'

import { RoleModel, RolePolicyRequest, UpdateRoleRequest } from './role.model'

export const roleApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRole: builder.query<RoleModel, RoleModel['id']>({
      query: (id) => ({
        url: `/v1/authorization/role/${id}`,
        method: 'GET',
      }),
      providesTags: ['ROLES_BY_ID'],
    }),
    getRoles: builder.query<ApiResponseSerializer<RoleModel>, void>({
      query: () => ({
        url: '/v1/authorization/roles',
        method: 'GET',
      }),
      forceRefetch: () => true,
      providesTags: ['ROLES'],
    }),
    updateRole: builder.mutation<RoleModel, UpdateRoleRequest>({
      query(data) {
        const { id, name } = data
        return {
          url: `/v1/authorization/roles`,
          method: 'PATCH',
          data: { id, name },
        }
      },
      invalidatesTags: ['ROLES_BY_ID'],
    }),
    updateRolePolicy: builder.mutation<RoleModel, RolePolicyRequest>({
      query(data) {
        const { action, roleId, policyId } = data
        return {
          url:
            action === 'attach'
              ? `/v1/authorization/roles/attach-policy`
              : `/v1/authorization/roles/detach-policy`,
          method: 'POST',
          data: { policyId, roleId },
        }
      },
      invalidatesTags: ['ROLES'],
    }),
    createRole: builder.mutation<null, RoleModel['name']>({
      query(data) {
        return {
          url: 'v1/authorization/roles',
          method: 'POST',
          data: { name: data },
        }
      },
    }),
  }),
})

export const {
  useGetRoleQuery,
  useGetRolesQuery,
  useUpdateRolePolicyMutation,
  useUpdateRoleMutation,
  useCreateRoleMutation,
} = roleApi
