import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetEligibilityGuidelineByStateAbbreviationParams {
  stateAbbreviation?: string
  personId?: string
}

const useGetEligibilityGuidelineByStateAbbreviation = (
  params: GetEligibilityGuidelineByStateAbbreviationParams,
) => {
  const { stateAbbreviation, personId } = params

  return useQuery({
    queryKey: [
      'getEligibilityGuidelineByStateAbbreviation',
      stateAbbreviation,
      personId,
    ],
    queryFn: async () => {
      return await api.get(
        `/v2/benestream-eligibility-guidelines/v2/stateAbbreviation/${stateAbbreviation}`,
        { params: { personId } },
      )
    },
    enabled: !!stateAbbreviation,
  })
}

export default useGetEligibilityGuidelineByStateAbbreviation
