import { IBasicEntity } from 'src/common/interfaces'

export enum EnumDocumentType {
  DRIVER_LICENSE = 'driver_license',
  TLC = 'tlc',
  SOCIAL_SECURITY_NUMBER = 'social_security_number',
  PASSPORT = 'passport',
  OTHER_TYPE = 'other_type',
}

export interface IDocument extends IBasicEntity {
  id: string
  //person: {}, //TODO-EXODUS what is it?
  type: EnumDocumentType
  number: string
  isVerified: boolean
  pictureURL: string[]
  licenseExpiration: string
  licenseUpdated: string
}
