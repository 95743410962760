import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import CreateLeadHealthcareRadios from './CreateLeadHealthcareRadios'
import styles from './create-lead-healthcare.module.scss'

const CreateLeadHealthcare = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Healthcare Screening</div>

      <div className={styles.inner}>
        <CreateLeadHealthcareRadios
          name="haveHealthInsurance"
          label="Do you have Health Insurance?"
        />

        <CreateLeadHealthcareRadios
          name="coverageThroughTheState"
          label="Is your coverage through the state?"
        />

        <CreateLeadHealthcareRadios
          name="pay20PerMonth"
          label="Do you pay over 20 dollars a month?"
        />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadHealthcare,
  'is_enable_create_lead_healthcare',
  true,
)
