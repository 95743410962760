import { useEffect, useState } from 'react'

import { useGetPersons } from 'src/benestream/hooks'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { BasicBlock, EmptyPersons } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages } from 'src/common/helpers'
import { InfiniteBasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import LeadsPageHeader from './LeadsPageHeader'
import LeadsPageTable from './LeadsPageTable'
import styles from './leads-page.module.scss'

const LeadsPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isRefetching,
    isFetchingNextPage,
    isFetched,
  } = useGetPersons({
    isVerified: false,
    organizationId: currentOrganizationId,
    //status
    //company
    //createdDate
    orderBy: 'createdAt DESC',
    limit: PAGINATION_LIMIT,
  })

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages])

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const leads = concatQueryPages<IBenestreamPerson>(pages)

  const title = 'Leads'

  return (
    <InfiniteBasicTemplate
      title={title}
      header={<LeadsPageHeader />}
      scrollPromise={handlePageScroll}
      isLoading={isLoading}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: leads?.length ?? 0,
      }}
    >
      <BasicBlock
        className={styles.wrapper}
        isLoading={pages.length === 0 || isFetchingNextPage}
        isLoadingTop={isRefetching && !isFetchingNextPage}
      >
        {leads.length ? (
          <LeadsPageTable leads={leads} />
        ) : isFetched ? (
          <EmptyPersons term="lead" />
        ) : null}
      </BasicBlock>
    </InfiniteBasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LeadsPage,
  'is_enable_benestream_leads_view',
  true,
)
