import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface UseWhoAmIParams {
  userId: string
}

const useGetOrganizationsByUserId = (params: UseWhoAmIParams) => {
  const { userId } = params

  return useQuery({
    queryKey: ['authOrganizationByUser', userId],
    queryFn: async () => await api.get(`/v3/auth/organizations/${userId}`),
  })
}

export default useGetOrganizationsByUserId
