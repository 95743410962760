import {
  Card,
  Flex,
  Group,
  Image,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import { AdministrationContainer } from '../container/administration.container'
import styles from './administration.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
]

export const AdministrationView = () => {
  const {
    handleRoles,
    handleUsers,
    handlePolicies,
    handleABCs,
    handleEmailTemplate,
    isEnableEmailTemplates,
    organization,
  } = AdministrationContainer()

  return (
    <BasicTemplate
      title="Administration Panel"
      breadCrumbItems={breadCrumbItems}
    >
      <Card shadow="lg" radius="lg" className={styles.defaultCard}>
        <Paper radius="lg" p="sm" shadow="none" withBorder mt={10}>
          <Flex
            mih={50}
            gap="md"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image
              h="auto"
              w={120}
              src={organization.circularLogoURL}
              alt="logo"
            />
            <Stack flex={1} align="stretch">
              <Text>{organization.name}</Text>
              <Group gap="lg">
                <TextInput
                  flex={1}
                  value={organization.type}
                  label="Type"
                  readOnly
                  required
                />
                <TextInput
                  flex={1}
                  value={organization.code}
                  label="API Name (code)"
                  readOnly
                />
              </Group>
            </Stack>
          </Flex>
        </Paper>

        <SimpleGrid
          mt={36}
          cols={3}
          spacing="xl"
          c="var(--mantine-color-primary-5)"
        >
          <Paper
            withBorder
            radius="lg"
            shadow="none"
            mb={20}
            onClick={handleUsers}
          >
            <Stack align="center" justify="center" my={20}>
              <Icons.UsersCircle width="80px" height="80px" />
              <Text c="black">Users</Text>
            </Stack>
          </Paper>
          <Paper
            withBorder
            radius="lg"
            shadow="none"
            mb={20}
            onClick={handleRoles}
          >
            <Stack align="center" justify="center" my={20}>
              <Icons.RoleCircle width="80px" height="80px" />
              <Text c="black">Roles</Text>
            </Stack>
          </Paper>
          <Paper
            withBorder
            radius="lg"
            shadow="none"
            mb={20}
            onClick={handlePolicies}
          >
            <Stack align="center" justify="center" my={20}>
              <Icons.PolicyCircle width="80px" height="80px" />
              <Text c="black">Policies</Text>
            </Stack>
          </Paper>
          <Paper
            withBorder
            radius="lg"
            shadow="none"
            h={150}
            onClick={handleABCs}
          >
            <Stack align="center" justify="center" my={20}>
              <Icons.AbcCircle width="80px" height="80px" />
              <Text c="black">Associatted Benefit Contracts</Text>
            </Stack>
          </Paper>
          {isEnableEmailTemplates && (
            <Paper
              withBorder
              radius="lg"
              shadow="none"
              h={150}
              onClick={handleEmailTemplate}
            >
              <Stack align="center" justify="center" my={20}>
                <Icons.EmailTemplatesCircle width="80px" height="80px" />
                <Text c="black">Email Templates</Text>
              </Stack>
            </Paper>
          )}
        </SimpleGrid>
      </Card>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AdministrationView,
  'is_enable_administration_page',
  true,
)
