import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { api } from 'src/common/api'

interface GetAttachmentParams {
  attachmentId?: string
  attachmentType?: string
}

const useGetAttachment = (params: GetAttachmentParams) => {
  const { attachmentId = '', attachmentType } = params

  const url = (() => {
    switch (attachmentType) {
      case 'document':
        return `/person/documents/viewable-url/${attachmentId}`
      case 'enrollment':
        return `/enrollments/attachments/get-viewable-url/${attachmentId}`
      case 'email':
        return `/emails/get-viewable-url/${attachmentId}`
      default:
        return `/attachment/${attachmentId}`
    }
  })()

  return useQuery({
    queryKey: [`getAttachment`, url],
    queryFn: async () => {
      const result = await api.get(url)

      const attachmentUrl = result.data
      if (attachmentUrl) {
        const gettingFileResult = await axios.get(attachmentUrl)

        return {
          url: attachmentUrl,
          contentType: gettingFileResult?.headers['content-type'] || undefined,
        }
      }

      return {
        url: attachmentUrl,
      }
    },
  })
}

export default useGetAttachment
