import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Breadcrumbs,
  Button,
  Icons,
  Table,
} from 'src/common/components'
import { concatQueryPages } from 'src/common/helpers'
import { IPerson } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import {
  useGetFormById,
  useGetPersons,
  useMutateFormSubmission,
} from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import styles from './single-form-submission.module.scss'

// TODO deprecated /forms/submissions/:id

const SingleFormSubmission = () => {
  const router = useRouter()
  const { formId = '' } = useParams<{ formId: string }>()
  const { data: form, isLoading } = useGetFormById({
    formId,
  })

  const mutateSingleFormSubmission = useMutateFormSubmission()
  const [information, setInformation] = useState<any | null>()
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<any | null>(null)
  const [canCreateALead, setCanCreateALead] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isRelatedToALead, setIsRelatedToALead] = useState<boolean>(false)
  const [haveLeadId, setHaveLeadId] = useState<boolean>(false)
  const [newLeadId, setNewLeadId] = useState<string>('')

  const { data: { pages } = { pages: [] } } = useGetPersons({
    email: searchValue,
  })

  const persons = concatQueryPages<IPerson>(pages)

  useEffect(() => {
    if (form?.data) {
      const submittedInfo = form?.data?.payload?.steps?.reduce(
        (acc: any, step: any) => {
          step?.fields?.forEach((field: any) => {
            acc[field.label] = field.value
          })
          return acc
        },
        {},
      )
      setInformation(submittedInfo ?? {})
      setShow(true)
    }
  }, [form?.data, form?.request])

  useEffect(() => {
    setSearchValue(information?.Email || information?.email)
  }, [information])

  useEffect(() => {
    if (
      !form?.data?.template?.isProcessable &&
      !form?.data?.person?.sfdcId &&
      pages?.length === 0
    ) {
      setCanCreateALead(true)
      setIsRelatedToALead(false)
    }
    if (
      form?.data?.person?.sfdcId ||
      form?.data?.template?.isProcessable ||
      persons[0]?.id
    ) {
      setCanCreateALead(false)
      setIsRelatedToALead(true)
    }
    if (form?.data?.person?.sfdcId || persons[0]?.id) {
      setHaveLeadId(true)
    }
  }, [form?.data?.template?.isProcessable, persons])

  const handleReview = async () => {
    setLoading(true)
    await mutateSingleFormSubmission
      .mutateAsync({
        id: formId,
        isReviewed: true,
        isCompleted: form?.data?.isCompleted,
      })
      .then(() => {
        setLoading(true)
        setResult('success')
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleReviewAndCreateLead = async () => {
    setLoading(true)
    await mutateSingleFormSubmission
      .mutateAsync({
        id: formId,
        isReviewed: true,
        isCompleted: form?.data?.isCompleted,
        processFormSubmission: true,
      })
      .then((response) => {
        setNewLeadId(response?.data?.person?.sfdcId)
        setLoading(true)
        setResult('success')
      })
      .catch((error) => {
        setLoading(false)
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(
        newLeadId
          ? `${PRIVATE_ROUTES.LEADS.path}/${newLeadId}`
          : PRIVATE_ROUTES.FORM_SUBMISSIONS.path,
      )
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <Breadcrumbs
        items={[{ label: form?.data?.template?.name ?? 'Form Submission' }]}
      />

      <BasicTemplate title="Form Submission" isLoading={isLoading}>
        <BasicBlock className={styles.wrapper} paddings="big">
          <p>Deprecated</p>
          <h3>Form Name: {form?.data?.template?.name}</h3>
          <div>
            <div>
              Is Reviewed
              <span
                className={cx(
                  form?.data.isReviewed && styles.checked,
                  styles.checkbox,
                )}
              >
                <Icons.Check />
              </span>
            </div>
            <div>
              Is Completed
              <span
                className={cx(
                  form?.data.isCompleted && styles.checked,
                  styles.checkbox,
                )}
              >
                <Icons.Check />
              </span>
            </div>
            <div>
              Submitted Date:{' '}
              {dayjs(form?.data.createdAt).format('MM/DD/YYYY').toString()}
            </div>
            {isRelatedToALead && haveLeadId && (
              <div>
                This form submission is already linked to
                <a
                  className={styles.relatedLead}
                  onClick={() =>
                    router.push(
                      `${PRIVATE_ROUTES.LEADS.path}/${
                        form?.data?.person?.sfdcId ?? persons[0]?.id
                      }`,
                    )
                  }
                >
                  this lead
                </a>
              </div>
            )}
          </div>
          <h3>Information Submitted</h3>
          {show && (
            <Table>
              {Object.keys(information)?.map((u: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Column
                    className={styles.name}
                    title="Fields"
                    showMobile
                  >
                    {u}
                  </Table.Column>

                  <Table.Column title="Value" showMobile>
                    {information[u] || 'No value submitted'}
                  </Table.Column>
                </Table.Row>
              ))}
            </Table>
          )}
          <div className={styles.buttonsWrapper}>
            {!form?.data?.isReviewed && (
              <PolicyCheckHOC policyName="is_enable_review_form_submission">
                <Button loading={loading} onClick={handleReview}>
                  Review
                </Button>
              </PolicyCheckHOC>
            )}

            {canCreateALead && (
              <PolicyCheckHOC policyName="is_enable_review_and_create_form_submission">
                <Button loading={loading} onClick={handleReviewAndCreateLead}>
                  Review and Create Lead
                </Button>
              </PolicyCheckHOC>
            )}
          </div>
        </BasicBlock>
        {result !== null ? (
          <ModalResult
            isSuccess={result === 'success'}
            description={result !== 'success' ? result : undefined}
            onClose={handleCloseResult}
          />
        ) : null}
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleFormSubmission,
  'is_enable_single_form_submission',
  true,
)
