import { validate as isValidUUID } from 'uuid'

import { IEligibility } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './eligibility-item.module.scss'

interface IEligibilityItem {
  data: IEligibility
}

const BENEFITS = {
  PAID_LEAVE: '80096cf7-8b61-4f43-8c7a-6202b6447853',
}

const EligibilityItem = (props: IEligibilityItem) => {
  const { data } = props

  const getExternalId = () => {
    if (data.benefit.id === BENEFITS.PAID_LEAVE) {
      return isValidUUID(data?.providerExternalId)
        ? 'PENDING'
        : data?.providerExternalId
    }
    return data?.providerExternalId
  }

  return (
    <div className={styles.resultContainer} onClick={()=>{
      console.log(data)
    }}>
      <div className={styles.name}>
        {data?.enrollment?.associatedBenefitContract?.name} -{' '}
        {data?.benefit?.name}
      </div>
      <div className={styles.statusContainer}>
        <div className={styles.titles}>Status:</div>
        <div className={styles.infoDates}>
          {data?.status} ({data?.providerDataExchangeStatus})
        </div>
      </div>
      <div className={styles.statusContainer}>
        <div className={styles.titles}>External ID:</div>
        <div className={styles.infoDates}>{getExternalId()}</div>
      </div>
      <div className={styles.closeDateContainer}>
        <div className={styles.titles}>Date:</div>
        <div className={styles.infoDates}>
          {new Date(data?.updatedAt).toLocaleDateString('en-US', {
            dateStyle: 'medium',
          })}
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EligibilityItem,
  'is_enable_eligibility_item',
  true,
)
