import { useField } from 'formik'
import { useState } from 'react'

import { Icons, Input } from 'src/common/components'

import BenestreamPersonFormHouseholdIncomeModal from './BenestreamPersonFormHouseholdIncomeModal'
import styles from './benestream-person-form-household-income.module.scss'

interface BenestreamPersonFormHouseholdIncomeProps {
  onSuccess: () => void
  refetchHouseHold: () => void
  refetchBeneStreamPerson: () => void
}

const BenestreamPersonFormHouseholdIncome = (
  props: BenestreamPersonFormHouseholdIncomeProps,
) => {
  const { onSuccess, refetchHouseHold, refetchBeneStreamPerson } = props

  const [showModal, setShowModal] = useState(false)

  const [{ value: fieldValue }] = useField('monthlyIncome')

  const totalIncome = Number(fieldValue)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label>Monthly Income</label>

          <div className={styles.editButton} onClick={() => setShowModal(true)}>
            <div className={styles.icon}>
              <Icons.Plus />
            </div>

            <span>Edit Income</span>
          </div>
        </div>

        <Input
          disabled
          name="monthlyIncome"
          placeholder="Enter monthly income"
          value={`$${totalIncome.toFixed(2)}`}
        />
      </div>

      {showModal ? (
        <BenestreamPersonFormHouseholdIncomeModal
          totalIncome={totalIncome}
          onClose={() => setShowModal(false)}
          onSuccess={onSuccess}
          refetchHouseHold={refetchHouseHold}
          refetchBeneStreamPerson={refetchBeneStreamPerson}
        />
      ) : null}
    </>
  )
}

export default BenestreamPersonFormHouseholdIncome
