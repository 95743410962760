import cx from 'classnames'

import styles from './checkbox.module.scss'

interface CheckboxProps {
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  checked?: boolean
  icon?: 'check' | 'indeterminate'
}

const Checkbox = (props: CheckboxProps) => {
  const { disabled, onClick, checked, icon = 'check' } = props

  return (
    <div
      className={cx(
        icon === 'indeterminate' && styles.checkBoxWrapperIndeterminate,
        icon === 'check' && styles.checkboxWrapperDefault,
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        onClick={onClick}
        checked={checked}
      />
    </div>
  )
}

export default Checkbox
