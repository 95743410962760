import { useFormik } from 'formik'
import { useState } from 'react'

import { BasicBlock, Button, Form } from 'src/common/components'
import { IDocument } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import SingleAccountDocumentsTable from './SingleAccountDocumentsTable'
import { SingleAccountDocumentsModal } from './components'
import styles from './single-account-documents.module.scss'

interface SingleAccountDocumentsProps {
  data: IDocument[]
  isSuccess: boolean
  refetch: () => Promise<any>
}

const SingleAccountDocuments = (props: SingleAccountDocumentsProps) => {
  const { data, isSuccess, refetch } = props

  const [documentModalIsOpen, setDocumentModalIsOpen] = useState(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>('')

  const handleSelectedDocument = (id: string) => {
    setSelectedDocumentId(id)
    setDocumentModalIsOpen(true)
  }

  const handleOnCloseModal = () => {
    setDocumentModalIsOpen(false)
    setSelectedDocumentId('')
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  })

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        paddings="small"
        isLoading={!isSuccess}
        title="Documents"
        header={
          <PolicyCheckHOC policyName="is_create_document_enabled">
            <Button
              className={styles.newButton}
              icon="Plus"
              size="small"
              onClick={() => setDocumentModalIsOpen(true)}
            >
              New Document
            </Button>
          </PolicyCheckHOC>
        }
      >
        <Form providerValue={formik}>
          {data.length ? (
            <SingleAccountDocumentsTable
              data={data}
              refetch={refetch}
              onSelectDocument={handleSelectedDocument}
            />
          ) : isSuccess ? (
            <div className={styles.noData}>
              <div>No documents found</div>
            </div>
          ) : null}
        </Form>
      </BasicBlock>

      {documentModalIsOpen ? (
        <PolicyCheckHOC policyName="is_get_document_by_id_enabled">
          <SingleAccountDocumentsModal
            refetch={refetch}
            initialData={
              data.find((item) => item.id === selectedDocumentId) || null
            }
            documentTypes={data.map((item) => item.type)}
            onClose={handleOnCloseModal}
          />
        </PolicyCheckHOC>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountDocuments,
  'is_enable_single_account_documents',
  true,
)
