import cx from 'classnames'

import styles from './basic-status.module.scss'

interface StatusProps {
  className?: string
  labelClassName?: string
  color?: string
  strictColor?: string
  size?: 'normal' | 'big'
  hideMobileLabel?: boolean
  hideCircle?: boolean
}

const BasicStatus = (props: React.PropsWithChildren<StatusProps>) => {
  const {
    className,
    labelClassName,
    color,
    strictColor,
    size = 'normal',
    hideMobileLabel,
    hideCircle,
    children,
  } = props

  return (
    <div
      className={cx(
        styles.wrapper,
        hideMobileLabel && styles.hideMobileLabel,
        color && styles[color],
        styles[size],
        className,
      )}
    >
      {!hideCircle ? (
        <span
          className={styles.circle}
          style={{ backgroundColor: strictColor }}
        />
      ) : null}
      <span className={cx(styles.label, labelClassName)}>{children}</span>
    </div>
  )
}

export default BasicStatus
