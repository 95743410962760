import { useField } from 'formik'
import { useEffect, useState } from 'react'

import { Input } from 'src/common/components'
import { IField } from 'src/common/interfaces'

interface InputSearchProps extends IField {
  onSearch?: (value: string) => Promise<void>
}

const InputSearch = (props: InputSearchProps) => {
  const { name, onSearch, ...rest } = props

  const [isAutoFocus, setIsAutoFocus] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)

  const [{ value }] = useField({ name })

  useEffect(() => {
    if (value) {
      setIsAutoFocus(true)
    }

    setIsReady(true)
  }, [])

  if (!isReady) {
    return null
  }

  return (
    <Input
      {...rest}
      name={name}
      withError={false}
      icon="Search"
      autoFocus={isAutoFocus}
    />
  )
}

export default InputSearch
