import { useParams } from 'react-router-dom'

import { UNION360 } from 'src/default/config'
import {
  EnumDocumentType,
  IDocument,
  IEligibility,
} from 'src/default/interfaces'

import { IPerson } from '../../../../common/interfaces'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import AccountMainInformation from './AccountMainInformation'
import LeadMainInformation from './LeadMainInformation'
import styles from './main-information.module.scss'

interface MainInformationProps {
  person: IPerson
  customerType: 'lead' | 'account'
  documents?: IDocument[]
  eligibilities?: IEligibility[]
}

const MainInformation = (props: MainInformationProps) => {
  const { customerType, documents = [], eligibilities = [], person } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const { ticketNumber } = useParams<{ ticketNumber?: string }>()

  const license = (() => {
    const tlcLicense = documents.find(
      (doc) => doc.type === EnumDocumentType.TLC,
    )?.number
    if (tlcLicense) {
      return {
        title: 'TLC Number:',
        value: tlcLicense,
      }
    }

    const driverLicense = documents.find(
      (doc) => doc.type === EnumDocumentType.DRIVER_LICENSE,
    )?.number
    if (driverLicense) {
      return {
        title: 'License Number:',
        value: driverLicense,
      }
    }

    return null
  })()

  return (
    <div className={styles.wrapper}>
      {customerType === 'lead' ? <LeadMainInformation /> : null}

      {license !== null && currentOrganizationId !== UNION360 ? (
        <PolicyCheckHOC
          policyName="is_enable_license_number"
          disablePolicyCheck={true}
        >
          <>
            {customerType === 'lead' ? (
              <div className={styles.divider} />
            ) : null}

            <div>
              <div className={styles.title}>{license.title}</div>
              <div>{license.value}</div>
            </div>

            {customerType === 'account' ? (
              <div className={styles.divider} />
            ) : null}
          </>
        </PolicyCheckHOC>
      ) : null}

      {!!person.nationBuilderId ? (
        <PolicyCheckHOC
          policyName="is_enable_nation_builder_id"
          disablePolicyCheck={true}
        >
          <>
            <div>
              <div className={styles.title}>NationBuilder ID:</div>
              <div>{person.nationBuilderId}</div>
            </div>
          </>
        </PolicyCheckHOC>
      ) : null}

      {customerType === 'account' ? (
        <AccountMainInformation eligibilities={eligibilities} />
      ) : null}

      {ticketNumber ? (
        <>
          <div className={styles.preQueued} />
          <div className={styles.queued}> Queued #{ticketNumber}</div>
        </>
      ) : null}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MainInformation,
  'is_enable_main_information',
  true,
)
