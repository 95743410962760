import type { AxiosError } from 'axios'
import { useState } from 'react'

import { Button } from 'src/common/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'
import { useConvertLeadToAccount } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import ModalResultConvertLead from '../../_components/ModalResultConvertLead/ModalResultEnrollments'
import ConvertLeadModal from './ConvertLeadModal'
import ManualConvertLeadModal from './ManualConvertLeadModal'

interface ConvertLeadProps {
  enrollmentId: string
  personId: string
  leadName?: string
  tlcNumber?: string
  refetch?: () => Promise<any>
}

const ConvertLead = (props: ConvertLeadProps) => {
  const { enrollmentId, personId, leadName, tlcNumber } = props

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showManualModal, setShowManualModal] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)
  const [errorDetail, setErrorDetail] = useState<string>('')
  const router = useRouter()
  const convertLeadToAccountMutation = useConvertLeadToAccount()

  const handleConvert = async (manualApproval?: boolean) => {
    //TODO mb remove these unnecessary lines
    setResult(null)
    setShowManualModal(false)

    await convertLeadToAccountMutation
      .mutateAsync({ enrollmentId, manualApproval })
      .then((result) => {
        setShowModal(false)
        if (result.data.error) {
          setResult('error')
        } else {
          setResult('success')
        }
      })
      .catch(async (error: AxiosError<{ message: string[] }>) => {
        //TODO ts
        if (tlcNumber) {
          if (
            error.response?.data?.message?.length === 1 &&
            error.response?.data?.message?.[0]?.includes(
              'The name in Worker360 does not match the name in the TLC Open Database.',
            )
          ) {
            setShowManualModal(true)

            return
          }
        }
        setErrorDetail(getErrorDetail(error?.response?.data?.message ?? []))
        setShowModal(false)
        setResult('error')
      })
  }

  const getErrorDetail = (message: string[]) => {
    if (Array.isArray(message)) {
      if (message.length === 1) {
        return message[0].split(',').join('\n')
      }
      return (message ?? []).join(', ')
    }
    return message ?? ''
  }

  const handleCloseResult = async () => {
    if (result === 'success') {
      router.push(`${PRIVATE_ROUTES.ACCOUNTS.path}/${personId}`)
    }

    setResult(null)
  }

  return (
    <>
      <div>
        <Button size="small" icon="Refresh" onClick={() => setShowModal(true)}>
          Convert Lead
        </Button>
      </div>

      {showModal ? (
        <ConvertLeadModal
          isLoading={convertLeadToAccountMutation.isPending}
          onClose={() => setShowModal(false)}
          onSubmit={handleConvert}
        />
      ) : null}

      {showManualModal ? (
        <ManualConvertLeadModal
          leadName={leadName}
          tlcNumber={tlcNumber}
          isLoading={convertLeadToAccountMutation.isPending}
          onClose={() => setShowManualModal(false)}
          onSubmit={() => handleConvert(true)}
        />
      ) : null}

      {result !== null ? (
        <ModalResultConvertLead
          isSuccess={result === 'success'}
          title={
            result === 'success'
              ? 'Enrollment Converted!'
              : 'Unable to Convert Enrollment!'
          }
          description={
            result === 'success'
              ? 'The lead has been converted into an account successfully.'
              : `Please check all the required information and try again.\n${errorDetail}`
          }
          onClose={handleCloseResult}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ConvertLead,
  'is_verify_enrollment_enabled',
)
