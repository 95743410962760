import cx from 'classnames'
import { useField } from 'formik'
import { useState } from 'react'

import { Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import { MultiSelect } from 'src/default/components'

import parentStyles from '../basic-multiselect-buttons.module.scss'
import BasicMultiSelectButtonsOtherInput from './BasicMultiSelectButtonsOtherInput'
import styles from './basic-multiselect-buttons-with-other.module.scss'

const BasicMultiSelectButtonsWithOther = (props: IField) => {
  const { name, options, disabled } = props

  const [{ value: fieldValue }, meta, { setValue: setFieldValue }] =
    useField(name)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const handleClick = () => {
    setIsOpen(!isOpen)

    if (
      inputValue &&
      !(options || []).find((o) => o.value === inputValue) &&
      !fieldValue?.includes(inputValue)
    ) {
      setFieldValue([...(fieldValue || []), inputValue])
    }

    setInputValue('')
  }

  const handleRemoveOption = (option: string) => {
    setFieldValue([
      ...(fieldValue || []).filter((item: string) => item !== option),
    ])
  }

  const handleCancel = () => {
    setIsOpen(false)
    setInputValue('')
  }

  const CurrentIcon = !isOpen ? Icons.Plus : Icons.Check

  const otherOptions =
    fieldValue?.filter((v: string) => !options?.find((o) => o.value === v)) ??
    []

  return (
    <MultiSelect.Buttons disabled={disabled} {...props}>
      {otherOptions.map((option: string, i: number) => (
        <div
          key={i}
          className={cx(parentStyles.item, parentStyles.selected, styles.item)}
        >
          {disabled ? null : (
            <span
              className={styles.close}
              onClick={() => handleRemoveOption(option)}
            >
              <Icons.Close />
            </span>
          )}
          <span className={parentStyles.check} />
          {option}
        </div>
      ))}

      {isOpen ? (
        <BasicMultiSelectButtonsOtherInput
          value={inputValue}
          onChange={setInputValue}
          onEscape={handleCancel}
          onEnter={handleClick}
        />
      ) : null}

      {disabled ? null : (
        <div
          className={cx(styles.addButton, disabled && styles.disabled)}
          onClick={disabled ? () => null : handleClick}
        >
          <CurrentIcon />
        </div>
      )}
    </MultiSelect.Buttons>
  )
}

export default BasicMultiSelectButtonsWithOther
