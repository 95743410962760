import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IMutatePersonNoteData {
  personId?: string
  chatMessageId?: string
  message: string
}

const useMutatePersonNote = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: IMutatePersonNoteData) => {
      return api[action === 'update' ? 'patch' : 'post']('/v4/person/note', {
        ...data,
        message: data.message.trim(),
      })
    },
  })

export default useMutatePersonNote
