import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetSmsCampaignById = (id: string) => {
  const url = `/sms-campaigns/${id}`

  return useQuery({
    queryKey: ['useGetSmsCampaignById', id],
    queryFn: async () => await api.get(url),
    retry: 2,
  })
}

export default useGetSmsCampaignById
