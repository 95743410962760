import cx from 'classnames'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import {
  GoogleMap,
  Icons,
  Input,
  InputGooglePlaces,
  InputZipcode,
  SelectState,
} from 'src/common/components'
import { matchCustomerState } from 'src/common/helpers'
import { useNotifications } from 'src/common/notification'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benestream-person-form-address-information.module.scss'

interface BenestreamPersonFormAddressBlockProps {
  showCopy?: boolean
  withPadding?: boolean
}

const BenestreamPersonFormAddressBlock = (
  props: BenestreamPersonFormAddressBlockProps,
) => {
  const { showCopy = true, withPadding = false } = props

  const notifications = useNotifications()

  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined,
  )

  useEffect(() => {
    const matchedValue = matchCustomerState(values.state)

    if (values.state !== matchedValue) {
      setValues({ ...values, state: matchedValue })
    }
  }, [])

  const handleCopyAddress = () => {
    const fullAddress = `${values.street} ${values.city} ${values.state} ${values.postalCode}`
    navigator.clipboard.writeText(fullAddress)

    notifications?.show('success', 'Address is copied to the clipboard', {
      id: 'addressIsCopyToTheClipboard',
    })
  }

  const { values, setValues } = useFormikContext<any>() //TODO ts

  const handleGooglePlace = (result: google.maps.places.PlaceResult | null) => {
    setLocation(result?.geometry?.location ?? undefined)

    if (result) {
      const newValues = {
        street: '',
      } as {
        city?: string
        state?: string
        street?: string
        postalCode?: string
      }

      result.address_components?.reverse().forEach((a) => {
        if (a.types.includes('street_number')) {
          newValues.street = `${a.long_name} ${newValues.street}`
        }

        if (a.types.includes('route')) {
          newValues.street = a.long_name
        }

        if (a.types.includes('sublocality')) {
          if (!newValues.city) {
            newValues.city = a.long_name
          }
        }

        if (a.types.includes('locality')) {
          newValues.city = a.long_name
        }

        if (a.types.includes('administrative_area_level_1')) {
          newValues.state = a.long_name
        }

        if (a.types.includes('postal_code')) {
          newValues.postalCode = a.long_name
        }
      })

      setValues({
        ...values,
        ...newValues,
      })
    }
  }

  return (
    <BenestreamPersonFormBlock
      className={styles.wrapper}
      title="Address Information"
    >
      <div
        className={cx(styles.formContainer, withPadding && styles.withPadding)}
      >
        <InputGooglePlaces
          name="street"
          label="Street"
          placeholder="Enter Street Address"
          onChange={handleGooglePlace}
          tabletWidth="50"
        />

        <div className={styles.unitWrapper}>
          {/*TODO mb implement common component for copying */}
          {showCopy ? (
            <div onClick={handleCopyAddress} className={styles.copyAddress}>
              <Icons.Copy />
              Copy full address{' '}
            </div>
          ) : null}

          <Input
            name="unitNumber"
            label="Unit Number"
            placeholder="Enter Unit Number"
          />
        </div>

        <div className={styles.stateWrapper}>
          <SelectState
            name="state"
            label="State"
            placeholder="Enter State"
            required
          />

          <InputZipcode name="postalCode" label="Zip Code" />
        </div>

        <Input
          name="city"
          label="City"
          placeholder="Enter City Name"
          tabletWidth="50"
        />

        {values.street ? (
          <GoogleMap
            className={styles.map}
            id="addressMap"
            location={location}
            defaultPlace={
              values.street
                ? `${values.city || ''} ${values.street}`
                : undefined
            }
            onLocationChange={setLocation}
          />
        ) : null}
      </div>
    </BenestreamPersonFormBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonFormAddressBlock,
  'is_create_benestream_person_primary_enabled',
)
