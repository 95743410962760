import { Button, Icons } from 'src/common/components'
import { Portal } from 'src/common/components'
import { NycLicenseLookup } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useRouter } from '../../../helpers/navigation'
import styles from './topbar.module.scss'

interface TopbarProps {
  onBurgerToggle: () => void
}

const Topbar = (props: React.PropsWithChildren<TopbarProps>) => {
  const router = useRouter()

  const { onBurgerToggle, children } = props

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mobileBurger} onClick={onBurgerToggle}>
          <Icons.Burger />
        </div>

        {children}

        {/* TODO refactor auto margin of component wrapper and move wrapper to component */}

        <Portal.Target
          className={styles.lookupButton}
          id="NYCLookupButton"
          media="desktop"
        />
      </div>

      <Portal.Wrapper id="NYCLookupButton" width={992}>
        <div className={styles.buttonsWrapper}>
          <NycLicenseLookup />
          <PolicyCheckHOC policyName="is_persons_data_loader_enabled">
            <a
              className={styles.noTextDecoration}
              onClick={() => router.push(`${PRIVATE_ROUTES.DATA_LOADER.path}`)}
            >
              <Button className={styles.altButton} icon="Upload" size="small">
                Upload File
              </Button>
            </a>
          </PolicyCheckHOC>
        </div>
      </Portal.Wrapper>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(Topbar, 'is_enable_topbar', true)
