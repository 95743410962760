import { useAppSelector } from 'src/v2/store/hooks'

import { useGetUsersQuery } from '../models/user.api'
import { type UsersResponseModel } from '../models/user.model'

interface IUseGetUsersNoPaginationUseCase {
  users: UsersResponseModel | undefined
  usersQueryStatus: {
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
  }
}

const useGetUsersNoPaginattionUseCase = (): IUseGetUsersNoPaginationUseCase => {
  const { id: organizationId } = useAppSelector(
    (state) => state.user.userProfile.organization,
  )

  const { isLoading, isSuccess, data, isFetching } = useGetUsersQuery({
    organizationId,
  })

  return {
    users: data,
    usersQueryStatus: {
      isLoading,
      isSuccess,
      isFetching,
    },
  }
}

export default useGetUsersNoPaginattionUseCase
