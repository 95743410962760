import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { formatDate } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'

import DateSelect from './DateSelect'
import MonthSelect from './MonthSelect'
import YearSelect from './YearSelect'
import styles from './calendar.module.scss'

interface CalendarProps {
  value: string
  onChange: (value: string) => void
  onClose?: () => void
  futureDate?: boolean
  pastDate?: boolean
  maxYear?: number
  format?: string
}

const Calendar = (props: CalendarProps) => {
  const {
    value: outerValue,
    onChange,
    onClose,
    futureDate = false,
    pastDate = true,
    maxYear,
    format,
  } = props

  const [date, setDate] = useState<Dayjs>(dayjs())
  const [value, setValue] = useState<Dayjs>(dayjs())

  const [shouldClose, setShouldClose] = useState<boolean>(false)

  const dateFormat = format || 'MM/DD/YYYY'

  useEffect(() => {
    if (outerValue) {
      const outerDate = dayjs(outerValue, dateFormat)

      setDate(outerDate.clone())
      setValue(outerDate.clone())
    }
  }, [])

  useEffect(() => {
    if (outerValue) {
      const outerDate = dayjs(outerValue, dateFormat)

      if (!value.isSame(outerDate)) {
        onChange(formatDate(value, dateFormat))
      }
    } else {
      onChange(formatDate(value, dateFormat))
    }
  }, [value])

  useEffect(() => {
    if (onClose && shouldClose) {
      onClose()
    }
  }, [shouldClose])

  const handleChangeDate = (date: Dayjs) => {
    setValue(date)
    setShouldClose(true)
  }

  const yearsOptions = (() => {
    const optionsLength = 100 //TODO make as variable
    const past = pastDate
    const future = futureDate
    const hasMaxYear = typeof maxYear !== 'undefined'

    const current = Number(formatDate(dayjs(), 'YYYY'))
    const result: { label: string; value: string }[] = []

    for (let i = 0; i < optionsLength; i++) {
      let year = null
      if (!past) {
        year = current + i
      } else if (!future) {
        year = current - i
      } else {
        year =
          i < optionsLength / 2
            ? current - optionsLength / 2 + i
            : current + (i - optionsLength / 2)
        if (hasMaxYear && year > maxYear) {
          break
        }
      }
      result[hasMaxYear ? 'unshift' : 'push']({
        label: String(year),
        value: String(year),
      })
    }

    return result
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <YearSelect date={date} options={yearsOptions} onChange={setDate} />

        <MonthSelect
          pastDate={pastDate}
          futureDate={futureDate}
          date={date}
          yearsOptions={yearsOptions}
          onChange={setDate}
        />
      </div>

      <DateSelect
        pastDate={pastDate}
        futureDate={futureDate}
        value={value}
        date={date}
        onChange={handleChangeDate}
      />
    </div>
  )
}

export default Calendar
