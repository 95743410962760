import { Alert, Button, Container, Modal, Stack, rem } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { IconCalendar } from '@tabler/icons-react'
import { useState } from 'react'

import dayjs from 'src/common/libs/dayjs'
import { useMutationSmsReport } from 'src/default/hooks/sms/useMutationSmsReport'

import styles from './sms-campaigns.module.scss'

export default function SmsCampaignsReportModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const currentDate = new Date()
  const oneMonthAgo = new Date(currentDate)
  oneMonthAgo.setMonth(currentDate.getMonth() - 1)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const createSmsReport = useMutationSmsReport()

  const icon = <IconCalendar className={styles.iconCalendar} />

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      fromDate: oneMonthAgo,
      toDate: currentDate,
    },
    onValuesChange: (values) => {
      setSuccessMessage(null)
      setErrorMessage(null)
      const diffDays = dayjs(values.fromDate, 'MM-DD-YYYY').diff(
        dayjs(values.toDate, 'MM-DD-YYYY'),
        'month',
      )

      if (diffDays !== 0) {
        setErrorMessage('Date range must not exceed 1 month')
      }
      return
    },
  })

  const handleSubmitForm = async (values: { fromDate: Date; toDate: Date }) => {
    const fromDate = dayjs.tz(values.fromDate).format('MM-DD-YYYY')
    const toDate = dayjs.tz(values.toDate).format('MM-DD-YYYY')

    await createSmsReport
      .mutateAsync({ fromDate, toDate })
      .then((response: any) => {
        response.success
          ? setSuccessMessage(
              `you will receive an email shortly with a sms report file`,
            )
          : setErrorMessage(response.message)
      })
      .catch((error: Error) => {
        setErrorMessage(error.message)
      })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Create a CSV Report"
      centered
      size="xs"
    >
      <>
        <form onSubmit={form.onSubmit(handleSubmitForm)}>
          <Stack justify="center" gap="md">
            <DatePickerInput
              label="From Date"
              placeholder="Pick Date"
              leftSection={icon}
              leftSectionPointerEvents="none"
              valueFormat="MM-DD-YYYY"
              {...form.getInputProps('fromDate')}
              maxDate={currentDate}
            />
            <DatePickerInput
              label="To"
              placeholder="To Date"
              leftSection={icon}
              leftSectionPointerEvents="none"
              valueFormat="MM-DD-YYYY"
              {...form.getInputProps('toDate')}
              maxDate={currentDate}
            />
            <Container>
              {errorMessage && (
                <Alert color="red" title="Oops">
                  {errorMessage}
                </Alert>
              )}
              {successMessage && (
                <Alert title="Success">{successMessage}</Alert>
              )}
            </Container>
            <Button type="submit">Create</Button>
          </Stack>
        </form>
      </>
    </Modal>
  )
}
