import { AxiosResponse } from 'axios'
import cx from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { useAppSelector } from '../../../../v2/store/hooks'
import { Icons, Spinner } from '../../ui'
import styles from './file-uploader.module.scss'

interface FileUploaderProps {
  description: string
  maxSizeMB: number
  formats: string[]
  mutation?: any
  file: File
  setFile: (file: File) => void
  setUrlValue: (url: string) => void
  errorMessage?: string
  setErrorMessage: (error: string) => void
  dragActiveDescription: string
  label?: string
  required?: boolean
}

const FileUploader = (props: FileUploaderProps) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const {
    description,
    maxSizeMB,
    formats,
    mutation,
    file,
    setFile,
    setUrlValue,
    errorMessage,
    setErrorMessage,
    dragActiveDescription,
    label,
    required,
  } = props

  const [error, setError] = useState(errorMessage || '')

  const validateFileSize = (file: File) => {
    let hasError = false

    const maxAllowedSize = maxSizeMB * 1024 * 1024

    if (inputRef.current) {
      if (file.size > maxAllowedSize) {
        inputRef.current.value = ''
        const error = `File size must not exceed ${maxSizeMB}MB`
        setError(error)
        setErrorMessage(error)
        hasError = true
      }
    }

    return hasError
  }

  const validateFileType = (file: File) => {
    let hasError = false

    formats.forEach((format) => {
      if (!file.type.includes(format.replaceAll('.', ''))) {
        const error = `Only accepted file types ${formats.join(', ')}`
        setError(error)
        setErrorMessage(error)
        hasError = true
      }
    })

    return hasError
  }

  const handleUploadFileMutation = async (file: File) => {
    if (Boolean(mutation)) {
      await mutation
        .mutateAsync({ file })
        .then((result: AxiosResponse) => {
          const imageUrl = result?.request.responseURL.split('?')[0]
          setUrlValue(imageUrl)
          setFile(file)
        })
        .catch(() => {
          setError('Error upload CSV file. Try again later.')

          if (inputRef?.current) {
            inputRef.current.value = ''
          }
        })
    } else {
      setFile(file)
    }
  }

  const onDrop = (files: File[]) => {
    if (files.length >= 2) {
      const error = `Upload only one file`
      setErrorMessage(error)
      return setError(error)
    }

    const file = files[0]

    if (validateFileType(file)) {
      return validateFileType(file)
    }

    if (validateFileSize(file)) {
      return validateFileSize(file)
    }

    setError('')

    handleUploadFileMutation(file)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: Boolean(file.type && file.name && file.size),
  })

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    const target = event.target
    if (target.files && target.files[0] && inputRef.current) {
      const file = target.files[0]

      if (validateFileType(file)) {
        return validateFileType(file)
      }

      if (validateFileSize(file)) {
        return validateFileSize(file)
      }

      handleUploadFileMutation(file)
    }
  }

  const calculateSize = (size: number) => {
    const sizeInKb = size / 1024

    return sizeInKb > 1 ? `${sizeInKb}KB` : `${size}B`
  }

  const handleDeleteFile = () => {
    setTimeout(() => {
      setFile(new File([], ''))
      setUrlValue('')

      if (inputRef?.current) {
        inputRef.current.value = ''
      }
    }, 100)
  }

  const getContent = useCallback(() => {
    if (mutation?.isLoading) {
      return <Spinner color="primary" size="big" />
    }

    if (file.type && file.name && file.size) {
      return (
        <div className={styles.successUploadWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.successUploadIcon}>
              <Icons.PageGray />
            </div>
            <div className={styles.successUploadText}>{file.name}</div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.successUploadText}>
              {calculateSize(file.size)}
            </div>
            <div className={styles.trashIcon} onClick={handleDeleteFile}>
              <Icons.TrashCircle />
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className={styles.icon}>
          <Icons.UploadDocument />
        </div>
        <div className={styles.description}>
          {isDragActive ? dragActiveDescription : description}
        </div>
        <div
          className={styles.maxSize}
        >{`File size must not exceed ${maxSizeMB}MB`}</div>
      </>
    )
  }, [file, mutation?.isLoading, isDragActive])

  useEffect(() => {
    if (file) {
      setFile(file)
    }
  }, [file])

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])

  return (
    <div {...getRootProps({ onClick: (evt) => evt.preventDefault() })}>
      {label ? (
        <label className={styles.inputLabel}>
          {label} {required ? <span>*</span> : null}
        </label>
      ) : null}

      <label
        className={cx(
          styles.wrapper,
          error && styles.error,
          file.type && file.name && file.size && styles.success,
          isDragActive && styles.isDragActive,
          styles[`${currentTheme}Slice`],
        )}
      >
        <input
          type="file"
          className={cx(styles.input)}
          onChange={handleOnChange}
          accept={formats.join(', ')}
          disabled={!!file.name}
          {...getInputProps()}
          ref={inputRef}
        />
        {getContent()}
      </label>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  )
}

export default FileUploader
