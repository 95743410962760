import { useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { IField } from 'src/common/interfaces'

import { BasicInput } from '..'
import FieldWrapper from '../../FieldWrapper/FieldWrapper'
import styles from './input-options.module.scss'

interface SelectOption {
  id: string
  name: string
}

interface InputOptionsProps extends IField {
  selectOptions: SelectOption[]
  setIsActiveOptions: (value: boolean) => void
  onSelectOption: (id: string) => void
  selectedValue: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isActive: boolean
}

const InputOptions = (props: InputOptionsProps) => {
  const selectOptionsRef = useRef<HTMLDivElement>(null)

  const {
    setIsActiveOptions,
    selectOptions,
    value,
    onSelectOption,
    selectedValue,
    onChange,
    isActive,
    ...rest
  } = props

  const [inputValue, setInputValue] = useState(value)

  const closeModal = () => {
    setIsActiveOptions(false)
  }

  const handleSelectOption = (id: string) => {
    onSelectOption(id)
    closeModal()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange(e)

    if (!isActive) {
      setIsActiveOptions(true)
    }
  }

  useEffect(() => {
    const handleOnClick = (e: MouseEvent) => {
      if (
        selectOptionsRef.current &&
        !selectOptionsRef.current.contains(e.target as Node)
      ) {
        closeModal()
      }
    }

    if (typeof document !== 'undefined') {
      document.addEventListener('click', handleOnClick)
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('click', handleOnClick)
      }
    }
  }, [])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FieldWrapper {...rest}>
      <BasicInput onChange={handleOnChange} {...rest} value={inputValue} />
      {selectOptions.length && isActive ? (
        <div ref={selectOptionsRef} className={styles.options}>
          {selectOptions.map((option, item) => (
            <div
              className={styles.optionItem}
              onClick={() => handleSelectOption(option.id)}
              key={item}
            >
              {option.name}
            </div>
          ))}
        </div>
      ) : null}
    </FieldWrapper>
  )
}

export default InputOptions
