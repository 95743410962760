import { useState } from 'react'

import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'
//TODO mb refactor this import
import { LANG_OPTIONS } from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage/mutateBenefitsAdministrationPageConfig'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './mutate-benefits-administration-delete-lang-modal.module.scss'

interface MutateBenefitsAdministrationDeleteLangModalProps extends IModal {
  language: string
  onRemove: () => Promise<void>
}

const MutateBenefitsAdministrationDeleteLangModal = (
  props: MutateBenefitsAdministrationDeleteLangModalProps,
) => {
  const { language, onClose, onRemove } = props

  const [isLoading, setIsLoading] = useState(false)

  const handleRemove = async () => {
    setIsLoading(true)

    await onRemove()

    setIsLoading(false)
  }

  return (
    <Modal id="convertLead" template="small" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.TrashCross />
        </div>

        <div className={styles.title}>Delete this language version?</div>

        <div className={styles.subtitle}>
          Are you sure you want to delete the{' '}
          <strong>
            {LANG_OPTIONS.find((l) => l.value === language)?.label}
          </strong>{' '}
          version?
        </div>

        <div className={styles.buttons}>
          <Button
            disabled={isLoading}
            size="small"
            template="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button loading={isLoading} size="small" onClick={handleRemove}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationDeleteLangModal,
  'is_enable_mutate_benefits_administration_delete_lang_modal',
  true,
)
