import { BasicBlock } from 'src/common/components'

import zoomImg from './assets/zoom.svg'
import styles from './error-block-user-information.module.scss'

//TODO mb make common component

const ErrorBlockUserInformation = () => {
  return (
    <BasicBlock className={styles.wrapper}>
      <img src={zoomImg} alt="zoom" />

      <div className={styles.title}>Information not available</div>

      <div className={styles.description}>
        Looks like your user information is incomplete. Please contact your
        system administrator
      </div>
    </BasicBlock>
  )
}

export default ErrorBlockUserInformation
