import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benefits-administration-img-name.module.scss'

interface BenefitsAdministrationImgNameProps {
  img: string
  name: string
}

const BenefitsAdministrationImgName = (
  props: BenefitsAdministrationImgNameProps,
) => {
  const { img, name } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <img src={img} />
      </div>

      <div className={styles.name}>{name}</div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationImgName,
  'is_enable_benefit_administration_img',
  true,
)
