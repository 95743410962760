import { Anchor, Breadcrumbs, Loader } from '@mantine/core'
import cx from 'classnames'

import { Portal } from 'src/common/components'
import { useAppSelector } from 'src/v2/store/hooks'

import withErrorBoundaryAndPolicyCheck from '../../HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from '../../components/Breadcrumb'
import styles from './basic-template.module.scss'

export interface BasicTemplateProps {
  header?: string | React.ReactNode
  title?: string
  breadCrumbItems?: TBreadCrumbItems
  isLoading?: boolean
}

const BasicTemplate = (props: React.PropsWithChildren<BasicTemplateProps>) => {
  const { header, title, breadCrumbItems, isLoading, children } = props
  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
      {breadCrumbItems && (
        <Breadcrumbs>
          {breadCrumbItems.map((item, index) => (
            <Anchor
              c={
                index === breadCrumbItems.length - 1
                  ? 'var(--mantine-color-primary-5)'
                  : 'var(--mantine-color-gray-5)'
              }
              href={item.href}
              key={index}
              underline="never"
            >
              {item.title}
            </Anchor>
          ))}
        </Breadcrumbs>
      )}

      <div className={styles.header}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {header && <div className={styles.headerInner}>{header}</div>}
      </div>
      <div>
        <Portal.Target
          className={styles.mobileSearch}
          id="searchField"
          media="mobile"
        />
      </div>
      {isLoading ? <Loader size={30} /> : <div>{children}</div>}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BasicTemplate,
  'is_enable_basic_template',
  true,
)
