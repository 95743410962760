import { useFormik } from 'formik'
import * as yup from 'yup'

import { useMutateEligibilityGuideline } from 'src/benestream/hooks'
import { IEligibilityGuideline } from 'src/benestream/interfaces'
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  SubmitButton,
} from 'src/common/components'
import QuillEditor from 'src/common/components/form/QuillEditor'
import { STATES } from 'src/common/config'
import { IModal } from 'src/common/interfaces'

import styles from './eligibility-editor-modal.module.scss'

interface EligibilityEditorModalProps extends IModal {
  isNew: boolean
  availableStates: string[]
  initialValues?: Partial<IEligibilityGuideline>
  refetch: () => Promise<any>
}

const EligibilityEditorModal = (props: EligibilityEditorModalProps) => {
  const { isNew, initialValues, availableStates, refetch, onClose } = props

  const eligibilityMutation = useMutateEligibilityGuideline(
    isNew ? 'create' : 'update',
  )

  const formik = useFormik({
    initialValues: !isNew
      ? (initialValues as Partial<IEligibilityGuideline>)
      : {
          stateAbbreviation: '',
          stateName: '',
          otherAdultIndividual: '',
          parentFamilyOf3: '',
          immigrationRules: '',
          //povertyGuidelineName: '',
          premiums: '',
          waitPeriods: '',
          additionalNotes: '',
          basePovertyGuideline: '',
          povertyGuidelinePerAdditionalPerson: '',
        },
    onSubmit: async (values) => {
      const payload = !isNew
        ? values
        : {
            ...values,
            stateAbbreviation:
              Object.values(STATES).find(
                (state) => state.value === values.stateName,
              )?.match?.[0] ?? '',
          }

      await eligibilityMutation.mutateAsync(payload).then(async () => {
        await refetch()

        if (onClose) {
          onClose()
        }
      })
    },
    validationSchema: yup.object().shape({
      stateName: yup.string().required('State is required'),
      otherAdultIndividual: yup
        .number()
        .required('Other Adult Individual is required'),
      parentFamilyOf3: yup.number().required('Parent Family Of 3 is required'),
      immigrationRules: yup.string().required('Immigration Rules is required'),
      // povertyGuidelineName: yup
      //   .string()
      //   .required('Poverty Guideline Name is required'),
      premiums: yup.string().required('Premiums is required'),
      waitPeriods: yup.string().required('Wait Periods is required'),
    }),
  })

  const selectStateOptions = availableStates.map((value) => ({
    label: value,
    value,
  }))

  const modalTitle = !isNew
    ? `${formik.values.stateName} Eligibility Guidelines`
    : 'New Eligibility Guideline'

  return (
    <Modal id="eligibilityEditorModal" onClose={onClose} template="big">
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>{modalTitle}</div>

        <div className={styles.inner}>
          {isNew ? (
            <Select
              required
              name="stateName"
              label="State"
              placeholder="Select State"
              options={selectStateOptions}
            />
          ) : null}
          <div className={styles.inputsWrapper}>
            <Input
              required
              name="otherAdultIndividual"
              type="number"
              label="Other Adult Individual"
              placeholder="Enter Other Adult Individual"
            />

            <Input
              required
              name="parentFamilyOf3"
              type="number"
              label="Parent Family Of 3"
              placeholder="Enter Parent Family Of 3"
            />
          </div>

          <div className={styles.inputsWrapper}>
            <Input
              name="basePovertyGuideline"
              type="number"
              label="Base Poverty Guideline"
              placeholder="Enter Base Poverty Guideline"
            />

            <Input
              name="povertyGuidelinePerAdditionalPerson"
              type="number"
              label="Poverty Guideline Per Additional Person"
              placeholder="Enter Poverty Guideline Per Additional Person"
            />
          </div>

          <QuillEditor
            required
            name="immigrationRules"
            label="Immigration Rules"
            placeholder="Enter Immigration Rules"
          />

          {/* <QuillEditor
            required
            name="povertyGuidelineName"
            label="Poverty Guideline Name"
            placeholder="Enter Poverty Guideline Name"
          /> */}

          <QuillEditor
            required
            name="premiums"
            label="Premiums"
            placeholder="Enter Premiums"
          />

          <QuillEditor
            required
            name="waitPeriods"
            label="Wait Periods"
            placeholder="Enter Wait Periods"
          />

          <QuillEditor
            name="additionalNotes"
            label="Additional Notes"
            placeholder="Enter Additional Notes"
          />

          <div className={styles.bottomButtons}>
            <Button size="small" template="secondary" onClick={onClose}>
              Cancel
            </Button>

            <SubmitButton size="small" loading={formik.isSubmitting}>
              Save
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default EligibilityEditorModal
