export const TASK_TYPE_OPTIONS = [
  { label: 'Make a Call', value: 'makeCall' },
  { label: 'Other', value: 'other' },
]

export const TASK_STATUS_OPTIONS = [
  { label: 'Open', value: 'open' },
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Completed', value: 'completed' },
]

export const TASK_PRIORITY_OPTIONS = [
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
]

export const TASK_SUBJECT_OPTIONS = [
  { value: 'callDriver', label: 'Call Driver' },
  { value: 'urgentCall', label: 'Urgent Call' },
  { value: 'sendEmailToDriver', label: 'Send Email to Driver' },
  { value: 'assistance', label: 'Assistance' },
  { value: 'leaveVoiceMail', label: 'Leave Voice Mail' },
]
