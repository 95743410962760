import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateSingleTask = () =>
  useMutation({
    mutationFn: async (data: Partial<{ id: string; assignedToId: string }>) => {
      return api.patch('/v3/tasks', data)
    },
  })

export default useMutateSingleTask
