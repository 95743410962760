import { createTheme } from '@mantine/core'

import componentsOverrides from '../overrides'

export const defaultTheme = createTheme({
  colors: {
    primary: [
      '#D4DBFF',
      '#B5C2FF',
      '#92A4FF',
      '#7D92FF',
      '#6980FF',
      '#516DFF',
      '#3C5BFF',
      '#2649FF',
      '#1E3BE6',
      '#1730CC',
    ],
    blue: [
      '#D4DBFF',
      '#B5C2FF',
      '#92A4FF',
      '#7D92FF',
      '#6980FF',
      '#516DFF',
      '#3C5BFF',
      '#2649FF',
      '#e1e6ff',
      '#1730CC',
    ],
    darkBlue: [
      '#D4E0FB',
      '#BAC9E9',
      '#A1B4DD',
      '#8DA2D1',
      '#7A90C0',
      '#667EAE',
      '#566C9C',
      '#465A8A',
      '#364971',
      '#031331',
    ],
    red: [
      '#FFE8E8',
      '#FFDADA',
      '#FFCCCC',
      '#FFBEBE',
      '#FFB0B0',
      '#FFA2A2',
      '#E53E3E',
      '#FF9494',
      '#ebb9b9',
      '#E34A30',
      '#E53E3E',
    ],
    green: [
      '#CCF7E0',
      '#B3F4D1',
      '#99F1C2',
      '#80EEB3',
      '#66EBA4',
      '#4DE895',
      '#33E586',
      '#1AE277',
      '#00D564',
      '#00BD59',
    ],
    orange: [
      '#FFF1E0',
      '#FFE3C2',
      '#FFD5A3',
      '#FFC785',
      '#F7B055',
      '#E6A34F',
      '#D49649',
      '#C28943',
      '#B07C3D',
      '#9E6F37',
    ],
    gray: [
      '#FCFDFF',
      '#F4F4F4',
      '#E6E8EC',
      '#D4D7DE',
      '#C3CBDD',
      '#8A909E',
      '#BBC2D3',
      '#A3AAB8',
      '#444955',
      '#21252C',
      '#EAECF0',
    ],
  },
  defaultRadius: 'sm',
  radius: {
    six: '6px',
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '3rem',
  },
  primaryColor: 'blue',
  primaryShade: { light: 5, dark: 7 },
  white: '#FFFFFF',
  black: '#000000',
  fontFamily: '"SFProText", sans-serif',
  headings: {
    fontFamily: '"SFProText", sans-serif',
  },
  components: {
    ...componentsOverrides,
  },
})
