import cx from 'classnames'
import React from 'react'

import TableCoreCellInner from './TableCoreCellInner'
import styles from './table-core-cell.module.scss'

interface ITableCoreCell {
  className?: string
  showMobile?: boolean
  noOverflow?: boolean
  link?: string
  onClick?: () => void
  children: any //TODO type
}

//TODO mb remove onClick from Table.Cell ???

const TableCoreCell = (props: ITableCoreCell) => {
  const { className, showMobile, noOverflow, link, onClick, children } = props

  return (
    <td
      onClick={onClick}
      className={cx(
        styles.tableCellWrapper,
        showMobile && styles.showMobile,
        noOverflow && styles.noOverflow,
        className,
      )}
    >
      <TableCoreCellInner link={link}>{children}</TableCoreCellInner>
    </td>
  )
}

export default TableCoreCell
