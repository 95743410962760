import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonParams {
  personId?: string
}

const useGetPerson = (params: GetPersonParams) => {
  const { personId } = params

  let url = `/v8/persons/${personId}`

  return useQuery({
    queryKey: [`getPerson`, url],
    queryFn: async () => await api.get(url),
    refetchOnWindowFocus: false,
  })
}

export default useGetPerson
