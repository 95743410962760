import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Breadcrumbs,
  Button,
  Form,
  Tabs,
} from 'src/common/components'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import { useGetCmsBenefitById, useMutateCmsBenefit } from 'src/default/hooks'
import { BenefitsAdministrationTemplate } from 'src/default/pages/Administration/BenefitsAdministration/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import MutateBenefitsAdministrationAddLangModal from './MutateBenefitsAdministrationAddLangModal'
import MutateBenefitsAdministrationLangData from './MutateBenefitsAdministrationLangData'
import styles from './mutate-benefits-administration-page.module.scss'
import {
  LANG_OPTIONS,
  initialValues,
} from './mutateBenefitsAdministrationPageConfig'
import { IBenefitForm } from './mutateBenefitsAdministrationPageConfig'
import getValidationSchema from './mutateBenefitsAdministrationPageValidation'

const MutateBenefitsAdministrationPage = () => {
  const [language, setLanguage] = useState('en')
  const [showNewLangModal, setShowNewLangModal] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)

  const { benefitId, organizationId } = useParams<{
    benefitId: string
    organizationId: string
  }>()
  const router = useRouter()

  const { data: benefitData } = useGetCmsBenefitById({ benefitId })

  const benefitMutation = useMutateCmsBenefit(benefitId ? 'update' : 'create')

  const formik = useFormik<IBenefitForm>({
    initialValues,
    onSubmit: async (values) => {
      await benefitMutation
        .mutateAsync({
          id: benefitId,
          private: true,
          name: values.metaData.name.en,
          content: values.content,
          metaData: values.metaData,
          buttons: values.buttons,
          icons: {
            public: values.icons.public,
            private: values.icons.public, //TODO mb refactor and revome private icon
          },
        })
        .then((result) => {
          if (!benefitId) {
            if (result.data.id) {
              router.push(
                `${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}/${result.data.id}`,
              )

              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          }

          setResult('success')
        })
        .catch((error) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: getValidationSchema,
  })

  useEffect(() => {
    //TODO mb remove it and separate file to Create and Update pages
    if (!benefitId) {
      formik.setValues(initialValues)
      formik.setTouched({})
      setLanguage('en')
    }
  }, [benefitId])

  useEffect(() => {
    if (benefitData?.data) {
      const { content, metaData, buttons, icons } = benefitData.data
      formik.setValues({
        content,
        metaData,
        buttons,
        icons,
      })
    }
  }, [benefitData])

  const handleClickTab = (tabId: string) => {
    const isNew = tabId === 'newLang'
    if (isNew) {
      setShowNewLangModal(true)
    } else {
      setLanguage(tabId)
    }

    return !isNew
  }

  const handleAddNewLanguage = (newLang: string) => {
    formik.setValues({
      ...formik.values,
      content: {
        public: {
          ...formik.values.content.public,
          [newLang]: '',
        },
        private: {
          ...formik.values.content.private,
          [newLang]: '',
        },
      },
      metaData: {
        ...formik.values.metaData,
        name: {
          ...formik.values.metaData.name,
          [newLang]: '',
        },
      },
      buttons: {
        ...formik.values.buttons,
        [newLang]: [
          {
            name: '',
            link: '',
            useNewTab: false,
          },
          {
            name: '',
            link: '',
            useNewTab: false,
          },
        ],
      },
    })

    setLanguage(newLang)
    setShowNewLangModal(false)
  }

  const handleSubmitForm = async () => {
    const validationResult = await formik.validateForm()

    const hasErrorsForOtherLangs = (() => {
      //TODO mb refactor
      const { content, metaData, buttons } = validationResult

      return (
        Object.keys(validationResult).length &&
        !content?.public?.[language] &&
        !content?.private?.[language] &&
        !metaData?.name?.[language] &&
        !buttons?.[language]
      )
    })()

    if (!hasErrorsForOtherLangs) {
      await formik.submitForm()
    } else {
      setResult('Please fill fields for other languages or remove them')
    }
  }

  const handleRemoveLanguage = async (langForRemoving: string) => {
    setLanguage('en')

    await formik.setValues((values: any) => ({
      //TODO ts and mb  refactor it
      ...values,
      content: {
        public: Object.keys(values.content.public).reduce(
          (acc: any, key: any) => {
            if (key !== langForRemoving) {
              acc[key] = values.content.public[key]
            }

            return acc
          },
          {},
        ),
        private: Object.keys(values.content.private).reduce(
          (acc: any, key: any) => {
            if (key !== langForRemoving) {
              acc[key] = values.content.private[key]
            }

            return acc
          },
          {},
        ),
      },
      metaData: {
        ...values.metaData,
        name: Object.keys(values.metaData.name).reduce((acc: any, key: any) => {
          if (key !== langForRemoving) {
            acc[key] = values.metaData.name[key]
          }

          return acc
        }, {}),
      },
      buttons: Object.keys(values.buttons).reduce((acc: any, key: any) => {
        if (key !== langForRemoving) {
          acc[key] = values.buttons[key]
        }

        return acc
      }, {}),
    }))

    await handleSubmitForm()
  }

  const showAddLangTab =
    Object.keys(formik.values.metaData.name).length < LANG_OPTIONS.length

  const breadcrumbsItems = (() => {
    if (!benefitId) {
      return [{ label: 'Create Benefit' }]
    }

    return [
      {
        label: formik.values?.metaData?.name?.en ?? 'Benefit',
        routeTo: `${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}/${benefitId}`,
      },
      { label: 'Edit' },
    ]
  })()

  return (
    <>
      <Breadcrumbs items={breadcrumbsItems} />

      <BenefitsAdministrationTemplate benefitId={benefitId}>
        <Form key={`from-${benefitId}`} providerValue={formik}>
          <BasicBlock className={styles.wrapper}>
            <Tabs
              show
              hideNavigation={!Boolean(benefitId)}
              value={language}
              onClick={handleClickTab}
            >
              {Object.keys(formik.values.metaData.name).map((l: string) => (
                <Tabs.Item
                  key={l}
                  id={l}
                  title={
                    LANG_OPTIONS.find((option) => option.value === l)?.label ??
                    ''
                  }
                >
                  <MutateBenefitsAdministrationLangData
                    language={l}
                    onRemove={handleRemoveLanguage}
                  />
                </Tabs.Item>
              ))}

              {showAddLangTab ? (
                <Tabs.Item id="newLang" title="Add Language +" />
              ) : null}
            </Tabs>
          </BasicBlock>

          <div className={styles.bottomButtons}>
            <Button
              className={styles.cancel}
              to={`/${organizationId}${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}`}
              template="secondary"
            >
              Cancel
            </Button>

            <Button
              className={styles.submit}
              iconAfter="ArrowSelect"
              loading={formik.isSubmitting}
              onClick={handleSubmitForm}
            >
              {!benefitId ? 'Publish' : 'Save'}
            </Button>
          </div>

          {showNewLangModal ? (
            <MutateBenefitsAdministrationAddLangModal
              onSelect={handleAddNewLanguage}
              onClose={() => setShowNewLangModal(false)}
            />
          ) : null}
        </Form>
      </BenefitsAdministrationTemplate>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          title={result === 'success' ? undefined : 'Error!'}
          description={result === 'success' ? undefined : result}
          onClose={() => setResult(null)}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationPage,
  'is_enable_mutate_benefits_administration_page',
  true,
)
