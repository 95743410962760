import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill-new'
//TODO we should use our inner Quill component
import * as yup from 'yup'

import { Button, Form, Input, Modal, SubmitButton } from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { UseCreateEmailTemplate } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './new-email-template-modal.module.scss'

interface INewEmailTemplateModal {
  refetch: () => void
}

const NewEmailTemplateModal = (props: INewEmailTemplateModal) => {
  const { refetch } = props
  const {
    setShowListEmailTemplateModal,
    setShowNewEmailTemplateModal,
    emailTemplateSelected,
    setEmailTemplateSelected,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const [emailBody, setEmailBody] = useState<string>('')
  const createNewEmailTemplate = UseCreateEmailTemplate()

  const formik = useFormik({
    initialValues: {
      id: '',
      subject: '',
      description: '',
      body: '',
      type: 'PRIVATE',
    },
    onSubmit: (values: any) => {
      if (values.id === '') {
        delete values.id
      }

      createNewEmailTemplate
        .mutateAsync(values)
        .then(() => {
          refetch()
          setShowNewEmailTemplateModal(false)
          setShowListEmailTemplateModal(true)
        })
        .catch((error) => console.log(error))
    },
    validationSchema: yup.object().shape({
      subject: yup.string().required('Subject is a required field'),
      description: yup.string().required('Description is a required field'),
      body: yup.string().required(),
    }),
  })

  useEffect(() => {
    if (emailTemplateSelected) {
      setEmailBody(emailTemplateSelected.body)
      formik.setValues({
        id: emailTemplateSelected.id || '',
        subject: emailTemplateSelected.subject,
        description: emailTemplateSelected.description,
        body: emailTemplateSelected.body,
        type: 'PRIVATE',
      })
      setEmailTemplateSelected(undefined)
    }
  }, [emailTemplateSelected])

  const handleChangeBody = (value: string) => {
    formik.setFieldValue('body', value)
    setEmailBody(value)
  }

  return (
    <Modal
      id="manage-template-modal"
      onClose={() => setShowNewEmailTemplateModal(false)}
    >
      <div className={styles.modalContainer}>
        <div className={styles.title}>New Template</div>
        <Form className={styles.form} providerValue={formik}>
          <Input required name="subject" label="Subject" />

          <Input required name="description" label="Description" />

          <div>
            <label className={styles.labelYSWYG}>
              Message<span> *</span>
            </label>
            <ReactQuill
              className={styles.emailSender}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  ['link', 'image'],
                ],
              }}
              value={emailBody}
              onChange={handleChangeBody}
              placeholder="Your Message ... "
              tabIndex={10}
            />
            {formik.errors.body && (
              <span className={styles.bodyError}>
                Message is a required field
              </span>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              onClick={() => setShowNewEmailTemplateModal(false)}
              className={styles.altButton}
              size="small"
            >
              Cancel
            </Button>
            <SubmitButton
              size="small"
              className={styles.saveButton}
              loading={formik.isSubmitting}
            >
              Save
            </SubmitButton>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  NewEmailTemplateModal,
  'is_create_email_template_enabled',
)
