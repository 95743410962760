import cx from 'classnames'
import { useEffect, useState } from 'react'

import { BasicBlock } from 'src/common/components'
import { IActionItem } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './action-items.module.scss'

interface ActionItemsProps {
  items?: IActionItem[]
}

const ActionItems = (props: ActionItemsProps) => {
  const { items } = props

  const [actions, setActions] = useState<IActionItem[]>([])

  useEffect(() => {
    const result: IActionItem[] = [...(items || [])]

    setActions(result)
  }, [items])

  if (!actions.length) {
    return null
  }

  const handleCheckbox = (item?: IActionItem) => {
    setActions(
      actions.map((_item) => {
        if (!!_item?.id && _item.id === item?.id) {
          _item.status = true
        }
        return _item
      }),
    )
  }

  return (
    <BasicBlock
      className={styles.wrapper}
      title="Action Items"
      headerType="alert"
      headerIcon="FlagCircle"
      paddings="small"
      isShowWrapper={false}
    >
      {actions.map((action, i) => (
        <div
          key={i}
          className={cx(
            styles.actionItem,
            action.status ? styles.actionItemActive : null,
          )}
          onClick={() => {
            handleCheckbox(action)
          }}
        >
          <span /> {action.title}
        </div>
      ))}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ActionItems,
  'is_enable_action_items',
  true,
)
