import cx from 'classnames'

import { useAppSelector } from '../../../../../../v2/store/hooks'
import TableCoreCell from './TableCoreCell'
import styles from './table-core-row.module.scss'

//TODO mb concat public interface and local

interface ITableCoreRow {
  className?: string
  index: number
  id?: string
  meta?: any
  editable?: boolean
  editLinkTemplate?: string | ((item: any) => string)
  onClick?: (item: any) => void
  onDoubleClick?: (item: any) => void
  children: any[] //TODO type
}

const TableCoreRow = (props: React.PropsWithChildren<ITableCoreRow>) => {
  const {
    className,
    index,
    id,
    editable,
    meta,
    editLinkTemplate,
    onClick,
    onDoubleClick,
    children,
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const handleClickRow = () => {
    if (onClick) {
      onClick(props)
    }
  }

  const handleDoubleClickRow = () => {
    if (onDoubleClick) {
      onDoubleClick(props)
    }
  }

  const link = (() => {
    let result

    if (editable && id) {
      if (editLinkTemplate) {
        const editLink =
          typeof editLinkTemplate === 'function'
            ? editLinkTemplate(meta)
            : editLinkTemplate

        result = editLink ? editLink.replace(':id', id) : undefined
      }
    }

    return result
  })()

  return (
    <tr
      className={cx(
        styles.tableRowWrapper,
        styles[`${currentTheme}Slice`],
        editable && styles.editable,
        className,
      )}
      onClick={handleClickRow}
      onDoubleClick={handleDoubleClickRow}
      id={id}
    >
      {children
        ?.filter((c: any) => c !== null) //TODO ts
        ?.map(
          (
            cell: any,
            i: number, //TODO cell type
          ) => (
            <TableCoreCell {...cell.props} key={`${index}-${i}`} link={link}>
              {cell.props.children}
            </TableCoreCell>
          ),
        )}
    </tr>
  )
}

export default TableCoreRow
