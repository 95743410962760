import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutationDocumentPersonDataloader = () =>
  useMutation({
    mutationFn: async (data: { fileKey: string }) => {
      const { fileKey } = data

      const response = await api.post(
        `/documents/dataloader/update-from-s3-csv`,
        {
          fileKey,
        },
      )

      return response.data
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useMutationDocumentPersonDataloader
