import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutationDeleteMembershipDomain = () =>
  useMutation({
    mutationFn: async (id: string) => {
      return api.delete(`/membership-domain/${id}`)
    },
  })

export default useMutationDeleteMembershipDomain
