import cx from 'classnames'

import { BasicBlock, Button, Icons, Table } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useGetEmailDomains } from 'src/default/hooks'
import { IEmailDomain } from 'src/v2/commons/interface/membershipEmailDomain'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import styles from './email-domains-page.module.scss'

const EmailDomainsPage = () => {
  const { data: emailDomains, isLoading, isSuccess } = useGetEmailDomains()

  return (
    <BasicTemplate
      title="Email Domains"
      header={
        <PolicyCheckHOC policyName="is_create_email_domain_enabled">
          <Button
            to={`${PRIVATE_ROUTES.EMAIL_DOMAINS.path}/create`}
            icon="Plus"
            size="small"
            className={styles.buttonHeader}
          >
            New Email Domain
          </Button>
        </PolicyCheckHOC>
      }
      isLoading={isLoading}
    >
      <BasicBlock className={styles.wrapper} paddings="big">
        {isSuccess && emailDomains?.data?.length > 0 && (
          <Table>
            {emailDomains.data.map((emailDomain: IEmailDomain) => (
              <Table.Row
                key={emailDomain.id}
                id={emailDomain.id}
                editable
                editLink={`${PRIVATE_ROUTES.EMAIL_DOMAINS.path}/:id`}
              >
                <Table.Column title="Email Address - Domain">
                  {emailDomain.domain}
                </Table.Column>

                <Table.Column title="Is WBF Register">
                  <span
                    className={cx(
                      emailDomain.isWBFRegister && styles.checked,
                      styles.checkbox,
                    )}
                  >
                    <Icons.Check />
                  </span>
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        )}
      </BasicBlock>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EmailDomainsPage,
  'is_get_all_email_domains_enabled',
)
