import { FormikContextType } from 'formik'

import { Form, Select } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './deactivation-benefit-history-filter.module.scss'

const FILTER_OPTIONS = [
  { value: '', label: 'Select a option' },
  { value: 'reactivated', label: 'Reactivated' },
  { value: 'decision', label: 'Decision' },
  { value: 'disputed', label: 'Disputed' },
]

interface DeactivationBenefitHistoryFilterProps {
  providerValue: FormikContextType<any>
}

const DeactivationBenefitHistoryFilter = (
  props: DeactivationBenefitHistoryFilterProps,
) => {
  const { providerValue } = props

  return (
    <Form providerValue={providerValue}>
      <Select
        className={styles.select}
        name="filter"
        options={FILTER_OPTIONS}
      />
    </Form>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitHistoryFilter,
  'is_enable_deactivation_benefit_history_filter',
  true,
)
