import { CreateUserError } from 'src/v2/domains/user/models/user.model'

export const isWeakPasswordMessage = (message: string) =>
  message.includes('Weak password!') ? CreateUserError.WEAK_PASSWORD : message

export const getErrorMessages = (message: string[]) => {
  if (Array.isArray(message)) {
    if (message.length === 1) {
      return message[0].split(',').join('\n')
    }
    return (message ?? []).join(', ')
  }
  return message ?? ''
}
