import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from '@v2/store/hooks'
import { WbfPolicies } from '@workers-benefit-fund/shared-data'

import { api } from 'src/common/api'

interface GetPersonTagsParams {
  personId?: string
}

const useGetPersonTagsBlackCarFund = (params: GetPersonTagsParams) => {
  // TODO (marco): this should be moved to something generic. This is a patch. Check this thread to get more information: https://wbf-workspace.slack.com/archives/C066MEHM25B/p1731600654962289
  const { policies } = useAppSelector((state) => state.user.userProfile)
  const { personId } = params

  let url = `/persons/${personId}/tags-black-car-fund`

  return useQuery({
    queryKey: [`getPersonTagsBlackCarFund`, url],
    queryFn: async () => await api.get(url),
    enabled: policies.includes(
      WbfPolicies.is_get_person_tags_black_car_fund_enabled,
    ),
  })
}

export default useGetPersonTagsBlackCarFund
