import { Icons } from '@workers-benefit-fund/wbf-ui-components'
import { useFormik } from 'formik'
import { useEffect } from 'react'

import {
  Form,
  Input,
  InputDateAndAge,
  SelectLanguage,
} from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IPerson } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { MultiSelectCarCompanies } from 'src/default/components'
import { EnumDocumentType } from 'src/default/interfaces'

import { useRouter } from '../../../../default/helpers/navigation'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './form-duplicate-person.module.scss'

interface FormDuplicatePersonProps {
  person: IPerson
  highlightDuplicateFields?: boolean
  otherPerson?: IPerson
  isBenestream?: boolean
}

const FormDuplicatePerson = (props: FormDuplicatePersonProps) => {
  const router = useRouter()

  const { person, highlightDuplicateFields, otherPerson, isBenestream } = props
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const formik = useFormik<IPerson>({
    initialValues: {
      ...person,
      birthDate: dayjs(person.birthDate).tz().format('MM/DD/YYYY'),
    },
    onSubmit: () => {},
  })

  const transformPerson = (record: IPerson) => ({
    ...record,
    tlc: record.documents?.find((item) => item.type === EnumDocumentType.TLC)
      ?.number,
    driverLicense: record.documents?.find(
      (item) => item.type === EnumDocumentType.DRIVER_LICENSE,
    )?.number,
    birthDate: dayjs(record.birthDate).tz().format('MM/DD/YYYY'),
  })

  //TODO remove these ts-ignore-s
  const duplicateFields =
    highlightDuplicateFields && otherPerson
      ? Object.keys(transformPerson(person)).filter(
          (key) =>
            // @ts-ignore
            transformPerson(otherPerson)[key] ===
              // @ts-ignore
              transformPerson(person)[key] &&
            // @ts-ignore
            Boolean(transformPerson(person)[key]),
        )
      : []

  const duplicatedProps = (name: string) =>
    highlightDuplicateFields
      ? {
          inputClassName: duplicateFields.includes(name)
            ? '!bg-warning/20'
            : '',
          labelClassName: duplicateFields.includes(name) ? '!text-warning' : '',
        }
      : {}

  const enrollments = () =>
    person.enrollments?.filter(
      (item) =>
        item.associatedBenefitContract.organization.id ===
          currentOrganizationId && item.isVerified,
    ) || []

  const enrollmentNotVerified = () =>
    person.enrollments?.find(
      (item) => item.associatedBenefitContract.organization.id,
    )

  useEffect(() => {
    formik.setValues(person)
  }, [person])

  return (
    <Form providerValue={formik} className="py-5">
      <div className="relative">
        <Input name="firstName" label="First Name" disabled required />
        {highlightDuplicateFields ? (
          <PolicyCheckHOC policyName="is_get_person_by_id_enabled">
            <a
              onClick={() => router.push(`/leads/${person.id}`)}
              className="absolute top-[calc(50%-10px)] right-2 cursor-pointer"
              target="_blank"
            >
              <Icons.Share className="fill-primary" />
            </a>
          </PolicyCheckHOC>
        ) : null}
      </div>
      <Input name="lastName" label="Last Name" disabled required />
      <Input name="gender" label="Gender" disabled required />
      <InputDateAndAge name="birthDate" label="Birthdate" disabled required />
      <Input
        name="email"
        label="Email"
        disabled
        {...duplicatedProps('email')}
        required
      />
      <Input
        name="phoneNumber"
        label="Main Mobile Phone"
        disabled
        {...duplicatedProps('phoneNumber')}
        required
      />
      <Input
        name="otherPhoneNumber"
        label="Secondary Mobile Phone"
        value={person?.otherPhoneNumber?.[0]}
        placeholder="888-888-888"
        disabled
      />
      <SelectLanguage
        name="preferredLanguage"
        label="Preferred Language"
        placeholder="Select Preferred Language"
        disabled
        required
      />
      {!isBenestream ? (
        <>
          <Input
            name="driverLicense"
            label="Driver License"
            value={
              person?.documents?.find(
                (item) => item.type === EnumDocumentType.DRIVER_LICENSE,
              )?.number
            }
            disabled
            {...duplicatedProps('driverLicense')}
          />
          <Input
            name="tlc"
            label="TLC"
            value={
              person?.documents?.find(
                (item) => item.type === EnumDocumentType.TLC,
              )?.number
            }
            disabled
            {...duplicatedProps('tlc')}
          />
          <MultiSelectCarCompanies
            name="companies"
            label="Which Company(s) do you work for?"
            disabled
            required
          />
        </>
      ) : null}
      <>
        <Input
          name="createdDate"
          label="Created Date"
          value={formatDate(person?.createdAt, 'MM/DD/YYYY')}
          disabled
        />
        <div />
      </>
      {enrollments().length ? (
        enrollments().map((enrollment) => (
          <>
            <div className={styles.abcName}>
              {enrollment.associatedBenefitContract.name}
            </div>
            <Input
              name="enrollmentUpdatedAt"
              label="Enrollment Last Updated Date"
              disabled
              value={formatDate(
                enrollment.enrollmentStatusLastModifiedDate,
                'MM/DD/YYYY',
              )}
            />

            <Input
              name="enrollmentStatusLastModifiedBy"
              label="Enrollment Last Updated By"
              disabled
              value={`${
                enrollment.enrollmentStatusLastModifiedBy?.firstName ?? ''
              } ${enrollment.enrollmentStatusLastModifiedBy?.lastName ?? ''}`}
            />
          </>
        ))
      ) : (
        <>
          <div className={styles.abcName}>
            {enrollmentNotVerified()?.associatedBenefitContract?.name}
          </div>
          <Input
            name="updatedAt"
            label="Updated Date"
            disabled
            value={formatDate(person.updatedAt, 'MM/DD/YYYY')}
          />

          <Input
            name="updatedByFirstName"
            label="Updated By"
            disabled
            value={`${person.updatedBy?.firstName ?? ''} ${
              person.updatedBy?.lastName ?? ''
            }`}
          />
        </>
      )}
    </Form>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  FormDuplicatePerson,
  'is_enable_duplicate_person_detail',
  true,
)
