import cx from 'classnames'
import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Icons } from 'src/common/components'

import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './checkbox.module.scss'

interface CheckboxProps {
  className?: string
  innerClassName?: string
  required?: boolean
  disabled?: boolean
  name: string
  label?: string
  value?: boolean | null
  topLabel?: boolean
  width?: string
  tabletWidth?: string
  onChange?: (value: boolean) => void
  withError?: boolean
  strictError?: string
  classNameWrapper?: string
}

const Checkbox = (props: CheckboxProps) => {
  const {
    className,
    classNameWrapper,
    innerClassName,
    required,
    disabled,
    name,
    label = '',
    value,
    topLabel,
    width,
    tabletWidth,
    onChange,
    withError = true,
    strictError,
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [touched, setTouched] = useState(false)

  const [field, meta, { setValue }] = useField({ name })

  const { isSubmitting } = useFormikContext()

  const handleChange = (newValue: boolean) => {
    if (disabled) {
      return
    }

    setTouched(true)

    if (typeof value === 'undefined') {
      setValue(newValue)
    }

    if (onChange) {
      onChange(newValue)
    }
  }

  const currentValue = typeof value === 'boolean' ? value : field.value

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
  }, [isSubmitting])

  return (
    <div
      className={cx(
        !label && (!strictError || !(touched && meta.error)) && styles.checkbox,
        tabletWidth && styles[`tabletW${tabletWidth}`],
        width && styles[`w${width}`],
        classNameWrapper,
      )}
    >
      <div
        className={cx(
          styles.wrapper,
          styles[`${currentTheme}Slice`],
          topLabel && styles.withTopLabel,
          ((touched && meta.error) || strictError) && styles.error,
          className,
        )}
      >
        {topLabel && label ? (
          <label htmlFor={name}>
            {label} {required ? <span>*</span> : null}
          </label>
        ) : null}

        <span
          className={cx(
            styles.inner,
            currentValue && styles.checked,
            disabled && styles.disabled,
            innerClassName,
          )}
          onClick={() => handleChange(!currentValue)}
        >
          <Icons.Check />
        </span>
        {!topLabel ? (
          <span>
            {label}{' '}
            {required ? <span className={styles.required}>*</span> : null}
          </span>
        ) : null}
      </div>
      {withError && (strictError || (touched && meta.error)) ? (
        <div className={styles.errorComment}>
          {strictError
            ? !touched
              ? strictError
              : null
            : touched && meta.error
            ? meta.error
            : null}
        </div>
      ) : null}
    </div>
  )
}

export default Checkbox
