import { useState } from 'react'

import { Icons } from 'src/common/components'

import styles from './household-income-information.module.scss'

interface Props {
  householdIncome: number
}

const BenestreamPersonFormHouseholdIncomeInformation = (props: Props) => {
  const { householdIncome = 0 } = props

  const [period, setPeriod] = useState('monthly')

  const PeriodComponent = (
    <div className={styles.checkIcon}>
      <Icons.CheckFilled width={15} height={15} />
    </div>
  )

  const totalIncome =
    period === 'annually' ? householdIncome * 12 : householdIncome

  const incomeFormatted = totalIncome
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(totalIncome))
    : '$ 0'

  return (
    <div>
      <div className={styles.description}>Income Period</div>
      <div className={styles.content}>
        <div className={styles.subContent}>
          <div className={styles.period} onClick={() => setPeriod('monthly')}>
            {period === 'monthly' ? PeriodComponent : null}
            Monthly
          </div>
          <div className={styles.period} onClick={() => setPeriod('annually')}>
            {period === 'annually' ? PeriodComponent : null}
            Annually
          </div>
        </div>
        <div className={styles.subContent}>
          <div className={styles.title}>Total Household Income</div>
          <div className={styles.income}>{incomeFormatted}</div>
        </div>
      </div>
    </div>
  )
}

export default BenestreamPersonFormHouseholdIncomeInformation
