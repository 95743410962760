import cx from 'classnames'

import { useAppSelector } from '../../../../../v2/store/hooks'
import { dataLoaderObjects } from '../dataLoader.constants'
import { ActionType } from '../dataLoaderWizard.interface'
import styles from './object-step.module.scss'

interface ObjectStep {
  actionType: ActionType
  object: string
  changeObjectValue: (object: string) => void
}

const ObjectStep = (props: ObjectStep) => {
  const { actionType, object, changeObjectValue } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div className={cx(styles.cardContent, styles[`${currentTheme}Slice`])}>
      <div className={cx(styles.objectTable)}>
        <div className={cx(styles.box, styles.title)}>Object</div>
        {dataLoaderObjects[actionType]?.map((item) => (
          <div
            className={cx(
              styles.box,
              styles.content,
              item.id === object && styles.active,
            )}
            onClick={() => changeObjectValue(item.id)}
            key={item.id}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ObjectStep
