import AdministrationView from '@v2/domains/administration/views/Administration.view'
import PoliciesView from '@v2/domains/policy/views/Policies.view'
import RoleView from '@v2/domains/role/views/Role.view'
import RolesView from '@v2/domains/role/views/Roles.view'
import CreateUserView from '@v2/domains/user/views/CreateUserView'
import SingleUserView from '@v2/domains/user/views/SingleUserView'
import UsersAdministrationPage from '@v2/domains/user/views/UsersView'
import { RouteObject } from 'react-router-dom'

import AccountsPage from 'src/benestream/pages/Accounts/AccountsPage'
import SingleAccountPage from 'src/benestream/pages/Accounts/SingleAccountPage'
import DataLoaderPage from 'src/benestream/pages/DataLoader/DataLoaderPage'
import CreateLeadPage from 'src/benestream/pages/Leads/CreateLeadPage'
import LeadsPage from 'src/benestream/pages/Leads/LeadsPage'
import SingleLeadPage from 'src/benestream/pages/Leads/SingleLeadPage'
import SearchResultPage from 'src/benestream/pages/Search/SearchResultPage'
import SettingsPage from 'src/benestream/pages/Settings/SettingsPage'
import { BasicTemplateWrapper } from 'src/benestream/templates'
import { CRMTemplate } from 'src/common/templates'
import AdministrationLayout from 'src/default/layouts/AdministrationLayout'
import ABCsAdministrationPage from 'src/default/pages/Administration/ABCsAdministration/ABCsAdministrationPage/ABCsAdministrationPage'
import BenefitsAdministrationPage from 'src/default/pages/Administration/BenefitsAdministration/BenefitsAdministrationPage'
import AdministrationBenefitsCreate from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage'
import MutateBenefitsAdministrationPage from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage'
import AdministrationBenefitsTemplatePreview from 'src/default/templates/AdministrationBenefitsTemplatePreview/AdministrationBenefitsTemplatePreview'

export const benestreamRoutes: RouteObject[] = [
  {
    path: 'leads',
    element: <LeadsPage />,
  },
  {
    path: 'leads/:personId',
    element: <SingleLeadPage />,
  },
  {
    path: 'leads/create',
    element: <CreateLeadPage />,
  },
  {
    path: 'accounts',
    element: <AccountsPage />,
  },
  {
    path: 'accounts/:personId',
    element: <SingleAccountPage />,
  },
  {
    path: 'search/:searchValue',
    element: <SearchResultPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'upload-file',
    element: <DataLoaderPage />,
  },
  {
    element: <AdministrationLayout />,
    children: [
      {
        path: 'administration',
        element: <AdministrationView />,
      },
      {
        path: 'administration/policies',
        element: <PoliciesView />,
      },
      {
        path: 'administration/roles',
        element: <RolesView />,
      },
      {
        path: 'administration/roles/:roleId',
        element: <RoleView />,
      },
      {
        path: 'administration/users',
        element: <UsersAdministrationPage />,
      },
      {
        path: 'administration/users/create',
        element: <CreateUserView />,
      },
      {
        path: 'administration/users/:userId',
        element: <SingleUserView />,
      },

      {
        path: 'administration/abcs',
        element: <ABCsAdministrationPage />,
      },
      {
        path: 'administration-benefits',
        element: <BenefitsAdministrationPage />,
      },
      {
        path: 'administration-benefits/_preview',
        element: <AdministrationBenefitsTemplatePreview />,
      },
      {
        path: 'administration-benefits/:benefitId',
        element: <MutateBenefitsAdministrationPage />,
      },
      {
        path: 'administration-benefits/create',
        element: <AdministrationBenefitsCreate />,
      },
      {
        path: 'administration/policies',
        element: <PoliciesView />,
      },
    ],
  },
]
