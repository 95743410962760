import cx from 'classnames'

import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import styles from './modal-result.module.scss'

interface ModalResultProps extends IModal {
  isSuccess?: boolean
  title?: string
  description?: string
  handleOnClickButton?: any
  showButtons?: boolean
  textSuccessButton?: string
  showCancelButton?: boolean
}

const ModalResult = (props: ModalResultProps) => {
  const {
    isSuccess = true,
    title = props.isSuccess ? 'Changes Saved!' : 'Saving Failed!',
    description = props.isSuccess
      ? 'Record updated successfully'
      : 'The record could not be saved',
    handleOnClickButton,
    showButtons,
    textSuccessButton,
    showCancelButton = true,
    ...rest
  } = props

  const CurrentIcon = Icons[isSuccess ? 'Check' : 'Warning']

  return (
    <Modal id="modalResult" {...rest}>
      <div className={styles.wrapper}>
        <div
          className={cx(styles.icon, styles[isSuccess ? 'success' : 'error'])}
        >
          <CurrentIcon />
        </div>

        <div className={styles.inner}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>

          {showButtons && (
            <div className={styles.buttonsContainer}>
              {showCancelButton && (
                <Button
                  onClick={() => handleOnClickButton(false)}
                  className={styles.altButton}
                  size="small"
                >
                  Cancel
                </Button>
              )}
              <Button onClick={() => handleOnClickButton(true)} size="small">
                {textSuccessButton || 'OK'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalResult
