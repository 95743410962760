import type { FormikErrors } from 'formik'

interface CheckValidationInOtherFormTabsProps {
  $form?: HTMLDivElement | null
  validationResult: FormikErrors<any>
  onResult?: (result: string | null) => void
}

export const checkValidationInOtherFormTabs = (
  props: CheckValidationInOtherFormTabsProps,
) => {
  const { $form, validationResult, onResult } = props

  if (onResult) {
    const errorsFromAnotherTab = Object.entries(validationResult).reduce(
      (a, [key, value]) => {
        if (value) {
          const $input = $form
            ? $form.querySelector(`[data-name="${key}"]`)
            : null
          if (!$input) {
            a[key] = value
          }
        }

        return a
      },
      {} as any, //TODO mb add type
    )

    if (Object.keys(errorsFromAnotherTab).length) {
      onResult(Object.values(errorsFromAnotherTab).join(', '))

      return false
    }
  }

  return true
}
