import { PRIVATE_ROUTES } from './routing'

export const ROUTES_MENU_ITEMS_BENESTREAM = [
  {
    id: 'leads',
    route: PRIVATE_ROUTES.LEADS,
    title: 'Leads',
    icon: 'Filter',
  },
  {
    id: 'accounts',
    route: PRIVATE_ROUTES.ACCOUNTS,
    title: 'Accounts',
    icon: 'Account',
  },
  {
    id: 'administration',
    route: PRIVATE_ROUTES.ADMINISTRATION,
    title: 'Administration',
    icon: 'Benefit',
    role: 'admin',
  },
]

export const ROUTES_SETTINGS_MENU_ITEMS = [
  {
    id: 'config',
    route: PRIVATE_ROUTES.SETTINGS,
    title: 'Settings',
    icon: 'Settings',
  },
]
