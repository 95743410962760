import cx from 'classnames'

import { Icons } from 'src/common/components'
import { IOption, IOptionsGroup } from 'src/common/interfaces'

import { BasicMultiSelectGroupsOptions } from '../components'
import styles from './basic-multi-select-groups-item.module.scss'

interface BasicMultiSelectGroupsItemProps extends IOptionsGroup {
  showGroup: boolean
  isActive: boolean
  currentValue: string[]
  options: IOption[]
  onToggle: () => void
  onChange: (newValue: string) => void
}

const BasicMultiSelectGroupsItem = (props: BasicMultiSelectGroupsItemProps) => {
  const {
    showGroup,
    isActive,
    label,
    id,
    currentValue,
    options,
    onToggle,
    onChange,
  } = props

  const handleToggle = () => {
    if (showGroup) {
      onToggle()
    }
  }

  const currentOptions = options.filter((option) =>
    option.meta.groups.includes(id),
  )

  return (
    <div className={styles.wrapper}>
      {showGroup ? (
        <div className={styles.inner} onClick={handleToggle}>
          <div className={cx(styles.arrow, isActive && styles.active)}>
            <Icons.ArrowSelect />
          </div>

          <div className={styles.label}>{label}</div>
        </div>
      ) : null}

      {!showGroup || isActive ? (
        <BasicMultiSelectGroupsOptions
          currentValue={currentValue}
          options={currentOptions}
          onChange={onChange}
        />
      ) : null}
    </div>
  )
}

export default BasicMultiSelectGroupsItem
