import React, { useMemo } from 'react'

import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'
import {
  WashingtonStateFieldConfigNames,
  WashingtonStateFieldsConfig,
  WashingtonStateRecord,
} from 'src/common/interfaces/washington-state'

import styles from './washington-state-confirmation-modal.module.scss'

interface WashingtonStateConfirmationModalProps extends IModal {
  currentRecords: WashingtonStateRecord
  changedRecords: WashingtonStateRecord
  washingtonStateFieldsConfig: WashingtonStateFieldsConfig
  onSubmit: () => void
  isSubmitting: boolean
  onClose: () => void
}

interface ModifiedRecords {
  name: WashingtonStateFieldConfigNames
  currentValue: string | string[]
  changedValue: string | string[]
}

const WashingtonStateConfirmationModal = (
  props: WashingtonStateConfirmationModalProps,
) => {
  const {
    washingtonStateFieldsConfig,
    currentRecords,
    changedRecords,
    isSubmitting,
    onSubmit,
    onClose,
  } = props

  const compareRecords = (
    currentRecords: WashingtonStateRecord,
    changedRecords: WashingtonStateRecord,
  ): ModifiedRecords[] => {
    const differences: ModifiedRecords[] = []

    const keys = Object.keys(
      currentRecords,
    ) as WashingtonStateFieldConfigNames[]

    for (const key of keys) {
      const currentValue = currentRecords[key]
      const changedValue = changedRecords[key]

      if (Array.isArray(currentValue) && Array.isArray(changedValue)) {
        if (JSON.stringify(currentValue) !== JSON.stringify(changedValue)) {
          differences.push({
            name: key,
            currentValue: currentValue,
            changedValue: changedValue,
          })
        }
      } else if (currentValue !== changedValue) {
        differences.push({
          name: key,
          currentValue: currentValue,
          changedValue: changedValue,
        })
      }
    }

    return differences
  }

  const modifiedRecords = useMemo(() => {
    return compareRecords(currentRecords, changedRecords)
  }, [currentRecords, changedRecords])

  if (modifiedRecords.length === 0) {
    onClose()
    return null
  }

  return (
    <Modal
      id="washingtonStateConfirmationModal"
      template="big"
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>Review Changes</div>
        <div>
          <div className={styles.alert}>
            <div className={styles.icon}>
              <Icons.WarningAngular />
            </div>
            <div className={styles.description}>
              You are about to update the PFML Submission. Please review the
              changes before saving.
            </div>
          </div>
          <div className={styles.changes}>
            <div className={styles.column}>
              <h3>Original Values</h3>
              {modifiedRecords.map((field) => {
                const Component =
                  washingtonStateFieldsConfig[field.name]?.component
                const props = washingtonStateFieldsConfig[field.name]?.props

                if (Component === undefined) {
                  return null
                }

                if (field.name === 'raceEthnicity') {
                  return (
                    <Component
                      key={field.name}
                      {...props}
                      name={`${field.name}-current`}
                      value={field.currentValue}
                      isDisabledOptions={true}
                    />
                  )
                }

                return (
                  <Component
                    key={field.name}
                    {...props}
                    name={`${field.name}-current`}
                    value={field.currentValue}
                    disabled={true}
                  />
                )
              })}
            </div>

            <div className={styles.column}>
              <h3>Updated Values</h3>
              {modifiedRecords.map((field) => {
                const Component =
                  washingtonStateFieldsConfig[field.name]?.component
                const props = washingtonStateFieldsConfig[field.name]?.props

                if (Component === undefined) {
                  return null
                }

                if (field.name === 'raceEthnicity') {
                  return (
                    <Component
                      key={field.name}
                      {...props}
                      name={`${field.name}-changed`}
                      value={field.changedValue}
                      isDisabledOptions={true}
                    />
                  )
                }

                return (
                  <Component
                    key={field.name}
                    {...props}
                    name={`${field.name}-changed`}
                    value={field.changedValue}
                    disabled={true}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <footer className={styles.footer}>
          <Button
            className={styles.cancelButton}
            template="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={styles.submitButton}
            iconAfter="ArrowSelect"
            onClick={onSubmit}
            loading={isSubmitting}
          >
            Save
          </Button>
        </footer>
      </div>
    </Modal>
  )
}

export default WashingtonStateConfirmationModal
