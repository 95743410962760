import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setUserProfile } from '@v2/domains/user/models/user.store'

import { AssignPersonModel } from '../assignPerson.model'

const initialState: AssignPersonModel = {
  filters: {
    selectedItems: [],
  },
}

const searchStore = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addSelectedItemSearch: (
      state,
      action: PayloadAction<AssignPersonModel['filters']['selectedItems'][0]>,
    ) => {
      state.filters.selectedItems = [
        ...state.filters.selectedItems,
        action.payload!,
      ]
    },
    removeSelectedItemSearch: (
      state,
      action: PayloadAction<AssignPersonModel['filters']['selectedItems'][0]>,
    ) => {
      state.filters.selectedItems = state.filters.selectedItems.filter(
        (item) => item !== action.payload!,
      )
    },
    setIsAllSelectedSearch: (
      state,
      action: PayloadAction<{
        isAllSelected: boolean
        allSearchResults: AssignPersonModel['filters']['selectedItems']
      }>,
    ) => {
      if (action.payload.isAllSelected) {
        const uniqueSelected = new Set([
          ...state.filters.selectedItems,
          ...(action.payload.allSearchResults as string[]),
        ])
        state.filters.selectedItems = Array.from(uniqueSelected)
        return
      }
      const filteredSelected = state.filters.selectedItems.filter(
        (item) => !action.payload.allSearchResults.includes(item),
      )
      state.filters.selectedItems = filteredSelected
    },
    resetStoreSearch: (state) => {
      state.filters = { ...initialState.filters }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserProfile, (state) => {
      state.filters = { ...initialState.filters }
    })
  },
})

export const {
  addSelectedItemSearch,
  removeSelectedItemSearch,
  setIsAllSelectedSearch,
  resetStoreSearch,
} = searchStore.actions

export default searchStore.reducer
