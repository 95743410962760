import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import { Button, SubmitButton } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './create-lead-bottom-buttons.module.scss'

const CreateLeadBottomButtons = () => {
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <div className={styles.wrapper}>
      <Button
        to={`/${organizationId}${PRIVATE_ROUTES.LEADS.path}`}
        template="secondary"
      >
        Cancel
      </Button>

      <SubmitButton className={styles.saveButton} iconAfter="ArrowSelect">
        Save
      </SubmitButton>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadBottomButtons,
  'is_create_benestream_person_primary_enabled',
)
