import cx from 'classnames'

import { Icons } from 'src/common/components'

import { useAppSelector } from '../../../../../v2/store/hooks'
import { DataLoaderResultObject } from '../dataLoaderWizard.interface'
import styles from './summary-step.module.scss'

interface SummaryStepProps {
  result: DataLoaderResultObject
  csvError: string
  resetUpload: () => void
}

const SummaryStep = (props: SummaryStepProps) => {
  const { result, csvError, resetUpload } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const hasError = result.errorCount > 0 || Boolean(csvError)

  return (
    <div
      className={cx(
        styles.cardContent,
        styles.successCard,
        styles[`${currentTheme}Slice`],
      )}
    >
      <div className={cx(styles.iconCheckWrapper, hasError && styles.error)}>
        <div className={hasError ? styles.iconError : styles.iconCheckSucess}>
          {hasError ? <Icons.Warning /> : <Icons.CheckFilled />}
        </div>
      </div>
      <div className={styles.successTitle}>
        {hasError
          ? 'Information Failed to Import!'
          : 'Information Imported Successfully!'}
      </div>
      <div className={styles.successDescription}>
        {hasError
          ? 'Please review the information and try again.'
          : 'All the fields have been imported.'}
      </div>
      {!result.errorUrl && !result.successUrl && csvError ? (
        <div className={styles.successDescription}>{csvError}</div>
      ) : (
        <>
          <div>
            Task Resume:{' '}
            {result.successCount > 0 ? (
              <a
                href={result.successUrl}
                className={styles.successText}
                download="successReport.csv"
                target="_blank"
                rel="noreferrer"
              >
                {result.successCount} successes
              </a>
            ) : (
              <span className={styles.successText}>
                {result.successCount} successes
              </span>
            )}
            ,{' '}
            {result.errorCount > 0 ? (
              <a
                href={result.errorUrl}
                download="errorReport.csv"
                target="_blank"
                rel="noreferrer"
                className={styles.errorText}
              >
                {result.errorCount} error(s)
              </a>
            ) : (
              <span className={styles.errorText}>
                {result.errorCount} error(s)
              </span>
            )}
          </div>
        </>
      )}
      {csvError || result.errorCount > 0 ? (
        <div className={styles.tryAgainButton} onClick={resetUpload}>
          Try again
        </div>
      ) : null}
    </div>
  )
}

export default SummaryStep
