export const validatePhoneFormat = (str: string) => /^\+[0-9]{10,11}$/.test(str)

export const validateAndFormatPhoneNumber = (str: string) => {
  // Remove unwanted characters and only keep digits
  const cleanedNumber = str?.replace(/\D/g, '')

  // Check if the length is valid, considering the migration changes
  if (cleanedNumber?.length >= 10 && cleanedNumber?.length <= 11) {
    // Add '1' if the number doesn't already start with it, then add '+'
    const formattedNumber = cleanedNumber.startsWith('1')
      ? `+${cleanedNumber}`
      : `+1${cleanedNumber}`

    // Check if the formatted number matches the desired format
    if (validatePhoneFormat(formattedNumber)) {
      return formattedNumber
    }
  }

  return cleanedNumber // Invalid length or format
}

export const getPhoneWithMask = (str: string) => {
  const preventNullStr = str || ''

  return preventNullStr
    .replace('+1', '')
    .replace(/[^0-9.]/g, '')
    .split('')
    .reduce((a, c, i) => {
      if (i == 6) a.push('-')
      a.push(c)
      if (i == 2) a.push('-')
      return a
    }, [] as string[])
    .join('')
}
