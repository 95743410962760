import { FormikContextType } from 'formik'
import { useState } from 'react'

import {
  Button,
  Checkbox,
  FieldWrapper,
  Form,
  Input,
  InputPassword,
  InputPhone,
  SubmitButton,
} from 'src/common/components'
import { IUserMutationData } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import UserFormDeleteModal from './UserFormDeleteModal'
import styles from './user-form.module.scss'

interface UserFormProps {
  formik: FormikContextType<IUserMutationData>
  isCreating?: boolean
}

const UserForm = (props: UserFormProps) => {
  const { formik, isCreating = false } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const isSameUser = userProfile.user.id === formik.values.id

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  return (
    <>
      <Form providerValue={formik}>
        <div className={styles.wrapper}>
          <Input
            required
            name="email"
            label="Email"
            disabled={!isCreating}
            width="33"
          />

          <Input required name="firstName" label="First Name" width="33" />

          <Input required name="lastName" label="Last Name" width="33" />

          <InputPhone
            required
            name="phone"
            label="Mobile Phone"
            width="33"
            tabletWidth="50"
            isCreating={isCreating}
            autoComplete="off"
          />

          <InputPassword
            required={isCreating}
            name="password"
            label={`${!isCreating ? 'New ' : ''}Password`}
            width="33"
            autoComplete="new-password"
          />

          <Input name="aircallUserId" label="Aircall User ID" width="33" />

          <FieldWrapper
            name="hipaaCertified"
            label="HIPAA Certified"
            width="33"
          >
            <Checkbox name="hipaaCertified" />
          </FieldWrapper>

          <div className={styles.fieldPlaceholder} />
        </div>

        <div className={styles.buttonsWrapper}>
          {!isCreating && !isSameUser ? (
            <PolicyCheckHOC policyName="is_enable_delete_user">
              <Button
                template="danger"
                loading={formik.isSubmitting}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
            </PolicyCheckHOC>
          ) : null}

          <PolicyCheckHOC policyName="is_get_users_enabled">
            <SubmitButton loading={formik.isSubmitting}>Save</SubmitButton>
          </PolicyCheckHOC>
        </div>
      </Form>

      {showDeleteModal ? (
        <UserFormDeleteModal
          userId={formik.values.id}
          onClose={() => setShowDeleteModal(false)}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserForm,
  'is_update_user_enabled',
)
