import { FormikContextType } from 'formik'
import { useEffect, useState } from 'react'

import { Select, Table } from 'src/common/components'
import {
  TASK_PRIORITY_OPTIONS,
  TASK_STATUS_OPTIONS,
  TASK_SUBJECT_OPTIONS,
  TASK_TYPE_OPTIONS,
} from 'src/default/config'
import { useGetOrganizationUsers } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'

interface LeadsFilterProps {
  providerValue: FormikContextType<any>
  total: number
}

const TasksFilter = (props: LeadsFilterProps) => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const [usersOfTheOrganization, setUsersOfTheOrganization] = useState<any[]>(
    [],
  )

  const { data: organizationUsers, isSuccess } = useGetOrganizationUsers({
    organizationId: currentOrganizationId ?? '',
  })

  useEffect(() => {
    if (organizationUsers?.data?.length && isSuccess) {
      setUsersOfTheOrganization(
        organizationUsers.data.map((organizationUser: any) => ({
          value: organizationUser.user.id,
          label: `${organizationUser.user.firstName} ${organizationUser.user.lastName}`,
        })),
      )
    }
  }, [organizationUsers, isSuccess])

  return (
    <>
      <Table.Filter {...props} showSaveCSV={false}>
        <Select
          options={[{ label: 'All', value: 'all' }, ...TASK_TYPE_OPTIONS]}
          name="type"
          placeholder="Type"
          withError={false}
          template="filter"
        />

        <Select
          options={[{ label: 'All', value: 'all' }, ...TASK_SUBJECT_OPTIONS]}
          name="subject"
          placeholder="Subject"
          withError={false}
          template="filter"
        />

        <Select
          options={[{ label: 'All', value: 'all' }, ...usersOfTheOrganization]}
          name="assignedToId"
          placeholder="Assigned To"
          withError={false}
          template="filter"
        />

        <Select
          options={[{ label: 'All', value: 'all' }, ...TASK_STATUS_OPTIONS]}
          name="status"
          placeholder="Status"
          withError={false}
          template="filter"
        />

        <Select
          options={[{ label: 'All', value: 'all' }, ...TASK_PRIORITY_OPTIONS]}
          name="priority"
          placeholder="Priority"
          withError={false}
          template="filter"
        />
      </Table.Filter>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TasksFilter,
  'is_enable_tasks_filter_view',
  true,
)
