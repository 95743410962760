import { IPerson, IPersonMutationData } from 'src/common/interfaces'

export const BASIC_PERSON_INITIAL_DATA: IPersonMutationData = {
  email: '',
  phoneNumber: '',
  otherPhoneNumber: '',
  firstName: '',
  lastName: '',
  gender: 'male',
  otherGender: '',
  birthDate: '',
  preferredLanguage: '',
  street: '',
  city: '',
  state: '',
  postalCode: '',
  companies: [],
  source: undefined,
  haveHealthInsurance: '',
  coverageThroughTheState: '',
  pay20PerMonth: '',
  blackCarFund: '',
  rating: '',
  updatedAt: '',
  createdAt: '',
  otherEmail: [],
  createdBy: {} as IPerson,
  updatedBy: {} as IPerson,
}
