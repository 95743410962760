import cx from 'classnames'
import { useLocation, useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import { Icons } from 'src/common/components'
import { checkRouteHavePolicy } from 'src/common/helpers'
import { useLogout } from 'src/common/hooks'
import { IRoute } from 'src/common/interfaces'

import { useRouter } from '../../../../../default/helpers/navigation'
import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './routes-menu.module.scss'

interface MenuItemProps {
  id: string
  route: IRoute
  title: string
  isSidebarCollapsed?: boolean
  icon?: string
  role?: string | string[]
}

interface RoutesMenuProps {
  menuItems: MenuItemProps[]
  isSidebarCollapsed?: boolean
  borderBottom?: boolean
  showLogoutButton?: boolean
}

interface LogoutButtonProps {
  isSidebarCollapsed?: boolean
}

const MenuItem = (props: MenuItemProps) => {
  const router = useRouter()

  const { route, title, icon, isSidebarCollapsed } = props

  const { pathname } = useLocation()

  const isMatch = pathname.startsWith(route.path)

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <a
      className={cx(
        styles.menuItem,
        isMatch && styles.active,
        isSidebarCollapsed && styles.collapsed,
      )}
      onClick={() => router.push(route.path)}
    >
      {IconComponent ? <IconComponent /> : null}
      {!isSidebarCollapsed && <div className={styles.title}>{title}</div>}
    </a>
  )
}

const LogoutButton = (props: LogoutButtonProps) => {
  const { isSidebarCollapsed } = props

  const logoutMutation = useLogout()

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <div className={styles.menuItem} onClick={handleLogout}>
      <Icons.Logout />
      {!isSidebarCollapsed && <div className={styles.title}>Logout</div>}
    </div>
  )
}

const RoutesMenu = (props: RoutesMenuProps) => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const {
    menuItems,
    isSidebarCollapsed,
    borderBottom = true,
    showLogoutButton = false,
  } = props

  const filteredPrivateRoutes = Object.values(PRIVATE_ROUTES).filter(
    (route: IRoute) => {
      if (!userProfile) {
        return false
      }

      const routeHavePolicy = checkRouteHavePolicy(route.policy)

      if (!routeHavePolicy) {
        return false
      }

      return true
    },
  )

  const filteredMenuItems = menuItems.filter((menuItem) => {
    return filteredPrivateRoutes.find(
      (route: IRoute) => route.path === menuItem.route.path,
    )
  })

  return (
    <div className={cx(styles.wrapper, borderBottom && styles.borderBottom)}>
      {filteredMenuItems.map((menuItem) => (
        <MenuItem
          key={menuItem.id}
          {...menuItem}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      ))}

      <PolicyCheckHOC
        policyName="is_enable_benestream_logout_button"
        disablePolicyCheck
      >
        <>
          {showLogoutButton && (
            <LogoutButton isSidebarCollapsed={isSidebarCollapsed} />
          )}
        </>
      </PolicyCheckHOC>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  RoutesMenu,
  'is_enable_benestream_routes_menu',
  true,
)
