import { Input } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useDebouncedValue } from '@mantine/hooks'
import { useEffect, useState } from 'react'

import { Icons } from 'src/common/components'

interface SearchInputProps {
  form: UseFormReturnType<any>
  placeholder?: string
}

const SearchInput = ({ form, placeholder = 'Search...' }: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState('')

  const [debouncedSearch] = useDebouncedValue(searchValue, 600)

  useEffect(() => {
    form.setFieldValue('search', debouncedSearch)
  }, [debouncedSearch])

  return (
    <Input
      placeholder={placeholder}
      value={searchValue}
      leftSection={<Icons.Search />}
      onChange={(event) => setSearchValue(event.currentTarget.value)}
    />
  )
}

export default SearchInput
