import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateUser = (userId: string) =>
  useMutation({
    mutationFn: async () => api.delete(`/users/${userId}`),
  })

export default useMutateUser
