import { useFormikContext } from 'formik'
import { useRef, useState } from 'react'

import { BasicBlock, Button, Tabs } from 'src/common/components'
import { capitalize, checkValidationInOtherFormTabs } from 'src/common/helpers'
import { IEnrollment } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import BenestreamPersonFormHeader from './BenestreamPersonFormHeader'
import * as TabsContent from './BenestreamPersonFormTabsContent'
import styles from './benestream-person-form.module.scss'

interface BenestreamPersonFormProps {
  personType: 'lead' | 'account'
  enrollments?: IEnrollment[]
  refetchEligibilityGuideline?: () => void
  refetchPerson: () => void
  onResult?: (error: string | null) => void
}

const BenestreamPersonForm = (props: BenestreamPersonFormProps) => {
  const {
    personType,
    enrollments,
    refetchEligibilityGuideline,
    refetchPerson,
    onResult,
  } = props

  const formWrapperRef = useRef(null)

  const [selectedTab, setSelectedTab] = useState<string | null>('basic')

  const formik = useFormikContext()

  const handleSubmit = async () => {
    const validationResult = await formik.validateForm()

    const isValid = checkValidationInOtherFormTabs({
      validationResult,
      $form: formWrapperRef.current,
      onResult: (errorsStr) => {
        if (onResult) {
          onResult(
            `Please check all the required information in the Basic Information Tab and try again. \n${errorsStr}`,
          )
        }
      },
    })

    if (!isValid) {
      return false
    }

    await formik.submitForm()
  }

  return (
    <BasicBlock className={styles.wrapper}>
      <BenestreamPersonFormHeader
        enrollments={enrollments}
        personType={personType}
      />

      <div ref={formWrapperRef}>
        <Tabs show value={selectedTab || ''} onClick={setSelectedTab}>
          <Tabs.Item id="basic" title="Basic Information">
            <TabsContent.BasicInformation personType={personType} />
          </Tabs.Item>

          <Tabs.Item id="employer" title="Employer">
            <TabsContent.Employer />
          </Tabs.Item>

          {personType === 'account' && !!onResult ? (
            <Tabs.Item id="household" title="Household">
              <TabsContent.Household
                refetchEligibilityGuideline={refetchEligibilityGuideline}
                refetchPerson={refetchPerson}
                onResult={onResult}
                savePersonInformation={handleSubmit}
                personIsLoading={formik.isSubmitting}
              />
            </Tabs.Item>
          ) : null}

          <Tabs.Item
            id={personType}
            title={`${capitalize(personType)} Information`}
          >
            <TabsContent.PersonInformation personType={personType} />
          </Tabs.Item>
        </Tabs>
      </div>

      {selectedTab !== 'household' ? (
        <PolicyCheckHOC policyName="is_update_person_enabled">
          <div className={styles.bottomButtons}>
            <Button
              className={styles.saveButton}
              iconAfter="ArrowSelect"
              loading={formik.isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </PolicyCheckHOC>
      ) : null}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonForm,
  'is_get_benestream_person_by_id_enabled',
)
