import { Card, Modal, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import DeleteModalWithConfirmation from '@v2/commons/components/DeleteModalWithFeedback/DeleteModalWithFeedback.component'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import EmailDomainInformation from '../compoments/EmailDomainInformation'
import MembershipInformation from '../compoments/MembershipInformation/MembershipInformation.component'
import UserInformation from '../compoments/UserInformation'
import useAdministrationUserContainer from '../containers/useAdministrationUser.container'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Users' },
]

const SingleUserView = () => {
  const {
    userInformationForm,
    editForm,
    setEditForm,
    handleCancelForm,
    onSubmit,
    membership,
    membershipRoleList,
    userMembershipModal,
    setMembershipRole,
    actualRoleId,
    membershipRole,
    newStatus,
    setNewStatus,
    handleMutation,
    emailDomains,
    loadingUpdateMutation,
    isLoading,
    errorModal,
    handleDeleteUser,
    isSuccessDeleteUser,
    handleDeleteMembership,
    handleGenerateApiKey,
    isDeleteMembershipSuccess,
    isGenerateApiKeySuccess,
  } = useAdministrationUserContainer()

  const [
    deleteUserModalOpened,
    { open: openDeleteUserModal, close: closeDeleteUserModal },
  ] = useDisclosure(false)

  const [
    deleteUserMembershipModalOpened,
    {
      open: openDeleteUserMembershipModal,
      close: closeDeleteUserMembershipModal,
    },
  ] = useDisclosure(false)

  const [
    generateApiKeyModal,
    { open: openGenerateApiKeyModal, close: closeGenerateApiKeyModal },
  ] = useDisclosure(false)

  return (
    <BasicTemplate
      isLoading={false}
      title="Users"
      breadCrumbItems={breadCrumbItems}
    >
      <Card shadow="lg" radius="sm">
        <UserInformation
          loading={isLoading}
          onSubmit={onSubmit}
          userInformationForm={userInformationForm}
          editForm={editForm}
          setEditForm={setEditForm}
          handleCancelForm={handleCancelForm}
          openDeleteUserModal={openDeleteUserModal}
        />
        <DeleteModalWithConfirmation
          opened={deleteUserModalOpened}
          close={closeDeleteUserModal}
          handleDelete={handleDeleteUser}
          isSuccessDelete={isSuccessDeleteUser}
          buttonText="Delete User"
          title="Confirm User Removal"
          subtitle="Are you sure you want to delete this user?"
          body={`This action cannot be undone. Removing this user will revoke     their access to the system`}
          confirmationTitle="User deleted successfully"
          confirmationButtonText="Return to Admin Panel"
          successPath="USERS"
        />

        <MembershipInformation
          loadingUpdateMutation={loadingUpdateMutation}
          handleMutation={handleMutation}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
          membershipRole={membershipRole}
          actualRoleId={actualRoleId}
          setMembershipRole={setMembershipRole}
          membershipRoleList={membershipRoleList}
          userMembershipModal={userMembershipModal}
          membership={membership}
          handleDeleteMembership={openDeleteUserMembershipModal}
          handleGenerateApiKey={openGenerateApiKeyModal}
        />
        <DeleteModalWithConfirmation
          opened={deleteUserMembershipModalOpened}
          close={closeDeleteUserMembershipModal}
          handleDelete={handleDeleteMembership}
          isSuccessDelete={isDeleteMembershipSuccess}
          buttonText="Remove User Membership"
          title="Confirm Membership Removal"
          subtitle="Are you sure you want to remove this membership?"
          body={`This action cannot be undone. It will remove this user from the organization`}
          confirmationTitle="Membership removed successfully"
          confirmationButtonText="Return to Admin Panel"
          successPath="USERS"
        />
        <DeleteModalWithConfirmation
          opened={generateApiKeyModal}
          close={closeGenerateApiKeyModal}
          handleDelete={handleGenerateApiKey}
          isSuccessDelete={isGenerateApiKeySuccess}
          buttonText="Generate API Key"
          title="Confirm Generating new API Key"
          subtitle=""
          body={`This action cannot be undone. The existing API key will be deleted (if exist).`}
          confirmationTitle="API key set to database. Contact the engineer to get more info."
          confirmationButtonText="Return to Admin Panel"
          successPath="USERS"
        />
        <EmailDomainInformation emailDomains={emailDomains} />
      </Card>
      <Modal opened={errorModal.opened} onClose={errorModal.close}>
        <Stack align="center" justify="center" gap="md">
          <Icons.Warning width="60" height="60" style={{ color: 'red' }} />
          <Text size={'16px'} fw={700}>
            Error when try to create user
          </Text>
          <Text size={'14px'} fw={400}>
            {userInformationForm.errors?.error}
          </Text>
        </Stack>
      </Modal>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleUserView,
  'is_get_roles_enabled',
  true,
)
