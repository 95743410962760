import { IRolesResponse } from '../models/role.model'
import useRolesUseCase from '../useCases/useRoles.usecase'

export const useRolesContainer = (): IRolesResponse => {
  const {
    data,
    isLoading,
    membershipRole,
    membershipRoleList,
    activePolicies,
    handleOnSelectChange,
    handleOnCheckBoxChange,
    updateRoleMutationStatus,
  } = useRolesUseCase()

  return {
    data,
    isLoading,
    membershipRole,
    membershipRoleList,
    activePolicies,
    handleOnSelectChange,
    handleOnCheckBoxChange,
    updateRoleMutationStatus,
  }
}

export default useRolesContainer
