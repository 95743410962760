import {
  setShowTicketBlockInformation,
  setTicketId,
} from '@v2/domains/ticket/models/ticket.store'
import {
  Icons,
  Modal,
  Pagination,
} from '@workers-benefit-fund/wbf-ui-components'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useBlocker, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { Breadcrumbs } from 'src/common/components'
import { BasicBlock, PersonNotesBlock, Table } from 'src/common/components'
import DuplicatePersons from 'src/common/components/person/DuplicatePersons/DuplicatePersons'
import { PersonNoteModal } from 'src/common/components/person/PersonNotesBlock/components'
import { sortEnrollments } from 'src/common/helpers'
import { useDocumentTitle } from 'src/common/hooks'
import { IPerson, IPersonMutationData } from 'src/common/interfaces'
import { ModalResult } from 'src/default/components'
import DriverForm from 'src/default/components/DriverForm'
import { BASIC_PERSON_INITIAL_DATA, PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import {
  useGetPerson,
  useGetPersonTags,
  useGetPersonTagsBlackCarFund,
  useMutatePerson,
  useMutatePersonTagBlackCarFund,
  useUpdatePersonTags,
} from 'src/default/hooks'
import useGetDuplicatedPersons from 'src/default/hooks/person/useGetDuplicatePersons'
import { EnumDocumentType } from 'src/default/interfaces'
import { SinglePersonTemplate } from 'src/default/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppDispatch, useAppSelector } from '../../../../v2/store/hooks'
import { useRouter } from '../../../helpers/navigation'
import useGetOrganizationTags from '../../../hooks/person/useGetOrganizationTags'
//TODO refactor it
import SingleAccountDocuments from '../../Accounts/SingleAccountPage/SingleAccountDocuments/SingleAccountDocuments'
import SingleAccountEnrollments from '../../Accounts/SingleAccountPage/SingleAccountEnrollments/SingleAccountEnrollments'
import SingleAccountFormSubmissions from '../../Accounts/SingleAccountPage/SingleAccountFormSubmissions/SingleAccountFormSubmissions'
import styles from './single-lead-page.module.scss'
import { getValidationSchemaByOrganizationId } from './single-lead-page.util'

const SingleLeadPageWrapper = () => {
  const router = useRouter()

  const { personId } = useParams<{
    personId: string
    organizationId: string
  }>()

  const [isReady, setIsReady] = useState<boolean>(false)

  const {
    data: { data: leadData } = { data: {} },
    isSuccess,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetPerson({
    personId,
  })

  const {
    data: { data: personTagsBlackCarFund } = { data: [] },
    isSuccess: isPersonTagsBlacCarFundSuccess,
    isLoading: isPersonTagsBlacCarFundLoading,
    isError: isPersonTagsBlacCarFundError,
    refetch: refetchPersonTagBlackCarFund,
  } = useGetPersonTagsBlackCarFund({ personId })

  useEffect(() => {
    if (
      leadData &&
      !isLoading &&
      ((isPersonTagsBlacCarFundSuccess && personTagsBlackCarFund) ||
        (!isPersonTagsBlacCarFundLoading && !isPersonTagsBlacCarFundError))
    ) {
      if (leadData.isAccount) {
        router.push(`/${PRIVATE_ROUTES.ACCOUNTS.path}/${personId}`)
      }
      if (leadData.hasOwnProperty('id')) {
        ;(leadData.birthDate = leadData.birthDate
          ? dayjs(leadData.birthDate).format('MM/DD/YYYY')
          : ''),
          (leadData.blackCarFund =
            personTagsBlackCarFund.length > 0
              ? personTagsBlackCarFund[0]?.id
              : '')
        setIsReady(true)
      }
    }
  }, [
    leadData,
    isLoading,
    isPersonTagsBlacCarFundSuccess,
    personTagsBlackCarFund,
  ])

  if (!isReady) {
    return null
  }

  const refetchPersonAndPersonTagBlackCarFund = () => {
    refetch()
    refetchPersonTagBlackCarFund()
  }

  return (
    <SingleLeadPage
      leadData={leadData}
      isSuccess={isSuccess}
      isError={isError}
      error={error}
      refetch={refetchPersonAndPersonTagBlackCarFund}
    />
  )
}

interface SingleLeadPageProps {
  leadData: IPerson
  isSuccess: boolean
  isError: boolean
  error: unknown
  refetch: () => any
}

const SingleLeadPage = (props: SingleLeadPageProps) => {
  const router = useRouter()
  const dispatch = useAppDispatch()

  const LIMIT_BY_PAGE = 3
  const { leadData, isSuccess, isError, error, refetch } = props

  const { personId, ticketId } = useParams<{
    personId: string
    ticketId?: string
  }>()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const [isReady, setIsReady] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)
  const [duplicatePerson, setDuplicatePerson] = useState<IPerson | null>(null)
  const [paginationOffset, setPaginationOffset] = useState(0)
  const [isLeavingTheRecordWithoutNote, setIsLeavingTheRecordWithoutNote] =
    useState<boolean>(true)

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      userProfile.policies.includes('is_notespromptmodal_enabled') &&
      isLeavingTheRecordWithoutNote &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isLeavingTheRecordWithoutNote) {
        event.preventDefault()
      }
      return
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isLeavingTheRecordWithoutNote])

  const {
    data: { data: organizationTags } = { data: [] },
    isLoading: isOrganizationTagsLoading,
    isSuccess: isOrganizationTagsSuccess,
  } = useGetOrganizationTags()

  const { data: { data: duplicatedPersons } = { data: [] } } =
    useGetDuplicatedPersons({
      personId: personId ?? '',
      params: {
        organizationId: currentOrganizationId!,
        byEmail: 'true',
        byTLC: 'true',
        byDL: 'true',
      },
    })

  const leadMutation = useMutatePerson('update')

  const tagsMutation = useUpdatePersonTags({
    personId,
    organizationId: currentOrganizationId,
  })
  const {
    data: { data: personTags } = { data: [] },
    isLoading: isTagsLoading,
    isSuccess: isTagsSuccess,
  } = useGetPersonTags({
    personId,
    organizationId: currentOrganizationId,
  })

  const mutationPersonTagBlackCarFund = useMutatePersonTagBlackCarFund()

  useDocumentTitle(`${leadData?.firstName ?? ''} ${leadData?.lastName ?? ''}`)

  useEffect(() => {
    if (ticketId) {
      dispatch(setTicketId(ticketId))
      dispatch(setShowTicketBlockInformation(true))
    }
  }, [ticketId])

  useEffect(() => {
    if (isError) {
      setResult(basicApiErrorHandling(error))
    }
  }, [isError])

  const formik = useFormik<IPersonMutationData>({
    initialValues: {
      id: personId,
      ...BASIC_PERSON_INITIAL_DATA,
    },
    onSubmit: async (values, { setSubmitting }) => {
      const newValues = { ...values }
      delete newValues.source
      delete newValues.updatedAt
      delete newValues.createdAt
      delete newValues.createdBy
      delete newValues.updatedBy
      delete newValues.blackCarFund

      await leadMutation
        .mutateAsync({
          ...newValues,
        })
        .then(() => {
          if (values.blackCarFund) {
            mutationPersonTagBlackCarFund.mutateAsync({
              personId: personId ?? '',
              tagId: values.blackCarFund ?? '',
            })
          }
          setResult('success')
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
        })

      setSubmitting(false)
    },
    validationSchema: yup
      .object()
      .shape(getValidationSchemaByOrganizationId(currentOrganizationId!)),
  })

  const handleFormReady = async () => {
    setIsReady(true)
  }

  const handleCloseModal = () => {
    if (!isError) {
      setResult(null)
      refetch()
    } else {
      router.push(PRIVATE_ROUTES.LEADS.path)
    }
  }

  const enrollments = leadData?.enrollments ?? []

  const documents = leadData?.documents ?? []
  const tlcNumber = documents.find(
    (doc) => doc.type === EnumDocumentType.TLC,
  )?.number

  const getPersonEnrollment = (person: IPerson) => {
    return (
      person?.enrollments?.find(
        (item) =>
          item.associatedBenefitContract.organization.id ===
          currentOrganizationId,
      ) || { isVerified: false, startDate: null }
    )
  }

  useEffect(() => {
    setDuplicatePerson(null)
  }, [leadData])

  const personName = (() => {
    const { firstName, lastName } = formik.values
    if (!firstName && !lastName) {
      return 'Lead'
    }

    return `${firstName} ${lastName}`
  })()

  return (
    <>
      <Breadcrumbs items={[{ label: personName }]} />
      {!isError ? (
        <SinglePersonTemplate isReady={isReady} personData={leadData}>
          <div className={styles.flexOne}>
            {duplicatedPersons?.totalRecords ? (
              <BasicBlock paddings="normal" className="mb-6">
                <div className="flex flex-col gap-5">
                  <div className="flex items-center gap-3">
                    <Icons.Info />
                    <div className="text-primary font-bold text-base">
                      We found potential duplicates of this records.
                    </div>
                  </div>
                  <Table>
                    {duplicatedPersons.records
                      ?.map((record: IPerson, index: number) => ({
                        ...record,
                        index: index + 1,
                      }))
                      ?.slice(
                        paginationOffset,
                        LIMIT_BY_PAGE + paginationOffset,
                      )
                      ?.map((l: IPerson, index: number) => (
                        <Table.Row
                          key={index}
                          id={l.id}
                          editable
                          onClick={() =>
                            setDuplicatePerson(
                              duplicatedPersons.records.find(
                                (item: IPerson) => item.id === l.id,
                              ),
                            )
                          }
                        >
                          <Table.Column title="No." showMobile width={50}>
                            {l.index}
                          </Table.Column>
                          <Table.Column title="Name" showMobile>
                            {`${l.firstName} ${l.lastName}`}
                          </Table.Column>
                          <Table.Column title="Mobile Phone" showMobile>
                            {l.phoneNumber}
                          </Table.Column>
                          <Table.Column title="Email" showMobile>
                            {l.email}
                          </Table.Column>
                          <Table.Column title="Type" showMobile>
                            {getPersonEnrollment(l)?.isVerified
                              ? 'Account'
                              : 'Lead'}
                          </Table.Column>
                          <Table.Column title="Created At" showMobile>
                            {getPersonEnrollment(l)?.isVerified
                              ? dayjs(getPersonEnrollment(l)?.startDate).format(
                                  'MM/DD/YYYY',
                                )
                              : dayjs(l.createdAt).format('MM/DD/YYYY')}
                          </Table.Column>
                        </Table.Row>
                      )) || null}
                  </Table>
                  <div className="w-full text-center">
                    {duplicatedPersons?.totalRecords > LIMIT_BY_PAGE ? (
                      <Pagination
                        limit={LIMIT_BY_PAGE}
                        total={duplicatedPersons?.totalRecords}
                        onChangePage={(page: number) =>
                          setPaginationOffset((page - 1) * LIMIT_BY_PAGE)
                        }
                        currentPage={paginationOffset / LIMIT_BY_PAGE + 1}
                      />
                    ) : null}
                  </div>
                </div>
              </BasicBlock>
            ) : null}
            <DriverForm
              customerType="lead"
              data={leadData}
              formik={formik}
              isSuccess={
                isSuccess && isTagsSuccess && isOrganizationTagsSuccess
              }
              onReady={handleFormReady}
              onResult={setResult}
              initialTags={personTags?.records ?? []}
              organizationTags={organizationTags?.records ?? []}
              onTagsUpdate={async (values) => {
                await tagsMutation
                  .mutateAsync({ records: values })
                  .then(() => {
                    console.log('success tags')
                  })
                  .catch((error) => {
                    console.log('error tags', error)
                  })
              }}
              isTagsLoading={isTagsLoading || isOrganizationTagsLoading}
              isTagsSuccess={isTagsSuccess && isOrganizationTagsSuccess}
              refetch={refetch}
              policies={userProfile.policies}
            />

            <SingleAccountDocuments
              data={documents}
              isSuccess={isSuccess}
              refetch={refetch}
            />

            <SingleAccountEnrollments
              data={sortEnrollments(enrollments)}
              refetch={refetch}
              isSuccess={isSuccess}
              leadName={`${leadData?.firstName ?? ''} ${
                leadData?.lastName ?? ''
              }`}
              tlcNumber={tlcNumber}
            />

            <SingleAccountFormSubmissions />

            <PersonNotesBlock
              setIsLeavingTheRecordWithoutNote={
                setIsLeavingTheRecordWithoutNote
              }
            />
          </div>
        </SinglePersonTemplate>
      ) : null}

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          title={isError ? 'Error!' : undefined}
          description={result === 'success' ? undefined : result}
          onClose={handleCloseModal}
        />
      ) : null}

      {duplicatePerson ? (
        <Modal isOpen onClose={() => setDuplicatePerson(null)} size="large">
          <DuplicatePersons
            duplicatePerson={duplicatePerson}
            currentPerson={leadData}
            persons={
              duplicatedPersons.records ? [...duplicatedPersons.records] : []
            }
          />
        </Modal>
      ) : null}

      {blocker.state === 'blocked' ? (
        <Modal isOpen onClose={() => blocker.reset()}>
          <PersonNoteModal onAfterSave={() => blocker.proceed()} />
        </Modal>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleLeadPageWrapper,
  'is_get_person_by_id_enabled',
)
