import { useState } from 'react'

import { IField } from 'src/common/interfaces'
import { MultiSelect } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

const CAR_COMPANIES = {
  Uber: 'Uber',
  Lyft: 'Lyft',
  Unknown: 'Unknown',
}

const MultiSelectCarCompanies = (props: IField) => {
  const options = Object.entries(CAR_COMPANIES).map(([key, value]) => ({
    value,
    label: key,
  }))

  const placeholder = 'Type the companies here'

  return (
    <MultiSelect.Buttons
      withOther
      placeholder={placeholder}
      {...props}
      options={options}
      withError={true}
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MultiSelectCarCompanies,
  'is_enable_multi_select_car_companies',
  true,
)
