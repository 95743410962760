import { useParams } from 'react-router-dom'

import { Select, Status, Table } from 'src/common/components'
import { getPhoneWithMask } from 'src/common/helpers'
import { IOption } from 'src/common/interfaces'
import { EmptyTickets } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { ITicket } from 'src/default/interfaces'
import { TicketCustomerLink } from 'src/default/pages/Tickets/TicketsPage/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../tickets-page.module.scss'
import OpenTicketsTableSelectAssign from './OpenTicketsTableSelectAssign'
import styles from './open-tickets-table.module.scss'

interface OpenTicketsTableProps {
  isLoading: boolean
  tickets: ITicket[]
  usersOptions: IOption[]
  onAssignTicket: (
    assignUserId: string,
    assignUserLabel: string,
    ticketId: string,
  ) => void
}

const OpenTicketsTable = (props: OpenTicketsTableProps) => {
  const { isLoading, tickets, usersOptions, onAssignTicket } = props
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <>
      {tickets.length ? (
        <div>
          <Table>
            {tickets.map((t: ITicket) => (
              <Table.Row
                key={t.id}
                id={t.id}
                className={rootStyles.row}
                editable={false}
                editLink={`/${organizationId}${PRIVATE_ROUTES.LEADS.path}/:id`}
              >
                <Table.Column title="Ticket Number" showMobile>
                  <div className={styles.ticketNumberWrapper}>
                    <TicketCustomerLink className={styles.status} ticket={t}>
                      <Status
                        className={styles.ticketNumber}
                        color="orange"
                        hideMobileLabel
                      ></Status>
                      {`Queued #${t.numberVisit}`}
                    </TicketCustomerLink>
                  </div>
                </Table.Column>

                <Table.Column
                  className={rootStyles.name}
                  title="Name"
                  showMobile
                >
                  {t.personId ? (
                    <TicketCustomerLink ticket={t}>{t.name}</TicketCustomerLink>
                  ) : (
                    t.name
                  )}
                </Table.Column>

                <Table.Column title="Email">{t.email}</Table.Column>

                <Table.Column title="Mobile">
                  {getPhoneWithMask(t.phoneNumber)}
                </Table.Column>

                <Table.Column title="Ticket Status" showMobile>
                  {t.status}
                </Table.Column>

                <Table.Column title="Reason For Visit">
                  {t.reasonForVisit}
                </Table.Column>

                <Table.Column title="Assigned To" noOverflow={true}>
                  <OpenTicketsTableSelectAssign
                    name={`assignTo-${t.id}`}
                    initialValue={t.assignUserId}
                    options={usersOptions}
                    onChange={(value, label) =>
                      onAssignTicket(value, label, t.id)
                    }
                  />
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        </div>
      ) : (
        <>{!isLoading ? <EmptyTickets /> : null}</>
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  OpenTicketsTable,
  'is_enable_get_open_tickets',
  true,
)
