import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import EligibilityEditor from './EligibilityEditor'
import styles from './settings-page.module.scss'

const SettingsPage = () => {
  return (
    <BasicTemplate>
      <div className={styles.wrapper}>
        <EligibilityEditor />
      </div>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SettingsPage,
  'is_enable_benestream_settings_view',
  true,
)
