import { Link } from 'react-router-dom'

interface AttachmentLinkProps {
  s3Url: string
  attachmentType: 'enrollment' | 'document'
}

const AttachmentLink = (props: AttachmentLinkProps) => {
  const { s3Url, attachmentType } = props

  const urlToView =
    s3Url !== '[]'
      ? `/attachment/${s3Url.split('amazonaws.com/')[1]}/${attachmentType}`
      : ''

  return <Link to={urlToView}>{urlToView}</Link>
}

export default AttachmentLink
