import { Icons } from 'src/common/components'
import { useLogout } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './logout-button.module.scss'

const LogoutButton = () => {
  const logoutMutation = useLogout()

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <div className={styles.wrapper} onClick={handleLogout}>
      <Icons.Logout />
      <span>Log Out</span>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LogoutButton,
  'is_enable_logout',
  true,
)
