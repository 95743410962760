import { Button, Flex, Modal, Stack, Text } from '@mantine/core'

import { Icons } from 'src/common/components'

import styles from './action-confirmation-modal.module.scss'

interface ActionConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  subtitle?: string
  handleConfirm: () => void
  buttonText: string
  isLoading: boolean
}

const ActionConfirmationModal = (props: ActionConfirmationModalProps) => {
  const {
    isLoading,
    buttonText,
    subtitle,
    title,
    handleConfirm,
    onClose,
    isOpen,
  } = props
  return (
    <Modal
      opened={isOpen}
      size="xs"
      trapFocus={false}
      onClose={onClose}
      centered
      classNames={{
        content: styles['modal-content'],
        header: styles['modal-header'],
        title: styles['modal-title'],
        close: styles['modal-close'],
        body: styles['modal-body'],
      }}
      title={
        <Flex className={styles.icon}>
          <Icons.Check />
        </Flex>
      }
    >
      <Stack justify="center" gap="15px" align="center" maw="100%">
        <Text fz="16px" lh="130%" fw="700" ta="center">
          {title}
        </Text>
        <Text fz="14px" lh="130%" fw="400" c="var(--mantine-color-gray-5)">
          {subtitle}
        </Text>
        <Flex
          align="center"
          gap={{ base: '20px', sm: 'none' }}
          justify="space-between"
          w="100%"
          mt="20px"
        >
          <Button className={styles.button} onClick={onClose} variant="default">
            Cancel
          </Button>
          <Button
            classNames={{
              root: styles.button,
              inner: styles.inner,
            }}
            loading={isLoading}
            onClick={handleConfirm}
            variant="filled"
          >
            {buttonText}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

export default ActionConfirmationModal
