import { AxiosError } from 'axios'
import cx from 'classnames'

import { useDeleteDependentPerson } from 'src/benestream/hooks'
import { IPersonHousehold } from 'src/benestream/interfaces'
import { Icons, Table } from 'src/common/components'
import dayjs from 'src/common/libs/dayjs'
import { Triggers } from 'src/default/components'
import { basicApiErrorHandling } from 'src/default/helpers'

import styles from './benestream-person-form-household-table.module.scss'

interface BenestreamPersonFormHouseholdTableProps {
  data: IPersonHousehold[]
  selectedHouseholdId: string | null
  onSelect: (id: string) => void
  onResult: (error: string) => void
  refetch: () => void
}

const BenestreamPersonFormHouseholdTable = (
  props: BenestreamPersonFormHouseholdTableProps,
) => {
  const { data, selectedHouseholdId, onSelect, onResult, refetch } = props

  const deleteDependentPerson = useDeleteDependentPerson()

  const deleteHouseholderMember = (id: string) => {
    deleteDependentPerson
      .mutateAsync(id)
      .then(() => {
        onResult('The household member has been successfully deleted')
        refetch()
      })
      .catch((error: AxiosError) => {
        onResult(basicApiErrorHandling(error))
      })
  }

  return (
    <Table>
      {data
        .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
        .map((h: IPersonHousehold) => (
          <Table.Row
            key={h.id}
            className={cx(
              styles.row,
              h.id === selectedHouseholdId && styles.active,
            )}
            onClick={() => onSelect(h.id)}
          >
            <Table.Column title="Member ID">{h.id}</Table.Column>

            <Table.Column
              title="Name"
              showMobile
            >{`${h.firstName} ${h.lastName}`}</Table.Column>

            <Table.Column title="Age" showMobile width={75}>
              {dayjs().diff(dayjs(h.birthDate, 'YYYY-MM-DD'), 'year')}
            </Table.Column>

            <Table.Column title="Relationship to Applicant">
              {h.relationToApplicant}
            </Table.Column>

            <Table.Column width={50}>
              {h.relationToApplicant !== 'primary' ? (
                <Triggers.Delete
                  className={styles.deleteButton}
                  name={h.firstName}
                  title="Delete Household Member!"
                  description={
                    <div>
                      You are going to delete{' '}
                      <b>
                        {h.firstName} {h.lastName}
                      </b>{' '}
                      Household Member record. Are you sure?
                    </div>
                  }
                  onAction={() => deleteHouseholderMember(h.id)}
                >
                  <Icons.TrashCross />
                </Triggers.Delete>
              ) : null}
            </Table.Column>
          </Table.Row>
        ))}
    </Table>
  )
}

export default BenestreamPersonFormHouseholdTable
