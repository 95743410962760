import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { formatDate } from 'src/common/helpers'

import DateSelect from './DateSelect/DateSelect'
import MonthSelect from './MonthSelect/MonthSelect'
import YearSelect from './YearSelect/YearSelect'
import styles from './calendar.module.scss'

interface CalendarProps {
  value: string
  onChange: (value: string) => void
  onClose?: () => void
  futureDate?: boolean
  pastDate?: boolean
}

const Calendar = (props: CalendarProps) => {
  const {
    value: outerValue,
    onChange,
    onClose,
    futureDate = false,
    pastDate = true,
  } = props

  const [date, setDate] = useState<Dayjs>(dayjs())
  const [value, setValue] = useState<string>('')

  const [shouldClose, setShouldClose] = useState<boolean>(false)

  useEffect(() => {
    if (outerValue) {
      setValue(outerValue)
    }
  }, [])

  useEffect(() => {
    if (outerValue) {
      onChange(value)
    } else {
      onChange(value)
    }
  }, [value])

  useEffect(() => {
    if (onClose && shouldClose) {
      onClose()
    }
  }, [shouldClose])

  const handleChangeDate = (date: string) => {
    setValue(date)
  }

  const yearsOptions = (() => {
    const optionsLength = 100 //TODO make as variable
    const past = pastDate
    const future = futureDate

    const current = Number(formatDate(dayjs(), 'YYYY'))
    const result = []

    for (let i = 0; i < optionsLength; i++) {
      let year = null
      if (!past) {
        year = current + i
      } else if (!future) {
        year = current - i
      } else {
        year =
          i < optionsLength / 2
            ? current - optionsLength / 2 + i
            : current + (i - optionsLength / 2)
      }
      result.push({ label: String(year), value: String(year) })
    }

    return result
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <MonthSelect
          futureDate={futureDate}
          date={date}
          yearsOptions={yearsOptions}
          onChange={setDate}
        />

        <YearSelect date={date} options={yearsOptions} onChange={setDate} />
      </div>

      <DateSelect
        futureDate={futureDate}
        value={value}
        date={date}
        onChange={handleChangeDate}
      />
    </div>
  )
}

export default Calendar
