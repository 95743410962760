import { rootApi } from 'src/v2/store/api/rootApi'

import { ISaveFileData } from './RunProcess.model'
import { resetRunProcess } from './RunProcess.store'

export const runProcessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    runProcess: builder.mutation<any, any>({
      query: (data: { item: any; values: any }) => ({
        url: data.item.url,
        method: 'POST',
        data: {
          ...data.values,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(resetRunProcess())
        } catch (error) {
          return
        }
      },
    }),
    saveFile: builder.mutation<unknown, ISaveFileData>({
      query: (data) => ({
        url: '/files',
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useRunProcessMutation, useSaveFileMutation } = runProcessApi
