import { Button, Flex, Group, Text } from '@mantine/core'
import { DatesRangeValue } from '@mantine/dates'
import { UseFormReturnType } from '@mantine/form/lib/types'
import DateRangePickerFilter from '@v2/commons/components/DatePickerFilter/DateRangeFilterPicker.component'
import ButtonFilter from '@v2/commons/components/FilterButton/FilterButton.component'
import SelectFilter from '@v2/commons/components/FilterSelect/FilterSelect.component'
import TextInputFilter from '@v2/commons/components/FilterTextInput/FilterTextInput.component'
import SelectLanguageFilter from '@v2/commons/components/LanguageFilterSelect/LanguageSelect.component'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import PolicyCheckHOC from 'src/v2/commons/HOC/policyCheckHOC'
import { type PersonsFiltersModel } from 'src/v2/domains/person/models/person.model'

import classes from './persons-filters.module.scss'

interface PersonTableFilterProps {
  personsFiltersForm: UseFormReturnType<PersonsFiltersModel>
  handleDateRangeChange: (value: DatesRangeValue) => void
  clearAllFilters: () => void
  isProgrammaticFormUpdateRef: React.MutableRefObject<boolean>
  handleClearStorePerson: () => void
  totalRecords: number
}

const PersonTableFilter = ({
  personsFiltersForm,
  handleDateRangeChange,
  clearAllFilters,
  isProgrammaticFormUpdateRef,
  handleClearStorePerson,
  totalRecords,
}: PersonTableFilterProps) => {
  const [key, setFormKey] = useState(1)
  const [openFilters, setOpenFilters] = useState(false)

  const handleClearFilters = () => {
    setFormKey((prev) => ++prev)
    handleClearStorePerson()
    clearAllFilters()
  }

  const handleChangeValue = (
    path: keyof PersonsFiltersModel,
    value: PersonsFiltersModel[keyof PersonsFiltersModel],
  ) => {
    personsFiltersForm.setFieldValue(path, value)
    if (isProgrammaticFormUpdateRef.current) {
      isProgrammaticFormUpdateRef.current = false
    } else {
      handleClearStorePerson()
    }
  }

  const toggleOpenFilters = () => {
    setOpenFilters((prev) => !prev)
  }

  useEffect(() => {
    const isPersistedFilters = Object.keys(
      personsFiltersForm.getValues(),
    ).length
    if (isPersistedFilters) {
      setOpenFilters(true)
    }
  }, [])

  const getDefaultDatevalues = (): [Date, Date] | undefined => {
    const filters = personsFiltersForm.getValues()
    const fromDate = filters['fromDate']
    const toDate = filters['toDate']
    if (fromDate && toDate) {
      return [dayjs(fromDate).toDate(), dayjs(toDate).toDate()]
    }
    return undefined
  }

  return (
    <>
      <Flex justify="space-between" align="center" mb="30px">
        <ButtonFilter onClick={toggleOpenFilters} isOpen={openFilters} />
        <Text lh="26px" size="18px">
          Total Records: {totalRecords}
        </Text>
      </Flex>
      {openFilters && (
        <form key={key} style={{ marginBottom: '30px' }}>
          <Group
            preventGrowOverflow={false}
            grow={true}
            classNames={{ root: classes['group-root'] }}
          >
            <SelectLanguageFilter
              placeholder="Preferred Language"
              {...personsFiltersForm.getInputProps('preferredLanguage')}
              key={personsFiltersForm.key('preferredLanguage')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('preferredLanguage', value)
              }}
            />
            <PolicyCheckHOC policyName="is_rating_enabled">
              <SelectFilter
                options={[
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                ]}
                placeholder="Person Rating"
                key={personsFiltersForm.key('Person Rating')}
                {...personsFiltersForm.getInputProps('rating')}
                onChange={(
                  value: PersonsFiltersModel[keyof PersonsFiltersModel],
                ) => {
                  handleChangeValue('rating', value)
                }}
              />
            </PolicyCheckHOC>
            <TextInputFilter
              placeholder="Postal Code"
              {...personsFiltersForm.getInputProps('zipCode')}
              key={personsFiltersForm.key('zipCode')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeValue('zipCode', event?.target.value)
              }}
            />
            <SelectFilter
              options={[
                { label: 'Uber', value: 'Uber' },
                { label: 'Lyft', value: 'Lyft' },
                { label: 'Other', value: 'Other' },
              ]}
              placeholder="Company"
              {...personsFiltersForm.getInputProps('companies')}
              key={personsFiltersForm.key('companies')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('companies', value)
              }}
            />
            <SelectFilter
              options={[
                { label: 'Yes', value: 'false' },
                { label: 'No', value: 'true' },
              ]}
              placeholder="Assigned"
              {...personsFiltersForm.getInputProps('isUnassigned')}
              key={personsFiltersForm.key('isUnassigned')}
              onChange={(
                value: PersonsFiltersModel[keyof PersonsFiltersModel],
              ) => {
                handleChangeValue('isUnassigned', value)
              }}
            />
            <DateRangePickerFilter
              valueFormat="MM/DD/YYYY"
              placeholder="From - To"
              handleDateRangeChange={handleDateRangeChange}
              getFilters={getDefaultDatevalues}
              key={personsFiltersForm.key('From - To')}
            />
            <Flex miw="50%" justify="flex-end">
              <Button
                variant="transparent"
                classNames={{ root: classes['clear-button-root'] }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear All
              </Button>
            </Flex>
          </Group>
        </form>
      )}
    </>
  )
}

export default PersonTableFilter
