import { BasicInput, FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

const BasicInputWithWrapper = (props: IField) => {
  return (
    <FieldWrapper {...props}>
      <BasicInput {...props} />
    </FieldWrapper>
  )
}

export default BasicInputWithWrapper
