import cx from 'classnames'
import { NavLink, useParams } from 'react-router-dom'

import { Icons } from 'src/common/components'
import { checkRouteHavePolicy } from 'src/common/helpers'
import { IRoute } from 'src/common/interfaces'
import { MENU_ITEMS, PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './menu.module.scss'

interface MenuItemProps {
  id: string
  route: IRoute
  title: string
  icon?: string
  role?: string | string[]
}

const MenuItem = (props: MenuItemProps) => {
  const { route, title, icon } = props

  const { organizationId } = useParams()

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return (
    <NavLink
      className={({ isActive }) =>
        cx(styles.menuItem, isActive && styles.active)
      }
      to={`/${organizationId}${route.path}`}
    >
      {IconComponent ? <IconComponent /> : null}
      <div className={styles.title}>{title}</div>
    </NavLink>
  )
}

const Menu = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const filteredPrivateRoutes = Object.values(PRIVATE_ROUTES).filter(
    (route: IRoute) => {
      if (!userProfile) {
        return false
      }

      const routeHavePolicy = checkRouteHavePolicy(route.policy)

      if (!routeHavePolicy) {
        return false
      }

      return true
    },
  )

  const filteredMenuItems = MENU_ITEMS.filter((menuItem) => {
    return filteredPrivateRoutes.find(
      (route: IRoute) => route.path === menuItem.route.path,
    )
  })

  return (
    <div className={styles.wrapper}>
      {filteredMenuItems.map((menuItem) => (
        <MenuItem key={menuItem.id} {...menuItem} />
      ))}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(Menu, 'is_enable_menu', true)
