import { IEligibilityGuideline } from 'src/benestream/interfaces'
import { Communications, Portal } from 'src/common/components'
import { IPerson } from 'src/common/interfaces'
import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BenestreamPersonEligibilities from './BenestreamPersonEligibilities'
import Opportunities from './Opportunities'
import styles from './single-benestream-person-template.module.scss'

interface SingleBenestreamPersonTemplateProps {
  isReady?: boolean
  personData?: IPerson
  personType?: 'lead' | 'account'
  state?: string
  eligibilityGuideline?: IEligibilityGuideline
}

const SingleBenestreamPersonTemplate = (
  props: React.PropsWithChildren<SingleBenestreamPersonTemplateProps>,
) => {
  const { children, personType, state, eligibilityGuideline } = props

  return (
    <BasicTemplate>
      <div className={styles.wrapper}>
        {children}

        <div className={styles.rightBlock}>
          {personType ? (
            <div>
              <Opportunities />
            </div>
          ) : null}

          {personType === 'account' && eligibilityGuideline ? (
            <BenestreamPersonEligibilities
              state={state}
              eligibilityGuideline={eligibilityGuideline}
            />
          ) : null}

          <Communications hideCreateCommunication />

          <Portal.Target id="metroPlus" />
        </div>
      </div>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleBenestreamPersonTemplate,
  'is_enable_benestream_single_person_template',
  true,
)
