import { useEffect, useState } from 'react'

import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'
import { useNycLookupLicense } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './manual-convert-lead-modal.module.scss'

interface ManualConvertLeadModalProps extends IModal {
  leadName?: string
  tlcNumber?: string
  isLoading?: boolean
  onSubmit: () => void
}

const ManualConvertLeadModal = (props: ManualConvertLeadModalProps) => {
  const { leadName, tlcNumber, isLoading, onClose, onSubmit } = props

  const [isReady, setIsReady] = useState<boolean>(false)

  const { data: nycLookupLicenseData, isLoading: lookupIsLoading } =
    useNycLookupLicense({
      enabled: true,
      findBy: 'license_number',
      findValue: tlcNumber ?? '',
    })

  useEffect(() => {
    if (!lookupIsLoading) {
      if ((nycLookupLicenseData?.data ?? {})?.id) {
        setIsReady(true)
      } else {
        onClose?.()
      }
    }
  }, [nycLookupLicenseData, lookupIsLoading])

  if (!isReady) {
    return null
  }

  const lookupData = nycLookupLicenseData?.data

  return (
    <Modal id="convertLead" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.Warning />
        </div>

        <div className={styles.title}>Convert Lead</div>

        <div className={styles.subtitle}>
          Do you want to convert this lead manually?
        </div>

        <div className={styles.diff}>
          <div className={styles.separator} />

          <div>
            <div className={styles.title}>The NYC License Lookup Data</div>
            <p>
              <strong>Name:</strong> {lookupData.name}
            </p>
            <p>
              <strong>License Number:</strong> {lookupData.licenseNumber}
            </p>
          </div>

          <div className={styles.separator} />

          <div>
            <div className={styles.title}>The 360 Data</div>
            <p>
              <strong>Name:</strong> {leadName}
            </p>
            <p>
              <strong>License Number:</strong> {tlcNumber}
            </p>
          </div>

          <div className={styles.separator} />
        </div>

        <div className={styles.buttons}>
          <Button
            loading={isLoading}
            size="small"
            template="danger"
            onClick={onClose}
          >
            It's not the same driver. Reject.
          </Button>

          <Button loading={isLoading} size="small" onClick={onSubmit}>
            It's the same driver. Confirm activation
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ManualConvertLeadModal,
  'is_enable_verify_enrollment_manual',
  true,
)
