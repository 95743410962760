import cx from 'classnames'

import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './toast.module.scss'

interface ToastProps {
  className?: string
  content: string | React.ReactNode
  status?: 'success' | 'system' | 'alert' | 'warning' | undefined
}

const Toast = (props: ToastProps) => {
  const { className, content, status } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[`${currentTheme}Slice`],
        status && styles[status],
        className,
      )}
    >
      <div className={styles.toastMessage}>{content}</div>
    </div>
  )
}

export default Toast
