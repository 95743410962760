import { Select } from '@mantine/core'
import { GetInputPropsReturnType } from '@mantine/form/lib/types'

import { Icons } from 'src/common/components'

import styles from './modal-select.module.scss'

interface ModalSelectProps extends Partial<GetInputPropsReturnType> {
  placeholder: string
  label: string
  options: { value: string; label: string }[]
  required?: boolean
}

const ModalSelect = ({
  options,
  placeholder,
  label,
  required = true,
  ...props
}: ModalSelectProps) => {
  return (
    <Select
      label={label}
      required
      rightSection={<Icons.ArrowSelect />}
      placeholder={placeholder}
      comboboxProps={{
        withinPortal: false,
        position: 'bottom',
        middlewares: { flip: false, shift: false },
        offset: 0,
        transitionProps: { transition: 'scale-y', duration: 300 },
      }}
      withScrollArea={false}
      classNames={{
        input: styles.input,
        section: styles.section,
        label: styles.label,
        dropdown: styles.dropdown,
      }}
      {...props}
      data={options}
    />
  )
}

export default ModalSelect
