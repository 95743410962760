import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IUseGetEmailDomain {
  emailDomainId: string
}

const useGetEmailDomain = (props: IUseGetEmailDomain) => {
  const { emailDomainId } = props

  return useQuery({
    queryKey: [`getEmailDomain`],
    queryFn: async () => await api.get(`/email-domains/${emailDomainId}`),
  })
}

export default useGetEmailDomain
