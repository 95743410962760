import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetOrganizationUsersParams {
  organizationId: string
}

const useGetOrganizationUsers = (params: GetOrganizationUsersParams) => {
  const { organizationId } = params
  return useQuery({
    queryKey: ['getOrganizationsUsers', organizationId],
    queryFn: async () => {
      return await api
        .get(`/organization/${organizationId}/users`)
        .then((result) => {
          return {
            ...result,
            data: result.data,
          }
        })
    },
  })
}

export default useGetOrganizationUsers
