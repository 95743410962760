import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/tiptap/styles.css';
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import * as Sentry from '@sentry/react'
import 'mantine-datatable/styles.layer.css'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import 'src/app.module.scss'
import { APIProviders } from 'src/common/api'
import { GooglePlacesApiProvider } from 'src/common/context'
import 'src/common/libs/yup'
import 'src/common/styles/global.scss'
import { TemplateWrapper } from 'src/common/templates'

import ThemeProvider from './configs/styles/ThemeProvider'
import AppRoutes from './routes'
import { store } from './v2/store/store'

Sentry.init({
  dsn: 'https://dce540f21c590272e68106d188dec1f1@o1204727.ingest.us.sentry.io/4506614768861184',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: ['Request failed with status code 401'],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <APIProviders>
          <GooglePlacesApiProvider>
            <TemplateWrapper>
              <AppRoutes />
              <Notifications />
            </TemplateWrapper>
          </GooglePlacesApiProvider>
        </APIProviders>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)
