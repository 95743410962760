import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import AddressInformation from './components/AddressInformation/AddressInformation'
import ContactInformation from './components/ContactInformation/ContactInformation'

interface IBasicInformation {
  showUpdatePerson: boolean
  policies: string[]
}

const BasicInformation = (props: IBasicInformation) => {
  const { showUpdatePerson, policies } = props
  return (
    <>
      <ContactInformation
        showUpdatePerson={showUpdatePerson}
        policies={policies}
      />
      <AddressInformation showUpdatePerson={showUpdatePerson} />
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BasicInformation,
  'is_enable_basic_information',
  true,
)
