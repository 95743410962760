import { UseFormReturnType } from '@mantine/form'
import { ApiResponseSerializer } from '@v2/commons/interface/ApiResponseSerializer'

export interface TicketModel {
  id: string
  numberVisit: number
  status: string
  reasonForVisit: string
  location: string
  personId?: string
  isVerifiedPerson: boolean
  name: string
  phoneNumber: string
  email: string
  assignUserId: string
  createdAt: string
  showTicketBlockInformation?: boolean
}

export type TicketsModel = ApiResponseSerializer<TicketModel>
export interface TicketFiltersModel {
  isOpenTickets?: boolean
  status?: string
  reason?: string
  date?: string
  assignedTo?: string
  offset?: number
}

export interface IOfficeVisit {
  id?: string
  reasonForVisit?: string
  assignedToId?: string
  status?: string
  note?: string | null
}
export interface OpenTicketsProps {
  isLoading: boolean
  isErrorAssignTicket: boolean
  tickets: TicketsModel | undefined
  openAssignModal: () => void
  usersOptions: { value: string; label: string }[]
  setOrganizer: React.Dispatch<
    React.SetStateAction<{ label: string; value: string }>
  >
  setTicketToUpdate: React.Dispatch<React.SetStateAction<string>>
}

export interface ITicketsContainerSchema {
  assignedToUsers: { value: string; label: string }[]
  openTickets: TicketsModel | undefined
  pastTickets: TicketsModel | undefined
  isLoadingOpenTickets: boolean
  isFetchingOpenTickets: boolean
  isSuccessOpenTickets: boolean
  handlePaginateOpenTickets: () => void
  handlePaginatePastTickets: () => void
  updateTicketMutationStatus: {
    isAssignTicketLoading: boolean
    isSuccessAssignTicket: boolean
    isErrorAssignTicket: boolean
  }
  TicketFiltersForm: UseFormReturnType<TicketFiltersModel>
  handleAssignTicketToUser: (ticketId: string, assignedToUser: string) => void
}
export interface ITicketsUseCaseSchema {
  openTickets: TicketsModel | undefined
  pastTickets: TicketsModel | undefined
  queryStatus: {
    isFetchingOpenTickets: boolean
    isLoadingOpenTickets: boolean
    isSuccessOpenTickets: boolean
  }
  TicketFiltersForm: UseFormReturnType<TicketFiltersModel>
  handleAssignTicketToUser: (
    ticketId: string,
    assignedToUser: string,
  ) => Promise<any>
  updateTicketMutationStatus: {
    isAssignTicketLoading: boolean
    isSuccessAssignTicket: boolean
    isErrorAssignTicket: boolean
  }
  handlePaginateOpenTickets: () => void
  handlePaginatePastTickets: () => void
}

export const REASONS_FOR_VISIT = [
  { label: 'Tickets', value: 'Tickets' },
  { label: 'Driver Holiday Party 2023', value: 'Driver Holiday Party 2023' },
  { label: 'Class', value: 'Class' },
  { label: 'Deactivation', value: 'Deactivation' },
  { label: 'Immigration', value: 'Immigration' },
  { label: 'Accident', value: 'Accident' },
  { label: 'Wellness', value: 'Wellness' },
  { label: 'Benefits', value: 'Benefits' },
  { label: '5 Star Classes', value: '5 Star Classes' },
  { label: 'Other', value: 'other' },
]

export const STATUS_TICKETS = [
  { label: 'Open', value: 'Open' },
  { label: 'Resolved', value: 'Resolved' },
  { label: 'Closed', value: 'Closed' },
  { label: 'In Progress', value: 'In Progress' },
]

export const PAST_STATUS_TICKETS = [
  { value: 'closed', label: 'Closed' },
  { value: 'resolved', label: 'Resolved' },
]
