import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { FieldWrapper, Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import {
  ICurrentOptionComponent,
  IOptionComponent,
} from 'src/default/interfaces'

import { useAppSelector } from '../../../../../v2/store/hooks'
import BasicSelectGroups from '../BasicSelectGroups'
import BasicCurrentOption from './BasicCurrentOption'
import BasicOption from './BasicOption'
import styles from './select.module.scss'

interface SelectProps extends IField {
  OptionComponent?: (props: IOptionComponent) => React.ReactElement
  CurrentOptionComponent?: (
    props: ICurrentOptionComponent,
  ) => React.ReactElement
  closeOnSelect?: boolean
  strictOpen?: boolean
  withArrow?: boolean
  optionsWrapperClassName?: string
  hideErrorMessage?: boolean
  onSelectDisabled?: (value: string) => void
}

const Select = (props: SelectProps) => {
  const {
    CurrentOptionComponent = BasicCurrentOption,
    OptionComponent = BasicOption,
    strictOpen,
    closeOnSelect = true,
    withArrow = true,
    strictShowOption,
    showMetaForCurrentOption,
    optionsWrapperClassName,
    hideErrorMessage,
    isDisabledOptions,
    isOverflowValue,
    ...rest
  } = props
  const {
    inputClassName,
    name,
    options = [],
    placeholder,
    size,
    disabled,
    value,
    template = 'basic',
    onChange,
    onCustomEvent,
    onSelectDisabled,
  } = rest

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )

          if (!isClickInside) {
            setIsOpen(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsOpen(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const [field, meta, { setValue }] = useField({ name })

  const handleOpen = () => {
    if (!disabled && typeof strictOpen === 'undefined') {
      setIsOpen(!isOpen)
      if (onCustomEvent) {
        onCustomEvent('toggleList', !isOpen)
      }
    }
  }

  const handleChange = (newValue: string) => {
    if (!disabled) {
      if (closeOnSelect) {
        handleOpen()
      }

      if (typeof value === 'undefined') {
        setValue(newValue)
      }

      if (onChange) {
        onChange(newValue)
      }
    }
  }

  const currentValue = typeof value !== 'undefined' ? value : field.value

  return (
    <FieldWrapper {...rest} hideErrorMessage={hideErrorMessage}>
      <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
        <div
          className={cx(
            styles.input,
            size && styles[size],
            meta.error && meta.touched && styles.error,
            disabled && styles.disabled,
            (strictOpen || isOpen) && styles.open,
            (strictOpen || isOpen) && `${name}--is-open`,
            styles[`template-${template}`],
            styles[`${inputClassName}`],
          )}
          ref={selectRef}
          data-name={name}
          onClick={handleOpen}
        >
          <CurrentOptionComponent
            name={name}
            value={currentValue}
            placeholder={placeholder}
            options={options}
            strictShowOption={strictShowOption}
            showMetaForCurrentOption={showMetaForCurrentOption}
            onCustomEvent={onCustomEvent}
            isOverflowValue={isOverflowValue}
          />

          {withArrow ? (
            <div
              className={cx(
                styles.arrow,
                (strictOpen || isOpen) && styles.open,
              )}
            >
              <Icons.ArrowSelect />
            </div>
          ) : null}

          {strictOpen || isOpen ? (
            <div
              className={cx(
                styles.basicOptions,
                size && styles[size],
                styles[`template-${template}`],
                optionsWrapperClassName,
              )}
              onClick={(e) => e.stopPropagation()}
            >
              {options?.map((o) => {
                if (o?.hide) return null
                return (
                  <OptionComponent
                    key={o.value}
                    {...o}
                    fieldValue={currentValue}
                    onChange={handleChange}
                    isDisabledOptions={isDisabledOptions}
                    isDisabled={o?.disabled}
                    onSelectDisabled={onSelectDisabled}
                  />
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
    </FieldWrapper>
  )
}

Select.Groups = BasicSelectGroups

export default Select
