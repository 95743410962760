import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetOrganizationTags = () => {
  let url = `/persons/tags/organization`

  return useQuery({
    queryKey: [`getOrganizationTags`, url],
    queryFn: async () => await api.get(url),
  })
}

export default useGetOrganizationTags
