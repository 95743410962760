import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useState } from 'react'

import { Input, InputDate } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'

import styles from './input-date-and-time.module.scss'

interface IInputDateTimeProps extends IField {
  timeValue?: string
  nameForDate?: string
}

const InputDateAndTime = (props: IInputDateTimeProps) => {
  const {
    className,
    name,
    width,
    tabletWidth,
    nameForDate,
    label,
    required,
    pastDate,
  } = props

  const [, meta, helpers] = useField({ name })
  const [, metaNameForDate] = useField({ name: nameForDate ?? '' })

  const [time, setTime] = useState(dayjs(meta.value).tz().format('HH:mm'))

  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value)
    const value = event.target.value.split(':')
    const newDate = dayjs(metaNameForDate?.value)
      .tz()
      .hour(Number(value[0]))
      .minute(Number(value[1]))
    if (newDate) {
      helpers.setValue(newDate)
    }
  }

  useEffect(() => {
    const dayJsMeta = dayjs(meta.value).tz()
    const dayJsMetaNameForDate = dayjs(metaNameForDate?.value).tz()

    if (
      dayJsMeta.isValid() &&
      dayJsMetaNameForDate.isValid() &&
      dayJsMeta.format('YYYY-MM-DD') ===
        dayJsMetaNameForDate.format('YYYY-MM-DD')
    ) {
      setTime(dayJsMeta.format('HH:mm'))
    }

    if (
      dayJsMeta.isValid() &&
      dayJsMetaNameForDate.isValid() &&
      dayJsMeta.format('YYYY-MM-DD') !==
        dayJsMetaNameForDate.format('YYYY-MM-DD')
    ) {
      const value = time.split(':')
      helpers.setValue(
        dayJsMetaNameForDate.hour(Number(value[0])).minute(Number(value[1])),
      )
    }
  }, [meta.value, metaNameForDate?.value])

  return (
    <div
      className={cx(
        styles.wrapper,
        width && styles[`w${width}`],
        tabletWidth && styles[`tabletW${tabletWidth}`],
      )}
    >
      <div className={styles.label}>
        {label} {required ? <span>*</span> : null}
      </div>
      <div>
        <div className={styles.inner}>
          <InputDate
            {...props}
            className={cx(styles.date, className)}
            width={undefined}
            withError={false}
            name={nameForDate ?? name}
            pastDate={pastDate}
            label=""
          />

          <div className={styles.time}>
            <Input
              type="time"
              name="time"
              withError={false}
              onChange={handleChangeTime}
              value={time}
            />
          </div>
        </div>
      </div>

      <div className={styles.errorComment}>
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </div>
  )
}

export default InputDateAndTime
