import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { FileAttachments } from 'src/default/interfaces/communication/email-communication'

interface ISendEmail {
  fromEmailId: string
  replyToEmailId?: string
  taskId?: string
  isDeltaType?: string
  personId: string
  toEmail?: string
  subject: string
  body: string
  attachments: File[]
  attachmentsFilesName: FileAttachments[]
}

const useSendEmailMutation = () =>
  useMutation({
    mutationFn: async (data: ISendEmail) => {
      const formData = new FormData()
      formData.append('fromEmailId', data.fromEmailId)
      if (data.replyToEmailId) {
        formData.append('replyToEmailId', data.replyToEmailId)
      }
      if (data.taskId) {
        formData.append('taskId', data.taskId)
      }
      if (data.isDeltaType) {
        formData.append('isDeltaType', data.isDeltaType)
      }
      formData.append('personId', data.personId)
      if (data.toEmail) {
        formData.append('toEmail', data.toEmail)
      }
      if (data.attachmentsFilesName) {
        formData.append(
          'attachmentsFilesName',
          JSON.stringify(data.attachmentsFilesName),
        )
      }
      formData.append('subject', data.subject)
      formData.append('body', data.body)

      data.attachments.forEach((attachment) => {
        formData.append('attachments', attachment)
      })

      return api.post(`v4/persons/email/`, formData)
    },
  })

export default useSendEmailMutation
