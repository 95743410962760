import { useMutation } from '@tanstack/react-query'
import type { AxiosRequestHeaders } from 'axios'
import axios from 'axios'

import { api } from 'src/common/api'

interface UploadAttachmentParams {
  attachmentType?:
    | 'enrollment'
    | 'document'
    | 'person-file'
    | 'emails'
    | 'emails-images'
}

const useUploadAttachment = (params: UploadAttachmentParams) =>
  useMutation({
    mutationFn: async (data: { file: File }) => {
      const { file } = data

      const { attachmentType = 'document' } = params
      const url = (() => {
        switch (attachmentType) {
          case 'document':
            return `/person/documents/generate-presigned-url`
          case 'person-file':
            return `/persons/dataloader/generate-upload-url`
          case 'enrollment':
            return `/enrollments/attachments/generate-presigned-url`
          case 'emails':
            return `/emails/generate-pre-signed-url`
          case 'emails-images':
            return `/emails/images/generate-pre-signed-url`
          default:
            return `/generate-presigned-url`
        }
      })()

      const generateUrlResponse = await api.get(
        `${url}?contentType=${encodeURIComponent(file.type)}`,
      )

      const presignedUrl = generateUrlResponse.data

      if (presignedUrl) {
        return axios.put(presignedUrl, file, {
          transformRequest: (data, headers: any) => {
            //TODO ts
            delete headers.common['Authorization']

            headers['Content-Type'] = file.type

            return data
          },
        })
      }
    },
    retry: 2, //TODO not good, but not bad for now
  })

export default useUploadAttachment
