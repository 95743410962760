import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import avatar from '../assets/Avatar.png'
import styles from './user-information.module.scss'

interface UserInformationProps {
  isSidebarCollapsed?: boolean
}

const UserInformation = (props: UserInformationProps) => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const { isSidebarCollapsed } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.profileImage}>
        <img src={avatar} alt={userProfile.user.firstName} />
      </div>
      {!isSidebarCollapsed && (
        <div className={styles.userDetails}>
          <div
            className={styles.userName}
          >{`${userProfile.user.firstName} ${userProfile.user.lastName}`}</div>
          <div className={styles.userRole}>
            {userProfile.membership.membershipRole.name}
          </div>
        </div>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserInformation,
  'is_enable_user_information',
  true,
)
