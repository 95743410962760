import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface NycLookupParams {
  findBy?: string
  findValue?: string
  enabled?: boolean
}

const useNycLookupLicense = (params: NycLookupParams) => {
  const { enabled, findBy, findValue } = params

  return useQuery({
    queryKey: [`getNYCLookupLicense`, findBy, findValue],
    queryFn: async () =>
      await api.get('/v2/nyc-open-data/lookup', {
        params: {
          findBy: findBy?.trim?.(),
          findValue: findValue?.trim?.(),
        },
      }),
    enabled: enabled && !!findBy && !!findValue,
  })
}

export default useNycLookupLicense
