import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE as BENESTREAM_DOCUMENT_TITLE } from 'src/benestream/config'
//TODO mb refactor
import { DEFAULT_DOCUMENT_TITLE } from 'src/default/config'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'

import { useAppSelector } from '../../../v2/store/hooks'

const useDocumentTitle = (title: string | undefined) => {
  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const defaultTitle =
    currentTheme === CurrentTheme.BENESTREAM
      ? BENESTREAM_DOCUMENT_TITLE
      : DEFAULT_DOCUMENT_TITLE

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = title ? `${title} | ${defaultTitle}` : defaultTitle
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.title = defaultTitle
      }
    }
  }, [title])
}

export default useDocumentTitle
