import { BasicBlock, SelectOrganization } from 'src/common/components'
import { BENESTREAM_ORG_ID } from 'src/common/config'
import { useChangeOrganization } from 'src/common/hooks'
import { appLocalStorage } from 'src/common/libs'
import { cleanStringToBeUrlReadable } from 'src/default/helpers/clean-string'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { resetAccountStoreAccount } from '../../../../v2/domains/person/models/account/assignAccount.store'
import { resetLeadStoreLead } from '../../../../v2/domains/person/models/leads/assignLead.store'
import {
  setCurrentTheme,
  setGlobalIsLoading,
} from '../../../../v2/domains/ui/models/ui.store'
import { useAppDispatch } from '../../../../v2/store/hooks'
import CRMSelectOrganizationEmpty from './CRMSelectOrganizationEmpty'
import styles from './crm-select-organization.module.scss'

const CRMSelectOrganization = () => {
  const dispatch = useAppDispatch()

  const organizationsByUser = JSON.parse(
    appLocalStorage.getData('organizationByUser'),
  )
  const changeOrganizationMutation = useChangeOrganization()

  const handleChangeOrganization = async (
    organizationId: string,
    membershipId: string,
  ) => {
    dispatch(setGlobalIsLoading(true))
    changeOrganizationMutation
      .mutateAsync({ organizationId, membershipId })
      .then((response) => {
        dispatch(resetLeadStoreLead())
        dispatch(resetAccountStoreAccount())
        dispatch(
          setCurrentTheme(
            BENESTREAM_ORG_ID === organizationId
              ? CurrentTheme.BENESTREAM
              : CurrentTheme.DEFAULT_360,
          ),
        )
        dispatch({
          type: 'redirect',
          payload: {
            path: `/${cleanStringToBeUrlReadable(
              response.data.organizationName ?? '',
            )}/leads`,
          },
        })
        dispatch(setGlobalIsLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  return (
    <div className={styles.wrapper}>
      <BasicBlock className={styles.inner}>
        {organizationsByUser.length > 0 ? (
          <>
            <div className={styles.title}>Please select your organization</div>

            <SelectOrganization onChange={handleChangeOrganization} />
          </>
        ) : (
          <CRMSelectOrganizationEmpty />
        )}
      </BasicBlock>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CRMSelectOrganization,
  'is_enable_select_organization',
  true,
)
