import cx from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { Form, Icons, Input, Modal, SubmitButton } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './mutate-benefits-administration-item-quill-width-modal.module.scss'

interface MutateBenefitsAdministrationItemQuillWidthModalProps extends IModal {
  initialValues: { width: number; height: number }
  onSubmit: (values: { width: number; height: number }) => Promise<any>
}

const MutateBenefitsAdministrationItemQuillWidthModal = (
  props: MutateBenefitsAdministrationItemQuillWidthModalProps,
) => {
  const { initialValues, onSubmit, onClose } = props

  const [loading, setLoading] = useState(false)
  const [isChainActive, setIsChainActive] = useState(true)

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setLoading(true)
      await onSubmit(values)
      setLoading(false)
    },
    validationSchema: yup.object().shape({
      width: yup.number().required().moreThan(0),
      height: yup.number().required().moreThan(0),
    }),
  })

  const handleChange = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    formik.setFieldValue(name, value)

    if (isChainActive) {
      if (name === 'width') {
        formik.setFieldValue(
          'height',
          Math.round(
            (Number(value) * initialValues.height) / initialValues.width,
          ),
        )
      } else {
        formik.setFieldValue(
          'width',
          Math.round(
            (Number(value) * initialValues.width) / initialValues.height,
          ),
        )
      }
    }
  }

  return (
    <Modal
      id="mutateBenefitsAdministrationItemQuillWidthModal"
      onClose={onClose}
    >
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>Enter image dimensions</div>

        <div className={styles.inner}>
          <Input
            required
            name="width"
            type="number"
            label="Width"
            placeholder="Enter width"
            value={formik.values.width}
            onChange={(event) => handleChange('width', event)}
          />

          <div
            className={styles.chain}
            onClick={() => setIsChainActive(!isChainActive)}
          >
            <div className={cx(styles.icon, isChainActive && styles.active)}>
              <Icons.Chain />
            </div>
          </div>

          <Input
            required
            name="height"
            type="number"
            label="Height"
            placeholder="Enter Height"
            value={formik.values.height}
            onChange={(event) => handleChange('height', event)}
          />
        </div>

        <SubmitButton loading={loading}>Submit</SubmitButton>
      </Form>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationItemQuillWidthModal,
  'is_enable_mutate_benefits_administration_item_quil',
)
