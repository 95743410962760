import { useFormikContext } from 'formik'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import useMutationGenerateEmail from 'src/benestream/hooks/person/useMutationGenerateEmail'
import {
  Button,
  Checkbox,
  Input,
  InputDateAndAge,
  InputPhone,
  InputTLCNumber,
  Select,
  SelectLanguage,
} from 'src/common/components'
import { GENDER_OPTIONS } from 'src/common/config'

import { OtherEmail } from '../../../../../../../default/components'
import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './benestream-person-form-contact-information.module.scss'

const BenestreamPersonFormContactInformation = ({
  personType,
}: {
  personType: 'lead' | 'account'
}) => {
  const generateEmail = useMutationGenerateEmail()

  const { setFieldValue, values } = useFormikContext()

  const handleGenerateEmail = () => {
    generateEmail.mutateAsync().then((data) => {
      setFieldValue('email', data)
    })
  }
  return (
    <BenestreamPersonFormBlock title="Contact Information">
      <Input
        required
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
        tabletWidth="50"
      />

      <Input
        required
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        tabletWidth="50"
      />

      <div className={styles.emailWrapper}>
        <PolicyCheckHOC
          policyName="is_enable_generate_email"
          disablePolicyCheck
        >
          <Button
            onClick={handleGenerateEmail}
            className={styles.generateEmailButton}
            disabled
            size="small"
          >
            Generate Email
          </Button>
        </PolicyCheckHOC>
        <PolicyCheckHOC
          policyName="is_enable_email_edit"
          defaultComponent={
            <Input
              required
              type="email"
              name="email"
              label="Main Email"
              disabled
            />
          }
        >
          <Input required type="email" name="email" label="Main Email" />
        </PolicyCheckHOC>
      </div>

      <PolicyCheckHOC policyName="is_enable_other_email">
        <OtherEmail
          inputClassName={styles.otherEmail}
          name="otherEmail"
          label="Secondary Emails"
        />
      </PolicyCheckHOC>

      <InputPhone
        required
        name="phoneNumber"
        label="Main Mobile Phone"
        tabletWidth="50"
      />

      <InputPhone
        name="otherPhoneNumber"
        label="Secondary Mobile Phone"
        tabletWidth="50"
      />

      <Select
        name="gender"
        label="Gender"
        options={GENDER_OPTIONS}
        placeholder="Select Gender"
        tabletWidth="50"
      />

      <InputDateAndAge
        required
        name="birthDate"
        label="Date of Birth"
        tabletWidth="50"
      />

      <SelectLanguage
        required={personType !== 'lead'}
        name="preferredLanguage"
        label="Preferred Language"
        placeholder="Select Preferred Language"
        tabletWidth="50"
      />

      {personType === 'lead' ? (
        <>
          <Input
            required={personType !== 'lead'}
            name="householdSize"
            label="Household Size"
            placeholder="Enter Household Size"
            type="number"
            tabletWidth="50"
          />
          <Input
            required={personType !== 'lead'}
            name="monthlyIncome"
            label="Primary Monthly Income"
            type="number"
            tabletWidth="50"
            disabled
          />
        </>
      ) : null}

      <InputTLCNumber
        name="tlcLicenseNumber"
        label="TLC Number"
        tabletWidth="50"
        disabled
      />

      <Input
        name="driverLicense"
        label="Driver License"
        type="text"
        tabletWidth="50"
        disabled
      />

      <Checkbox
        name="agreedToPrivacyWaiver"
        label="Agreed to Privacy Waiver"
        classNameWrapper={styles.checkboxWrapper}
        required={personType !== 'lead'}
      />
    </BenestreamPersonFormBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonFormContactInformation,
  'is_enable_benestream_person_form_contact_information',
  true,
)
