import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import EmailSender from './EmailSender'
import ManagerTemplateModal from './ManagerTemplateModal/ManagerTemplateModal'
import styles from './email-sender.module.scss'

const EmailSenderAndManagerEmailTemplate = () => {
  const { showManagerEmailTemplateModal } =
    useEmailSenderAndManagerEmailTemplateContext()
  return (
    <div className={styles.blockEmailContainer}>
      <EmailSender />
      {showManagerEmailTemplateModal && <ManagerTemplateModal />}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EmailSenderAndManagerEmailTemplate,
  'is_enable_email_sender_and_manager_email_template',
  true,
)
