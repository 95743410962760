import { useFormik } from 'formik'
import { useEffect } from 'react'

import { Form } from 'src/common/components'
import { IDMPCase } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import DeactivationBenefitCaseBlocks from './DeactivationBenefitCaseBlocks'
import styles from './deactivation-benefit-case.module.scss'

interface DeactivationBenefitCaseProps {
  data: IDMPCase
}

const DeactivationBenefitCase = (props: DeactivationBenefitCaseProps) => {
  const { data } = props

  const caseFormik = useFormik<IDMPCase>({
    initialValues: {
      ...data,
    },
    onSubmit: () => {},
  })

  useEffect(() => {
    caseFormik.setValues({ ...data })

    if (typeof document !== 'undefined') {
      document.getElementById('caseScrollAnchor')?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [data?.key])

  useEffect(() => {
    // console.log('VALUES')
    // console.log(caseFormik.values)
  }, [caseFormik.values])

  return (
    <Form className={styles.wrapper} providerValue={caseFormik}>
      <div id="caseScrollAnchor" />

      <div className={styles.blockTitle}>Deactivation Cases</div>

      {DeactivationBenefitCaseBlocks.map((Block, i) => (
        <Block
          key={i}
          // TODO(marco): check type
          // @ts-ignore
          caseId={caseFormik.values._id}
          customerId={caseFormik.values?.customer._id}
        />
      ))}
    </Form>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitCase,
  'is_enable_deactivation_benefit_case',
  true,
)
