import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benefits-administration-block-header.module.scss'

interface BenefitsAdministrationBlockHeaderProps {
  title: string
}

const BenefitsAdministrationBlockHeader = (
  props: React.PropsWithChildren<BenefitsAdministrationBlockHeaderProps>,
) => {
  const { title, children } = props

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>

      {children}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationBlockHeader,
  'is_enable_benefit_administration_block_header_view',
  true,
)
