import { useState } from 'react'

import { Spinner } from 'src/common/components'
import dayjs from 'src/common/libs/dayjs'
import { useNycLookupLicense } from 'src/default/hooks/'

import styles from './nyc-license-lookup-result.module.scss'

interface NycLicenseLookupResultWrapperProps {
  findBy: string
  findValue: string
}

const NycLicenseLookupResultWrapper = (
  props: NycLicenseLookupResultWrapperProps,
) => {
  const { findBy, findValue } = props

  const { data, isLoading } = useNycLookupLicense({
    enabled: true,
    findBy,
    findValue: findValue ? String(findValue) : undefined,
  })

  //const showResult = false

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spinner color="primary" size="normal" />
        </div>
      ) : (
        <NycLicenseLookupResult data={data?.data ?? []} />
      )}
    </div>
  )
}

interface NycLicenseLookupResultProps {
  data: any
}

const NycLicenseLookupResult = (props: NycLicenseLookupResultProps) => {
  const { data } = props

  const [currentResult, setCurrentResult] = useState<any | null>(null) //TODO ts

  const handleResultsClick = (information: any) => {
    //TODO ts
    setCurrentResult({ ...information })
  }

  if (!data?.id) {
    return (
      <div className={styles.resultsContainer}>
        <div className={styles.errorMessage}>
          Driver is not found in NYC Open Data
        </div>
      </div>
    )
  }

  if (data?.id || currentResult) {
    const result = currentResult || data
    const transformDate = (date: string) => {
      if (!date) {
        return dayjs()
      }
      const [simpleDate] = date.split('T')
      return dayjs(simpleDate)
    }
    return (
      <div className={styles.resultContainer}>
        <div className={styles.name}>
          {result.name} - {result.licenseNumber}
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.titles}>Expiration date:</div>
          <div className={styles.infoDates}>
            {transformDate(result.expirationDate).format('MMM DD, YYYY')}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.titles}>Last Update:</div>
          <div className={styles.infoDates}>
            {transformDate(result?.lastDateUpdated).format('MMM DD, YYYY')}
          </div>
        </div>
        <div>
          {result?.isActive ? (
            <h3 className={styles.active}>ACTIVE</h3>
          ) : (
            <h3 className={styles.inactive}>INACTIVE</h3>
          )}
        </div>
      </div>
    )
  }

  if (data?.length > 1) {
    return (
      <div className={styles.resultsContainer}>
        <div className={styles.totalRecords}>{data.length} Records found</div>
        {data.map((information: any, index: number) => {
          //TODO ts
          return (
            <div
              key={index}
              onClick={() => handleResultsClick(information)}
              className={styles.result}
            >
              {information.name} - {information.licenseNumber}
            </div>
          )
        })}
      </div>
    )
  }

  return null
}

export default NycLicenseLookupResultWrapper
