import { useEffect, useState } from 'react'

import { getIsVerifiedPerson } from 'src/common/helpers'
import { IPerson } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import { Icons } from '../../ui'
import FormDuplicatePerson from './FormDuplicatePerson'

interface DuplicatePersonsProps {
  duplicatePerson: IPerson
  currentPerson: IPerson
  isBenestream?: boolean
  persons: IPerson[]
}

const DuplicatePersons = (props: DuplicatePersonsProps) => {
  const { duplicatePerson, currentPerson, isBenestream, persons } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id

  const [duplicatePersonState, changeDuplicatedPersonState] =
    useState(duplicatePerson)
  const [duplicatePersonIndex, changeDuplicatePersonIndex] = useState(
    persons?.findIndex((item) => item.id === duplicatePerson.id) + 1 || 0,
  )

  const changeDuplicatedPerson = (index: number) => {
    changeDuplicatedPersonState(persons[index - 1])
    changeDuplicatePersonIndex(index)
  }

  useEffect(() => {
    changeDuplicatedPersonState(duplicatePerson)
    changeDuplicatePersonIndex(
      persons?.findIndex((item) => item.id === duplicatePerson.id) + 1 || 0,
    )
  }, [duplicatePerson, persons])

  return (
    <div className="py-16 pb-10">
      <div className="text-default text-2xl text-center font-bold">
        Potential Duplicate Records
      </div>
      <div className="pt-12 flex flex-nowrap overflow-y-scroll">
        <div className="w-1/2 px-10 border-r border-primary">
          <div className="flex-center gap-4 text-primary text-base font-semibold pb-2 border-b border-primary">
            Current Record
            <div className="text-default font-normal">
              {getIsVerifiedPerson(
                currentPerson.enrollments,
                currentOrganizationId!,
              )
                ? '(Account)'
                : '(Lead)'}
            </div>
          </div>
          <FormDuplicatePerson
            person={currentPerson}
            isBenestream={isBenestream}
          />
        </div>
        <div className="w-1/2 px-10">
          <div className="flex-center pb-2 border-b border-primary justify-between w-full">
            <div className="flex-center gap-4 text-primary text-base font-semibold">
              Duplicate
              <div className="text-default font-normal">
                {getIsVerifiedPerson(
                  duplicatePersonState.enrollments,
                  currentOrganizationId!,
                )
                  ? '(Account)'
                  : '(Lead)'}
              </div>
            </div>
            <div className="flex-center text-primary gap-1">
              {duplicatePersonIndex > 1 ? (
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    changeDuplicatedPerson(duplicatePersonIndex - 1)
                  }
                >
                  <Icons.ArrowLeft />
                </div>
              ) : null}
              <div>{duplicatePersonIndex}</div>
              {duplicatePersonIndex < persons?.length ? (
                <div
                  className="text-primary cursor-pointer"
                  onClick={() =>
                    changeDuplicatedPerson(duplicatePersonIndex + 1)
                  }
                >
                  <Icons.ArrowRight className="fill-primary stroke-primary stroke-0" />
                </div>
              ) : null}
            </div>
          </div>
          <FormDuplicatePerson
            person={duplicatePersonState}
            highlightDuplicateFields
            otherPerson={currentPerson}
            isBenestream={isBenestream}
          />
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DuplicatePersons,
  'is_get_duplicate_persons_enabled',
)
