import {
  addSelectedItemAccount,
  removeSelectedItemAccount,
  resetAccountStoreAccount,
  setIsAllSelectedAccounts,
} from 'src/v2/domains/person/models/account/assignAccount.store'
import { useAppDispatch, useAppSelector } from 'src/v2/store/hooks'

import { AssignPersonModel } from '../../models/assignPerson.model'
import { type PersonModel } from '../../models/person.model'

const UseAssignAccountUseCase = () => {
  const dispatch = useAppDispatch()
  const { selectedItems } = useAppSelector((state) => state.account.filters)

  const handleAddRemoveAccount = (
    account: PersonModel['id'],
    addAccount: boolean,
  ) => {
    if (addAccount) {
      dispatch(addSelectedItemAccount(account))
    } else {
      dispatch(removeSelectedItemAccount(account))
    }
  }

  const handleSelectAllAccounts = ({
    isAllSelected,
    allSelectedPersons,
  }: {
    isAllSelected: boolean
    allSelectedPersons: AssignPersonModel['filters']['selectedItems']
  }) => {
    const payload = { isAllSelected, allSelectedPersons }
    dispatch(setIsAllSelectedAccounts(payload))
  }

  const handleClearStoreAccount = () => {
    dispatch(resetAccountStoreAccount())
  }

  return {
    selectedItems,
    handleAddRemoveAccount,
    handleSelectAllAccounts,
    handleClearStoreAccount,
  }
}

export default UseAssignAccountUseCase
