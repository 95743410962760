import { DatesRangeValue } from '@mantine/dates'
import { type UseFormReturnType } from '@mantine/form/lib/types'
import { filterUndefinedValues } from '@v2/commons/utils/filterUndefinedValues'
import { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import dayjs from 'src/common/libs/dayjs'
import { PAGINATION_LIMIT } from 'src/v2/commons/config'
import usePersistentForm from 'src/v2/commons/hooks/forms/usePersistentValuesForm'
import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import { usePersonsQuery } from 'src/v2/domains/person/models/person.api'
import { type PersonsFiltersModel } from 'src/v2/domains/person/models/person.model'
import { useAppSelector } from 'src/v2/store/hooks'

export interface IPersonsUseCase {
  personsFiltersForm: UseFormReturnType<PersonsFiltersModel>
  handleDateRangeChange: (value: DatesRangeValue) => void
  handlePaginate: () => void
  clearAllFilters: () => void
  personsQueryStatus: {
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
  }
  data: PersonsModel | undefined
  organizationId: string
  personsIds: string[]
  isProgrammaticFormUpdateRef: React.MutableRefObject<boolean>
  isAssignMultipleTasksEnabled: boolean
}

const usePersonsUseCase = (): IPersonsUseCase => {
  const [page, setPage] = useState(0)
  const { pathname } = useLocation()
  const currentPath = pathname.split('/')
  const currentPage = currentPath[currentPath.length - 1]
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const isProgrammaticFormUpdateRef = useRef<boolean>(true)
  const initialFetchValues = {
    limit: PAGINATION_LIMIT,
    orderBy: 'createdAt DESC',
  }
  const orgId = useMemo(() => {
    setPage(0)
    return (userProfile.organization.id as string) || ''
  }, [userProfile.organization.id])

  const form = usePersistentForm<PersonsFiltersModel>({
    localStoragePath: currentPage,
    isProgrammaticFormUpdateRef,
    formConfig: {
      initialValues: {},
      onValuesChange() {
        setPage(0)
      },
    },
    parseBooleans: false,
  })

  const handleDateRangeChange = (dateValues: DatesRangeValue) => {
    const isRangeSelected = dateValues.every((item) => !!item)
    if (isRangeSelected) {
      const [startDate, endDate] = dateValues.map((dateValue) =>
        dayjs(dateValue).format('YYYY-MM-DD'),
      )
      form.setFieldValue('fromDate', startDate)
      form.setFieldValue('toDate', endDate)
    }
  }

  const clearAllFilters = () => {
    form.reset()
  }

  const isAssignMultipleTasksEnabled = !!userProfile.policies.find(
    (policy) => policy === 'is_create_multiple_task_enabled',
  )

  const filters = filterUndefinedValues(
    form.getValues() as Record<
      string,
      string | number | boolean | object | null | undefined
    >,
  )

  const { isLoading, isSuccess, data, isFetching } = usePersonsQuery({
    params: {
      ...initialFetchValues,
      ...filters,
      organizationId: orgId,
      offset: page * PAGINATION_LIMIT,
      isVerified: currentPage === 'accounts' ? true : false,
    },
  })

  const personsIds = useMemo(() => {
    if (data) {
      return data.records.map((result) => result.id)
    }
    return []
  }, [data?.records])

  const handlePaginate = () => {
    if (isFetching) return
    return setPage((prev) => {
      if (
        data &&
        data.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  return {
    personsFiltersForm: form,
    personsIds,
    data: data,
    clearAllFilters,
    handlePaginate,
    handleDateRangeChange: handleDateRangeChange,
    personsQueryStatus: {
      isLoading: isLoading,
      isSuccess: isSuccess,
      isFetching: isFetching,
    },
    isProgrammaticFormUpdateRef,
    organizationId: userProfile.organization.id || '',
    isAssignMultipleTasksEnabled,
  }
}

export default usePersonsUseCase
