import styles from './file-upload-result.module.scss'

interface FileUploadResultProps {
  value: string
}

const FileUploadResult = (props: FileUploadResultProps) => {
  const { value } = props

  return <img className={styles.image} src={value} />
}

export default FileUploadResult
