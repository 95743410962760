import { Card, Flex, Loader } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import GlobalSearchCheckBox from '@v2/domains/person/components/GlobalSearch/GlobalSearchCheckBox.component'
import SearchResultTableComponent from '@v2/domains/person/components/Tables/SearchResultTable/SearchResultTable.component'
import { useMemo } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import { type TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import SuccessErrorModal from 'src/v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'
import InfiniteScrollTemplate from 'src/v2/commons/templates/InfiniteScrollTemplate'
import AssignTaskModal from 'src/v2/domains/task/views/AssignTaskModal.view'
import FloatingTasksCard from 'src/v2/domains/task/views/FloatingTaskCard.view'

import useSearchContainer from '../../containers/search/useSearch.container'
import styles from '../table-view.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Search Results' },
]

const SearchResultsView = () => {
  const {
    data,
    searchQueryStatus,
    handlePaginate,
    searchForm,
    handleAddRemoveSearch,
    selectedItems,
    handleSelectAllSearch,
    handleClearStoreSearch,
    searchValue,
    searchResultsIds,
    userOrganizations,
    isAssignMultipleTasksEnabled,
  } = useSearchContainer()

  const isAllItemsSelected = useMemo(
    () =>
      data?.records
        .map((item) => item.id)
        .every((selectedItem) => selectedItems.includes(selectedItem)) ?? false,
    [data?.records, selectedItems],
  )
  const openFloatingMenu =
    !!data?.records.length && (isAllItemsSelected || !!selectedItems.length)

  const [
    assignTaskModalOpen,
    { open: openAssignTaskModal, close: closeAssignTaskModal },
  ] = useDisclosure(false)
  const [
    successTaskModalOpen,
    { open: openSuccessAssignTaskModal, close: closeSuccessAssignTaskModal },
  ] = useDisclosure(false)

  return (
    <InfiniteScrollTemplate
      isLoading={searchQueryStatus.isLoading}
      title={`Search results for ${searchValue}`}
      breadCrumbItems={breadCrumbItems}
      onScroll={handlePaginate}
      records={{
        totalRecords: data?.totalRecords || 0,
        actualNumberOfRecords: data?.records?.length ?? 0,
      }}
    >
      <GlobalSearchCheckBox
        organizationsByUser={userOrganizations}
        form={searchForm}
      />
      <Card shadow="lg" radius="lg" className={styles['default-card']}>
        <SearchResultTableComponent
          results={data?.records ?? []}
          handleAddRemoveSearch={handleAddRemoveSearch}
          handleClearStoreSearch={handleClearStoreSearch}
          handleSelectAllSearch={handleSelectAllSearch}
          isAllSelected={isAllItemsSelected}
          selectedItems={selectedItems}
          searchResultsIds={searchResultsIds}
          isCrossOrganization={
            searchForm.getValues()['getUserOrganizations'] ?? false
          }
        />
        {searchQueryStatus.isFetching && !searchQueryStatus.isLoading && (
          <Flex mt="30px" mb="20px" justify="center">
            <Loader size={30} />
          </Flex>
        )}
        {openFloatingMenu && (
          <FloatingTasksCard
            location="search"
            selectedItems={selectedItems}
            openAssignTaskModal={openAssignTaskModal}
            isMergingToolActive={true}
            onDeselect={handleClearStoreSearch}
            isAssignMultipleTasksEnabled={isAssignMultipleTasksEnabled}
          />
        )}
        {assignTaskModalOpen && (
          <AssignTaskModal
            isOpen={assignTaskModalOpen}
            close={closeAssignTaskModal}
            selectedItems={selectedItems}
            openSuccessAssignTaskModal={openSuccessAssignTaskModal}
            pageFilters={{}}
            clearStore={handleClearStoreSearch}
          />
        )}
        {successTaskModalOpen && (
          <SuccessErrorModal
            status="success"
            title="Tasks created successfully"
            subTitle="Tasks were created and assigned correctly."
            buttonText="Review Tasks"
            opened={successTaskModalOpen}
            onClose={closeSuccessAssignTaskModal}
            path={PRIVATE_ROUTES.TASKS.path}
          />
        )}
      </Card>
    </InfiniteScrollTemplate>
  )
}

export default SearchResultsView
