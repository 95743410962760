import { IUser } from '@v2/domains/person/models/person.model'

import { IBasicEntity, IOrganization } from 'src/common/interfaces'

export interface IEnrollmentABC extends IBasicEntity {
  id: string
  name: string
  code: string
  isDefault: boolean
  organization: IOrganization extends IBasicEntity ? IBasicEntity : any //TODO mb refactor and move extend to IOrganization
}

export enum EnumEnrollmentActiveStatus {
  ACTIVE = 'ACTIVE',
  DRIVING_HOURS_APPEAL_PERIOD = 'DRIVING_HOURS_APPEAL_PERIOD',
  DRIVING_HOURS_REACTIVATED = 'DRIVING_HOURS_REACTIVATED',
  LICENSE_GRACE_PERIOD_REACTIVATED = 'LICENSE_GRACE_PERIOD_REACTIVATED',
  LICENSE_GRACE_PERIOD = 'LICENSE_GRACE_PERIOD',
}

export enum EnumEnrollmentInactiveStatus {
  INITIAL = 'INITIAL',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  DRIVING_HOURS_DEACTIVATED = 'DRIVING_HOURS_DEACTIVATED',
  LICENSE_GRACE_PERIOD_DEACTIVATED = 'LICENSE_GRACE_PERIOD_DEACTIVATED',
  OPT_OUT = 'OPT_OUT',
  DUPLICATE = 'DUPLICATE',
  INVALID_LICENSE = 'INVALID_LICENSE',
  NAME_MISMATCH = 'NAME_MISMATCH',
  REVIEW_NEEDED = 'REVIEW_NEEDED',
  EXISTING_PERSON = 'EXISTING_PERSON',
  INVALID_CONTACT = 'INVALID_CONTACT',
  MISSING_INFO = 'MISSING_INFO',
  ELIGIBLE = 'ELIGIBLE',
}

export type EnumEnrollmentStatus =
  | EnumEnrollmentActiveStatus
  | EnumEnrollmentInactiveStatus

export interface IEnrollment extends IBasicEntity {
  //associatedBenefitContract: IEnrollmentABC | IEnrollmentABC[]
  associatedBenefitContract: any //TODO remove any and refactor
  id: string
  status: EnumEnrollmentStatus
  isVerified: boolean
  isActive: boolean
  statusReason: string | null
  deactivationDate: string | null
  enrollmentStatusLastModifiedDate: string
  enrollmentStatusLastModifiedBy: IUser | null
  previousStatus: EnumEnrollmentStatus | null
  startDate: string
  expirationDate: string
  createdBy: string | null
  updatedBy: string | null
  attachments: string[]
  allowedTransitionsComputed: EnumEnrollmentStatus[]
  verifiedBy: IUser | null
  verificationSource: string | null
  hasAgreedWithPolicies: boolean
}
