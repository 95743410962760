import { Communications, Portal } from 'src/common/components'
import { EmailSenderAndManagerEmailTemplateContextProvider } from 'src/common/context'
import { getIsVerifiedPerson } from 'src/common/helpers/person/person'
import { IPerson } from 'src/common/interfaces'
import { BasicTemplate } from 'src/common/templates'
import { IActionItem, IEligibility } from 'src/default/interfaces'
import { useAppSelector } from 'src/v2/store/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../v2/commons/HOC/policyCheckHOC'
import ActionItems from './ActionItems'
import Eligibilities from './Eligibilities'
import Opportunities from './Opportunities'
import styles from './single-person-template.module.scss'

interface SinglePersonTemplateProps {
  isReady?: boolean
  isEligibilitiesLoading?: boolean
  isEligibilitiesSuccess?: boolean
  eligibilities?: IEligibility[]
  personData?: IPerson
}

const SinglePersonTemplate = (
  props: React.PropsWithChildren<SinglePersonTemplateProps>,
) => {
  const {
    isReady,
    isEligibilitiesLoading,
    isEligibilitiesSuccess,
    eligibilities,
    personData,
    children,
  } = props

  const items = [] as IActionItem[]

  const userProfile = useAppSelector((state) => state.user.userProfile)
  const currentOrganizationId = userProfile.organization.id || ''
  const isVerifiedPerson = getIsVerifiedPerson(
    personData?.enrollments,
    currentOrganizationId,
  )

  return (
    <BasicTemplate>
      <EmailSenderAndManagerEmailTemplateContextProvider>
        <div className={styles.wrapper}>
          <Portal.Target id="actionItems" media="mobile" />
          {children}

          <div className={styles.rightBlock}>
            {/* mb remove this portal */}
            <Portal.Target id="actionItems" media="desktop" />

            {isVerifiedPerson && <Opportunities />}

            <Communications />

            <Portal.Target id="deactivationCases" />

            {isVerifiedPerson && (
              <Eligibilities
                isLoading={isEligibilitiesLoading}
                isSuccess={isEligibilitiesSuccess}
                eligibilities={eligibilities || []}
              />
            )}

            <Portal.Target id="metroPlus" />
          </div>
        </div>

        {isReady ? (
          <PolicyCheckHOC
            policyName="is_enable_person_actions"
            disablePolicyCheck
          >
            <Portal.Wrapper id="actionItems" width={992}>
              <ActionItems items={items} />
            </Portal.Wrapper>
          </PolicyCheckHOC>
        ) : null}
      </EmailSenderAndManagerEmailTemplateContextProvider>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SinglePersonTemplate,
  'is_enable_single_person_template',
  true,
)
