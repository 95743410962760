const Theme = () => {
  return (
    <style>
      {`
            :root {
                --black-color: #000000;
                --white-color: #FFFFFF;
                --blue-50-color: #0f4033;
                --gray-20-color: #b4b9c4;

                --primary-40-color: #0D352B;
                --primary-30-color: #0F4033;
                --primary-20-color: #376055;
                --primary-10-color: #5F8077;
                --primary-00-color: #87A099;

                --secondary-40-color: #C25F3F;
                --secondary-30-color: #E9724C;
                --secondary-20-color: #ED8A6A;
                --secondary-10-color: #F0A188;
                --secondary-00-color: #F4B9A6;

                --red-50-color: #E53E3E;

                --neutral-40-color: #444955;
                --neutral-30-color: #666666;
                --neutral-20-color: #8A909E;
                --neutral-10-color: #C3CBDD;
                --neutral-00-color: #FCFCFF;

                --background-primary-color: #F2F3F3;
                --background-secondary-color: #D6F5ED;

                --status-blue-background-color: rgba(212, 219, 255, 0.7);
                --status-red-background-color: #ebb9b9;
                --status-gray-color: #C3CBDD;
                --status-orange-color: #F7B055;
                --status-red-color: #E34A30;
                --status-green-color: #1DC39A;
                --status-blue-color: #63b9ff;

                --neutral-10-color-opacity: rgba(195, 203, 221, 0.3);
                --primary-00-color-opacity: rgba(135, 160, 153, 0.3);
                --secondary-00-color-opacity: rgba(244, 185, 166, 0.3);
                --red-00-color-opacity: rgba(235, 185, 185, 0.3);
            }

            body {
              color: var(--neutral-40-color);
            }
        `}
    </style>
  )
}

export default Theme
