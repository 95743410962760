import { FormikContextType } from 'formik'

import { Form } from 'src/common/components'

import styles from './table-filter-form.module.scss'

interface TableFilterFormProps {
  providerValue: FormikContextType<any>
}

const TableFilterForm = (
  props: React.PropsWithChildren<TableFilterFormProps>,
) => {
  const { providerValue, children } = props

  return (
    <Form className={styles.wrapper} providerValue={providerValue}>
      {children}

      <div
        className={styles.clearButton}
        onClick={() => providerValue.resetForm()}
      >
        Clear All
      </div>
    </Form>
  )
}

export default TableFilterForm
