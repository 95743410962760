import { useFormikContext } from 'formik'

import { Button } from 'src/common/components'
//TODO something with the ButtonProps file path
import ButtonProps from 'src/common/components/form/Button/BasicButton/ButtonProps'

const SubmitButton = (props: React.PropsWithChildren<ButtonProps>) => {
  const { submitForm } = useFormikContext()

  return <Button type="submit" onClick={submitForm} {...props} />
}

export default SubmitButton
