import { getEmailTemplate } from '../useCase/getEmailTemplate.usecase'

const getEmailTemplateContainer = () => {
  const {
    setIsDeleteEmailTemplate,
    setIsEditEmailTemplate,
    isDeleteEmailTemplate,
    isEditEmailTemplate,
    isLoading,
    initialEmailTemplate,
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    handleViewEmailTemplates,
    handleDeleteEmailTemplate,
  } = getEmailTemplate()

  return {
    setIsDeleteEmailTemplate,
    setIsEditEmailTemplate,
    isDeleteEmailTemplate,
    isEditEmailTemplate,
    isLoading,
    initialEmailTemplate,
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    handleViewEmailTemplates,
    handleDeleteEmailTemplate,
  }
}

export default getEmailTemplateContainer
