import { useFormik } from 'formik'

import { Checkbox, Form, Input, Modal, Textarea } from 'src/common/components'
import { TIMEZONE } from 'src/common/config'
import { IModal } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { IFormSubmission } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './form-submissions-modal.module.scss'

type FormSubmissionWithPayloadType = IFormSubmission & {
  payload: any
}

interface IFormSubmissionsModal extends IModal {
  form: FormSubmissionWithPayloadType | null
}

const FormSubmissionsModal = (formSubmission: IFormSubmissionsModal) => {
  const { onClose, form } = formSubmission

  const formik = useFormik({
    initialValues: {
      formName: form?.template?.name,
      createdAt: form?.createdAt
        ? dayjs(form.createdAt).tz(TIMEZONE).format('MM/DD/YYYY HH:mm A')
        : '',
      source: form?.payload?.source,
      browserDevice: form?.payload?.browserDevice,
      browserLanguage: form?.payload?.browserLanguage,
      reviewed: form?.isReviewed,
      completed: form?.isCompleted,
      message:
        form?.payload?.yourMessage ??
        form?.payload?.message ??
        form?.payload?.Message,
    },
    onSubmit: () => {},
  })

  delete form?.payload?.yourMessage
  delete form?.payload?.message
  delete form?.payload?.Message
  delete form?.payload?.source
  delete form?.payload?.browserDevice
  delete form?.payload?.browserLanguage
  delete form?.payload?.socialSecurityNumber

  return (
    <Modal id="singleAccountDocumentModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>Form Submission Information</div>
        <div className={styles.subtitle}>Form Details</div>
        <div className={styles.inner}>
          <div className={styles.block}>
            <Input name="formName" label="Form Name" disabled />
            <Input name="createdAt" label="Created Date" disabled />
          </div>
          <div className={styles.block}>
            <Input name="source" label="Source" disabled />
            <Input name="browserDevice" label="Browser Device" disabled />
          </div>
          <div className={styles.block}>
            <Input name="browserLanguage" label="Browser Language" disabled />
            <div className={styles.checkboxContainer}>
              <Checkbox topLabel name="reviewed" label="Reviewed" disabled />
              <Checkbox topLabel name="completed" label="Completed" disabled />
            </div>
          </div>
        </div>
        <div className={styles.subtitle}>Submission Details</div>
        <div className={styles.wrapperInputs}>
          {Object.keys(form?.payload)?.map((u: any, index: number) => (
            <Input
              key={index}
              disabled={true}
              value={form?.payload[u]}
              name={u}
              label={u}
            />
          ))}
        </div>
        <Textarea name="message" label="Message" disabled />
      </Form>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  FormSubmissionsModal,
  'is_enable_single_account_form_submission_detail',
  true,
)
