import { Checkbox } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './search-result-filter.module.scss'

const SearchResultFilter = () => {
  const organizationsByUser = useAppSelector(
    (state) => state.organization.organizationsByUser,
  )

  return (
    <div className={styles.wrapper}>
      {organizationsByUser.length! > 1 && (
        <Checkbox name="globalMode" label="Search globally" />
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SearchResultFilter,
  'is_global_search_enabled',
)
