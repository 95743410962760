import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { useRouter } from '@v2/commons/utils/navigation'
import { useState } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'

import { useCreateEmailTemplateMutation } from '../models/emailTemplate.api'
import { ICreateEmailTemplate } from '../models/emailTemplate.model'

export const createEmailTemplate = () => {
  const router = useRouter()
  const initialEmailTemplate: ICreateEmailTemplate = {
    subject: '',
    type: 'PUBLIC',
    description: '',
    body: '',
  }

  const [showSuccessModal, setShowSucessModal] = useState<boolean>(false)
  const [pathToViewEmailTempalte, setPathToViewEmailTempalte] =
    useState<string>(`${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}`)

  const [createEmailTemplate] = useCreateEmailTemplateMutation()

  const emailTemplateForm = useForm<ICreateEmailTemplate>({
    mode: 'uncontrolled',
    initialValues: {
      ...initialEmailTemplate,
    },
    validateInputOnBlur: true,
    validate: {
      subject: isNotEmpty('Subject is required'),
      type: isNotEmpty('Type is required'),
      description: isNotEmpty('Description is required'),
    },
  })

  const setEmailBody = (value: string) => {
    emailTemplateForm.setFieldValue('body', value)
  }

  const onSubmit = () => {
    emailTemplateForm.onSubmit(async (values) => {
      createEmailTemplate({ ...values })
        .unwrap()
        .then((response) => {
          setPathToViewEmailTempalte(
            `${pathToViewEmailTempalte}/${response.id}`,
          )
          setShowSucessModal(true)
        })
        .catch(() => {
          notifications.show({
            title: 'Oops',
            message: 'There was an error',
            color: 'red',
          })
        })
    })()
  }

  const handleViewEmailTemplates = () => {
    router.push(`${PRIVATE_ROUTES.EMAIL_TEMPLATE_ADMINISTRATION.path}`)
  }

  return {
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    setShowSucessModal,
    showSuccessModal,
    pathToViewEmailTempalte,
    handleViewEmailTemplates,
  }
}
