import { useEffect, useState } from 'react'

import { Button } from 'src/common/components'
import { appLocalStorage } from 'src/common/libs'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { setOrganizationsByUser } from '../../../../v2/domains/organization/models/organization.store'
import { useAppDispatch } from '../../../../v2/store/hooks'
import SelectOrganizationWrapperModal from './SelectOrganizationWrapperModal'
import styles from './select-organization-wrapper.module.scss'

const SelectOrganizationWrapper = () => {
  const dispatch = useAppDispatch()

  let organizationsByUseInLocalStorage = []
  const organizationByUser = appLocalStorage.getData('organizationByUser')
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (organizationByUser) {
    organizationsByUseInLocalStorage = JSON.parse(organizationByUser)
  }

  useEffect(() => {
    dispatch(setOrganizationsByUser(organizationsByUseInLocalStorage))
  }, [])

  if (
    !organizationsByUseInLocalStorage?.length ||
    organizationsByUseInLocalStorage?.length === 1
  ) {
    return null
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Button icon="Reload" size="small" onClick={() => setIsModalOpen(true)}>
          Switch Organization
        </Button>
      </div>

      {isModalOpen ? (
        <SelectOrganizationWrapperModal onClose={() => setIsModalOpen(false)} />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SelectOrganizationWrapper,
  'is_enable_select_organization',
  true,
)
