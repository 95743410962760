import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { navigationState } from 'src/default/helpers/navigation'
import DefaultTheme from 'src/default/theme'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'
import { setCurrentTheme } from 'src/v2/domains/ui/models/ui.store'
import { useAppDispatch } from 'src/v2/store/hooks'

import styles from './auth-template.module.scss'

const AuthLayout = () => {
  const dispatch = useAppDispatch()
  navigationState.navigate = useNavigate()
  navigationState.location = useLocation()

  useEffect(() => {
    dispatch(setCurrentTheme(CurrentTheme.DEFAULT_360))
    document
      .getElementsByTagName('body')[0]
      ?.setAttribute('data-theme', CurrentTheme.DEFAULT_360)
  }, [])

  return (
    <>
      <DefaultTheme />
      <div className={styles.wrapper}>
        <div className={styles.sidebarWrapper}>
          <div className={styles.sidebarInner}>
            <Outlet />
          </div>
        </div>

        <div className={styles.image} />
      </div>
    </>
  )
}

export default AuthLayout
