import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { QUERY_IDS } from 'src/default/config'

const useRefetchById = () => {
  const queryClient = useQueryClient()

  return async (queryId: string) => {
    const queryKey = (() => {
      if (queryId === QUERY_IDS.GET_COMMUNICATIONS) {
        return [QUERY_IDS.GET_COMMUNICATIONS, null]
      }

      return [queryId]
    })()

    const result: AxiosResponse = await queryClient.fetchQuery({ queryKey })

    return result?.status === 200 ? result.data : null
  }
}

export default useRefetchById
