import { WbfPolicies } from '@workers-benefit-fund/shared-data'

import { IRunProcessItem } from '../../run-process/models/RunProcess.model'

export const STATIC_REPORTS: IRunProcessItem[] =
  1 == 1
    ? [
        {
          id: 'uuid-mock-1',
          name: 'ESD Bulk Enrollment File',
          url: '/v1/washington-state/build-report',
          type: 'static',
          policy: WbfPolicies.is_washington_state_build_report_with_ssn_enabled,
          description:
            'Generate CSV file with updates for the Washington State PFML program.',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              name: 'fromDate',
              type: 'date',
              label: 'From Date',
            },
            {
              name: 'toDate',
              type: 'date',
              label: 'To Date',
            },
          ],
        },
        {
          id: 'uuid-mock-3',
          name: 'ESD Bulk Enrollment File (Excluding SNN)',
          url: '/v1/washington-state/build-report-without-ssn',
          type: 'static',
          policy:
            WbfPolicies.is_washington_state_build_report_without_ssn_enabled,
          description:
            'This file contains the PFML submissions for Washington State (excluding SSN)',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              name: 'fromDate',
              type: 'date',
              label: 'From Date',
            },
            {
              name: 'toDate',
              type: 'date',
              label: 'To Date',
            },
          ],
        },
        {
          id: 'uuid-mock-2',
          name: 'PFML hashed report for TNCs',
          url: '/v1/washington-state/build-hashed-report',
          type: 'static',
          policy:
            WbfPolicies.is_washington_state_build_hashed_report_du_esd_enabled,
          description: 'Generate PFML hashed reports for TNCs',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              name: 'fromDate',
              type: 'date',
              label: 'From Date',
            },
            {
              name: 'toDate',
              type: 'date',
              label: 'To Date',
            },
            {
              label: 'Select the TNC source',
              name: 'tncSource',
              options: ['Uber', 'Lyft', 'HopSkipDrive', 'Via'],
              type: 'select',
              required: true,
              validate: (value: any) => {
                if (!value) {
                  return 'Please select the TNC source'
                }
                return null
              },
            },
          ],
        },
        {
          id: 'uuid-mock-4',
          name: 'ESD Response File',
          url: '/v1/washington-state/build-report-with-unique-id-and-errors',
          type: 'static',
          policy:
            WbfPolicies.is_washington_state_build_report_du_esd_without_ssn_enabled,
          description:
            'This file contains the PFML submissions with Unique ID and errors for Washington State (excluding SSN)',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              name: 'fromDate',
              type: 'date',
              label: 'From Date',
            },
            {
              name: 'toDate',
              type: 'date',
              label: 'To Date',
            },
          ],
        },
        {
          id: 'uuid-mock-5',
          name: 'AON Weekly Cleanup File v1',
          url: '/v1/aon/build-report',
          type: 'static',
          policy: 'is_aon_build_report_enabled',
          description: 'This file contains all BCF Accounts data for AON.',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [],
        },
        {
          id: 'uuid-mock-6',
          name: 'Differences Report',
          url: '/v1/washington-state/differences-report',
          type: 'static',
          policy:
            WbfPolicies.is_washington_state_build_differences_report_enabled,
          description:
            'This file compares W360 records that participate in the PFML program with their corresponding NationBuilder records.',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              name: 'fromDate',
              type: 'date',
              label: 'From Date',
            },
            {
              name: 'toDate',
              type: 'date',
              label: 'To Date',
            },
          ],
        },
        {
          id: 'uuid-mock-7',
          name: 'Users Logins Report',
          url: '/v1/user-reports/logins',
          type: 'static',
          policy: 'is_organization_users_logins_report_enabled',
          description: 'User logins information',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [],
        },
        {
          id: 'uuid-mock-7',
          name: 'Users Activity Report',
          url: '/v1/user-reports/activity',
          type: 'static',
          policy: 'is_organization_user_activity_report_enabled',
          description: 'User Activity information TODO',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            // TODO
          ],
        },
        {
          id: 'uuid-mock-8',
          name: 'ICESA file',
          url: '/v1/washington-state/build-icesa-file',
          type: 'static',
          policy: 'is_washington_state_build_icesa_file_enabled',
          description: 'Generate ICESA file',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              label: 'Select the quarter',
              name: 'quarter',
              options: ['Q1', 'Q2', 'Q3', 'Q4'],
              type: 'select',
              required: true,
              validate: (value: any) => {
                if (!value) {
                  return 'Please select the quarter'
                }
                return null
              },
            },
            {
              label: 'Select the year',
              name: 'year',
              type: 'year-picker',
              required: true,
              validate: (value: any) => {
                if (!value) {
                  return 'Please select the year'
                }
                return null
              },
            },
          ],
        },
        {
          id: 'uuid-mock-9',
          name: 'Earnings report',
          url: '/v1/washington-state/build-earnings-report',
          type: 'static',
          policy: 'is_washington_state_build_earnings_report_enabled',
          description: 'Generate Earnings Report',
          successMessage:
            "We're processing your request. You will get the report in your email.",
          parameters: [
            {
              label: 'Select the quarter',
              name: 'quarter',
              options: ['Q1', 'Q2', 'Q3', 'Q4'],
              type: 'select',
              required: true,
              validate: (value: any) => {
                if (!value) {
                  return 'Please select the quarter'
                }
                return null
              },
            },
            {
              label: 'Select the year',
              name: 'year',
              type: 'year-picker',
              required: true,
              validate: (value: any) => {
                if (!value) {
                  return 'Please select the year'
                }
                return null
              },
            },
          ],
        },
      ]
    : []
