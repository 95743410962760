import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import {
  Form,
  Input,
  Select,
  SubmitButton,
  Textarea,
} from 'src/common/components'
import { useRefetchById } from 'src/common/hooks'
import { IUserOrganization } from 'src/common/interfaces'
import { ModalResult } from 'src/default/components'
import { QUERY_IDS } from 'src/default/config'
import { CONTRACT_IDS } from 'src/default/config'
import { reasonsForVisit, statusTicket } from 'src/default/helpers'
import { useGetUsers } from 'src/default/hooks'
import {
  useGetOfficeVisitById,
  useMutationOfficeVisit,
} from 'src/default/hooks/office-visit'
import { ITicketUpdate } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import styles from './ticket-information.module.scss'

const TicketInformation = () => {
  const { ticketId } = useParams<{ ticketId: string }>()

  const {
    data: { data: officeVisit } = { data: {} },
    isSuccess,
    isLoading,
  } = useGetOfficeVisitById({
    officeVisitId: ticketId ?? '',
  })

  const updateOfficeVisit = useMutationOfficeVisit()

  const [ready, setReady] = useState<boolean>(false)
  const [disabledForm, setDisabledForm] = useState<boolean>(false)
  const refetchById = useRefetchById()

  const [showModal, setShowModal] = useState<boolean>(false)

  const usersOptions = useGetUsers()
    .data?.data?.filter(
      (user: IUserOrganization) =>
        !user.email.includes('@workersbenefitfund.com'),
    )
    .map((user: IUserOrganization) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }))

  const formik = useFormik<ITicketUpdate>({
    initialValues: {
      reasonForVisit: '',
      note: null,
      status: '',
      id: '',
      assignedToId: '',
      createdAt: '',
    },
    onSubmit: async (values: ITicketUpdate) => {
      const sendTicketUpdate = { ...values }
      const userSelected = usersOptions.find(
        (user: any) => user.value === values.assignedToId,
      )

      updateOfficeVisit
        .mutateAsync({
          reasonForVisit: sendTicketUpdate.reasonForVisit,
          note: sendTicketUpdate.note || null,
          status: sendTicketUpdate.status,
          assignedToId: userSelected?.value || undefined,
          id: sendTicketUpdate.id,
        })
        .then(() => {
          setShowModal(true)
          refetchById(QUERY_IDS.GET_COMMUNICATIONS)
        })
        .catch((error) => console.log(error))
    },
    validationSchema: yup.object().shape({
      assignedToId: yup.string().when('status', {
        is: 'In Progress',
        then: yup.string().required('Required'),
      }),
    }),
  })

  useEffect(() => {
    if (ticketId && isSuccess) {
      formik.setValues({
        id: officeVisit.id,
        location: officeVisit.location,
        createdAt: new Date(officeVisit.createdAt).toLocaleDateString(),
        reasonForVisit: officeVisit.reasonForVisit,
        assignedToId:
          officeVisit.status === 'Open' ? '' : officeVisit.assignUserId,
        status: officeVisit.status,
        note: officeVisit.note,
      })
      setDisabledForm(officeVisit.status?.toUpperCase() === 'CLOSED')
      setReady(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (formik.values.status === 'Open') {
      formik.setFieldValue('assignedToId', '')
    }
  }, [formik.values.status])

  useEffect(() => {
    if (
      formik.values.assignedToId !== '' &&
      formik.values.assignedToId !== officeVisit?.assignUserId
    ) {
      formik.setFieldValue('status', 'In Progress')
    }
  }, [formik.values.assignedToId, officeVisit?.assignUserId])

  const groups = Object.entries(CONTRACT_IDS)
    .filter(([label]) => label !== 'IDG New York')
    .map(([label, code]) => ({
      label,
      id: code,
    }))

  const handleChange = async (newValue: string) => {
    await formik.setFieldValue('assignedToId', newValue)
  }

  return (
    <>
      <Form className="" providerValue={formik}>
        <div className={styles.blockTitle}>Ticket Information</div>
        {ready && (
          <div className={styles.wrapper}>
            <Input disabled name="location" label="Location" tabletWidth="50" />

            <Input
              disabled
              name="createdAt"
              label="Created Date"
              tabletWidth="50"
            />

            <Select
              disabled={disabledForm}
              label="Reason For Visit"
              tabletWidth="50"
              className={styles.select}
              name="reasonForVisit"
              options={reasonsForVisit}
            />

            <Select
              name="assignedToId"
              label="Assigned To"
              options={usersOptions}
              placeholder="Select IDG Steward"
              onChange={handleChange}
              tabletWidth="50"
            />

            <Select
              disabled={disabledForm}
              label="Status"
              tabletWidth="50"
              className={styles.select}
              name="status"
              options={statusTicket}
            />

            <Textarea disabled={disabledForm} label="Notes" name="note" />

            <PolicyCheckHOC policyName="is_tickets_enabled">
              <div className={styles.saveWrapper}>
                <SubmitButton
                  className={styles.saveButton}
                  iconAfter="ArrowSelect"
                  loading={isLoading}
                >
                  Save
                </SubmitButton>
              </div>
            </PolicyCheckHOC>
          </div>
        )}
      </Form>
      {showModal && (
        <ModalResult
          isSuccess={true}
          title="Updated Ticket"
          description="Record updated successfully"
          onClose={() => setShowModal(!showModal)}
        />
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TicketInformation,
  'is_enable_ticket_information',
  true,
)
