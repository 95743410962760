import {
  UseFormReturnType,
  hasLength,
  isEmail,
  isNotEmpty,
  useForm,
} from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useEffect, useState } from 'react'

import { getErrorMessages } from 'src/v2/commons/utils/getError'
import { useAppDispatch, useAppSelector } from 'src/v2/store/hooks'

import { useUpdateUserMutation } from '../models/user.api'
import { CreateUser } from '../models/user.model'
import { resetUser, setUser } from '../models/user.store'

interface UseUserInformationUseCase {
  onSubmit: () => void
  editForm: boolean
  setEditForm: (state: boolean) => void
  handleCancelForm: () => void
  userInformationForm: UseFormReturnType<CreateUser>
  errorModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  loading: boolean
}

const useUserInformationUseCase = (): UseUserInformationUseCase => {
  const [opened, { open, close }] = useDisclosure(false)
  const dispatch = useAppDispatch()
  const [editForm, setEditForm] = useState<boolean>(false)
  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const user = useAppSelector((state) => state.user.user)

  const userInformationForm = useForm<CreateUser>({
    mode: 'uncontrolled',
    initialValues: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      aircallUserId: '',
      hipaaCertified: false,
      password: '',
      organizationId: '',
    },
    validateInputOnBlur: true,
    validate: {
      firstName: isNotEmpty('First Name is required!'),
      lastName: isNotEmpty('Last Name is required!'),
      email: isEmail('Invalid Email'),
      password: hasLength({ min: 8 }, 'At least 8 characters!'),
      phone: hasLength({ min: 10 }, 'At least 10 characters!'),
    },
  })

  useEffect(() => {
    userInformationForm.setValues({
      id: user.id ?? '',
      email: user.email ?? '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      phone: user.phone ?? '',
      aircallUserId: user.aircallUserId ?? '',
      hipaaCertified: user.hipaaCertified ?? false,
      password: '',
    })
  }, [user])

  useEffect(() => {
    return () => {
      dispatch(resetUser())
    }
  }, [])

  const onSubmit = () => {
    userInformationForm.onSubmit(async (values) => {
      updateUser(values)
        .unwrap()
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'User succesfuly changed',
          })
          setEditForm(false)
        })
        .catch((error) => {
          userInformationForm.setErrors({
            error: getErrorMessages(error?.data?.message ?? ''),
          })
          open()
        })
    })()
  }

  const handleCancelForm = () => {
    dispatch(setUser({ ...user }))
    setEditForm(false)
  }

  return {
    onSubmit,
    editForm,
    setEditForm,
    handleCancelForm,
    userInformationForm,
    errorModal: {
      opened,
      open,
      close,
    },
    loading: isLoading,
  }
}

export default useUserInformationUseCase
