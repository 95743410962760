import { createContext, useContext, useReducer } from 'react'

import INITIAL_STATE from './initialState'

const EmailSenderAndManagerEmailTemplateContext = createContext({
  ...INITIAL_STATE,
})
const EmailSenderAndManagerEmailTemplateDispatchContext = createContext({})

const reducer = (
  state: typeof INITIAL_STATE,
  action: { type: string; payload: any },
) => {
  if (action?.type) {
    const replacedKey = action.type.replace('set', '')
    const key = replacedKey.charAt(0).toLowerCase() + replacedKey.slice(1)

    if (key in INITIAL_STATE) {
      return { ...state, [key as keyof typeof INITIAL_STATE]: action.payload }
    }
  }

  return state
}

export const EmailSenderAndManagerEmailTemplateContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE })

  const basicActions = Object.keys(INITIAL_STATE).reduce((acc, key: string) => {
    const actionKey = `set${key.charAt(0).toUpperCase() + key.slice(1)}`
    acc[actionKey] = (value: typeof key) =>
      dispatch({ type: actionKey, payload: value })
    return acc
  }, {} as { [key: string]: (value: any) => void })

  const dispatchProviderValue = {
    ...basicActions,
  }

  return (
    <EmailSenderAndManagerEmailTemplateContext.Provider value={state}>
      <EmailSenderAndManagerEmailTemplateDispatchContext.Provider
        value={dispatchProviderValue}
      >
        {children}
      </EmailSenderAndManagerEmailTemplateDispatchContext.Provider>
    </EmailSenderAndManagerEmailTemplateContext.Provider>
  )
}

export const useEmailSenderAndManagerEmailTemplateContext = () => {
  const context = useContext(EmailSenderAndManagerEmailTemplateContext)
  const dispatch = useContext(EmailSenderAndManagerEmailTemplateDispatchContext)

  return {
    ...(context || {}),
    ...(dispatch || {}),
  } as typeof INITIAL_STATE & {
    [key: string]: (value: any, otherValue?: any, otherOtherValue?: any) => void
  }
}
