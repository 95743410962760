import { BasicBlock } from 'src/common/components'
import { IEligibility } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { EligibilitiesList } from './components'
import styles from './eligibilities.module.scss'

interface EligibilitiesProps {
  isLoading?: boolean
  isSuccess?: boolean
  eligibilities: IEligibility[]
}

const Eligibilities = (props: EligibilitiesProps) => {
  const { isLoading, isSuccess, eligibilities } = props

  return (
    <BasicBlock
      className={styles.wrapper}
      title="Eligibilities"
      paddings="small"
      isLoading={isLoading}
      isShowWrapper={false}
    >
      {isSuccess && <EligibilitiesList eligibilities={eligibilities} />}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Eligibilities,
  'is_get_person_eligibilities_enabled',
)
