export const APP_IS_TEMPORARILY_UNAVAILABLE = false

export const PAGINATION_LIMIT = 25

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
  { label: 'Other', value: 'Non-Binary' },
]

export const BENESTREAM_ORG_ID = '85b6f94e-4bb2-47fb-b0e2-dbbb823a59f4'
export const DRIVERS_UNION_ID = '1f0636ef-588f-4dbd-ab55-114750af7dc2'
