import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'

export interface HtmlModeOptions {
  HTMLAttributes?: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    htmlMode: {
      toggleHtmlMode: () => ReturnType
    }
  }
}

export const HtmlMode = Extension.create<HtmlModeOptions>({
  name: 'htmlMode',

  addStorage() {
    return {
      isHtmlMode: false,
    }
  },

  onCreate() {
    this.storage.isHtmlMode = false
  },

  addCommands() {
    return {
      toggleHtmlMode: () => () => {
        this.storage.isHtmlMode = !this.storage.isHtmlMode
        return true
      },
    }
  },

  addProseMirrorPlugins() {
    const extension = this
    return [
      new Plugin({
        key: new PluginKey('htmlMode'),
        props: {
          handlePaste: (view, event) => {
            if (!extension.storage.isHtmlMode) {
              return false
            }

            event.preventDefault()
            const text = event.clipboardData?.getData('text/plain') ?? ''
            view.dispatch(view.state.tr.insertText(text))
            return true
          },
        },
      }),
    ]
  },
})
