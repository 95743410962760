import { Button, Flex, Group, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form/lib/types'
import DatePickerFilter from '@v2/commons/components/DatePickerFilter/DatePickerFilter.component'
import ButtonFilter from '@v2/commons/components/FilterButton/FilterButton.component'
import SelectFilter from '@v2/commons/components/FilterSelect/FilterSelect.component'
import { useEffect, useState } from 'react'

import PolicyCheckHOC from 'src/v2/commons/HOC/policyCheckHOC'

import {
  PAST_STATUS_TICKETS,
  REASONS_FOR_VISIT,
  type TicketFiltersModel,
} from '../../models/ticket.model'
import classes from './ticket-filters.module.scss'

interface TicketTableFilterProps {
  TicketFiltersForm: UseFormReturnType<TicketFiltersModel>
  //   handleDateRangeChange: (value: DatesRangeValue) => void
  clearAllFilters: () => void
  totalRecords: number
  assignedToUsers: { value: string; label: string }[]
}

const TicketTableFilter = ({
  TicketFiltersForm,
  //   handleDateRangeChange,
  clearAllFilters,
  totalRecords,
  assignedToUsers,
}: TicketTableFilterProps) => {
  const [key, setFormKey] = useState(1)
  const [openFilters, setOpenFilters] = useState(false)

  const handleClearFilters = () => {
    setFormKey((prev) => ++prev)
    clearAllFilters()
  }

  const handleChangeValue = (
    path: keyof TicketFiltersModel,
    value: TicketFiltersModel[keyof TicketFiltersModel],
  ) => {
    TicketFiltersForm.setFieldValue(path, value)
  }

  const toggleOpenFilters = () => {
    setOpenFilters((prev) => !prev)
  }

  useEffect(() => {
    const isPersistedFilters = Object.keys(TicketFiltersForm.getValues()).length
    if (isPersistedFilters) {
      setOpenFilters(true)
    }
  }, [])

  return (
    <>
      <Flex justify="space-between" align="center" mb="30px">
        <ButtonFilter onClick={toggleOpenFilters} isOpen={openFilters} />
        <Text lh="26px" size="18px">
          Total Records: {totalRecords}
        </Text>
      </Flex>
      {openFilters && (
        <form key={key} style={{ marginBottom: '30px' }}>
          <Group
            preventGrowOverflow={false}
            grow={true}
            classNames={{ root: classes['group-root'] }}
          >
            <SelectFilter
              options={PAST_STATUS_TICKETS}
              placeholder="Status"
              {...TicketFiltersForm.getInputProps('status')}
              key={TicketFiltersForm.key('status')}
            />
            <SelectFilter
              options={assignedToUsers}
              placeholder="Assigned To"
              {...TicketFiltersForm.getInputProps('assignedTo')}
              key={TicketFiltersForm.key('assignedTo')}
            />
            <SelectFilter
              options={REASONS_FOR_VISIT}
              placeholder="Reason For Visit"
              {...TicketFiltersForm.getInputProps('reasonForVisit')}
              key={TicketFiltersForm.key('reasonForVisit')}
            />
            <DatePickerFilter
              key={TicketFiltersForm.key('createdAt')}
              {...TicketFiltersForm.getInputProps('createdAt')}
            />
            <Flex miw="50%" justify="flex-end">
              <Button
                variant="transparent"
                classNames={{ root: classes['clear-button-root'] }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear All
              </Button>
            </Flex>
          </Group>
        </form>
      )}
    </>
  )
}

export default TicketTableFilter
