import { Location, NavigateFunction } from 'react-router-dom'

import { redirectRoute } from 'src/v2/store/actions/common.actions'
import { useAppDispatch, useAppSelector } from 'src/v2/store/hooks'

import { cleanStringToBeUrlRedeable } from '..'

const useRouter = () => {
  const dispatch = useAppDispatch()
  const organizationName = useAppSelector(
    (state) => state.user.userProfile.organization.name,
  )

  const router = {
    push: (path: string, includeOrganizationId: boolean = true) => {
      let normalizedPath = path.startsWith('/') ? path : `/${path}`
      if (normalizedPath.startsWith('//')) {
        normalizedPath = normalizedPath.replace('//', '/')
      }
      const fullPath = includeOrganizationId
        ? `/${cleanStringToBeUrlRedeable(organizationName)}${normalizedPath}`
        : normalizedPath
      dispatch(redirectRoute({ path: fullPath }))
    },
  }

  return {
    ...router,
  }
}

const navigationState: {
  navigate?: NavigateFunction
  location?: Location
} = {
  navigate: undefined,
  location: undefined,
}

export { useRouter, navigationState }
