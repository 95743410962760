import cx from 'classnames'

import { Icons } from 'src/common/components'

import { useAppSelector } from '../../../../../../v2/store/hooks'
import styles from './table-filter-button.module.scss'

interface TableFilterButtonProps {
  isOpen: boolean
  onToggle: () => void
}

const TableFilterButton = (props: TableFilterButtonProps) => {
  const { isOpen, onToggle } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div
      className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}
      onClick={onToggle}
    >
      <Icons.Filter />

      <div className={styles.label}>Filter</div>

      <div className={cx(styles.arrowWrapper, isOpen && styles.open)}>
        <Icons.ArrowSelect />
      </div>
    </div>
  )
}

export default TableFilterButton
