import {
  Affix,
  Badge,
  Button,
  Divider,
  Flex,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from 'src/v2/commons/HOC/policyCheckHOC'
import { useRouter } from 'src/v2/commons/utils/navigation'

import { type PersonModel } from '../../person/models/person.model'
import styles from './assign-tasks-card.module.scss'

interface FloatingTasksCardProps {
  selectedItems: PersonModel['id'][]
  openAssignTaskModal: () => void
  isMergingToolActive?: boolean
  onDeselect: () => void
  location: 'account' | 'search' | 'lead'
  isAssignMultipleTasksEnabled: boolean
}

const MERGE_TOOL_PATH = '/merging-tool'

const FloatingTasksCard = ({
  selectedItems,
  openAssignTaskModal,
  onDeselect,
  isMergingToolActive = false,
  location,
  isAssignMultipleTasksEnabled,
}: FloatingTasksCardProps) => {
  const router = useRouter()

  const onMerge = () => {
    router.push(`${MERGE_TOOL_PATH}?location=${location}`)
  }

  return (
    <Affix
      position={{
        bottom: 30,
        right: 60,
        left: 150,
      }}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Paper className={styles['paper']}>
        <Flex gap={20} align="center">
          <Flex
            c="var(--mantine-color-blue-6)"
            justify="space-between"
            gap={10}
            align="center"
          >
            <Badge
              color="var(--status-blue-background-color)"
              size="lg"
              circle
              miw={'var(--badge-height'}
              w="auto"
              styles={{ label: { padding: '2px' } }}
            >
              <Text fw="600" c="var(--mantine-color-blue-6)">
                {selectedItems.length}
              </Text>
            </Badge>
            <Text lh="26px" fz="14px" fw="500">
              Selected
            </Text>
          </Flex>
          <PolicyCheckHOC
            policyName={[
              'is_create_task_enabled',
              'is_create_multiple_task_enabled',
            ]}
          >
            <>
              <Divider orientation="vertical" />
              <Tooltip
                label="can only assign task to one account at a time"
                disabled={
                  isAssignMultipleTasksEnabled && !(selectedItems.length > 1)
                }
              >
                <Button
                  leftSection={<Icons.PageCheck />}
                  variant="default"
                  disabled={
                    !isAssignMultipleTasksEnabled && selectedItems.length > 1
                  }
                  className={styles['button-root']}
                  onClick={
                    selectedItems.length ? openAssignTaskModal : () => {}
                  }
                >
                  Assign Tasks
                </Button>
              </Tooltip>
            </>
          </PolicyCheckHOC>

          {isMergingToolActive && selectedItems.length > 1 && (
            <>
              <PolicyCheckHOC policyName="is_merge_persons_enabled">
                <>
                  <Divider orientation="vertical" />
                  <Button
                    leftSection={<Icons.Merge />}
                    variant="default"
                    className={styles['button-root']}
                    onClick={onMerge}
                  >
                    Merge Duplicates
                  </Button>
                </>
              </PolicyCheckHOC>
              <Divider orientation="vertical" />
            </>
          )}
          <Button
            leftSection={<Icons.Deselect />}
            variant="default"
            className={styles['button-root']}
            onClick={onDeselect}
          >
            Clear Selection
          </Button>
        </Flex>
      </Paper>
    </Affix>
  )
}

export default withErrorBoundaryAndPolicyCheck(FloatingTasksCard, [
  'is_create_task_enabled',
  'is_create_multiple_task_enabled',
  'is_merge_persons_enabled',
])
