import useGetUsersNoPaginattionUseCase from '@v2/domains/user/useCase/useGetUsersNoPagination.useCase'

import { ITicketsContainerSchema } from '../models/ticket.model'
import useTicketsUseCase from '../useCases/useTickets.usecase'

const useTicketsContainer = (): ITicketsContainerSchema => {
  const assignedToUsers = (
    useGetUsersNoPaginattionUseCase().users?.records ?? []
  )
    .filter((user) => !user.email.includes('@workersbenefitfund.com'))
    .map((user) => ({
      value: user.id,
      label: user.name,
    }))

  const {
    openTickets,
    pastTickets,
    queryStatus: {
      isFetchingOpenTickets,
      isLoadingOpenTickets,
      isSuccessOpenTickets,
    },
    handlePaginateOpenTickets,
    handlePaginatePastTickets,
    handleAssignTicketToUser,
    updateTicketMutationStatus,
    TicketFiltersForm,
  } = useTicketsUseCase()

  return {
    assignedToUsers,
    openTickets,
    pastTickets,
    isLoadingOpenTickets,
    isFetchingOpenTickets,
    isSuccessOpenTickets,
    handlePaginateOpenTickets,
    handlePaginatePastTickets,
    handleAssignTicketToUser,
    updateTicketMutationStatus,
    TicketFiltersForm,
  }
}

export default useTicketsContainer
