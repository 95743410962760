import { Box, Checkbox, Container, Flex, Table, Text } from '@mantine/core'

import { ActionMenu, Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { MembershipEmailDomainUser } from '../../models/user.model'
import styles from './membership-information.module.scss'

interface EmailDomainInformation {
  emailDomains: MembershipEmailDomainUser[]
}

const EmailDomainInformation = ({ emailDomains }: EmailDomainInformation) => {
  return (
    <Box my="md">
      <Flex
        justify="space-between"
        align="flex-start"
        className={styles.headerWrapper}
      >
        <Text fw={700} c="blue.5">
          Email Domail
        </Text>
      </Flex>

      <Flex justify="center">
        <Container mx="md" className={styles.flex1} my="xl">
          <Table withTableBorder highlightOnHover withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Email/Domain</Table.Th>
                <Table.Th>Name/Title</Table.Th>
                <Table.Th>Direction</Table.Th>
                <Table.Th>Is Registered</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {emailDomains.map((emailDomanin) => (
                <Table.Tr>
                  <Table.Td>{emailDomanin.domain}</Table.Td>
                  <Table.Td>{emailDomanin.name}</Table.Td>
                  <Table.Td>{emailDomanin.type}</Table.Td>
                  <Table.Td>
                    <Checkbox checked={emailDomanin.isWBFRegister} readOnly />
                  </Table.Td>
                  <Table.Td>
                    <ActionMenu
                      items={[
                        {
                          label: 'Update',
                          color: 'blue',
                          icon: <Icons.Edit />,
                          onClick: () => null,
                          enable: true,
                        },
                        {
                          label: 'Delete',
                          color: 'red',
                          icon: <Icons.Trash />,
                          onClick: () => null,
                          enable: true,
                        },
                      ]}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Container>
      </Flex>
    </Box>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EmailDomainInformation,
  'is_enable_leads_table',
  true,
)
