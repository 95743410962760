import {
  Button,
  Container,
  Image,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'

import logoImg from 'src/assets/lock.png'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import ForgotViewWrapper from '../components/ForgotViewWrapper'
import useForgotFormContainer from '../containers/useForgotFormContainer'

interface ForgotViewProps {
  onSent: () => void
}

export const ForgotView = (props: ForgotViewProps) => {
  const { getFormProps, forgotMutationStatus, onSubmit } =
    useForgotFormContainer()

  return (
    <Container>
      <Stack align="center" mb={21}>
        <Container bg="darkBlue.0" mb={10} style={{ borderRadius: '50%' }}>
          <Image src={logoImg} alt="Logo" w={75} my={14} mx={10} />
        </Container>
        <Title order={1}>Forgot password?</Title>
        <Text ta="center">
          Please enter the email address associated with your account{' '}
        </Text>
      </Stack>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(props.onSent)
        }}
      >
        <TextInput
          id="email"
          label="Email"
          placeholder="Enter Email Address"
          size="lg"
          mb={29}
          {...getFormProps('email')}
        />
        <Button
          type="submit"
          size="lg"
          loading={forgotMutationStatus.isLoading}
          fullWidth
        >
          Submit
        </Button>
      </form>
    </Container>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ForgotViewWrapper,
  'is_enable_forgot_page',
  true,
)
