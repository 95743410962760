import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

interface GetWashingtonStateHistoryProps {
  objectId: string
  limit?: number
}

const useGetWashingtonStateHistory = (
  params: GetWashingtonStateHistoryProps,
) => {
  const { objectId, limit } = params

  return useInfiniteQuery({
    queryKey: [`useGetWashingtonStateHistory`],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v1/washington-state/history/${objectId}`, {
        signal,
        params: {
          limit,
          offset: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length,
  })
}

export default useGetWashingtonStateHistory
