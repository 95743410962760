import cx from 'classnames'

import { Checkbox } from 'src/common/components'
import { IOptionComponent } from 'src/default/interfaces'

import styles from './multi-select-option.module.scss'

const MultiSelectOption = (props: IOptionComponent) => {
  const { fieldValue, label, value, meta, onChange, isDisabledOptions } = props

  const isActive = fieldValue.includes(value)

  const handleOnChange = () => {
    if (!isDisabledOptions) {
      onChange(value)
    }
  }
  return (
    <div
      className={cx(styles.wrapper, isDisabledOptions && styles.noPointer)}
      onClick={handleOnChange}
    >
      {!isDisabledOptions ? (
        <Checkbox
          className={styles.checkbox}
          name={`${label}-${value}`}
          value={isActive}
          onChange={() => onChange(value)}
        />
      ) : null}
      {meta || label}
    </div>
  )
}

export default MultiSelectOption
