import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { Table } from 'src/common/components'
import { formatDate, getPhoneWithMask } from 'src/common/helpers'

import styles from './accounts-page-table.module.scss'

interface AccountsPageTableProps {
  accounts: IBenestreamPerson[]
}

const AccountsPageTable = (props: AccountsPageTableProps) => {
  const { accounts } = props
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <Table className={styles.wrapper}>
      {accounts.map((a: IBenestreamPerson) => (
        <Table.Row
          key={a.id}
          editable
          id={a.id}
          editLink={`/${organizationId}${PRIVATE_ROUTES.ACCOUNTS.path}/:id`}
        >
          <Table.Column className={styles.name} title="Name" showMobile>
            {`${a.firstName} ${a.lastName}`}
          </Table.Column>

          <Table.Column title="Email" width={175}>
            {a.email}
          </Table.Column>

          <Table.Column title="Mobile">
            {getPhoneWithMask(a.phoneNumber)}
          </Table.Column>

          <Table.Column title="Employee ID">{a.employeeId}</Table.Column>

          <Table.Column title="Company">
            {a?.enrollments &&
              a.enrollments[0].associatedBenefitContract &&
              a.enrollments[0].associatedBenefitContract.name}
          </Table.Column>

          <Table.Column title="Worksite">{a.worksite}</Table.Column>

          <Table.Column title="Source">{a.source}</Table.Column>

          <Table.Column title="Created Date">
            {formatDate(a.createdAt, 'MM/DD/YYYY')}
          </Table.Column>
        </Table.Row>
      ))}
    </Table>
  )
}

export default AccountsPageTable
