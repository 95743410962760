import { useField } from 'formik'
import { useEffect, useState } from 'react'

import { BasicInput, FieldWrapper } from 'src/common/components'
import {
  getPhoneWithMask,
  validateAndFormatPhoneNumber,
  validatePhoneFormat,
} from 'src/common/helpers'
import { IField } from 'src/common/interfaces'

const InputPhone = (props: IField) => {
  const { name, value } = props

  const [innerValue, setInnerValue] = useState('')

  const [field, , { setValue }] = useField({ name })

  useEffect(() => {
    if (field.value && !Array.isArray(field.value)) {
      const basicPhoneValueIsValid = validatePhoneFormat(field.value)

      if (!basicPhoneValueIsValid) {
        const formattedPhoneValue = validateAndFormatPhoneNumber(field.value)
        if (formattedPhoneValue) {
          setValue(formattedPhoneValue)
          setInnerValue(getPhoneWithMask(formattedPhoneValue))
        }
      } else {
        setInnerValue(getPhoneWithMask(field.value))
      }
    }

    if (typeof field.value === 'undefined') {
      setInnerValue('')
    }
  }, [field.value])

  const handleChange = (
    event: React.SyntheticEvent<HTMLInputElement, InputEvent>,
  ) => {
    const { inputType } = event.nativeEvent
    const { defaultValue, value: originalValue } = event.currentTarget
    let phoneValue = originalValue

    phoneValue = phoneValue.replace(/[^0-9.]/g, '')

    if (
      inputType === 'insertFromPaste' &&
      phoneValue.length > 10 &&
      phoneValue[0] === '1'
    ) {
      phoneValue = phoneValue.substring(1)
    }

    if (inputType !== 'insertFromPaste' && phoneValue.length > 10) {
      phoneValue = phoneValue.substring(0, phoneValue.length - 1)
    }

    if (
      originalValue.length < defaultValue.length &&
      defaultValue[defaultValue.length - 1] === '-'
    ) {
    } else {
      phoneValue = getPhoneWithMask(phoneValue)
    }

    setInnerValue(phoneValue || '')

    setValue(validateAndFormatPhoneNumber(phoneValue || ''))
  }

  const formattedPhoneValue = getPhoneWithMask(
    validateAndFormatPhoneNumber(value),
  )

  return (
    <FieldWrapper {...props}>
      <BasicInput
        {...props}
        value={innerValue || formattedPhoneValue}
        placeholder="888-888-8888"
        onChange={handleChange}
      />
    </FieldWrapper>
  )
}

export default InputPhone
