import { Button, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './requirements-view-modal.module.scss'

interface EligibilityEditorModalProps extends IModal {
  isNew: boolean
  initialValues?: any
}

const RequirementsViewModal = (props: EligibilityEditorModalProps) => {
  const { isNew, initialValues, onClose } = props

  const modalTitle = `Requirements (rule)`

  return (
    <Modal id="requirementsViewModal" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{modalTitle}</div>

        <div className={styles.inner}>
          <div>
            <pre>{JSON.stringify(initialValues, null, 2)}</pre>
          </div>

          <div className={styles.bottomButtons}>
            <Button size="small" template="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  RequirementsViewModal,
  'is_enable_associated_benefit_contracts_detail',
  true,
)
