import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'
import { QUERY_IDS } from 'src/default/config'

interface GetSmsCampaignsParams {
  limit: number
}

const useGetSmsCampaigns = (params: GetSmsCampaignsParams) => {
  const { limit } = params
  return useInfiniteQuery({
    queryKey: [`getSmsCampaigns`, QUERY_IDS.GET_SMS_CAMPAIGNS],
    queryFn: async ({ signal, pageParam = 0 }) =>
      await api.get('/sms-campaigns', {
        signal,
        params: {
          limit,
          offset: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length,
  })
}

export default useGetSmsCampaigns
