import {
  Button,
  Flex,
  Group,
  Modal,
  ModalBaseCloseButtonProps,
  ModalBaseProps,
  Stack,
  Text,
} from '@mantine/core'
import cx from 'classnames'
import { useEffect } from 'react'

import { Icons } from 'src/common/components'
import { useRouter } from 'src/v2/commons/utils/navigation'

import styles from './success-modal.module.scss'

type SuccessErrorModalProps = {
  status: 'success' | 'error'
  title: string
  subTitle?: string
  buttonText?: string
  path?: string | undefined
  closeButtonProps?: ModalBaseCloseButtonProps
  onOpen?: () => void
  triggerOpen?: boolean
  closeButtonText?: string
  closeButtonAction?: () => void
} & ModalBaseProps

const SuccessErrorModal = ({
  opened,
  onClose,
  status,
  buttonText = 'close',
  path,
  title,
  subTitle,
  triggerOpen,
  onOpen,
  closeButtonText,
  closeButtonAction,
  ...rest
}: SuccessErrorModalProps) => {
  const isSuccess = status === 'success'
  const CurrentIcon = Icons[status === 'success' ? 'Check' : 'Warning']
  const router = useRouter()

  const handleNavigate = () => {
    router.push(path ?? '')
  }

  useEffect(() => {
    if (onOpen && triggerOpen) {
      onOpen()
    }
  }, [triggerOpen])

  return (
    <Modal
      {...rest}
      trapFocus={false}
      opened={opened}
      onClose={onClose}
      centered
      title={
        <Flex className={cx(styles.icon, styles[status])}>
          <CurrentIcon />
        </Flex>
      }
      classNames={{
        content: styles['modal-content'],
        header: styles['modal-header'],
        title: styles['modal-title'],
        close: styles['modal-close'],
        body: styles['modal-body'],
      }}
    >
      <Stack justify="center" gap="16px" align="center">
        <Text
          fz="16px"
          fw="700"
          ta="center"
          c={
            isSuccess
              ? 'var(--mantine-color-gray-8)'
              : 'var(--mantine-color-red-6)'
          }
        >
          {title}
        </Text>
        <Text fz="14px" fw="400" c="var(--mantine-color-gray-8)">
          {subTitle}
        </Text>

        {path ? (
          <Group justify='center'>
            {closeButtonText && <Button
              onClick={closeButtonAction}
              variant={'default'}
            >
              {closeButtonText}
            </Button>}

            <Button
              onClick={handleNavigate}
              miw="115px"
              variant={isSuccess ? 'filled' : 'default'}
            >
              {buttonText}
            </Button>
          </Group>
        ) : (
          <Button
            onClick={onClose}
            miw="115px"
            variant={isSuccess ? 'filled' : 'default'}
            className={styles.btn}
          >
            {buttonText}
          </Button>
        )}
      </Stack>
    </Modal>
  )
}
export default SuccessErrorModal
