import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { WashingtonStateRecord } from 'src/common/interfaces/washington-state/washington-state'

interface GetWARecordParams {
  id: string
}

const useGetWashingtonStateRecord = (params: GetWARecordParams) => {
  const { id } = params

  return useQuery({
    queryKey: [`useGetWashingtonStateRecord`, id],
    queryFn: async () =>
      await api.get<WashingtonStateRecord>(`/v1/washington-state/${id}`),
    enabled: !!id,
  })
}

export default useGetWashingtonStateRecord
