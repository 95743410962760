import {
  UseFormReturnType,
  hasLength,
  isEmail,
  isNotEmpty,
  matches,
  useForm,
} from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'

import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'
import {
  getErrorMessages,
  isWeakPasswordMessage,
} from 'src/v2/commons/utils/getError'
import { useAppSelector } from 'src/v2/store/hooks'

import { useCreateUserMutation } from '../models/user.api'
import { CreateUser } from '../models/user.model'

interface UseUserInformationUseCase {
  onSubmit: () => void
  handleCancelForm: () => void
  userCreateForm: UseFormReturnType<CreateUser>
  errorModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  loading: boolean
}

const useCreateUserUseCase = (): UseUserInformationUseCase => {
  const [opened, { open, close }] = useDisclosure(false)
  const [createUser, { isLoading }] = useCreateUserMutation()
  const router = useRouter()

  const organizationId = useAppSelector(
    (state) => state.user.userProfile.organization.id,
  )

  const userCreateForm = useForm<CreateUser>({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      aircallUserId: '',
      hipaaCertified: false,
      password: '',
      organizationId: organizationId ?? '',
    },
    validateInputOnBlur: true,
    validate: {
      firstName: isNotEmpty('First Name is required!'),
      lastName: isNotEmpty('Last Name is required!'),
      email: isEmail('Invalid Email'),
      password: hasLength(
        { min: 8, max: 20 },
        'Password must be between 8-20 characters long!',
      ),
      phone: matches(
        /^\d{10,11}$/,
        'Phone must be 10 characters long and contain only digits!',
      ),
    },
  })

  const onSubmit = () => {
    userCreateForm.onSubmit(async (values) => {
      createUser(values)
        .unwrap()
        .then((response) => {
          router.push(`${PRIVATE_ROUTES.USERS.path}/${response.id}`)
        })
        .catch((error) => {
          userCreateForm.setErrors({
            error: isWeakPasswordMessage(
              getErrorMessages(error?.data?.message ?? ''),
            ),
          })
          open()
        })
    })()
  }

  const handleCancelForm = () => {
    router.push(PRIVATE_ROUTES.USERS.path)
  }

  return {
    onSubmit,
    handleCancelForm,
    userCreateForm,
    errorModal: {
      opened,
      open,
      close,
    },
    loading: isLoading,
  }
}

export default useCreateUserUseCase
