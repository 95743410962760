import Papa from 'papaparse'

export const processCsv = (
  file: File,
  callback: (file: File, data: any, headers: string[]) => void,
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header: string) => header.trim(),
    complete(results: { data: any; meta: any }) {
      callback(file, results.data, results.meta.fields)
    },
  })
}
