import cx from 'classnames'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  Form,
  Icons,
  Input,
  Spinner,
  Textarea,
} from 'src/common/components'
import ModalFormSubmissions from 'src/default/components/Modal/ModalFormSubmissions/ModalFormSubmissions'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useGetPersons, useMutateFormSubmission } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { useRouter } from '../../../../helpers/navigation'
import useMutateNotificationFormSubmission from '../../../../hooks/forms/useMutateNotificationFormSubmission'
import useMutateProcessFormSubmission from '../../../../hooks/forms/useMutateProcessFormSubmission'
import styles from './form-single-submission.module.scss'

const FormSingleSubmission = (props: any) => {
  const router = useRouter()

  const { singleFormSubmission, onClickCancel, refetch } = props
  const mutateSingleFormSubmission = useMutateFormSubmission()
  const mutateProcessFormSubmission = useMutateProcessFormSubmission()
  const mutateNotificationFormSubmission = useMutateNotificationFormSubmission()
  const [loading, setLoading] = useState<boolean>(false)

  const [sendNotification, setSendNotification] = useState<boolean>(false)

  const [canCreateALead, setCanCreateALead] = useState<boolean>(false)
  const [isRelatedToALead, setIsRelatedToALead] = useState<boolean>(false)
  const [haveLeadId, setHaveLeadId] = useState<boolean>(false)
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true)
  const [newLeadId, setNewLeadId] = useState<string>('')
  const [result, setResult] = useState<any | null>(null)
  const [descriptionModal, setDescriptionModal] = useState<any>()

  const formik = useFormik({
    initialValues: {
      isReviewed: singleFormSubmission.isReviewed,
    },
    onSubmit: () => {},
  })

  const getPayload = (u: any) => {
    return u.payload.steps.reduce((acc: any, step: any) => {
      ;(step?.fields ?? []).forEach((field: any) => {
        acc[field.label] = field.value
      })
      return acc
    }, {})
  }

  const payload = getPayload(singleFormSubmission)
  const message = payload?.yourMessage ?? payload?.message ?? payload?.Message
  delete payload?.yourMessage
  delete payload?.message
  delete payload?.Message
  delete payload?.socialSecurityNumber

  const { data: { pages } = { pages: [] } } = useGetPersons({
    email: payload?.Email || payload?.email,
  })

  const handleReview = async () => {
    setLoading(true)
    await mutateSingleFormSubmission
      .mutateAsync({
        id: singleFormSubmission.id,
        isReviewed: true,
        isCompleted: singleFormSubmission?.isCompleted,
      })
      .then(() => {
        setLoading(true)
        setResult('successReview')
        setDescriptionModal(
          <>
            The form <strong>{singleFormSubmission?.template?.name}</strong>{' '}
            from{' '}
            <strong className={styles.textCapitalize}>
              {payload.firstName} {payload.lastName}
            </strong>{' '}
            has been reviewed.
          </>,
        )
        refetch()
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleReviewAndCreateLead = async () => {
    setLoading(true)
    await mutateProcessFormSubmission
      .mutateAsync({
        id: singleFormSubmission.id,
        processingStrategy: 'CreateOrNothing',
      })
      .then(async (data) => {
        console.log(data.data)
        setNewLeadId(data.data?.id)

        await mutateSingleFormSubmission
          .mutateAsync({
            id: singleFormSubmission.id,
            isReviewed: true,
          })
          .then((response) => {
            setLoading(true)
            setResult('successReviewAndCreateLead')
            setDescriptionModal(
              'This form submission has been converted into the Lead/Account.',
            )
            refetch()
          })
          .catch((error) => {
            setLoading(false)
            setResult(basicApiErrorHandling(error))
          })
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleSendNotification = async (id: string) => {
    setLoading(true)
    await mutateNotificationFormSubmission
      .mutateAsync({
        id: id,
      })
      .then(async (data) => {
        setLoading(false)
        setSendNotification(true)
        setResult('notificationSent')
        setDescriptionModal('Notifications sent.')
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleCancel = () => {
    onClickCancel()
  }

  const handleClickReview = () => {
    setDisableSaveButton(!disableSaveButton)
  }

  const handleCloseResult = () => {
    if (result === 'successReviewAndCreateLead') {
      setLoading(false)
      router.push(
        newLeadId
          ? `${PRIVATE_ROUTES.LEADS.path}/${newLeadId}`
          : PRIVATE_ROUTES.FORM_SUBMISSIONS.path,
      )
      return
    }
    if (result === 'successReview') {
      setLoading(false)
      setResult(null)
      onClickCancel()
      return
    } else {
      setLoading(false)
      setResult(null)
      onClickCancel()
      return
    }
  }

  const handleGoToLeadRecord = () => {
    setLoading(false)
    router.push(
      newLeadId
        ? `${PRIVATE_ROUTES.LEADS.path}/${newLeadId}`
        : PRIVATE_ROUTES.FORM_SUBMISSIONS.path,
    )
  }

  useEffect(() => {
    if (!singleFormSubmission.person?.id) {
      setCanCreateALead(true)
      setIsRelatedToALead(false)
    } else {
      setCanCreateALead(false)
      setIsRelatedToALead(true)
    }
    if (singleFormSubmission.person?.id) {
      setHaveLeadId(true)
    }
  }, [singleFormSubmission.person?.id])

  return (
    <>
      <Form providerValue={formik}>
        <div className={styles.formWrapper}>
          <Input
            value={singleFormSubmission.template?.name}
            disabled={true}
            name="formName"
            label="Form Name"
          />

          <Input
            value={dayjs(singleFormSubmission.createdAt).format(
              'MM/DD/YYYY HH:mm A',
            )}
            disabled={true}
            name="createdDate"
            label="Created Date"
          />

          <Checkbox
            className={styles.checkbox}
            innerClassName={styles.marginAuto}
            topLabel={true}
            label="Reviewed"
            name="isReviewed"
            onChange={handleClickReview}
          />

          <Checkbox
            className={styles.checkbox}
            value={singleFormSubmission.isCompleted}
            innerClassName={cx(styles.marginAuto, styles.noClick)}
            topLabel={true}
            label="Completed"
            name="isCompleted"
          />
        </div>

        <div className={styles.wrapperInputs}>
          {Object.keys(payload)?.map((u: any, index: number) => (
            <Input
              key={index}
              disabled={true}
              value={payload[u]}
              name={u}
              label={u}
            />
          ))}
        </div>

        <div>
          <Textarea
            label="Message"
            disabled={true}
            value={message}
            name={'text'}
          ></Textarea>
        </div>

        {(singleFormSubmission?.template?.notifyEmails as string[])?.length >
          0 && (
          <div className={styles.haveLead}>
            <Icons.Email />
            Send form submission notification (
            {singleFormSubmission?.template?.notifyEmails?.toString()}):
            {loading ? (
              'loading...'
            ) : !sendNotification ? (
              <a
                className={styles.relatedLead}
                onClick={() => {
                  handleSendNotification(singleFormSubmission.id)
                }}
              >
                Send
              </a>
            ) : (
              <p>Sent</p>
            )}
          </div>
        )}
        {isRelatedToALead && haveLeadId && (
          <div className={styles.haveLead}>
            <Icons.UserCircle />
            This form submission is already linked to this person
            <a
              className={styles.relatedLead}
              onClick={() =>
                router.push(
                  `/${PRIVATE_ROUTES.LEADS.path}/${singleFormSubmission.person?.id}`,
                )
              }
              target="_blank"
            >
              ({singleFormSubmission.person.firstName}{' '}
              {singleFormSubmission.person.lastName})
            </a>
          </div>
        )}
        {canCreateALead && (
          <div className={styles.haveLead}>
            <Icons.WarningBlue />
            This person does not have a Lead record.{' '}
            {!loading ? (
              <PolicyCheckHOC
                policyName="is_process_form_submission_enabled"
                disablePolicyCheck
              >
                <span
                  className={styles.textLink}
                  onClick={handleReviewAndCreateLead}
                >
                  Create Lead record
                </span>
              </PolicyCheckHOC>
            ) : (
              <div className={styles.loader}>
                <Spinner color="blue" size="big" />
              </div>
            )}
          </div>
        )}

        <PolicyCheckHOC
          policyName="is_process_form_submission_enabled"
          disablePolicyCheck
        >
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={handleCancel}
              className={cx(styles.flexPointOne, styles.altButton)}
            >
              Cancel
            </Button>
            {!singleFormSubmission?.isReviewed && (
              <Button
                disabled={disableSaveButton}
                className={styles.buttonCreate}
                loading={loading}
                onClick={handleReview}
              >
                Save
              </Button>
            )}
          </div>
        </PolicyCheckHOC>
      </Form>
      {result !== null ? (
        result === 'notificationSent' ? (
          <ModalFormSubmissions
            title="Sent Successfully"
            isSuccess={true}
            onClose={handleCloseResult}
          />
        ) : (
          <ModalFormSubmissions
            title="Reviewed Successfully"
            isSuccess={
              result === 'successReview' ||
              result === 'successReviewAndCreateLead'
            }
            description={descriptionModal}
            onClose={handleCloseResult}
            showButtons={result === 'successReviewAndCreateLead'}
            textSuccessButton="Go to Lead Record"
            handleOnClickButton={handleGoToLeadRecord}
          />
        )
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  FormSingleSubmission,
  'is_enable_form_single_submission',
  true,
)
