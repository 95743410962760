import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { api } from 'src/common/api'
import { API_BASE_URL } from 'src/common/config'
import { IResetPage } from 'src/common/interfaces'

const useMutateUserPassword = (action: 'update', accessToken?: string) =>
  useMutation({
    mutationFn: async (data: IResetPage) => {
      const { id, ...restData } = data

      const url = '/v3/auth/set-new-password'

      return axios['post'](
        `${API_BASE_URL}${url}`,
        {
          password: restData.password,
        },
        {
          transformRequest: [
            (data: any, headers: any) => {
              if (accessToken) {
                headers.common['Authorization'] = `Bearer ${accessToken}`
              }

              return data
            },
          ].concat(
            api.defaults.transformRequest ? api.defaults.transformRequest : [],
          ),
        },
      )
    },
  })

export default useMutateUserPassword
