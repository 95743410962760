import cx from 'classnames'

import { isDevEnv } from 'src/common/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../../deactivation-benefit-case.module.scss'
import styles from './deactivation-benefit-case-url.module.scss'

interface DeactivationBenefitHistoryProps {
  caseId: string
}

const DeactivationBenefitCaseURL = (props: DeactivationBenefitHistoryProps) => {
  const { caseId } = props

  const url = `https://admin${
    isDevEnv() ? '.dev' : ''
  }.iamdeactivated.com/cases/${caseId}`

  return (
    <div className={cx(rootStyles.caseBlock, styles.wrapper)}>
      <div className={cx(rootStyles.title, styles.title)}>Case URL</div>
      <div className={rootStyles.inner}>
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitCaseURL,
  'is_enable_deactivation_benefit_case_url',
  true,
)
