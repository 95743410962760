import cx from 'classnames'

import { Spinner } from 'src/common/components'

import styles from './loader.module.scss'

interface LoaderProps {
  isShowWrapper?: boolean
  isLoading?: boolean
  fullScreen?: boolean
  overlay?: boolean
}

const FullScreenLoader = ({ overlay }: { overlay?: boolean }) => {
  return (
    <div className={cx(styles.fullScreen, !overlay && styles.noOverlay)}>
      <Spinner color="primary" size="big" />
    </div>
  )
}

const Loader = (props: LoaderProps) => {
  const { isLoading, isShowWrapper, fullScreen, overlay = true } = props

  if (isLoading && fullScreen) {
    return <FullScreenLoader overlay={overlay} />
  }

  if (isLoading || isShowWrapper) {
    return (
      <div className={styles.wrapper}>
        {isLoading ? <Spinner color="primary" size="bigger" /> : null}
      </div>
    )
  } else {
    return <div></div>
  }
}

export default Loader
