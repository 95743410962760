import { FormikContextType } from 'formik'

import {
  Form,
  InputPhone,
  InputZipcode,
  Select,
  Spinner,
  SubmitButton,
  Textarea,
} from 'src/common/components'
import { InputDateAndTime } from 'src/default/components'
import {
  BENEFIT_TYPE_OPTIONS,
  PREFERRED_LANGUAGES_OPTION,
  STATUS_OPTIONS,
} from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './appointment-form.module.scss'

interface IAppointmentForm {
  showForm: boolean
  showSpinner: boolean
  formik: FormikContextType<any>
}

const AppointmentForm = (props: IAppointmentForm) => {
  const { showForm, showSpinner, formik } = props

  return (
    <>
      <div className={styles.mainTitle}>Manage Appointment</div>
      {showForm && (
        <Form className={styles.formContainer} providerValue={formik}>
          <Select
            required
            label="Benefit"
            name="benefitType"
            options={BENEFIT_TYPE_OPTIONS}
            tabletWidth="100"
          />

          <Select
            required
            label="Preferred Language"
            name="preferredLanguage"
            options={PREFERRED_LANGUAGES_OPTION}
            tabletWidth="50"
          />

          <InputZipcode
            required
            name="clinicZipCode"
            label="Clinic Zip Code"
            tabletWidth="50"
          />

          <Textarea
            required
            name="clinicInformation"
            label="Clinic Information"
            tabletWidth="50"
          />

          <div style={{ width: '45%' }}>
            <InputDateAndTime
              futureDate={true}
              pastDate={false}
              required
              name="appointmentDate"
              label="Appointment Date"
              nameForDate="dateForAppointment"
            />
          </div>

          <Select
            required
            label="Status"
            name="status"
            options={STATUS_OPTIONS}
            tabletWidth="50"
          />

          <InputPhone
            className={styles.disabledInput}
            disabled
            required
            name="repPhoneNumber"
            label="Call Center Rep. Phone Number"
            tabletWidth="50"
          />

          <div className={styles.saveWrapper}>
            <SubmitButton
              className={styles.saveButton}
              iconAfter="ArrowSelect"
              size="normal"
              loading={formik.isSubmitting}
            >
              Save
            </SubmitButton>
          </div>
        </Form>
      )}
      {showSpinner && (
        <div className={styles.spinner}>
          <Spinner color="primary" size="bigger" />
        </div>
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AppointmentForm,
  'is_enable_appointment_form',
  true,
)
