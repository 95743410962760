import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'
import { useGetEmailTemplate } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import ListEmailTemplateModal from './ListEmailTemplateModal/ListEmailTemplateModal'
import NewEmailTemplateModal from './NewEmailTemplateModal/NewEmailTemplateModal'

const ManagerTemplateModal = () => {
  const { showListEmailTemplateModal, showNewEmailTemplateModal } =
    useEmailSenderAndManagerEmailTemplateContext()

  const { refetch } = useGetEmailTemplate()

  return (
    <>
      {showListEmailTemplateModal && <ListEmailTemplateModal />}
      {showNewEmailTemplateModal && <NewEmailTemplateModal refetch={refetch} />}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ManagerTemplateModal,
  'is_enable_manager_template',
  true,
)
