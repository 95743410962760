import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Portal } from 'src/common/components'
import { Icons } from 'src/common/components'
import { useDebounce } from 'src/common/hooks'
import { cleanStringToBeUrlReadable } from 'src/default/helpers/clean-string'

import { useRouter } from '../../../../default/helpers/navigation'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './search-field.module.scss'

const SearchField = () => {
  const router = useRouter()

  const { pathname } = useLocation()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationName = cleanStringToBeUrlReadable(
    userProfile.organization.name || '',
  )

  const [search, setSearch] = useState<string | null>(null)
  const [debounceValue, setDebounceValue] = useState<string | null>(null)
  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const navigateToSearchPath = (search: string) => {
    if (search) {
      router.push(`/search/${search}`)
    }
    setDebounceValue(search)
  }

  const debounceSearch = useDebounce(navigateToSearchPath, 600)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (debounceValue === '') {
      router.push('/leads')
    }
  }, [debounceValue])

  useEffect(() => {
    if (!pathname.includes('/search/')) {
      setSearch(null)
    }
  }, [pathname])

  return (
    <Portal.Wrapper id="searchField">
      <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
        <div
          className={cx(
            styles.inputWrapper,
            styles[`${currentTheme}Slice`],
            styles.middle,
          )}
        >
          <span className={styles.icon}>
            <Icons.Search />
          </span>
          <input
            className={cx(styles.withIcon)}
            placeholder="Search"
            onChange={handleChangeInput}
            value={search ?? ''}
          />
        </div>
      </div>
    </Portal.Wrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SearchField,
  'is_enable_search_person_view',
  true,
)
