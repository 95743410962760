import { useParams } from 'react-router-dom'

import { BasicBlock, Table } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { PRIVATE_ROUTES } from 'src/default/config'
import { useGetFormsByOrganization } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import FormsPageHeader from './FormsPageHeader/FormsPageHeader'
import styles from './forms-page.module.scss'

const FormsPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const { organizationId } = useParams<{ organizationId: string }>()

  const currentOrganizationId = userProfile.organization.id
  const { data: forms, isLoading } = useGetFormsByOrganization({
    organizationId: currentOrganizationId ?? '',
  })

  return (
    <BasicTemplate
      title="Forms"
      header={<FormsPageHeader />}
      isLoading={isLoading}
    >
      <BasicBlock className={styles.wrapper} paddings="big">
        <h3>Form Template</h3>
        <Table>
          {forms?.data.records.map((u: any) => (
            <Table.Row
              key={u.id}
              id={u.id}
              editable
              editLink={`/${organizationId}${PRIVATE_ROUTES.FORM_TEMPLATES.path}/:id`}
            >
              <Table.Column className={styles.name} title="Name" showMobile>
                {u.name}
              </Table.Column>

              <Table.Column title="Type" showMobile>
                {u.type}
              </Table.Column>

              <Table.Column title="url" showMobile>
                {u.url}
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </BasicBlock>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  FormsPage,
  'is_get_organization_form_templates_enabled',
)
