import cx from 'classnames'

import { Icons } from 'src/common/components'
import { IOption, IOptionsGroup } from 'src/common/interfaces'

import { BasicSelectGroupsOptions } from '../components'
import styles from './basic-select-groups-item.module.scss'

interface BasicSelectGroupsItemProps extends IOptionsGroup {
  isActive: boolean
  currentValue?: string
  options: IOption[]
  onToggle: () => void
  onChange: (newValue: string) => void
}

const BasicSelectGroupsItem = (props: BasicSelectGroupsItemProps) => {
  const { isActive, label, id, currentValue, options, onToggle, onChange } =
    props

  const currentOptions = options.filter((option) =>
    option.meta.groups.includes(id),
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner} onClick={onToggle}>
        <div className={cx(styles.arrow, isActive && styles.active)}>
          <Icons.ArrowSelect />
        </div>

        <div className={styles.label}>{label}</div>
      </div>

      {isActive ? (
        <BasicSelectGroupsOptions
          currentValue={currentValue}
          options={currentOptions}
          onChange={onChange}
        />
      ) : null}
    </div>
  )
}

export default BasicSelectGroupsItem
