import { useFormik, useFormikContext } from 'formik'
import * as yup from 'yup'

import { useMutatePersonIncome } from 'src/benestream/hooks'
import {
  IPersonIncome,
  IncomePeriod,
  IncomeSource,
} from 'src/benestream/interfaces'
import {
  Button,
  Form,
  Input,
  Select,
  SubmitButton,
} from 'src/common/components'

import { BenestreamPersonFormHouseholdIncomeValue } from '../components'
import styles from './benestream-person-form-household-income-form.module.scss'

interface HouseHoldInformation {
  amount: number
  incomeSource: string
  period: string
  benestreamPersonIncomeId: string
}
interface BenestreamPersonFormHouseholdIncomeFormProps {
  benestreamPersonId: string
  personName: string
  onClose: () => void
  onSuccess: () => void
  onError: () => void
  houseHoldInformation?: HouseHoldInformation
}

const BenestreamPersonFormHouseholdIncomeForm = (
  props: BenestreamPersonFormHouseholdIncomeFormProps,
) => {
  const {
    benestreamPersonId,
    personName,
    onClose,
    onSuccess,
    houseHoldInformation,
    onError,
  } = props

  const personIncomeMutation = useMutatePersonIncome(
    houseHoldInformation?.benestreamPersonIncomeId ? 'update' : 'create',
  )

  const formik = useFormik({
    initialValues: houseHoldInformation || {
      incomeSource: '',
      period: '',
      amount: 0,
    },
    onSubmit: async (values) => {
      await personIncomeMutation
        .mutateAsync({
          ...values,
          benestreamPersonId,
        })
        .then(() => {
          onSuccess()
        })
        .catch(() => {
          onError()
        })
    },
    validationSchema: () => {
      return yup.object().shape({
        incomeSource: yup.string().required('Required'),
        period: yup.string().required('Required'),
        amount: yup
          .number()
          .required('Required')
          .moreThan(0, 'Must be greater than 0'),
      })
    },
  })

  const montlyIncomeValue = (() => {
    const { period, amount } = formik.values

    if (period === 'weekly') {
      return (amount * 52) / 12
    }

    if (period === 'bi-weekly') {
      return (amount * 26) / 12
    }

    if (period === 'twice-monthly') {
      return amount * 2
    }

    if (period === 'monthly') {
      return amount
    }

    if (period === 'annually') {
      return amount / 12
    }

    return null
  })()

  return (
    <Form className={styles.wrapper} providerValue={formik}>
      <div className={styles.inner}>
        <Select
          required
          name="incomeSource"
          label="Income Source"
          placeholder="Select Income Source"
          options={Object.entries(IncomeSource).map(([key, value]) => ({
            label: key,
            value,
          }))}
          tabletWidth="50"
        />

        <Input
          disabled
          name="personName"
          label="Person"
          value={personName}
          tabletWidth="50"
        />

        <Select
          required
          name="period"
          label="Period"
          placeholder="Select Period"
          options={Object.entries(IncomePeriod).map(([key, value]) => ({
            label: key,
            value,
          }))}
          tabletWidth="50"
        />

        <Input
          required
          name="amount"
          type="number"
          label="Amount"
          placeholder="Enter amount"
          tabletWidth="50"
        />
      </div>

      {montlyIncomeValue ? (
        <BenestreamPersonFormHouseholdIncomeValue value={montlyIncomeValue} />
      ) : null}

      <div className={styles.bottomButtons}>
        <Button template="secondary" onClick={() => onClose()}>
          Cancel
        </Button>

        <SubmitButton className={styles.submitButton} iconAfter="ArrowSelect">
          Save
        </SubmitButton>
      </div>
    </Form>
  )
}

export default BenestreamPersonFormHouseholdIncomeForm
