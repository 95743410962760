import cx from 'classnames'
import { useFormik } from 'formik'

import { formatDate } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'
import { useGetDMPCaseHistory } from 'src/default/hooks'
import { IDMPCaseHistoryItem } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../../deactivation-benefit-case.module.scss'
import DeactivationBenefitHistoryFilter from './DeactivationBenefitHistoryFilter'
import DeactivationBenefitHistoryItem from './DeactivationBenefitHistoryItem'
import styles from './deactivation-benefit-history.module.scss'

interface DeactivationBenefitHistoryProps {
  caseId: string
  customerId: string
}

const DeactivationBenefitHistory = (props: DeactivationBenefitHistoryProps) => {
  const { caseId, customerId } = props

  const { data } = useGetDMPCaseHistory({
    caseId,
    customerId,
  })

  const filterFormik = useFormik<{ filter?: string }>({
    initialValues: {
      filter: '',
    },
    onSubmit: () => {},
  })

  const handledHistoryData = [
    ...(data?.data.case.edges ?? []),
    ...(data?.data.customer.edges ?? []).map((edge: IDMPCaseHistoryItem) => {
      if (edge.payload && edge.payload.prev) {
        const changeObj = {} as {
          [key: string]: {
            prev: string
            actual: string
          }
        }

        if (
          edge.payload.firstName !== edge.payload.prev.firstName ||
          edge.payload.lastName !== edge.payload.prev.lastName
        ) {
          changeObj.name = {
            prev: `${edge.payload.prev.firstName} ${edge.payload.prev.lastName}`,
            actual: `${edge.payload.firstName} ${edge.payload.lastName}`,
          }
        }

        if (edge.payload.license !== edge.payload.prev.license) {
          changeObj.license = {
            prev: edge.payload.prev.license || '',
            actual: edge.payload.license || '',
          }
        }

        if (edge.payload.phone !== edge.payload.prev.phone) {
          changeObj.phone = {
            prev: edge.payload.prev.phone || '',
            actual: edge.payload.phone || '',
          }
        }

        if (
          edge.payload.driveLocation &&
          edge.payload.prev.driveLocation &&
          edge.payload.driveLocation?.city !==
            edge.payload.prev.driveLocation.city
        ) {
          changeObj.driveArea = {
            prev: `${edge.payload.prev.driveLocation.city}, ${edge.payload.prev.driveLocation.state}`,
            actual: `${edge.payload.driveLocation.city}, ${edge.payload.driveLocation.state}`,
          }
        }

        return Object.keys(changeObj).length
          ? {
              ...edge,
              changeStr: Object.entries(changeObj)
                .map(([changeKey, { prev, actual }]) => {
                  let propName = changeKey
                  if (changeKey === 'name') {
                    propName = "driver's name"
                  }
                  if (changeKey === 'license') {
                    propName = 'license number'
                  }
                  if (changeKey === 'phone') {
                    propName = 'phone number'
                  }
                  if (changeKey === 'driveArea') {
                    propName = 'driving area'
                  }

                  return `${propName} from "${prev}" to "${actual}"`
                })
                .join(' & '),
            }
          : edge
        return edge
      } else {
        return edge
      }
    }),
  ].sort((a, b) => (dayjs(a.createdAt).diff(dayjs(b.createdAt)) > 0 ? -1 : 1))

  const historyItemsByDate = handledHistoryData
    .filter((item) =>
      filterFormik.values.filter
        ? item.action === 'changeStatus' &&
          item.payload.status.key === filterFormik.values.filter
        : true,
    )
    .reduce(
      (
        a: { [key: string]: IDMPCaseHistoryItem[] },
        item: IDMPCaseHistoryItem,
      ) => {
        const date = formatDate(item.createdAt, 'DD MMMM YYYY')

        if (!(date in a)) {
          a[date] = []
        }

        a[date].push(item)

        return a
      },
      {},
    )

  return (
    <div className={cx(rootStyles.caseBlock, styles.wrapper)}>
      <div className={styles.header}>
        <div className={rootStyles.title}>History</div>

        <DeactivationBenefitHistoryFilter providerValue={filterFormik} />
      </div>

      <div className={styles.inner}>
        {Object.entries(historyItemsByDate).length ? (
          <>
            {Object.entries(historyItemsByDate).map(([key, value]) => (
              <>
                <div key={key} className={styles.dateWrapper}>
                  <div className={styles.date}>{key}</div>

                  <div>
                    {(value as IDMPCaseHistoryItem[]).map(
                      (item: IDMPCaseHistoryItem, i: number) => (
                        <DeactivationBenefitHistoryItem
                          key={`${item._id}-${i}`}
                          {...item}
                        />
                      ),
                    )}
                  </div>
                </div>

                <div className={styles.border} />
              </>
            ))}
          </>
        ) : (
          <div className={styles.empty}>No history for display</div>
        )}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitHistory,
  'is_deactivation_benefit_history',
  true,
)
