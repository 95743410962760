import { WbfPolicies } from '@workers-benefit-fund/shared-data'

import { BasicBlock, Breadcrumbs } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import CreateLeadForm from './CreateLeadForm'

const CreateLeadPage = () => {
  return (
    <>
      <Breadcrumbs items={[{ label: 'Create New Lead' }]} />

      <BasicTemplate title="Create New Lead">
        <BasicBlock paddings="big">
          <CreateLeadForm />
        </BasicBlock>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadPage,
  WbfPolicies.is_create_person_enabled,
)
