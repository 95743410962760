import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Container,
  Flex,
  Modal,
  Select,
  Stack,
  Table,
  Text,
} from '@mantine/core'

import { ActionMenu, Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { UserMembership } from '../../models/user.model'
import styles from './membership-information.module.scss'

interface MembershipInformationProps {
  membership: UserMembership
  membershipRoleList: Array<{ value: string; label: string }>
  userMembershipModal: {
    opened: boolean
    open: () => void
    close: () => void
  }
  actualRoleId: string
  setMembershipRole: (value: ComboboxItem) => void
  membershipRole: ComboboxItem | null
  newStatus: string
  setNewStatus: (value: 'active' | 'inactive') => void
  handleMutation: () => void
  loadingUpdateMutation: boolean
  handleDeleteMembership: () => void
  handleGenerateApiKey: () => void
}

const MembershipInformation = ({
  membershipRole,
  setMembershipRole,
  actualRoleId,
  membership,
  membershipRoleList,
  userMembershipModal,
  newStatus,
  setNewStatus,
  handleMutation,
  loadingUpdateMutation,
  handleDeleteMembership,
  handleGenerateApiKey,
}: MembershipInformationProps) => {
  return (
    <Box my="md">
      <Flex
        justify="space-between"
        align="flex-start"
        className={styles.headerWrapper}
      >
        <Text fw={700} c="blue.5">
          Membership Information
        </Text>
      </Flex>

      <Flex justify="center">
        <Container mx="md" className={styles.flex1} my="xl">
          <Table withTableBorder highlightOnHover withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Role</Table.Th>
                <Table.Th>Active</Table.Th>
                <Table.Th>API key</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {!!membership.id ? (
                <Table.Tr>
                  <Table.Td>{membership.role}</Table.Td>
                  <Table.Td>
                    <Flex justify="center">
                      <Checkbox
                        checked={membership.status === 'active'}
                        readOnly
                      />
                    </Flex>
                  </Table.Td>
                  <Table.Td>{!membership.hasAPIKey ? '-' : 'Active'}</Table.Td>
                  <Table.Td>
                    <Flex justify="center">
                      <ActionMenu
                        items={[
                          {
                            label: 'Update',
                            color: 'blue',
                            icon: <Icons.Edit />,
                            onClick: () => userMembershipModal.open(),
                            enable: true,
                          },
                          {
                            label: 'Add API Key',
                            color: 'blue',
                            icon: <Icons.Logout />,
                            onClick: handleGenerateApiKey,
                            enable: !membership.hasAPIKey,
                          },
                          {
                            label: 'Delete',
                            color: 'red',
                            icon: <Icons.Trash />,
                            onClick: handleDeleteMembership,
                            enable: true,
                          },
                        ]}
                      />
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              ) : (
                <Table.Tr>
                  <Table.Td>
                    <Button
                      fullWidth
                      variant="transparent"
                      size="md"
                      radius="xs"
                      leftSection={<Icons.Plus />}
                    >
                      Add new membership
                    </Button>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </Container>
      </Flex>
      <Modal
        opened={userMembershipModal.opened}
        onClose={userMembershipModal.close}
      >
        <h3>
          {membership.id === '' ? 'Create Membership' : 'Update Membership'}
        </h3>

        <Stack gap="xl">
          <Select
            label="Role"
            withAsterisk
            value={membershipRole ? membershipRole.value : actualRoleId}
            onChange={(_value, option) => setMembershipRole(option)}
            placeholder="Select Role"
            data={membershipRoleList}
            allowDeselect={false}
          />
          <Checkbox
            label="Active"
            defaultChecked
            onChange={() =>
              setNewStatus(newStatus === 'active' ? 'active' : 'inactive')
            }
          />
        </Stack>

        <Flex justify="space-between" align="flex-start" mt="lg">
          <Button onClick={() => userMembershipModal.close()} variant="outline">
            Cancel
          </Button>
          <Button
            loading={loadingUpdateMutation}
            onClick={() => handleMutation()}
            variant="filled"
          >
            Save
          </Button>
        </Flex>
      </Modal>
    </Box>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MembershipInformation,
  'is_enable_leads_table',
  true,
)
