import { FormikContextType } from 'formik'
import React, { useEffect } from 'react'

import { Select, Table } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

interface SmsCampaignFilterProps {
  providerValue: FormikContextType<any>
  total: number
}

const SmsCampaignFilter = (props: SmsCampaignFilterProps) => {
  const { providerValue } = props

  const handleFilterChange = (value: string, name: string) => {
    providerValue.setFieldValue(name, value)
  }

  return (
    <Table.Filter {...props} showSaveCSV={false}>
      <Select
        options={[
          { label: 'None', value: 'NONE' },
          { label: 'Delivered', value: 'DELIVERED' },
          { label: 'Undelivered', value: 'UNDELIVERED' },
          { label: 'Error', value: 'ERROR' },
        ]}
        name="status"
        placeholder="Status"
        withError={false}
        template="filter"
        onChange={(e) => handleFilterChange(e, 'status')}
      />
    </Table.Filter>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SmsCampaignFilter,
  'is_enable_sms_campaigns_filter',
  true,
)
