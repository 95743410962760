import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DeactivationCaseModel } from './deactivationCase.model'

const initialState: DeactivationCaseModel = {
  deactivationCaseData: null,
}

const deactivationCaseStore = createSlice({
  name: 'deactivationCase',
  initialState,
  reducers: {
    setDeactivationCase: (
      state,
      action: PayloadAction<
        DeactivationCaseModel['deactivationCaseData'] | null
      >,
    ) => {
      state.deactivationCaseData = action.payload
    },
  },
})

export const { setDeactivationCase } = deactivationCaseStore.actions

export default deactivationCaseStore.reducer
