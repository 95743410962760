import cx from 'classnames'
import { useEffect } from 'react'

import { Portal, Toast } from 'src/common/components'
import { NOTIFICATIONS_TIMEOUT } from 'src/default/config'

import { useNotifications } from '..'
import { INotification } from '../Notifications'
import styles from './notifications.module.scss'

type NotificationProps = INotification & {
  id: string
}

const Notification = (props: NotificationProps) => {
  const { id, type, content, options } = props

  const context = useNotifications()

  useEffect(() => {
    if (!options?.persistent) {
      setTimeout(() => {
        context?.clear(id)
      }, NOTIFICATIONS_TIMEOUT)
    }
  }, [])

  if (options?.portalId) {
    return (
      <Portal.Wrapper id={options?.portalId}>
        <Toast
          className={cx(styles.wrap, options?.allWidth && styles.allWidth)}
          status={type}
          content={content}
        />
      </Portal.Wrapper>
    )
  }

  return (
    <Toast
      className={cx(styles.wrap, options?.allWidth && styles.allWidth)}
      status={type}
      content={content}
    />
  )
}

export default Notification
