import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ApiResponseSerializer } from 'src/v2/commons/interface/ApiResponseSerializer'

import { UserOrganization } from './user.model'

export const defaultUsers: ApiResponseSerializer<UserOrganization> = {
  records: [],
  totalRecords: 0,
  recordsLeft: 0,
}

const initialState = {
  ...defaultUsers,
}

const usersStore = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (
      state,
      action: PayloadAction<ApiResponseSerializer<UserOrganization>>,
    ) => {
      state = {
        ...action.payload,
      }
    },
    resetUserProfileStore: (state) => {
      state = { ...initialState }
    },
  },
})

export const { setUsers, resetUserProfileStore } = usersStore.actions
export default usersStore.reducer
