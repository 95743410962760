import { createEmailTemplate } from "../useCase/createEmailTemplate.usecase"

const createEmailTemplatesContainer = () => {
  const {
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    showSuccessModal,
    setShowSucessModal,
    pathToViewEmailTempalte,
    handleViewEmailTemplates,
  } = createEmailTemplate()

  return {
    emailTemplateForm,
    onSubmit,
    setEmailBody,
    showSuccessModal,
    setShowSucessModal,
    pathToViewEmailTempalte,
    handleViewEmailTemplates
  }
}

export default createEmailTemplatesContainer
