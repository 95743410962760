import { useParams } from 'react-router-dom'

import { Button } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './abcs-administration-template.module.scss'

interface ABCsAdministrationTemplateProps {
  benefitId?: string
}

const ABCsAdministrationTemplate = (
  props: React.PropsWithChildren<ABCsAdministrationTemplateProps>,
) => {
  const { benefitId, children } = props
  const { organizationId } = useParams<{ organizationId: string }>()
  const showAddNewBenefitButton = benefitId

  return (
    <BasicTemplate
      title="Associated Benefit Contracts Administration"
      header={
        <div className={styles.header}>
          {showAddNewBenefitButton ? (
            <PolicyCheckHOC
              policyName="is_enable_create_associated_benefit_contract"
              disablePolicyCheck
            >
              <Button
                size="small"
                icon="Plus"
                to={`/${organizationId}${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}/create`}
              >
                Add New Benefit
              </Button>
            </PolicyCheckHOC>
          ) : null}
        </div>
      }
    >
      {children}
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ABCsAdministrationTemplate,
  'is_enable_abc_administration_template',
  true,
)
