import { FormikContextType } from 'formik'

import { Table } from 'src/common/components'
import { IOption } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import { EmptyTickets } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { ITicket } from 'src/default/interfaces'
import { TicketCustomerLink } from 'src/default/pages/Tickets/TicketsPage/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import rootStyles from '../tickets-page.module.scss'
import PastTicketsTableFilter from './PastTicketsTableFilter'

interface PastTicketsTableProps {
  filterFormik: FormikContextType<any>
  isLoading: boolean
  tickets: ITicket[]
  total: number
  usersOptions: IOption[]
}

const PastTicketsTable = (props: PastTicketsTableProps) => {
  const { filterFormik, isLoading, tickets, total, usersOptions } = props

  return (
    <>
      <PastTicketsTableFilter
        filterFormik={filterFormik}
        total={total}
        usersOptions={usersOptions}
      />

      {tickets.length ? (
        <div>
          <Table>
            {tickets.map((t: ITicket) => (
              <Table.Row
                key={t.id}
                id={t.id}
                className={rootStyles.row}
                editable={false}
                editLink={`${PRIVATE_ROUTES.LEADS.path}/:id`}
              >
                <Table.Column
                  className={rootStyles.name}
                  title="Name"
                  showMobile
                >
                  <TicketCustomerLink ticket={t}>{t.name}</TicketCustomerLink>
                </Table.Column>

                <Table.Column title="Email">{t.email}</Table.Column>

                <Table.Column title="Location" showMobile>
                  {t.location}
                </Table.Column>

                <Table.Column title="Reason For Visit">
                  {t.reasonForVisit}
                </Table.Column>

                <Table.Column title="Status" showMobile>
                  {t.status}
                </Table.Column>

                <Table.Column title="Assigned To">
                  {usersOptions.find((u) => u.value === t.assignUserId)
                    ?.label ?? ''}
                </Table.Column>

                <Table.Column title="Created Date">
                  {dayjs(t.createdAt).utc().format('MM/DD/YYYY')}
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        </div>
      ) : (
        <>{!isLoading ? <EmptyTickets /> : null}</>
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PastTicketsTable,
  'is_enable_get_past_tickets',
  true,
)
