import { Icons } from 'src/common/components'

import styles from './file-upload-empty.module.scss'

interface FileUploadEmptyProps {
  description?: string
  formats?: string[]
  errorMessage?: string
}

const FileUploadEmpty = (props: FileUploadEmptyProps) => {
  const { description, formats, errorMessage } = props

  return (
    <>
      <div className={styles.icon}>
        <Icons.CloudUpload />
      </div>

      {formats ? (
        <div className={styles.formats}>{formats.join(' - ')}</div>
      ) : null}

      <div className={styles.description}>{description}</div>
    </>
  )
}

export default FileUploadEmpty
