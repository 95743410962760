import { useForm, yupResolver } from '@mantine/form'
import { GetInputProps } from '@mantine/form/lib/types'
import * as yup from 'yup'

import { useLoginMutation } from '../models/auth.api'
import { LoginFormModel } from '../models/auth.model'

interface IUseLoginFormUseCase {
  getFormProps: GetInputProps<LoginFormModel>
  onSubmit: () => void
  loginMutationStatus: {
    isLoading: boolean
    isSuccess: boolean
  }
}

const useLoginFormUseCase = (): IUseLoginFormUseCase => {
  const [login, { isLoading, isSuccess }] = useLoginMutation()

  const schema = yup.object().shape({
    email: yup.string().required('Required').email('Invalid email'),
    password: yup.string().required('Required').min(8, 'At least 8 characters'),
  })

  const form = useForm<LoginFormModel>({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
      password: '',
    },
    validateInputOnBlur: true,
    validate: yupResolver(schema),
  })

  const onSubmit = () => {
    form.onSubmit(async (values) => {
      login(values)
        .unwrap()
        .catch((error) => {
          form.setErrors({ password: error?.data?.message ?? 'Error' })
        })
    })()
  }

  return {
    getFormProps: form.getInputProps,
    onSubmit: onSubmit,
    loginMutationStatus: {
      isLoading: isLoading,
      isSuccess: isSuccess,
    },
  }
}

export default useLoginFormUseCase
