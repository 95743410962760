import { IListOfRecords } from 'src/common/interfaces'

export type ConcatQueryPagesItem<T> = T & {
  id: string
}

export type ConcatQueryPage<T> = {
  data: IListOfRecords<ConcatQueryPagesItem<T>>
}

export const concatQueryPages = <T>(pages: ConcatQueryPage<T>[]) => {
  try {
    const iterator = Array.isArray(pages) ? pages : []

    return iterator.reduce((a, c, i) => {
      if (typeof c.data.records?.[Symbol.iterator] === 'function') {
        if (i === 0) {
          a = [...c.data.records]
        } else {
          a = [
            ...a,
            ...c.data.records.filter(
              (newItem) => !a.some((oldItem) => oldItem.id === newItem.id),
            ),
          ]
        }
      } else {
        a = [...a, c.data.records as any]
      }

      return a
    }, [] as ConcatQueryPagesItem<T>[])
  } catch (error) {
    console.error(error)

    return []
  }
}

export const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value)
}

export const capitalize = (value: string) => {
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const splitCamelCase = (value: string) => {
  //TODO mb remove beneStream exception from the common helper
  if (value.includes('beneStream')) {
    value = value.replace('beneStream', 'Benestream')
  }

  return value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export const getDocumentTypeFormatted = (type: string) => {
  if (type === 'tlc') {
    return type.toUpperCase()
  }
  if (type === 'other_type') {
    return 'Miscellaneous'
  }
  return capitalize(type)
}

export const removeUnderScoreAndTransformToLowerCase = (value: string) => {
  return value?.replace(/_/g, ' ').toLowerCase()
}

export const formatErrorMessage = (errorMessage: string | string[]) => {
  if (!errorMessage) {
    return ''
  }

  const stringErrorMessage =
    typeof errorMessage === 'string' ? errorMessage : errorMessage.join(' ')

  const capitalizedErrorMessage =
    stringErrorMessage.charAt(0) + stringErrorMessage.slice(1)

  if (stringErrorMessage.charAt(-1) === '.') {
    return capitalizedErrorMessage
  }

  return `${capitalizedErrorMessage}.`
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase(),
    )
    .replace(/\s+/g, '')
}

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, index * size + size),
  )
}
