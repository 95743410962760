import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateFormSubmission = () =>
  useMutation({
    mutationFn: async (data: any) => {
      const url = '/v1/forms'
      return api['patch'](url, data)
    },
  })

export default useMutateFormSubmission
