import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useGetUsers = () => {
  return useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      return await api.get(`/v4/users`).then((result) => {
        function compare(a: any, b: any) {
          if (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`) {
            return -1
          }
          if (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`) {
            return 1
          }
          return 0
        }

        return {
          ...result,
          data: result.data.records.sort(compare),
        }
      })
    },
  })
}

export default useGetUsers
