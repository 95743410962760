import cx from 'classnames'

import { Button, Icons } from 'src/common/components'

import styles from './benestream-person-form-household-income-notification.module.scss'

interface Props {
  title: string
  description: string
  isSuccess: boolean
  closeModal: () => void
  closeNotification: () => void
}

const BenestreamPersonHouseholdIncomeNotification = (props: Props) => {
  const { description, isSuccess, title, closeNotification, closeModal } = props
  return (
    <div className={styles.notificationWrapper}>
      {isSuccess ? (
        <div className={styles.successIcon}>
          <Icons.CheckFilled className={styles.icon} />
        </div>
      ) : (
        <div className={styles.errorIcon}>
          <Icons.Warning className={styles.icon} />
        </div>
      )}
      <div
        className={cx(styles.title, isSuccess ? styles.success : styles.error)}
      >
        {title}
      </div>
      <div className={styles.description}>{description}</div>
      {isSuccess ? (
        <div className={styles.successButtonWrapper}>
          <Button className={styles.cancelButton} onClick={closeModal}>
            Go to Household Information
          </Button>
          <Button className={styles.primaryButton} onClick={closeNotification}>
            Household Member Income
          </Button>
        </div>
      ) : (
        <div className={styles.errorButtonWrapper}>
          <Button className={styles.primaryButton} onClick={closeNotification}>
            Ok
          </Button>
        </div>
      )}
    </div>
  )
}

export default BenestreamPersonHouseholdIncomeNotification
