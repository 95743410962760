import { isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'

import { useUpdatePolicyDescriptionMutation } from '../models/policy.api'

export const useUpdatePolicyDescriptionUseCase = ({
  id,
  description,
  name,
}: {
  id: string
  description?: string
  name: string
}): any => {
  const [useUpdatePolicyDesctionMutation, { isLoading }] =
    useUpdatePolicyDescriptionMutation()

  const [isEditing, setIsEditing] = useState(false)
  const policyUpdateForm = useForm<{ description: string }>({
    mode: 'uncontrolled',
    initialValues: {
      description: description ?? '',
    },
    validateInputOnBlur: true,
    validate: {
      description: isNotEmpty('Description is required!'),
    },
  })

  const onSubmit = () => {
    policyUpdateForm.onSubmit(async (values) => {
      useUpdatePolicyDesctionMutation({
        description: values.description,
        id,
        name,
      })
        .unwrap()
        .then(() => {
          setIsEditing(false)
          notifications.show({
            title: 'Success',
            message: 'Policy description successfully updated',
          })
        })
        .catch(() => {
          setIsEditing(false)
          notifications.show({
            title: 'Oops',
            message: 'There was an error',
            color: 'red',
          })
        })
    })()
  }

  const onCancel = () => {
    policyUpdateForm.reset()
    setIsEditing(false)
  }

  return {
    policyUpdateForm,
    onSubmit,
    isLoading,
    onCancel,
    isEditing,
    setIsEditing,
  }
}
