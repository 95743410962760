import { notifications } from '@mantine/notifications'
import { useParams } from 'react-router-dom'

import { useDeleteUserMutation, useGetUserQuery } from '../models/user.api'

interface UseUserUseCaseProps {
  handleDeleteUser: () => void
  isSuccessDeleteUser: boolean
}

const useUserUseCase = (): UseUserUseCaseProps => {
  const { userId } = useParams()

  useGetUserQuery(userId, { refetchOnMountOrArgChange: true })

  const [deleteUser, { isSuccess }] = useDeleteUserMutation()

  const handleDeleteUser = () => {
    deleteUser(userId)
      .unwrap()
      .catch(() => {
        notifications.show({
          title: 'Error',
          message: 'User could not be deleted due to an error',
          color: 'red',
        })
      })
  }

  return {
    handleDeleteUser,
    isSuccessDeleteUser: isSuccess,
  }
}

export default useUserUseCase
