import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { PAGINATION_LIMIT } from '@v2/commons/config'
import { filterUndefinedValues } from '@v2/commons/utils/filterUndefinedValues'
import { useState } from 'react'

import {
  useSetOfficeVisitMutation,
  useTicketsQuery,
} from '../models/ticket.api'
import {
  ITicketsUseCaseSchema,
  TicketFiltersModel,
} from '../models/ticket.model'

const initialFetchValues = {
  isOpenTickets: true,
  offset: 0,
  limit: PAGINATION_LIMIT,
}

const useTicketsUseCase = (): ITicketsUseCaseSchema => {
  const [openTicketsPage, setOpenTicketsPage] = useState(0)
  const [pastTicketsPage, setPastTicketsPage] = useState(0)

  const TicketFiltersForm = useForm<TicketFiltersModel>({
    initialValues: {},
    onValuesChange() {
      setPastTicketsPage(0)
    },
  })

  const {
    isLoading: isLoadingOpenTickets,
    isSuccess: isSuccessOpenTickets,
    data: openTickets,
    isFetching: isFetchingOpenTickets,
  } = useTicketsQuery(
    {
      params: {
        ...initialFetchValues,
        offset: openTicketsPage * PAGINATION_LIMIT,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const filters = filterUndefinedValues(
    TicketFiltersForm.getValues() as Record<
      string,
      string | number | boolean | object | null | undefined
    >,
  )

  const { data: pastTickets } = useTicketsQuery(
    {
      params: {
        ...initialFetchValues,
        ...filters,
        isOpenTickets: false,
        offset: pastTicketsPage * PAGINATION_LIMIT,
      },
    },
    { refetchOnMountOrArgChange: true },
  )
  const [
    updateOfficeVisit,
    {
      isLoading: isAssignTicketLoading,
      isSuccess: isSuccessAssignTicket,
      isError: isErrorAssignTicket,
    },
  ] = useSetOfficeVisitMutation()

  const handlePaginateOpenTickets = () => {
    if (isFetchingOpenTickets) return
    return setOpenTicketsPage((prev) => {
      if (
        openTickets &&
        openTickets.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  const handlePaginatePastTickets = () => {
    if (isFetchingOpenTickets) return
    return setPastTicketsPage((prev) => {
      if (
        pastTickets &&
        pastTickets.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  const handleAssignTicketToUser = async (
    ticketId: string,
    assignedToUser: string,
  ) => {
    updateOfficeVisit({
      id: ticketId,
      assignedToId: assignedToUser,
      status: 'In Progress',
    })
      .unwrap()
      .then(() => {
        setOpenTicketsPage(0)
      })
      .catch(() => {
        notifications.show({
          title: 'Oops',
          message: 'There was an error',
          color: 'red',
        })
      })
  }
  return {
    openTickets,
    pastTickets,
    handlePaginateOpenTickets,
    handlePaginatePastTickets,
    handleAssignTicketToUser,
    updateTicketMutationStatus: {
      isAssignTicketLoading,
      isSuccessAssignTicket,
      isErrorAssignTicket,
    },
    TicketFiltersForm,
    queryStatus: {
      isLoadingOpenTickets: isLoadingOpenTickets,
      isSuccessOpenTickets: isSuccessOpenTickets,
      isFetchingOpenTickets: isFetchingOpenTickets,
    },
  }
}

export default useTicketsUseCase
