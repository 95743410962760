export enum IncomeSource {
  'Wages/Self Employment' = 'wages-self-employment',
  'Child Support' = 'child-support',
  'Social Security' = 'social-security',
  'SSI' = 'ssi',
  'SSDI' = 'ssdi',
  'Unemployment' = 'unemployment',
  'Other' = 'other',
}

export enum IncomePeriod {
  'Weekly' = 'weekly',
  'Bi-Weekly' = 'bi-weekly',
  'Twice Monthly' = 'twice-monthly',
  'Monthly' = 'monthly',
  'Annually' = 'annually',
}

export interface IPersonIncome {
  id: string
  incomeSource: IncomeSource
  period: IncomePeriod
  amount: string
  monthlyIncome: number
}
