import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useDeleteDocument = (documentId: string) =>
  useMutation({
    mutationFn: async () => await api.delete(`/person/documents/${documentId}`),
  })

export default useDeleteDocument
