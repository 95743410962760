import { useState } from 'react'

import { useDebounce } from 'src/common/hooks'

import { useGetPoliciesQuery } from '../models/policy.api'
import {
  IPoliciesResponse,
  PolicyDataTable,
  PolicyModel,
} from '../models/policy.model'

export const usePoliciesUseCase = (): IPoliciesResponse => {
  const { data: dataPolicies, isLoading } = useGetPoliciesQuery()

  const [filteredPolicies, setFilteredPolicies] = useState<PolicyModel[]>([])

  const filterPolicies = (term: string) => {
    const filtered = dataPolicies
      ? dataPolicies.records.filter((policy: PolicyDataTable) =>
          policy.name.toLowerCase().includes(term.toLowerCase()),
        )
      : []

    setFilteredPolicies(filtered)
  }

  const debounceSearch = useDebounce(filterPolicies, 600)

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    debounceSearch(term)
  }

  if (isLoading) {
    return {
      data: [],
      isLoading: false,
      handleOnSearchChange,
      filteredPolicies,
    }
  }
  if (!dataPolicies)
    return {
      data: [],
      isLoading: false,
      handleOnSearchChange,
      filteredPolicies,
    }

  return {
    data: dataPolicies.records,
    isLoading,
    handleOnSearchChange,
    filteredPolicies,
  }
}
