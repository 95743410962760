import { Card, Tabs } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import withErrorBoundaryAndPolicyCheck from '@v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import ActionConfirmationModalWithFeedback from '@v2/commons/components/ActionConfirmationModalWithFeedback copy/ActionModalWithFeedback.component'
import { TBreadCrumbItems } from '@v2/commons/components/Breadcrumb'
import InfiniteScrollTemplate from '@v2/commons/templates/InfiniteScrollTemplate'
import { useState } from 'react'

import { OpenTickets } from '../components/OpenTickets.component'
import { PastTickets } from '../components/PastTickets.component'
import useTicketsContainer from '../containers/useTickets.container'
import styles from '../tickets.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Tickets' },
]

const TicketsView = () => {
  const [activeTab, setActiveTab] = useState<string | null>('open-tickets')
  const [organizer, setOrganizer] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  })
  const [ticketToUpdate, setTicketToUpdate] = useState<string>('')
  const [openTicketKey, setOpenTicketKey] = useState<number>(1)
  const [
    assignModalOpened,
    { open: openAssignModal, close: closeAssignModal },
  ] = useDisclosure(false)

  const {
    assignedToUsers,
    openTickets,
    pastTickets,
    isFetchingOpenTickets,
    isLoadingOpenTickets,
    handlePaginateOpenTickets,
    handlePaginatePastTickets,
    handleAssignTicketToUser,
    updateTicketMutationStatus,
    TicketFiltersForm,
  } = useTicketsContainer()

  const onAssignTicket = async () => {
    closeAssignModal()
    handleAssignTicketToUser(ticketToUpdate, organizer.value)
  }

  const onCloseModal = () => {
    setOpenTicketKey((prev) => prev + 1)
    closeAssignModal()
  }
  return (
    <>
      <InfiniteScrollTemplate
        isLoading={isLoadingOpenTickets}
        title="All Tickets"
        breadCrumbItems={breadCrumbItems}
        onScroll={
          activeTab === 'open-tickets'
            ? handlePaginateOpenTickets
            : handlePaginatePastTickets
        }
        records={
          activeTab === 'open-tickets'
            ? {
                totalRecords: openTickets?.totalRecords || 0,
                actualNumberOfRecords: openTickets?.records?.length ?? 0,
              }
            : {
                totalRecords: pastTickets?.totalRecords || 0,
                actualNumberOfRecords: pastTickets?.records?.length ?? 0,
              }
        }
      >
        <Card shadow="lg" radius="lg" className={styles.defaultCard}>
          <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
            <Tabs.List grow justify="space-between">
              <Tabs.Tab value="open-tickets" className={styles.tabTitle}>
                Open Tickets
              </Tabs.Tab>
              <Tabs.Tab value="past-tickets" className={styles.tabTitle}>
                Past Tickets
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="open-tickets" className={styles.tabContent}>
              <OpenTickets
                key={openTicketKey}
                isLoading={isFetchingOpenTickets}
                tickets={openTickets}
                usersOptions={assignedToUsers}
                openAssignModal={openAssignModal}
                setTicketToUpdate={setTicketToUpdate}
                setOrganizer={setOrganizer}
                isErrorAssignTicket={
                  updateTicketMutationStatus.isErrorAssignTicket
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value="past-tickets" className={styles.tabContent}>
              <PastTickets
                isLoading={isFetchingOpenTickets}
                tickets={pastTickets}
                usersOptions={assignedToUsers}
                TicketFiltersForm={TicketFiltersForm}
              />
            </Tabs.Panel>
          </Tabs>
        </Card>
      </InfiniteScrollTemplate>
      <ActionConfirmationModalWithFeedback
        isLoading={updateTicketMutationStatus.isAssignTicketLoading}
        isOpen={assignModalOpened}
        onClose={onCloseModal}
        handleConfirm={onAssignTicket}
        title="Assign Ticket"
        subtitle="Are you sure you want to assign this ticket?"
        buttonText="Assign Ticket"
        isSuccess={updateTicketMutationStatus.isSuccessAssignTicket}
        confirmationTitle={'Changes Saved!'}
      />
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TicketsView,
  'is_enable_tickets_view',
  true,
)
