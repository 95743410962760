import cx from 'classnames'
import { useFormikContext } from 'formik'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import { IPersonHousehold } from 'src/benestream/interfaces'
import { Checkbox, Input, InputDate, Select } from 'src/common/components'

import styles from './benestream-person-form-benefits.module.scss'

const BenestreamPersonFormBenefits = () => {
  const { values } = useFormikContext<IPersonHousehold>()

  return (
    <BenestreamPersonFormBlock
      className={styles.wrapper}
      title="Benefits Information"
    >
      <Select
        name="onCompanyPlan"
        label="On Company Plan?"
        placeholder="Select Variant"
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'unknown', label: 'Unknown' },
        ]}
        tabletWidth="50"
      />

      <Select
        name="accessToCompanyPlan"
        label="Access to Company Plan?"
        placeholder="Select Variant"
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'unknown', label: 'Unknown' },
        ]}
        tabletWidth="50"
      />

      <Checkbox
        name="haveMedicaidChipNYEP"
        label="Do you have Medicaid, CHIP, or the NYEP (if applicable)?"
        tabletWidth="50"
        className={styles.checkboxMedical}
      />

      <div className={styles.medicalInputs}>
        <InputDate
          className={cx(
            styles.inputDate,
            values.haveMedicaidChipNYEP && styles.visible,
          )}
          name="CHIPRenewalDate"
          label="CHIP Renewal Date"
          tabletWidth="50"
          futureDate
          maxYear={2030}
        />

        <InputDate
          className={cx(
            styles.inputDate,
            values.haveMedicaidChipNYEP && styles.visible,
          )}
          name="MARenewalDate"
          label="Medicaid Renewal Date"
          tabletWidth="50"
          futureDate
          maxYear={2030}
        />

        <InputDate
          className={cx(
            styles.inputDate,
            values.haveMedicaidChipNYEP && styles.visible,
          )}
          name="nyepRenewalDate"
          label="NYEP Renewal Date"
          tabletWidth="50"
          futureDate
          maxYear={2030}
        />
      </div>

      <Checkbox
        name="isEnrolledToMedicare"
        label="Are you enrolled in/do you have Medicare?"
        tabletWidth="50"
      />

      <Select
        name="areYouOnSSIOrSSDI"
        label="Are you on SSI or SSDI?"
        placeholder="Select Variant"
        options={[
          { value: 'ssi', label: 'SSI' },
          { value: 'ssdi', label: 'SSDI' },
          { value: 'both', label: 'Both' },
          { value: 'none', label: 'None' },
          { value: 'unknown', label: 'Unknown' },
        ]}
        tabletWidth="50"
      />

      <Select
        name="citizenship"
        label="Citizenship/Immigration Status"
        placeholder="Select Variant"
        options={[
          { value: 'US', label: 'U.S. Citizen' },
          { value: 'qualifiedAlien', label: 'Qualified Alien' },
          { value: 'protectedAlien', label: 'Protected Alien' },
          { value: 'workAuthorization', label: 'Work Authorization/EAD' },
          {
            value: 'greenCard5Plus',
            label: 'Permanent Resident/Green Card 5+ years',
          },
          { value: 'greenCard5Less', label: 'Green Card Holder < 5 years' },
          { value: 'none', label: 'None of the Above' },
        ]}
        tabletWidth="50"
      />

      <Select
        name="taxFilingStatus"
        label="Tax Filing Status"
        placeholder="Select status"
        options={[
          { value: 'Head of Household', label: 'Head of Household' },
          {
            value: 'Married Filing Separate',
            label: 'Married Filing Separate',
          },
          { value: 'Married Filing Jointly', label: 'Married Filing Jointly' },
          { value: 'Qualified Widow', label: 'Qualified Widow' },
          { value: 'Single', label: 'Single' },
        ]}
        tabletWidth="50"
      />

      <Input
        name="medicaidIdNumber"
        label="Medicaid ID Number"
        tabletWidth="50"
        placeholder="Enter Medicaid ID Number"
      />

      <Input
        name="medicareIdNumber"
        label="Medicare ID Number"
        tabletWidth="50"
        placeholder="Enter Medicare ID Number"
      />

      <Input
        name="socialSecurityNumber"
        label="Social Security Number"
        tabletWidth="50"
        placeholder="Enter Social Security Number"
      />

      <Input
        name="applicationNumber"
        label="Application Number"
        tabletWidth="50"
        placeholder="Enter Application Number"
      />
    </BenestreamPersonFormBlock>
  )
}

export default BenestreamPersonFormBenefits
