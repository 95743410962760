import { Card, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import SuccessErrorModal from '@v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'

import { PRIVATE_ROUTES } from 'src/default/config'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import AssociatedInformation from '../components/AssociatedInformation.component'
import RecordInformation from '../components/RecordInformation.component'
import useMergingToolContainer from '../containers/useRecordInformation.container'
import styles from './person-merging-tool.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Persons' },
  { title: 'Merge Duplicates' },
]

const PersonMerginToolView = () => {
  const {
    tableData,
    recodrInformationForm,
    setEntireColumn,
    isChecked,
    setCellValue,
    onSubmit,
    mergeMutationStatus,
    mergeResponse,
    handleClearStoreSearch,
  } = useMergingToolContainer()

  const [
    isSuccessModalOpen,
    { close: closeSuccessModal, open: openSuccessModal },
  ] = useDisclosure(false)
  const [isErrorModalOpen, { close: closeErrorModal, open: openErrorModal }] =
    useDisclosure(false)

  const OnMergeSuccess = () => {
    openSuccessModal()
    handleClearStoreSearch()
  }

  return (
    <BasicTemplate
      isLoading={false}
      title="Person Merging Tool"
      breadCrumbItems={breadCrumbItems}
    >
      <Card shadow="lg" radius="sm" className={styles['default-card']}>
        <Text size="sm">
          To merge records, select a primary record that will be updated with
          the information chosen by the user.{' '}
          <Text span inherit fw={500} c="#E53E3E">
            Once you merge you cannot undo the process.
          </Text>
        </Text>
        <section>
          <Text mt={20} className={styles.title}>
            Associated Information
          </Text>
          <AssociatedInformation />
        </section>
        <section>
          <Text mt={20} className={styles.title}>
            Record Information
          </Text>
          <RecordInformation
            tableData={tableData}
            recodrInformationForm={recodrInformationForm}
            setEntireColumn={setEntireColumn}
            isChecked={isChecked}
            setCellValue={setCellValue}
            onSubmit={onSubmit}
            isLoading={mergeMutationStatus.isLoading}
          />
        </section>
        <SuccessErrorModal
          closeOnClickOutside={false}
          closeOnEscape={false}
          closeButtonProps={{
            display: 'none',
          }}
          status={'success'}
          title="Records Merged Successfully"
          subTitle="The selected records have been merged into one"
          buttonText="View Merged Record"
          path={
            mergeResponse?.isVerified
              ? `${PRIVATE_ROUTES.ACCOUNTS.path}/${mergeResponse?.id}`
              : `${PRIVATE_ROUTES.LEADS.path}/${mergeResponse?.id}`
          }
          opened={isSuccessModalOpen}
          onClose={closeSuccessModal}
          onOpen={OnMergeSuccess}
          triggerOpen={mergeMutationStatus.isSuccess}
        />
        <SuccessErrorModal
          status={'error'}
          title="Merge Failed"
          subTitle="There was an issue merging the selected records. Please try again or check for any conflicts"
          buttonText="Close"
          opened={isErrorModalOpen}
          onClose={closeErrorModal}
          onOpen={openErrorModal}
          triggerOpen={mergeMutationStatus.isError}
        />
      </Card>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonMerginToolView,
  'is_get_person_by_id_enabled',
  true,
)
