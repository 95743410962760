import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutatePersonTagBlackCarFund = () =>
  useMutation({
    mutationFn: async (data: { personId: string; tagId: string }) => {
      const { personId, tagId } = data

      const url = `/persons/${personId}/${tagId}/relation`

      return api.patch(url)
    },
  })

export default useMutatePersonTagBlackCarFund
