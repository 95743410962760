import {
  addSelectedItemSearch,
  removeSelectedItemSearch,
  resetStoreSearch,
  setIsAllSelectedSearch,
} from 'src/v2/domains/person/models/search/assignSearch.store'
import { useAppDispatch, useAppSelector } from 'src/v2/store/hooks'

import { AssignPersonModel } from '../../models/assignPerson.model'
import { type PersonModel } from '../../models/person.model'

const UseaAssignSearchUseCase = () => {
  const dispatch = useAppDispatch()
  const { selectedItems } = useAppSelector((state) => state.search.filters)

  const handleAddRemoveSearch = (
    search: Pick<PersonModel, 'id'>['id'],
    addSearch: boolean,
  ) => {
    if (addSearch) {
      dispatch(addSelectedItemSearch(search))
    } else {
      dispatch(removeSelectedItemSearch(search))
    }
  }

  const handleSelectAllSearch = ({
    isAllSelected,
    allSearchResults,
  }: {
    isAllSelected: boolean
    allSearchResults: AssignPersonModel['filters']['selectedItems']
  }) => {
    const payload = { isAllSelected, allSearchResults }
    dispatch(setIsAllSelectedSearch(payload))
  }

  const handleClearStoreSearch = () => {
    dispatch(resetStoreSearch())
  }

  return {
    selectedItems,
    handleAddRemoveSearch,
    handleSelectAllSearch,
    handleClearStoreSearch,
  }
}

export default UseaAssignSearchUseCase
