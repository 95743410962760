import { useGetCmsBenefits } from 'src/default/hooks'
import { ICmsBenefit } from 'src/default/interfaces'
import { BenefitsAdministrationTemplate } from 'src/default/pages/Administration/BenefitsAdministration/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BenefitsAdministrationItem from './BenefitsAdministrationItem'

const sortBenefitsByOrderProp = (a: ICmsBenefit, b: ICmsBenefit) => {
  if (
    typeof a.metaData.order === 'undefined' &&
    typeof b.metaData.order === 'undefined'
  ) {
    return 0
  }

  if (typeof a.metaData.order === 'undefined') {
    return 1
  }
  if (typeof b.metaData.order === 'undefined') {
    return -1
  }

  return a.metaData.order - b.metaData.order
}

const BenefitsAdministrationPage = () => {
  const { data: benefits, isLoading, refetch } = useGetCmsBenefits()

  return (
    <BenefitsAdministrationTemplate>
      {(benefits?.data ?? [])
        .sort(sortBenefitsByOrderProp)
        .map((benefit: ICmsBenefit) => (
          <BenefitsAdministrationItem
            key={benefit.id}
            {...benefit}
            onDelete={() => refetch()}
          />
        ))}
    </BenefitsAdministrationTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationPage,
  'is_benefits_administration_view',
  true,
)
