import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

export interface GetFormsParams {
  organizationId: string
  formTemplateId?: string
  personId?: string
  limit?: number
  isReviewed?: boolean
  isCompleted?: boolean
  fromDate?: string
  toDate?: string
  orderBy?: string
  offset?: number
}

const useGetSubmissionsByOrganizationFile = (params: GetFormsParams) => {
  const {
    organizationId,
    formTemplateId,
    personId,
    limit,
    isReviewed,
    isCompleted,
    fromDate,
    toDate,
    orderBy,
  } = params

  return useQuery({
    queryKey: [
      `getFormsSubmissionByOrganizationFile`,
      organizationId,
      formTemplateId,
      personId,
      limit,
      isReviewed,
      isCompleted,
      fromDate,
      toDate,
      orderBy,
    ],
    queryFn: async ({ signal }) =>
      await api.get(`/v3/forms/file`, {
        signal,
        params: {
          limit,
          organizationId,
          formTemplateId,
          personId,
          isReviewed,
          isCompleted,
          fromDate,
          toDate,
          orderBy,
        },
      }),
  })
}

export default useGetSubmissionsByOrganizationFile
