import { FormikProps, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import useMutationGenerateEmail from 'src/benestream/hooks/person/useMutationGenerateEmail'
import {
  Button,
  Input,
  InputDateAndAge,
  InputPhone,
  SelectLanguage,
} from 'src/common/components'
import InputOptions from 'src/common/components/form/Input/InputOptions/InputOptions'
import { useDebounce } from 'src/common/hooks'
import { useSearchABCs } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import { CreateLeadValues } from '../createLeadPage.interface'
import styles from './create-lead-contact-information.module.scss'

interface CreateLeadContactInformationProps {
  formik: FormikProps<CreateLeadValues>
}

const CreateLeadContactInformation = (
  props: CreateLeadContactInformationProps,
) => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const { formik } = props

  const generateEmail = useMutationGenerateEmail()

  const [isActiveOptions, setIsActiveOptions] = useState(false)
  const [companiesOption, setCompaniesOption] = useState<
    Array<{ id: string; name: string }>
  >([])
  const [leadData, setLeadData] = useState({
    name: '',
    companyId: '',
  })
  const [searchText, setSearchText] = useState('')

  const { data } = useSearchABCs({
    name: searchText,
    organizationId: currentOrganizationId || '',
  })

  const { setFieldValue } = useFormikContext()

  const handleGenerateEmail = () => {
    generateEmail.mutateAsync().then((data) => {
      setFieldValue('email', data)
    })
  }

  const searchCompanyName = (value: string) => {
    if (!value) {
      setCompaniesOption([])
      setIsActiveOptions(false)
      setSearchText('')
    }
    setSearchText(value)
  }

  const debounceSearch = useDebounce(searchCompanyName, 600)

  const handleOnChangeCompanyName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debounceSearch(e.target.value)
    if (e.target.value !== leadData.name) {
      setLeadData((state) => ({ name: e.target.value, companyId: '' }))
      formik.setFieldValue('abcIds', leadData.companyId)
    }
  }

  const getCompanyName = (id?: string) => {
    return (
      companiesOption.find((option) => option.id === (id || leadData.companyId))
        ?.name || ''
    )
  }

  useEffect(() => {
    if (data?.data.records) {
      setCompaniesOption(data.data.records)
    }
  }, [data?.data.records])

  useEffect(() => {
    if (leadData.companyId) {
      setLeadData((state) => ({ ...state, name: getCompanyName() }))
      formik.setFieldValue('abcIds', leadData.companyId)
    }
  }, [leadData.companyId])

  return (
    <BenestreamPersonFormBlock title="Contact Information">
      <div className={styles.formContainer}>
        <Input
          required
          name="firstName"
          label="First Name"
          placeholder="Enter first name"
          tabletWidth="50"
        />

        <Input
          required
          name="lastName"
          label="Last Name"
          placeholder="Enter last name"
          tabletWidth="50"
        />

        <div className={styles.emailWrapper}>
          <Button
            onClick={handleGenerateEmail}
            className={styles.generateEmailButton}
            size="small"
          >
            Generate Email
          </Button>
          <Input
            required
            type="email"
            name="email"
            label="Email"
            placeholder="Enter email"
          />
        </div>

        <InputPhone
          required
          name="phoneNumber"
          label="Mobile Phone"
          placeholder="Enter phone"
          tabletWidth="50"
        />

        <InputPhone
          name="otherPhoneNumber"
          label="Secondary Mobile Phone"
          placeholder="Enter phone"
          tabletWidth="50"
        />

        <InputDateAndAge
          required
          name="birthDate"
          label="Date of Birth"
          tabletWidth="50"
        />

        <SelectLanguage
          required
          name="preferredLanguage"
          label="Preferred Language"
          placeholder="Select Preferred Language"
          tabletWidth="50"
        />

        <InputOptions
          required
          name="abcIds"
          label="Company Name"
          placeholder="Enter company name"
          tabletWidth="50"
          onChange={handleOnChangeCompanyName}
          selectOptions={companiesOption}
          setIsActiveOptions={setIsActiveOptions}
          onSelectOption={(value) =>
            setLeadData((state) => ({
              ...state,
              companyId: value,
              name: getCompanyName(value),
            }))
          }
          selectedValue={leadData.companyId}
          value={leadData.name}
          isActive={isActiveOptions}
        />

        <Input
          name="employeeId"
          label="Employee ID"
          placeholder="Enter employee ID"
          tabletWidth="50"
        />
      </div>
    </BenestreamPersonFormBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadContactInformation,
  'is_create_benestream_person_primary_enabled',
)
