//TODO move to DB
export const reasonsForVisit = [
  { label: 'Tickets', value: 'Tickets' },
  { label: 'Driver Holiday Party 2023', value: 'Driver Holiday Party 2023' },
  { label: 'Class', value: 'Class' },
  { label: 'Deactivation', value: 'Deactivation' },
  { label: 'Immigration', value: 'Immigration' },
  { label: 'Accident', value: 'Accident' },
  { label: 'Wellness', value: 'Wellness' },
  { label: 'Benefits', value: 'Benefits' },
  { label: '5 Star Classes', value: '5 Star Classes' },
  { label: 'Other', value: 'other' },
]

export const statusTicket = [
  { label: 'Open', value: 'Open' },
  { label: 'Resolved', value: 'Resolved' },
  { label: 'Closed', value: 'Closed' },
  { label: 'In Progress', value: 'In Progress' },
]
