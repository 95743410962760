import { useState } from 'react'

import { Button, Icons, Modal } from 'src/common/components'
import { useNotifications } from 'src/common/notification'
import { ITrigger } from 'src/default/interfaces'

import styles from './trigger-delete.module.scss'

interface TriggerDeleteProps extends ITrigger {
  className?: string
  title?: string
  description?: string | JSX.Element
  isOpen?: boolean
}

const TriggerDelete = (props: React.PropsWithChildren<TriggerDeleteProps>) => {
  const { className, name, title, description, onAction, children, isOpen } =
    props

  const [isModalOpen, setIsModalOpen] = useState(isOpen || false)
  const [isLoading, setIsLoading] = useState(false)
  const notifications = useNotifications()

  const handleRemove = async () => {
    setIsLoading(true)

    await onAction()
    setIsModalOpen(false)
    setIsLoading(false)
    notifications?.show('success', 'document was successfully deleted')
  }

  return (
    <>
      <div className={className} onClick={() => setIsModalOpen(true)}>
        {children}
      </div>

      {isModalOpen ? (
        <Modal
          id={`${name}TriggerDelete`}
          template="small"
          onClose={() => setIsModalOpen(false)}
        >
          <div className={styles.wrapper}>
            <div className={styles.icon}>
              <Icons.TrashCross />
            </div>

            {title ? <div className={styles.title}>{title}</div> : null}

            {description ? (
              <div className={styles.description}>{description}</div>
            ) : null}

            <div className={styles.buttons}>
              <Button
                disabled={isLoading}
                size="small"
                template="secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>

              <Button loading={isLoading} size="small" onClick={handleRemove}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default TriggerDelete
