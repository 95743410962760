import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IMagicLinkLoginData {
  magicLinkToken: string
}

const useMagicLinkLogin = () => {
  return useMutation({
    mutationFn: async (data: IMagicLinkLoginData) =>
      api.post('/v3/auth/magic-link/login', { ...data }),
  })
}

export default useMagicLinkLogin
