import { Select } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import { EnumPersonLanguage } from 'src/common/interfaces'

const options = Object.entries(EnumPersonLanguage).map(([value, label]) => ({
  value,
  label,
}))

const SelectLanguage = (props: IField) => {
  return <Select {...props} options={options} />
}

export default SelectLanguage
