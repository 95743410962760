import cx from 'classnames'
import { useState } from 'react'

import { BasicInput, FieldWrapper, Icons } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import styles from './input-password.module.scss'

const InputPassword = (props: IField) => {
  const { size = 'normal' } = props

  const [show, setShow] = useState<boolean>(false)

  return (
    <FieldWrapper {...props}>
      <div className={cx(styles.wrapper, styles[size])}>
        <BasicInput {...props} type={show ? 'text' : 'password'} />

        <div className={styles.button} onClick={() => setShow(!show)}>
          {!show ? <Icons.Eye /> : <Icons.EyeCrossed />}
        </div>
      </div>
    </FieldWrapper>
  )
}

export default InputPassword
