import { Text } from '@mantine/core'
import { removeUnderScoreAndTransformToLowerCase } from '@v2/commons/utils/stringManipulation'
import { getLeadStatusColor } from '@v2/domains/person/utils'
import { DataTable } from 'mantine-datatable'

import { useAssociatedInformationContainer } from '../containers/useAssociatedInformation.container'
import styles from './data-table.module.scss'

const AssociatedInformation = () => {
  const { data: datafields } = useAssociatedInformationContainer()

  const tableColumns = datafields.length
    ? Object.keys(datafields[0])
        .sort()
        .map((colHeader, idx) => ({
          accessor: colHeader,
          title: `Duplicate ${idx + 1}`,
        }))
        .filter(
          ({ accessor }) =>
            !['field'].includes(accessor) && !['total'].includes(accessor),
        )
    : []

  return (
    !!datafields.length && (
      <DataTable
        style={{ zIndex: 0 }}
        pinFirstColumn
        withColumnBorders
        withTableBorder
        idAccessor="field"
        fz="16px"
        records={datafields}
        defaultColumnProps={{
          titleClassName: styles['column-title'],
          width: '200px',
          textAlign: 'center',
          cellsStyle: ({ field }) =>
            field === 'Chat'
              ? (theme) => ({ fontStyle: 'italic', color: theme.colors.red[6] })
              : undefined,
        }}
        defaultColumnRender={(row, _idx, accessor) => {
          const data = row[accessor as keyof typeof row]

          return (
            <div>
              {Array.isArray(data) || !data
                ? data.map((value: any, idx: number) => (
                    <div key={value.name + idx}>
                      <Text
                        style={{
                          textAlign: 'left',
                          textTransform: 'capitalize',
                        }}
                      >
                        {value.name}
                        <br />
                      </Text>
                      <Text
                        style={{
                          textAlign: 'left',
                          textTransform: 'capitalize',
                        }}
                        c={getLeadStatusColor(
                          removeUnderScoreAndTransformToLowerCase(value.status),
                        )}
                      >
                        {removeUnderScoreAndTransformToLowerCase(value.status)}
                      </Text>
                    </div>
                  ))
                : (data as string)}
            </div>
          )
        }}
        columns={[
          {
            accessor: 'field',
            title: '',
            titleStyle: { backgroundColor: 'white' },
            cellsClassName: styles['column-cell-field'],
            textAlign: 'left',
          },
          {
            accessor: 'total',
            title: 'Merged Record',
            titleClassName: styles['sticky-column'],
            cellsClassName: styles['sticky-cell'],
          },
          ...tableColumns,
        ]}
      />
    )
  )
}

export default AssociatedInformation
