import { AxiosResponse } from 'axios'
import cx from 'classnames'
import { useField } from 'formik'
import { useRef, useState } from 'react'

import { FieldWrapper, Spinner } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import FileUploadEmpty from './FileUploadEmpty'
import FileUploadResult from './FileUploadResult'
import styles from './file-upload.module.scss'

interface IFileUpload extends IField {
  innerClassName?: string
  uploadFileMutation: any //TODO add ts
  EmptyComponent?: () => JSX.Element
  ResultComponent?: (() => JSX.Element) | null
  multiple?: boolean
  description?: string
  formats?: string[]
  maxSizeInMB?: number
  onUploadError?: (errorMessage: string) => void
}

const FileUpload = (props: IFileUpload) => {
  const {
    innerClassName,
    uploadFileMutation,
    EmptyComponent = FileUploadEmpty,
    ResultComponent = typeof props.ResultComponent === 'undefined'
      ? FileUploadResult
      : null,
    name,
    multiple = false,
    description = 'Click here to browse your files',
    formats,
    disabled,
    maxSizeInMB,
    onUploadError,
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [{ value }, meta, { setValue }] = useField(name)
  const [error, setError] = useState('')

  const covertMBSizeInBytes = (sizeInMB: number) => {
    return sizeInMB * 1000000
  }

  const validateFileSize = (file: File) => {
    let hasError = false

    const maxAllowedSize = (maxSizeInMB || 5) * 1024 * 1024

    if (inputRef.current) {
      if (file.size > maxAllowedSize) {
        inputRef.current.value = ''
        const error = `File size must not exceed ${maxSizeInMB}MB`
        setError(error)
        hasError = true
      }
    }

    return hasError
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!multiple) {
      const file = event.target.files?.[0]
      if (validateFileSize(file!)) {
        const errorMessage = `${formats
          ?.map((item) => item.toUpperCase())
          .join(',')}, file size must not exceed ${maxSizeInMB || 5}MB.`
        setError(errorMessage)
        onUploadError?.(errorMessage)
        return null
      }
      if (file) {
        await uploadFileMutation
          .mutateAsync({ file })
          .then((result: AxiosResponse) => {
            //TODO better way to get the image url from the response
            const imageUrl = result?.request.responseURL.split('?')[0]

            setValue(imageUrl)
          })
        props.onChange?.call(this, null)
      }
    } else {
      const files = event.target.files
      if (files) {
        let hasError = false
        for (let index = 0; index < files.length; index++) {
          if (validateFileSize(files[index]!)) {
            hasError = true
            break
          }
        }
        if (hasError) {
          const errorMessage = `${formats
            ?.map((item) => item.toUpperCase())
            .join(', ')}, file size must not exceed ${maxSizeInMB || 5}MB.`
          setError(errorMessage)
          onUploadError?.(errorMessage)
          return null
        }
        const images = await Promise.all(
          Array.from(files).map((file) =>
            uploadFileMutation
              .mutateAsync({ file })
              .then((result: AxiosResponse) => {
                //TODO better way to get the image url from the response
                const imageUrl = result?.request.responseURL.split('?')[0]
                return imageUrl
              }),
          ),
        )
        props.onChange?.call(this, null)
        if (value) {
          setValue([...value, ...images])
        } else {
          setValue([...images])
        }
      }
    }
  }

  return (
    <FieldWrapper name={name} {...rest}>
      <label
        className={cx(
          styles.wrapper,
          meta.error && styles.error,
          uploadFileMutation.isLoading && styles.loading,
          disabled && styles.disabled,
          innerClassName,
        )}
      >
        {!uploadFileMutation.isLoading ? (
          <>
            <input
              type="file"
              multiple={multiple}
              disabled={disabled}
              className={styles.input}
              onChange={handleChange}
              accept={formats?.join(',')}
              size={covertMBSizeInBytes(maxSizeInMB || 5)}
              ref={inputRef}
            />

            {!value || ResultComponent === null ? (
              <EmptyComponent
                formats={formats}
                description={description}
                errorMessage={error}
              />
            ) : (
              <>
                {ResultComponent !== null ? (
                  <ResultComponent value={value} />
                ) : null}
              </>
            )}
          </>
        ) : (
          <Spinner color="primary" size="big" />
        )}
      </label>
    </FieldWrapper>
  )
}

export default FileUpload
