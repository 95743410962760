import { useEffect, useState } from 'react'

import { BasicTemplate } from 'src/common/templates'
import { BasicTemplateProps } from 'src/common/templates/BasicTemplate'

export interface InfiniteBasicTemplateProps extends BasicTemplateProps {
  scrollPromise: () => Promise<void>
  records: { totalRecords: number; actualNumberOfRecords: number }
}

const InfiniteBasicTemplate = (
  props: React.PropsWithChildren<InfiniteBasicTemplateProps>,
) => {
  const { scrollPromise, records, ...rest } = props
  const totalRecords = records?.totalRecords || 0
  const actualNumberOfRecords = records?.actualNumberOfRecords || 0
  const [scrolled, setScrolled] = useState<boolean>(false)
  const tolerance = 5

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const handleScroll = (event: Event) => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - tolerance
        ) {
          if (!!scrollPromise) {
            setScrolled(true)
          }
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [])

  useEffect(() => {
    if (scrolled && actualNumberOfRecords < totalRecords) {
      ;(async () => {
        if (scrollPromise) {
          await scrollPromise()
        }
        setScrolled(false)
      })()
    }
  }, [scrolled, actualNumberOfRecords, totalRecords])

  return <BasicTemplate {...rest} />
}

export default InfiniteBasicTemplate
