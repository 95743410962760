import { useEffect, useState } from 'react'

import { BasicBlock, Loader, Tabs } from 'src/common/components'
import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages } from 'src/common/helpers'
import { useParamsFormik } from 'src/common/hooks'
import { IUserOrganization } from 'src/common/interfaces'
import { InfiniteBasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useGetUsers } from 'src/default/hooks'
import {
  useGetOfficeVisits,
  useMutationOfficeVisit,
} from 'src/default/hooks/office-visit'
import { ITicket } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import AssignTicketModal from './AssignTicketModal'
import OpenTicketsTable from './OpenTicketsTable'
import PastTicketsTable from './PastTicketsTable'
import styles from './tickets-page.module.scss'

const TicketsPage = () => {
  const [isOpenTickets, setIsOpenTickets] = useState<boolean>(true)

  const pastTicketsFilterFormik = useParamsFormik({
    id: 'past-tickets',
    initialValues: {
      createdDate: '',
    },
  })

  const [nextPageParam, setNextPageParam] = useState<number | null>(null)

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useGetOfficeVisits({
    status: pastTicketsFilterFormik.values.status
      ? pastTicketsFilterFormik.values.status
      : undefined,
    assignedTo: pastTicketsFilterFormik.values.assignedTo
      ? pastTicketsFilterFormik.values.assignedTo
      : undefined,
    reasonForVisit: pastTicketsFilterFormik.values.reasonForVisit
      ? pastTicketsFilterFormik.values.reasonForVisit
      : undefined,
    limit: PAGINATION_LIMIT,
    isOpenTickets,
    fromDate: pastTicketsFilterFormik.values.createdDate
      ? pastTicketsFilterFormik.values.createdDate
      : undefined,
    nextPageParam,
  })

  const [organizer, setOrganizer] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  })

  const [ticketToUpdate, setTicketToUpdate] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)
  const updateOfficeVisit = useMutationOfficeVisit()

  const usersOptions = (useGetUsers().data?.data ?? [])
    .filter(
      (user: IUserOrganization) =>
        !user.email.includes('@workersbenefitfund.com'),
    )
    .map((user: IUserOrganization) => ({
      value: user.id,
      label: user.name,
    }))

  const handlePageScroll = async () => {
    setNextPageParam(
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length, //TODO add ts
    ) //TODO ts
  }

  //TODO refactor
  useEffect(() => {
    ;(async () => {
      if (nextPageParam !== null) {
        await fetchNextPage()
      }
    })()
  }, [nextPageParam])

  const handleAssigner = (value: string, label: string, id: string) => {
    setOrganizer({ label: label, value: value })
    setTicketToUpdate(id)
    setShowModal(true)
  }

  const handleAssignTicketToUser = async () => {
    setShowModal(false)
    setShowLoader(true)

    await updateOfficeVisit
      .mutateAsync({
        id: ticketToUpdate,
        assignedToId: organizer.value,
        status: 'In Progress',
      })
      .then(() => {
        refetch()
        setShowLoader(false)
        setResult('success')
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleChangeTab = (id: string | null) => {
    setIsOpenTickets(id === 'open')
    pastTicketsFilterFormik.setValues({ createdDate: '' })
  }

  const tickets = concatQueryPages<ITicket>(pages)

  return (
    <>
      <InfiniteBasicTemplate
        title="All Tickets"
        scrollPromise={handlePageScroll}
        records={{
          totalRecords: pages?.[0]?.data.totalRecords ?? 0,
          actualNumberOfRecords: tickets?.length ?? 0,
        }}
      >
        <BasicBlock
          className={styles.wrapper}
          paddings="big"
          isLoading={pages.length === 0 || isFetchingNextPage}
        >
          {/* TODO ivnert logic of Tabs show prop */}
          <Tabs onChange={handleChangeTab} show>
            <Tabs.Item id="open" title="Open Tickets">
              <OpenTicketsTable
                isLoading={isFetching}
                tickets={tickets}
                usersOptions={usersOptions}
                onAssignTicket={handleAssigner}
              />
            </Tabs.Item>

            <Tabs.Item id="past" title="Past Tickets">
              <PastTicketsTable
                filterFormik={pastTicketsFilterFormik}
                isLoading={isFetching}
                tickets={tickets}
                total={pages?.[0]?.data.totalRecords ?? 0}
                usersOptions={usersOptions}
              />
            </Tabs.Item>
          </Tabs>
        </BasicBlock>
      </InfiniteBasicTemplate>

      {showModal ? (
        <AssignTicketModal
          isLoading={false}
          onClose={() => setShowModal(false)}
          onSubmit={handleAssignTicketToUser}
        />
      ) : null}
      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={result !== 'success' ? result : undefined}
          onClose={() => setResult(null)}
        />
      ) : null}
      <Loader isLoading={showLoader} fullScreen={true} />
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TicketsPage,
  'is_enable_tickets_view',
  true,
)
