import cx from 'classnames'
import { useState } from 'react'

import { IEligibility } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import EligibilityItem from '../EligibilityItems/EligibilityItem'
import styles from './eligibilities-list.module.scss'

interface EligibilitiesListProps {
  eligibilities: IEligibility[]
}

const EligibilitiesList = (props: EligibilitiesListProps) => {
  const { eligibilities } = props
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const items = eligibilities

  if (!items.length) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.textCenter}>No eligibilities found</div>
      </div>
    )
  }

  const itemsToDisplay = isOpened
    ? items
    : items.filter((_, index) => index < 3)

  return (
    <div className={styles.wrapper}>
      {itemsToDisplay.map((eligibility, index) => (
        <EligibilityItem
          key={`${eligibility.id}-${index}`}
          data={eligibility}
        />
      ))}

      {items.length > 3 ? (
        <div
          className={cx(styles.textCenter, styles.showButton)}
          onClick={() => setIsOpened(!isOpened)}
        >
          {isOpened ? 'Show less' : 'Show more'}
        </div>
      ) : null}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EligibilitiesList,
  'is_enable_eligibilities_list',
  true,
)
