import { BasicBlock } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { useGetUsers } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import UsersPageHeader from './UsersPageHeader'
import UsersTable from './UsersTable'

const UsersPage = () => {
  const { data: users, isLoading } = useGetUsers()

  return (
    <BasicTemplate
      title="Users"
      header={<UsersPageHeader />}
      isLoading={isLoading}
    >
      <BasicBlock paddings="big">
        <UsersTable users={users?.data ?? []} />
      </BasicBlock>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UsersPage,
  'is_enable_users_view',
  true,
)
