import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IGetOfficeVisit {
  officeVisitId: string
}

const useGetOfficeVisitById = (params: IGetOfficeVisit) => {
  const { officeVisitId } = params

  let url = `office-visit/${officeVisitId}`

  return useQuery({
    queryKey: [`getOfficeVisit`, officeVisitId],
    queryFn: async () => await api.get(url),
    refetchOnMount: 'always',
    gcTime: 0,
  })
}

export default useGetOfficeVisitById
