import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import OpportunityItem from '../OpportunityItems/OpportunityItem'

interface IOpportunityItem {
  opportunities: any
  handleOnClickOpportunity: (value: any) => void
}

const OpportunitiesList = (props: IOpportunityItem) => {
  const { opportunities, handleOnClickOpportunity } = props
  return (
    <div>
      {opportunities?.length > 0 ? (
        opportunities.map((opportunity: any, key: number) => (
          <OpportunityItem
            key={key}
            onClick={handleOnClickOpportunity}
            opportunity={opportunity}
          />
        ))
      ) : (
        <div style={{ textAlign: 'center' }}>No appointments found</div>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  OpportunitiesList,
  'is_enable_opportunities_list',
  true,
)
