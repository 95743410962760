export const PAGINATION_LIMIT = 25

export const NYC_ORGANIZATION_ID = '6966036a-12d4-4a57-a2ad-c119d797edd1'
export const DRIVERS_UNION_ID = '1f0636ef-588f-4dbd-ab55-114750af7dc2'
export const ALL_ORGANIZATION_NAMES = [
  'wbf',
  'bcf-new-york',
  'idg-illinois',
  'drivers-union',
  'demonstration-org',
  'drivers-demand-justice',
  'union360',
  'idg-connecticut',
  'idg-florida',
  'idg-state-is-not-listed',
  'idg-machinists-union',
  'idg-new-jersey',
  'benestream',
  'idg-new-york',
]
