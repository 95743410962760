import { Button, Icons } from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './success-send-email.module.scss'

const SuccessSendEmail = () => {
  const {
    setEmailBody,
    setSubject,
    setIsNewEmail,
    setIsSuccessSendEmail,
    setIsViewEmail,
    setEmailDomainSignature,
    setFromEmailId,
    setReplyEmailId,
    setAttachments,
    setFilesAttachments,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const handleBackButton = () => {
    setIsNewEmail(true)
    setIsSuccessSendEmail(false)
    setIsViewEmail(false)
    setSubject('')
    setEmailBody('')
    setEmailDomainSignature('')
    setFromEmailId('')
    setReplyEmailId('')
    setFilesAttachments([])
    setAttachments([])
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleViewMessage = () => {
    setIsNewEmail(false)
    setIsSuccessSendEmail(false)
    setIsViewEmail(true)
  }

  return (
    <div className={styles.successMessageContainer}>
      <div className={styles.imageContainer}>
        <Icons.MessageCheck />
      </div>
      <div className={styles.messageTitle}>Message Sent</div>
      <div className={styles.messageInformation}>
        Your message was sent successfully
      </div>
      <div className={styles.messageButtonContainer}>
        <Button
          onClick={handleBackButton}
          className={styles.altButton}
          size="small"
        >
          Create New Message
        </Button>
        <Button onClick={handleViewMessage} size="small">
          View Message
        </Button>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SuccessSendEmail,
  'is_enable_success_send_email',
  true,
)
