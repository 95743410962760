import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

import { GetFormsParams } from './useGetSubmissionsByOrganizationFile'

const useGetSubmissionsByOrganization = (params: GetFormsParams) => {
  const {
    organizationId,
    formTemplateId,
    personId,
    limit,
    isReviewed,
    isCompleted,
    fromDate,
    toDate,
    orderBy,
    offset,
  } = params

  return useInfiniteQuery({
    queryKey: [
      `getFormsSubmissionByOrganization`,
      organizationId,
      formTemplateId,
      personId,
      limit,
      isReviewed,
      isCompleted,
      fromDate,
      toDate,
      orderBy,
      offset,
    ],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v3/forms`, {
        signal,
        params: {
          offset: offset ?? pageParam,
          limit,
          organizationId,
          formTemplateId,
          personId,
          isReviewed,
          isCompleted,
          fromDate,
          toDate,
          orderBy,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length, //TODO ts
  })
}

export default useGetSubmissionsByOrganization
