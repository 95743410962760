import { FormErrors, GetInputProps } from '@mantine/form/lib/types'

import { IRunProcessItem } from '../models/RunProcess.model'
import useRunProcessUseCase from '../useCases/useRunProcess.usecase'

const useRunProcessContainer = (
  initialItem: IRunProcessItem,
): {
  getFormProps: GetInputProps<any>
  onSubmit: () => void
  status: string
  formErrors: FormErrors
  form: any
  isLoading: boolean
} => {
  const { getFormProps, onSubmit, status, formErrors, isLoading, form } =
    useRunProcessUseCase(initialItem)

  return {
    getFormProps,
    form,
    onSubmit,
    status,
    formErrors,
    isLoading,
  }
}

export default useRunProcessContainer
