import { useParams } from 'react-router-dom'

import { PRIVATE_ROUTES } from 'src/benestream/config'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { Table, TableLeadStatus } from 'src/common/components'
import {
  formatDate,
  getPhoneWithMask,
  removeUnderScoreAndTransformToLowerCase,
} from 'src/common/helpers'
import { getLeadStatusColor } from 'src/default/helpers'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './leads-page-table.module.scss'

interface LeadsPageTableProps {
  leads: IBenestreamPerson[]
}

const LeadsPageTable = (props: LeadsPageTableProps) => {
  const { leads } = props
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <Table className={styles.wrapper}>
      {leads.map((l: IBenestreamPerson) => (
        <Table.Row
          key={l.id}
          editable
          id={l.id}
          editLink={`/${organizationId}${PRIVATE_ROUTES.LEADS.path}/:id`}
        >
          <Table.Column className={styles.name} title="Name" showMobile>
            {`${l.firstName} ${l.lastName}`}
          </Table.Column>

          <Table.Column title="Email" width={175}>
            {l.email}
          </Table.Column>

          <Table.Column title="Mobile">
            {getPhoneWithMask(l.phoneNumber)}
          </Table.Column>

          <Table.Column title="Status" showMobile>
            <TableLeadStatus
              status={
                l?.enrollments
                  ? removeUnderScoreAndTransformToLowerCase(
                      l.enrollments[0].status,
                    )
                  : ''
              }
              color={
                l?.enrollments
                  ? getLeadStatusColor(
                      removeUnderScoreAndTransformToLowerCase(
                        l.enrollments[0].status,
                      ),
                    )
                  : ''
              }
            />
          </Table.Column>

          <Table.Column title="Company">
            {l?.enrollments &&
              l.enrollments[0].associatedBenefitContract &&
              l.enrollments[0].associatedBenefitContract.name}
          </Table.Column>

          <Table.Column title="Worksite">{l.worksite}</Table.Column>

          <Table.Column title="Source">{l.source}</Table.Column>

          <Table.Column title="Created Date">
            {formatDate(l.createdAt, 'MM/DD/YYYY')}
          </Table.Column>
        </Table.Row>
      ))}
    </Table>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  LeadsPageTable,
  'is_get_benestream_persons_enabled',
)
