import * as yup from 'yup'

import { yupPhoneValidator } from 'src/common/helpers'
import { IDG_MACHINISTS_UNION } from 'src/default/config'

const validationSchemaForMachinists = {
  firstName: yup.string().name().required(),
  lastName: yup.string().name().required(),
  email: yup.string().email().required(),
  phoneNumber: yup
    .string()
    .typeError('must be a valid phone number')
    .phone()
    .required(),
  companies: yup.array(),
  street: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  postalCode: yup.string().nullable(),
}

const validationSchemaDefault = {
  firstName: yup.string().name().required('First Name is a requierd field'),
  lastName: yup.string().name().required('Last Name is a requierd field'),
  email: yup.string().email().required('Email is a required field'),
  phoneNumber: yupPhoneValidator({
    requiredMessage: 'main phone number is required field',
  }),
}

export const getValidationSchemaByOrganizationId = (organizationId: string) => {
  if (organizationId === IDG_MACHINISTS_UNION) {
    return validationSchemaForMachinists
  }
  return validationSchemaDefault
}
