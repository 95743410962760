import cx from 'classnames'

import { Icons, Loader } from 'src/common/components'

import styles from './basic-block.module.scss'

interface BasicBlockProps {
  className?: string
  beforeTitle?: string | React.ReactNode
  header?: string | React.ReactNode
  headerType?: 'normal' | 'alert'
  headerIcon?: string
  headerClassName?: string
  title?: string | React.ReactElement
  titleClassName?: string
  paddings?: 'normal' | 'small' | 'big'
  isLoading?: boolean
  isLoadingTop?: boolean
  isShowWrapper?: boolean
}

const BasicBlock = (props: React.PropsWithChildren<BasicBlockProps>) => {
  const {
    className,
    beforeTitle,
    header,
    headerType = 'normal',
    headerIcon,
    headerClassName,
    title = '',
    titleClassName,
    paddings = 'normal',
    isLoading = false,
    isLoadingTop = false,
    children,
    isShowWrapper = true,
  } = props

  const IconComponent =
    headerIcon &&
    (Object.entries(Icons)?.find(([key, value]) => key === headerIcon)?.[1] ??
      null)

  return (
    <div className={cx(styles.wrapper, className)}>
      {beforeTitle ? beforeTitle : null}

      {title ? (
        <div
          className={cx(
            styles.header,
            headerType !== 'normal' && styles.withType,
            headerType !== 'normal' && styles[headerType],
            paddings !== 'normal' && styles[`${paddings}Paddings`],
            headerClassName,
          )}
        >
          {IconComponent ? <IconComponent /> : null}
          <span className={titleClassName}>{title}</span>

          {header ? header : null}
        </div>
      ) : null}

      <div className={cx(styles.inner, styles[paddings])}>
        {isLoadingTop && (
          <Loader isLoading={isLoadingTop} isShowWrapper={isShowWrapper} />
        )}

        {children}

        {isLoading && (
          <Loader isLoading={isLoading} isShowWrapper={isShowWrapper} />
        )}
      </div>
    </div>
  )
}

export default BasicBlock
