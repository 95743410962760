import cx from 'classnames'

import { Icons } from 'src/common/components'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useRouter } from '../../../../../helpers/navigation'
import styles from './benefits-administration-toggle-link.module.scss'

interface BenefitsAdministrationToggleLinkProps {
  benefitId?: string
}

const BenefitsAdministrationToggleLink = (
  props: BenefitsAdministrationToggleLinkProps,
) => {
  const router = useRouter()

  const { benefitId } = props

  return (
    <a
      className={cx(styles.wrapper, !benefitId && styles.opened)}
      onClick={() =>
        router.push(
          `${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}/${
            benefitId ? benefitId : ''
          }`,
        )
      }
    >
      <Icons.Plus />
    </a>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationToggleLink,
  'is_enable_benefit_administration_toggle',
  true,
)
