import { Button, Card } from '@mantine/core'
import withErrorBoundaryAndPolicyCheck from '@v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import EmptyPersons from '@v2/commons/components/EmptyBlock/EmptyPersons.component'

import { Icons } from 'src/common/components'
import { type TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import InfiniteScrollTemplate from 'src/v2/commons/templates/InfiniteScrollTemplate'

import EmailTemplateFilter from '../../components/EmailTemplateFilters/EmailTemplateFilters.component'
import { EmailTemplateList } from '../../components/EmailTemplateList/EmailTemplateList.component'
import getEmailTemplatesContainer from '../../containers/getEmailTemplates.container'

const Filters = withErrorBoundaryAndPolicyCheck(
  EmailTemplateFilter,
  'is_enable_email_template_filter',
  true,
)

const Table = withErrorBoundaryAndPolicyCheck(
  EmailTemplateList,
  'is_enable_email_template_table',
  true,
)

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Email Templates', href: '/' },
]

const EmailTemplateView = () => {
  const {
    isLoading,
    isSuccess,
    handleDateRangeChange,
    templates,
    clearAllFilters,
    handlePaginate,
    filterForm,
    totalRecords,
    handleEditEmailTemplate,
    handleDeleteEmailTemplate,
    openConfirmModalToDelete,
    setDisplayDeleteModal,
    displayDeleteModal,
    handleCreateEmailTemplate,
    usersOptions,
  } = getEmailTemplatesContainer()

  return (
    <InfiniteScrollTemplate
      isLoading={isLoading}
      title={'Email Tempaltes'}
      header={
        <Button
          variant="filled"
          leftSection={<Icons.Plus />}
          onClick={handleCreateEmailTemplate}
        >
          Create New Emai Template
        </Button>
      }
      breadCrumbItems={breadCrumbItems}
      onScroll={handlePaginate}
      records={{
        totalRecords: totalRecords,
        actualNumberOfRecords: templates?.length ?? 0,
      }}
    >
      <Card shadow="lg" radius="md" style={{ padding: '36px' }}>
        <Filters
          usersOptions={usersOptions}
          emailTemplateFiltersForm={filterForm}
          handleDateRangeChange={handleDateRangeChange}
          clearAllFilters={clearAllFilters}
          totalRecords={templates?.length ?? 0}
        />
        {templates?.length > 0 && !isLoading ? (
          <Table
            onEdit={handleEditEmailTemplate}
            openDeleteModal={openConfirmModalToDelete}
            deleteEmailTemplate={handleDeleteEmailTemplate}
            templates={templates}
            isLoading={isLoading}
            displayDeleteModal={displayDeleteModal}
            setDisplayDeleteModal={setDisplayDeleteModal}
          />
        ) : (
          templates?.length === 0 && <EmptyPersons term="Email Templates" />
        )}
      </Card>
    </InfiniteScrollTemplate>
  )
}

export default EmailTemplateView
