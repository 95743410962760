import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetDependentPersonIncomesParams {
  dependentPersonId?: string
}

const useGetDependentPersonIncomes = (
  params: GetDependentPersonIncomesParams,
) => {
  const { dependentPersonId } = params

  return useQuery({
    queryKey: ['getBenestreamDependentPersonIncomes', dependentPersonId],
    queryFn: async () =>
      await api.get(
        `/benestream/persons/income/dependent/${dependentPersonId}/`,
      ),
  })
}

export default useGetDependentPersonIncomes
