import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { WashingtonStateRecord } from 'src/common/interfaces/washington-state/washington-state'

const useUpdateWashingtonStateRecord = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: Partial<WashingtonStateRecord>) =>
      api.patch(`/v1/washington-state/${data.id}`, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [`useGetWashingtonStateRecord`, response.data?.id],
      })
    },
  })
}

export default useUpdateWashingtonStateRecord
