import { ComboboxItem } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'

import { PolicyModel } from '../../policy/models/policy.model'
import {
  useGetRolesQuery,
  useUpdateRolePolicyMutation,
} from '../models/role.api'
import { IRolesResponse, RoleModel } from '../models/role.model'

const useRolesUseCase = (): IRolesResponse => {
  const { data: dataRoles, isLoading, refetch } = useGetRolesQuery()

  const [membershipRole, setMembershipRole] = useState<
    ComboboxItem | undefined
  >()

  const [activePolicies, setActivePolicies] = useState<string[]>()
  const [updateRolePolicy, updateRoleMutationStatus] =
    useUpdateRolePolicyMutation()

  const handleOnSelectChange = (
    _value: string | null,
    option: ComboboxItem | undefined,
  ) => {
    const selectedRole = dataRoles?.records.find(
      (role: RoleModel) => role.id === option?.value,
    )

    if (selectedRole && selectedRole.policies) {
      setMembershipRole(option)
      setActivePolicies(
        selectedRole.policies.map((policy: PolicyModel) => policy.id),
      )
    } else {
      setActivePolicies([])
    }
  }

  const handleOnCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    policyId: string,
  ) => {
    const action = event.target.checked ? 'attach' : 'detach'
    updateRolePolicy({
      action,
      roleId: membershipRole ? membershipRole.value : '',
      policyId: policyId,
    })
      .then((result) => {
        setActivePolicies((prevActivePolicies: string[] | undefined) =>
          event.target.checked
            ? prevActivePolicies?.filter((id: string) => id !== policyId)
            : [...prevActivePolicies!, policyId],
        )
        notifications.show({
          title: 'Success',
          message: 'Role Policy succesfuly changed',
        })
        refetch()
      })
      .catch((error: Error) => {
        notifications.show({
          title: 'Oops',
          message: 'There was an error',
          color: 'red',
        })
      })
  }

  return {
    data: dataRoles ? dataRoles.records : [],
    isLoading,
    membershipRole,
    activePolicies,
    handleOnSelectChange,
    handleOnCheckBoxChange,
    updateRoleMutationStatus: updateRoleMutationStatus.status,
  }
}

export default useRolesUseCase
