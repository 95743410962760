import { useEffect, useState } from 'react'

import { Select } from 'src/common/components'
import { useGetFormsByOrganization } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../../v2/store/hooks'

const SubmissionsFilterByName = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''
  const { data: forms, isLoading } = useGetFormsByOrganization({
    organizationId: currentOrganizationId ?? '',
  })
  const [options, setOptions] = useState<any[]>([])

  useEffect(() => {
    if (!isLoading && forms) {
      setOptions(
        forms.data.records.map((form: any) => ({
          value: form.id,
          label: form.name,
        })),
      )
    }
  }, [forms, isLoading])

  return (
    <Select
      name="formTemplateId"
      placeholder="Form Name"
      options={options}
      withError={false}
      template="filter"
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SubmissionsFilterByName,
  'is_enable_submissions_filter_by_name',
  true,
)
