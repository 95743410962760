import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setUserProfile } from '@v2/domains/user/models/user.store'

import { AssignPersonModel } from '../assignPerson.model'

const initialState: AssignPersonModel = {
  filters: {
    selectedItems: [],
  },
}

const leadStore = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    addSelectedItemLead: (
      state,
      action: PayloadAction<AssignPersonModel['filters']['selectedItems'][0]>,
    ) => {
      state.filters.selectedItems = [
        ...state.filters.selectedItems,
        action.payload!,
      ]
    },
    removeSelectedItemLead: (
      state,
      action: PayloadAction<AssignPersonModel['filters']['selectedItems'][0]>,
    ) => {
      state.filters.selectedItems = state.filters.selectedItems.filter(
        (item) => item !== action.payload!,
      )
    },
    removeSelectedItemLeads: (
      state,
      action: PayloadAction<AssignPersonModel['filters']['selectedItems'][0]>,
    ) => {
      state.filters.selectedItems = state.filters.selectedItems.filter(
        (item) => item !== action.payload!,
      )
    },
    setIsAllSelectedLeads: (
      state,
      action: PayloadAction<{
        isAllSelected: boolean
        allSelectedPersons: AssignPersonModel['filters']['selectedItems']
      }>,
    ) => {
      if (action.payload.isAllSelected) {
        const uniqueSelected = new Set([
          ...state.filters.selectedItems,
          ...(action.payload.allSelectedPersons as string[]),
        ])
        state.filters.selectedItems = Array.from(uniqueSelected)
        return
      }
      const filteredSelected = state.filters.selectedItems.filter(
        (item) => !action.payload.allSelectedPersons.includes(item),
      )
      state.filters.selectedItems = filteredSelected
    },
    resetLeadStoreLead: (state) => {
      state.filters = { ...initialState.filters }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserProfile, (state) => {
      state.filters = { ...initialState.filters }
    })
  },
})

export const {
  addSelectedItemLead,
  removeSelectedItemLead,
  resetLeadStoreLead,
  setIsAllSelectedLeads,
} = leadStore.actions

export default leadStore.reducer
