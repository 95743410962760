import { Card, Flex, Loader } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import withErrorBoundaryAndPolicyCheck from '@v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import EmptyPersons from '@v2/commons/components/EmptyBlock/EmptyPersons.component'
import PersonTableFilter from '@v2/domains/person/components/PersonsFilterTable/PersonsFilterTable.component'
import PersonsTable from '@v2/domains/person/components/Tables/PersonsTable/PersonsTable.component'
import { useMemo } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import { type TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import SuccessErrorModal from 'src/v2/commons/components/SuccessErrorModal/SuccessErrorModal.component'
import InfiniteScrollTemplate from 'src/v2/commons/templates/InfiniteScrollTemplate'
import useAccountsContainer from 'src/v2/domains/person/containers/accounts/useAccounts.container'
import AssignTaskModal from 'src/v2/domains/task/views/AssignTaskModal.view'
import FloatingTasksCard from 'src/v2/domains/task/views/FloatingTaskCard.view'

import styles from '../table-view.module.scss'

const Filters = withErrorBoundaryAndPolicyCheck(
  PersonTableFilter,
  'is_get_persons_enabled_filter',
)

const Table = withErrorBoundaryAndPolicyCheck(
  PersonsTable,
  'is_enable_accounts_table',
  true,
)

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Accounts' },
]

const AccountsTableView = () => {
  const {
    isLoading,
    isFetching,
    isSuccess,
    handleDateRangeChange,
    data,
    clearAllFilters,
    handlePaginate,
    personsFiltersForm,
    handleAddRemoveAccount,
    isProgrammaticFormUpdateRef,
    selectedItems,
    handleSelectAllAccounts,
    handleClearStoreAccount,
    personsIds,
    isAssignMultipleTasksEnabled,
  } = useAccountsContainer()

  const isAllItemsSelected = useMemo(
    () =>
      data?.records
        .map((item) => item.id)
        .every((selectedItem) => selectedItems.includes(selectedItem)) ?? false,
    [data?.records, selectedItems],
  )

  const openFloatingMenu =
    !!data?.records.length && (isAllItemsSelected || !!selectedItems.length)
  const [
    assignTaskModalOpen,
    { open: openAssignTaskModal, close: closeAssignTaskModal },
  ] = useDisclosure(false)
  const [
    successTaskModalOpen,
    { open: openSuccessAssignTaskModal, close: closeSuccessAssignTaskModal },
  ] = useDisclosure(false)

  const clearAccountStore = () => {
    handleClearStoreAccount()
  }

  return (
    <InfiniteScrollTemplate
      isLoading={isLoading}
      title={'Accounts'}
      breadCrumbItems={breadCrumbItems}
      onScroll={handlePaginate}
      records={{
        totalRecords: data?.totalRecords || 0,
        actualNumberOfRecords: data?.records?.length ?? 0,
      }}
    >
      <Card shadow="lg" radius="lg" className={styles['default-card']}>
        <Filters
          personsFiltersForm={personsFiltersForm}
          handleDateRangeChange={handleDateRangeChange}
          clearAllFilters={clearAllFilters}
          handleClearStorePerson={handleClearStoreAccount}
          isProgrammaticFormUpdateRef={isProgrammaticFormUpdateRef}
          totalRecords={data?.totalRecords ?? 0}
        />
        {!!data?.records?.length ? (
          <Table
            personsIds={personsIds}
            page="accounts"
            persons={data?.records}
            getFilters={personsFiltersForm.getValues}
            handleAddRemovePerson={handleAddRemoveAccount}
            handleSelectAllPersons={handleSelectAllAccounts}
            isAllSelected={isAllItemsSelected}
            selectedItems={selectedItems}
          />
        ) : (
          isSuccess && <EmptyPersons term="account" />
        )}
        {isFetching && !isLoading && (
          <Flex mt="30px" mb="20px" justify="center">
            <Loader size={30} />
          </Flex>
        )}
        {openFloatingMenu && (
          <FloatingTasksCard
            location="account"
            selectedItems={selectedItems}
            openAssignTaskModal={openAssignTaskModal}
            isMergingToolActive={true}
            isAssignMultipleTasksEnabled={isAssignMultipleTasksEnabled}
            onDeselect={clearAccountStore}
          />
        )}
        {assignTaskModalOpen && (
          <AssignTaskModal
            isOpen={assignTaskModalOpen}
            close={closeAssignTaskModal}
            selectedItems={selectedItems}
            pageFilters={personsFiltersForm.getValues()}
            clearStore={clearAccountStore}
            openSuccessAssignTaskModal={openSuccessAssignTaskModal}
          />
        )}
        {successTaskModalOpen && (
          <SuccessErrorModal
            status="success"
            title="Tasks created successfully"
            subTitle="Tasks were created and assigned correctly."
            buttonText="Review Tasks"
            opened={successTaskModalOpen}
            onClose={closeSuccessAssignTaskModal}
            path={PRIVATE_ROUTES.TASKS.path}
          />
        )}
      </Card>
    </InfiniteScrollTemplate>
  )
}

export default AccountsTableView
