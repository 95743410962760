import { useFormik } from 'formik'

import { Form, Select } from 'src/common/components'
import { IOption } from 'src/common/interfaces'

interface OpenTicketsTableSelectAssignProps {
  name: string
  initialValue?: string
  options: IOption[]
  onChange: (value: string, label: string) => void
}

const OpenTicketsTableSelectAssign = (
  props: OpenTicketsTableSelectAssignProps,
) => {
  const { name, initialValue, options, onChange } = props

  const formik = useFormik({
    initialValues: {
      [name]: initialValue,
    },
    onSubmit: (values) => {
      onChange(
        values?.[name] ?? '',
        options?.find((o) => o.value === values[name])?.label ?? '',
      )
    },
  })

  const handleChange = async (newValue: string) => {
    await formik.setFieldValue(name, newValue)

    formik.submitForm()
  }

  return (
    <Form providerValue={formik}>
      <Select
        name={name}
        options={options}
        placeholder="Select IDG Steward"
        onChange={handleChange}
        withError={false}
      />
    </Form>
  )
}

export default OpenTicketsTableSelectAssign
