import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CommunicationModel } from './communication.model'

const initialState: CommunicationModel = {
  newCommunication: false,
}

const communicationStore = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    setNewCommunication: (state, action: PayloadAction<boolean>) => {
      state.newCommunication = action.payload
    },
  },
})

export const { setNewCommunication } = communicationStore.actions

export default communicationStore.reducer
