import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateSendMetroplus = () =>
  useMutation({
    mutationFn: async (data: { personId: string; email: string }) =>
      await api.post(
        `/metro-plus/send-person-metroplus/${data.personId}?repEmail=${data.email}`,
      ),
  })

export default useMutateSendMetroplus
