// IEnrollmentAddModal
import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Form,
  Modal,
  Select,
  SubmitButton,
} from 'src/common/components'
import { IModal } from 'src/common/interfaces'
import {
  useGetCatalogAbcsByCurrentUser,
  useMutatePersonEnrollment,
} from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../../v2/store/hooks'
import ModalResultConvertLead from '../_components/ModalResultConvertLead/ModalResultEnrollments'
import styles from './enrollment-add-modal.module.scss'

interface IEnrollmentAddModal extends IModal {
  refetchEnrollments: () => Promise<any>
}
const DRIVERS_UNION_ID = '1f0636ef-588f-4dbd-ab55-114750af7dc2'

const EnrollmentAddModal = (props: IEnrollmentAddModal) => {
  const { onClose, refetchEnrollments } = props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const [result, setResult] = useState<string | null>(null)
  const [errorForModal, setErrorForModal] = useState<string | null>(null)

  const { personId } = useParams<{ personId: string }>()

  const personEnrollmentMutation = useMutatePersonEnrollment('create')

  const formik = useFormik({
    initialValues: {
      enrollment: '',
    },
    onSubmit: async (values) => {
      await personEnrollmentMutation
        .mutateAsync({
          isVerified: false,
          associatedBenefitContractId: values.enrollment,
          personId,
        })
        .then(() => {
          if (onClose) {
            refetchEnrollments()
            setResult('success')
          }
        })
        .catch((error: any) => {
          setResult('error')
          setErrorForModal(error.response?.data?.message)
        })
    },
  })

  const catalogAbcs = useGetCatalogAbcsByCurrentUser()

  const options =
    (catalogAbcs?.data?.data ?? [])
      .map((abc: { id: string; name: string }) => ({
        label: abc.name || '',
        value: abc.id || '',
      }))
      .filter((abc: { value: string; label: string }) =>
        currentOrganizationId !== DRIVERS_UNION_ID ||
        abc.value === '53324e4a-4368-4a52-bf2d-2ebcf7d0d498'
          ? abc
          : undefined,
      ) ?? []

  const handleCloseResult = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal id="EnrollmentAddModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>New Enrollment</div>

        <div className={styles.inner}>
          <div className={styles.block}>
            <Select
              required
              name="enrollment"
              label="Enrollment"
              placeholder="Select an Option"
              options={options}
              optionsWrapperClassName={styles.selectOptions}
            />
          </div>

          <div className={styles.footer}>
            <Button
              className={styles.cancelButton}
              template="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <SubmitButton
              className={styles.submitButton}
              iconAfter="ArrowSelect"
            >
              Save
            </SubmitButton>
          </div>
        </div>
      </Form>

      {result !== null ? (
        <ModalResultConvertLead
          isSuccess={result === 'success'}
          title={
            result === 'success'
              ? 'Enrollment Added!'
              : 'Unable to add Enrollment!'
          }
          description={
            result === 'success' ? (
              <>
                The New Enrollment for{' '}
                <strong>
                  {
                    options.filter(
                      (option: any) =>
                        option.value === formik.values.enrollment,
                    )[0].label
                  }
                </strong>{' '}
                was added successfully.
              </>
            ) : (
              <>{errorForModal}</>
            )
          }
          onClose={handleCloseResult}
        />
      ) : null}
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  EnrollmentAddModal,
  'is_create_enrollment_enabled',
)
