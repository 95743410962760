import cx from 'classnames'
import { useEffect, useState } from 'react'

import { useGooglePlacesApi } from 'src/common/hooks'

import styles from './google-map.module.scss'

interface GoogleMapProps {
  className?: string
  id: string
  defaultPlace?: string
  location?: google.maps.LatLng
  onLocationChange?: (location: google.maps.LatLng | undefined) => void
}

const GoogleMap = (props: GoogleMapProps) => {
  const { className, id, defaultPlace, location, onLocationChange } = props

  const googlePlacesApi = useGooglePlacesApi()

  const [googleMap, setGoogleMap] = useState<
    google.maps.Map | null | undefined
  >(undefined)
  const [marker, setMarker] = useState<google.maps.Marker | undefined>(
    undefined,
  )

  useEffect(() => {
    if (defaultPlace && onLocationChange) {
      ;(async () => {
        const currentPrediction = await googlePlacesApi
          ?.findPlace(defaultPlace)
          .then((results) => {
            return results?.predictions?.length
              ? results.predictions[0].place_id
              : null
          })

        if (currentPrediction) {
          googlePlacesApi?.getPlaceById(currentPrediction, (result) => {
            const currentLocation = result?.geometry?.location ?? undefined
            onLocationChange(currentLocation)
          })
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (location) {
      let currentMap = googleMap
      let currentMarker = marker

      if (!currentMap) {
        currentMap = googlePlacesApi?.createMap(id)
        setGoogleMap(currentMap || null)

        if (currentMap) {
          currentMarker = new google.maps.Marker({
            position: {
              lat: 0,
              lng: 0,
            },
          })
          currentMarker.setMap(currentMap)
          setMarker(currentMarker)
        }
      }

      currentMarker?.setPosition(location)
      currentMap?.setCenter(location)
    }
  }, [location])

  return <div className={cx(styles.wrapper, className)} id={id} />
}

export default GoogleMap
