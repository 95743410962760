const Theme = () => {
  return (
    <style>
      {`
            :root {
                --black-color: #000000;
                --white-color: #FFFFFF;

                --blue-50-color: #2649FF;
                --blue-40-color: #3C5BFF;
                --blue-30-color: #516DFF;
                --blue-20-color: #7D92FF;
                --blue-10-color: #92A4FF;
                --blue-00-color: #D4DBFF;

                --dark-blue-50-color: #031331;
                --dark-blue-40-color: #364971;
                --dark-blue-30-color: #566C9C;
                --dark-blue-20-color: #7A90C0;
                --dark-blue-10-color: #A1B4DD;
                --dark-blue-00-color: #D4E0FB;

                --red-50-color: #E53E3E;
                --red-00-color: #EBB9B9;

                --green-50-color: #00D564;
                --green-00-color: #CCF7E0;

                --orange-50-color: #F7B055;
                --orange-00-color: #F7B05533;

                --gray-60-color: #21252C;
                --gray-50-color: #444955;
                --gray-40-color: #8A909E;
                --gray-30-color: #BBC2D3;
                --gray-20-color: #C3CBDD;
                --gray-10-color: #F4F4F4;
                --gray-00-color: #FCFDFF;
                --gray-light-border-color: rgba(102, 102, 102, 0.08);

                --status-gray-color: #C3CBDD;
                --status-orange-color: #F7B055;
                --status-red-color: #E34A30;
                --status-green-color: #1DC39A;
                --status-blue-color: #63b9ff;

                --status-blue-background-color: rgba(212, 219, 255, 0.7);
                --status-light-blue-background-color: rgba(212, 219, 255, 0.3);
                --status-orange-background-color: rgba(247, 176, 85, 0.2);
                --red-00-color-opacity: #EBB9B9B2;
                --status-green-background-color: #ccf7e0;
                --status-red-background-color: #ebb9b9;
            }

            body {
                color: var(--dark-blue-50-color);
            }
        `}
    </style>
  )
}

export default Theme
