import { Button, Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './assign-ticket-modal.module.scss'

interface AssignTicketToUserModalProps extends IModal {
  isLoading?: boolean
  onSubmit: () => void
}

const AssignTicketToUserModal = (props: AssignTicketToUserModalProps) => {
  const { isLoading, onClose, onSubmit } = props
  return (
    <Modal id="assignTicket" template="small" onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.Check />
        </div>

        <div className={styles.title}>Confirm Assign Ticket</div>

        <div className={styles.subtitle}>
          Are you sure you want to assign this ticket?
        </div>

        <div className={styles.buttons}>
          <Button
            disabled={isLoading}
            size="small"
            template="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button loading={isLoading} size="small" onClick={onSubmit}>
            Assign Ticket
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AssignTicketToUserModal,
  'is_enable_assign_ticket_to_user',
  true,
)
