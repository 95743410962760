import cx from 'classnames'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill-new'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Button,
  Form,
  Icons,
  Input,
  Select,
  Table,
} from 'src/common/components'
import { IOrganization, IOrganizationMembership } from 'src/common/interfaces'
import { yup } from 'src/common/libs'
import { PRIVATE_ROUTES } from 'src/default/config'
import {
  useGetEmailDomains,
  useGetMembershipDomain,
  useGetUserV2,
  useMutateMembershipEmailDomain,
  useMutationDeleteMembershipDomain,
} from 'src/default/hooks'
import { MembershipEmailDomainType } from 'src/default/hooks/membership/useMutateMembershipEmailDomain'
import {
  IEmailDomain,
  MembershipEmailDomainUser,
} from 'src/v2/commons/interface/membershipEmailDomain'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './user-email-domains.module.scss'

interface IUserEmailDomains {
  firstName: string
  lastName: string
}

const UserEmailDomains = (props: IUserEmailDomains) => {
  const { userId } = useParams<{ userId: string }>()
  const { firstName, lastName } = props
  const { data: { data: userInformation } = { data: {} }, isSuccess } =
    useGetUserV2({ userId: userId || '' })

  const {
    data: { data: emailDomainsList } = { data: {} },
    isSuccess: isSuccessEmailDomainList,
  } = useGetEmailDomains()

  const mutationMembershipEmailDomain = useMutateMembershipEmailDomain()
  const mutationDeleteMembershipEmailDomain =
    useMutationDeleteMembershipDomain()

  const handleChangeWYSWYG = (value: string) => {
    formik.setFieldValue('signatureTemplate', value)
  }

  const formik = useFormik({
    initialValues: {
      organizationId: '',
      membershipId: '',
      emailDomainId: '',
      name: '',
      type: '',
      signatureTemplate: '',
    },
    onSubmit: async (values) => {
      await mutationMembershipEmailDomain
        .mutateAsync({
          membershipId: values.membershipId,
          emailDomainId: values.emailDomainId,
          type: MembershipEmailDomainType[
            values.type as keyof typeof MembershipEmailDomainType
          ],
          signatureTemplate: values.signatureTemplate,
          name: values.name,
        })
        .then(() => {
          refetchGetMembershipDomain()
        })
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      organizationId: yup.string().required('Organization is required'),
      membershipId: yup.string().required('Membership is required'),
      emailDomainId: yup.string().required('Email Domain is required'),
      type: yup.string().required('Type Email Domain is required'),
    }),
  })

  const {
    data: { data: membershipDomains } = { data: {} },
    isSuccess: isSuccessMembershipDomain,
    refetch: refetchGetMembershipDomain,
  } = useGetMembershipDomain({ membershipId: formik.values.membershipId })

  const [organizationOptions, setOrganizatinOption] = useState<
    Array<{ label: string; value: string }>
  >([{ label: '', value: '' }])
  const [membershipRole, setMembershipRole] = useState<
    Array<{ label: string; value: string }>
  >([{ label: '', value: '' }])
  const [emailDomains, setEmailDomains] = useState<
    Array<{ label: string; value: string }>
  >([{ label: '', value: '' }])
  const [showEmailDomainContainer, setShowEmailDomainContainer] =
    useState(false)

  useEffect(() => {
    if (isSuccessEmailDomainList) {
      setEmailDomains(
        emailDomainsList.map((domain: IEmailDomain) => ({
          label: `${domain.domain}`,
          signatureTemplate: '',
          value: domain.id,
        })),
      )
    }
  }, [isSuccessEmailDomainList])

  useEffect(() => {
    if (isSuccess) {
      const organizationCatalg = userInformation.organizations.map(
        (organization: IOrganization) => ({
          label: organization.name,
          value: organization.id,
        }),
      )
      setOrganizatinOption(organizationCatalg)
    }
  }, [isSuccess])

  useEffect(() => {
    if (formik.values.organizationId) {
      const getSelectOrganization = userInformation.organizations.filter(
        (organization: IOrganization) =>
          organization.id === formik.values.organizationId,
      )[0]
      setMembershipRole(
        getSelectOrganization.memberships.map(
          (membership: IOrganizationMembership) => ({
            label: membership.role,
            value: membership.id,
          }),
        ),
      )
      formik.setFieldValue('membershipId', '')
    }
  }, [formik.values.organizationId])

  const handleAddMoreEmailDomains = () => {
    setShowEmailDomainContainer(true)
  }

  const handleCancelAddEmailDomain = () => {
    setShowEmailDomainContainer(false)
  }

  const typeEmailDomain = [
    { label: 'Send', value: 'SEND' },
    { label: 'Reply', value: 'REPLY' },
    { label: 'Both', value: 'BOTH' },
  ]

  const handleDeleteMemberhipDomain = (membershipDomainId: string) => {
    mutationDeleteMembershipEmailDomain
      .mutateAsync(membershipDomainId)
      .then(() => {
        refetchGetMembershipDomain()
      })
  }

  return (
    <BasicBlock
      title={`${firstName} ${lastName}`}
      header={
        <Button
          to={PRIVATE_ROUTES.EMAIL_DOMAINS.path}
          size="small"
          className={styles.buttonHeader}
        >
          Email Domains
        </Button>
      }
    >
      {isSuccess && (
        <Form providerValue={formik}>
          <div className={styles.wrapperForm}>
            <Select
              width="50"
              label="Organization"
              name="organizationId"
              options={organizationOptions}
              placeholder="Select one organization"
            />
            <Select
              width="50"
              label="Membership Role"
              name="membershipId"
              options={membershipRole}
              placeholder="Select one membership role"
            />
            {!showEmailDomainContainer && (
              <PolicyCheckHOC policyName="is_update_membership_email_domain_enabled">
                <Button
                  size="small"
                  className={styles.widthAuto}
                  onClick={handleAddMoreEmailDomains}
                >
                  Add Email Domain to this Membership Role
                </Button>
              </PolicyCheckHOC>
            )}
            {showEmailDomainContainer && (
              <>
                <Select
                  width="50"
                  label="Email Address - Domain"
                  name="emailDomainId"
                  options={emailDomains}
                  placeholder="Select one email domain"
                />
                <Select
                  width="50"
                  label="Type Email Domain"
                  name="type"
                  options={typeEmailDomain}
                  placeholder="Select one type email domain"
                />

                <Input width="50" name="name" label="Name" required />

                <div className={styles.wrapperReactQuill}>
                  <label htmlFor="signatureTemplate">
                    Signature Template <span>*</span>
                  </label>

                  <ReactQuill
                    className={styles.wrapper}
                    placeholder="Your Signature..."
                    tabIndex={10}
                    value={formik.values.signatureTemplate}
                    onChange={handleChangeWYSWYG}
                  />
                  <div className={styles.errorComment}>
                    {formik?.errors?.signatureTemplate}
                  </div>
                </div>

                <PolicyCheckHOC policyName="is_create_email_domain_enabled">
                  <div className={styles.buttonsWrapper}>
                    <Button
                      className={styles.cancelButton}
                      size="small"
                      onClick={handleCancelAddEmailDomain}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      onClick={() => formik.submitForm()}
                    >
                      Save
                    </Button>
                  </div>
                </PolicyCheckHOC>
              </>
            )}
          </div>
        </Form>
      )}
      {formik.values.membershipId && isSuccessMembershipDomain && (
        <div className={styles.wrapperMembershipDomainList}>
          {membershipDomains.length > 0 ? (
            <Table>
              {membershipDomains.map(
                (membershipEmailDomain: MembershipEmailDomainUser) => (
                  <Table.Row
                    key={membershipEmailDomain.id}
                    id={membershipEmailDomain.id}
                  >
                    <Table.Column title="Email/Domain">
                      {membershipEmailDomain.emailDomain.domain}
                    </Table.Column>

                    <Table.Column title="Name/Title">
                      {membershipEmailDomain.name}
                    </Table.Column>

                    <Table.Column title="Direction">
                      {
                        typeEmailDomain.filter(
                          (emailDomain) =>
                            emailDomain.value === membershipEmailDomain.type,
                        )[0].label
                      }
                    </Table.Column>

                    <Table.Column title="Is Register">
                      <span
                        className={cx(
                          membershipEmailDomain.emailDomain.isWBFRegister &&
                            styles.checked,
                          styles.checkbox,
                        )}
                      >
                        <Icons.Check />
                      </span>
                    </Table.Column>

                    <PolicyCheckHOC policyName="is_enable_delete_email_domain">
                      <Table.Column title="Actions">
                        <span
                          className={styles.iconActions}
                          onClick={() =>
                            handleDeleteMemberhipDomain(
                              membershipEmailDomain.id,
                            )
                          }
                        >
                          <Icons.Trash />
                        </span>
                      </Table.Column>
                    </PolicyCheckHOC>
                  </Table.Row>
                ),
              )}
            </Table>
          ) : (
            <>No Records</>
          )}
        </div>
      )}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserEmailDomains,
  'is_enable_user_email_domains',
  true,
)
