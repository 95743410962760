import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { BasicBlock } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { useGetEligibilities } from 'src/common/hooks'
//TODO move to common
import { ModalResult } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import OpportunitiesModal from './OpportunitiesModal'
import { OPPORTUNITY_STATUS } from './opportunities.enum'
import { Opportunity } from './opportunities.interface'
import styles from './opportunities.module.scss'

const Opportunities = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const [result, setResult] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity>()

  const { personId } = useParams<{ personId: string }>()

  const { data, refetch } = useGetEligibilities({
    personId,
    organizationId: currentOrganizationId!,
  })

  const opportunities = data?.data?.records || []

  const editOpportunity = (record: Opportunity) => {
    setModalIsOpen(true)
    setSelectedOpportunity(record)
  }

  const handleSetResult = (resultValue: string, isError: boolean) => {
    setResult(resultValue)
    setIsError(isError)
    setModalIsOpen(false)
    refetch()
  }

  return (
    <BasicBlock
      className={styles.opportunitiesWrapper}
      title="Opportunities"
      paddings="small"
      isLoading={false}
      isShowWrapper={false}
    >
      <PolicyCheckHOC
        policyName="is_enable_get_benestream_opportunities"
        disablePolicyCheck
      >
        {opportunities.length ? (
          opportunities.map((record: Opportunity, index: number) => (
            <div
              className={styles.opportunityItem}
              onClick={() => editOpportunity(record)}
              key={index}
            >
              <div className={styles.benefitName}>
                {record.benefit?.name ?? ''}
              </div>
              <div className={styles.benefitStateText}>
                Status:{' '}
                <span className={styles.benefitDescriptionText}>
                  {OPPORTUNITY_STATUS.find(
                    (item) => item.value === record.beneStreamStatus,
                  )?.label || record.beneStreamStatus}
                </span>
              </div>
              <div className={styles.benefitStateText}>
                Last Modified Date:{' '}
                <span className={styles.benefitDescriptionText}>
                  {formatDate(record.updatedAt, 'MM/DD/YYYY')}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.emptyStateText}>No Records found</div>
        )}
      </PolicyCheckHOC>

      {modalIsOpen ? (
        <OpportunitiesModal
          onCloseModal={() => setModalIsOpen(false)}
          opportunity={selectedOpportunity as Opportunity}
          setResult={handleSetResult}
        />
      ) : null}
      {result ? (
        <ModalResult
          isSuccess={result === 'success'}
          title={isError ? 'Error!' : undefined}
          description={result === 'success' ? undefined : result}
          onClose={() => setResult('')}
        />
      ) : null}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Opportunities,
  'is_enable_benestream_opportunities',
  true,
)
