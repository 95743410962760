import {
  type IMutateTasksData,
  ItaskModalForm,
} from '@v2/domains/task/models/task.model'
import { rootApi } from '@v2/store/api/rootApi'

export const tasksApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    assignTasks: builder.mutation<{ OK: boolean }, IMutateTasksData>({
      query: (queryArgs) => ({
        url: 'v4/tasks/multiple',
        method: 'POST',
        params: queryArgs.params,
        data: queryArgs.body,
      }),
    }),
    assignTask: builder.mutation<{ OK: boolean }, ItaskModalForm>({
      query: (queryBody) => ({
        url: 'v3/tasks',
        method: 'POST',
        data: queryBody,
      }),
    }),
  }),
})

export const { useAssignTasksMutation, useAssignTaskMutation } = tasksApi
