import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetPersonHouseholdParams {
  primaryPersonId?: string
}

const useGetPersonHousehold = (params: GetPersonHouseholdParams) => {
  const { primaryPersonId } = params

  return useQuery({
    queryKey: [`getPersonHousehold`, primaryPersonId],
    queryFn: async () =>
      await api.get(`/benestream/persons/household/${primaryPersonId}`),
  })
}

export default useGetPersonHousehold
