import { DatePickerInput, DateValue } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import { FormikContextType } from 'formik'
import { useState } from 'react'

import { Input, InputDate, Table } from 'src/common/components'
import { useDebounce } from 'src/common/hooks'
import { IOption } from 'src/common/interfaces'

import PastTicketsTableFilterAssignedTo from './PastTicketsTableFilterAssignedTo'
import PastTicketsTableFilterReason from './PastTicketsTableFilterReason'
import PastTicketsTableFilterStatus from './PastTicketsTableFilterStatus'

interface PastTicketsTableFilterProps {
  filterFormik: FormikContextType<any>
  total: number
  usersOptions: IOption[]
}

const PastTicketsTableFilter = (props: PastTicketsTableFilterProps) => {
  const { filterFormik, total, usersOptions } = props
  const [filterDate, setFilterDate] = useState<DateValue | null>(null)

  const handleOnChangeDate = (value: DateValue) => {
    if (value) {
      setFilterDate(value)
      filterFormik.setFieldValue('createdDate', value.toISOString())
    }
  }

  const icon = <IconCalendar />

  return (
    <Table.Filter
      providerValue={filterFormik}
      total={total}
      showSaveCSV={false}
    >
      <PastTicketsTableFilterStatus />

      <PastTicketsTableFilterAssignedTo usersOptions={usersOptions} />

      <PastTicketsTableFilterReason />

      <DatePickerInput
        value={filterFormik.values.createdDate === '' ? null : filterDate}
        variant="unstyled"
        placeholder="MM/DD/YYYY"
        leftSection={icon}
        leftSectionPointerEvents="none"
        valueFormat="MM/DD/YYYY"
        style={{ borderBottom: '1px solid var(--gray-20-color)' }}
        onChange={handleOnChangeDate}
      />
    </Table.Filter>
  )
}

export default PastTicketsTableFilter
