import cx from 'classnames'

import { ICurrentOptionComponent } from 'src/default/interfaces'

import styles from './multi-select-current-option.module.scss'

const MultiSelectCurrentOption = (props: ICurrentOptionComponent) => {
  const { value, placeholder, options = [], isOverflowValue } = props

  const currentOptions =
    options
      ?.filter((o) => value?.includes(o.value))
      ?.map((o) => o.label)
      ?.join(', ') ?? ''

  const parentWidth =
    typeof document !== 'undefined'
      ? document.getElementById('current-option-text-overflow')?.parentElement
          ?.clientWidth
      : 0

  return (
    <>
      {currentOptions ? (
        <div
          className={cx(isOverflowValue && styles.overflow)}
          style={{ width: parentWidth }}
          id="current-option-text-overflow"
        >
          {currentOptions}
        </div>
      ) : (
        <div className={cx(styles.placeholder)}>{placeholder || ''}</div>
      )}
    </>
  )
}

export default MultiSelectCurrentOption
