import { Box, Button, Flex, Group, Text } from '@mantine/core'
import DateRangePickerFilter from '@v2/commons/components/DatePickerFilter/DateRangeFilterPicker.component'
import ButtonFilter from '@v2/commons/components/FilterButton/FilterButton.component'
import SelectFilter from '@v2/commons/components/FilterSelect/FilterSelect.component'
import SearchInput from '@v2/commons/components/SearchInput/SearchInput'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import {
  IEmailTemplate,
  IEmailTemplateFilters,
} from '../../models/emailTemplate.model'
import classes from './email-template.module.scss'

const EmailTemplateFilter = ({
  emailTemplateFiltersForm,
  handleDateRangeChange,
  clearAllFilters,
  usersOptions = [],
}: any) => {
  const [key, setFormKey] = useState(1)
  const [openFilters, setOpenFilters] = useState(false)

  const handleClearFilters = () => {
    setFormKey((prev) => ++prev)
    clearAllFilters()
  }

  const handleChangeValue = (
    path: keyof IEmailTemplateFilters,
    value: IEmailTemplateFilters[keyof IEmailTemplateFilters],
  ) => {
    emailTemplateFiltersForm.setFieldValue(path, value)
  }

  const toggleOpenFilters = () => {
    setOpenFilters((prev) => !prev)
  }

  useEffect(() => {
    const isPersistedFilters = Object.keys(
      emailTemplateFiltersForm.getValues(),
    ).length
    if (isPersistedFilters) {
      setOpenFilters(true)
    }
  }, [])

  const getDefaultDatevalues = (): [Date, Date] | undefined => {
    const filters = emailTemplateFiltersForm.getValues()
    const fromDate = filters['fromDate']
    const toDate = filters['toDate']
    if (fromDate && toDate) {
      return [dayjs(fromDate).toDate(), dayjs(toDate).toDate()]
    }
    return undefined
  }

  return (
    <>
      <Flex gap="xl" justify="space-between" align="center" mb="30px">
        <ButtonFilter onClick={toggleOpenFilters} isOpen={openFilters} />
        <Box flex={1}>
          <SearchInput form={emailTemplateFiltersForm} />
        </Box>
      </Flex>
      {openFilters && (
        <form key={key} style={{ marginBottom: '30px' }}>
          <Group
            preventGrowOverflow={false}
            grow={true}
            classNames={{ root: classes['group-root'] }}
          >
            <SelectFilter
              options={[
                { label: 'Public', value: 'PUBLIC' },
                { label: 'Private', value: 'PRIVATE' },
                { label: 'Transactional', value: 'TRANSACTIONAL' },
              ]}
              placeholder="Type"
              key={emailTemplateFiltersForm.key('type')}
              {...emailTemplateFiltersForm.getInputProps('type')}
              onChange={(value: IEmailTemplate[keyof IEmailTemplate]) => {
                handleChangeValue('type', value)
              }}
            />
            <SelectFilter
              options={usersOptions}
              placeholder="Created By"
              key={emailTemplateFiltersForm.key('createdBy')}
              {...emailTemplateFiltersForm.getInputProps('createdBy')}
              onChange={(value: IEmailTemplate[keyof IEmailTemplate]) => {
                handleChangeValue('createdById', value)
              }}
            />
            <DateRangePickerFilter
              valueFormat="MM/DD/YYYY"
              placeholder="From - To"
              handleDateRangeChange={handleDateRangeChange}
              getFilters={getDefaultDatevalues}
              key={emailTemplateFiltersForm.key('From - To')}
            />
            <Flex miw="50%" justify="flex-end">
              <Button
                variant="transparent"
                classNames={{ root: classes['clear-button-root'] }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear All
              </Button>
            </Flex>
          </Group>
        </form>
      )}
    </>
  )
}

export default EmailTemplateFilter
