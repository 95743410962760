import cx from 'classnames'

import { Icons } from 'src/common/components'

import { ITableColumn } from '../../table.interfaces'
import styles from '../../table.module.scss'

const TableTh = (props: ITableColumn) => {
  const { showMobile, mobileWidth, width, title, sortable } = props

  return (
    <th
      className={cx(showMobile && styles.showMobile)}
      style={{
        width:
          window.innerWidth <= 992
            ? typeof mobileWidth !== 'undefined'
              ? `${mobileWidth}px`
              : undefined
            : typeof width !== 'undefined'
            ? `${width}px`
            : undefined,
      }}
    >
      <div className={styles.thInner}>
        <div className={styles.thTitle}>{title}</div>
        {sortable ? (
          <div className={styles.sortingButton}>
            <Icons.ArrowSelect />
            <Icons.ArrowSelect />
          </div>
        ) : null}
      </div>
    </th>
  )
}

export default TableTh
