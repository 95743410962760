import { useState } from 'react'

import { capitalize } from 'src/common/helpers'
import { useMutateDataLoaderEligibilities } from 'src/common/hooks'
import { BasicTemplate } from 'src/common/templates'
import { processCsv } from 'src/default/helpers/processCsv'
import { useMutationDataloaderEnrollments } from 'src/default/hooks/enrollments'

import useMutationBenestreamPersonDataloader from '../../../../benestream/hooks/person/useMutationBenestreamPersonDataloader'
import { useMutationPersonDataloader } from '../../../../default/hooks'
import useMutationDocumentPersonDataloader from '../../../../default/hooks/enrollments/useMutationDocumentPersonDataloader'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { Wizard } from '../../ui'
import ObjectStep from './ObjectsStep/ObjectStep'
import OperationStep from './OperationStep/OperationStep'
import ReviewStep from './ReviewStep/ReviewStep'
import SummaryStep from './SummaryStep/SummaryStep'
import UploadFileError from './UploadFile/UploadFileError'
import UploadFileForm from './UploadFile/UploadFileForm'
import { validateCsv } from './dataLoader.util'
import {
  ActionType,
  DataLoaderResultObject,
  DataLoaderState,
} from './dataLoaderWizard.interface'

const DataLoaderWizard = () => {
  const processFileMutation = useMutationPersonDataloader()
  const benestreamFileMutation = useMutationBenestreamPersonDataloader()
  const eligibilitiesFileMutation = useMutateDataLoaderEligibilities()
  const enrollmentsFileMutation = useMutationDataloaderEnrollments()
  const documentPersonFileMutation = useMutationDocumentPersonDataloader()

  const [currentStep, setCurrentStep] = useState(1)
  const [processFileError, setProcessFileError] = useState('')
  const [uploadFileState, setUploadFileState] = useState<DataLoaderState>({
    actionType: 'insert',
    object: '',
    fileData: [],
    file: new File([], ''),
    url: '',
  })
  const [result, setResult] = useState<DataLoaderResultObject>({
    successUrl: '',
    errorUrl: '',
    successCount: 0,
    errorCount: 0,
  })
  const [csvError, setCsvError] = useState('')

  const handleChangeActionType = (actionType: ActionType) => {
    setUploadFileState((state) => ({
      ...state,
      actionType,
    }))
  }

  const handleChangeObject = (object: string) => {
    setUploadFileState((state) => ({
      ...state,
      object,
    }))
  }

  const handleRemoveCsvFile = () => {
    setProcessFileError('')
    setUploadFileState((state) => ({
      ...state,
      file: new File([], ''),
    }))
    setResult({
      successUrl: '',
      errorUrl: '',
      errorCount: 0,
      successCount: 0,
    })
    setCsvError('')
  }

  const handleSetUrlOfFile = (url: string) => {
    setUploadFileState((state) => ({
      ...state,
      url,
    }))
  }

  const handleSetPerson = (fileData: DataLoaderState['fileData']) => {
    setUploadFileState((state) => ({
      ...state,
      fileData,
    }))
  }

  const handleSetFileUploaded = (file: File) => {
    setUploadFileState((state) => ({
      ...state,
      file,
    }))
    setProcessFileError('')
    processCsv(
      file,
      validateCsv({
        actionType: uploadFileState.actionType,
        setValidatedData: handleSetPerson,
        object: uploadFileState.object,
        setErrorMessage: setProcessFileError,
      }),
    )
  }

  const handleOnClickCancel = () => {
    setProcessFileError('')
    setUploadFileState({
      actionType: 'insert',
      object: '',
      fileData: [],
      file: new File([], ''),
      url: '',
    })
    setResult({
      successUrl: '',
      errorUrl: '',
      errorCount: 0,
      successCount: 0,
    })
    setTimeout(() => {
      setCurrentStep(1)
    }, 100)
  }

  const uploadFileToProcess = async () => {
    let mutation = null
    switch (uploadFileState.object) {
      case 'workerPerson':
        mutation = processFileMutation
        break
      case 'benestreamPerson':
        mutation = benestreamFileMutation
        break
      case 'opportunity':
        mutation = eligibilitiesFileMutation
        break
      case 'enrollment':
        mutation = enrollmentsFileMutation
        break
      case 'documentPerson':
        mutation = documentPersonFileMutation
        break
    }
    await mutation!
      .mutateAsync({
        fileKey: uploadFileState.url.split('.com/')[1],
        type: uploadFileState.actionType === 'insert' ? 'create' : 'update',
      })
      .then((response) => {
        if (response.error) {
          setCsvError(response.error)
          setProcessFileError(response.error)
          setCurrentStep(3)
        } else {
          setResult({
            ...response,
          })
        }
      })
      .catch(() => {
        setProcessFileError(
          'Error proccessing file. Check the CSV data and try again.',
        )
      })
  }

  const resetUploadHandler = () => {
    setProcessFileError('')
    setUploadFileState((state) => ({
      ...state,
      fileData: [],
      file: new File([], ''),
      url: '',
    }))
    setResult({
      successUrl: '',
      errorUrl: '',
      errorCount: 0,
      successCount: 0,
    })
    setCurrentStep(3)
  }

  const actionLabel = capitalize(uploadFileState.actionType)

  return (
    <BasicTemplate title={`Upload File / ${actionLabel}`}>
      <Wizard
        steps={[
          {
            title: 'Operation',
            content: (
              <OperationStep
                actionType={uploadFileState.actionType}
                changeActionType={handleChangeActionType}
              />
            ),
            footer: {
              backButton: {
                label: 'Cancel',
                onClick: () => {},
              },
              nextButton: {
                label: 'Next',
                onClick: () => setCurrentStep(currentStep + 1),
                disabled: !Boolean(uploadFileState.actionType),
              },
            },
          },
          {
            title: `${actionLabel} Object`,
            content: (
              <ObjectStep
                actionType={uploadFileState.actionType}
                object={uploadFileState.object}
                changeObjectValue={handleChangeObject}
              />
            ),
            footer: {
              backButton: {
                label: 'Back',
                onClick: () => handleChangeObject(''),
              },
              nextButton: {
                label: 'Next',
                onClick: () => {},
                disabled: !Boolean(uploadFileState.object),
              },
            },
          },
          {
            title: 'Upload File',
            content: processFileError ? (
              <UploadFileError
                errorMessage={processFileError}
                removeCsvFile={handleRemoveCsvFile}
              />
            ) : (
              <UploadFileForm
                file={uploadFileState.file}
                errorMessage={processFileError}
                setErrorMessage={setProcessFileError}
                setUrlOfFile={handleSetUrlOfFile}
                setFileUploaded={handleSetFileUploaded}
              />
            ),
            footer: {
              backButton: {
                label: 'Back',
                onClick: () => {},
              },
              nextButton: {
                label: 'Next',
                onClick: () => {},
                disabled: !uploadFileState.url || !!processFileError,
                loading: processFileMutation.isPending,
              },
            },
          },
          {
            title: 'Review',
            content:
              !csvError && !result.successUrl && !result.errorUrl ? (
                <ReviewStep fileData={uploadFileState.fileData} />
              ) : (
                <SummaryStep
                  result={result}
                  csvError={csvError}
                  resetUpload={resetUploadHandler}
                />
              ),
            footer: {
              backButton:
                result.errorUrl || csvError
                  ? {
                      label: 'Cancel',
                      onClick: handleOnClickCancel,
                      noArrow: true,
                    }
                  : {
                      label: 'Back',
                      onClick: () => {},
                    },
              nextButton:
                result.errorUrl || csvError
                  ? {
                      label: 'Continue',
                      onClick: () => {},
                      disabled: true,
                    }
                  : {
                      label: 'Save',
                      onClick: uploadFileToProcess,
                      loading: processFileMutation.isPending,
                    },
            },
          },
        ]}
        currentStep={currentStep}
        changeCurrentStep={setCurrentStep}
        hideFooter={!!result.successUrl || !!result?.errorUrl || !!csvError}
      />
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DataLoaderWizard,
  'is_enable_data_loader_view',
  true,
)
