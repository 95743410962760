export const SERVICE_OPTIONS = [
  {
    label: 'Drivers Vision (Free eye exam and glasses or contacts)',
    value: 'DRIVERS_VISION',
  },
  {
    label:
      'Drivers Drug Discount (Save money on prescriptions for you and your family)',
    value: 'DRIVERS_DRUG_DISCOUNT',
  },
  {
    label:
      'Drivers Accident Support (Get help paying medical bills and replacing lost wages)',
    value: 'DRIVERS_ACCIDENT_SUPPORT',
  },
  {
    label:
      'Drivers Legal Assistance (Get affordable legal service to fight traffic violations)',
    value: 'DRIVERS_LEGAL_ASSISTANCE',
  },
  {
    label:
      'Drivers Telemedicine (Call a doctor 24/7 in any language for you and your family)',
    value: 'DRIVERS_TELEMEDICINE',
  },
  {
    label:
      'Drivers Classes (Earn $300 while learning how to be a better driver)',
    value: 'DRIVERS_CLASSES',
  },
  {
    label: "Driver's Dental (Up to $2,000 a year)",
    value: 'DRIVERS_DENTAL',
  },
]

export const BENEFIT_TYPE_OPTIONS = [
  {
    label: 'Dental',
    value: 'DENTAL',
  },
  {
    label: 'Healthcare',
    value: 'HEALTHCARE',
  },
  {
    label: 'Full Vision Exam',
    value: 'FULL_VISION_EXAM',
  },
  {
    label: 'Vision Health Exam',
    value: 'VISION_HEALTH_EXAM',
  },
]

export const STATUS_OPTIONS = [
  { label: 'Appointment set', value: 'APPOINTMENT_SET' },
  { label: 'Pending/Missed', value: 'PENDING/MISSED' },
  { label: 'Appointment complete', value: 'APPOINTMENT_COMPLETE' },
  { label: 'Benefits used', value: 'BENEFITS_USED' },
  { label: 'Cancelled', value: 'CANCELLED' },
]

export const PREFERRED_LANGUAGES_OPTION = [
  { label: 'English', value: 'ENGLISH' },
  { label: 'Spanish', value: 'SPANISH' },
]
