import { APP_IS_TEMPORARILY_UNAVAILABLE } from 'src/common/config'

import { useAppSelector } from '../../../v2/store/hooks'
import { Loader } from '../../components'
import SystemMessages from './SystemMessages'
import styles from './template-wrapper.module.scss'

const TemplateWrapper = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const isGlobalLoading = useAppSelector((state) => state.ui.isGlobalLoading)

  if (APP_IS_TEMPORARILY_UNAVAILABLE) {
    return <SystemMessages />
  }

  return (
    <>
      <Loader isLoading={isGlobalLoading} fullScreen={true} overlay={false} />
      <div id="root" className={styles.wrapper}>
        {children}
      </div>
    </>
  )
}

export default TemplateWrapper
