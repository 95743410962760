import { yup } from 'src/common/libs'

interface IYupPhoneValidatorProps {
  requiredMessage?: string
}

export const yupPhoneValidator = (
  props: IYupPhoneValidatorProps | undefined = {},
) => {
  const { requiredMessage = 'is required' } = props

  return yup
    .string()
    .typeError('must be a valid phone number')
    .phone()
    .required(requiredMessage)
}
