import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutatePersonEnrollment = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (
      data: any, //TODO ts
    ) => api[action === 'update' ? 'patch' : 'post']('/enrollments', data),
  })

export default useMutatePersonEnrollment
