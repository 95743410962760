import cx from 'classnames'
import dayjs from 'dayjs'
import { useState } from 'react'

import { Icons } from 'src/common/components/ui'
import {
  IFieldsItem,
  IFormSubmission,
  IFormSubmissionPayload,
} from 'src/default/interfaces'
import FormSingleSubmission from 'src/default/pages/Forms/components/FormSingleSubmission/FormSingleSubmission'

import styles from './table-collapsible.module.scss'

interface CollapsibleProps {
  form: IFormSubmission
  refetch?: () => void
}

const TableCollapsible = (props: CollapsibleProps) => {
  const { form, refetch } = props

  const [isOpened, setIsOpened] = useState(false)
  const handleCollapsible = () => {
    if (!isOpened) {
      setIsOpened(true)
    }
  }
  const onClickCancel = () => {
    setIsOpened(false)
  }

  // TODO type the acc
  const getPayload = (formSubmissionItem: IFormSubmission) => {
    return formSubmissionItem?.payload?.steps.reduce((acc: any, step) => {
      ;(step?.fields ?? []).forEach((field: IFieldsItem) => {
        acc[field.name] = field.value
      })
      return acc
    }, {} as IFormSubmissionPayload)
  }

  const getPreviewMessage = (message: string) => {
    return message?.length > 100 ? `${message?.slice(0, 100)} ...` : message
  }

  const getExpandedItem = (formSubmissionItem: IFormSubmission) => {
    return (
      <FormSingleSubmission
        singleFormSubmission={formSubmissionItem}
        onClickCancel={onClickCancel}
        refetch={refetch}
      />
    )
  }

  const getCompressedItem = (formSubmissionItem: IFormSubmission) => {
    const payload = getPayload(formSubmissionItem)
    return (
      <div className={styles.collapsibleContainer}>
        <div className={styles.compressedItemLeft}>
          <div className={styles.nameTemplate}>
            {formSubmissionItem?.template?.name}
          </div>
          <div className={styles.nameSubmitted}>
            {payload?.lastName ?? 'No name provided'}
          </div>
          <div className={styles.message}>
            {payload?.yourMessage ??
              getPreviewMessage(payload?.message) ??
              'No message provided'}
          </div>
        </div>
        <div className={styles.compressedItemRight}>
          <div className={styles.submittedDate}>
            {dayjs(formSubmissionItem.createdAt).format('MM/DD/YYYY HH:mm A')}
          </div>
          <div className={styles.reviewed}>
            Reviewed
            <span
              className={cx(
                formSubmissionItem.isReviewed && styles.checked,
                styles.checkbox,
              )}
            >
              <Icons.Check />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.collapsibleWrapper} onClick={handleCollapsible}>
      <div
        className={cx(styles.iconArrow, isOpened && styles.open)}
        onClick={() => setIsOpened(false)}
      >
        <Icons.ArrowSelect />
      </div>
      {isOpened ? getExpandedItem(form) : getCompressedItem(form)}
    </div>
  )
}

export default TableCollapsible
