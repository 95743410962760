import cx from 'classnames'

import { Checkbox } from 'src/common/components'
import { IOptionComponent } from 'src/default/interfaces'

import styles from './multi-select-contracts-option.module.scss'

interface MultiSelectContractsOptionProps extends IOptionComponent {
  className?: string
  checkboxClassName?: string
}

const MultiSelectContractsOption = (props: MultiSelectContractsOptionProps) => {
  const {
    className,
    checkboxClassName,
    fieldValue,
    label,
    value,
    onChange,
    isDisabledOptions,
  } = props

  const isActive = fieldValue?.includes(value)

  const currentLabel = label.includes('(') ? label : `${label.trim()}`

  return (
    <div
      style={{ cursor: isDisabledOptions ? 'not-allowed' : 'pointer' }}
      className={cx(styles.wrapper, className)}
      onClick={() => {
        if (!isDisabledOptions) {
          onChange(value)
        }
      }}
    >
      <Checkbox
        className={cx(styles.checkbox, checkboxClassName)}
        name={`${label}-${value}__contract__checkbox`}
        value={isActive}
        onChange={() => {
          if (!isDisabledOptions) {
            onChange(value)
          }
        }}
      />
      <span className={styles.label}>{currentLabel}</span>
    </div>
  )
}

export default MultiSelectContractsOption
