import cx from 'classnames'
import { useField } from 'formik'

import { IField } from 'src/common/interfaces'

import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './field-wrapper.module.scss'

const FieldWrapper = (props: React.PropsWithChildren<IField>) => {
  const {
    className,
    name,
    label,
    width,
    tabletWidth,
    required,
    withError = true,
    strictError,
    children,
    hideErrorMessage,
    labelClassName,
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [, meta] = useField({ name })

  const getError = () => {
    if (strictError) {
      return strictError
    }
    if (meta.touched) {
      return meta.error
    }
    return null
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[`${currentTheme}Slice`],
        tabletWidth && styles[`tabletW${tabletWidth}`],
        width && styles[`w${width}`],
        className,
      )}
    >
      {label ? (
        <div style={{ display: 'flex' }}>
          <label htmlFor={name} className={labelClassName}>
            {label}{' '}
            {required ? <span className={labelClassName}>*</span> : null}
          </label>
          {withError &&
          !hideErrorMessage &&
          getError() === 'Email already exists' ? (
            <div
              className={cx(
                styles.errorCommentInline,
                styles[`${currentTheme}Slice`],
              )}
            >
              {getError()}
            </div>
          ) : null}
        </div>
      ) : null}

      {children}

      {withError &&
      !hideErrorMessage &&
      getError() !== 'Email already exists' ? (
        <div
          className={cx(styles.errorComment, styles[`${currentTheme}Slice`])}
        >
          {getError()}
        </div>
      ) : null}
    </div>
  )
}

export default FieldWrapper
