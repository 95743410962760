import { useField } from 'formik'

import { Select } from 'src/common/components'
import { capitalize } from 'src/common/helpers'
import {
  EnumEnrollmentActiveStatus,
  EnumEnrollmentInactiveStatus,
  EnumEnrollmentStatus,
} from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

interface SingleAccountEnrollmentsModalStatusProps {
  name: string
  required?: boolean
  disabled: boolean
  label?: string
  allowedStatuses: EnumEnrollmentStatus[]
  currentEnrollmentStatus: EnumEnrollmentStatus
}

const SingleAccountEnrollmentsModalStatus = (
  props: SingleAccountEnrollmentsModalStatusProps,
) => {
  const {
    name,
    required,
    disabled,
    label,
    allowedStatuses,
    currentEnrollmentStatus,
  } = props

  const [{ value: currentStatus }] = useField(name)

  const options = Object.keys({
    ...EnumEnrollmentInactiveStatus,
    ...EnumEnrollmentActiveStatus,
  })
    .filter(
      (key) =>
        allowedStatuses?.includes(key as EnumEnrollmentStatus) ||
        key === currentStatus,
    )
    .map((key) => ({
      value: key,
      label: capitalize(key),
      hide: key === currentEnrollmentStatus,
    }))

  return (
    <Select
      name={name}
      required={required}
      disabled={disabled}
      label={label}
      options={options}
      withError={false}
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountEnrollmentsModalStatus,
  'is_enable_single_account_enrollments_status',
  true,
)
