import { useState } from 'react'

import { Icons } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import MutateBenefitsAdministrationDeleteLangModal from './MutateBenefitsAdministrationDeleteLangModal'
import styles from './mutate-benefits-administration-delete-lang.module.scss'

interface MutateBenefitsAdministrationDeleteLangProps {
  language: string
  onRemove: () => Promise<void>
}

const MutateBenefitsAdministrationDeleteLang = (
  props: MutateBenefitsAdministrationDeleteLangProps,
) => {
  const { language, onRemove } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className={styles.wrapper} onClick={() => setIsModalOpen(true)}>
        <Icons.TrashCross />
        <span className={styles.text}>Delete</span>
      </div>

      {isModalOpen ? (
        <MutateBenefitsAdministrationDeleteLangModal
          language={language}
          onRemove={onRemove}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationDeleteLang,
  'is_enable_administration_delete_lang',
  true,
)
