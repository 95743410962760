import { STATES } from 'src/common/config'

export const matchCustomerState = (rawState: string = '') => {
  const findedStateData = Object.entries(STATES).find(([key, { match }]) => {
    const formattedRawState = rawState?.replace(/\s/g, '_').toUpperCase()
    return key === formattedRawState || match.includes(formattedRawState)
  })

  return findedStateData ? findedStateData[1].value : ''
}

export const getStateNameByAbbreviation = (stateAbbreviation: string) => {
  return (
    Object.values(STATES).find((state) => state.match[0] === stateAbbreviation)
      ?.value ?? stateAbbreviation
  )
}

export const getStateAbbreviationByName = (stateName: string) => {
  return (
    Object.values(STATES).find((state) => state.value === stateName)
      ?.match?.[0] ?? ''
  )
}
