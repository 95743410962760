import { Button as ButtonMantine } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import cx from 'classnames'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Button, Icons, Input, Modal } from 'src/common/components'
import { validateEmail } from 'src/default/helpers/emailValidator'

import { MultiSelect } from '../MultiSelect'
import styles from './other-email.module.scss'

interface OtherEmailProps {
  name: string
  label?: string
  disabled?: boolean
  inputClassName?: string
}

const OtherEmail = (props: OtherEmailProps) => {
  const { name, label, disabled, inputClassName } = props

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errors, setErrors] = useState<
    { index: number; error: string; onBlur: boolean }[]
  >([])
  const [initialState, setInitialState] = useState<string[]>([])
  const [isEditing, setIsEditing] = useState<number[]>([])

  const onCloseModal = () => {
    setFieldValue(name, initialState)
    setModalIsOpen(false)
    setIsEditing([])
    setErrors([])
  }

  const { values, setFieldValue } = useFormikContext<any>()

  const handleAddNewEmail = () => {
    const newValues = values[name]?.length ? [...values[name], ''] : ['']
    setFieldValue(name, newValues)
    setIsEditing((state) => [...state, newValues.length - 1])
  }

  const handleOnChangeEmail = (index: number, e: any) => {
    const newValues = [...values[name]]
    newValues[index] = e.target.value
    setFieldValue(name, newValues)
    if (!isEditing.includes(index)) {
      setIsEditing((state) => [...state, index])
    }

    if (!validateEmail(e.target.value)) {
      const newErrors = [...errors]
      if (newErrors.find((item) => item.index === index)) {
        setErrors(
          errors.map((item) =>
            item.index === index
              ? { ...item, error: 'Email is not a valid email' }
              : item,
          ),
        )
      } else {
        setErrors([
          ...errors,
          { index, error: 'Email is not a valid email', onBlur: true },
        ])
      }
    } else {
      setErrors(errors.filter((item) => item.index !== index))
    }
  }

  const handleDeleteEmail = (index: number) => {
    const newValues = [...values[name]]
    newValues.splice(index, 1)
    setFieldValue(name, newValues)
    const newErrors = [...errors]
    if (newErrors.find((item) => item.index === index)) {
      setErrors(errors.filter((item) => item.index !== index))
    }
    setIsEditing((state) => state.filter((i) => i !== index))
  }

  const onBlur = (index: number) => {
    if (!validateEmail(values[name][index])) {
      setErrors(
        errors.map((item) =>
          item.index === index
            ? { ...item, error: 'Email is not a valid email', onBlur: true }
            : item,
        ),
      )
    }
  }

  const isDisabled = (): boolean => {
    if (!values[name]?.length && !!initialState.length) {
      return false
    }
    return (
      !values[name]?.length ||
      values[name]?.filter((item: string) => !item).length ||
      errors.find((item) => item.error)
    )
  }

  const handleCancelModal = () => {
    setFieldValue(name, initialState)
    setModalIsOpen(false)
    setIsEditing([])
    setErrors([])
  }

  const handleOnSaveModal = () => {
    let hasError = false
    hasError = values[name].some((_: string, index: number) => {
      return validateSameEmail(index)
    })

    if (hasError) {
      return
    }
    setInitialState(values[name])
    setModalIsOpen(false)
    setIsEditing([])
  }

  const validateSameEmail = (index: number) => {
    const currentOtherEmail = values[name][index] || ''

    const otherEmailEquals =
      values[name].filter((email: string) => email === currentOtherEmail)
        ?.length >= 2

    if (otherEmailEquals) {
      const newErrors = [...errors]
      newErrors.push({ index, error: 'The email is repeated', onBlur: true })
      setErrors(newErrors)
    }

    if (currentOtherEmail === values.email) {
      const newErrors = [...errors]
      newErrors.push({
        index,
        error: 'The email is the same of the main email',
        onBlur: true,
      })
      setErrors(newErrors)
    }

    return otherEmailEquals || currentOtherEmail === values.email
  }

  const handleSaveEmail = (index: number) => {
    const emailOnErrors = errors.find((item) => item.index === index)
    if (!!emailOnErrors || !values[name][index] || validateSameEmail(index)) {
      return
    }
    setIsEditing((state) => [...state.filter((item) => item !== index)])
    if (isEditing.includes(index)) {
      let newValues: string[] = [...values[name]]
      for (let i = 0; i <= isEditing.length; i++) {
        newValues = newValues.filter(
          (_, itemIndex) => itemIndex !== isEditing[i] || itemIndex === index,
        )
      }
      setInitialState(newValues)
      setIsEditing((state) => [...state.filter((item) => item !== index)])
    }
  }

  useEffect(() => {
    setInitialState(values[name] || [])
  }, [values[name]])

  return (
    <div className={styles.wrapper}>
      {!disabled ? (
        <div className={styles.editIcon} onClick={() => setModalIsOpen(true)}>
          <Icons.Edit />
          Update Email
        </div>
      ) : null}
      <MultiSelect
        name={name}
        label={label}
        options={
          values[name]?.map((item: string) => ({ label: item, value: item })) ||
          []
        }
        value={values[name]}
        isDisabledOptions
      />
      {modalIsOpen ? (
        <Modal
          onClose={onCloseModal}
          className={styles.modalWrapper}
          id="OtherEmail"
          title="Secondary Emails"
        >
          <div className={styles.modalContent}>
            <div className={styles.header}>
              <div className={styles.title}>Secondary Emails</div>
              <div className={styles.headerButton}>
                <ButtonMantine
                  leftSection={<IconPlus />}
                  size="md"
                  onClick={handleAddNewEmail}
                >
                  Add Secondary Email
                </ButtonMantine>
              </div>
            </div>
            <div className={styles.body}>
              {values[name]?.map((item: string, index: number) => (
                <div className={styles.emailInput} key={index}>
                  <div className={styles.inputWrapper}>
                    <Input
                      inputClassName={inputClassName}
                      name="otherEmail"
                      onChange={(e) => handleOnChangeEmail(index, e)}
                      tabletWidth="60"
                      value={item}
                      onBlur={() => onBlur(index)}
                      strictError={
                        errors.find((item) => item.index === index)?.onBlur
                          ? errors.find((item) => item.index === index)?.error
                          : ''
                      }
                    />
                  </div>
                  {isEditing.includes(index) ? (
                    <div
                      className={cx(
                        styles.successCircle,
                        !!errors.find((item) => item.index === index) &&
                          styles.disabled,
                      )}
                      onClick={() => handleSaveEmail(index)}
                    >
                      <Icons.SuccessCircle />
                    </div>
                  ) : (
                    <div
                      className={styles.trashIcon}
                      onClick={() => handleDeleteEmail(index)}
                    >
                      <Icons.Trash />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.footer}>
              <div>
                <Button
                  size="small"
                  template="secondary"
                  onClick={handleCancelModal}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  iconAfter="ArrowRight"
                  size="small"
                  disabled={isDisabled()}
                  onClick={handleOnSaveModal}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default OtherEmail
