import cx from 'classnames'

import { Button, Icons } from 'src/common/components'
import { BENEFIT_TYPE_OPTIONS } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './appointment-result.module.scss'

interface AppointmentResultProps {
  benefitType: string
  scheduleNewAppointment: () => void
  viewOpportunity: () => void
}

const AppointmentResult = (props: AppointmentResultProps) => {
  const { benefitType, scheduleNewAppointment, viewOpportunity } = props

  const message = (() => {
    const currentBenefitType = BENEFIT_TYPE_OPTIONS.find(
      (option) => option.value === benefitType,
    )
    if (!currentBenefitType) {
      return 'The appointment was scheduled successfully'
    }

    return `The appointment for the ${currentBenefitType.label} Benefit was scheduled successfully.`
  })()

  return (
    <div className={styles.resultContainer}>
      <div className={cx(styles.icon, styles.success)}>
        <Icons.ConfirmCalendar />
      </div>

      <div className={styles.title}>Appointment Scheduled Successfully!</div>

      <div className={styles.subtitle}>{message}</div>

      <div className={styles.buttonsContainer}>
        <Button
          onClick={scheduleNewAppointment}
          size="small"
          className={styles.altButton}
        >
          Schedule New Appointment
        </Button>

        <Button onClick={viewOpportunity} size="small">
          View Opportunity
        </Button>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  AppointmentResult,
  'is_enable_appointment_result',
  true,
)
