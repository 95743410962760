import { type PersonModel } from '@v2/domains/person/models/person.model'

import { rootApi } from 'src/v2/store/api/rootApi'

import { AssociatedInformation } from './merging-tool.model'
import {
  MergedPersonModel,
  TransformReacordInformationValues,
} from './merging-tool.model'

export const mergingToolApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerson: builder.query<MergedPersonModel[], PersonModel['id'][]>({
      queryFn: async (ids, _api, _extraOptions, baseQuery) => {
        try {
          const persons = await Promise.all(
            ids.map(
              (id) =>
                baseQuery({
                  url: `/v8/persons/${id}`,
                  method: 'GET',
                }) as Promise<{ data: PersonModel }>,
            ),
          )
          const personsData = persons.map(
            ({
              data: {
                enrollments = {},
                documents = {},
                createdAt = {},
                updatedAt = {},
                chat = {},
                updatedBy = {},
                createdBy = {},
                source = '',
                isAccount = false,
                isVerified = false,
                isEmailVerified = false,
                city = '',
                state = '',
                street = '',
                postalCode = '',
                ...rest
              },
            }) => {
              const address = [city, state, street, postalCode]
                .filter((item) => item)
                .join(', ')
              return { ...rest, address }
            },
          )
          return { data: personsData ?? [] }
        } catch (error) {
          return { error } as { error: Error }
        }
      },
    }),
    mergeDuplicates: builder.mutation<
      PersonModel,
      {
        personId: string
        mergePersonIds: string[]
        updatePerson: TransformReacordInformationValues
      }
    >({
      query: (body) => {
        return {
          url: '/v1/persons/merge',
          method: 'POST',
          data: body,
        }
      },
    }),
    getInformationRelated: builder.query<
      AssociatedInformation[],
      { params: { personsIds: PersonModel['id'][] } }
    >({
      query: (queryArgs) => ({
        url: '/persons/information-related',
        method: 'GET',
        params: queryArgs.params,
      }),
      transformResponse: (response: AssociatedInformation[]) => {
        return response.filter((field) => !!Number(field.data.total))
      },
    }),
  }),
})

export const {
  useGetPersonQuery,
  useMergeDuplicatesMutation,
  useGetInformationRelatedQuery,
} = mergingToolApi
