import React from 'react'

import { ModalResult } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

interface ISuccessAssignTasksModalProps {
  onClose: () => void
  onClickReviewTasks: () => void
  title: string
  description: string
  showButtons: boolean
}

const SuccessAssignTasksModal = (props: ISuccessAssignTasksModalProps) => {
  const { onClose, onClickReviewTasks, title, description, showButtons } = props
  return (
    <ModalResult
      isSuccess={true}
      title={title}
      description={description}
      showButtons={showButtons}
      textSuccessButton="Review Tasks"
      showCancelButton={false}
      onClose={onClose}
      handleOnClickButton={onClickReviewTasks}
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SuccessAssignTasksModal,
  'is_enable_success_assign_tasks',
  true,
)
