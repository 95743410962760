import { Checkbox, Flex, Stack, Table } from '@mantine/core'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { UsersResponseModel, typeOrderBy } from '../../models/user.model'
import classes from './users-table.module.scss'

interface LeadsTableProps {
  users: UsersResponseModel | undefined
  handleOrderBy: (field: string, orderBy: typeOrderBy) => void
  filterOrderBy: { field: string; orderBy: typeOrderBy }
  handleEditUser: (userId: string) => void
}

interface FilterChevrons {
  field: string
}

const UsersTable = ({
  users,
  handleOrderBy,
  filterOrderBy,
  handleEditUser,
}: LeadsTableProps) => {
  const FilterChevrons = ({ field }: FilterChevrons) => {
    const handleClick = () => {
      if (filterOrderBy.field !== field || !filterOrderBy.orderBy) {
        handleOrderBy(field, 'ASC')
      } else if (filterOrderBy.orderBy === 'ASC') {
        handleOrderBy(field, 'DESC')
      } else {
        handleOrderBy('', undefined)
      }
    }

    return (
      <Stack gap={6} align="center" justify="center">
        <Icons.ChevronUp
          stroke={
            filterOrderBy.field === field && filterOrderBy.orderBy === 'ASC'
              ? 'blue'
              : 'gray'
          }
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        />
        <Icons.ChevronsDown
          stroke={
            filterOrderBy.field === field && filterOrderBy.orderBy === 'DESC'
              ? 'blue'
              : 'gray'
          }
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        />
      </Stack>
    )
  }

  return (
    <Table
      layout="fixed"
      classNames={{
        table: classes.table,
        thead: classes['table-head'],
        th: classes['table-th'],
        td: classes['table-td'],
      }}
      highlightOnHover={true}
      withTableBorder={true}
      withColumnBorders={true}
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Flex justify="center" align="center" gap="xs">
              Name
              <FilterChevrons field="fullname" />
            </Flex>
          </Table.Th>
          <Table.Th>
            <Flex justify="center" align="center" gap="xs">
              Email
              <FilterChevrons field="user.email" />
            </Flex>
          </Table.Th>
          <Table.Th>
            <Flex justify="center" align="center" gap="xs">
              Role(s)
              <FilterChevrons field="role.name" />
            </Flex>
          </Table.Th>
          <Table.Th>
            <Flex justify="center" align="center" gap="xs">
              Status
            </Flex>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {users?.records.map((user, idx) => (
          <Table.Tr
            key={`${user.name}-${idx}`}
            onClick={() => handleEditUser(user.id)}
          >
            <Table.Td>{user.name ?? '----'}</Table.Td>
            <Table.Td>{user.email ?? '----'}</Table.Td>
            <Table.Td>{user.role ?? '----'}</Table.Td>
            <Table.Td>
              <Flex justify="center">
                <Checkbox checked={user.status === 'active'} readOnly />
              </Flex>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UsersTable,
  'is_enable_leads_table',
  true,
)
