import { PRIVATE_ROUTES } from './routing'

export const MENU_ITEMS = [
  {
    id: 'dashboard',
    route: PRIVATE_ROUTES.DASHBOARD,
    title: 'Dashboard',
    icon: 'Reports',
  },
  {
    id: 'tickets',
    route: PRIVATE_ROUTES.TICKETS,
    title: 'Tickets',
    icon: 'Ticket',
  },
  {
    id: 'leads',
    route: PRIVATE_ROUTES.LEADS,
    title: 'Leads',
    icon: 'User',
  },
  {
    id: 'accounts',
    route: PRIVATE_ROUTES.ACCOUNTS,
    title: 'Accounts',
    icon: 'Account',
  },
  {
    id: 'organizations',
    route: PRIVATE_ROUTES.ORGANIZATIONS,
    title: 'Organizations',
    icon: 'Event',
  },
  {
    id: 'benefitsAdministration',
    route: PRIVATE_ROUTES.BENEFITS_ADMINISTRATION,
    title: 'Benefits Administration',
    icon: 'Benefit',
  },
  {
    id: 'smsCampaign',
    route: PRIVATE_ROUTES.SMS_CAMPAIGNS,
    title: 'SMS Campaigns',
    icon: 'Sms',
  },
  {
    id: 'formsSubmissionByOrganization',
    route: PRIVATE_ROUTES.FORM_SUBMISSIONS,
    title: 'Forms Submission',
    icon: 'PageDuplicate',
  },
  {
    id: 'tasks',
    route: PRIVATE_ROUTES.TASKS,
    title: 'Tasks',
    icon: 'Page',
  },
  {
    id: 'administration',
    route: PRIVATE_ROUTES.ADMINISTRATION,
    title: 'Administration',
    icon: 'Benefit',
    role: 'admin',
  },
  {
    id: 'reports',
    route: PRIVATE_ROUTES.REPORTS,
    title: 'Reports',
    icon: 'Reports',
  },

  {
    id: 'data-processing',
    route: PRIVATE_ROUTES.DATA_PROCESSING,
    title: 'Data Processing',
    icon: 'Reports',
  },
  // Uncomment this when we have the design
  // {
  //   id: 'formsTemplateByOrganization',
  //   route: PRIVATE_ROUTES.FORM_TEMPLATES,
  //   title: 'Form Templates',
  //   icon: 'Page',
  //   role: 'admin', //TODO mb remove
  // },
]
