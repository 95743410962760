import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetDMPCaseHistoryParams {
  caseId: string
  customerId: string
}

const useGetDMPCaseHistory = (params: GetDMPCaseHistoryParams) => {
  const { caseId, customerId } = params

  return useQuery({
    queryKey: [`getGetDMPCaseHistory`, caseId, customerId],
    queryFn: async () => await api.get(`/dmp/history/case`, { params }),
  })
}

export default useGetDMPCaseHistory
