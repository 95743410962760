import { Modal } from '@workers-benefit-fund/wbf-ui-components'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useBlocker, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { BenestreamPersonForm } from 'src/benestream/components'
import { PRIVATE_ROUTES } from 'src/benestream/config'
import {
  transformerPersonsResponseToReadable,
  transformerPersonsToRequest,
} from 'src/benestream/helpers'
import { useGetPerson, useMutatePrimaryPerson } from 'src/benestream/hooks'
import { IBenestreamPerson } from 'src/benestream/interfaces'
import { SingleBenestreamPersonTemplate } from 'src/benestream/templates'
import {
  Breadcrumbs,
  Form,
  MetroPlus,
  PersonNotesBlock,
  Portal,
} from 'src/common/components'
import { PersonNoteModal } from 'src/common/components/person/PersonNotesBlock/components'
import { STATES } from 'src/common/config'
import { yupPhoneValidator } from 'src/common/helpers'
import { ModalResult } from 'src/default/components'
import { basicApiErrorHandling, getPersonDocuments } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import styles from './single-lead-page.module.scss'

const SingleLeadPageWrapper = () => {
  const { personId } = useParams<{ personId: string }>()
  const router = useRouter()

  const [isReady, setIsReady] = useState<boolean>(false)

  const { data, refetch: refetchPerson } = useGetPerson({ personId })
  const personData = data?.data

  const initialValues = (() => {
    if (personData) {
      return transformerPersonsResponseToReadable(personData)
    }

    return null
  })()

  useEffect(() => {
    if (personData) {
      if (personData.isAccount) {
        router.push(`${PRIVATE_ROUTES.ACCOUNTS.path}/${personId}`)
      } else {
        setIsReady(true)
      }
    }
  }, [personData])

  if (!isReady) {
    return null
  }

  return (
    <SingleBenestreamPersonTemplate>
      <div className={styles.wrapper}>
        {initialValues ? (
          <SingleLeadPage
            initialValues={initialValues}
            refetchPerson={refetchPerson}
          />
        ) : null}
      </div>
    </SingleBenestreamPersonTemplate>
  )
}

interface SingleLeadPageProps {
  initialValues: Partial<IBenestreamPerson>
  refetchPerson: () => void
}

const SingleLeadPage = (props: SingleLeadPageProps) => {
  const { initialValues, refetchPerson } = props

  const [result, setResult] = useState<string | null>(null)

  const primaryPersonUpdateMutation = useMutatePrimaryPerson('update')
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [isLeavingTheRecordWithoutNote, setIsLeavingTheRecordWithoutNote] =
    useState<boolean>(true)

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isLeavingTheRecordWithoutNote &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isLeavingTheRecordWithoutNote) {
        event.preventDefault()
      }
      return
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isLeavingTheRecordWithoutNote])

  const handleCloseResult = () => {
    setResult(null)
  }

  const formik = useFormik({
    initialValues: { ...initialValues, statusIsChanged: false },
    onSubmit: async (values) => {
      await primaryPersonUpdateMutation
        .mutateAsync(transformerPersonsToRequest(values))
        .then(() => {
          setResult('success')
          formik.setFieldValue('statusIsChanged', false)
          refetchPerson()
        })
        .catch((error: AxiosError) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().name().required('First name is a required field'),
      lastName: yup.string().name().required('Last name is a required field'),
      email: yup.string().email().required('Email is a required field'),
      phoneNumber: yupPhoneValidator({
        requiredMessage: 'Phone number is a required field',
      }),
      birthDate: yup
        .string()
        .date({
          format: 'MM/DD/YYYY',
          specialRule: 'birthdate',
        })
        .required('Date of Birth is a required field'),
      preferredLanguage: yup.string().nullable(),
      householdSize: yup.number().nullable(),
      agreedToPrivacyWaiver: yup.boolean(),
    }),
  })

  useEffect(() => {
    if (initialValues.documents) {
      const documents = getPersonDocuments(initialValues.documents)

      formik.setFieldValue('tlcLicenseNumber', documents.tlc || '')
      formik.setFieldValue('driverLicense', documents.driverLicense || '')
    }

    if (initialValues.enrollments && initialValues.enrollments.length > 0) {
      formik.setFieldValue(
        'companyName',
        initialValues.enrollments[0].associatedBenefitContract.name ?? '',
      )
    }
  }, [initialValues])

  useEffect(() => {
    if (initialValues.status !== formik.values.status) {
      formik.setFieldValue('statusIsChanged', true)
    }
  }, [formik.values.status])

  useEffect(() => {
    formik.setFieldValue('otherEmail', initialValues.otherEmail)
  }, [initialValues.otherEmail])

  const personName = (() => {
    const { firstName, lastName } = formik.values
    if (!firstName && !lastName) {
      return 'Account'
    }

    return `${firstName} ${lastName}`
  })()

  return (
    <>
      <Breadcrumbs items={[{ label: personName }]} />

      <div className={styles.wrapper}>
        <Form providerValue={formik} className={styles.wrapper}>
          <BenestreamPersonForm
            enrollments={initialValues.enrollments}
            personType="lead"
            refetchPerson={refetchPerson}
            onResult={setResult}
          />
        </Form>

        {result !== null ? (
          <ModalResult
            isSuccess={result === 'success'}
            description={result !== 'success' ? result : undefined}
            onClose={handleCloseResult}
          />
        ) : null}
        <PersonNotesBlock
          setIsLeavingTheRecordWithoutNote={setIsLeavingTheRecordWithoutNote}
        />
      </div>

      {blocker.state === 'blocked' ? (
        <Modal isOpen onClose={() => blocker.reset()}>
          <PersonNoteModal onAfterSave={() => blocker.proceed()} />
        </Modal>
      ) : null}

      {formik.values.state === STATES.NEW_YORK.value ? (
        <Portal.Wrapper id="metroPlus" isAdaptive={false}>
          <MetroPlus personType="lead" />
        </Portal.Wrapper>
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleLeadPageWrapper,
  'is_enable_benestream_single_lead_view',
  true,
)
