import { Flex, Stack, Text } from '@mantine/core'

import { ActionMenu, Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { RoleModel } from '../models/role.model'

const RoleItem = (props: {
  data: RoleModel
  onSelect: (id: RoleModel['id']) => void
}) => {
  const { data: role, onSelect } = props

  return (
    <Stack key={role.id}>
      <Flex
        gap="md"
        align="center"
        style={{ borderBottom: '1px solid #BBC2D3' }}
        my="20"
        mx="10"
        pb="md"
      >
        <Icons.UserCircle height="60" width="60" />
        <Text style={{ flex: 1, color: 'black' }}>{role.name}</Text>
        <ActionMenu
          items={[
            {
              label: 'Update',
              color: 'blue',
              icon: <Icons.Edit />,
              onClick: () => onSelect(role.id),
              enable: true,
            },
          ]}
        />
      </Flex>
    </Stack>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  RoleItem,
  'is_enable_role_items',
  true,
)
