import { IPoliciesResponse } from '../models/policy.model'
import { usePoliciesUseCase } from '../useCases/usePolicies.usecase'

export const usePoliciesContainer = (): IPoliciesResponse => {
  const {
    data: dataPolicies,
    isLoading,
    handleOnSearchChange,
    filteredPolicies,
  } = usePoliciesUseCase()

  return {
    data: dataPolicies,
    isLoading,
    handleOnSearchChange,
    filteredPolicies,
  }
}
