import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutatePrimaryPerson = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: any) => {
      //TODO ts
      return api[action === 'update' ? 'patch' : 'post'](
        `/benestream/persons/primary`,
        data,
      )
    },
  })

export default useMutatePrimaryPerson
