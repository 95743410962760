import { useRouter } from '../../../../../../../../default/helpers/navigation'
import styles from './table-core-cell-inner.module.scss'

interface ITableCoreCellInner {
  link?: string
}

const TableCoreCellInner = (
  props: React.PropsWithChildren<ITableCoreCellInner>,
) => {
  const router = useRouter()
  const { link, children } = props

  if (link) {
    return (
      <a className={styles.link} onClick={() => router.push(link, false)}>
        {children}
      </a>
    )
  }

  return <>{children}</>
}

export default TableCoreCellInner
