import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { BasicBlock, Breadcrumbs } from 'src/common/components'
import { BasicTemplate } from 'src/common/templates'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import { useGetFormTemplateById, useMutateForms } from 'src/default/hooks'
import { IFormTemplate } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import { FormTemplate } from '../components'
import styles from './forms-page.module.scss'

const SingleFormPage = () => {
  const router = useRouter()
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id
  const { formTemplateId } = useParams<{ formTemplateId: string }>()
  const {
    data: form,
    isLoading,
    isSuccess,
  } = useGetFormTemplateById({
    formTemplateId: formTemplateId ?? '',
  })
  const formMutation = useMutateForms('update')
  const [result, setResult] = useState<string | null>(null)

  const formik = useFormik<IFormTemplate>({
    initialValues: {
      id: formTemplateId,
      notifyEmails: [],
      url: '',
      type: 'public',
      name: '',
      version: '1',
      userId: '',
      organizationId: currentOrganizationId ?? '',
    },
    onSubmit: async (values) => {
      const notifyEmails = values.notifyEmails.includes(',')
        ? values.notifyEmails.toString().split(',')
        : [values.notifyEmails]

      const newValues = { ...values, notifyEmails: notifyEmails }

      await formMutation
        .mutateAsync(newValues)
        .then(async ({ data }) => {
          setResult('success')
        })
        .catch((error) => {
          setResult(basicApiErrorHandling(error))
        })
    },
    validationSchema: yup.object().shape({
      notifyEmails: yup.string().required(),
      name: yup.string().required(),
      url: yup.string().required(),
      type: yup.string().required(),
      userId: yup.string().required(),
    }),
  })

  useEffect(() => {
    ;(async () => {
      if (form?.data && Object.keys(form?.data).length) {
        await formik.setValues(
          Object.entries(form?.data).reduce(
            (a, [key, value]) => {
              if (key in a) {
                if (key === 'notifyEmails' && Array.isArray(value)) {
                  a[key] = (value as Array<string>).join(',')
                } else {
                  a[key] = value
                }
              }
              if (key === 'user') {
                a['userId'] = (value as any)?.id
              }

              return a
            },
            { ...formik.values } as any,
          ),
        )
      }
    })()
  }, [form, isSuccess])

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.FORM_TEMPLATES.path)
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <Breadcrumbs items={[{ label: formik.values.name || 'Update Form' }]} />

      <BasicTemplate title="Update Form Template" isLoading={isLoading}>
        <BasicBlock className={styles.wrapper} paddings="big">
          <FormTemplate formik={formik} isCreating />
        </BasicBlock>
        {result !== null ? (
          <ModalResult
            isSuccess={result === 'success'}
            description={result !== 'success' ? result : undefined}
            onClose={handleCloseResult}
          />
        ) : null}
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleFormPage,
  'is_get_form_templates_by_id_enabled',
)
