import { IUser } from '@v2/domains/person/models/person.model'

import { IBasicEntity } from 'src/common/interfaces'
//TODO-BENESTREAM it should be common
import { IDocument, IEnrollment } from 'src/default/interfaces'

//TODO-BENESTREAM mb it is not common
export enum EnumPersonStatus {
  NEW = 'New',
  PARTIAL = 'Partial',
  SIGNEDUP = 'Signed Up',
  PROCESSING = 'Processing',
  INVALIDLICENSE = 'Invalid License',
  NAMEMISMATCH = 'Name Mismatch',
  MISSINGINFORMATION = 'Missing Information',
  ELIGIBLE = 'Eligible',
  ELIGIBLEPARTIALLY = 'Eligible Partially',
  EXISTINGACCOUNT = 'Existing Account',
}

export enum EnumPersonLanguage {
  'en' = 'English',
  am = 'Amharic',
  ar = 'Arabic',
  km = 'Cambodian (Khmer)',
  zh = 'Chinese',
  fa = 'Farsi',
  ja = 'Japanese',
  ko = 'Korean',
  lo = 'Laotian',
  mh = 'Marshallese',
  om = 'Oromo',
  pa = 'Punjabi',
  ru = 'Russian',
  so = 'Somali',
  'es' = 'Spanish',
  uk = 'Ukrainian',
  vi = 'Vietnamese',
  'fr' = 'French',
  'hi' = 'Hindi',
  'bn' = 'Bengali',
  'Other' = 'Other',
}

export interface IPerson extends IBasicEntity {
  index?: number
  id: string
  sfdcId: string
  isVerified: boolean
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  otherPhoneNumber: string | string[]
  birthDate: string
  companies: string[]
  tlcLicenseNumber: string
  driversLicenseNumber: string
  gender?: string
  otherGender?: string
  street?: string
  state?: string
  city?: string
  postalCode?: string
  preferredLanguage?: string
  isEmailVerified?: boolean
  isPhoneVerified?: boolean
  source?: string
  enrollments?: IEnrollment[]
  createdBy?: IPerson
  updatedBy?: IPerson
  haveHealthInsurance: string
  coverageThroughTheState: string
  pay20PerMonth: string
  dentalEligible?: boolean
  visionEligible?: boolean
  documents?: IDocument[]
  blackCarFund?: string
  nationBuilderId?: string
  rating?: string
  otherEmail?: string[]
}

export interface IPersonMutationData extends Partial<IPerson> {} //TODO-EXODUS let's try it

//TODO-BENESTREAM mb it is not common
export enum EnumLeadStatusForConvering {
  ELIGIBLE = EnumPersonStatus.ELIGIBLE,
  EXISTINGACCOUNT = EnumPersonStatus.EXISTINGACCOUNT,
}

export interface IPersonSearchResultItem extends IPerson {
  enrollments: IEnrollment[]
  documents: IDocument[]
  worksite: string
}

export interface IHistory {
  id: string
  person: IPerson
  objectType: string
  objectId: string
  objectField: string
  originalValue: string
  newValue: string
  dateOfChange: string
  user: IUser
  actionSource: string
}
