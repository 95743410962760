import { useEffect, useState } from 'react'
import { Editor } from '@tiptap/core'
import { Button } from '@mantine/core'
import { IconCode } from '@tabler/icons-react'

interface HtmlModeButtonProps {
  editor: Editor | null
}

export const HtmlModeButton = ({ editor }: HtmlModeButtonProps) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (editor) {
      setIsActive(editor.storage.htmlMode.isHtmlMode)
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <Button 
      variant={isActive ? 'filled' : 'default'}
      onClick={() => {
        editor.commands.toggleHtmlMode()
        setIsActive(!isActive)
      }}
      size="xs"
      leftSection={<IconCode size={16} />}
    >
      HTML Text
    </Button>
  )
} 