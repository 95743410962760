import cx from 'classnames'
import { useFormik } from 'formik'

import { useUploadAttachment } from '../../../../../hooks'
import { FileUploader, Form } from '../../../../form'
import styles from './upload-file-form.module.scss'

interface UploadFileForm {
  file: File
  setFileUploaded: (file: File) => void
  setUrlOfFile: (url: string) => void
  errorMessage: string
  setErrorMessage: (error: string) => void
}

const UploadFileForm = (props: UploadFileForm) => {
  const uploadFileMutation = useUploadAttachment({
    attachmentType: 'person-file',
  })

  const { file, setFileUploaded, setUrlOfFile, setErrorMessage, errorMessage } =
    props

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    onSubmit: () => {},
  })

  return (
    <div className={cx(styles.cardContent)}>
      <Form className={styles.formContainer} providerValue={formik}>
        <FileUploader
          description="Select a CVS file or drag and drop it here"
          dragActiveDescription="Drop your CSV file here"
          maxSizeMB={5}
          formats={['.csv']}
          mutation={uploadFileMutation}
          file={file}
          setFile={setFileUploaded}
          setUrlValue={setUrlOfFile}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </Form>
    </div>
  )
}

export default UploadFileForm
