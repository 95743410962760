export const CurrentTheme = {
  DEFAULT_360: 'default-360',
  BENESTREAM: 'benestream',
} as const

export type CurrentThemeType = (typeof CurrentTheme)[keyof typeof CurrentTheme]

export interface UiModel {
  isGlobalLoading: boolean
  currentTheme: CurrentThemeType
  organizationLogo: string
}

export const LocalStorageUI = 'UI'
export const LocalStorageLogo = 'LOGO'
