import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

interface GetHistoryPersonProps {
  personId: string
  limit?: number
}

const useGetHistoryPerson = (params: GetHistoryPersonProps) => {
  const { personId, limit } = params

  return useInfiniteQuery({
    queryKey: [`getHistoryPerson`],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v2/persons/history/${personId}`, {
        signal,
        params: {
          limit,
          offset: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      PAGINATION_LIMIT * pages.filter((p: any) => p.data.records.length).length, //TODO ts,
  })
}

export default useGetHistoryPerson
