import { useEffect, useState } from 'react'

import BenestreamTheme from 'src/benestream/theme'
import { BENESTREAM_ORG_ID } from 'src/common/config'
import { useGetOrganizationsByUserId } from 'src/common/hooks'
import { IOrganization } from 'src/common/interfaces'
import { appLocalStorage } from 'src/common/libs'
import CRMSelectOrganization from 'src/common/templates/CRMTemplate/CRMSelectOrganization/CRMSelectOrganization'
import { FUTURE_BANK } from 'src/default/config'
import DefaultTheme from 'src/default/theme'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { setOrganizationsByUser } from '../../../../v2/domains/organization/models/organization.store'
import { useAppDispatch, useAppSelector } from '../../../../v2/store/hooks'

const SelectOrganizationPage = () => {
  const dispatch = useAppDispatch()

  const userProfile = useAppSelector((state) => state.user.userProfile)
  const session = useAppSelector((state) => state.auth)

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [isReady, setIsReady] = useState(false)

  const { data: organizationsByUserId, isSuccess } =
    useGetOrganizationsByUserId({
      userId: session.userId ?? '',
    })

  useEffect(() => {
    document
      .getElementsByTagName('body')[0]
      ?.setAttribute('data-theme', currentTheme)
  }, [currentTheme])

  const CurrentTheme =
    BENESTREAM_ORG_ID === userProfile.organization.id
      ? BenestreamTheme
      : DefaultTheme

  useEffect(() => {
    if (organizationsByUserId && isSuccess) {
      const organizations = organizationsByUserId?.data?.records?.filter(
        (org: IOrganization) => org?.id !== FUTURE_BANK,
      )
      dispatch(setOrganizationsByUser(organizations))
      appLocalStorage.setData(
        'organizationByUser',
        JSON.stringify(organizations),
      )
      setIsReady(true)
    }
  }, [organizationsByUserId])

  return (
    <>
      <CurrentTheme />
      {isReady ? (
        <>
          {userProfile.organization.id === '' ? (
            <CRMSelectOrganization />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SelectOrganizationPage,
  'is_enable_select_organization',
  true,
)
