import { useMutation } from '@tanstack/react-query'
import { IEmailTemplateUpdate } from '@v2/domains/email-template/models/emailTemplate.model'

import { api } from 'src/common/api'

const UseCreateEmailTemplate = () =>
  useMutation({
    mutationFn: async (data: IEmailTemplateUpdate) => {
      if (data.id) {
        const newValues = {
          body: data.body,
          description: data.description,
          subject: data.subject,
          type: data.type,
        }
        return api.patch(`/email-template/${data.id}`, newValues)
      }
      return api.post('/email-template', data)
    },
  })

export default UseCreateEmailTemplate
