import cx from 'classnames'
import { useFormikContext } from 'formik'

import { BenestreamPersonFormBlock } from 'src/benestream/components'
import { IPersonHousehold } from 'src/benestream/interfaces'
import { Checkbox, Input, InputDateAndAge, Select } from 'src/common/components'
import { GENDER_OPTIONS } from 'src/common/config'

import BenestreamPersonFormHouseholdIncome from './BenestreamPersonFormHouseholdIncome'
import styles from './benestream-person-form-household-information.module.scss'

interface BenestreamPersonFormHouseholdInformationProps {
  isNew?: boolean
  onSuccess: () => void
  refetchHouseHold: () => void
  refetchBeneStreamPerson: () => void
}

const BenestreamPersonFormHouseholdInformation = (
  props: BenestreamPersonFormHouseholdInformationProps,
) => {
  const { isNew, onSuccess, refetchHouseHold, refetchBeneStreamPerson } = props

  const { values } = useFormikContext<IPersonHousehold>()

  return (
    <BenestreamPersonFormBlock
      className={styles.wrapper}
      title="Member Information"
    >
      <Input
        required
        name="firstName"
        label="First Name"
        placeholder="Enter first name"
        tabletWidth="50"
      />

      <Input
        required
        name="lastName"
        label="Last Name"
        placeholder="Enter last name"
        tabletWidth="50"
      />

      <InputDateAndAge
        required
        name="birthDate"
        label="Date of Birth"
        tabletWidth="50"
        maxYear={new Date().getFullYear() + 1}
        futureDate={true}
      />

      <Select
        name="gender"
        label="Gender"
        placeholder="Select Gender"
        options={GENDER_OPTIONS}
        tabletWidth="50"
      />

      {values.relationToApplicant === 'primary' ? (
        <Input
          required
          disabled
          name="relationToApplicantInput"
          label="Relation to Applicant"
          value="Self"
          tabletWidth="50"
        />
      ) : (
        <Select
          required
          name="relationToApplicant"
          label="Relation to Applicant"
          placeholder="Select Relation to Applicant"
          options={[
            { value: 'spouse', label: 'Spouse' },
            { value: 'parent', label: 'Parent/Caretaker' },
            { value: 'child', label: 'Dependent Child' },
            { value: 'sibling', label: 'Sibling' },
            { value: 'other', label: 'Other' },
          ]}
          tabletWidth="50"
        />
      )}

      {!isNew ? (
        <BenestreamPersonFormHouseholdIncome
          onSuccess={onSuccess}
          refetchHouseHold={refetchHouseHold}
          refetchBeneStreamPerson={refetchBeneStreamPerson}
        />
      ) : (
        <div className={styles.tabletW50} />
      )}

      <Checkbox
        name="isPregnant"
        label="Are you expecting any children (pregnant) at this time?"
        tabletWidth="50"
      />

      <Input
        className={cx(
          styles.expectedChildrenInput,
          values.isPregnant && styles.visible,
        )}
        type="number"
        name="numberOfExpectedChildren"
        label="Number of Expected Children"
        placeholder="Enter number of expected children"
        tabletWidth="50"
      />

      <Checkbox
        name="haveDisability"
        label="Do you have a disability?"
        tabletWidth="50"
      />

      <Checkbox
        name="isEverInFosterCare"
        label="(If Under 26) Ever in Foster Care"
        tabletWidth="50"
      />
    </BenestreamPersonFormBlock>
  )
}

export default BenestreamPersonFormHouseholdInformation
