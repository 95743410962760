import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { OrganizationModel } from './organization.model'

const initialState: OrganizationModel = {
  organizationsByUser: [],
  showApproveToChangeOrganization: false,
  globalSearchChangeOrganization: {
    newOrganizationId: '',
    newMembershipId: '',
    newPath: '',
  },
}

const organizationStore = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationsByUser: (
      state,
      action: PayloadAction<OrganizationModel['organizationsByUser']>,
    ) => {
      state.organizationsByUser = action.payload
    },
    setShowApproveToChangeOrganization: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showApproveToChangeOrganization = action.payload
    },
    setGlobalSearchChangeOrganization: (
      state,
      action: PayloadAction<
        OrganizationModel['globalSearchChangeOrganization']
      >,
    ) => {
      state.globalSearchChangeOrganization = action.payload
    },
  },
})

export const {
  setOrganizationsByUser,
  setShowApproveToChangeOrganization,
  setGlobalSearchChangeOrganization,
} = organizationStore.actions

export default organizationStore.reducer
