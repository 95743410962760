import { Checkbox, Table } from '@mantine/core'
import PolicyCheckHOC from '@v2/commons/HOC/policyCheckHOC'
import EmptyPersons from '@v2/commons/components/EmptyBlock/EmptyPersons.component'
import PersonTableABCs from '@v2/domains/person/components/Tables/ABCsPersonColumn/ABCsPersonTable.component'
import PersonOrganization from '@v2/domains/person/components/Tables/PersonOrganizationsColumn/PersonOrganization.component'
import { AssignPersonModel } from '@v2/domains/person/models/assignPerson.model'

import { PRIVATE_ROUTES } from 'src/default/config'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useRouter } from 'src/v2/commons/utils/navigation'
import { getPhoneWithMask } from 'src/v2/commons/utils/phone'
import { removeUnderScoreAndTransformToLowerCase } from 'src/v2/commons/utils/stringManipulation'
import { type IEnrollment } from 'src/v2/domains/person/models/enrollment.model'
import { type PersonsModel } from 'src/v2/domains/person/models/leads/leads.model'
import { type PersonModel } from 'src/v2/domains/person/models/person.model'
import {
  getLeadStatusColor,
  getPersonDocuments,
  mapEnrollmentStatus,
  sortEnrollments,
} from 'src/v2/domains/person/utils'

import { TableLeadStatus } from '../StatusColumn'
import styles from '../table.module.scss'

interface SearchResultTableProps {
  results: PersonsModel['records']
  handleAddRemoveSearch: (
    search: Pick<PersonModel, 'id'>['id'],
    add: boolean,
  ) => void
  handleSelectAllSearch: (payload: {
    isAllSelected: boolean
    allSearchResults: AssignPersonModel['filters']['selectedItems']
  }) => void
  handleClearStoreSearch: () => void
  isAllSelected: boolean
  selectedItems: PersonModel['id'][]
  searchResultsIds: string[]
  isCrossOrganization: boolean
}

const SearchResultTable = ({
  results,
  isAllSelected,
  searchResultsIds,
  handleAddRemoveSearch,
  handleSelectAllSearch,
  selectedItems,
  isCrossOrganization,
}: SearchResultTableProps) => {
  const router = useRouter()
  const navigateToLeadById = (item: PersonsModel['records'][0]) => {
    router.push(`${PRIVATE_ROUTES.LEADS.path}/${item.id}`)
  }

  const handleSelectSearchItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    searchItem: Pick<PersonModel, 'id'>['id'],
  ) => {
    const { checked } = event.target
    handleAddRemoveSearch(searchItem, checked)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    handleSelectAllSearch({
      isAllSelected: checked,
      allSearchResults: searchResultsIds,
    })
  }
  const isSearchItemSelected = (searchId: string) => {
    return !!selectedItems.find((selectedId) => selectedId === searchId)
  }
  return (
    <>
      {!!results.length ? (
        <Table
          layout="fixed"
          classNames={{
            table: styles.table,
            thead: styles['table-head'],
            th: styles['table-th'],
            td: styles['table-td'],
          }}
          highlightOnHover={true}
          withTableBorder={true}
          withColumnBorders={true}
        >
          <Table.Thead>
            <Table.Tr>
              <PolicyCheckHOC
                policyName={[
                  'is_create_task_enabled',
                  'is_merge_persons_enabled',
                  'is_create_multiple_task_enabled',
                ]}
              >
                <Table.Th>
                  <Checkbox
                    className={styles.checkbox}
                    radius="3px"
                    size="18px"
                    onChange={handleSelectAll}
                    checked={isAllSelected}
                  />
                </Table.Th>
              </PolicyCheckHOC>
              <Table.Th>Name</Table.Th>
              <Table.Th>ABC</Table.Th>
              {isCrossOrganization && <Table.Th>Organizations</Table.Th>}
              <Table.Th>Status</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Driver's License Number</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Mobile Phone</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {results.map((searchItem, idx) => (
              <Table.Tr
                key={`${searchItem.firstName}-${idx}`}
                className={styles['table-tr']}
              >
                <PolicyCheckHOC
                  policyName={[
                    'is_create_task_enabled',
                    'is_merge_persons_enabled',
                    'is_create_multiple_task_enabled',
                  ]}
                >
                  <Table.Td>
                    <Checkbox
                      className={styles.checkbox}
                      radius="3px"
                      size="18px"
                      checked={isSearchItemSelected(searchItem.id)}
                      onChange={(e) => handleSelectSearchItem(e, searchItem.id)}
                    />
                  </Table.Td>
                </PolicyCheckHOC>
                <Table.Td
                  fw="600"
                  onClick={() => navigateToLeadById(searchItem)}
                >
                  {searchItem.firstName + ' ' + searchItem.lastName}
                </Table.Td>

                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  <PersonTableABCs enrollments={searchItem.enrollments || []} />
                </Table.Td>
                {isCrossOrganization && (
                  <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                    <PersonOrganization
                      enrollments={searchItem.enrollments || []}
                    />
                  </Table.Td>
                )}

                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  {searchItem.enrollments?.map(
                    (enrollment: IEnrollment, idx: number) => (
                      <TableLeadStatus
                        key={idx}
                        status={removeUnderScoreAndTransformToLowerCase(
                          mapEnrollmentStatus(
                            enrollment.status,
                            enrollment.associatedBenefitContract.organization
                              .id,
                          ),
                        )}
                        color={getLeadStatusColor(
                          removeUnderScoreAndTransformToLowerCase(
                            mapEnrollmentStatus(
                              enrollment.status,
                              enrollment.associatedBenefitContract.organization
                                .id,
                            ),
                          ),
                        )}
                      />
                    ),
                  )}
                </Table.Td>
                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  {sortEnrollments(searchItem.enrollments).map((item) => (
                    <div className={styles.typeRow} key={item.id}>
                      <div className={styles.circle} />
                      {item.isVerified ? 'Account' : 'Lead'}
                    </div>
                  ))}
                </Table.Td>
                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  {getPersonDocuments(searchItem.documents).driverLicense}
                </Table.Td>
                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  {searchItem.email}
                </Table.Td>
                <Table.Td onClick={() => navigateToLeadById(searchItem)}>
                  {getPhoneWithMask(searchItem.phoneNumber)}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <EmptyPersons term="record" />
      )}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SearchResultTable,
  'is_enable_leads_table',
  true,
)
