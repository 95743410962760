import { Pagination } from '@workers-benefit-fund/wbf-ui-components'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BasicBlock,
  Breadcrumbs,
  EmptyPersons,
  Loader,
  Table,
} from 'src/common/components'
import { capitalize } from 'src/common/helpers'
import { BasicTemplate } from 'src/common/templates'
import { useGetSmsCampaignById } from 'src/default/hooks'
import useGetSmsByCampaignId from 'src/default/hooks/sms/useGetSmsByCampaignId'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import SmsCampaignFilter from '../SmsCampaignFilter/SmsCampaignFilter'
import styles from './sms-campaigns.module.scss'

const SmsCampaignDetail = () => {
  const { campaignId } = useParams<{ campaignId: string }>()

  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
  })

  const { data } = useGetSmsCampaignById(campaignId!)

  const filterCampaignFormik = useFormik({
    initialValues: {
      status: '',
    },
    onSubmit: () => {},
  })

  const {
    data: smsByCampaign,
    isFetched,
    isFetching,
  } = useGetSmsByCampaignId(
    campaignId!,
    pagination.limit,
    pagination.offset,
    filterCampaignFormik.values.status,
  )

  const smsCampaignData = data?.data || {}

  const getSmsStatus = (item: any) => {
    if (item.error) {
      return 'Error'
    }

    if (item.delivered) {
      return 'Delivered'
    }

    if (item.type === 'SCHEDULED') {
      return 'Scheduled'
    }

    return 'Sending'
  }

  const getUnDeliveredSms = () => {
    if (smsCampaignData?.sms?.length) {
      return smsCampaignData.sms.filter(
        (item: any) => !item.delivered && !item.error,
      ).length
    }
    return 0
  }

  const getPersonIdFromError = (errorMessage: string) => {
    return errorMessage?.split(':')?.[1] || ''
  }

  const removePersonIdFromError = (errorMessage: string) => {
    return errorMessage?.split(':')?.[0] || errorMessage
  }

  return (
    <>
      <Breadcrumbs items={[{ label: smsCampaignData.name }]} />

      <BasicTemplate title={smsCampaignData.name}>
        <div className={styles.wrapper}>
          <div>
            <div className={styles.title}>Campaign summary</div>
            <div className={styles.cardWrapper}>
              <BasicBlock className={styles.card} paddings="normal">
                <div className={styles.circleText}>TARGETS</div>
                <div>{smsCampaignData.targets}</div>
              </BasicBlock>
              <BasicBlock className={styles.card} paddings="normal">
                <div className={styles.circleText}>DELIVERED</div>
                <div>{smsCampaignData.successCount || 0}</div>
              </BasicBlock>
              <BasicBlock className={styles.card} paddings="normal">
                <div className={styles.circleText}>UNDELIVERED</div>
                <div>{getUnDeliveredSms()}</div>
              </BasicBlock>
              <BasicBlock className={styles.card} paddings="normal">
                <div className={styles.circleText}>ERRORS</div>
                <div>{smsCampaignData.errorCount || 0}</div>
              </BasicBlock>
              <BasicBlock className={styles.card} paddings="normal">
                <div className={styles.circleText}>STATUS</div>
                <div>{capitalize(smsCampaignData.status || '')}</div>
                {smsCampaignData.sentAt ? (
                  <div className={styles.sentAtDescription}>
                    Sent message at:{' '}
                    {dayjs(smsCampaignData.sentAt).format('DD/MM/YYYY : HH:mm')}
                  </div>
                ) : null}
              </BasicBlock>
            </div>
          </div>
          <div>
            <div className={styles.title}>Campaign message</div>
            <div>{smsCampaignData.smsMessage}</div>
          </div>
          <div>
            <div className={styles.title}>Targets</div>
            <BasicBlock paddings="big">
              <SmsCampaignFilter
                providerValue={filterCampaignFormik}
                total={smsByCampaign?.data?.totalRecords || 0}
              />
              {isFetching ? (
                <Loader isLoading={isFetching} />
              ) : smsByCampaign?.data?.records?.length ? (
                <Table>
                  {smsByCampaign?.data?.records
                    ?.sort((item: any) => (!item.error ? 1 : 0))
                    ?.map((item: any, index: number) => (
                      <Table.Row key={item.id} id={item.id}>
                        <Table.Column title="" showMobile width={50}>
                          {pagination.offset + index + 1}
                        </Table.Column>
                        <Table.Column title="Person Id" showMobile>
                          {item.personId || getPersonIdFromError(item.error)}
                        </Table.Column>
                        <Table.Column title="Person phone number" showMobile>
                          {item.to}
                        </Table.Column>
                        <Table.Column title="From" showMobile>
                          {item.from}
                        </Table.Column>
                        <Table.Column title="Status" showMobile>
                          {getSmsStatus(item)}
                        </Table.Column>
                        <Table.Column title="Error message" showMobile>
                          {removePersonIdFromError(item?.error) || ''}
                        </Table.Column>
                      </Table.Row>
                    ))}
                </Table>
              ) : isFetched ? (
                <EmptyPersons term="record" />
              ) : null}
              {smsByCampaign?.data?.totalRecords > 20 ? (
                <div className="text-center pt-4">
                  <Pagination
                    limit={pagination.limit}
                    total={smsByCampaign?.data?.totalRecords}
                    onChangePage={(page: number) =>
                      setPagination((state) => ({
                        ...state,
                        offset: (page - 1) * pagination.limit,
                      }))
                    }
                    currentPage={pagination.offset / pagination.limit + 1}
                  />
                </div>
              ) : null}
            </BasicBlock>
          </div>
        </div>
      </BasicTemplate>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SmsCampaignDetail,
  'is_enable_sms_campaign_detail',
  true,
)
