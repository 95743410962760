import { useForm, yupResolver } from '@mantine/form'
import { GetInputProps } from '@mantine/form/lib/types'
import * as yup from 'yup'

import { useForgotPasswordMutation } from '../models/auth.api'
import { ForgotFormModel } from '../models/auth.model'

interface IUseForgotFormUseCase {
  getFormProps: GetInputProps<ForgotFormModel>
  onSubmit: (callback: () => void) => void
  forgotMutationStatus: {
    isLoading: boolean
    isSuccess: boolean
  }
}
const useForgotFormUseCase = (): IUseForgotFormUseCase => {
  const [forgotPassword, { isLoading, isSuccess }] = useForgotPasswordMutation()

  const schema = yup.object().shape({
    email: yup.string().required('Required').email('Invalid email'),
  })

  const form = useForm<ForgotFormModel>({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
    },
    validateInputOnBlur: true,
    validate: yupResolver(schema),
  })

  const onSubmit = (callback: () => void) => {
    form.onSubmit(async (values) => {
      forgotPassword(values).unwrap().finally(callback)
    })()
  }

  return {
    getFormProps: form.getInputProps,
    onSubmit: onSubmit,
    forgotMutationStatus: { isLoading: isLoading, isSuccess: isSuccess },
  }
}

export default useForgotFormUseCase
