import cx from 'classnames'

import { Icons, Spinner } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

import { useRouter } from '../../../../../default/helpers/navigation'
import { useAppSelector } from '../../../../../v2/store/hooks'
import ButtonProps from './ButtonProps'
import styles from './button.module.scss'

interface ButtonWrapperProps {
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  to?: string
  pseudo?: boolean
  target?: string
  onClick?: () => void
}

const WrapperComponent = (
  props: React.PropsWithChildren<ButtonWrapperProps>,
) => {
  const router = useRouter()

  const { className, to, pseudo, type, disabled, target, onClick, children } =
    props

  if (to) {
    if (target) {
      return (
        <a className={className} href={to} target={target}>
          {children}
        </a>
      )
    }
    return (
      <a className={props.className} onClick={() => router.push(to, false)}>
        {props.children}
      </a>
    )
  }

  if (pseudo) {
    return (
      <span
        className={cx(className, disabled && styles.disabled)}
        onClick={onClick}
      >
        {children}
      </span>
    )
  }

  return (
    <button
      className={className}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const {
    className,
    type = 'button',
    size = 'normal',
    to,
    target,
    icon,
    iconAfter,
    template,
    loading,
    disabled,
    pseudo,
    children,
    onClick = () => false,
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const IconComponent =
    icon && (Object.entries(Icons)?.find(([key]) => key === icon)?.[1] ?? null)

  const IconAfterComponent =
    iconAfter &&
    (Object.entries(Icons)?.find(([key]) => key === iconAfter)?.[1] ?? null)

  return (
    <WrapperComponent
      className={cx(
        styles.button,
        template && styles[template],
        styles[size],
        styles[`${currentTheme}Slice`],
        className,
        'bg-primary text-white',
      )}
      type={type}
      disabled={disabled || loading}
      to={to}
      target={target}
      pseudo={pseudo}
      onClick={() => onClick()}
    >
      {IconComponent ? (
        <span className={cx(styles.icon, !children && styles.withoutText)}>
          <IconComponent />
        </span>
      ) : null}
      {!loading ? children : <Spinner />}

      {IconAfterComponent ? (
        <span className={styles.iconAfter}>
          <IconAfterComponent />
        </span>
      ) : null}
    </WrapperComponent>
  )
}

export default Button
