import { Button } from 'src/common/components'

import { useRouter } from '../../../../default/helpers/navigation'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../v2/commons/HOC/policyCheckHOC'
import { PRIVATE_ROUTES } from '../../../config'
import styles from './topbar.module.scss'

const Topbar = (props: React.PropsWithChildren<{}>) => {
  const router = useRouter()

  const { children } = props

  return (
    <div className={styles.wrapper}>
      {children}

      <PolicyCheckHOC policyName="is_persons_data_loader_enabled">
        <a onClick={() => router.push(PRIVATE_ROUTES.DATA_LOADER.path)}>
          <Button className={styles.altButton} icon="Upload" size="small">
            Upload File
          </Button>
        </a>
      </PolicyCheckHOC>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Topbar,
  'is_enable_benestream_topbar',
  true,
)
