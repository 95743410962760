import { BasicBlock, Portal } from 'src/common/components'
import { useGetDMPCases } from 'src/default/hooks'
import { IDMPCase } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import DeactivationCasesItem from './DeactivationCasesItem'
import styles from './deactivation-cases.module.scss'

interface DeactivationCasesProps {
  email: string
}

const DeactivationCases = (props: DeactivationCasesProps) => {
  const { email } = props

  const { data } = useGetDMPCases({ email })
  const cases = data?.data?.cases?.edges ?? []

  if (!cases.length) {
    return null
  }

  return (
    <Portal.Wrapper id="deactivationCases" isAdaptive={false}>
      <BasicBlock
        className={styles.wrapper}
        title="Deactivation Cases"
        paddings="small"
        isShowWrapper={false}
      >
        {cases.map((item: IDMPCase) => (
          <DeactivationCasesItem
            key={item._id}
            data={item}
            isOnlyOne={cases.length === 1}
          />
        ))}
      </BasicBlock>
    </Portal.Wrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationCases,
  'is_enable_deactivation_cases',
  true,
)
