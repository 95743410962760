import { ITableRow } from '../../table.interfaces'

const TablePublicRow = <TableRowData,>(
  props: React.PropsWithChildren<ITableRow<TableRowData>>,
) => {
  const { className, children, id } = props

  return (
    <div id={id} className={className}>
      {children}
    </div>
  )
}

export default TablePublicRow
