import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useState } from 'react'

//import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental'
import { apiClient } from './'

//TODO NEXTJS read about ReactQueryStreamedHydration

const APIProviders = (props: React.PropsWithChildren) => {
  const [queryClient] = useState(apiClient)

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )

  //   return (
  //     <QueryClientProvider client={queryClient}>
  //       <ReactQueryStreamedHydration>
  //         {props.children}
  //       </ReactQueryStreamedHydration>
  //       <ReactQueryDevtools initialIsOpen={false} />
  //     </QueryClientProvider>
  //   )
}

export default APIProviders
