import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AuthModel, LocalStorageAuthSession } from './auth.model'

const initialState: AuthModel = {
  accessToken: '',
  refreshToken: '',
  userId: '',
}

const authSchema = createSlice({
  name: 'auth',
  initialState: {
    ...(localStorage.getItem(LocalStorageAuthSession)
      ? JSON.parse(localStorage.getItem(LocalStorageAuthSession) as string)
      : initialState),
  },
  reducers: {
    setAuthInfo: (state, action: PayloadAction<Partial<AuthModel>>) => {
      const { accessToken, refreshToken, userId } = action.payload

      if (accessToken) {
        state.accessToken = accessToken
      }

      if (refreshToken) {
        state.refreshToken = refreshToken
      }

      if (userId) {
        state.userId = userId
      }

      localStorage.setItem(LocalStorageAuthSession, JSON.stringify(state))
    },
    resetAuthStore: (state) => {
      state.accessToken = ''
      state.refreshToken = ''
      state.userId = ''
      localStorage.setItem(LocalStorageAuthSession, '')
    },
  },
})

export const { setAuthInfo, resetAuthStore } = authSchema.actions

export default authSchema.reducer
