import { useFormik } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { Button, Form, Input, SubmitButton } from 'src/common/components'
import QuillEditor from 'src/common/components/form/QuillEditor'
import { useMutatePersonNote } from 'src/common/hooks'
import { IPersonNote } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../../v2/store/hooks'
import styles from './person-note-form.module.scss'

interface PersonNoteFormProps {
  data?: IPersonNote
  onAfterSave: () => void
  onCancel: () => void
}

const PersonNoteForm = (props: PersonNoteFormProps) => {
  const { data, onAfterSave, onCancel } = props

  const { personId } = useParams<{ personId: string }>()

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const noteMutation = useMutatePersonNote(data ? 'update' : 'create')

  const formik = useFormik({
    initialValues: {
      message: data ? data.message : '',
    },
    onSubmit: async (values) => {
      await noteMutation.mutateAsync({
        ...values,
        personId: data ? undefined : personId,
        chatMessageId: data?.id,
      })

      onAfterSave()
    },
    validationSchema: yup.object().shape({
      message: yup
        .string()
        .required('Note cannot be empty')
        .test('empty', 'Note cannot be empty', (value) => {
          return value?.replace(/<[^>]*>?/gm, '').trim() !== ''
        }),
    }),
  })

  return (
    <Form className={styles.wrapper} providerValue={formik}>
      <div className={styles.inner}>
        <Input
          required
          disabled
          name="createdBy"
          label="Created By"
          tabletWidth="50"
          value={`${userProfile?.user?.firstName} ${userProfile?.user?.lastName}`}
        />

        <Input
          required
          disabled
          name="createdDate"
          label="Created Date"
          tabletWidth="50"
          value={new Date().toLocaleDateString('en-US')}
        />

        <QuillEditor
          required
          name="message"
          label="Note"
          placeholder="Enter your note"
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onCancel}>
          Cancel
        </Button>

        <SubmitButton className={styles.button} loading={formik.isSubmitting}>
          Save
        </SubmitButton>
      </div>
    </Form>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PersonNoteForm,
  'is_update_person_note_enabled',
)
