import { Box, Card, Flex } from '@mantine/core'

import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import { RoleSelect } from '../../role/components/RoleSelect.component'
import useRolesContainer from '../../role/containers/useRoles.container'
import { PoliciesFilter } from '../components/PoliciesFilter.component'
import { PoliciesTable } from '../components/PoliciesTable.component'
import { usePoliciesContainer } from '../containers/Policies.container'
import styles from './role.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Roles' },
]

const PoliciesView = () => {
  const {
    data: dataRoles,
    membershipRole,
    activePolicies,
    handleOnSelectChange,
    handleOnCheckBoxChange,
    updateRoleMutationStatus,
  } = useRolesContainer()

  const {
    data: dataPolicies,
    filteredPolicies,
    handleOnSearchChange,
  } = usePoliciesContainer()

  return (
    dataRoles.length > 0 && (
      <BasicTemplate title="Policies" breadCrumbItems={breadCrumbItems}>
        <Card shadow="lg" radius="lg" className={styles.defaultCard}>
          <Box mt="xl">
            <Flex gap="xl">
              <RoleSelect
                membershipRole={membershipRole}
                membershipRoleList={dataRoles.map((role: any) => ({
                  value: role.id,
                  label: role.name,
                }))}
                handleOnSelectChange={handleOnSelectChange}
              />
              <PoliciesFilter onSearchChange={handleOnSearchChange} />
            </Flex>
            <PoliciesTable
              policies={
                filteredPolicies.length > 0 ? filteredPolicies : dataPolicies
              }
              activePolicies={activePolicies}
              onCheckBoxChange={handleOnCheckBoxChange}
              updateRoleMutationStatus={updateRoleMutationStatus}
            />
          </Box>
        </Card>
      </BasicTemplate>
    )
  )
}

export default withErrorBoundaryAndPolicyCheck(
  PoliciesView,
  'is_get_roles_enabled',
  true,
)
