import { FormikValues, useFormik } from 'formik'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { appLocalStorage } from 'src/common/libs'
import {
  getParamsObjectWithoutPrefix,
  getParamsStringWithPrefix,
} from 'src/default/helpers'

import { useRouter } from '../../../default/helpers/navigation'

const PARAMS_PREFIX_KEY = 'f'

interface UseParamsFormikParams {
  id: string
  initialValues: FormikValues
}

const useParamsFormik = (params: UseParamsFormikParams) => {
  const { id, initialValues } = params

  const { pathname } = useLocation()

  const localStorageId = `params_formik_${id}`

  const router = useRouter()

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  })

  useEffect(() => {
    const initialURLParams = getParamsObjectWithoutPrefix(
      PARAMS_PREFIX_KEY,
      window.location.search,
      {
        arrayFormat: 'bracket',
        parseBooleans: true,
      },
    )

    if (Object.keys(initialURLParams).length) {
      formik.setValues({
        ...initialValues,
        ...initialURLParams,
      })
    } else {
      const currentLocalStorageData = appLocalStorage.getData(localStorageId)
      if (currentLocalStorageData) {
        if (JSON.stringify(initialValues) !== currentLocalStorageData) {
          formik.setValues({
            ...initialValues,
            ...JSON.parse(currentLocalStorageData),
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(formik.values) !== JSON.stringify(initialValues)) {
      const newURLParamsString = getParamsStringWithPrefix(
        PARAMS_PREFIX_KEY,
        formik.values,
        {
          arrayFormat: 'bracket',
          skipEmptyString: true,
        },
      )

      router.push(`${pathname}?${newURLParamsString}`, false)
    } else {
      router.push(pathname, false)
    }

    appLocalStorage.setData(localStorageId, JSON.stringify(formik.values))
  }, [formik.values])

  return formik
}

export default useParamsFormik
