import { Button, Card, Flex, Loader } from '@mantine/core'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import InfiniteScrollTemplate from 'src/v2/commons/templates/InfiniteScrollTemplate'

import UsersTable from '../compoments/UsersTable'
import useAdministrationUsersContainer from '../containers/useUsers.container'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Users' },
]

const UsersAdministrationPage = () => {
  const {
    users,
    isFetching,
    isLoading,
    handlePaginate,
    handleOrderBy,
    filterOrderBy,
    handleEditUser,
    handleCreateUser,
  } = useAdministrationUsersContainer()

  return (
    <InfiniteScrollTemplate
      isLoading={isLoading}
      title="Users"
      header={
        <Button
          variant="filled"
          leftSection={<Icons.Plus />}
          onClick={handleCreateUser}
        >
          Create New User
        </Button>
      }
      breadCrumbItems={breadCrumbItems}
      onScroll={handlePaginate}
      records={{
        totalRecords: users?.totalRecords || 0,
        actualNumberOfRecords: users?.records?.length ?? 0,
      }}
    >
      <Card shadow="lg" radius="md" style={{ padding: '36px' }}>
        <UsersTable
          handleEditUser={handleEditUser}
          users={users}
          handleOrderBy={handleOrderBy}
          filterOrderBy={filterOrderBy}
        />
        {isFetching && !isLoading && (
          <Flex mt="30px" mb="20px" justify="center">
            <Loader size={30} />
          </Flex>
        )}
      </Card>
    </InfiniteScrollTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UsersAdministrationPage,
  'is_get_roles_enabled',
  true,
)
