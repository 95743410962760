import { useField } from 'formik'
import { useEffect, useState } from 'react'

import { Select } from 'src/common/components'
import { IOption } from 'src/common/interfaces'

import { OPPORTUNITY_STATUS } from '../../opportunities.enum'
import parentStyles from '../opportunities-modal.module.scss'

interface SelectOpportunitiesWrapperProps {
  withError: boolean
}

const SelectOpportunitiesWrapper = (props: SelectOpportunitiesWrapperProps) => {
  const [options, setOptions] = useState<IOption[] | null>(null)

  const [{ value }, , { setValue }] = useField('beneStreamStatus')

  useEffect(() => {
    //TODO remove the currentValue hack after the data team does the migration
    let currentValue = value
    if (value === 'SCREENED_WITH_HEALTHFIRST') {
      currentValue = 'SCHEDULED_WITH_HEALTHFIRST'
      setValue('SCHEDULED_WITH_HEALTHFIRST')
    }

    if (!options && currentValue) {
      const actualValues = (() => {
        switch (currentValue) {
          case 'INFO_GATHERING':
            return [
              'INFO_GATHERING',
              'QUICK_SCREEN_COMPLETE',
              'OPT_OUT',
              'REMOVED',
            ]

          case 'QUICK_SCREEN_COMPLETE':
            return [
              'QUICK_SCREEN_COMPLETE',
              'NOT_ELIGIBLE',
              'ENROLLED_ALREADY_ON_AT_TIME_OF_QUICK_SCREEN',
              'POTENTIAL_ELIGIBILITY',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'POTENTIAL_ELIGIBILITY':
            return [
              'POTENTIAL_ELIGIBILITY',
              'ENROLLED_ENROLLED_BY_METROPLUS',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'PENDING_STATE_RESPONSE',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'ENROLLED_ENROLLED_BY_METROPLUS':
            return [
              'ENROLLED_ENROLLED_BY_METROPLUS',
              'PENDING_RENEWAL',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'ENROLLED_ENROLLED_BY_BENESTREAM':
            return [
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'PENDING_RENEWAL',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED':
            return [
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'PENDING_RENEWAL',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'ENROLLED_ALREADY_ON_AT_TIME_OF_QUICK_SCREEN':
            return [
              'ENROLLED_ALREADY_ON_AT_TIME_OF_QUICK_SCREEN',
              'PENDING_RENEWAL',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'PENDING_RENEWAL':
            return [
              'PENDING_RENEWAL',
              'ENROLLED_RENEWED_RENEWAL_CONFIRMED_BY_BENESTREAM',
              'PENDING_STATE_RESPONSE',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'ENROLLED_RENEWED_RENEWAL_CONFIRMED_BY_BENESTREAM':
            return [
              'ENROLLED_RENEWED_RENEWAL_CONFIRMED_BY_BENESTREAM',
              'PENDING_RENEWAL',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'PENDING_STATE_RESPONSE':
            return [
              'PENDING_STATE_RESPONSE',
              'ENROLLED_ENROLLED_BY_METROPLUS',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'RE_SCREENED':
            return [
              'RE_SCREENED',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          //TODO remove this one after the data team does the migration
          // case 'SCREENED_WITH_HEALTHFIRST':
          //   return [
          //     'SCREENED_WITH_HEALTHFIRST',
          //     'ENROLLED_ENROLLED_BY_BENESTREAM',
          //     'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
          //     'NOT_ELIGIBLE',
          //     'OPT_OUT',
          //     'REMOVED',
          //     'RE_SCREENED',
          //   ]

          case 'SCHEDULED_WITH_HEALTHFIRST':
            return [
              'SCHEDULED_WITH_HEALTHFIRST',
              'RE_SCHEDULED_WITH_HEALTHFIRST',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
            ]

          case 'RE_SCHEDULED_WITH_HEALTHFIRST':
            return [
              'RE_SCHEDULED_WITH_HEALTHFIRST',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'NOT_ELIGIBLE',
              'OPT_OUT',
              'REMOVED',
              'RE_SCREENED',
            ]

          case 'NOT_ELIGIBLE':
            return [
              'NOT_ELIGIBLE',
              'ENROLLED_ENROLLED_BY_METROPLUS',
              'ENROLLED_ENROLLED_BY_BENESTREAM',
              'ENROLLED_INDEPENDENT_ENROLLMENT_CONFIRMED',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'OPT_OUT':
            return [
              'OPT_OUT',
              'ENROLLED_ENROLLED_BY_METROPLUS',
              'REMOVED',
              'RE_SCREENED',
              'SCHEDULED_WITH_HEALTHFIRST',
            ]

          case 'REMOVED':
            return ['REMOVED']

          default:
            return []
        }
      })()

      const options = actualValues.reduce((acc: IOption[], value) => {
        const status = OPPORTUNITY_STATUS.find(
          (status) => status.value === value,
        )
        if (status) {
          acc.push(status)
        }
        return acc
      }, [])

      setOptions(options)
    }
  }, [value, options])

  if (!options) {
    return null
  }

  return <SelectOpportunities {...props} options={options} />
}

interface SelectOpportunitiesProps extends SelectOpportunitiesWrapperProps {
  options: IOption[]
}

const SelectOpportunities = (props: SelectOpportunitiesProps) => {
  const { options, withError } = props

  return (
    <Select
      className={parentStyles.fieldWrapper}
      name="beneStreamStatus"
      label="Status"
      options={options}
      placeholder="Select status"
      tabletWidth="50"
      required
      withError={withError}
    />
  )
}

export default SelectOpportunitiesWrapper
