import { formatDate } from 'src/common/helpers'
import { IDMPCaseHistoryItem } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './deactivation-benefit-history-item.module.scss'

interface DeactivationBenefitHistoryItemProps extends IDMPCaseHistoryItem {
  changeStr?: string
}

const DeactivationBenefitHistoryItem = (
  props: DeactivationBenefitHistoryItemProps,
) => {
  const { createdAt, action, user, customer, payload, changeStr } = props

  const description = (() => {
    const userStr = user
      ? `${user?.profile?.firstName ?? ''} ${user?.profile?.lastName ?? ''} (${
          user.email
        })`
      : `${customer?.profile?.firstName ?? ''} ${
          customer?.profile?.lastName ?? ''
        } (${customer?.email})`

    let actionStr =
      changeStr ||
      (() => {
        switch (action) {
          case 'update':
            return 'updated case'
          case 'changeStatus':
            return `changed status to "${payload?.status?.label}"`
          default:
            return `took an action "${action}"`
        }
      })()

    return `${userStr} ${actionStr}`
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.time}>{formatDate(createdAt, 'h:mm A')}</div>

      <div className={styles.description}>{description}</div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitHistoryItem,
  'is_enable_deactivation_benefit_history_item',
  true,
)
