import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { Icons } from 'src/common/components'
import { useRefetchById } from 'src/common/hooks'
import dayjs from 'src/common/libs/dayjs'
import { useNotifications } from 'src/common/notification'
import { PHONE_NUMBER_FOR_APPOINTMENTS, QUERY_IDS } from 'src/default/config'
import { useManageAppointment } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { AppointmentForm, AppointmentResult } from './components'
import styles from './manage-appointment-modal.module.scss'

interface IManagerAppointmentModal {
  clickClose: () => void
  opportunity: any
}

const ManagerAppointmentModal = (props: IManagerAppointmentModal) => {
  const { personId } = useParams<{ personId: string }>()

  const manageAppointment = useManageAppointment()

  const { clickClose, opportunity } = props

  const refetchById = useRefetchById()
  const notifications = useNotifications()

  const [showForm, setShowForm] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const [dateForAppointment, setDateForAppointment] = useState('')
  const [displayAppointmentModal, setDisplayAppointmentModal] = useState(true)
  const [displayAppointmentResultModal, setDisplayAppointmentResultModal] =
    useState(false)

  useEffect(() => {
    if (opportunity?.id) {
      setShowForm(false)
      setShowSpinner(true)

      const setValues = async () => {
        function sleep(ms: number) {
          return new Promise((resolve) => setTimeout(resolve, ms))
        }

        await formik.setValues({
          appointmentDate: dayjs(opportunity.appointmentDate).tz().toString(),
          status: opportunity.status,
          id: opportunity.id,
          personId,
          clinicInformation: opportunity.clinicInformation,
          clinicZipCode: opportunity.clinicZipCode,
          repPhoneNumber: opportunity.repPhoneNumber,
          preferredLanguage: opportunity.preferredLanguage,
          benefitType: opportunity.benefitType,
          confirmAssistantByPerson: opportunity.confirmAssistantByPerson,
          dateForAppointment: dayjs(opportunity.appointmentDate)
            .tz()
            .toString(),
        })
        await sleep(400)

        setShowForm(true)
        setShowSpinner(false)
        return
      }

      setValues()
    }
  }, [opportunity])

  const handleClickButton = () => {
    clickClose()
  }

  const currentDate = dayjs().tz().startOf('day').toDate()

  const formik = useFormik({
    initialValues: {
      id: opportunity?.id,
      personId,
      benefitType: '',
      appointmentDate: '',
      repPhoneNumber: PHONE_NUMBER_FOR_APPOINTMENTS,
      clinicInformation: '',
      clinicZipCode: '',
      preferredLanguage: '',
      status: '',
      confirmAssistantByPerson: false,
      dateForAppointment: '',
    },
    validationSchema: yup.object().shape({
      benefitType: yup.string().required('Benefit Type is required'),
      appointmentDate: yup.date().when('id', {
        is: (id: unknown) => !id,
        then: yup
          .date()
          .required('Appointment Date is required')
          .min(
            currentDate,
            `Appointment Date must be later than ${dayjs(currentDate).format(
              'MM/DD/YYYY',
            )}`,
          ),
        otherwise: yup.date().required('Appointment Date is required'),
      }),
      status: yup.string().required('Status is required'),
      repPhoneNumber: yup
        .string()
        .required('Representative Phone Number is required'),
      clinicInformation: yup
        .string()
        .required('Clinic Information is required'),
      clinicZipCode: yup.string().required('Clinic Zip Code is required'),
      preferredLanguage: yup
        .string()
        .required('Preferred Language is required'),
    }),
    onSubmit: async (values: any) => {
      //TODO ts
      delete values.dateForAppointment

      await manageAppointment
        .mutateAsync(values)
        .then((data) => {
          if (data?.data?.id) {
            formik.setValues({ ...formik.values, id: data.data.id })
          }
          if (data?.status === 201 || data?.status === 200) {
            refetchById(QUERY_IDS.GET_OPPORTUNITIES)
            setDateForAppointment(data.data.appointmentDate)
            setDisplayAppointmentModal(false)
            setDisplayAppointmentResultModal(true)
          }
        })
        .catch((error) => {
          if (error?.response?.data?.statusCode === 400) {
            notifications?.show('system', error.response.data.message)
          }
          if (error?.response?.status == 500) {
            notifications?.show('system', error.response.data.error)
          }
        })
    },
  })

  const handleScheduleNewAppointment = () => {
    setDisplayAppointmentResultModal(false)
    setDisplayAppointmentModal(true)
    formik.resetForm()
  }

  const handleViewOpportunity = async () => {
    formik.setFieldValue('dateForAppointment', dateForAppointment)
    setDisplayAppointmentResultModal(false)
    setDisplayAppointmentModal(true)
  }

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContainer}>
        <div onClick={handleClickButton} className={styles.closeIcon}>
          <Icons.Close />
        </div>
        {displayAppointmentModal && (
          <AppointmentForm
            showForm={showForm}
            showSpinner={showSpinner}
            formik={formik}
          />
        )}
        {displayAppointmentResultModal && (
          <AppointmentResult
            benefitType={formik.values.benefitType}
            scheduleNewAppointment={handleScheduleNewAppointment}
            viewOpportunity={handleViewOpportunity}
          />
        )}
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ManagerAppointmentModal,
  'is_create_appointment_enabled',
)
