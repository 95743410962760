import { useEffect, useState } from 'react'

import { BasicBlock, EmptyPersons } from 'src/common/components'
import SuccessAssignTasksModal from 'src/common/components/ui/AssignNewTasksModal/SuccessAssignTasksModal'
import { PAGINATION_LIMIT } from 'src/common/config'
import { concatQueryPages } from 'src/common/helpers'
import { ITask } from 'src/common/interfaces'
import { InfiniteBasicTemplate } from 'src/common/templates'
import { useGetTasks } from 'src/default/hooks'

import { useParamsFormik } from '../../../../common/hooks'
import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import SingleTaskModal from '../components/SingleTaskModal/SingleTaskModal'
import TasksFilter from './TasksFilter/TasksFilter'
import TasksTable from './TasksTable'
import styles from './tasks-page.module.scss'

const TasksPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [singleTask, setSingleTask] = useState<ITask | null>(null)
  const [isSingleTaskModalOpen, setIsSingleTaskModalOpen] =
    useState<boolean>(false)
  const [isUpdateSuccessfullyModalOpen, setIsUpdateSuccessfullyModalOpen] =
    useState<boolean>(false)

  const filterFormik = useParamsFormik({
    id: 'tasks',
    initialValues: {},
  })

  const {
    data: { pages } = { pages: [] },
    fetchNextPage,
    isRefetching,
    isFetchingNextPage,
    isFetched,
    refetch,
  } = useGetTasks({
    organizationId: currentOrganizationId || '',
    type:
      filterFormik.values.type === 'all' ? undefined : filterFormik.values.type,
    subject:
      filterFormik.values.subject === 'all'
        ? undefined
        : filterFormik.values.subject,
    assignedToUserId:
      filterFormik.values.assignedToId === 'all'
        ? undefined
        : filterFormik.values.assignedToId,
    status: filterFormik.values.status,
    priority:
      filterFormik.values.priority === 'all'
        ? undefined
        : filterFormik.values.priority,
    limit: PAGINATION_LIMIT,
    orderBy: 'createdAt DESC',
  })

  useEffect(() => {
    if (isLoading && pages.length > 0) {
      setIsLoading(false)
    }
  }, [pages])

  const handlePageScroll = async () => {
    await fetchNextPage()
  }

  const handleSetSingleTask = (item: ITask) => {
    setSingleTask(item)
    setIsSingleTaskModalOpen(true)
  }

  const tasks = concatQueryPages<ITask>(pages)

  const title = 'Tasks'

  return (
    <InfiniteBasicTemplate
      title={title}
      scrollPromise={handlePageScroll}
      isLoading={isLoading}
      records={{
        totalRecords: pages?.[0]?.data.totalRecords ?? 0,
        actualNumberOfRecords: tasks?.length ?? 0,
      }}
    >
      <BasicBlock
        className={styles.wrapper}
        paddings="big"
        isLoading={pages.length === 0 || isFetchingNextPage}
        isLoadingTop={isRefetching && !isFetchingNextPage}
      >
        <TasksFilter
          providerValue={filterFormik}
          total={pages?.[0]?.data.totalRecords ?? 0}
        />

        {tasks.length ? (
          <TasksTable tasks={tasks} setSingleTask={handleSetSingleTask} />
        ) : isFetched ? (
          <EmptyPersons term="task" />
        ) : null}
      </BasicBlock>

      {isSingleTaskModalOpen ? (
        <SingleTaskModal
          initialData={singleTask}
          refetch={refetch}
          onClose={() => setIsSingleTaskModalOpen(false)}
          setIsUpdateSuccessfullyModalOpen={setIsUpdateSuccessfullyModalOpen}
        />
      ) : null}
      {isUpdateSuccessfullyModalOpen ? (
        <SuccessAssignTasksModal
          title="Task Updated Successfully!"
          description="Task was updated correctly."
          showButtons={false}
          onClose={() => setIsUpdateSuccessfullyModalOpen(false)}
          onClickReviewTasks={() => {}}
        />
      ) : null}
    </InfiniteBasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  TasksPage,
  'is_get_tasks_enabled',
)
