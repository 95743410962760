import { WbfPolicies } from '@workers-benefit-fund/shared-data'
import { useFormik } from 'formik'
import { MouseEvent, useState } from 'react'

import {
  BasicBlock,
  Button,
  Checkbox,
  Form,
  Table,
} from 'src/common/components'
import { capitalize, mapEnrollmentStatus } from 'src/common/helpers'
import { IEnrollment } from 'src/default/interfaces'
import { useAppSelector } from 'src/v2/store/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { useMutatePersonEnrollment } from '../../../../hooks'
import SingleAccountEnrollmentDisableAction from '../SingleAccountEnrollmentDisableAction/SingleAccountEnrollmentDisableAction'
import EnrollmentAddModal from './EnrollmentAddModal/EnrollmentAddModal'
import SingleAccountEnrollmentsModal from './SingleAccountEnrollmentsModal'
import styles from './single-account-enrollments.module.scss'

interface ISingleAccountEnrollments {
  data: IEnrollment[]
  leadName?: string
  tlcNumber?: string
  refetch: () => Promise<any>
  isSuccess: boolean
}

const SingleAccountEnrollments = (props: ISingleAccountEnrollments) => {
  const { data, leadName, tlcNumber, refetch, isSuccess } = props

  const [enrollmentModalIsOpen, setEnrollmentModalIsOpen] = useState(false)

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState<
    string | null
  >(null)

  const personEnrollmentMutation = useMutatePersonEnrollment('update')

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  })

  const handleModalClose = () => {
    setSelectedEnrollmentId(null)
    refetch()
  }

  const handleAddEnrollmentModalClose = () => {
    setEnrollmentModalIsOpen(false)
  }

  const getEnrollmentById = (id: string) => {
    return data.find((enrollment: IEnrollment) => enrollment.id === id)
  }

  const handleClickHasAgreedWithPolicies = async (
    e: MouseEvent<HTMLElement>,
    enrollment: IEnrollment,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    await personEnrollmentMutation
      .mutateAsync({
        id: enrollment.id,
        hasAgreedWithPolicies: !enrollment.hasAgreedWithPolicies,
      })
      .then(() => {
        refetch()
      })
  }

  const selectedEnrollment = getEnrollmentById(selectedEnrollmentId ?? '')

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        paddings="small"
        isLoading={!isSuccess}
        title="Associated Benefit Contract Information"
        header={
          <PolicyCheckHOC policyName="is_create_enrollment_enabled">
            <Button
              className={styles.newButton}
              icon="Plus"
              size="small"
              disabled={false}
              onClick={() => setEnrollmentModalIsOpen(true)}
            >
              New Enrollment
            </Button>
          </PolicyCheckHOC>
        }
      >
        <Form providerValue={formik}>
          <Table className={styles.table}>
            {data.map((enrollment: IEnrollment, i: number) => (
              <Table.Row
                key={enrollment.id}
                className={styles.row}
                onClick={() => setSelectedEnrollmentId(enrollment.id)}
              >
                <Table.Column title="No." width={40}>
                  {i + 1}
                </Table.Column>

                <Table.Column className={styles.abcColumn} title="Contract">
                  {enrollment.associatedBenefitContract.name}
                </Table.Column>

                <Table.Column title="Enrollment Status">
                  {capitalize(
                    mapEnrollmentStatus(
                      enrollment.status,
                      enrollment.associatedBenefitContract.organization.id,
                    ),
                  )}
                </Table.Column>

                <Table.Column title="Is Active">
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.checkboxColumn}
                      name={`${enrollment.id}-inactiveEnrollment`}
                      value={enrollment.isActive}
                    />
                  </div>
                </Table.Column>
                {userProfile.policies.includes(
                  //add ts-ignore until update the share-date witn the last version
                  //@ts-ignore
                  WbfPolicies.is_has_agreed_with_policies_enabled,
                ) ? (
                  <Table.Column
                    title="Has Agreed With Policies"
                    onClick={(e) =>
                      handleClickHasAgreedWithPolicies(e, enrollment)
                    }
                  >
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        className={styles.checkboxColumn}
                        name={`${enrollment.id}-hasAgreedWithPolicies`}
                        value={enrollment.hasAgreedWithPolicies}
                      />
                    </div>
                  </Table.Column>
                ) : null}
                {userProfile.policies.includes(
                  'is_disable_du_pfml_enrollment_enabled',
                ) ? (
                  <Table.Column
                    title="Disable Enrollment"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className={styles.checkboxWrapper}>
                      <SingleAccountEnrollmentDisableAction
                        enrollment={getEnrollmentById(enrollment.id)}
                        refetchEnrollments={refetch}
                      />
                    </div>
                  </Table.Column>
                ) : null}
              </Table.Row>
            ))}
          </Table>
        </Form>
      </BasicBlock>

      {selectedEnrollment ? (
        <SingleAccountEnrollmentsModal
          data={selectedEnrollment}
          refetch={refetch}
          leadName={leadName}
          tlcNumber={tlcNumber}
          onClose={handleModalClose}
        />
      ) : null}

      {enrollmentModalIsOpen ? (
        <EnrollmentAddModal
          onClose={handleAddEnrollmentModalClose}
          refetchEnrollments={refetch}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountEnrollments,
  'is_enable_single_account_view',
  true,
)
