import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetUserParams {
  userId: string
}

const useGetUser = (params: GetUserParams) => {
  const { userId } = params

  return useQuery({
    queryKey: [`getUser`, userId],
    queryFn: async () => await api.get(`/users/${userId}/`),
  })
}

export default useGetUser
