import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benefits-administration-preview-page.module.scss'

const BenefitsAdministrationPreviewPage = () => {
  return (
    <div className={styles.wrapper}>
      <iframe src="/benefits-preview.html" />
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationPreviewPage,
  'is_enable_benefits_administration_view',
  true,
)
