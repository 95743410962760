import { Dayjs } from 'dayjs'

import { Select } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IOption } from 'src/common/interfaces'

import styles from './year-select.module.scss'

interface YearSelectProps {
  date: Dayjs
  options: IOption[]
  onChange: (value: Dayjs) => void
}

const YearSelect = (props: YearSelectProps) => {
  const { date, options, onChange } = props

  const handleChange = (yearValue: string) => {
    const newDate = date.clone().year(Number(yearValue))
    onChange(newDate)
  }

  return (
    <div className={styles.wrapper}>
      <Select
        inputClassName={styles.select}
        name="year"
        placeholder="Year"
        size="small"
        withError={false}
        options={options}
        value={formatDate(date, 'YYYY')}
        onChange={handleChange}
      />
    </div>
  )
}

export default YearSelect
