import { useForm, yupResolver } from '@mantine/form'
import * as yup from 'yup'

const useCreateSmsCampaignFormUseCase = () => {
  const schema = yup.object().shape({
    title: yup.string().required('Required'),
    smsMessage: yup.string().required('Required'),
    sendAt: yup.string().required('Required'),
  })

  const validationsByStep = {
    0: ['title', 'smsMessage', 'sendAt'],
  }

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      title: '',
      smsMessage: '',
      sendAt: '',
      targets: new File([], ''),
      organizationId: '',
    },
    validateInputOnBlur: true,
    validateInputOnChange: false,
    validate: yupResolver(schema),
  })

  const checkIfStepHasError = (currentStep: number) => {
    return !Boolean(
      Object.keys(form.validate().errors).filter((item) =>
        validationsByStep[currentStep as 0].includes(item),
      ).length,
    )
  }

  form.watch('sendAt', ({ value }) => {
    const minutes = new Date(value).getMinutes()
    if (minutes % 15 !== 0) {
      form.setFieldError('sendAt', 'The time can only be in 15 min intervals')
    } else {
      form.clearFieldError('sendAt')
    }
  })

  return {
    form,
    checkIfStepHasError,
  }
}

export default useCreateSmsCampaignFormUseCase
