import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import {
  Button,
  Form,
  Input,
  InputDate,
  InputWithOpenLink,
  Modal,
  Select,
  SubmitButton,
  Textarea,
} from 'src/common/components'
import { IModal, ITask } from 'src/common/interfaces'
import dayjs from 'src/common/libs/dayjs'
import {
  TASK_PRIORITY_OPTIONS,
  TASK_STATUS_OPTIONS,
  TASK_SUBJECT_OPTIONS,
  TASK_TYPE_OPTIONS,
} from 'src/default/config'
import { useGetOrganizationUsers, useMutateSingleTask } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import styles from './single-task-modal.module.scss'

interface SingleTaskModalProps extends IModal {
  initialData?: ITask | null
  refetch: () => Promise<any>
  setIsUpdateSuccessfullyModalOpen: (value: boolean) => void
}

const SingleTaskModal = (props: SingleTaskModalProps) => {
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const currentOrganizationId = userProfile.organization.id || ''
  const { initialData, refetch, onClose, setIsUpdateSuccessfullyModalOpen } =
    props
  const [usersOfTheOrganization, setUsersOfTheOrganization] = useState<any[]>(
    [],
  )

  const { data: organizationUsers, isSuccess } = useGetOrganizationUsers({
    organizationId: currentOrganizationId ?? '',
  })

  const updateTask = useMutateSingleTask()

  useEffect(() => {
    if (organizationUsers?.data?.length && isSuccess) {
      setUsersOfTheOrganization(
        organizationUsers.data.map((organizationUser: any) => ({
          value: organizationUser.user.id,
          label: `${organizationUser.user.firstName} ${organizationUser.user.lastName}`,
        })),
      )
    }
  }, [organizationUsers, isSuccess])

  const formik = useFormik({
    initialValues: initialData
      ? {
          id: initialData.id,
          recordName: `${initialData.person.firstName} ${initialData.person.lastName}`,
          type: initialData.type,
          subject: initialData.subject,
          assignedToId: initialData.assignedTo.id,
          activityDate: dayjs.tz(initialData.activityDate).isValid()
            ? dayjs.tz(initialData.activityDate).format('YYYY-MM-DD')
            : '',
          priority: initialData.priority,
          status: initialData.status,
          createdAt: dayjs.tz(initialData.createdAt).isValid()
            ? dayjs.tz(initialData.createdAt).format('YYYY-MM-DD')
            : '',
          updatedAt: dayjs.tz(initialData.updatedAt).isValid()
            ? dayjs.tz(initialData.updatedAt).format('YYYY-MM-DD')
            : '',
          createdBy: `${initialData?.createdBy?.firstName ?? ''} ${
            initialData?.createdBy?.lastName ?? ''
          }`,
          notes: initialData?.chat?.messages[0]?.message ?? '',
        }
      : {
          id: '',
          assignedToId: '',
        },
    onSubmit: async (values) => {
      await updateTask
        .mutateAsync({
          id: values.id,
          assignedToId: values.assignedToId,
        })
        .then(() => {
          if (onClose) {
            onClose()
            setIsUpdateSuccessfullyModalOpen(true)
            refetch()
          }
        })
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Required'),
    }),
  })

  return (
    <Modal id="singleTaskModal" template="big" onClose={onClose}>
      <Form className={styles.wrapper} providerValue={formik}>
        <div className={styles.title}>Task Information</div>

        <div className={styles.inner}>
          <InputWithOpenLink
            label="Record Name"
            required
            disabled
            name="recordName"
            width="50"
            url={`/leads/${initialData?.person.id}`}
          />

          <Select
            disabled
            required
            name="type"
            label="Type"
            options={TASK_TYPE_OPTIONS}
            width="50"
          />

          <Select
            disabled
            required
            name="subject"
            label="Subject"
            options={TASK_SUBJECT_OPTIONS}
            width="50"
          />

          <Select
            required
            options={usersOfTheOrganization}
            name="assignedToId"
            label="Assigned To"
            placeholder="Assigned To"
            width="50"
          />

          <InputDate
            required
            disabled
            name="activityDate"
            label="Due Date"
            futureDate
            width="50"
          />

          <Select
            disabled
            required
            name="priority"
            label="Priority"
            options={TASK_PRIORITY_OPTIONS}
            width="50"
          />

          <Select
            disabled
            required
            name="status"
            label="Status"
            options={TASK_STATUS_OPTIONS}
            width="50"
          />

          <Input
            disabled
            required
            name="createdBy"
            label="Created By"
            width="50"
          />

          <InputDate
            required
            disabled
            name="createdAt"
            label="Created Date"
            width="50"
          />

          <InputDate
            disabled
            required
            name="updatedAt"
            label="Last Modified Date"
            width="50"
          />

          <Textarea disabled required name="notes" label="Notes" width="100" />

          <PolicyCheckHOC policyName="is_create_task_enabled">
            <div className={styles.footer}>
              <Button
                className={styles.cancelButton}
                template="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <SubmitButton
                className={styles.submitButton}
                iconAfter="ArrowSelect"
                loading={false}
                disabled={!formik.values.type}
              >
                Save
              </SubmitButton>
            </div>
          </PolicyCheckHOC>
        </div>
      </Form>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleTaskModal,
  'is_enable_single_task_modal',
  true,
)
