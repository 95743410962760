import { useEffect, useState } from 'react'

import BasicTemplate, { type BasicTemplateProps } from '../BasicTemplate'

export interface InfiniteBasicTemplateProps extends BasicTemplateProps {
  onScroll: () => void
  records: { totalRecords: number; actualNumberOfRecords: number }
}

const InfiniteBasicTemplate = (
  props: React.PropsWithChildren<InfiniteBasicTemplateProps>,
) => {
  const { onScroll, records, ...rest } = props
  const totalRecords = records?.totalRecords || 0
  const actualNumberOfRecords = records?.actualNumberOfRecords || 0
  const [scrolled, setScrolled] = useState<boolean>(false)
  const tolerance = 5

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const handleScroll = () => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - tolerance
        ) {
          if (!!onScroll) {
            setScrolled(true)
          }
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [])

  useEffect(() => {
    if (scrolled && actualNumberOfRecords < totalRecords) {
      ;(() => {
        if (onScroll) {
          onScroll()
        }
        setScrolled(false)
      })()
    }
  }, [scrolled, actualNumberOfRecords, totalRecords])

  return <BasicTemplate {...rest} />
}

export default InfiniteBasicTemplate
