import cx from 'classnames'
import { useField } from 'formik'

import { FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import styles from './radios.module.scss'

interface RadiosProps extends IField {
  template?: 'buttons' | 'default'
}

const Radios = (props: RadiosProps) => {
  const { name, options, disabled, template = 'default' } = props

  const [field, , { setValue }] = useField({ name })

  const handleChange = (value: string) => {
    if (!disabled) {
      setValue(value)
    }
  }

  return (
    <FieldWrapper {...props}>
      <div className={cx(styles.wrapper, styles[`${template}Template`])}>
        {options?.map((o) => (
          <div
            key={o.value}
            className={cx(
              field.value === o.value && styles.active,
              disabled && styles.disabled,
            )}
            onClick={() => handleChange(o.value)}
          >
            <span />
            {o.label}
          </div>
        ))}
      </div>
    </FieldWrapper>
  )
}

export default Radios
