import * as yup from 'yup'

export interface IBenefitForm {
  content: {
    public: {
      [lang: string]: string
    }
    private: {
      [lang: string]: string
    }
  }
  metaData: {
    name: {
      [lang: string]: string
    }
  }
  buttons: {
    [lang: string]: {
      name: string
      link: string
      useNewTab: boolean
    }[]
  }
  icons: {
    public: string
    private: string
  }
}

export const initialValues = {
  content: {
    public: {
      en: '',
    },
    private: {
      en: '',
    },
  },
  metaData: {
    name: {
      en: '',
    },
    order: undefined,
  },
  buttons: {
    en: [
      {
        name: '',
        link: '',
        useNewTab: false,
      },
      {
        name: '',
        link: '',
        useNewTab: false,
      },
    ],
  },
  icons: {
    public: '',
    private: '',
  },
}

export const LANG_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'so', label: 'Somali' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'om', label: 'Oromo' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'fr', label: 'French' },
  { value: 'es', label: 'Spanish' },
  { value: 'zh', label: 'Chinese (simplified)' },
]
