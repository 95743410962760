import { UseFormReturnType } from '@mantine/form'
import { PersonModel } from '@v2/domains/person/models/person.model'
import UseaAssignSearchUseCase from '@v2/domains/person/useCases/search/useAssignSearch.useCase'

import {
  type MergedPersonModel,
  type RecordInformationFormValues,
  RecordInformationTableData,
} from '../models/merging-tool.model'
import useRecordInformationUseCase from '../useCases/useRecordInformation.usecase'

interface UseMergingToolContainer {
  tableData: RecordInformationTableData[]
  recodrInformationForm: UseFormReturnType<RecordInformationFormValues | {}>
  setEntireColumn: (id: string) => void
  isChecked: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => boolean
  setCellValue: (
    field: keyof MergedPersonModel,
    accessor: MergedPersonModel['id'],
  ) => void
  onSubmit: (
    values: RecordInformationFormValues,
    primaryEntityId: string,
  ) => void
  handleClearStoreSearch: () => void
  mergeMutationStatus: {
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
  }
  mergeResponse: PersonModel | undefined
}

const useMergingToolContainer = (): UseMergingToolContainer => {
  const {
    tableData,
    form: recodrInformationForm,
    setCellValue,
    setEntireColumn,
    isChecked,
    onSubmit,
    mergeMutationStatus,
    mergeResponse,
  } = useRecordInformationUseCase()

  const { handleClearStoreSearch } = UseaAssignSearchUseCase()

  return {
    tableData,
    recodrInformationForm,
    setEntireColumn,
    setCellValue,
    isChecked,
    onSubmit,
    handleClearStoreSearch,
    mergeMutationStatus,
    mergeResponse,
  }
}

export default useMergingToolContainer
