import cx from 'classnames'
import { useField, useFormikContext } from 'formik'
import { useEffect } from 'react'

import { Input, Select } from 'src/common/components'
import { GENDER_OPTIONS } from 'src/common/config'
import { IField } from 'src/common/interfaces'

import styles from './select-gender.module.scss'

const SelectGender = (props: IField) => {
  const { tabletWidth, name, inputClassName, disabled } = props
  const [field] = useField({ name })

  const isNonBinary = field.value === 'Non-Binary'

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (!isNonBinary && field.value) {
      setFieldValue('otherGender', '')
    }
  }, [field.value])

  return (
    <div
      className={cx(styles.wrapperSelectGender)}
      style={{ width: `calc((100% - 56px) / ${100 / +(tabletWidth || 100)})` }}
    >
      <div
        className={cx(
          styles.selectGender,
          isNonBinary && styles.selectWithInput,
        )}
      >
        <Select {...props} options={GENDER_OPTIONS} tabletWidth="100" />
      </div>
      {isNonBinary ? (
        <div className={styles.inputGender}>
          <Input
            inputClassName={inputClassName}
            disabled={disabled}
            required
            name="otherGender"
            tabletWidth="100%"
          />
        </div>
      ) : null}
    </div>
  )
}

export default SelectGender
