import { useState } from 'react'

import { Button, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'
import { ModalResult } from 'src/default/components'
import { PRIVATE_ROUTES } from 'src/default/config'
import { basicApiErrorHandling } from 'src/default/helpers'
import { useRouter } from 'src/default/helpers/navigation'
import { useDeleteUser } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './user-form-delete-modal.module.scss'

interface UserFormDeleteModalProps extends IModal {
  userId?: string
}

const UserFormDeleteModal = (props: UserFormDeleteModalProps) => {
  const { userId = '', onClose } = props

  const router = useRouter()

  const [result, setResult] = useState<string | null>(null)

  const { mutateAsync: deleteUser, isPending } = useDeleteUser(userId)

  const handleSubmit = async () => {
    await deleteUser()
      .then(() => {
        setResult('success')
      })
      .catch((error) => {
        setResult(basicApiErrorHandling(error))
      })
  }

  const handleClose = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.USERS.path)
    }
  }

  const handleCloseResult = () => {
    if (result === 'success') {
      router.push(PRIVATE_ROUTES.USERS.path)
    } else {
      setResult(null)
    }
  }

  return (
    <>
      <Modal id="deleteUser" template="small" onClose={handleClose}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Delete User</div>

          <div className={styles.subtitle}>
            Are you sure you want to delete this user?
          </div>

          <div className={styles.buttons}>
            <Button
              disabled={isPending}
              size="small"
              template="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button loading={isPending} size="small" onClick={handleSubmit}>
              Delete User
            </Button>
          </div>
        </div>
      </Modal>

      {result !== null ? (
        <ModalResult
          isSuccess={result === 'success'}
          description={
            result !== 'success' ? result : 'User deleted successfully'
          }
          onClose={handleCloseResult}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserFormDeleteModal,
  'is_enable_user_form_delete_modal',
  true,
)
