import cx from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'

import { Button, Form, Icons, InputSearch } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import NycLicenseLookupResult from './NycLicenseLookupResult'
import styles from './nyc-lookup.module.scss'

const NycLicenseLookup = () => {
  const [showLookup, setShowLookup] = useState<boolean>(false)
  const [showResults, setShowResults] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      findBy: 'license_number',
      findValue: '',
    },
    onSubmit: async () => {},
  })

  const handleClickButton = () => {
    setShowLookup(!showLookup)
    setShowResults(false)
    formik.setFieldValue('findValue', '')
  }

  const handleFindBy = (findBy: string) => {
    formik.setFieldValue('findValue', '')
    formik.setFieldValue('findBy', findBy)
    setShowResults(false)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (formik.values.findValue !== '') {
      if (event.code === 'Enter' || event.keyCode === 13) {
        setShowResults(true)
      } else {
        if (showResults) {
          setShowResults(false)
        }
      }
    }
  }

  return (
    <div className={styles.lookupContainer}>
      <Button
        onClick={handleClickButton}
        icon="CheckCircle"
        size="small"
        className={styles.button}
      >
        NYC License Lookup
      </Button>
      {showLookup && (
        <div className={styles.floatPopupContainer}>
          <div className={styles.titleContainer}>
            <div>NYC License Lookup</div>
            <div onClick={handleClickButton} className={styles.closeIcon}>
              <Icons.Close />
            </div>
          </div>
          <div className={styles.nycLinkWrapper}>
            <span>Lookup the users license information on</span>
            <a
              href="https://data.cityofnewyork.us/Transportation/For-Hire-Vehicles-FHV-Active-Drivers/xjfq-wh2d/data"
              target="_blank"
              className={styles.nycLink}
            >
              NYC OpenData
            </a>
          </div>
          <div className={styles.btnGroup}>
            <div
              onClick={() => handleFindBy('license_number')}
              className={cx(
                formik.values.findBy === 'license_number' &&
                  styles.activeButton,
              )}
            >
              By License
            </div>
            <div
              onClick={() => handleFindBy('name')}
              className={cx(
                formik.values.findBy === 'name' && styles.activeButton,
              )}
            >
              By Name
            </div>
          </div>
          <div>
            <Form providerValue={formik}>
              <InputSearch
                className={styles.input}
                name="findValue"
                size="middle"
                placeholder={
                  formik.values.findBy === 'license_number'
                    ? 'Enter License Number'
                    : 'Enter Name'
                }
                onKeyDown={handleKeyPress}
                type={
                  formik.values.findBy === 'license_number' ? 'number' : 'text'
                }
              />
            </Form>
          </div>

          {showResults ? <NycLicenseLookupResult {...formik.values} /> : null}
        </div>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  NycLicenseLookup,
  'is_nyc_lookup_enabled',
)
