import { Radios } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

export const HEALTHCARE_RADIO_OPTIONS = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Unsure', value: 'UNSURE' },
]

interface CreateLeadHealthcareRadiosProps {
  name: string
  label: string
}

const CreateLeadHealthcareRadios = (props: CreateLeadHealthcareRadiosProps) => {
  const { name, label } = props

  return (
    <Radios
      name={name}
      label={label}
      options={HEALTHCARE_RADIO_OPTIONS}
      template="buttons"
    />
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateLeadHealthcareRadios,
  'is_enable_create_lead_healthcare_radios',
  true,
)
