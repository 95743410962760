import { useQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface GetUserParams {
  userId: string
}

//TODO - Why we have 2 useGetUser hooks?

const useGetUserV2 = (params: GetUserParams) => {
  const { userId } = params

  return useQuery({
    queryKey: [`getUserV2`, userId],
    queryFn: async () => await api.get(`/v2/users/${userId}/`),
  })
}

export default useGetUserV2
