import { ActionMenu, Icons, Status } from 'src/common/components'
import { IEnrollment } from 'src/default/interfaces'
import PolicyCheckHOC from 'src/v2/commons/HOC/policyCheckHOC'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../v2/store/hooks'
import ScheduleAppointment from './ScheduleAppointment/ScheduleAppointment'
import styles from './driver-form-header.module.scss'

interface DriverFormHeaderProps {
  customerType: 'lead' | 'account'
  setShowUpdatePerson: () => void
  onDeletePerson: () => void
  enrollments?: IEnrollment[]
}

const DriverFormHeader = (props: DriverFormHeaderProps) => {
  const { customerType, enrollments, setShowUpdatePerson, onDeletePerson } =
    props

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const { label, color } = (() => {
    if (customerType === 'account') {
      return { label: 'Account', color: 'grey' }
    }

    return { label: 'Lead', color: 'grey' }
  })()

  const isActiveInIDGNew = enrollments?.filter(
    (enrollment: IEnrollment) =>
      enrollment.associatedBenefitContract.name.includes('BCF - NY') &&
      enrollment.isVerified,
  )

  const showScheduleAppointment =
    customerType === 'account' &&
    isActiveInIDGNew &&
    isActiveInIDGNew.length > 0

  return (
    <>
      <Status className={styles.status} size="big" color={color}>
        {label}
      </Status>

      {showScheduleAppointment && (
        <ScheduleAppointment className={styles.scheduleAppointment} />
      )}

      <PolicyCheckHOC policyName="is_update_user_enabled">
        <div className={styles.actionsMenu}>
          <ActionMenu
            items={[
              {
                label: 'Update',
                color: 'blue',
                icon: <Icons.Edit />,
                onClick: setShowUpdatePerson,
                enable: userProfile.policies.includes('is_update_user_enabled'),
              },
              {
                label: 'Delete',
                color: 'red',
                icon: <Icons.TrashCross />,
                onClick: onDeletePerson,
                enable: userProfile.policies.includes(
                  'is_person_permanent_delete_enabled',
                ),
              },
            ]}
          />
        </div>
      </PolicyCheckHOC>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DriverFormHeader,
  'is_enable_driver_form_header',
  true,
)
