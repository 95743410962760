import { useLocation } from 'react-router-dom'

import { Portal } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BreadcrumbsItem from './BreadcrumbsItem'
import styles from './breadcrumbs.module.scss'

interface BreadcrumbsProps {
  autoRoot?: boolean
  items: { label: string; routeTo?: string }[]
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { autoRoot = true, items } = props

  const { pathname } = useLocation()
  const pathnames = pathname.split('/').filter((x) => x)

  const currentItems = (() => {
    let result = [...items]

    if (autoRoot) {
      result.unshift({
        label: pathnames[1],
        routeTo: `/${pathnames[1]}`,
      })
    }

    return result
  })()

  return (
    <Portal.Wrapper id="breadcrumbs" isAdaptive={false}>
      <nav className={styles.wrapper}>
        {currentItems.map((item, index) => (
          <BreadcrumbsItem
            key={`${item.label}-${index}`}
            {...item}
            isLast={index === currentItems.length - 1}
          />
        ))}
      </nav>
    </Portal.Wrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Breadcrumbs,
  'is_enable_breadcrumbs',
  true,
)
