export const cleanStringToBeUrlRedeable = (value?: string) => {
  if (typeof value === 'undefined') {
    return
  }
  const charsToRemove = /[_;.,/]/g
  return value
    .replace(charsToRemove, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .toLowerCase()
}
