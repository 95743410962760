import cx from 'classnames'
import { useField, useFormik } from 'formik'
import { useState } from 'react'

import { Form, Modal, Select } from 'src/common/components'
import { IModal } from 'src/common/interfaces'
//TODO mb refactor this import
import { LANG_OPTIONS } from 'src/default/pages/Administration/BenefitsAdministration/MutateBenefitsAdministrationPage/mutateBenefitsAdministrationPageConfig'

import styles from './mutate-benefits-administration-add-lang-modal.module.scss'

interface MutateBenefitsAdministrationAddLangModalProps extends IModal {
  onSelect: (newLang: string) => void
}

const MutateBenefitsAdministrationAddLangModal = (
  props: MutateBenefitsAdministrationAddLangModalProps,
) => {
  const { onSelect, onClose } = props

  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const [field] = useField('metaData.name')

  const formik = useFormik({
    initialValues: {
      language: '',
    },
    onSubmit: () => {},
  })

  const handleCustomEvents = (eventId: string, event: boolean) => {
    if (eventId === 'toggleList') {
      setIsSelectOpen(event)
    }
  }

  const filteredOptions = LANG_OPTIONS.filter(
    (option) => !Object.keys(field.value).includes(option.value),
  )

  return (
    <Modal id="mutateBenefitsAdministrationNewLang" onClose={onClose}>
      <Form
        className={cx(styles.wrapper, isSelectOpen && styles.isSelectOpen)}
        providerValue={formik}
      >
        <Select
          optionsWrapperClassName={styles.optionsWrapper}
          label="Language"
          name="selectLanguage"
          placeholder="Select Language"
          options={filteredOptions}
          value={formik.values.language}
          withError={false}
          onChange={onSelect}
          onCustomEvent={handleCustomEvents}
        />
      </Form>
    </Modal>
  )
}

export default MutateBenefitsAdministrationAddLangModal
