import { Button } from '@mantine/core'

export default {
  Button: Button.extend({
    styles: (theme, params) => ({
      defaultProps: {
        variant: 'filled',
      },
      root: {
        borderRadius: '8px',
        backgroundColor: !params.variant ? theme.colors.blue[6] : undefined,
      },
    }),
  }),
}
