import { Icons } from 'src/common/components'
import { Triggers } from 'src/default/components'
import { useDeleteDocument } from 'src/default/hooks'

import styles from './single-account-documents-delete-action.module.scss'

interface SingleAccountDocumentsDeleteActionProps {
  documentId: string
  name: string
  refetch: () => Promise<any>
  isOpen?: boolean
}

const SingleAccountDocumentsDeleteAction = (
  props: SingleAccountDocumentsDeleteActionProps,
) => {
  const { documentId, name, refetch, isOpen } = props

  const deleteDocumentMutation = useDeleteDocument(documentId)

  const handleRemove = async () => {
    await deleteDocumentMutation.mutateAsync().then(() => {
      refetch()
    })
  }

  return (
    <Triggers.Delete
      className={styles.wrapper}
      name={name}
      title="Delete Document?"
      description={`Are you sure you want to delete the ${name} document? This action cannot be undone.`}
      onAction={handleRemove}
      isOpen={isOpen}
    >
      <Icons.Trash />
    </Triggers.Delete>
  )
}

export default SingleAccountDocumentsDeleteAction
