import { Link } from 'react-router-dom'

import { Button } from 'src/common/components'
import { useEmailSenderAndManagerEmailTemplateContext } from 'src/common/context'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import { setNewCommunication } from '../../../../../v2/domains/communication/models/communication.store'
import { useAppDispatch } from '../../../../../v2/store/hooks'
import styles from './view-email.module.scss'

const ViewEmail = () => {
  const dispatch = useAppDispatch()

  const {
    setEmailBody,
    setSubject,
    setIsNewEmail,
    setIsSuccessSendEmail,
    setIsViewEmail,
    subject,
    emailBody,
    fromEmail,
    replyEmail,
    setEmailDomainSignature,
    setReplyEmailId,
    setFromEmailId,
    filesAttachments,
    setFilesAttachments,
    setAttachments,
  } = useEmailSenderAndManagerEmailTemplateContext()

  const handleBackButton = () => {
    setIsNewEmail(true)
    setIsSuccessSendEmail(false)
    setIsViewEmail(false)
    setSubject('')
    setEmailBody('')
    setEmailDomainSignature('')
    setReplyEmailId('')
    setFromEmailId('')
    dispatch(setNewCommunication(false))
    setFilesAttachments([])
    setAttachments([])
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      <div className={styles.viewInformation}>View Email</div>
      <div className={styles.viewSubject}>{subject}</div>
      <div className={styles.fromLabel}>
        <label className={styles.labelInformation}>From:</label>
        <>{fromEmail}</>
      </div>
      <div className={styles.replyLabel}>
        <label className={styles.labelInformation}>Reply To:</label>
        <>{replyEmail}</>
      </div>
      <div
        className={styles.viewEmailBody}
        dangerouslySetInnerHTML={{ __html: emailBody }}
      ></div>
      <PolicyCheckHOC policyName="is_get_emails_attachment_url_enabled">
        <div>
          {filesAttachments?.length > 0 && (
            <div className={styles.attachmentsTitle}>Attachments</div>
          )}
          <div className={styles.attachmentsContainer}>
            {filesAttachments?.map((attachment: any, key: any) => {
              const urlToView = `/attachment/${
                attachment.fileUrl.split('amazonaws.com/')[1]
              }/email`
              return (
                <Link
                  to={urlToView}
                  key={key}
                  className={styles.attachment}
                  target="_blank"
                >
                  {attachment.fileName}
                </Link>
              )
            })}
          </div>
        </div>
      </PolicyCheckHOC>
      <div className={styles.backButtonContainer}>
        <Button
          onClick={handleBackButton}
          className={styles.altButton}
          size="small"
        >
          Back
        </Button>
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ViewEmail,
  'is_get_email_by_id_enabled',
)
