import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { BasicTemplateWrapper as BasicTemplateBenestreamWrapper } from 'src/benestream/templates'
import BenestreamTheme from 'src/benestream/theme'
import { Loader } from 'src/common/components'
import { BENESTREAM_ORG_ID } from 'src/common/config'
import OrgApprovalModal from 'src/common/context/AppContext/OrgApprovalModal/OrgApprovalModal'
import { useChangeOrganization } from 'src/common/hooks'
import { cleanStringToBeUrlReadable } from 'src/default/helpers/clean-string'
import { BasicTemplateWrapper } from 'src/default/templates'
import DefaultTheme from 'src/default/theme'
import { CurrentTheme } from 'src/v2/domains/ui/models/ui.model'

import { navigationState, useRouter } from '../../../default/helpers/navigation'
import { setShowApproveToChangeOrganization } from '../../../v2/domains/organization/models/organization.store'
import { resetAccountStoreAccount } from '../../../v2/domains/person/models/account/assignAccount.store'
import { resetLeadStoreLead } from '../../../v2/domains/person/models/leads/assignLead.store'
import { setCurrentTheme } from '../../../v2/domains/ui/models/ui.store'
import { useAppDispatch, useAppSelector } from '../../../v2/store/hooks'

const CRMTemplateWrapper = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  navigationState.navigate = useNavigate()
  navigationState.location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [isValidAccessOrganization, setIsValidAccessOrganization] =
    useState(true)
  const changeOrganizationMutation = useChangeOrganization()

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const globalSearchChangeOrganization = useAppSelector(
    (state) => state.organization.globalSearchChangeOrganization,
  )

  const { organizationsByUser, showApproveToChangeOrganization } =
    useAppSelector((state) => state.organization)

  const Wrapper =
    currentTheme === CurrentTheme.BENESTREAM
      ? BasicTemplateBenestreamWrapper
      : BasicTemplateWrapper

  const { pathname: location } = useLocation()
  const paths = location.split('/').filter(Boolean)
  const currentOrganizationName = paths[0]

  const Theme =
    currentTheme === CurrentTheme.BENESTREAM ? BenestreamTheme : DefaultTheme

  const getCurrentTheme = (organizationId: string) => {
    return organizationId === BENESTREAM_ORG_ID
      ? CurrentTheme.BENESTREAM
      : CurrentTheme.DEFAULT_360
  }

  const handleChangeOrganization = async () => {
    const newOrganization = organizationsByUser.find(
      (organization) =>
        organization.id === globalSearchChangeOrganization.newOrganizationId,
    )

    await changeOrganizationMutation
      .mutateAsync({
        organizationId: globalSearchChangeOrganization.newOrganizationId,
        membershipId: globalSearchChangeOrganization.newMembershipId,
      })
      .then(() => {
        dispatch(resetLeadStoreLead())
        dispatch(resetAccountStoreAccount())
        dispatch(
          setCurrentTheme(
            getCurrentTheme(globalSearchChangeOrganization.newOrganizationId),
          ),
        )
        router.push(
          `${cleanStringToBeUrlReadable(newOrganization?.name)}${
            globalSearchChangeOrganization.newPath
          }`,
          false,
        )
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 600)
  }, [])

  useEffect(() => {
    document
      .getElementsByTagName('body')[0]
      ?.setAttribute('data-theme', currentTheme)
  }, [currentTheme])

  useEffect(() => {
    if (
      userProfile.organization &&
      userProfile.organization.id !== '' &&
      currentOrganizationName !==
        cleanStringToBeUrlReadable(userProfile.organization.name)
    ) {
      const newOrganization = organizationsByUser?.find(
        (organization) =>
          cleanStringToBeUrlReadable(organization.name) ===
          currentOrganizationName,
      )
      const newMembershipId = newOrganization?.memberships?.at(0)?.id

      if (
        typeof newOrganization === 'undefined' &&
        typeof newMembershipId === 'undefined'
      ) {
        return
      }

      changeOrganizationMutation
        .mutateAsync({
          organizationId: newOrganization?.id ?? '',
          membershipId: newMembershipId ?? '',
        })
        .then((response) => {
          if (!response?.data?.success && response?.data?.statusCode === 403) {
            setIsValidAccessOrganization(false)
            return
          }
          if (currentTheme !== getCurrentTheme(newOrganization?.id ?? '')) {
            dispatch(
              setCurrentTheme(getCurrentTheme(newOrganization?.id ?? '')),
            )
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [location])

  if (!isValidAccessOrganization) {
    return (
      <>
        You can't not access to this organization, please contact with you
        administrator.
      </>
    )
  }

  return (
    <>
      <Wrapper>
        <Theme />
        {isLoading ? <Loader isLoading isShowWrapper fullScreen /> : <Outlet />}
        {showApproveToChangeOrganization && (
          <OrgApprovalModal
            onSubmit={handleChangeOrganization}
            onClose={() => dispatch(setShowApproveToChangeOrganization(false))}
          />
        )}
      </Wrapper>
    </>
  )
}

export default CRMTemplateWrapper
