interface ObjectItems {
  [key: string]: Array<{
    label: string
    id: string
    requiredHeaders: string[]
    acceptedHeaders: string[]
  }>
}

const REQUIRED_INSERT_HEADERS = ['lastName', 'email', 'abcIds']

const REQUIRED_UPDATE_HEADERS = ['id']

const REQUIRED_UPDATE_DOCUMENT_HEADERS = [
  'personId',
  'documentType',
  'documentNumber',
]

const ACCEPTED_OPPORTUNITY_HEADERS = [
  'id',
  'beneStreamStatus',
  'reasonForRefusal',
  'isExpired',
  'expirationDate',
  'screeningCompletedAt',
  'markedAsEligibleAt',
  'screeningCompletedById',
  'markedAsEligibleById',
]

const ACCEPTED_ENROLLMENT_HEADERS = [
  'id',
  'status',
  'isVerified',
  'reasonsForDeactivation',
  'deactivationDate',
  'isActive',
  'startDate',
  'isExpired',
  'expirationDate',
  'sfdcId',
  'isInWhitelist',
  'funnelStage',
]

const ALL_ACCEPTED_HEADERS = [
  'abcIds',
  'id',
  'sfdcId',
  'isVerified',
  'email',
  'isEmailVerified',
  'firstName',
  'lastName',
  'birthDate',
  'isPhoneVerified',
  'companies',
  'gender',
  'drivingStates',
  'country',
  'street',
  'state',
  'city',
  'postalCode',
  'source',
  'rating',
  'browserLanguage',
  'preferredLanguage',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'notesId',
  'createdById',
  'password',
  'googleId',
  'phoneNumber',
  'assignedToId',
  'updatedById',
  'futureMemberId',
  'futureCompanyId',
  'otherEmail',
  'otherGender',
  'otherPreferredLanguage',
  'nationBuilderId',
  'otherPhoneNumber',
  'citizenship',
  'aonId',
  'isTest',
  'haveHealthInsurance',
  'coverageThroughTheState',
  'pay20PerMonth',
  'metroplusId',
]

const BENESTREAM_ACCEPTED_PERSON_HEADERS = [
  'id',
  'email',
  'abcIds',
  'sfdcId',
  'isVerified',
  'isPhoneVerified',
  'isEmailVerified',
  'otherEmail',
  'password',
  'googleId',
  'birthDate',
  'phoneNumber',
  'otherPhoneNumber',
  'companies',
  'gender',
  'otherGender',
  'drivingStates',
  'country',
  'street',
  'state',
  'city',
  'postalCode',
  'source',
  'rating',
  'browserLanguage',
  'preferredLanguage',
  'futureMemberId',
  'futureCompanyId',
  'otherPreferredLanguage',
  'haveHealthInsurance',
  'coverageThroughTheState',
  'pay20PerMonth',
  'nationBuilderId',
  'metroplusId',
  'aonId',
  'citizenship',
  'isTest',
  'assignedTo',
  'primaryPerson',
  'agreeToPrivacyWaiver',
  'type',
  'managerName',
  'worksite',
  'firstName',
  'lastName',
  'isNewHire',
  'dateOfHire',
  'isFullTime',
  'shift',
  'medicalTier',
  'employeeId',
  'severancePayment',
  'layoffDate',
  'gender',
  'householdSize',
  'householdMonthlyIncome',
  'birthDate',
  'relationToApplicant',
  'monthlyIncome',
  'incomes',
  'isPregnant',
  'haveDisability',
  'isEverInFosterCare',
  'doYouHaveMA',
  'numberOfExpectedChildren',
  'onCompanyPlan',
  'accessToCompanyPlan',
  'CHIPRenewalDate',
  'MARenewalDate',
  'nyepRenewalDate',
  'haveMedicaidChipNYEP',
  'isEnrolledToMedicare',
  'areYouOnSSIOrSSDI',
  'anyoneInHouseholdUnder19',
  'citizenship',
  'taxFilingStatus',
  'medicaidIdNumber',
  'medicareIdNumber',
  'socialSecurityNumber',
  'applicationNumber',
]

export const dataLoaderObjects: ObjectItems = {
  insert: [
    {
      label: 'Worker 360 person',
      id: 'workerPerson',
      requiredHeaders: REQUIRED_INSERT_HEADERS,
      acceptedHeaders: ALL_ACCEPTED_HEADERS,
    },
    {
      label: 'Benestream person',
      id: 'benestreamPerson',
      requiredHeaders: REQUIRED_INSERT_HEADERS,
      acceptedHeaders: BENESTREAM_ACCEPTED_PERSON_HEADERS,
    },
    {
      label: 'Enrollment',
      id: 'enrollment',
      requiredHeaders: REQUIRED_INSERT_HEADERS,
      acceptedHeaders: ACCEPTED_ENROLLMENT_HEADERS,
    },
    {
      label: 'Document Person',
      id: 'documentPerson',
      requiredHeaders: REQUIRED_UPDATE_DOCUMENT_HEADERS,
      acceptedHeaders: REQUIRED_UPDATE_DOCUMENT_HEADERS,
    },
  ],
  update: [
    {
      label: 'Worker 360 person',
      id: 'workerPerson',
      requiredHeaders: REQUIRED_UPDATE_HEADERS,
      acceptedHeaders: ALL_ACCEPTED_HEADERS,
    },
    {
      label: 'Benestream person',
      id: 'benestreamPerson',
      requiredHeaders: REQUIRED_UPDATE_HEADERS,
      acceptedHeaders: BENESTREAM_ACCEPTED_PERSON_HEADERS,
    },
    {
      label: 'Opportunity',
      id: 'opportunity',
      requiredHeaders: REQUIRED_UPDATE_HEADERS,
      acceptedHeaders: ACCEPTED_OPPORTUNITY_HEADERS,
    },
    {
      label: 'Enrollment',
      id: 'enrollment',
      requiredHeaders: REQUIRED_UPDATE_HEADERS,
      acceptedHeaders: ACCEPTED_ENROLLMENT_HEADERS,
    },
    {
      label: 'Document Person',
      id: 'documentPerson',
      requiredHeaders: REQUIRED_UPDATE_DOCUMENT_HEADERS,
      acceptedHeaders: REQUIRED_UPDATE_DOCUMENT_HEADERS,
    },
  ],
}
