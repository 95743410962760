import { Pagination } from '@workers-benefit-fund/wbf-ui-components'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { BasicBlock, Checkbox, Form, Table } from 'src/common/components'
import { TIMEZONE } from 'src/common/config'
import { concatQueryPages, toCamelCase } from 'src/common/helpers'
import dayjs from 'src/common/libs/dayjs'
import { useGetSubmissionsByOrganization } from 'src/default/hooks'
import { IFormSubmission } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../../../v2/store/hooks'
import FormSubmissionsModal from './FormSubmissionModal/FormSubmissionsModal'
import styles from './single-account-form-submissions.module.scss'

const SingleAccountFormSubmissions = () => {
  const LIMIT_BY_PAGE = 3
  const [paginationOffset, setPaginationOffset] = useState(0)
  const { personId } = useParams<{ personId: string }>()
  const [formModalIsOpen, setFormModalIsOpen] = useState(false)
  const [selectedFormId, setSelectedFormId] = useState<string>('')

  const userProfile = useAppSelector((state) => state.user.userProfile)

  const formSubmissions = useGetSubmissionsByOrganization({
    organizationId: userProfile.organization.id ?? '',
    personId: personId,
    limit: LIMIT_BY_PAGE,
    offset: paginationOffset,
    orderBy: 'createdAt DESC',
  })

  const totalRecords = formSubmissions.data?.pages[0].data?.totalRecords

  const pages = concatQueryPages<IFormSubmission>(
    formSubmissions.data?.pages ?? [],
  )

  const getFormsWithPayload = (forms: IFormSubmission[]) => {
    return forms.map((form) => {
      const payload = form?.payload?.steps?.reduce((acc: any, step) => {
        ;(step?.fields ?? []).forEach((field: any) => {
          if (field?.label) {
            acc[toCamelCase(field.label)] = field?.value
          }
        })
        return acc
      }, {})

      return {
        ...form,
        payload,
      }
    })
  }

  const forms = getFormsWithPayload(pages)

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  })

  const handleSelectedForm = (id: string) => {
    setSelectedFormId(id)
    setFormModalIsOpen(true)
  }

  const handleOnCloseModal = () => {
    setFormModalIsOpen(false)
    setSelectedFormId('')
  }

  return (
    <>
      <BasicBlock
        className={styles.wrapper}
        paddings="small"
        isLoading={!formSubmissions.isSuccess}
        title="Form Submissions"
      >
        {forms.length ? (
          <Form providerValue={formik}>
            <Table className={styles.table}>
              {forms?.map((form, index) => (
                <Table.Row
                  key={form.id}
                  className={styles.row}
                  onClick={() => handleSelectedForm(form.id ?? '')}
                >
                  <Table.Column title="No." width={40}>
                    {paginationOffset + index + 1}
                  </Table.Column>
                  <Table.Column title="Form Name">
                    {form?.template?.name}
                  </Table.Column>
                  <Table.Column title="Source">
                    {form?.payload?.source}
                  </Table.Column>
                  <Table.Column title="Submit Date">
                    {dayjs(form.createdAt)
                      .tz(TIMEZONE)
                      .format('MM/DD/YYYY HH:mm A')}
                  </Table.Column>
                  <Table.Column title="Reviewed">
                    <Checkbox
                      name={`${form.id}-inactiveEnrollment`}
                      value={form.isReviewed}
                    />
                  </Table.Column>
                </Table.Row>
              ))}
            </Table>
            <div className="w-full text-center mt-5">
              {totalRecords > LIMIT_BY_PAGE ? (
                <Pagination
                  limit={LIMIT_BY_PAGE}
                  total={totalRecords}
                  onChangePage={(page: number) =>
                    setPaginationOffset((page - 1) * LIMIT_BY_PAGE)
                  }
                  currentPage={paginationOffset / LIMIT_BY_PAGE + 1}
                />
              ) : null}
            </div>
          </Form>
        ) : !formSubmissions.isLoading ? (
          <div className={styles.empty}>
            <p>No Form Submissions Found</p>
            <p>
              It looks like this record does not have form submissions linked.
            </p>
          </div>
        ) : null}
      </BasicBlock>
      {formModalIsOpen ? (
        <FormSubmissionsModal
          onClose={handleOnCloseModal}
          form={forms.find((form) => form.id === selectedFormId) || null}
        />
      ) : null}
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  SingleAccountFormSubmissions,
  'is_get_forms_enabled',
)
