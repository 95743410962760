import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useDeleteCmsBenefit = () =>
  useMutation({
    mutationFn: async (benefitId: string) => api.delete(`/cms/${benefitId}`),
  })

export default useDeleteCmsBenefit
