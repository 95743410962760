import cx from 'classnames'
import { useField } from 'formik'

import { FieldWrapper } from 'src/common/components'
import { IField } from 'src/common/interfaces'

import BasicMultiSelectButtonsWithOther from './BasicMultiSelectButtonsWithOther'
import styles from './basic-multiselect-buttons.module.scss'

interface BasicMultiSelectButtonsProps extends IField {
  withOther?: boolean
  disabled?: boolean
}

const BasicMultiSelectButtons = (
  props: React.PropsWithChildren<BasicMultiSelectButtonsProps>,
) => {
  const { withOther, children, ...rest } = props
  const { name, options, disabled } = rest

  const [{ value: fieldValue }, meta, { setValue, setTouched }] = useField(name)

  const handleChange = (value: string) => {
    setTouched(true)
    const currentValue = fieldValue || []

    if (!currentValue.includes(value)) {
      setValue([...currentValue, value])
    } else {
      setValue([...currentValue.filter((item: string) => item !== value)])
    }
  }

  if (withOther) {
    return <BasicMultiSelectButtonsWithOther {...rest} />
  }

  return (
    <FieldWrapper {...rest} name={name}>
      <div className={styles.wrapper} data-name={name}>
        {options?.map((o, i) => (
          <div
            key={o.value}
            className={cx(
              styles.item,
              fieldValue?.includes(o.value) && styles.selected,
              disabled && styles.disabled,
            )}
            onClick={disabled ? () => null : () => handleChange(o.value)}
          >
            <span className={styles.check} />
            {o.label}
          </div>
        ))}

        {children}
      </div>
    </FieldWrapper>
  )
}

export default BasicMultiSelectButtons
