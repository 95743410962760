import { useMemo, useState } from 'react'

import { PRIVATE_ROUTES } from 'src/default/config'
import { useRouter } from 'src/default/helpers/navigation'
import { PAGINATION_LIMIT } from 'src/v2/commons/config'
import { useAppSelector } from 'src/v2/store/hooks'

import { useGetUsersQuery } from '../models/user.api'
import { type UsersResponseModel } from '../models/user.model'
import { typeOrderBy } from '../models/user.model'

interface IUseGetUsersUseCase {
  users: UsersResponseModel | undefined
  handlePaginate: () => void
  usersQueryStatus: {
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
  }
  handleOrderBy: (field: string, orderBy: typeOrderBy) => void
  filterOrderBy: { field: string; orderBy: typeOrderBy }
  handleEditUser: (userId: string) => void
  handleCreateUser: () => void
}

const useGetUsersUseCase = (): IUseGetUsersUseCase => {
  const [page, setPage] = useState(0)
  const { id: orgId } = useAppSelector(
    (state) => state.user.userProfile.organization,
  )
  const organizationId = useMemo(() => {
    setPage(0)
    return orgId
  }, [orgId])

  const [filterOrderBy, setFilterOrderBy] = useState<{
    field: string
    orderBy: typeOrderBy
  }>({ field: '', orderBy: undefined })

  const { isLoading, isSuccess, data, isFetching } = useGetUsersQuery({
    organizationId: organizationId ?? '',
    status: undefined,
    limit: PAGINATION_LIMIT,
    offset: page * PAGINATION_LIMIT,
    search: undefined,
    orderBy: filterOrderBy.orderBy
      ? `${filterOrderBy.field} ${filterOrderBy.orderBy}`
      : undefined,
  })

  const handleOrderBy = (field: string, orderBy: typeOrderBy) => {
    setFilterOrderBy({ field, orderBy })
  }

  const router = useRouter()

  const handleEditUser = (userId: string) => {
    router.push(`${PRIVATE_ROUTES.USERS.path}/${userId}`)
  }

  const handleCreateUser = () => {
    router.push(`${PRIVATE_ROUTES.USERS.path}/create`)
  }

  const handlePaginate = () => {
    if (isFetching) return
    return setPage((prev) => {
      if (
        data &&
        data.totalRecords <= prev * PAGINATION_LIMIT + PAGINATION_LIMIT
      ) {
        return prev
      }
      return ++prev
    })
  }

  return {
    users: data,
    usersQueryStatus: {
      isLoading,
      isSuccess,
      isFetching,
    },
    handlePaginate,
    handleEditUser,
    handleOrderBy,
    filterOrderBy,
    handleCreateUser,
  }
}

export default useGetUsersUseCase
