import { IRoute } from 'src/common/interfaces'

export const DEV_ROUTES = {
  ICONS: {
    path: '/icons',
  },
  BENEFITS_PREVIEW: {
    path: '/administration-benefits/preview',
  },
}

export const PRIVATE_ROUTES: { [key: string]: IRoute } = {
  DASHBOARD: {
    path: '/dashboard',
    policy: 'is_get_dashboard_enabled',
  },
  TICKETS: {
    path: '/tickets',
    policy: 'is_get_office_visits_by_organization_id_enabled',
  },
  LEADS: {
    path: '/leads',
    policy: 'is_get_persons_enabled',
  },
  ACCOUNTS: {
    path: '/accounts',
    policy: 'is_get_persons_enabled',
  },
  USERS: {
    path: '/administration/users',
    policy: 'is_get_users_enabled',
  },
  ORGANIZATIONS: {
    path: '/organizations',
    policy: 'is_get_all_organisations_enabled',
  },
  ABCS_ADMINISTRATION: {
    path: '/administration/abcs',
    policy: null,
  },
  ROLES_ADMINISTRATION: {
    path: '/administration/roles',
    policy: 'is_get_roles_enabled',
  },
  POLICIES_ADMINISTRATION: {
    path: '/administration/policies',
    policy: 'is_get_policies_enabled',
  },
  ADMINISTRATION: {
    path: '/administration',
    policy: 'is_enable_administration',
  },
  BENEFITS_ADMINISTRATION: {
    path: '/administration-benefits',
    policy: 'is_enable_benefits_administration',
  },
  DATA_LOADER: {
    path: '/upload-file',
    policy: 'is_persons_data_loader_enabled',
  },
  SMS_CAMPAIGNS: {
    path: '/sms-campaigns',
    policy: 'is_sms_campaigns_enabled',
  },
  FORM_SUBMISSIONS: {
    path: '/form-submissions',
    policy: 'is_enable_view_form_submissions',
  },
  FORM_TEMPLATES: {
    path: '/form-templates',
    policy: null,
  },
  EMAIL_DOMAINS: {
    path: '/email-domains',
    policy: null,
  },
  TASKS: {
    path: '/tasks',
    policy: 'is_get_tasks_enabled',
  },
  REPORTS: {
    path: '/reports',
    policy: 'is_enable_reports',
  },
  DATA_PROCESSING: {
    path: '/data-processing',
    policy: 'is_enable_data_processing',
  },
  EMAIL_TEMPLATE_ADMINISTRATION: {
    path: '/administration/email-template',
    policy: 'is_get_persons_enabled'
  }
}
