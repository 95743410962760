import queryString from 'query-string'
import { ParseOptions, ParsedQuery, StringifyOptions } from 'query-string'

export const getParamsObjectWithoutPrefix = (
  prefixKey: string,
  paramsString: string,
  options: ParseOptions = {},
) =>
  Object.entries(queryString.parse(paramsString, options)).reduce(
    (acc, [key, value]) => {
      const newKey = key.replace(`${prefixKey}_`, '')
      return { ...acc, [newKey]: value }
    },
    {},
  )

export const getParamsStringWithPrefix = (
  prefixKey: string,
  paramsObject: ParsedQuery,
  options: StringifyOptions = {},
) =>
  queryString.stringify(
    Object.entries(paramsObject).reduce(
      (acc, [key, value]) => ({ ...acc, [`${prefixKey}_${key}`]: value }),
      {},
    ),
    options,
  )
