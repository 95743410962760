import { useEffect, useRef, useState } from 'react'

import { useDebounce } from 'src/common/hooks'
import { MembershipEmailDomainUser } from 'src/v2/commons/interface/membershipEmailDomain'

import { Icons } from '../../ui'
import styles from './email-badge.module.scss'

interface EmailBadgeProps {
  value?: string
  setFromOrReplyEmailId: (value: string) => void
  membershipEmailDomains?: MembershipEmailDomainUser[]
  setEmailDomainSignature?: (value: string) => void
}

const EmailBadge = (props: EmailBadgeProps) => {
  const {
    membershipEmailDomains,
    setFromOrReplyEmailId,
    setEmailDomainSignature,
  } = props
  const [isSelectedOneEmail, setIsSelectedOneEmail] = useState<boolean>(false)
  const [selectedEmail, setSelectedEmail] = useState<string>('')
  const [allEmailDomains, setAllEmailDomains] = useState<any>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const inputRef = useRef(null)
  const emailBadgeWrapperRef = useRef(null)

  const handleChangeFilterEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debounceSearch(event.target.value)
  }

  const handleSelectEmail = (email: MembershipEmailDomainUser) => {
    if (setEmailDomainSignature) {
      setEmailDomainSignature(email.signatureTemplate)
    }
    setFromOrReplyEmailId(email.id)
    setSelectedEmail(`${email.name} (${email.emailDomain.domain})`)
    setIsSelectedOneEmail(true)
    setIsOpen(false)
  }

  const handleDeselectEmail = () => {
    setSelectedEmail('')
    setIsSelectedOneEmail(false)
  }

  const filterMembershipEmailDomains = (value: string) => {
    if (value !== '') {
      setAllEmailDomains(
        membershipEmailDomains?.filter((email: MembershipEmailDomainUser) =>
          email.emailDomain.domain.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    }
    if (value === '') {
      setAllEmailDomains(membershipEmailDomains)
    }
  }

  const debounceSearch = useDebounce(filterMembershipEmailDomains, 100)

  useEffect(() => {
    if (membershipEmailDomains && membershipEmailDomains.length > 0) {
      setAllEmailDomains(membershipEmailDomains)
    }
  }, [membershipEmailDomains])

  useEffect(() => {
    if (inputRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const emailBadgeElement = emailBadgeWrapperRef.current
        if (emailBadgeElement && event.target instanceof Node) {
          const isClickInside = (emailBadgeElement as HTMLDivElement).contains(
            event.target,
          )

          if (isClickInside) {
            setIsOpen(true)
          } else {
            setIsOpen(false)
          }
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
      }
    }
  }, [inputRef])

  return (
    <div className={styles.emailBadgeWrapper} ref={emailBadgeWrapperRef}>
      <div className={styles.inputIconWrapper}>
        {isSelectedOneEmail ? (
          <div className={styles.emailAddressBadgeContainer}>
            <span>{selectedEmail}</span>
            <span onClick={handleDeselectEmail} className={styles.iconClose}>
              <Icons.Close />
            </span>
          </div>
        ) : (
          <div className={styles.inputContainer} ref={inputRef}>
            <input
              type="text"
              className={styles.inputSearch}
              placeholder="Select Sender Email Address"
              value={props.value}
              onChange={handleChangeFilterEmail}
            />
            <div className={styles.icon}>
              <Icons.ArrowSelect />
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <div className={styles.optionsWrapper}>
          {allEmailDomains && allEmailDomains.length > 0 ? (
            allEmailDomains.map((membershipEmailDomain: any) => (
              <div
                key={membershipEmailDomain.id}
                className={styles.optionWrapper}
                onClick={() => handleSelectEmail(membershipEmailDomain)}
              >
                <div className={styles.emailFirstLetterContainer}>
                  <span>
                    {membershipEmailDomain.emailDomain.domain
                      ?.charAt(0)
                      ?.toUpperCase()}
                  </span>
                </div>
                <div className={styles.emailAddressInformationContainer}>
                  <div className={styles.emailName}>
                    {membershipEmailDomain.name}
                  </div>
                  <div className={styles.emailAddress}>
                    {membershipEmailDomain.emailDomain.domain}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.notFoundWrapper}>
              Not Email Address Found
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EmailBadge
