import { Table } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import TicketTableFilter from '@v2/domains/ticket/components/TicketFilters/TicketsFilters.component'
import React from 'react'

import dayjs from 'src/common/libs/dayjs'

import {
  TicketFiltersModel,
  TicketModel,
  TicketsModel,
} from '../models/ticket.model'
import styles from '../tickets.module.scss'
import TicketCustomerLink from './TicketCustomerLink.component'

interface PastTicketsProps {
  isLoading: boolean
  tickets: TicketsModel | undefined
  usersOptions: { value: string; label: string }[]
  TicketFiltersForm: UseFormReturnType<TicketFiltersModel>
}

export const PastTickets = (props: PastTicketsProps) => {
  const { isLoading, tickets, usersOptions, TicketFiltersForm } = props
  return (
    <>
      <TicketTableFilter
        TicketFiltersForm={TicketFiltersForm}
        clearAllFilters={TicketFiltersForm.reset}
        totalRecords={tickets?.totalRecords || 0}
        assignedToUsers={usersOptions}
      />
      {tickets?.records ? (
        <div>
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Email</Table.Th>
                <Table.Th>Location</Table.Th>
                <Table.Th>Reason For Visit</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Assigned To</Table.Th>
                <Table.Th>Created Date</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tickets.records.map((t: TicketModel) => (
                <Table.Tr key={t.id} id={t.id} className={styles.row}>
                  <Table.Td className={styles.name}>
                    {t.personId ? (
                      <TicketCustomerLink ticket={t}>
                        {t.name}
                      </TicketCustomerLink>
                    ) : (
                      t.name
                    )}
                  </Table.Td>

                  <Table.Td className={styles.secondary}>{t.email}</Table.Td>

                  <Table.Td className={styles.secondary}>{t.location}</Table.Td>
                  <Table.Td className={styles.secondary}>
                    {t.reasonForVisit}
                  </Table.Td>

                  <Table.Td className={styles.secondary}>{t.status}</Table.Td>

                  <Table.Td className={styles.secondary}>
                    {usersOptions.find((u) => u.value === t.assignUserId)
                      ?.label ?? ''}
                  </Table.Td>
                  <Table.Td className={styles.secondary}>
                    {dayjs(t.createdAt).utc().format('MM/DD/YYYY')}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
      ) : (
        <>{!isLoading ? 'No Past Tickets Available' : null}</>
      )}
    </>
  )
}
