import { useAppSelector } from 'src/v2/store/hooks'

import { MembershipEmailDomainUser } from '../models/user.model'

interface UseUserInformationUseCase {
  emailDomains: MembershipEmailDomainUser[]
}

const useUserEmailDomainsUseCase = (): UseUserInformationUseCase => {
  const user = useAppSelector((state) => state.user.user)

  return {
    emailDomains: user?.emailDomains ?? [],
  }
}

export default useUserEmailDomainsUseCase
