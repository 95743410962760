import cx from 'classnames'
import { useField } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill-new'

import { FieldWrapper } from 'src/common/components'
import { BASIC_QUILL_EDITOR_CONFIG } from 'src/common/config'
import { IField } from 'src/common/interfaces'

import { useAppSelector } from '../../../../v2/store/hooks'
//TODO better path
import styles from './quill-editor.module.scss'
import '/node_modules/react-quill-new/dist/quill.snow.css'

interface QuillEditorProps extends IField {
  editorClassName?: string
  modules?: Record<string, unknown>

  handlers?: {
    [key: string]: ($editor: Quill) => void | Promise<void>
  }
  readOnly?: boolean
  onEditorLoad?: (editor: Quill) => void
}

const QuillEditor = (props: QuillEditorProps) => {
  const {
    editorClassName,
    name,
    placeholder = 'Your Message...',
    modules = BASIC_QUILL_EDITOR_CONFIG,
    handlers,
    readOnly,
    onEditorLoad,
    ...rest
  } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  const [$editor, set$Editor] = useState<Quill | null>(null)

  const editorRef = useCallback((editorNode: ReactQuill) => {
    set$Editor(editorNode?.getEditor() ?? null)
  }, [])

  useEffect(() => {
    if ($editor) {
      const toolbar = $editor.getModule('toolbar')
      if (toolbar) {
        if (handlers) {
          Object.entries(handlers).forEach(([key, handler]) => {
            //@ts-ignore
            toolbar.addHandler(key, () => handler($editor))
          })
        }
      }

      if (onEditorLoad) {
        onEditorLoad($editor)
      }
    }
  }, [$editor])

  const [{ value }, meta, { setValue }] = useField(name)

  return (
    <FieldWrapper {...rest} name={name}>
      <ReactQuill
        className={cx(
          styles.wrapper,
          styles[`${currentTheme}Slice`],
          meta.touched && meta.error && styles.error,
          editorClassName,
        )}
        ref={editorRef}
        modules={modules}
        value={value}
        placeholder={placeholder}
        tabIndex={10}
        readOnly={readOnly}
        onChange={(value) => setValue(value)}
      />
    </FieldWrapper>
  )
}

export default QuillEditor
