import { Box, Text, Tooltip } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useElementSize } from '@mantine/hooks'
import { IconInfoCircle } from '@tabler/icons-react'

import dayjs from '../../../../../common/libs/dayjs'
import { CreateSmsCampaignModel } from '../../models/smsCampaign.model'
import { smsSegmentCalculator } from '../../utils/smsSegmentCalculator'
import styles from './step-three-create-sms-campaign.module.scss'

const StepThreeCreateSmsCampaign = ({
  form,
}: {
  form: UseFormReturnType<CreateSmsCampaignModel>
}) => {
  const { ref, width } = useElementSize()
  return (
    <div>
      <Box mb="lg">
        <Text fw="bold" mb="sm">
          Campaign title
        </Text>
        {form.getValues().title}
      </Box>
      <Box mb="lg">
        <Text fw="bold" mb="sm">
          Campaign message
        </Text>
        {form.getValues().smsMessage}
      </Box>
      <Box mb="lg" className={styles.campaignMessageContainer}>
        <Text fw="bold" mb="sm" ref={ref} w="fit-content">
          Total number of segments
        </Text>
        {smsSegmentCalculator(form.getValues().smsMessage)}
        <Tooltip label="The number of segments is the total number of messages to be sent per target.">
          <IconInfoCircle
            color="blue"
            className={styles.tooltip}
            style={{
              top: 0,
              left: width + 8,
            }}
          />
        </Tooltip>
      </Box>
      <Box mb="lg">
        <Text fw="bold" mb="sm">
          Campaign sending date
        </Text>
        {dayjs(form.getValues().sendAt).format('MM-DD-YYYY HH:mm:ss')}
      </Box>
    </div>
  )
}

export default StepThreeCreateSmsCampaign
