import { Icons } from 'src/common/components'
import { CommunicationTypeEnum, ICommunication } from 'src/default/interfaces'

const ActionItemIcon = (props: ICommunication) => {
  const icon = (() => {
    if ('type' in props) {
      switch (props.type) {
        case CommunicationTypeEnum.EMAIL_OUTBOUND:
        case CommunicationTypeEnum.EMAIL_INBOUND:
          return 'Email'
        case CommunicationTypeEnum.CHAT:
          return 'Chat'
        case CommunicationTypeEnum.CALL_OUTBOUND:
        case CommunicationTypeEnum.CALL_INBOUND:
          return 'Phone'
        case CommunicationTypeEnum.SMS_OUTBOUND:
        case CommunicationTypeEnum.SMS_INBOUND:
          return 'Sms'
        case CommunicationTypeEnum.TICKET:
          return 'Ticket'
      }
    }
    return 'CheckCircle'
  })()

  const IconComponent =
    icon &&
    (Object.entries(Icons)?.find(([key, value]) => key === icon)?.[1] ?? null)

  return IconComponent ? <IconComponent /> : null
}

export default ActionItemIcon
