import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface IPermanentDeletePerson {
  personId: string
  email: string
}

const usePermanentDeletePerson = () =>
  useMutation({
    mutationFn: async (data: IPermanentDeletePerson) =>
      await api.delete(
        `/persons/permanent-delete/${data.personId}?email=${encodeURIComponent(
          data.email,
        )}`,
      ),
  })

export default usePermanentDeletePerson
