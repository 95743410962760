import { useState } from 'react'

import {
  Portal,
  SearchField,
  SelectOrganizationWrapper,
} from 'src/common/components'
import { useDocumentTitle } from 'src/common/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import styles from './basic-template-wrapper.module.scss'

const BasicTemplateWrapper = (props: React.PropsWithChildren<{}>) => {
  const { children } = props
  useDocumentTitle('')

  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false)

  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar
          showMobile={showMobileSidebar}
          onClose={() => setShowMobileSidebar(false)}
        />

        <div className={styles.inner}>
          <Topbar
            onBurgerToggle={() => setShowMobileSidebar(!showMobileSidebar)}
          >
            <SelectOrganizationWrapper />

            <Portal.Target
              className={styles.desktopSearch}
              id="searchField"
              media="desktop"
            />
          </Topbar>
          {children}
        </div>
      </div>

      <SearchField />
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BasicTemplateWrapper,
  'is_enable_basic_template_wrapper',
  true,
)
