import {
  Button,
  Checkbox,
  Flex,
  Modal,
  SimpleGrid,
  Text,
  Textarea,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import React, { useEffect, useMemo } from 'react'

import { Icons } from 'src/common/components/ui'
import {
  type PersonModel,
  type PersonsFiltersModel,
} from 'src/v2/domains/person/models/person.model'

import ModalSelect from '../components/ModalSelect/modalSelect.component'
import useTaskContainer from '../containers/task.container'
import {
  TASK_PRIORITY_OPTIONS,
  TASK_SUBJECT_OPTIONS,
  TASK_TYPE_OPTIONS,
} from '../models/task.model'
import styles from './assign-tasks-card.module.scss'

interface ModalProps {
  isOpen: boolean
  close: () => void
  selectedItems: PersonModel['id'][]
  pageFilters: PersonsFiltersModel
  clearStore: () => void
  openSuccessAssignTaskModal: () => void
}
const AssignTaskModal = ({
  isOpen,
  close,
  selectedItems,
  pageFilters,
  clearStore,
  openSuccessAssignTaskModal,
}: ModalProps) => {
  const {
    getFormProps,
    users,
    onSubmitForm,
    assignFieldKey,
    taskMutationStatus,
  } = useTaskContainer()

  const asignees = useMemo(() => {
    return users?.records.map((user) => ({ label: user.name, value: user.id }))
  }, [users])

  useEffect(() => {
    if (taskMutationStatus.isSuccess) {
      close()
      return () => {
        openSuccessAssignTaskModal()
        clearStore()
      }
    }
  }, [taskMutationStatus])

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmitForm(selectedItems, pageFilters)
  }
  return (
    <Modal
      opened={isOpen}
      onClose={close}
      centered
      classNames={{
        content: styles['modal-content'],
        overlay: styles['modal-overlay'],
        body: styles['modal-body'],
      }}
    >
      <Text className={styles['modal-header']} ta="center">
        Assign New Tasks
      </Text>
      <form onSubmit={handleSubmit}>
        <SimpleGrid
          cols={{ base: 1, sm: 2 }}
          spacing="xl"
          className={styles['simpleGrid-root']}
        >
          <ModalSelect
            label="Type"
            placeholder="Enter Type"
            {...getFormProps('type')}
            options={TASK_TYPE_OPTIONS}
            key={assignFieldKey('type')}
          />
          <ModalSelect
            label="Subject"
            placeholder="Enter Subject"
            {...getFormProps('subject')}
            options={TASK_SUBJECT_OPTIONS}
            key={assignFieldKey('subject')}
          />
          <ModalSelect
            label="Assign To"
            placeholder="Select Asignee"
            {...getFormProps('assignedToId')}
            options={asignees ?? []}
            key={assignFieldKey('assignedToId')}
          />
          <DatePickerInput
            valueFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            label="Due Date"
            key={assignFieldKey('activityDate')}
            required
            rightSection={
              <Icons.Calendar className={styles['calender-icon']} />
            }
            highlightToday={true}
            {...getFormProps('activityDate')}
            popoverProps={{
              offset: 0,
              width: 'target',
              withinPortal: false,
              transitionProps: { transition: 'scale-y', duration: 300 },
              middlewares: {
                flip: false,
                shift: false,
                inline: true,
              },
            }}
            classNames={{
              input: styles['datePicker-input'],
              label: styles['datePicker-label'],
            }}
          />
          <ModalSelect
            label="Priority"
            placeholder="Select Priority"
            {...getFormProps('priority')}
            key={assignFieldKey('priority')}
            options={TASK_PRIORITY_OPTIONS}
          />
          <Checkbox
            label="Assign record to Organizer"
            {...getFormProps('isPersonAssign')}
            key={assignFieldKey('isPersonAssign')}
            radius="3px"
            classNames={{
              root: styles['checkBox-assign-root'],
              body: styles['checkBox-assign-body'],
            }}
          />
          <Textarea
            classNames={{
              root: styles['textArea-root'],
              label: styles['textArea-label'],
              input: styles['textArea-input'],
            }}
            {...getFormProps('note')}
            key={assignFieldKey('note')}
            label="Notes"
            withAsterisk
            placeholder="Enter your notes here..."
          />
          <Checkbox
            label="Notify Assignee via email"
            radius="3px"
            {...getFormProps('isNotify')}
            key={assignFieldKey('isNotify')}
            classNames={{
              body: styles['checkBox-notify-body'],
            }}
          />
        </SimpleGrid>
        <Flex justify="space-between" mt="30px">
          <Button
            variant="default"
            className={styles['button-cancel']}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            rightSection={<Icons.ArrowRight />}
            className={styles['button-save']}
            type="submit"
            disabled={taskMutationStatus.isLoading}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}

export default AssignTaskModal
