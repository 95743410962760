import cx from 'classnames'
import { useState } from 'react'

import { Icons } from 'src/common/components'

import styles from './accordion.module.scss'

interface AccordionProps {
  className?: string
  title: string
  titleClassName?: string
  contentClassName?: string
  disabled?: boolean
  showByDefault?: boolean
  onTitleClick?: () => void
}

const Accordion = (props: React.PropsWithChildren<AccordionProps>) => {
  const {
    className,
    title,
    titleClassName,
    contentClassName,
    disabled,
    showByDefault = false,
    onTitleClick,
    children,
  } = props

  const [show, setShow] = useState<boolean>(disabled || showByDefault)

  const handleClick = () => {
    if (!disabled) {
      setShow(!show)
    }
  }

  const handleTitleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()

    if (onTitleClick) {
      onTitleClick()
    }
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <div
        className={cx(styles.title, show && styles.show, titleClassName)}
        onClick={handleClick}
      >
        <span onClick={handleTitleClick}>{title}</span>
        {!disabled ? <Icons.ArrowSelect /> : null}
      </div>

      <div className={cx(contentClassName)}>{show ? children : null}</div>
    </div>
  )
}

export default Accordion
