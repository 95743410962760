import axios from 'axios'
import { useField } from 'formik'
import fileDownload from 'js-file-download'

import { FieldWrapper, Icons } from 'src/common/components'
import { Table } from 'src/common/components'
import { formatDate } from 'src/common/helpers'
import { IDMPAttachment } from 'src/default/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './deactivation-benefit-files.module.scss'

const DeactivationBenefitFiles = () => {
  const [{ value: files }] = useField('attachments')

  if (!files?.length) {
    return null
  }

  const handleDownload = async (file: IDMPAttachment) => {
    const res = await axios.get(file.url, { responseType: 'arraybuffer' })
    fileDownload(res.data, file.fileName)
  }

  return (
    <FieldWrapper className={styles.wrapper} name="attachments" label="Files">
      <Table>
        {files.map((file: IDMPAttachment) => (
          <Table.Row key={file._id}>
            <Table.Column title="Date">
              {formatDate(file.createdAt, 'MM/DD/YYYY')}
            </Table.Column>

            <Table.Column title="Source">
              {file.owner?.role ? 'IDG' : 'Driver'}
            </Table.Column>

            <Table.Column title="Filename" showMobile>
              {file.fileName}
            </Table.Column>

            <Table.Column title="Description">{file.description}</Table.Column>

            <PolicyCheckHOC policyName="is_enable_deactivation_benefit_file_download">
              <Table.Column title="" showMobile>
                <div
                  className={styles.eyeButton}
                  onClick={() => handleDownload(file)}
                >
                  <Icons.Eye />
                </div>
              </Table.Column>
            </PolicyCheckHOC>
          </Table.Row>
        ))}
      </Table>
    </FieldWrapper>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  DeactivationBenefitFiles,
  'is_enable_deactivation_benefit_files',
  true,
)
