import { useParams } from 'react-router-dom'

import { Button } from 'src/common/components'
import { PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './form-page-header.module.scss'

const UsersPageHeader = () => {
  const { organizationId } = useParams<{ organizationId: string }>()

  return (
    <div className={styles.wrapper}>
      <PolicyCheckHOC policyName="is_crete_form_template_enabled">
        <Button
          className={styles.button}
          icon="Plus"
          to={`/${organizationId}${PRIVATE_ROUTES.FORM_TEMPLATES.path}/create`}
          size="small"
        >
          Create New Form
        </Button>
      </PolicyCheckHOC>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UsersPageHeader,
  'is_enable_users_page_header',
  true,
)
