import { Icons } from 'src/common/components'
import { Triggers } from 'src/default/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benefits-administration-item-remove.module.scss'

interface BenefitsAdministrationItemRemoveProps {
  name: string
  onRemove: () => Promise<void>
}

const BenefitsAdministrationItemRemove = (
  props: BenefitsAdministrationItemRemoveProps,
) => {
  const { name, onRemove } = props

  return (
    <Triggers.Delete
      className={styles.wrapper}
      name={name}
      title="Delete Benefit?"
      description={`Are you sure you want to delete the ${name} benefit?`}
      onAction={onRemove}
    >
      <Icons.TrashCross />
      <span className={styles.text}>Delete</span>
    </Triggers.Delete>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationItemRemove,
  'is_enable_delete_benefit',
  true,
)
