import cx from 'classnames'
import React from 'react'

import { Icons, Modal } from 'src/common/components'
import { IModal } from 'src/common/interfaces'

import withErrorBoundaryAndPolicyCheck from '../../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './modal-result-enrollments.module.scss'

interface ModalResultProps extends IModal {
  isSuccess?: boolean
  title?: string
  description?: string | React.ReactNode
}

const ModalResultConvertLead = (props: ModalResultProps) => {
  const { isSuccess, title, description, ...rest } = props

  const CurrentIcon = Icons[isSuccess ? 'Check' : 'Warning']

  return (
    <Modal id="modalResultConvertLead" {...rest}>
      <div className={styles.wrapper}>
        <div
          className={cx(styles.icon, styles[isSuccess ? 'success' : 'error'])}
        >
          <CurrentIcon />
        </div>

        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </Modal>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  ModalResultConvertLead,
  'is_enable_single_account_enrollments_modal_result',
  true,
)
