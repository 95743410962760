import dayjs from 'dayjs'

import {
  IBenestreamPerson,
  PersonUpdateRequest,
} from 'src/benestream/interfaces'

//TODO make it clear, we should use the same DTO on BE and FE
export const transformerPersonsResponseToReadable = (
  person: IBenestreamPerson,
) => {
  return {
    ...person,
    birthDate: Boolean(person.birthDate)
      ? dayjs.tz(person.birthDate, 'UTC').format('MM/DD/YYYY')
      : '',
    householdSize: Number(person.householdSize),
    street: person.street?.split('   ')[0] || person.street,
    unitNumber: person.street?.split('   ')[1] || '',
    isNewHire: person.isNewHire === null ? '' : person.isNewHire ? '1' : '0',
    isFullTime: person.isFullTime === null ? '' : person.isFullTime ? '1' : '0',
    agreedToPrivacyWaiver: person.agreeToPrivacyWaiver,
    otherPhoneNumber: Array.isArray(person.otherPhoneNumber)
      ? person.otherPhoneNumber.map((phone) =>
          phone === '[]' ? '' : phone,
        )?.[0] ?? []
      : person.otherPhoneNumber,
    smsOptIn: person.smsOptIn ? '1' : '0',
    emailOptIn: person.emailOptIn ? '1' : '0',
    status: person.enrollments?.[0]?.status || '',
    otherEmail: person?.primaryPerson?.otherEmail || [],
  }
}

//TODO make it clear, we should use the same DTO on BE and FE
export const transformerPersonsToRequest = (
  person: Partial<IBenestreamPerson>,
): PersonUpdateRequest => {
  const street = `${person.street}   ${person.unitNumber || ''}`.trim()
  let dateOfHire = undefined
  let layoffDate = undefined
  let householdSize: number | null = Number(person.householdSize)

  if (person.dateOfHire) {
    dateOfHire = person.dateOfHire
  }

  if (person.layoffDate) {
    layoffDate = person.layoffDate
  }

  if (typeof person.householdSize === 'string' && person.householdSize === '') {
    householdSize = null
  }

  const data: PersonUpdateRequest = {
    id: person.id!,
    firstName: person.firstName!,
    lastName: person.lastName!,
    email: person.email!,
    otherEmail: person.otherEmail || null,
    phoneNumber: person.phoneNumber!,
    gender: person.gender,
    birthDate: person.birthDate!,
    preferredLanguage: person.preferredLanguage!,
    householdSize: householdSize,
    street,
    state: person.state!,
    city: person.city!,
    postalCode: person.postalCode!,
    worksite: person.worksite!,
    isNewHire: person.isNewHire === '1',
    isFullTime: person.isFullTime === '1',
    shift: person.shift!,
    onCompanyPlan: person.onCompanyPlan,
    medicalTier: person.medicalTier,
    employeeId: person.employeeId,
    MARenewalDate: person.MARenewalDate,
    severancePayment: person.severancePayment,
    createdAt: person.createdAt!,
    source: person.source!,
    type: person.type!,
    agreedToPrivacyWaiver: person.agreedToPrivacyWaiver!,
    companies: person.companies!,
    managerName: person.managerName,
    dateOfHire,
    layoffDate,
    smsOptIn: person.smsOptIn === '1',
    emailOptIn: person.emailOptIn === '1',
    preferredMethodOfContact: person.preferredMethodOfContact,
    preferredTimeOfContact: person.preferredTimeOfContact,
    status: person.status,
  }

  if (person.otherPhoneNumber && person.otherPhoneNumber.length) {
    if (Array.isArray(person.otherPhoneNumber)) {
      data.otherPhoneNumber = person.otherPhoneNumber[0]
    } else {
      data.otherPhoneNumber = person.otherPhoneNumber?.toString()!
    }
  } else if (person.otherPhoneNumber?.length === 0) {
    data.otherPhoneNumber = ''
  }
  return data
}

export const getLeadStatusColor = (status: string) => {
  switch (status) {
    case 'Removed':
      return 'red'
    case 'Not Interested':
      return 'orange'
    case 'Interested':
      return 'green'
    case 'New':
      return 'blue'
  }

  return 'grey'
}
