import { Accordion } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import styles from './benestream-person-eligibilities-item.module.scss'

interface BenestreamPersonEligibilitiesItemProps {
  title: string
  value: string
}

const BenestreamPersonEligibilitiesItem = (
  props: BenestreamPersonEligibilitiesItemProps,
) => {
  const { title, value } = props
  return (
    <Accordion
      className={styles.wrapper} //TODO move layout to common place like benestream accordion layout
      titleClassName={styles.accordionTitle}
      contentClassName={styles.accordionContent}
      title={title}
    >
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </Accordion>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonEligibilitiesItem,
  'is_enable_benestream_person_eligibilities_item',
  true,
)
