import cx from 'classnames'

import { Portal, Spinner } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { useAppSelector } from '../../../v2/store/hooks'
import styles from './basic-template.module.scss'

export interface BasicTemplateProps {
  header?: string | React.ReactNode | null
  title?: string
  description?: string
  isLoading?: boolean
}

const BasicTemplate = (props: React.PropsWithChildren<BasicTemplateProps>) => {
  const { header, title, description, isLoading, children } = props

  const currentTheme = useAppSelector((state) => state.ui.currentTheme)

  return (
    <div className={cx(styles.wrapper, styles[`${currentTheme}Slice`])}>
      <Portal.Target id="breadcrumbs" />

      <div className={styles.header}>
        {title ? (
          <h1 className={styles.title}>
            {`${title}${description ? ': ' : ''}`}
            {description ? (
              <span className={styles.description}>{description}</span>
            ) : null}
          </h1>
        ) : null}

        {header ? <div className={styles.headerInner}>{header}</div> : null}
      </div>

      <Portal.Target
        className={styles.mobileSearch}
        id="searchField"
        media="mobile"
      />

      {isLoading ? (
        <div className={styles.loading}>
          <Spinner color="primary" size="big" />
        </div>
      ) : (
        <>
          {/* <Portal.Target id="basicTemplateNotifications" /> */}
          {children}
        </>
      )}
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BasicTemplate,
  'is_enable_basic_template',
  true,
)
