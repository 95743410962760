import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useMutateNotificationFormSubmission = () =>
  useMutation({
    mutationFn: async (data: any) => {
      const url = `/v1/forms/notify/` + data.id
      return api['post'](url)
    },
  })

export default useMutateNotificationFormSubmission
