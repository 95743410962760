import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authStore from '../domains/auth/models/auth.store'
import communicationStore from '../domains/communication/models/communication.store'
import deactivationCaseStore from '../domains/deactivationCase/models/deactivationCase.store'
import organizationStore from '../domains/organization/models/organization.store'
import accountStore from '../domains/person/models/account/assignAccount.store'
import leadStore from '../domains/person/models/leads/assignLead.store'
import searchStore from '../domains/person/models/search/assignSearch.store'
import roleStore from '../domains/role/models/role.store'
import runProcessStore from '../domains/run-process/models/RunProcess.store'
import smsCampaignStore from '../domains/smsCampaigns/models/smsCampaign.store'
import ticketStore from '../domains/ticket/models/ticket.store'
import uiStore from '../domains/ui/models/ui.store'
import userStore from '../domains/user/models/user.store'
import usersStore from '../domains/user/models/users.store'
import { rootApi } from './api/rootApi'
import routerMiddleware from './middleware/router.middleware'

const reducers = {
  auth: authStore,
  user: userStore,
  ui: uiStore,
  communication: communicationStore,
  ticket: ticketStore,
  deactivationCase: deactivationCaseStore,
  organization: organizationStore,
  lead: leadStore,
  search: searchStore,
  account: accountStore,
  runProcess: runProcessStore,
  smsCampaign: smsCampaignStore,
  role: roleStore,
  users: usersStore,
}

const rootReducer = combineReducers({
  ...reducers,
  [rootApi.reducerPath]: rootApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(routerMiddleware)
      .concat(rootApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer> & {
  getState: () => RootState
}
export type AppDispatch = typeof store.dispatch
