import cx from 'classnames'

import { Accordion, Status } from 'src/common/components'
import { formatDate } from 'src/common/helpers'

import { DMPCase } from '../../../../../v2/domains/deactivationCase/models/deactivationCase.model'
import { setDeactivationCase } from '../../../../../v2/domains/deactivationCase/models/deactivationCase.store'
import { useAppDispatch } from '../../../../../v2/store/hooks'
import styles from './deactivation-cases-item.module.scss'

interface DeactivationCasesItemProps {
  data: DMPCase
  isOnlyOne?: boolean
}

const DeactivationCasesItem = (props: DeactivationCasesItemProps) => {
  const dispatch = useAppDispatch()

  const {
    data: { _id, key, service, assignedTo, createdAt, updatedAt, status },
    isOnlyOne,
  } = props

  const fullName =
    assignedTo?.profile.firstName || assignedTo?.profile.lastName
      ? `${assignedTo?.profile.firstName ?? ''} ${
          assignedTo?.profile.lastName ?? ''
        }`
      : '-'

  return (
    <Accordion
      className={styles.wrapper}
      title={`Case ID: ${key}`}
      titleClassName={cx(styles.title, isOnlyOne && styles.onlyOne)}
      disabled={isOnlyOne}
      onTitleClick={() => dispatch(setDeactivationCase(props.data))}
    >
      <table className={styles.data}>
        <tbody>
          <tr>
            <td>Company:</td>
            <td>{service.serviceName}</td>
          </tr>

          <tr>
            <td>Assigned To:</td>
            <td>{fullName}</td>
          </tr>

          <tr>
            <td>Created:</td>
            <td>{formatDate(createdAt, 'MM/DD/YYYY')}</td>
          </tr>

          <tr>
            <td>Last Modified:</td>
            <td>{formatDate(updatedAt, 'MM/DD/YYYY')}</td>
          </tr>

          <tr>
            <td>Status:</td>
            <td>
              <Status strictColor={status.color}>{status.label}</Status>
            </td>
          </tr>
        </tbody>
      </table>
    </Accordion>
  )
}

export default DeactivationCasesItem
