import { Card, Modal, Stack, Text } from '@mantine/core'

import { Icons } from 'src/common/components'
import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import UserInformation from '../compoments/UserInformation'
import useCreateUserContainer from '../containers/useCreateUser.container'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Users' },
  { title: 'Create New User' },
]

const CreateUserView = () => {
  const { userCreateForm, handleCancelForm, onSubmit, errorModal, loading } =
    useCreateUserContainer()

  return (
    <BasicTemplate
      isLoading={false}
      title="Users"
      breadCrumbItems={breadCrumbItems}
    >
      <Card shadow="lg" radius="sm">
        <UserInformation
          loading={loading}
          onSubmit={onSubmit}
          userInformationForm={userCreateForm}
          editForm={true}
          setEditForm={() => null}
          handleCancelForm={handleCancelForm}
        />
      </Card>
      <Modal opened={errorModal.opened} onClose={errorModal.close}>
        <Stack align="center" justify="center" gap="md">
          <Icons.Warning width="60" height="60" style={{ color: 'red' }} />
          <Text size={'16px'} fw={700}>
            Error when try to create user
          </Text>
          <Text size={'14px'} fw={400} style={{ whiteSpace: 'pre-wrap' }}>
            {userCreateForm.errors?.error}
          </Text>
        </Stack>
      </Modal>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  CreateUserView,
  'is_get_roles_enabled',
  true,
)
