import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

const useDeleteDependentPerson = () =>
  useMutation({
    mutationFn: async (id: string) =>
      api.delete(`/benestream/persons/dependent/delete/${id}`),
  })

export default useDeleteDependentPerson
