import { getABCName, sortEnrollments } from '@v2/domains/person/utils/index'

import { IEnrollment } from 'src/v2/domains/person/models/enrollment.model'

import styles from './abcs-person-table.module.scss'

interface LeadsTableABCsProps {
  enrollments: IEnrollment[]
}

const PersonTableABCs = (props: LeadsTableABCsProps) => {
  const { enrollments } = props
  const sortedEnrollments = sortEnrollments(enrollments)

  return (
    <>
      {sortedEnrollments.map((enrollment) => (
        <div key={enrollment.id} className={styles.wrapper}>
          {getABCName(enrollment)}
        </div>
      ))}
    </>
  )
}

export default PersonTableABCs
