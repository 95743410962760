import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'

interface ManageAppointment {
  id?: string
  startTime?: string
  status?: string
  service?: string
  repPhoneNumber?: string
  clinicInformation?: string
  clinicZipCode?: string
}

const useManageAppointment = () => {
  return useMutation({
    mutationFn: async (data: ManageAppointment) => {
      if (data.id) {
        const updateData = { ...data }
        delete updateData.id
        return api.put(`/appointments/${data.id}`, updateData)
      } else {
        delete data.id
        return api.post('/appointments', data)
      }
    },
  })
}

export default useManageAppointment
