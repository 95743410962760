import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import BenestreamPersonFormAddressInformation from './BenestreamPersonFormAddressInformation'
import BenestreamPersonFormContactInformation from './BenestreamPersonFormContactInformation'
import BenestreamPersonFormPreferredCommunications from './BenestreamPersonFormPreferredCommunications'

const BenestreamPersonFormBasicInformation = ({
  personType,
}: {
  personType: 'lead' | 'account'
}) => {
  return (
    <>
      <BenestreamPersonFormContactInformation personType={personType} />

      <BenestreamPersonFormAddressInformation />

      <BenestreamPersonFormPreferredCommunications />
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenestreamPersonFormBasicInformation,
  'is_enable_benestream_person_form_basic_information',
  true,
)
