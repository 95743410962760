export const API_BASE_URL =
  import.meta.env.VITE_PUBLIC_APP_API_URL || 'https://api.dev.driversguild.net'

export const QUERY_CLIENT_DEFAULT_OPTIONS = {
  queries: {
    retry: 2,
    // refetchOnWindowFocus: true,
    // refetchOnMount: true,
  },
}
