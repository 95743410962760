import { DatePickerInput, DatesRangeValue } from '@mantine/dates'

// move to v2
import { Icons } from 'src/common/components/ui'

import classes from './filter-datepicker.module.scss'

interface DatePickerProps {
  placeholder: string
  valueFormat: string
  handleDateRangeChange: (value: DatesRangeValue) => void
  getFilters: () => [Date, Date] | undefined
}
const DateRangePickerFilter = ({
  handleDateRangeChange,
  placeholder = 'From - To',
  valueFormat = 'MM/DD/YYYY',
  getFilters,
}: DatePickerProps) => {
  return (
    <DatePickerInput
      valueFormat={valueFormat}
      placeholder={placeholder}
      defaultValue={getFilters()}
      rightSection={<Icons.Calendar className={classes['calender-icon']} />}
      type="range"
      onChange={handleDateRangeChange}
      highlightToday={true}
      maxDate={new Date()}
      popoverProps={{
        offset: 0,
        width: 'target',
        withinPortal: false,
        transitionProps: { transition: 'scale-y', duration: 300 },
        middlewares: {
          flip: false,
          shift: false,
          inline: true,
        },
      }}
      classNames={{
        input: classes.input,
        section: classes.section,
        calendarHeader: classes.calendarHeader,
        levelsGroup: classes.levelsGroup,
        month: classes.month,
      }}
    />
  )
}
export default DateRangePickerFilter
