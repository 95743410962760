import { useField } from 'formik'
import { useEffect } from 'react'

import { Select } from 'src/common/components'
import { IField } from 'src/common/interfaces'
import {
  ICurrentOptionComponent,
  IOptionComponent,
} from 'src/default/interfaces'

import BasicMultiSelectButtons from '../BasicMultiSelectButtons'
import BasicMultiSelectCheckboxes from '../BasicMultiSelectCheckboxes'
import BasicMultiSelectGroups from '../BasicMultiSelectGroups'
import BasicMultiSelectSuggestions from '../BasicMultiSelectSuggestions'
import MultiSelectCurrentOption from './MultiSelectCurrentOption'
import MultiSelectOption from './MultiSelectOption'

interface MultiSelectProps extends IField {
  OptionComponent?: (props: IOptionComponent) => React.ReactElement
  CurrentOptionComponent?: (
    props: ICurrentOptionComponent,
  ) => React.ReactElement
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    name,
    OptionComponent = MultiSelectOption,
    CurrentOptionComponent = MultiSelectCurrentOption,
    onChange,
    isDisabledOptions,
    value,
  } = props

  const [{ value: fieldValue }, meta, { setValue, setTouched }] = useField({
    name,
  })

  useEffect(() => {
    if (fieldValue?.length === 0 && !meta.touched) {
      setTouched(true)
    }
  }, [fieldValue])

  const handleChange = (value: string) => {
    const currentValue = fieldValue || []
    const newValue = !currentValue.includes(value)
      ? [...currentValue, value]
      : [...currentValue.filter((item: string) => item !== value)]

    setValue(newValue)

    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <Select
      {...props}
      closeOnSelect={false}
      OptionComponent={OptionComponent}
      CurrentOptionComponent={CurrentOptionComponent}
      value={fieldValue ?? value}
      onChange={handleChange}
      withError={true}
      isOverflowValue
      isDisabledOptions={isDisabledOptions}
    />
  )
}

MultiSelect.Buttons = BasicMultiSelectButtons
MultiSelect.Checkboxes = BasicMultiSelectCheckboxes
MultiSelect.Groups = BasicMultiSelectGroups
MultiSelect.Suggestions = BasicMultiSelectSuggestions

export default MultiSelect
