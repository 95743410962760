import { IOptionComponent } from 'src/default/interfaces'

const BasicOption = (props: IOptionComponent) => {
  const { label, value, meta, onChange, isDisabled, onSelectDisabled } = props

  const currentLabel = (typeof meta === 'string' ? meta : '') || label //TODO mb refactor

  return (
    <>
      <div
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        onClick={() => {
          if (isDisabled) {
            onSelectDisabled && onSelectDisabled(value)
            return
          }
          onChange(value)
        }}
      >
        {currentLabel}
      </div>
    </>
  )
}

export default BasicOption
