import { TextInput } from '@mantine/core'
import { getHotkeyHandler, useClickOutside } from '@mantine/hooks'
import { useEffect, useRef } from 'react'

import { useUpdatePolicyDescriptionContainer } from '../containers/UpdatePolicyDescription.container'

interface EditableDescriptionProps {
  description: string
  policyId: string
  name: string
  disabled?: boolean
}

export const EditableDescription = ({
  description,
  policyId,
  name,
  disabled = false,
}: EditableDescriptionProps) => {
  const {
    policyUpdateForm,
    onSubmit,
    isEditing,
    onCancel,
    isEnableToEditDescription,
    inputRef,
    clickOutsideRef,
    handleDoubleClick,
  } = useUpdatePolicyDescriptionContainer({
    id: policyId,
    description,
    name,
  })

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }, [isEditing])

  if (isEditing) {
    return (
      <div ref={clickOutsideRef}>
        <TextInput
          label="Press Enter to save or click outside to cancel"
          ref={inputRef}
          onKeyDown={getHotkeyHandler([
            ['Enter', onSubmit],
            ['Escape', onCancel],
          ])}
          {...policyUpdateForm.getInputProps('description')}
        />
      </div>
    )
  }

  return (
    <span
      onDoubleClick={isEnableToEditDescription ? handleDoubleClick : () => null}
      style={{
        cursor: disabled || !isEnableToEditDescription ? 'default' : 'pointer',
      }}
    >
      {description ?? '---'}
    </span>
  )
}
