import { Button } from '@mantine/core'

import { Icons } from 'src/common/components/ui'

import classes from './filter-button.module.scss'

interface ButtonFilterProps {
  onClick: () => void
  text?: string
  isOpen: boolean
}
const ButtonFilter = ({
  onClick,
  isOpen,
  text = 'Filter',
}: ButtonFilterProps) => {
  return (
    <Button
      mod={{ opened: isOpen }}
      type="button"
      classNames={{
        root: classes.root,
        inner: classes.inner,
      }}
      style={{ borderRadius: 'var(--mantine-radius-six)' }}
      justify="space-between"
      leftSection={<Icons.Filter height="18px" />}
      rightSection={<Icons.ArrowSelect height="14px" />}
      variant="default"
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
export default ButtonFilter
