import dayjs from 'dayjs'
import { useState } from 'react'

import { useRouter } from 'src/v2/commons/utils/navigation'

import { useRefetchById } from '../../../../common/hooks'
import { PRIVATE_ROUTES, QUERY_IDS } from '../../../../default/config'
import useMutationSmsCampaign from '../../../../default/hooks/sms/useMutationSmsCampaign'
import { useAppSelector } from '../../../store/hooks'
import useCreateSmsCampaignFormUseCase from '../useCases/useCreateSmsCampaignFormUseCase'
import useCreateSmsCampaignUseCase from '../useCases/useCreateSmsCampaignUseCase'

const useCreateSmsCampaignContainer = () => {
  const router = useRouter()

  const [campaignCreated, setCampaignCreated] = useState({
    id: '',
    name: '',
    targets: {
      successes: [],
      errors: [],
    },
  })

  const createSmsCampaign = useMutationSmsCampaign()
  const refetchById = useRefetchById()
  const organizationId = useAppSelector(
    (state) => state.user.userProfile.organization.id,
  )

  const { currentStep, changeCurrentStep, changePrevStep, changeNextStep } =
    useCreateSmsCampaignUseCase()
  const { form, checkIfStepHasError } = useCreateSmsCampaignFormUseCase()

  const handleCreateSmsCampaign = async (values: {
    title: string
    targets: File
    smsMessage: string
    sendAt: string
  }) => {
    const formData = new FormData()

    formData.append('name', values.title)
    formData.append('targetsFile', values.targets)
    formData.append('smsMessage', values.smsMessage)
    formData.append('organizationId', organizationId!)
    if (!!values.sendAt) {
      formData.append(
        'sendAt',
        dayjs.tz(values.sendAt, 'UTC').format('MM/DD/YYYY HH:mm'),
      )
    }
    await createSmsCampaign
      .mutateAsync(formData)
      .then((response) => {
        setTimeout(() => refetchById(QUERY_IDS.GET_SMS_CAMPAIGNS), 1000)
        setCampaignCreated(response.data)
      })
      .catch((error: any) => {
        console.log('error', error)
      })
  }

  const goToCampaignsPage = () => {
    router.push(PRIVATE_ROUTES.SMS_CAMPAIGNS.path)
  }

  const goToCampaignDetail = () => {
    router.push(`/sms-campaigns/${campaignCreated.id}`)
  }

  return {
    currentStep,
    changePrevStep,
    changeNextStep,
    changeCurrentStep,
    form,
    handleCreateSmsCampaign,
    goToCampaignsPage,
    goToCampaignDetail,
    campaignCreated,
    checkIfStepHasError,
  }
}

export default useCreateSmsCampaignContainer
