import { Outlet } from 'react-router-dom'

import { Breadcrumbs } from 'src/common/components'

const AdministrationLayout = () => {
  return (
    <>
      <Breadcrumbs items={[]} />
      <Outlet />
    </>
  )
}

export default AdministrationLayout
