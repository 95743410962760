import { ReactElement } from 'react'

import { useAppSelector } from '../../store/hooks'

interface PolicyCheckProps {
  children: ReactElement
  policyName: string | string[]
  disablePolicyCheck?: boolean
  defaultComponent?: ReactElement
}

const PolicyCheckHOC = (props: PolicyCheckProps) => {
  const { children, policyName, disablePolicyCheck, defaultComponent } = props

  const policies =
    (useAppSelector((state) => state.user.userProfile.policies) as string[]) ||
    []
  const checkPolicies = Array.isArray(policyName) ? policyName : [policyName]

  const isApproved =
    disablePolicyCheck ||
    checkPolicies.some((checkPolicy) => policies.includes(checkPolicy))

  return <>{isApproved ? children : defaultComponent || null}</>
}

export default PolicyCheckHOC
