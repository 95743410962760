import { useMutation } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { ICmsBenefitMutationData } from 'src/default/interfaces'

interface MutateCmsBenefitData extends ICmsBenefitMutationData {
  id?: string
}

const useMutateCmsBenefit = (action: 'create' | 'update') =>
  useMutation({
    mutationFn: async (data: MutateCmsBenefitData) => {
      const { id, ...rest } = data

      return api[action === 'update' ? 'patch' : 'post'](
        `/cms${id ? '/' + id : ''}`,
        rest,
      )
    },
  })

export default useMutateCmsBenefit
