export interface AuthModel {
  accessToken: string
  refreshToken: string
  userId: string
}

export interface LoginFormModel {
  email: string
  password: string
}

export interface ForgotFormModel {
  email: string
}

export const LocalStorageAuthSession = 'AUTH_SESSION'
