import { Card, Center, Loader } from '@mantine/core'

import withErrorBoundaryAndPolicyCheck from 'src/v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { TBreadCrumbItems } from 'src/v2/commons/components/Breadcrumb'
import BasicTemplate from 'src/v2/commons/templates/BasicTemplate'

import { usePoliciesContainer } from '../../policy/containers/Policies.container'
import { RoleForm } from '../components/RoleForm.component'
import { useRoleContainer } from '../containers/useRole.container'
import styles from './role.module.scss'

const breadCrumbItems: TBreadCrumbItems = [
  { title: 'Home' },
  { title: 'Administration Panel' },
  { title: 'Roles' },
]

const RoleView = () => {
  const { data: dataRole, isLoading: isRoleLoading } = useRoleContainer()

  return (
    <BasicTemplate title="Roles" breadCrumbItems={breadCrumbItems}>
      <Card shadow="lg" radius="lg" className={styles.defaultCard}>
        {isRoleLoading ? (
          <Center>
            <Loader size={30} />
          </Center>
        ) : (
          <RoleForm
            id={dataRole.id}
            name={dataRole.name}
            createdAt={dataRole.createdAt}
            updatedAt={dataRole.updatedAt}
          />
        )}
      </Card>
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  RoleView,
  'is_get_roles_enabled',
  true,
)
