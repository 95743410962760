import cx from 'classnames'
import { useField } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { FieldWrapper, Icons } from 'src/common/components'
import { IOption, IOptionsGroup } from 'src/common/interfaces'

import BasicSelectGroupsItem from './BasicSelectGroupsItem'
import BasicSelectGroupsSearch from './BasicSelectGroupsSearch'
import styles from './basic-select-groups.module.scss'

interface BasicSelectGroupsProps {
  name: string
  searchable?: boolean
  placeholder?: string
  groups: IOptionsGroup[]
  options: IOption[]
  onChange: (value: string) => void
  showBorder?: boolean
  tabletWidth?: string
  label?: string
}

const BasicSelectGroups = (props: BasicSelectGroupsProps) => {
  const {
    name,
    groups,
    options,
    searchable,
    placeholder = '',
    onChange,
    showBorder = false,
    ...rest
  } = props

  const [{ value: fieldValue }] = useField(name)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [activeGroup, setActiveGroup] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const selectRef = useRef(null)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )

          if (!isClickInside) {
            setIsOpen(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsOpen(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const handleOpen = () => {
    if (isOpen) {
      setActiveGroup(null)
      setSearchValue('')
    }

    setIsOpen(!isOpen)
  }

  const handleChange = async (newValue: string) => {
    handleOpen()

    onChange(newValue)
  }

  const currentOptionLabel = options?.find((o) => o.value === fieldValue)?.label

  return (
    <FieldWrapper name={name} {...rest} withError={false}>
      <div className={styles.wrapper}>
        <div
          className={cx(
            styles.input,
            showBorder ? styles.border : styles.selectWithoutBorder,
          )}
          ref={selectRef}
          data-name={name}
          onClick={handleOpen}
        >
          {currentOptionLabel ? (
            <div>{currentOptionLabel}</div>
          ) : (
            <div className={styles.placeholder}>{placeholder}</div>
          )}

          <div className={cx(styles.arrow, isOpen && styles.open)}>
            <Icons.ArrowSelect />
          </div>

          {isOpen ? (
            <div
              className={styles.groupsWrapper}
              onClick={(e) => e.stopPropagation()}
            >
              {searchable ? (
                <BasicSelectGroupsSearch
                  currentValue={fieldValue}
                  searchValue={searchValue}
                  options={options}
                  onSearchChange={setSearchValue}
                  onChange={handleChange}
                />
              ) : null}

              {!searchValue ? (
                <>
                  {groups.map((group) => (
                    <BasicSelectGroupsItem
                      key={group.id}
                      currentValue={fieldValue}
                      isActive={activeGroup === group.id}
                      {...group}
                      options={options}
                      onToggle={() =>
                        setActiveGroup(
                          activeGroup !== group.id ? group.id : null,
                        )
                      }
                      onChange={handleChange}
                    />
                  ))}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </FieldWrapper>
  )
}

export default BasicSelectGroups
