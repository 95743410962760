import { rootApi } from 'src/v2/store/api/rootApi'

import { ForgotFormModel, LoginFormModel } from './auth.model'
import { setAuthInfo } from './auth.store'

interface LoginResponse {
  userId: string
  access_token: string
  refresh_token: string
}

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginFormModel>({
      query: (queryArgs) => ({
        url: '/v3/auth/login',
        method: 'POST',
        data: {
          email: queryArgs.email.trim(),
          password: queryArgs.password,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            setAuthInfo({
              userId: data.userId,
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
            }),
          )
        } catch (error) {
          return
        }
      },
    }),
    forgotPassword: builder.mutation<void, ForgotFormModel>({
      query: (queryArgs) => ({
        url: '/v3/auth/forgot-password',
        method: 'POST',
        data: {
          email: queryArgs.email.trim(),
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          return
        }
      },
    }),
  }),
})

export const { useLoginMutation, useForgotPasswordMutation } = authApi
