import { useInfiniteQuery } from '@tanstack/react-query'

import { api } from 'src/common/api'
import { PAGINATION_LIMIT } from 'src/common/config'

interface GetTasksParams {
  organizationId?: string
  limit: number
  offset?: number
  assignedToUserId?: string
  search?: string
  type?: string
  fromDate?: Date
  toDate?: Date
  completeFromDate?: Date
  completeToDate?: Date
  priority?: string
  subject?: string
  status?: string
  orderBy?: string
}

const useGetTasks = (params: GetTasksParams) => {
  const {
    organizationId,
    limit,
    offset,
    assignedToUserId,
    search,
    type,
    fromDate,
    toDate,
    completeFromDate,
    completeToDate,
    priority,
    subject,
    status,
    orderBy,
  } = params

  return useInfiniteQuery({
    queryKey: [
      `getTasks`,
      organizationId,
      limit,
      offset,
      assignedToUserId,
      search,
      type,
      fromDate,
      toDate,
      completeFromDate,
      completeToDate,
      priority,
      subject,
      status,
      orderBy,
    ],
    queryFn: async ({ signal, pageParam }) =>
      await api.get(`/v3/tasks`, {
        signal,
        params: {
          organizationId,
          limit,
          assignedToUserId,
          search,
          type,
          fromDate,
          toDate,
          completeFromDate,
          completeToDate,
          priority,
          subject,
          status,
          offset: pageParam,
          orderBy,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) =>
      pages.filter((p: any) => p.data.records.length).length * PAGINATION_LIMIT,
  })
}

export default useGetTasks
