import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { BasicBlock } from 'src/common/components'
import { useGetOpportunities } from 'src/default/hooks'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { ManagerAppointmentModal, OpportunitiesList } from './components'
import styles from './opportunities.module.scss'

const Opportunities = () => {
  const { personId } = useParams<{ personId: string }>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [opportunityInformation, setOpportunityInformation] = useState<
    any | null
  >(null)

  const {
    data: { data: opportunities } = { data: {} },
    isLoading,
    isSuccess,
  } = useGetOpportunities({
    personId,
  })

  const handleOnClickOpportunity = (value: any) => {
    setShowModal(true)
    setOpportunityInformation({ ...value, confirmAssistantByPerson: false })
  }

  const clickClose = () => {
    setShowModal(false)
  }

  return (
    <BasicBlock
      className={styles.wrapper}
      title="Appointments"
      paddings="small"
      isLoading={isLoading}
      isShowWrapper={false}
    >
      {isSuccess && (
        <OpportunitiesList
          opportunities={opportunities}
          handleOnClickOpportunity={handleOnClickOpportunity}
        />
      )}
      {showModal && (
        <ManagerAppointmentModal
          opportunity={opportunityInformation}
          clickClose={clickClose}
        />
      )}
    </BasicBlock>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  Opportunities,
  'is_enable_view_appointments_widget',
)
