import { BENESTREAM_ORG_ID } from 'src/common/config'
import { NYC_ORGANIZATION_ID } from 'src/default/config'
//TODO move this interface to common/interfaces
import {
  EnumEnrollmentInactiveStatus,
  EnumEnrollmentStatus,
  IEnrollment,
} from 'src/default/interfaces'
import { IPersonSearchResultItem } from 'src/v2/domains/person/models/person.model'

export const legacyEnrollmentStatus = [
  EnumEnrollmentInactiveStatus.EXPIRED,
  EnumEnrollmentInactiveStatus.INACTIVE,
  EnumEnrollmentInactiveStatus.INVALID_CONTACT,
  EnumEnrollmentInactiveStatus.ELIGIBLE,
  EnumEnrollmentInactiveStatus.MISSING_INFO,
] as EnumEnrollmentStatus[]

export const defaultEnrollmentTransitions = [
  EnumEnrollmentInactiveStatus.EXISTING_PERSON,
  EnumEnrollmentInactiveStatus.NOT_ELIGIBLE,
  EnumEnrollmentInactiveStatus.OPT_OUT,
] as EnumEnrollmentStatus[]

export const getEnrollmentByOrgId = (
  enrollments: IEnrollment[],
  currentOrganizationId: string | null,
) => {
  return enrollments.find(
    (enrollment) =>
      enrollment.associatedBenefitContract?.organization?.id ===
      currentOrganizationId,
  )
}

export const getIsVerifiedPerson = (
  enrollments: IEnrollment[] | undefined,
  currentOrganizationId: string,
) => {
  return enrollments?.find(
    (item) =>
      item?.associatedBenefitContract?.organization?.id ===
        currentOrganizationId && item?.isVerified,
  )?.isVerified
}

export const getOrganizationAndPersonTypeToNavigate = (
  data: Omit<
    IPersonSearchResultItem,
    'assignedTo' | 'createdBy' | 'updatedBy' | 'chat'
  >,
  currentOrganizationId: string,
) => {
  const { id, enrollments } = data

  let path = (() => {
    if (currentOrganizationId === BENESTREAM_ORG_ID) {
      const benestreamEnrollment = getEnrollmentByOrgId(
        enrollments,
        BENESTREAM_ORG_ID,
      )
      return `${
        benestreamEnrollment?.isVerified ? '/accounts' : '/leads'
      }/${id}`
    }

    const isVerified = getIsVerifiedPerson(enrollments, currentOrganizationId)

    return `${isVerified ? '/accounts' : '/leads'}/${id}`
  })()
  let anotherOrgId = null

  const inCurrentOrg = getEnrollmentByOrgId(enrollments, currentOrganizationId)

  if (!inCurrentOrg) {
    const anotherOrgEnrollment = enrollments?.find(
      (enrollment) =>
        enrollment.associatedBenefitContract?.organization?.id &&
        enrollment.associatedBenefitContract.organization.id !==
          currentOrganizationId,
    )

    if (anotherOrgEnrollment) {
      anotherOrgId =
        anotherOrgEnrollment.associatedBenefitContract.organization.id

      if (anotherOrgId === BENESTREAM_ORG_ID) {
        path = `${anotherOrgId.isVerified ? '/accounts' : '/leads'}/${id}`
      }
    }
  }

  return {
    path,
    anotherOrgId,
  }
}

export const sortEnrollments = (enrollments: IEnrollment[]) => {
  return enrollments!.sort((a, b) =>
    a.associatedBenefitContract.name > b.associatedBenefitContract.name
      ? 1
      : -1,
  )
}

export const mapEnrollmentStatus = (
  enrollment: EnumEnrollmentStatus,
  organizationId: string,
) => {
  const isNYCOrganization = organizationId === NYC_ORGANIZATION_ID

  if (!isNYCOrganization) {
    return enrollment
  }

  switch (enrollment) {
    case EnumEnrollmentInactiveStatus.DUPLICATE:
      return EnumEnrollmentInactiveStatus.EXISTING_PERSON
    case legacyEnrollmentStatus.includes(enrollment) ? enrollment : null:
      return EnumEnrollmentInactiveStatus.REVIEW_NEEDED
    default:
      return enrollment
  }
}
