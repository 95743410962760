import SingleLeadPage from 'src/default/pages/Leads/SingleLeadPage'

import withErrorBoundaryAndPolicyCheck from '../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'

const LeadTicketsPersonPage = () => {
  return <SingleLeadPage />
}

export default withErrorBoundaryAndPolicyCheck(
  LeadTicketsPersonPage,
  'is_enable_lead_tickets_person_view',
  true,
)
