import { Input } from 'src/common/components'
import { FileUpload } from 'src/default/components'
import { useUploadCmsFile } from 'src/default/hooks'
import { BenefitsAdministrationBlockHeader } from 'src/default/pages/Administration/BenefitsAdministration/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import MutateBenefitsAdministrationButtonsData from './MutateBenefitsAdministrationButtonsData'
import MutateBenefitsAdministrationDeleteLang from './MutateBenefitsAdministrationDeleteLang'
import MutateBenefitsAdministrationItemQuill from './MutateBenefitsAdministrationItemQuill'
import styles from './mutate-benefits-administration-lang-data.module.scss'

interface MutateBenefitsAdministrationLangDataProps {
  language: string
  onRemove: (language: string) => Promise<void>
}

const MutateBenefitsAdministrationLangData = (
  props: MutateBenefitsAdministrationLangDataProps,
) => {
  const { language, onRemove } = props

  const uploadFileMutation = useUploadCmsFile()

  return (
    <div className={styles.wrapper}>
      <div>
        <FileUpload
          className={styles.fileUpload}
          uploadFileMutation={uploadFileMutation}
          required
          name={`icons.public`}
          label="Benefit Icon"
          formats={['.svg', '.png', '.jpg', '.jpeg']}
        />

        <Input
          name={`metaData.order`}
          label="Benefit Order"
          type="number"
          min={1}
        />
      </div>

      <div className={styles.benefitForm}>
        <BenefitsAdministrationBlockHeader title={`Public Benefit Information`}>
          {language !== 'en' ? (
            <MutateBenefitsAdministrationDeleteLang
              language={language}
              onRemove={() => onRemove(language)}
            />
          ) : null}
        </BenefitsAdministrationBlockHeader>

        <Input
          key={`metaData.name.${language}`}
          className={styles.input}
          required
          name={`metaData.name.${language}`}
          label="Benefit Name"
          placeholder="Enter benefit name"
        />

        <MutateBenefitsAdministrationItemQuill
          type="public"
          language={language}
        />

        <MutateBenefitsAdministrationButtonsData language={language} />

        <BenefitsAdministrationBlockHeader
          title={`Private Benefit Information`}
        />

        <MutateBenefitsAdministrationItemQuill
          type="private"
          language={language}
        />
      </div>
    </div>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  MutateBenefitsAdministrationLangData,
  'is_mutate_benefits_administration_lang',
  true,
)
