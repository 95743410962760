import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CreateSmsCampaignModel } from './smsCampaign.model'

const initialState = {
  createSmsCampaignForm: {
    title: '',
    smsMessage: '',
    sendAt: '',
    organizationId: '',
    targets: new File([], ''),
  },
}

const smsCampaignSchema = createSlice({
  name: 'smsCampaign',
  initialState: initialState,
  reducers: {
    setCreateSmsCampaignform: (
      state,
      action: PayloadAction<CreateSmsCampaignModel>,
    ) => {
      state.createSmsCampaignForm = action.payload
    },
  },
})

export const { setCreateSmsCampaignform } = smsCampaignSchema.actions

export default smsCampaignSchema.reducer
