import { BasicBlock } from 'src/common/components'

import withErrorBoundaryAndPolicyCheck from '../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import { UserForm } from '../../components'

interface IUserInformation {
  firstName: string
  lastName: string
  formik: any
}

const UserInformationTab = (props: IUserInformation) => {
  const { formik, firstName, lastName } = props

  return (
    <>
      <BasicBlock title={`${firstName} ${lastName}`}>
        <UserForm formik={formik} />
      </BasicBlock>
      <h4 className="text-error mt-4 font-bold">
        To use Union360 need add user to specific organization.<br></br>{' '}
        Contracts and role here will be deprecated!
      </h4>
    </>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  UserInformationTab,
  'is_enable_user_information_tab',
  true,
)
