import { useLocation, useParams } from 'react-router-dom'

import { Button } from 'src/common/components'
import { isDevEnv } from 'src/common/helpers'
import { BasicTemplate } from 'src/common/templates'
import { DEV_ROUTES, PRIVATE_ROUTES } from 'src/default/config'

import withErrorBoundaryAndPolicyCheck from '../../../../../../v2/commons/HOC/ErrorBoundaryAndPolicyCheckHOC'
import PolicyCheckHOC from '../../../../../../v2/commons/HOC/policyCheckHOC'
import styles from './benefits-administration-template.module.scss'

interface BenefitsAdministrationTemplateProps {
  benefitId?: string
}

const BenefitsAdministrationTemplate = (
  props: React.PropsWithChildren<BenefitsAdministrationTemplateProps>,
) => {
  const { benefitId, children } = props

  const { pathname } = useLocation()
  const { organizationId } = useParams<{ organizationId: string }>()
  const isMainBenefitsPage = pathname.startsWith(
    PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path,
  )

  const showAddNewBenefitButton = isMainBenefitsPage || benefitId

  return (
    <BasicTemplate
      title="Benefits Administration"
      header={
        <div className={styles.header}>
          {showAddNewBenefitButton ? (
            <PolicyCheckHOC policyName="is_enable_create_benefit">
              <Button
                size="small"
                icon="Plus"
                to={`/${organizationId}${PRIVATE_ROUTES.BENEFITS_ADMINISTRATION.path}/create`}
              >
                Add New Benefit
              </Button>
            </PolicyCheckHOC>
          ) : null}

          {isDevEnv() && isMainBenefitsPage ? (
            <PolicyCheckHOC policyName="is_enable_preview_page">
              <Button
                className={styles.previewButton}
                size="small"
                to={DEV_ROUTES.BENEFITS_PREVIEW.path}
                target="_blank"
                icon="Eye"
              >
                Preview
              </Button>
            </PolicyCheckHOC>
          ) : null}
        </div>
      }
    >
      {children}
    </BasicTemplate>
  )
}

export default withErrorBoundaryAndPolicyCheck(
  BenefitsAdministrationTemplate,
  'is_enable_benefits_administration_template',
  true,
)
