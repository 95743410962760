import { Icons } from 'src/common/components'
import { IOption } from 'src/common/interfaces'

import { BasicSelectGroupsOptions } from '../components'
import styles from './basic-select-groups-search.module.scss'

interface BasicSelectGroupsSearchProps {
  currentValue?: string
  searchValue: string
  options: IOption[]
  onSearchChange: (value: string) => void
  onChange: (newValue: string) => void
}

const BasicSelectGroupsSearch = (props: BasicSelectGroupsSearchProps) => {
  const { currentValue, searchValue, options, onSearchChange, onChange } = props

  const currentOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()),
  )

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icons.Search />
        </div>

        <input
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>

      {searchValue ? (
        <BasicSelectGroupsOptions
          currentValue={currentValue}
          options={currentOptions}
          onChange={onChange}
        />
      ) : null}
    </>
  )
}

export default BasicSelectGroupsSearch
